import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { ProfileMenu } from './components/ProfileMenu';
import { Dictionary } from '@onaio/utils/dist/types/types';
import './style.css';
import { IDENTITY_API, POSTS_API } from '../../configs/env';
import { AkukoAPIService } from '../../services/serviceClass';
import { ERROR_GENERIC } from '../../configs/constants';
import Loader from '../Post/Loader/Loader';
import Footer from '../Post/Footer';
import { hasPublicPosts } from './helpers';
import { actionUserGet, actionPostsGet, actionProfileGet } from '../Account/actions';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

export interface ProfileProps {
  user: Dictionary;
}

const Profile: React.FC<ProfileProps> = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const params: Dictionary = useParams();
  const user = useSelector((state: Dictionary) => state.user);
  const profile = useSelector((state: Dictionary) => state.profile);
  const posts = useSelector((state: Dictionary) => state.posts);
  const [showLoader, setShowLoader] = useState(false);
  document.title = `${profile.name} – Akuko`;

  useEffect(() => {
    document.body.classList.add('main-feed');
    return () => {
      document.body.classList.remove('main-feed');
    };
  }, []);

  useEffect(() => {
    setShowLoader(true);
    const service = new AkukoAPIService(IDENTITY_API, `user/handle`);
    service
      .read(params.user)
      .then((res) => {
        dispatch(actionProfileGet(res));
        const service = new AkukoAPIService(IDENTITY_API, `user/handle`);
        service.read(params.user);
      })
      .then((res) => {
        dispatch(actionUserGet(res));
        const currentState = store.getState();
        /* @ts-ignore */
        const service = new AkukoAPIService(POSTS_API, `post/email/${currentState.profile.email}`);
        return service.list();
      })
      .then((posts) => {
        dispatch(actionPostsGet(posts));
      })
      .catch((error) => {
        message.error(error.message || ERROR_GENERIC);
      })
      .finally(() => {
        setShowLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.user]);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="profile">
      <Row gutter={0}>
        <Col xs={24} sm={24} lg={16}>
          <div className="profile-header">
            <Link className="profile-header--brand" to="/feed">
              Brand
            </Link>
            <h1> {profile.name ? profile.name : profile.handle}</h1>
          </div>
          <div className="profile-posts">
            {posts.map(
              (item: Dictionary, index: number) =>
                item.public && (
                  <div className={`profile-post no-space`} key={index}>
                    {item.space_id && (
                      <div className="profile-post--space">
                        <span
                          className="profile-post--space-account"
                          style={{
                            backgroundImage: `url(https://assets.akuko.io/${item.account_id}-icon.png)`,
                          }}
                        >
                          {item.account_id}
                        </span>{' '}
                        <small>
                          Published in{' '}
                          <span className="profile-post--space-name">{item.space_id}</span>
                        </small>
                      </div>
                    )}
                    <div className="content">
                      {item.image && (
                        <div
                          className="profile-post--thumb"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>
                      )}
                      {!item.image && (
                        <div
                          className="profile-post--thumb no-thumb"
                          style={{
                            background: `#f9f9f9 url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center`,
                          }}
                        ></div>
                      )}
                      <Link to={`/post/${item.uuid}`}>
                        <h2>{item.title}</h2>
                      </Link>
                      <div className="profile-post--date">
                        <CalendarOutlined /> {moment(item.published).format('MMMM DD, YYYY')}
                      </div>
                      {item.summary ? <p>{item.summary}</p> : <p>{item.description}</p>}
                      {!item.summary && !item.description && <p>...</p>}
                    </div>
                  </div>
                )
            )}
            {!showLoader && !hasPublicPosts(posts) && (
              <p>@{params.user} has not made any posts public yet.</p>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <div className="profile--sidebar">
            <div className="content">
              {user && !user.handle && (
                <div className="profile-sidebar--sign-in">
                  <Link className="ant-btn ant-btn-primary" type="primary" to="/user/register">
                    Sign up
                  </Link>{' '}
                  <Link className="ant-btn ant-btn-link" to="/user/login">
                    Sign in
                  </Link>
                </div>
              )}

              {profile && profile.image && (
                <div
                  className="profile-sidebar--profile-pic"
                  style={{
                    backgroundImage: `url(https://assets.akuko.io/${profile.image})`,
                    backgroundSize: 'contain',
                  }}
                ></div>
              )}
              {profile && !profile.image && (
                <div className="profile-sidebar--profile-pic">
                  <UserOutlined />
                </div>
              )}
              <div className="profile-sidebar--name">{profile.name}</div>
              <div className="profile-sidebar--handle">@{params.user}</div>
              <div className="profile-sidebar--tagline">{profile.tagline}</div>

              <Footer align="left" position="bottom" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { Profile };

/*

 <div className="profile-sidebar">
        <div className="content">
          { user && !user.handle &&
          <div className="profile-sidebar--sign-in">
            <Link className="ant-btn ant-btn-primary" type="primary" to="/user/sign-up">Create Account</Link>{' '}
            <Link className="ant-btn ant-btn-link" to="/user/login">Sign in</Link>
          </div>
          }


          { profile && profile.image &&
            <div className="profile-sidebar--profile-pic"
              style={{ 
                backgroundImage: `url(https://assets.akuko.io/${profile.image})`,
                backgroundSize: 'contain'
              }}
            >
            </div>
            }
            { profile && !profile.image &&
            <div className="profile-sidebar--profile-pic"
            >
              <UserOutlined />
            </div>
            }
            <div className="profile-sidebar--name">
              { profile.name }
            </div>
            <div className="profile-sidebar--handle">
              @{ params.user }
            </div>
            <div className="profile-sidebar--tagline">
              { profile.tagline }
            </div>
            <div className="profile-sidebar--description">
              <p></p>
            </div>
          </div>
        */
