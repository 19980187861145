import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import Region from './Region';
import { PostComponentProps } from '../../PostComponent';
import { Dictionary } from '@onaio/utils';

export interface LayoutTypesProps {
  post: PostComponentProps;
  componentIndex: number;
}

export const CollapseLayout = (props: LayoutTypesProps): JSX.Element => {
  const { componentIndex, post } = props;
  const component = post.components?.[componentIndex as number];
  // const panelStyle: CSSProperties = {
  //   background: token.colorFillAlter,
  //   borderRadius: token.borderRadiusLG,
  //   border: 'none',
  // };

  //   const text = `
  //   A dog is a type of domesticated animal.
  //   Known for its loyalty and faithfulness,
  //   it can be found as a welcome guest in many households across the world.
  // `;

  const data: CollapseProps['items'] = [];
  component?.collapse?.map((collapseElement: Dictionary, index: number) => {
    data.push({
      key: index,
      label: collapseElement.title,
      children: <Region regionIndex={collapseElement.id} {...props} />,
      // children: <p>{text}</p>,
      showArrow: collapseElement.hideArrow ? false : true,
    });
  });

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <LeftOutlined rotate={isActive ? 90 : -90} />}
      expandIconPosition={component?.expandIconPosition || 'start'}
      // style={{ background: token.colorBgContainer }}
      items={data}
      accordion={component?.accordion || false}
      ghost={component?.ghost || false}
    />
  );
};
