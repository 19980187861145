import { GroupFieldProps } from '../types';

export const button: GroupFieldProps = {
  groups: ['Button', 'Items', 'Filters'],
  styles: ['Width', 'Font', 'Style'],
  sections: {},
  filters: true,
  fields: [
    {
      type: 'color',
      label: 'Background',
      property: 'backgroundColor',
      placeHolder: 'Background color',
      group: 'Style',
    },
    {
      type: 'color',
      label: 'Border color',
      property: 'borderColor',
      placeHolder: 'Border color',
      group: 'Style',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Button',
      placeHolder: 'Button Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'pills',
      label: 'Align',
      property: 'textAlign',
      placeHolder: 'Left',
      group: 'Font',
      options: [
        { value: 'left', label: 'Left' },
        { value: 'center', label: 'Center' },
        { value: 'right', label: 'Right' },
      ],
    },
    {
      type: 'select',
      property: 'size',
      label: 'Size',
      group: 'Font',
      defaultValue: 'middle',
      placeHolder: 'Medium',
      options: [
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'middle' },
        { label: 'Large', value: 'large' },
      ],
    },
    {
      type: 'fontFamily',
      property: 'fontFamily',
      placeHolder: 'PT Serif',
      label: 'Font family',
      group: 'Font',
    },
    {
      type: 'color',
      property: 'color',
      label: 'Color',
      group: 'Font',
    },
    {
      type: 'select',
      property: 'theme',
      label: 'Theme',
      group: 'Font',
      defaultValue: 'light',
      placeHolder: 'Light',
      options: [
        { label: 'Dark', value: 'dark' },
        { label: 'Light', value: 'light' },
      ],
      dependsOn: [
        {
          property: 'format',
          value: 'verticalMenu',
        },
      ],
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Button',
    },
    {
      type: 'dimension',
      label: 'Inherit',
      property: 'context',
      group: 'Button',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },
    {
      type: 'array',
      label: 'Properties',
      property: 'properties',
      group: 'Button',
      groups: ['Property'],
      hasContext: true,
      settings: [
        {
          label: 'Property',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Property',
      collapse: false,
      collapseProperty: 'value',
      collapseParent: 'properties',
      defaultValue: {
        value: '',
        type: '',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Property',
          property: 'value',
          showMeasures: true,
          showDimensions: true,
          parents: ['properties'],
          setType: true,
          setDataType: true,
          dataTypeKey: 'data-type',
          typeKey: 'type',
          placeHolder: 'Select',
        },
        {
          type: 'select',
          label: 'Granularity',
          property: 'granularity',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'second', label: 'Second' },
            { value: 'minute', label: 'Minute' },
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' },
          ],
          group: 'Property',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'select',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: '!' },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'yyyy', label: 'YYYY' },
            { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
            { value: 'MM-dd-yyy', label: 'MM-DD-YYYY' },
            { value: 'dd-yyyy-MM', label: 'DD-YYYY-MM' },
            { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
            { value: 'MM', label: 'MM' },
          ],
          group: 'Property',
          labelLink:
            'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'text',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: true },
          ],
          group: 'Property',
          labelLink:
            'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'checkbox',
          label: 'Use custom date format',
          property: 'dateFormatText',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
          ],
          group: 'Property',
          placeHolder: 'Toggle Format field to text',
          parents: ['properties'],
        },
      ],
    },
    {
      type: 'select',
      label: 'Format',
      property: 'format',
      group: 'Button',
      placeHolder: 'Button',
      options: [
        { value: 'checkbox', label: 'Check boxes' },
        { value: 'select', label: 'Select list' },
        { value: 'radio', label: 'Radio buttons' },
        { value: 'pills', label: 'Pills' },
        { value: 'button', label: 'Button' },
      ],
    },
    {
      type: 'array',
      property: 'items',
      label: '',
      item: 'Item',
      itemLabel: 'Item',
      addButtonText: 'Add Button',
      collapseProperty: 'label',
      collapseParent: 'items',
      group: 'Items',
      defaultValue: {
        label: 'Button',
      },
      groups: ['Item', 'Events'],
      settings: [
        {
          label: 'Item',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Events',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      fields: [
        {
          type: 'text',
          label: 'label',
          property: 'label',
          group: 'Item',
          parents: ['items'],
        },
        {
          type: 'switch',
          label: 'Use as divider',
          property: 'divider',
          group: 'Item',
          parents: ['items'],
        },
        {
          type: 'array',
          label: 'Events',
          property: 'events',
          itemLabel: 'Event',
          addButtonText: 'Add Event',
          parents: ['items'],
          collapseProperty: 'effect',
          collapseParent: 'events',
          group: 'Events',
          groups: ['Events'],
          settings: [
            {
              label: 'Events',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          defaultValueType: 'array',
          fields: [
            {
              type: 'select',
              label: 'Trigger',
              property: 'type',
              parents: ['items', 'events'],
              placeHolder: 'Select trigger',
              options: [{ value: 'onButtonClick', label: 'Click' }],
              group: 'Events',
            },
            {
              type: 'select',
              label: 'Event',
              property: 'effect',
              parents: ['items', 'events'],
              placeHolder: 'Select event',
              options: [
                { value: 'updateFilterValue', label: 'Update filter value' },
                { value: 'clearFilterValue', label: 'Clear filter value' },
                { value: 'showLayer', label: 'Show layer' },
                { value: 'hideLayer', label: 'Hide layer' },
                { value: 'toggleLayer', label: 'Toggle layer' },
                { value: 'showLayout', label: 'Show layout' },
                { value: 'hideLayout', label: 'Hide layout' },
                { value: 'toggleLayout', label: 'Toggle layout' },
                {
                  value: 'updateLayerProperty',
                  label: 'Update layer property',
                },
                { value: 'updateMapStyle', label: 'Update map style' },
                { value: 'flyTo', label: 'Fly to' },
                { value: 'collapseLayout', label: 'Collapse layout' },
                { value: 'webhook', label: 'Fire webhook' },
                { value: 'navigateToUrl', label: 'Navigate to URL' },
                { value: 'uncollapseLayout', label: 'Uncollapse layout' },
              ],
              group: 'Events',
              dependsOn: [
                { property: 'type', value: 'onButtonClick' },
                {
                  parent: 'component',
                  property: 'format',
                  value: ['button', 'select'],
                },
              ],
            },
            {
              type: 'select',
              label: 'Event',
              property: 'effect',
              parents: ['items', 'events'],
              placeHolder: 'Select event',
              options: [
                { value: 'toggleLayer', label: 'Toggle layer' },
                { value: 'toggleLayout', label: 'Toggle layout' },
                { value: 'updateFilterValue', label: 'Update filter value' },
              ],
              group: 'Events',
              dependsOn: [
                { property: 'type', value: 'onButtonClick' },
                {
                  parent: 'component',
                  property: 'format',
                  value: ['checkbox'],
                },
              ],
            },
            {
              type: 'select',
              label: 'Event',
              property: 'effect',
              parents: ['items', 'events'],
              placeHolder: 'Select event',
              options: [
                { value: 'toggleLayer', label: 'Toggle layer' },
                { value: 'toggleLayout', label: 'Toggle layout' },
                { value: 'updateFilterValue', label: 'Update filter value' },
                { value: 'updateMapStyle', label: 'Update map style' },
              ],
              group: 'Events',
              dependsOn: [
                { property: 'type', value: 'onButtonClick' },
                { parent: 'items', property: 'format', value: ['radio'] },
              ],
            },
            {
              type: 'select',
              label: 'Event',
              property: 'effect',
              parents: ['items', 'events'],
              placeHolder: 'Select event',
              options: [
                { value: 'toggleLayer', label: 'Toggle layer' },
                { value: 'toggleLayout', label: 'Toggle layout' },
                { value: 'updateFilterValue', label: 'Update filter value' },
                { value: 'updateMapStyle', label: 'Update map style' },
              ],
              group: 'Events',
              dependsOn: [
                { property: 'type', value: 'onButtonClick' },
                { parent: 'items', property: 'format', value: ['pills'] },
              ],
            },
            {
              type: 'text',
              label: 'URL',
              property: 'url',
              parents: ['items', 'events'],
              placeHolder: 'https://',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
            },
            {
              type: 'checkbox',
              label: 'Open in new window',
              property: 'blank',
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
            },
            {
              type: 'select',
              label: 'Method',
              property: 'method',
              parents: ['items', 'events'],
              placeHolder: 'GET',
              options: [
                { value: 'GET', label: 'GET' },
                { value: 'POST', label: 'POST' },
                { value: 'PUT', label: 'PUT' },
                { value: 'DELETE', label: 'DELETE' },
              ],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'webhook' }],
            },
            {
              type: 'textarea',
              label: 'Headers',
              property: 'headers',
              parents: ['items', 'events'],
              placeHolder: `{}`,
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'webhook' }],
            },
            {
              type: 'text',
              label: 'URL',
              property: 'url',
              parents: ['items', 'events'],
              placeHolder: 'https://',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'webhook' }],
            },
            {
              type: 'textarea',
              label: 'body',
              property: 'body',
              parents: ['items', 'events'],
              placeHolder: '',
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'webhook' },
                { property: 'method', value: ['POST', 'PUT'] },
              ],
            },
            {
              type: 'text',
              label: 'Success message',
              property: 'message',
              parents: ['items', 'events'],
              placeHolder: 'Webhook fired',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'webhook' }],
            },
            {
              type: 'layer',
              label: 'Layer',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Select layer',
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'showLayer' },
              ],
            },
            {
              type: 'layer',
              label: 'Layer',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Select layer',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'hideLayer' }],
            },
            {
              type: 'layer',
              label: 'Layer',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Select layer',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'updateLayerProperty' }],
            },
            {
              type: 'layer',
              property: 'componentId',
              parents: ['items', 'events'],
              excludeLayer: true,
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'updateMapStyle' }],
            },
            {
              type: 'select',
              label: 'Map style',
              property: 'target',
              parents: ['items', 'events'],
              options: [
                { value: 'default-style', label: 'Light' },
                { value: 'dark', label: 'Dark' },
                { value: 'outdoors', label: 'Outdoors' },
                { value: 'terrain', label: 'Terrain' },
                { value: 'satstreets', label: 'Satelitte Streets' },
                { value: 'sat', label: 'Satelitte' },
                { value: 'url-style', label: 'Custom style URL' },
                { value: 'no-style', label: 'No style' },
              ],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'updateMapStyle' }],
            },
            {
              type: 'text',
              label: 'Style url',
              property: 'customStyle',
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [
                {
                  property: 'target',
                  value: 'url-style',
                },
              ],
            },
            {
              type: 'color',
              label: 'Map background color',
              parents: ['items', 'events'],
              property: 'styleColor',
              group: 'Events',
              dependsOn: [
                {
                  property: 'target',
                  value: 'no-style',
                },
              ],
            },
            {
              type: 'layer',
              label: 'Layer to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Select layer',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'toggleLayer' }],
            },
            {
              type: 'dimension',
              label: 'Filter',
              property: 'filter',
              parents: ['items', 'events'],
              placeHolder: 'Select filter',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'clearFilterValue' }],
            },
            {
              type: 'dimension',
              label: 'Filter',
              property: 'filter',
              parents: ['items', 'events'],
              placeHolder: 'Filter',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'updateFilterValue' }],
            },
            {
              type: 'dimension-value',
              label: 'Value',
              property: 'value',
              parents: ['items', 'events'],
              group: 'Events',
              showDimensions: true,
              showMeasures: false,
              dependsOn: [{ property: 'filter', value: '*' }],
            },
            {
              type: 'component',
              label: 'Component to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'setActiveTab' }],
            },
            {
              type: 'component',
              label: 'Layout to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              componentType: 'layout',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'toggleLayout' }],
            },
            {
              type: 'component',
              label: 'Layout to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              componentType: 'layout',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'showLayout' }],
            },
            {
              type: 'component',
              label: 'Layout to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              componentType: 'layout',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'hideLayout' }],
            },
            {
              type: 'component',
              label: 'Layout to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              componentType: 'layout',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'collapseLayout' }],
            },
            {
              type: 'component',
              label: 'Layout to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              componentType: 'layout',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'uncollapseLayout' }],
            },
            {
              type: 'component',
              label: 'Component to update',
              property: 'target',
              parents: ['items', 'events'],
              placeHolder: 'Component',
              componentType: 'map',
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'flyTo' }],
            },
            {
              type: 'dimension',
              label: 'Latitude',
              property: 'lat',
              parents: ['items', 'events'],
              placeHolder: 'Latitude',
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'flyTo' },
                { property: 'customLatLng', value: ['!', true] },
              ],
            },
            {
              type: 'dimension',
              label: 'Longitude',
              property: 'lng',
              parents: ['items', 'events'],
              placeHolder: 'Longitude',
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'flyTo' },
                { property: 'customLatLng', value: ['!', true] },
              ],
            },
            {
              type: 'select',
              label: 'Property',
              property: 'property',
              parents: ['items', 'events'],
              placeHolder: 'Property',
              options: [{ value: 'colorField', label: 'Color based on' }],
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'updateLayerProperty' },
                { property: 'target', value: '*' },
              ],
            },
            {
              type: 'dimension',
              label: 'value',
              property: 'value',
              parents: ['items', 'events'],
              placeHolder: 'Select value',
              group: 'Events',
              dependsOn: [{ property: 'property', value: '*' }],
            },
            /*
            {
              type: 'lat-lng',
              label: 'Lat / Lng',
              property: 'latLng',
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'flyTo' },
                { property: 'customLatLng', value: true }
              ]
            },
            */
            {
              type: 'text',
              label: 'Longitude',
              property: 'customLng',
              parents: ['items', 'events'],
              placeHolder: '36.817223',
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'flyTo' },
                { property: 'customLatLng', value: true },
              ],
            },
            {
              type: 'text',
              label: 'Latitude',
              property: 'customLat',
              parents: ['items', 'events'],
              placeHolder: '-1.28638',
              group: 'Events',
              dependsOn: [
                { property: 'effect', value: 'flyTo' },
                { property: 'customLatLng', value: true },
              ],
            },
            {
              type: 'checkbox',
              label: 'Use custom lat / lng',
              property: 'customLatLng',
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'flyTo' }],
            },
            {
              type: 'slider',
              label: 'Zoom',
              property: 'zoom',
              parents: ['items', 'events'],
              min: 1,
              max: 20,
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'flyTo' }],
            },
            {
              type: 'slider',
              label: 'Pitch',
              property: 'pitch',
              min: 0,
              max: 90,
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'flyTo' }],
            },
            {
              type: 'slider',
              label: 'Bearing',
              property: 'bearing',
              min: 0,
              max: 360,
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'flyTo' }],
            },
            {
              type: 'slider',
              label: 'Duration',
              property: 'duration',
              min: 1000,
              max: 5000,
              step: 1000,
              placeHolder: '1000',
              parents: ['items', 'events'],
              group: 'Events',
              dependsOn: [{ property: 'effect', value: 'flyTo' }],
            },
            {
              type: 'select',
              label: 'Select tab',
              property: 'tab',
              parents: ['items', 'events'],
              placeHolder: 'Select tab',
              group: 'Events',
              options: [
                { value: '1', label: 'Tab 1' },
                { value: '2', label: 'Tab 2' },
                { value: '3', label: 'Tab 3' },
              ],
              dependsOn: [{ property: 'target', value: 'layout' }],
            },
          ],
        },
      ],
    },
    {
      type: 'array',
      label: 'Filters',
      property: 'filters',
      addButtonText: 'Add Filter',
      group: 'Filters',
      groups: ['Filter'],
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      collapse: false,
      itemLabel: 'Filter',
      collapseProperty: '1',
      collapseParent: 'filters',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate', '<=', '>='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Default value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          group: 'Options',
          label: 'Inherit filter value',
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          property: 'expose',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Options',
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Options',
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<'],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'text',
          label: 'Name',
          group: 'Options',
          property: 'name',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Options',
          property: 'cols',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'checkbox',
      label: 'Enable Cascade',
      property: 'cascade',
      group: 'Filters',
    },
  ],
};
