export const getFilterMode = (options) => {
  if (options.multiple === true) {
    return 'multiple';
  }
  return 'single';
};

export const getSliderValue = (layer, postSources, data, filterIndex) => {
  const property = layer.filters[filterIndex][1];
  const value = layer.filters[filterIndex][2];
  const cube = postSources[layer.source]?.cube;

  let sliderValue = 0;
  if (property !== undefined && value !== undefined) {
    data[`${layer.id}-filter-${filterIndex}`].forEach((item, index) => {
      if (item[`${cube}.${property}`] === value) {
        sliderValue = index;
      }
    });
  }
  return sliderValue;
};

export const getCheckedKeys = (props, options) => {
  const values = props.layer.filters[props.filterIndex][2];
  const keys = [];
  if (values && Array.isArray(values)) {
    values.forEach((item) => {
      options.forEach((option) => {
        option.children.forEach((child) => {
          if (child.title === item) {
            keys.push(child.key);
          }
        });
      });
    });
  }
  return keys;
};

export const getExpandedKeys = (props, options) => {
  const values = props.layer.filters[props.filterIndex][2];
  const keys = [];
  if (values && Array.isArray(values)) {
    values.forEach((item) => {
      options.forEach((option, index) => {
        option.children.forEach((child) => {
          if (child.title === item) {
            keys.push(index);
          }
        });
      });
    });
  }
  return [...new Set(keys)];
};

export const getGroupedFilter = (props) => {
  const data = props.data[`${props.layer.id}-filter-${props.filterIndex}`];
  const groupByValue =
    props.layer.filterOptions[props.filterIndex].groupByValue;
  const property = props.layer.filters[props.filterIndex][1];
  const cube = props.postSources[props.layer.source].cube;
  const obj = {};
  data.forEach((item) => {
    if (item[`${cube}.${groupByValue}`]) {
      const value = item[`${cube}.${groupByValue}`].trim();
      if (obj[value]) {
        if (!obj[value].includes(item[`${cube}.${property}`])) {
          obj[value].push(item[`${cube}.${property}`]);
        }
      } else {
        obj[value] = [item[`${cube}.${property}`]];
      }
    }
  });
  const array = [];
  Object.keys(obj).forEach((key, index) => {
    array.push({
      title: key,
      key: index,
      value: key,
      children: obj[key].map((item, childIndex) => {
        return {
          title: item,
          key: `${index}-${childIndex}`,
          value: item,
        };
      }),
    });
  });
  return array;
};

export const isChecked = (props, option) => {
  if (props.layer.filters[props.filterIndex][2] === option) {
    return true;
  }
  return false;
};

export const onFilterChange = (filterProps, value, valueAsText) => {
  const {
    index,
    primaryLayerIndex,
    layer,
    filterIndex,
    actionComponentMapLayerFilterEdit,
    propertyIndex,
  } = filterProps;
  if (!valueAsText) {
    if (value && !Array.isArray(value)) {
      value = [String(value)];
    }
    if (!value) {
      // if no value, set an empty array
      value = [];
    }
  }
  actionComponentMapLayerFilterEdit({
    componentIndex: index,
    layerIndex: primaryLayerIndex,
    childLayerIndex: layer.childLayerIndex,
    filterIndex: filterIndex,
    propertyIndex: propertyIndex || 2,
    value: value,
  });
};
