import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GenericComponent } from '../Component';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { ColsOne } from './components/cols-1';
import { ColsTwo } from './components/cols-2';
import { ColsThree } from './components/cols-3';
import { ColsFour } from './components/cols-4';
import { ColsFive } from './components/cols-5';
import { ColsSix } from './components/cols-6';
import { ColsSeven } from './components/cols-7';
import { ColsEight } from './components/cols-8';
import { ColsNine } from './components/cols-9';
import { ColsTen } from './components/cols-10';
import { TabsLayout } from './components/tabs';
import { CollapseLayout } from './components/collapse';
import { LayoutZero } from './components/layout-0';
import { LayoutOne } from './components/layout-1';
import { LayoutTwo } from './components/layout-2';
import { LayoutThree } from './components/layout-3';
import { LayoutFour } from './components/layout-4';
import { LayoutFive } from './components/layout-5';
import { LayoutSix } from './components/layout-6';
import { LayoutSeven } from './components/layout-7';
import { LayoutEight } from './components/layout-8';
import { LayoutNine } from './components/layout-9';
import { LayoutTen } from './components/layout-10';
import { LayoutEleven } from './components/layout-11';
import { LayoutTwelve } from './components/layout-12';
import { LayoutThirteen } from './components/layout-13';
import { LayoutFourteen } from './components/layout-14';
import { LayoutFifteen } from './components/layout-15';
import { LayoutSixteen } from './components/layout-16';
import { LayoutSeventeen } from './components/layout-17';
import { LayoutTabs } from './components/layout-tabs';
import { LayoutTwenty } from './components/layout-20';
import { LayoutTwentyOne } from './components/layout-21';
import { LayoutTwentyTwo } from './components/layout-22';
import { LayoutTwentyThree } from './components/layout-23';
import { LayoutTwentySix } from './components/layout-26';
import { DragOutlined } from '@ant-design/icons';
import './style.css';
import { getContrast } from '../../App/ColorSettings/helpers';
import { actionPostComponentSettingEdit } from '../actions';
import { useDispatch } from 'react-redux';
import { DrawerLayout } from './components/drawer';
import { ModalLayout } from './components/modal';

const mapStateToProps = (state) => {
  if (!state) {
    return null;
  } else {
    return state;
  }
};

const mapDispatchToProps = {};

const Layout = (props) => {
  const {
    componentIndex,
    parentIndex,
    componentId,
    isEmbed,
    dataRow,
    columnInTable,
    spaceComponent,
    style,
  } = props;
  const component = props.post.components[componentIndex];
  const post = useSelector((store) => store.post);
  const [draggable, setDraggable] = React.useState(false);
  const dispatch = useDispatch();
  const updatedProps = {
    ...props,
    spaceComponent: spaceComponent || component.spaceComponent,
  };

  const getLayoutId = (props) => {
    if (
      props.post.components[componentIndex]?.layout &&
      !props.post.components[componentIndex]?.layoutId
    ) {
      return props.post.components[componentIndex]?.layout;
    } else {
      return props.post.components[componentIndex]?.layoutId;
    }
  };

  let layoutId;
  if (parentIndex || parentIndex === 0) {
    layoutId = props.post.components[componentIndex].cardLayout || '24';
  } else {
    layoutId = getLayoutId(props) || '24';
  }

  const getPadding = () => {
    if (
      component?.paddingTop >= 0 ||
      component?.paddingRight >= 0 ||
      component?.paddingBottom >= 0 ||
      component?.paddingLeft >= 0
    ) {
      return `${component?.paddingTop || 0}px ${component?.paddingRight || 0}px ${
        component?.paddingBottom || 0
      }px ${component?.paddingLeft || 0}px`;
    }
    if (component?.layoutPadding) {
      return component.layoutPadding;
    }
    if (component.layoutPadding === 0) {
      return '0px';
    }
    if (component.layoutBackgroundColor) {
      return '20px';
    }
    if (component.layoutBorder) {
      return '20px';
    }
    if (component.layoutShadow) {
      return '20px';
    }
    return '0px';
  };

  const genericComponentProps = {
    isCard: parentIndex,
    componentIndex,
    componentId: componentId,
    drawerTitle: 'Layout',
    isEmbed: isEmbed,
    dataRow: dataRow,
    style: style,
    spaceComponent: spaceComponent || component.spaceComponent,
  };

  const handleBackground = () => {
    if (post?.config?.type === 'map') {
      if (!component?.region) {
        return 'transparent';
      } else {
        return component.layoutBackgroundColor;
      }
    } else {
      if (component.layoutBackgroundColor) {
        return component.layoutBackgroundColor;
      } else {
        return 'transparent';
      }
    }
  };

  const handleBorder = () => {
    if (post?.config?.type === 'map') {
      if (!component?.region) {
        return '0px';
      } else {
        if (component.layoutBorder) {
          return `1px solid rgba(0,0,0,0.1)`;
        } else {
          return '0px';
        }
      }
    } else {
      if (component.layoutBackgroundColor) {
        return component.layoutBackgroundColor;
      } else {
        return 'transparent';
      }
    }
  };

  const handleGutter = () => {
    let gutter = 15;
    if (component?.gutter >= 0) {
      gutter = component.gutter;
    }
    return gutter;
  };

  const handleRowMargin = () => {
    let rowMargin = 15;
    if (component?.rowMargin >= 0) {
      rowMargin = component.rowMargin;
    }
    return rowMargin;
  };

  const maxHeightGetter = (component) => {
    if (component?.heightMetric === 'px') {
      return `${component?.pixelHeight}px`;
    } else if (component?.heightMetric === '%') {
      return `${component?.percentHeight}%`;
    }
    return 'auto';
  };

  return (
    <div>
      <Row gutter={10}>
        <Col xs={24} sm={24}>
          <GenericComponent {...genericComponentProps}>
            <div
              className="layout--content"
              style={{
                background: handleBackground(),
                color: component?.layoutBackgroundColor
                  ? getContrast(component.layoutBackgroundColor || '#ffffff')
                  : '#111111',
                padding: getPadding(),
                marginTop: `${component.marginTop}px`,
                marginBottom: `${component.marginBottom}px`,
                border: component.layoutBorder ? '1px solid #ddd' : 'none',
                maxHeight: post?.config?.type !== 'map' ? `${component.maxHeight}px` : 'auto', // maxHeightGetter(component),
                overflowY: component.yScroll ? 'auto' : 'visible',
                overflowX: component.xScroll ? 'auto' : 'visible',
                borderRadius: component.borderRadius || 0,
                boxShadow: component?.layoutShadow ? `0 1px 7px rgb(0 0 0 / 20%)` : 'none',
                marginLeft: component?.marginLeft || 0,
              }}
            >
              {layoutId === '24' && (
                <ColsOne
                  columnInTable={columnInTable}
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  spaceComponent={spaceComponent || component.spaceComponent}
                  {...updatedProps}
                />
              )}
              {layoutId === '12-12' && (
                <ColsTwo
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  gutter={handleGutter()}
                  rowMargin={handleRowMargin()}
                  {...updatedProps}
                />
              )}
              {layoutId === '8-8-8' && (
                <ColsThree
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '6-6-6-6' && (
                <ColsFour
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '16-8' && (
                <ColsFive
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '8-16' && (
                <ColsSix
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '6-18' && (
                <ColsSeven
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '18-6' && (
                <ColsEight
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '4-20' && (
                <ColsNine
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === '20-4' && (
                <ColsTen
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  rowMargin={handleRowMargin()}
                  gutter={handleGutter()}
                  {...updatedProps}
                />
              )}
              {layoutId === 'tabs' && <TabsLayout {...updatedProps} />}

              {layoutId === 'collapse' && <CollapseLayout {...updatedProps} />}

              {layoutId === 'drawer' && <DrawerLayout {...updatedProps} />}

              {layoutId === 'modal' && <ModalLayout {...updatedProps} />}

              {layoutId === 'layout-0' && (
                <LayoutZero
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  {...updatedProps}
                />
              )}
              {layoutId === 'layout-1' && (
                <LayoutOne
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  {...updatedProps}
                />
              )}
              {layoutId === 'layout-2' && (
                <LayoutTwo
                  grid={component.mobileGrid || 'sm'}
                  rows={component.rows}
                  {...updatedProps}
                />
              )}
              {layoutId === 'layout-3' && <LayoutThree {...updatedProps} />}
              {layoutId === 'layout-4' && <LayoutFour {...updatedProps} />}
              {layoutId === 'layout-5' && <LayoutFive {...updatedProps} />}
              {layoutId === 'layout-6' && <LayoutSix {...updatedProps} />}
              {layoutId === 'layout-7' && <LayoutSeven {...updatedProps} />}
              {layoutId === 'layout-8' && <LayoutEight {...updatedProps} />}
              {layoutId === 'layout-9' && <LayoutNine {...updatedProps} />}
              {layoutId === 'layout-10' && <LayoutTen {...updatedProps} />}
              {layoutId === 'layout-11' && <LayoutEleven {...updatedProps} />}
              {layoutId === 'layout-12' && <LayoutTwelve {...updatedProps} />}
              {layoutId === 'layout-13' && <LayoutThirteen {...updatedProps} />}
              {layoutId === 'layout-14' && <LayoutFourteen {...updatedProps} />}
              {layoutId === 'layout-15' && <LayoutFifteen {...updatedProps} />}
              {layoutId === 'layout-16' && <LayoutSixteen {...updatedProps} />}
              {layoutId === 'layout-17' && <LayoutSeventeen {...updatedProps} />}
              {layoutId === 'layout-tabs' && <LayoutTabs {...updatedProps} />}
              {layoutId === 'layout-20' && <LayoutTwenty {...updatedProps} />}
              {layoutId === 'layout-21' && <LayoutTwentyOne {...updatedProps} />}
              {layoutId === 'layout-22' && <LayoutTwentyTwo {...updatedProps} />}
              {layoutId === 'layout-23' && <LayoutTwentyThree {...updatedProps} />}
              {layoutId === 'layout-26' && <LayoutTwentySix {...updatedProps} />}
            </div>
          </GenericComponent>
        </Col>
      </Row>
    </div>
  );
};

const LayoutContainer = connect(mapStateToProps, mapDispatchToProps)(Layout);

export { LayoutContainer };