import React, { useState, useEffect } from 'react';
import { InputNumber, Slider, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../actions';
import { LoadingOutlined } from '@ant-design/icons';


export interface SliderInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const SliderInput: React.FC<SliderInputProps> = (props: SliderInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, min, max, step, defaultValueType, arrayIndex, parents, placeHolder, defaultValue } =
    item;
  const post = useSelector((state: Dictionary) => state.post);
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      if (arrayIndex !== undefined) {
        const currentValue =
          post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
            property
          ][arrayIndex];
        return setValue(currentValue);
      }
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      return setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      if (arrayIndex !== undefined) {
        const currentValue =
          post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property][arrayIndex];
        return setValue(currentValue);
      }
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      return setValue(currentValue);
    }
    if (!parents) {
      if (arrayIndex !== undefined) {
        return setValue(post.components[componentIndex][property][arrayIndex]);
      }
      return setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      return setValue(post[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  const updateStore = (v: number) => {
    dispatch(
      actionPostComponentSettingEdit({
        defaultValuetype: defaultValueType,
        arrayIndex: arrayIndex,
        property: property,
        componentIndex: componentIndex,
        childIndex: childIndex,
        itemIndex: itemIndex,
        parents: parents,
        value: v,
      })
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <Row gutter={20}>
      <Col xs={6}>
        <label>{label}</label>
      </Col>
      <Col xs={10}>
        <Slider
          value={value === 0 ? 0 : value || defaultValue}
          min={min}
          max={max}
          step={step || 1}
          onChange={(v) => {
            setValue(v)
          }}
          onAfterChange={(v) => {
            updateStore(v);
            setValue(v);
          }}
        />
      </Col>
      <Col xs={8}>
        <InputNumber
          style={{ width: '60px' }}
          placeholder={placeHolder ? placeHolder : undefined}
          size={'small'}
          min={min}
          max={max}
          step={step || 1}
          value={value === 0 ? 0 : value || defaultValue}
          onChange={(v) => {
            setValue(v);
            updateStore(v);
          }}
        />
      </Col>
      <Col xs={3}>{loading && <LoadingOutlined style={{ fontSize: '18px' }} />}</Col>
    </Row>
  );
};

export { SliderInput };
