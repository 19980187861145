import React from 'react';
import { Dictionary } from '@onaio/utils';
import { Select, message } from 'antd';
import { FieldStringOutlined, NumberOutlined, CalendarOutlined } from '@ant-design/icons';
import { AkukoAPIService } from '../../../../../../services/serviceClass';
import { AKUKO_APP_RETRY_INTERVAL, AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME, QUERY_API } from '../../../../../../configs/env';
import { generateJWTToken } from '../../../../utils';
const { Option } = Select;

export const selectOptionsBuilder = (
  data: Dictionary[],
  expectedDataType: string | undefined,
  sourceType: string
): JSX.Element[] => {
  const options: JSX.Element[] = [];
  if (data?.length > 0) {
    data.forEach((dimension: Dictionary, index: number) => {
      if (expectedDataType) {
        // Allow number data type dimensions & measures
        if (expectedDataType === dimension.type || sourceType === 'measure') {
          options.push(
            <Option
              source-type={sourceType}
              key={`d-${index}-${sourceType}`}
              data-type={dimension.type}
              value={dimension.value || dimension.name}
            >
              <span className="collapse-header-label">
                {sourceType !== 'measure' ? (
                  <span className="ui-icon ui-icon--dimension">
                    {dimension.type === 'string' && <FieldStringOutlined />}
                    {dimension.type === 'number' && <NumberOutlined />}
                    {dimension.type === 'time' && <CalendarOutlined />}
                  </span>
                ) : (
                  <span className="ui-icon ui-icon--measure">
                    <NumberOutlined />
                  </span>
                )}
              </span>
              <span className="ui-icon--value">{dimension.value || dimension.name}</span>
            </Option>
          );
        }
      } else {
        options.push(
          <Option
            source-type={sourceType}
            key={`d-${index}-${sourceType}`}
            data-type={dimension.type}
            value={dimension.value || dimension.name}
          >
            <span className="collapse-header-label">
              {sourceType !== 'measure' ? (
                <span className="ui-icon ui-icon--dimension">
                  {dimension.type === 'string' && <FieldStringOutlined />}
                  {dimension.type === 'number' && <NumberOutlined />}
                  {dimension.type === 'time' && <CalendarOutlined />}
                </span>
              ) : (
                <span className="ui-icon ui-icon--measure">
                  <NumberOutlined />
                </span>
              )}
            </span>
            <span className="ui-icon--value">{dimension.value || dimension.name}</span>
          </Option>
        );
      }
    });
  }
  return options;
};

export const fetchDataWithRetry = async (retries: number, configs: Dictionary): Promise<void> => {
  // Get the number of retries and interval from environment variables

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const { source, dimensions, measures, queryFilters, limit, setResult, setLoading } = configs;
  try {
    const token = await generateJWTToken({
      sourceId: source?.uuid,
      cubeName: source?.cube,
      refreshKey: source?.refresh_key,
    });
    const headers = {
      [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
      'Content-Type': 'application/json'
    };
    const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
    const res = await service.create({
      query: {
        dimensions: dimensions,
        measures: measures,
        filters: queryFilters,
        limit: limit,
        renewQuery: true,
      },
    });
    const response = res as Dictionary;
    if (response.error === 'Continue wait' && retries > 0) {
      await sleep(AKUKO_APP_RETRY_INTERVAL);
      await fetchDataWithRetry(retries - 1, configs);
    } else if (response.data) {
      const queryResult = response.data as Dictionary[];
      setResult(queryResult);
      setLoading(false);
    } else {
      throw new Error('Failed to retrieve data after retries.');
    }
  } catch (err) {
    const error = new Error(err as string);
    message.error(error.message);
    setLoading(false);
  }
};

export const getDataType = (
  dimensions: Dictionary[],
  measures: Dictionary[],
  value: string
): string | void => {
  const dimensionValues = dimensions.map((dimension) => dimension.value);
  const measureName = measures.map((measure) => measure.name);
  if (dimensionValues.includes(value)) {
    return 'dimension';
  } else if (measureName.includes(value)) {
    return 'measure';
  }
};
