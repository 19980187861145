import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DatabaseOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils';
import { useAuth } from "react-oidc-context";
import { AddComponentMenu } from '../../../helpers/Settings/add-component-menu';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, POSTS_API, SOURCES_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import { makeId } from '../../../Map/helpers';
import { Tree, Menu, Dropdown, message, Input } from 'antd';
import { actionUserGet, actionAccountSourcesGet } from '../../../../Account/actions';
import { actionPostGet } from '../../../actions';
const { Search } = Input;
import './style.css';

export interface SourceMenuProps {
  hasError?: boolean;
}

const SourceMenu = (props: SourceMenuProps): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((store: Dictionary) => store.user);
  const config = useSelector((store: Dictionary) => store.config);
  const post = useSelector((store: Dictionary) => store.post);
  const source = useSelector((store: Dictionary) => store.source);
  const sources = useSelector((store: Dictionary) => store.sources);
  const history = useHistory();
  const [treeData, setTreeData] = useState<any[]>([]);
  const [postTreeData, setPostTreeData] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [currentPost, setCurrentPost] = useState<Dictionary>();
  const { user: authenticatedUser } = useAuth();

  useEffect(() => {
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccountSources = () => {
    const service = new AkukoAPIService(IDENTITY_API, `user`);
    service
      .read(authenticatedUser?.profile?.sub as string)
      .then((res) => {
        dispatch(actionUserGet(res));
        const url = post?.space_id ? `source/space/${post?.space_id}` : `source/user/account/${user.handle}`;
        const service = new AkukoAPIService(SOURCES_API, url);
        return service.list();
      })
      .then((data) => {
        dispatch(actionAccountSourcesGet(data));
      })
      .catch((error) => {
        message.error(error.message || ERROR_GENERIC);
      });
  };

  useEffect(() => {
    if (user.email) {
      getAccountSources();
    }
  }, [user.email]);

  useEffect(() => {
    if (config.activePostId) {
      setCurrentPost({ uuid: 'test', sources: [] });
    }
  }, []);

  const getPost = () => {
    const service = new AkukoAPIService(POSTS_API, 'post');
    if (config.activePostId) {
      service.read(config.activePostId).then((post) => {
        dispatch(actionPostGet(post));
      });
    }
  };

  const getTreeData = (type: string) => {
    const obj: any = {};
    const array: any[] = [];
    const expanded: any[] = [];
    if (type === 'all') {
      sources.items.forEach((item: Dictionary) => {
        array.push({
          key: item.uuid,
          name: item.name,
          title: (
            <>
              <DatabaseOutlined />
              &nbsp;&nbsp; {item.name}
            </>
          ),
        });
      });
    }
    if (type === 'post' && post?.sources) {
      Object.keys(post?.sources).forEach((key: string) => {
        sources.items[sources.index[key]]?.name &&
          array.push({
            key: key,
            title: (
              <>
                <DatabaseOutlined />
                &nbsp;&nbsp;
                {sources.items[sources.index[key]]?.name}
              </>
            ),
          });
      });
    }
    return array;
  };

  useEffect(() => {
    const treeData: any[] = getTreeData('all');
    setTreeData(treeData);
  }, [sources.items.length]);

  useEffect(() => {
    const treeData: any[] = getTreeData('post');
    setPostTreeData(treeData);
  }, [post, sources.items.length]);

  return (
    <div className="source-menu">
      <div className="sources-menu-wrapper">
        <h2>All Sources</h2>
        <Input
          allowClear
          className="source-menu-search"
          placeholder="Search"
          onChange={(e) => {
            const array: any[] = getTreeData('all');
            const filter = array.filter((item: Dictionary) =>
              item.name.toLowerCase().includes(e.target.value)
            );
            setTreeData(filter);
          }}
        />
        <Tree
          blockNode
          treeData={treeData}
          onSelect={(value, e) => {
            history.push(`/editor/data/${e.node.key}`);
          }}
        />
      </div>
    </div>
  );
};

export { SourceMenu };
