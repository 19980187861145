import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutNine = (props) => {
  return (
    <Row gutter={15}>
      <Col xs={12}>
        <Region regionIndex={1} {...props} />
      </Col>
      <Col xs={12}>
        <Region regionIndex={2} {...props} />
      </Col>
    </Row>
  );
};

export { LayoutNine };
