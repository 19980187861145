import React from 'react';
import DataSet from '@antv/data-set';
import {
  Chart,
  Axis,
  Tooltip,
  Legend,
  Interval,
  Line,
  Area,
  Point,
  Coord,
  BubbleChart,
  Polygon,
} from 'bizcharts';
import {
  getSeries,
  getColors,
  getLabelStyle,
  getScale,
  getGrid,
  formatLegendItemName,
  getStyle,
  toolTipBuilder,
  colorGetter,
} from '../helpers';
import { formatChartData } from '../helpers';
import { formatValue } from '../../helpers';
import { getContrast } from '../../../App/ColorSettings/helpers';

export const ChartTooltip = (component, source, hideTooltip, crosshairs) => {
  if (hideTooltip) {
    return '';
  }
  if (crosshairs) {
    return (
      <Tooltip
        crosshairs={{
          type: 'y',
        }}
      >
        {(title, items) => {
          return toolTipBuilder(title, items, component, source);
        }}
      </Tooltip>
    );
  }
  return (
    <Tooltip>
      {(title, items) => {
        return toolTipBuilder(title, items, component, source);
      }}
    </Tooltip>
  );
};

export const ScatterPlot = ({ component, data, source }) => {
  const { height, hideTooltip } = component;

  return (
    <Chart
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      scale={getScale(component)}
      data={getSeries(component, data)}
      animate={false}
      autoFit
      showTooltip={false}
    >
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        grid={getGrid(component)}
      />
      {ChartTooltip(component, source, hideTooltip)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Point
        color={getColors(component, data)}
        tooltip={hideTooltip ? false : true}
        label={
          component.circleLabels
            ? [
                'value',
                (value) => {
                  return {
                    content: value,
                    style: {
                      fill: 'red',
                    },
                  };
                },
              ]
            : ''
        }
        position='series*value'
        style={{
          fillOpacity: component.opacity || 0.9,
        }}
        size={component.circleSize || 5}
        shape='circle'
      />
    </Chart>
  );
};

export const LineChart = ({ component, data, source }) => {
  const { height, hideTooltip } = component;

  return (
    <Chart
      scale={getScale(component)}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={getSeries(component, data)}
      autoFit
      showTooltip={false}
    >
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        name='value'
        grid={getGrid(component)}
      />

      {ChartTooltip(component, source, hideTooltip, true)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Line
        color={getColors(component, data)}
        position='series*value'
        style={{ strokeOpacity: component.opacity || 0.9 }}
        tooltip={hideTooltip ? false : true}
      />
    </Chart>
  );
};

export const AreaChart = ({ component, data, source }) => {
  const { height, hideTooltip } = component;

  return (
    <Chart
      scale={getScale(component)}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={getSeries(component, data)}
      animate={false}
      autoFit
      showTooltip={false}
    >
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        grid={getGrid(component)}
      />

      {ChartTooltip(component, source, hideTooltip, true)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Area
        color={getColors(component, data)}
        position='series*value'
        tooltip={hideTooltip ? false : true}
      />
      <Line
        tooltip={hideTooltip ? false : true}
        position='series*value'
        color={getColors(component, data)}
      />
    </Chart>
  );
};

export const StackedAreaChart = ({ component, data, source }) => {
  const {
    height,
    showBarLabels,
    formatLabels,
    disableAutoGeneratedColors,
    barLabelColor,
    labelOffset,
    labelPositionOutsideBar,
    barLabelRotate,
    barLabelTextBaseLine,
    barLabelTextAlign,
    showPercentageChart,
    hideTooltip,
    fontSize,
    fontWeight,
  } = component;
  return (
    <Chart
      style={{ width: 'auto' }}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={getSeries(component, data)}
      animate={false}
      scale={getScale(component)}
      autoFit
      showTooltip={false}
    >
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        grid={getGrid(component)}
      />
      {ChartTooltip(component, source, hideTooltip, true)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Area
        adjust='stack'
        position='series*value'
        color={getColors(component, data)}
        tooltip={hideTooltip ? false : true}
        label={
          showBarLabels
            ? [
                'indicator',
                (indicator) => {
                  const color = colorGetter(component, indicator);
                  return {
                    offset: labelPositionOutsideBar
                      ? 25
                      : labelOffset === undefined || labelOffset === null
                      ? -10
                      : labelOffset,
                    content: (data) => {
                      return formatLabels
                        ? formatChartData(data, showPercentageChart)
                        : data.value;
                    },
                    rotate: barLabelRotate
                      ? barLabelRotate * (Math.PI / 180)
                      : 0,
                    style: {
                      fill:
                        disableAutoGeneratedColors || labelPositionOutsideBar
                          ? barLabelColor || '#000000'
                          : getContrast(color),
                      textAlign: barLabelTextAlign || 'right', // right center left
                      fontSize: fontSize,
                      fontWeight: fontWeight,
                      textBaseline: barLabelTextBaseLine || 'middle', // start middle end
                    },
                    layout: { type: 'limit-in-shape' },
                  };
                },
              ]
            : ''
        }
      />
      <Line
        adjust='stack'
        tooltip={hideTooltip ? false : true}
        position='series*value'
        color={getColors(component, data)}
      />
    </Chart>
  );
};

export const ColumnChart = ({ component, data, source }) => {
  const {
    height,
    showBarLabels,
    disableAutoGeneratedColors,
    barLabelColor,
    labelOffset,
    barLabelRotate,
    barLabelTextBaseLine,
    barLabelTextAlign,
    showPercentageChart,
    hideTooltip,
    fontSize,
    fontWeight,
    formatLabels,
    labelPositionOutsideBar,
  } = component;

  return (
    <Chart
      scale={getScale(component)}
      theme={{ maxColumnWidth: component.maxColumnWidth || 30 }}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      animate={false}
      data={getSeries(component, data).reverse()}
      autoFit
      showTooltip={false}
    >
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        grid={getGrid(component)}
      />
      {ChartTooltip(component, source, hideTooltip, true)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Interval
        color={getColors(component, data)}
        style={{ fillOpacity: component.opacity || 0.9 }}
        position='series*value'
        adjust={[
          {
            type: 'dodge',
          },
        ]}
        tooltip={hideTooltip ? false : true}
        label={
          showBarLabels
            ? [
                'indicator',
                (indicator) => {
                  const color = colorGetter(component, indicator);
                  return {
                    offset: labelPositionOutsideBar
                      ? 25
                      : labelOffset === undefined || labelOffset === null
                      ? -10
                      : labelOffset,
                    content: (data) => {
                      return formatLabels
                        ? formatChartData(data, showPercentageChart)
                        : data.value;
                    },
                    rotate: (barLabelRotate || 270) * (Math.PI / 180),
                    style: {
                      fill:
                        disableAutoGeneratedColors || labelPositionOutsideBar
                          ? barLabelColor || '#000000'
                          : getContrast(color),
                      textAlign: barLabelTextAlign || 'right', // right center left
                      fontSize: fontSize,
                      fontWeight: fontWeight,
                      textBaseline: barLabelTextBaseLine || 'middle', // start middle end
                    },
                    layout: { type: 'limit-in-shape' },
                  };
                },
              ]
            : ''
        }
      />
    </Chart>
  );
};

export const StackedColumnChart = ({ component, data, source }) => {
  const {
    height,
    showBarLabels,
    formatLabels,
    disableAutoGeneratedColors,
    barLabelColor,
    labelOffset,
    barLabelRotate,
    barLabelTextBaseLine,
    barLabelTextAlign,
    showPercentageChart,
    hideTooltip,
    fontSize,
    fontWeight,
    labelPositionOutsideBar,
  } = component;

  return (
    <Chart
      scale={getScale(component)}
      theme={{ maxColumnWidth: component.maxColumnWidth || 40 }}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={getSeries(component, data)}
      autoFit
      showTooltip={false}
    >
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        grid={getGrid(component)}
      />
      {ChartTooltip(component, source, hideTooltip, true)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Interval
        adjust='stack'
        style={{ fillOpacity: 1 }}
        color={getColors(component, data)}
        position='series*value'
        tooltip={hideTooltip ? false : true}
        label={
          showBarLabels
            ? [
                'indicator',
                (indicator) => {
                  const color = colorGetter(component, indicator);
                  return {
                    offset: labelPositionOutsideBar
                      ? 25
                      : labelOffset === undefined || labelOffset === null
                      ? -10
                      : labelOffset,
                    rotate: barLabelRotate
                      ? barLabelRotate * (Math.PI / 180)
                      : 0,
                    style: {
                      fill: disableAutoGeneratedColors
                        ? barLabelColor || '#000000'
                        : getContrast(color),
                      textAlign: barLabelTextAlign || 'center',
                      fontSize: fontSize,
                      fontWeight: fontWeight,
                    },
                    layout: { type: 'limit-in-shape' },
                    position: barLabelTextBaseLine || 'middle',
                    content: (data) => {
                      return formatLabels
                        ? formatChartData(data, showPercentageChart)
                        : data.value;
                    },
                  };
                },
              ]
            : ''
        }
      />
    </Chart>
  );
};

export const BarChart = ({ component, data, source }) => {
  const {
    height,
    showBarLabels,
    formatLabels,
    disableAutoGeneratedColors,
    barLabelColor,
    labelOffset,
    labelPositionOutsideBar,
    barLabelRotate,
    barLabelTextAlign,
    barLabelTextBaseLine,
    showPercentageChart,
    hideTooltip,
    fontSize,
    fontWeight,
  } = component;

  return (
    <Chart
      scale={getScale(component)}
      theme={{ maxColumnWidth: component.maxColumnWidth || 40 }}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={getSeries(component, data)}
      animate={false}
      autoFit
      showTooltip={false}
    >
      {ChartTooltip(component, source, hideTooltip)}
      <Coord transpose />
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
        grid={getGrid(component)}
      />
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Interval
        color={getColors(component, data)}
        style={{ fillOpacity: component.opacity || 0.9 }}
        adjust={[
          {
            type: 'dodge',
            marginRatio: 0,
          },
        ]}
        tooltip={hideTooltip ? false : true}
        position='series*value'
        label={
          showBarLabels
            ? [
                'indicator',
                (indicator) => {
                  const color = colorGetter(component, indicator);
                  return {
                    offset: labelPositionOutsideBar
                      ? 30
                      : labelOffset === undefined || labelOffset === null
                      ? -10
                      : labelOffset,
                    content: (data) => {
                      return formatLabels
                        ? formatChartData(data, showPercentageChart)
                        : data.value;
                    },
                    rotate: barLabelRotate
                      ? barLabelRotate * (Math.PI / 180)
                      : 0,
                    style: {
                      fill:
                        disableAutoGeneratedColors || labelPositionOutsideBar
                          ? barLabelColor || '#000000'
                          : getContrast(color),
                      textAlign: barLabelTextAlign || 'right', // right center left
                      fontSize: fontSize,
                      fontWeight: fontWeight,
                      textBaseline: barLabelTextBaseLine || 'middle', // start middle end
                    },
                    layout: { type: 'limit-in-shape' },
                  };
                },
              ]
            : ''
        }
      />
    </Chart>
  );
};

export const StackedBarChart = ({ component, data, source }) => {
  const {
    height,
    showBarLabels,
    formatLabels,
    disableAutoGeneratedColors,
    barLabelColor,
    showPercentageChart,
    labelOffset,
    barLabelRotate,
    barLabelTextAlign,
    barLabelTextBaseLine,
    labelPositionOutsideBar,
    hideTooltip,
    fontSize,
    fontWeight,
  } = component;

  return (
    <Chart
      scale={getScale(component)}
      padding={component.padding || [20, 0, 60, 70]}
      theme={{ maxColumnWidth: component.maxColumnWidth || 40 }}
      height={height || 400}
      animate={false}
      data={getSeries(component, data)}
      autoFit
      showTooltip={false}
    >
      <Coord transpose />
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
      />
      <Axis
        grid={{
          line: {
            style: {
              lineWidth: 0,
            },
          },
        }}
        name='value'
        label={data?.length > 0 ? getLabelStyle(component, 'y', source) : ''}
      />
      {ChartTooltip(component, source, hideTooltip, true)}
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Interval
        adjust={[
          {
            type: 'stack',
          },
        ]}
        tooltip={hideTooltip ? false : true}
        style={{ fillOpacity: component.opacity || 0.9 }}
        color={getColors(component, data)}
        position='series*value'
        label={
          showBarLabels
            ? [
                'indicator',
                (indicator) => {
                  const color = colorGetter(component, indicator);
                  return {
                    offset: labelPositionOutsideBar
                      ? 25
                      : labelOffset === undefined || labelOffset === null
                      ? -10
                      : labelOffset,
                    content: (data) => {
                      return formatLabels
                        ? formatChartData(data, showPercentageChart)
                        : data.value;
                    },
                    rotate: barLabelRotate
                      ? barLabelRotate * (Math.PI / 180)
                      : 0,
                    style: {
                      fill:
                        disableAutoGeneratedColors || labelPositionOutsideBar
                          ? barLabelColor || '#000000'
                          : getContrast(color),
                      textAlign: barLabelTextAlign || 'center', // right center left
                      fontSize: fontSize,
                      fontWeight: fontWeight,
                      // textBaseline: 'bottom', // start middle end
                    },
                    position: labelPositionOutsideBar
                      ? 'top'
                      : barLabelTextBaseLine || 'middle',
                    layout: { type: 'limit-in-shape' },
                  };
                },
              ]
            : ''
        }
      />
    </Chart>
  );
};

export const PolarChart = ({ component, data, source }) => {
  const { height, hideTooltip } = component;

  return (
    <Chart
      animate={false}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 600}
      scale={getScale(component)}
      data={getSeries(component, data)}
      autoFit
      showTooltip={false}
    >
      <Coord type='polar' innerRadius={0.5} />
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
        grid={getGrid(component)}
      />
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      {ChartTooltip(component, source, hideTooltip)}
      <Interval
        color={getColors(component, data)}
        style={{
          lineWidth: 1,
          stroke: '#fff',
          fillOpacity: component.opacity || 0.9,
        }}
        adjust='dodge'
        position='series*value'
        tooltip={hideTooltip ? false : true}
      />
    </Chart>
  );
};

export const RadarChart = ({ component, data, source }) => {
  const { height, hideTooltip } = component;

  return (
    <Chart
      scale={getScale(component)}
      animate={false}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={getSeries(component, data)}
      autoFit
      showTooltip={false}
    >
      <Coord type='polar' innerRadius={0.3} />
      <Axis
        name='series'
        label={data?.length > 0 ? getLabelStyle(component, 'x', source) : ''}
        grid={getGrid(component)}
      />
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      <Line
        type='line'
        position='series*value'
        color={getColors(component, data)}
        size={2}
        tooltip={hideTooltip ? false : true}
      />
      {ChartTooltip(component, source, hideTooltip)}
      <Point
        position='series*value'
        tooltip={hideTooltip ? false : true}
        color={getColors(component, data)}
        shape='circle'
        size={4}
        style={{
          stroke: '#fff',
          lineWidth: 1,
          fillOpacity: component.opacity || 1,
        }}
      />
    </Chart>
  );
};

export const PieChart = ({ component, data, source }) => {
  const { height, hideTooltip } = component;
  const chartData = getSeries(component, data);
  const compositeValue = chartData.reduce((a, b) => a + b.value, 0);
  return (
    <Chart
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      data={chartData}
      animate={false}
      autoFit
      showTooltip={false}
    >
      <Coord type='theta' innerRadius={0.6} />
      <Axis visible={false} grid={getGrid(component)} />
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
      {ChartTooltip(component, source, hideTooltip)}
      <Interval
        adjust='stack'
        style={{
          fillOpacity: component.opacity || 1,
        }}
        tooltip={hideTooltip ? false : true}
        color={getColors(component, data)}
        position='value'
        label={
          data?.length > 0
            ? getLabelStyle(component, 'x', source, compositeValue)
            : ''
        }
      />
    </Chart>
  );
};

export const BubbleChartHoc = ({ component, data, source }) => {
  const {
    height,
    bubbleMin,
    bubbleMax,
    xAxis,
    series,
    customToolTipProperties,
    bubbleProperties,
    hideTooltip,
  } = component;
  const { value } = getScale(component);

  return (
    <BubbleChart
      data={getSeries(component, data)}
      xField='series'
      yField='value'
      sizeField={bubbleProperties?.bubbleSize ? 'bubblesize' : 'value'}
      pointSize={[bubbleMin || 4, bubbleMax || 25]}
      pointStyle={(x) => {
        return getStyle(x, component);
      }}
      colorField={'indicator'}
      color={
        series?.[0]?.groupByColors.length > 0
          ? series?.[0]?.groupByColors.map((item) => item?.color)
          : series?.[0]?.color
      }
      yAxis={{
        label: data?.length > 0 ? getLabelStyle(component, 'y') : '',
        visible: true,
        max: value.yAxisMax,
        min: value.yAxisMax,
      }}
      xAxis={{
        visible: true,
        tickCount: 100,
        label: data?.length > 0 ? getLabelStyle(component, 'x') : '',
        max: value.xAxisMax,
        min: value.xAxisMax,
      }}
      adjust={[
        {
          type: 'jitter',
        },
      ]}
      padding={component.padding || [20, 0, 60, 70]}
      height={height || 400}
      meta={{
        series: {
          alias: xAxis.value,
        },
        value: {
          alias: series?.[0]?.value,
        },
        customToolTipField: {
          alias: customToolTipProperties
            ? customToolTipProperties?.customToolTipField
            : undefined,
        },
        bubblesize: {
          alias: bubbleProperties?.bubbleSize
            ? bubbleProperties?.bubbleSize
            : series?.[0]?.value,
        },
      }}
      tooltip={
        hideTooltip
          ? false
          : {
              fields:
                bubbleProperties?.bubbleSize === series?.[0]?.value
                  ? ['series', 'customToolTipField', 'value']
                  : ['series', 'customToolTipField', 'bubblesize', 'value'],
            }
      }
      animate={false}
      autoFit
      legend={{
        visible: component.legend,
        position: 'bottom',
        flipPage: true,
      }}
    ></BubbleChart>
  );
};

export const TreeMapChart = ({ component, data, source }) => {
  const { DataView } = DataSet;
  const {
    height,
    showBarLabels,
    disableAutoGeneratedColors,
    barLabelColor,
    labelOffset,
    barLabelRotate,
    barLabelTextAlign,
    barLabelTextBaseLine,
    labelPositionOutsideBar,
    hideSeriesBarLabel,
    hideCategoryBarLabel,
    series,
    hideTooltip,
    fontSize,
    fontWeight,
  } = component;
  const treemapData = {
    name: 'root',
    children: getSeries(component, data),
  };
  const dv = new DataView();
  dv.source(treemapData, {
    type: 'hierarchy',
  }).transform({
    field: 'value',
    type: 'hierarchy.treemap',
    tile: 'treemapResquarify',
    as: ['x', 'y'],
  });

  const nodes = [];
  for (const node of dv.getAllNodes()) {
    if (node.data.name === 'root') {
      continue;
    }
    const eachNode = {
      name: node.data.series,
      indicator: node.data.indicator,
      x: node.x,
      y: node.y,
      value: node.data.value,
    };

    nodes.push(eachNode);
  }
  const scale = {
    x: {
      nice: true,
    },
    y: {
      nice: true,
    },
  };
  return (
    <Chart scale={scale} pure height={height} autoFit data={nodes}>
      {ChartTooltip(component, source, hideTooltip)}
      <Polygon
        color={getColors(component, data)}
        position='x*y'
        style={{
          lineWidth: 1,
          stroke: '#fff',
          fillOpacity: component.opacity || 0.9,
        }}
        tooltip={hideTooltip ? false : true}
        label={
          showBarLabels
            ? [
                'name',
                (indicator) => {
                  const color = colorGetter(component, indicator);
                  return {
                    offset: labelPositionOutsideBar
                      ? 25
                      : labelOffset === undefined || labelOffset === null
                      ? -10
                      : labelOffset,
                    content: (data) => {
                      const name = series.map((property) => property.value);
                      const formatedValue = formatValue(
                        { name: name },
                        data.value,
                        source
                      );
                      let contentValue = `${data.name} \n ${formatedValue}`;

                      if (hideSeriesBarLabel) {
                        contentValue = data.name;
                      } else if (hideCategoryBarLabel) {
                        contentValue = formatedValue;
                      }

                      return contentValue;
                    },
                    rotate: barLabelRotate
                      ? barLabelRotate * (Math.PI / 180)
                      : 0,
                    style: {
                      fill:
                        disableAutoGeneratedColors || labelPositionOutsideBar
                          ? barLabelColor || '#000000'
                          : getContrast(color),
                      textAlign: barLabelTextAlign || 'center', // right center left
                      fontSize: fontSize,
                      fontWeight: fontWeight,
                      // textBaseline: 'bottom', // start middle end
                    },
                    position: labelPositionOutsideBar
                      ? 'top'
                      : barLabelTextBaseLine || 'middle',
                    layout: { type: 'limit-in-shape' },
                  };
                },
              ]
            : ''
        }
      />
      <Legend
        visible={component.legend}
        itemName={{
          formatter: (text) => {
            return formatLegendItemName(text, source);
          },
        }}
      />
    </Chart>
  );
};
