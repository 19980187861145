export const actionUserAuth = function (user) {
  return {
    type: 'USER_AUTH',
    data: user,
  };
};

export const actionProfileGet = function (data) {
  return {
    type: 'PROFILE_GET',
    data: data,
  };
};

export const actionIsUserAuth = function (data) {
  return {
    type: 'USER_IS_AUTH',
    data,
  };
};

export const actionUserLogout = function () {
  return {
    type: 'USER_LOGOUT',
  };
};

export const actionUserCache = function (data) {
  return {
    type: 'USER_CACHE',
    data: data,
  };
};

export const actionAccountSourcesGet = (sources) => {
  return {
    type: 'SOURCES_GET',
    data: sources,
  };
};

export const actionAccountSpacesGet = (spaces) => {
  return {
    type: 'SPACES_GET',
    data: spaces,
  };
};

export const actionAccountSpaceGet = (space) => {
  return {
    type: 'SPACE_GET',
    data: space,
  };
};

export const actionAccountSpaceAdd = (data) => {
  return {
    type: 'SPACE_ADD',
    data,
  };
};

export const actionUserGet = (data) => {
  return {
    type: 'CURRENT_USER_GET',
    data,
  };
};

export const actionUserAdd = (data) => {
  return {
    type: 'USER_ADD',
    data,
  };
};

export const actionAccountUsersGet = (users) => {
  return {
    type: 'ACCOUNT_USERS_GET',
    data: users,
  };
};

export const actionPostsGet = (posts) => {
  return {
    type: 'POSTS_GET',
    data: posts,
  };
};

export const actionUserActiveAccountEdit = function (data) {
  return {
    type: 'USER_ACTIVE_ACCOUNT_EDIT',
    data,
  };
};

export const actionSpaceMenuItemAdd = (data) => {
  return {
    type: 'SPACE_MENU_ITEM_ADD',
    data,
  };
};

export const actionSpaceMenuItemEdit = (data) => {
  return {
    type: 'SPACE_MENU_ITEM_EDIT',
    data,
  };
};

export const actionSpaceMenuItemDelete = (data) => {
  return {
    type: 'SPACE_MENU_ITEM_DELETE',
    data,
  };
};

export const actionPostEdit = (data) => {
  return {
    type: 'POST_EDIT',
    data,
  };
};

export const actionUserImageEdit = (data) => {
  return {
    type: 'USER_IMAGE_EDIT',
    data,
  };
};

export const actionUserSpaceRoleEdit = (data) => {
  return {
    type: 'ACCOUNT_USER_ROLE_EDIT',
    data,
  };
};

export const actionConfigDarkMode = (data) => ({
  type: 'CONFIG_DARK_MODE',
  data,
});

export const actionLoadAccountUsers = function (data) {
  return {
    type: 'LOAD_ACCOUNT_USERS',
    data,
  };
};

export const actionAccountInfoEdit = function (data) {
  return {
    type: 'ACCOUNT_INFO_EDIT',
    data,
  };
};

export const actionConfigRoleSet = function (data) {
  return {
    type: 'CONFIG_ROLE_SET',
    data,
  };
};

export const actionSpaceNameEdit = function (data) {
  return {
    type: 'SPACE_NAME_EDIT',
    data,
  };
};

export const actionSpacePropertyEdit = function (data) {
  return {
    type: 'SPACE_PROPERTY_EDIT',
    data,
  };
};

export const actionSpaceComponentAdd = (data) => {
  return {
    type: 'SPACE_COMPONENT_ADD',
    data,
  };
};

export const actionSpaceLogoEdit = function (data) {
  return {
    type: 'SPACE_LOGO_EDIT',
    data,
  };
};

export const actionSpaceLogoWidthEdit = function (data) {
  return {
    type: 'SPACE_LOGO_WIDTH_EDIT',
    data,
  };
};
