import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Input, message, Button, Space, Col, Collapse } from 'antd';
import { actionSourceSave } from '../actions';
import { Dictionary } from '@onaio/utils';
import {
  Source,
  UrlSourceCreationJobStatusTypes,
  sourceAsyncOperationsJobStatusTypes,
} from '../../../configs/component-types';
import { startAsycSourceCreationFromUrl } from '../helpers/helpers';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../configs/env';
import { UrlSourceCreationProgressSteps } from '../components/UrlSourceCreationProgressSteps';
import { TippecanoeOptionsTag } from '../components/TippecanoeOptionsTag';

const { Panel } = Collapse;

export interface SourceUrlInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const SourceUrlInput: React.FC<SourceUrlInputProps> = (props: SourceUrlInputProps) => {
  const { sourceActionSaveCreator } = props;
  const source = useSelector((store: Dictionary) => store.source);
  const store = useStore();
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});
  const [loading, setLoading] = useState(false);
  const [tippecanoeOptionsList, setTippecanoeOptionsList] = useState<string[]>([]);

  useEffect(() => {
    if (source.type === 'geojson') {
      setTippecanoeOptionsList(['-zg']);
    }
  }, [source.type]);

  const isVisible = () => {
    if (source.dimensions && source.dimensions.length > 0) {
      return false;
    } else {
      if (source.inputType === 'url') {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }

      setLoading(false);
      message.success('Source saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleFileUpload = ({ sourceUrl }: { sourceUrl: string }) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });

    const currentState = store.getState();
    const payload = {
      /* @ts-ignore */
      sourceId: currentState?.source?.uuid,
      /* @ts-ignore */
      sourceType: currentState?.source?.type,
      url: sourceUrl,
      refresh: false,
      /* @ts-ignore */
      name: currentState?.source?.name,
    } as Dictionary;
    if (source?.type === 'geojson') {
      payload.tippecanoe_options = tippecanoeOptionsList;
    }
    triggerAsycSourceOperation({
      asynFunc: startAsycSourceCreationFromUrl,
      asynFuncArgs: payload,
      asyncTaskName: 'Source Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  if (isVisible()) {
    return (
      <>
        <div className="input-field">
          <label>Url</label>
          <Input
            value={url}
            disabled={loading}
            onChange={(e: Dictionary) => {
              setUrl(e.target.value);
            }}
          />
        </div>
        {source?.type === 'geojson' ? (
          <Col xs={24} sm={24}>
            <Collapse>
              <Panel key={1} header="Advanced settings">
                <Space style={{ width: '100%' }} direction="vertical">
                  <Col xs={24}>
                    <label>Tile Generation Options</label>
                    <TippecanoeOptionsTag
                      disable={loading}
                      tippecanoeOptionsList={tippecanoeOptionsList}
                      setTippecanoeOptionsList={setTippecanoeOptionsList}
                    />
                  </Col>
                </Space>
              </Panel>
            </Collapse>
          </Col>
        ) : null}
        <div className="input-field">
          <Button
            disabled={
              url === '' ||
              loading ||
              (source?.type === 'geojson' && tippecanoeOptionsList.length === 0)
            }
            type="primary"
            onClick={() => {
              if (url !== '') {
                handleFileUpload({
                  sourceUrl: url,
                });
              }
            }}
          >
            Connect
          </Button>
        </div>
        {loading === true && (
          <div className="input-field">
            <label>Progress</label>
            <UrlSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

SourceUrlInput.defaultProps = defaultProps;

export { SourceUrlInput };
