import tinycolor from 'tinycolor2';

export const getTags = (array) => {
  const obj = {};
  const tags = [];
  array.forEach((item) => {
    if (item.tags) {
      item.tags.forEach((tag) => {
        obj[tag] = tag;
      });
    }
  });
  Object.keys(obj).forEach((key) => {
    tags.push({
      text: key,
      value: key,
    });
  });
  return tags;
};

export const getSpaceColor = (props) => {
  if (tinycolor(props.space.config.color).getBrightness() > 200) {
    return '#000';
  } else {
    return props.space.config.color;
  }
};

export const userCanAccessSpace = (item, props) => {
  const spaceId = item.id;
  let hasAccess = false;
  if (props.user && props.user.spaces) {
    props.user.spaces.forEach((space) => {
      if (space.id === spaceId) {
        hasAccess = true;
      }
    });
  }
  return hasAccess;
};

export const userIsAdminOfSpace = () => {
  return false;
};

export const spaceUrlIsValid = () => {
  return false;
};
