import queryString from 'querystring';
import axios, { Method } from 'axios';
import { Dictionary } from '@onaio/utils';
import { User } from "oidc-client-ts"
import { HTTPError } from './errors';
import { AKUKO_APP_OIDC_AUTHORITY, AKUKO_APP_OIDC_CLIENT_ID } from '../configs/env'


function getUser() {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${AKUKO_APP_OIDC_AUTHORITY}:${AKUKO_APP_OIDC_CLIENT_ID}`)
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

/** interface to describe URL params object */
export interface URLParams {
  [key: string]: string | number | boolean;
}

export interface ErrorResponse {
  data?: string | number;
  status?: string | number;
}

type ErrorWithMessage = {
  response: ErrorResponse;
};
/** params option type */
type paramsType = URLParams | null;

export class AkukoAPIService {
  public baseURL: string;
  public endpoint: string;
  public generalURL: string;
  public onUploadProgress?: (e: Dictionary) => void;
  public headers?: Dictionary;

  /**
   * Constructor method
   *
   * @param {string} baseURL - api base URL
   * @param {string} endpoint - api endpoint
   * @param {string} onUploadProgress - progress callback function
   * @param {string} headers - request headers
   */
  constructor(baseURL: string, endpoint: string, onUploadProgress?: (e: Dictionary) => void, headers?: Dictionary) {
    this.endpoint = endpoint;
    this.baseURL = baseURL;
    this.generalURL = `${this.baseURL}${this.endpoint}`;
    this.onUploadProgress = onUploadProgress;
    this.headers = headers || {};
  }

  /** appends any query params to the url as a querystring
   *
   * @param {string} generalUrl - the url
   * @param {object} params - the url params object
   * @returns {string} the final url
   */
  public static getURL(generalUrl: string, params: paramsType): string {
    if (params) {
      return `${generalUrl}?${decodeURIComponent(queryString.stringify(params))}`;
    }
    return generalUrl;
  }

  /**
   * Makes the actual API call
   *
   * @param {string} url full URL to the resource
   * @param {Method} method HTTP method
   * @param {Dictionary} data payload if any
   * @returns {unknown} API response
   */
  private async getResponse(
    url: string,
    method: Method,
    data?: Dictionary,
    onUploadProgress = this.onUploadProgress
  ) {
    let response;

    // if this is a PUT post, remove data and source objects
    // as we don't want to store these
    if (method === 'PUT') {
      if (data?.components) {
        if (data?.data) {
          data.data = {};
        }
        if (data?.sources) {
          data.sources = {};
        }
        if (data?.events) {
          delete data.events;
        }
      }
    }

    try {
      let headers = {
        ...this.headers,
      };
      const user = getUser();
      const token = user?.access_token;
      if (token) {
        headers = {
          ...headers,
          Authorization: `Bearer ${token}`,
        };
      }
      response = await axios({ url, method, data, headers: headers, onUploadProgress });
    } catch (error: ErrorWithMessage | any) {
      const errorMessage = error.response?.data?.error || error.response?.data;
      throw new HTTPError(errorMessage, error.response?.status);
    }
  
    return response?.data;
  }

  /** read method
   * Send a GET request to the url for the specific object
   *
   * @param {string|number} id - the identifier of the object
   * @param {params} params - the url params object
   * @param {string} method - the HTTP method
   * @returns {Promise<unknown>} API response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async read(
    id: string | number,
    params: paramsType = null,
    method: Method = 'GET'
  ): Promise<unknown> {
    const url = AkukoAPIService.getURL(`${this.generalURL}/${id}`, params);

    return this.getResponse(url, method);
  }

  /** create method
   * Send a POST request to the general endpoint containing the new object data
   
   * @param {object} data - the data to be POSTed
   * @param {params} params - the url params object
   * @param {string} method - the HTTP method
   * @returns {Promise<unknown>} API response
   */
  public async create(
    data: Dictionary,
    params: paramsType = null,
    method: Method = 'POST'
  ): Promise<unknown> {
    const url = AkukoAPIService.getURL(this.generalURL, params);

    return this.getResponse(url, method, data);
  }

  /** update method
   * Simply send the updated object as PUT request to the general endpoint URL
   *
   * @param {object} data - the data to be POSTed
   * @param {params} params - the url params object
   * @param {string} method - the HTTP method
   * @returns {Promise<unknown>} API response
   */
  public async update(
    data: Dictionary,
    params: paramsType = null,
    method: Method = 'PUT'
  ): Promise<unknown> {
    const url = AkukoAPIService.getURL(this.generalURL, params);
   
    return this.getResponse(url, method, data);
  }

  /** list method
   * Send a GET request to the general API endpoint
   *
   * @param {params} params - the url params object
   * @param {Method} method - the HTTP method
   * @returns {Promise<unknown>} API response
   */
  public async list(params: paramsType = null, method: Method = 'GET', headers?: Dictionary): Promise<unknown> {
    const url = AkukoAPIService.getURL(this.generalURL, params);

    return this.getResponse(url, method, undefined, undefined);
  }

  /** delete method
   * Send a DELETE request to the general endpoint
   *
   * @param {params} params - the url params object
   * @param {Method} method - the HTTP method
   * @returns {Promise<unknown>} API response
   */
  public async delete(params: paramsType = null, method: Method = 'DELETE', data?: Dictionary): Promise<unknown> {
    const url = AkukoAPIService.getURL(this.generalURL, params);

    return this.getResponse(url, method, data);
  }
}
