import React, { useEffect, useState } from 'react';
import { Switch, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionPostComponentSettingEdit,
  actionPostPropertyEdit,
} from '../../../actions';
import { ChartGroupSettingsTypes } from '../../../../../configs/types';
import { LoadingOutlined } from '@ant-design/icons';
import { SwitchSize } from 'antd/es/switch';

export interface SwitchSettingsProps {
  type?: string;
  label: string;
  property: string;
  parents: string[];
  entity: string;
  objectKey?: string;
  group?: ChartGroupSettingsTypes;
  size?: SwitchSize;
}
export interface SwitchInputProps {
  componentIndex: number;
  item: SwitchSettingsProps;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
}

const SwitchInput: React.FC<SwitchInputProps> = (props: SwitchInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, size, objectKey } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const [value, setValue] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const component = post.components[componentIndex];

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      let currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
            property
          ]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      let currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (!parents && componentIndex > 0) {
      setValue(component[property]);
    }
    if (item.entity === 'post' && !componentIndex) {
      setValue(post[property]);
    }
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <Row gutter={10}>
      <Col sm={17}>
        <label>{label}</label>
      </Col>
      <Col sm={7}>
        <Switch
          size={size ? size : undefined}
          checked={value}
          onChange={(value) => {
            setValue(value);
            setLoading(true);
            setTimeout(() => {
              if (item.entity === 'post') {
                dispatch(
                  actionPostPropertyEdit({
                    property: property,
                    objectKey: objectKey,
                    value: value,
                  })
                );
              } else {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: value,
                    objectKey: objectKey,
                  })
                );
              }
              setLoading(false);
            }, 500);
          }}
        />
        {loading && (
          <>
            {' '}
            <LoadingOutlined />
          </>
        )}
      </Col>
    </Row>
  );
};

export { SwitchInput };
