/* eslint-disable no-debugger */
import React from 'react';
import { Checkbox } from 'antd';
import MarkdownIt from 'markdown-it';
import { formatValue } from '../helpers';
import { Dictionary } from '@onaio/utils';
import { LayerProps } from '../../../configs/types';
import { ComponentProps } from '.';
import { Map, MapMouseEvent, Style } from 'mapbox-gl';
import { MapLayer } from '../../../configs/component-types';
import { LayoutContainer } from '../Layout';
import { actionComponentMapTilesetIdEdit } from './actions';

export interface PopupBlob {
  visible: boolean;
  coordinates: number[];
  content: JSX.Element | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resizeMaps = () => {
  Object.keys(window.akukoMaps).forEach((id) => {
    window.akukoMaps[id].resize();
  });
  return true;
};

export const hasMenuPills = (component: ComponentProps): boolean => {
  let hasMenu = false;
  component.layers.forEach((item: LayerProps) => {
    if (item.hasMenuItem) {
      hasMenu = true;
    }
  });
  return hasMenu;
};

export const getLayerVisible = (layer: MapLayer): string => {
  if (layer.visible) {
    return 'visible';
  }
  return 'none';
};

export const getStyle = (
  styleOption: string,
  styleColor: string,
  mapStyle?: string,
  darkMode?: string
): string | Style => {
  if (styleOption === 'dark') {
    return 'mapbox://styles/mapbox/dark-v10';
  } else if (styleOption === 'default-style') {
    return 'mapbox://styles/ona/ck896f2ge2bqc1inioptr3ul3';
  } else if (styleOption === 'outdoors') {
    return 'mapbox://styles/mapbox/outdoors-v11';
  } else if (styleOption === 'satstreets') {
    return 'mapbox://styles/mapbox/satellite-streets-v11';
  } else if (styleOption === 'sat') {
    return 'mapbox://styles/mapbox/satellite-v9';
  } else if (styleOption === 'terrain') {
    return 'mapbox://styles/ona/cjestgt7ldbet2sqnqth4xx8c';
  }

  if (styleOption === 'no-style') {
    return 'mapbox://styles/ona/cled8tpyy000001mx2li17h5e';
  }
  if (darkMode) {
    return 'mapbox://styles/ona/ckbl9jxlq08fb1mpervyxf634';
  }
  if (mapStyle) {
    return mapStyle;
  }
  return 'mapbox://styles/ona/ck896f2ge2bqc1inioptr3ul3';
};

export const getPopupContent = (
  map: Map,
  e: MapMouseEvent,
  layer: Dictionary,
  layerId: string,
  layerSource: Dictionary,
  popupConfigs: Dictionary
): Dictionary => {
  const { componentId, componentIndex, parentIndex, cardIndex } = popupConfigs;
  const features = map.queryRenderedFeatures(e.point, {
    layers: [layerId],
  });
  let popupContent: JSX.Element | string = '';
  if (layer.contextFromPopup && features[0]) {
    const layoutContainerProps = {
      componentIndex,
      parentIndex,
      dataRow: features[0]?.properties,
      cardIndex,
      componentId,
    };
    popupContent = (
      <div className='popup-holder'>
        <LayoutContainer key={0} {...layoutContainerProps} />
      </div>
    );
  } else if (layer.popupText && features[0]) {
    const md = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    });
    let render = layer.popupText;
    /** If token matches {foo} then it's a dimension/measure, get its value **/
    const matches = layer.popupText.match(/{([^}]+)}/g);
    if (matches) {
      matches.forEach((token: string) => {
        const property = token.replace('}', '').replace('{', '');
        const value = formatValue(
          { value: property },
          features[0]?.properties?.[property],
          layerSource
        );
        render = render.replace(token, value);
      });
    }

    popupContent = (
      <div
        dangerouslySetInnerHTML={{
          __html: md.render(render),
        }}
      ></div>
    );
  }
  return {
    visible: true,
    coordinates: { ...e.lngLat },
    content: popupContent,
  };
};

export const getTilesets = (
  layers: MapLayer[],
  tilesetEditAction: typeof actionComponentMapTilesetIdEdit,
  index: number
): Promise<unknown> => {
  // load all tilesets in a map
  return new Promise((resolve) => {
    const tilesetIds: Dictionary = {};
    const array: Dictionary[] = [];

    // create an obj from geometries
    if (layers && layers.length > 0) {
      layers.forEach((layer: MapLayer, index: number) => {
        if (
          layer.geometries &&
          layer.geometries[layer.geometryIndex as number]
        ) {
          const geometry = layer.geometries[layer.geometryIndex as number];
          tilesetIds[geometry.id] = geometry.id;
          array.push({ index: index, childIndex: null, id: geometry.id });
        }
        if (layer.children && layer.children.length > 0) {
          layer.children.forEach(
            (childLayer: Dictionary, childLayerIndex: number) => {
              if (
                childLayer.geometries &&
                childLayer.geometries[childLayer.geometryIndex]
              ) {
                const geometry =
                  childLayer.geometries[childLayer.geometryIndex];
                tilesetIds[geometry.id] = geometry.id;
                array.push({
                  index: index,
                  childIndex: childLayerIndex,
                  id: geometry.id,
                });
              }
            }
          );
        }
      });
    }
    array.forEach((item: Dictionary) => {
      tilesetEditAction({
        componentIndex: index,
        layerIndex: item.index,
        childLayerIndex: item.childLayerIndex,
        value: item.id,
      });
    });
    resolve([]);
  });
};

export const getFilters = (layer: Dictionary): (string | Dictionary)[] => {
  const { filters } = layer;
  if (
    layer.geometries[layer.geometryIndex] &&
    layer.geometries[layer.geometryIndex].groupBy &&
    layer.geometries[layer.geometryIndex].groupBy.length > 0
  ) {
    return ['!has', '34e34dfl23!'];
  } else {
    const currentFilters = filters;
    if (currentFilters && currentFilters.length > 0) {
      const array: Dictionary[] = [];
      currentFilters.forEach((item: Dictionary) => {
        if (item[0] === '==') {
          if (Array.isArray(item[2])) {
            if (item[2].length > 0) {
              array.push([
                'in',
                ['to-string', ['get', item[1]]],
                ['literal', item[2]],
              ]);
            }
          } else if (item[2] !== null && item[2] !== undefined) {
            array.push(['==', ['to-string', ['get', item[1]]], item[2]]);
          }
        }
        if (item[0] === '>') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            if (item[2].length > 0) {
              array.push(['>', ['get', item[1]], ['literal', item[2][0]]]);
            }
          } else if (!Array.isArray(item[2]) && !isNaN(item[2])) {
            const value = item[2].indexOf('.')
              ? parseFloat(item[2])
              : parseInt(item[2]);
            array.push(['>', ['get', item[1]], ['number', value]]);
          }
        }

        if (item[0] === '<') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            if (item[2].length > 0) {
              array.push(['<', ['get', item[1]], ['literal', item[2][0]]]);
            }
          } else if (!Array.isArray(item[2]) && !isNaN(item[2])) {
            const value = item[2].indexOf('.')
              ? parseFloat(item[2])
              : parseInt(item[2]);
            array.push(['<', ['get', item[1]], ['number', value]]);
          }
        }

        if (item[0] === '<=') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            if (item[2].length > 0) {
              array.push(['<=', ['get', item[1]], ['literal', item[2][0]]]);
            }
          } else if (!Array.isArray(item[2]) && !isNaN(item[2])) {
            const value = item[2].indexOf('.')
              ? parseFloat(item[2])
              : parseInt(item[2]);
            array.push(['<=', ['get', item[1]], ['number', value]]);
          }
        }

        if (item[0] === '>=') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            if (item[2].length > 0) {
              array.push(['>=', ['get', item[1]], ['literal', item[2][0]]]);
            }
          } else if (!Array.isArray(item[2]) && !isNaN(item[2])) {
            const value = item[2].indexOf('.')
              ? parseFloat(item[2])
              : parseInt(item[2]);
            array.push(['>=', ['get', item[1]], ['number', value]]);
          }
        }

        if (item[0] === '!=') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            item[2].forEach((val) => {
              array.push(['!=', ['get', item[1]], ['literal', val]]);
            });
          } else if (!Array.isArray(item[2]) && item[2]) {
            array.push(['!=', ['get', item[1]], ['literal', item[2]]]);
          }
        }

        if (item[0] === 'notNull' && item[1]) {
          array.push(['!=', ['get', item[1]], ['literal', '']]);
        }

        if (item[0] === 'isNull' && item[1]) {
          array.push(['==', ['get', item[1]], ['literal', '']]);
        }
        if (item[0] === 'contains') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            item[2].forEach((val) => {
              array.push(['in', val, ['to-string', ['get', item[1]]]]);
            });
          } else if (!Array.isArray(item[2]) && item[2]) {
            array.push(['in', item[2], ['to-string', ['get', item[1]]]]);
          }
        }
        if (item[0] === 'beforeDate') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            if (item[2].length > 0) {
              array.push(['<=', ['get', item[1]], ['literal', item[2][0]]]);
            }
          } else if (!Array.isArray(item[2]) && item[2]) {
            array.push(['<=', ['get', item[1]], ['literal', item[2]]]);
          }
        }
        if (item[0] === 'afterDate') {
          if (Array.isArray(item[2]) && item[2].length > 0) {
            if (item[2].length > 0) {
              array.push(['>=', ['get', item[1]], ['literal', item[2][0]]]);
            }
          } else if (!Array.isArray(item[2]) && item[2]) {
            array.push(['>=', ['get', item[1]], ['literal', item[2]]]);
          }
        }
        if (item[0] === 'inDateRange') {
          if (Array.isArray(item[3]) && Array.isArray(item[4])) {
            if (item[3].length > 0 && item[4].length > 0) {
              array.push([
                'all',
                ['>=', ['get', item[1]], item[3][0]],
                ['<=', ['get', item[1]], item[4][0]],
              ]);
            }
          } else if (
            !Array.isArray(item[3]) &&
            item[3] &&
            !Array.isArray(item[4]) &&
            item[4]
          ) {
            array.push([
              'all',
              ['>=', ['get', item[1]], item[3]],
              ['<=', ['get', item[1]], item[4]],
            ]);
          }
        }
        // add not in date range mapbox filter expression

        // if (item[0] === 'notInDateRange') {
        //   if (Array.isArray(item[3]) && Array.isArray(item[4])) {
        //     if (item[3].length > 0 && item[4].length > 0) {
        //       array.push([
        //         'all',
        //         ['<=', ['get', item[1]], item[3][0]],
        //         ['>=', ['get', item[1]], item[4][0]],
        //       ]);
        //     }
        //   } else if (!Array.isArray(item[3]) && item[3] && !Array.isArray(item[4]) && item[4]) {
        //     array.push([
        //       'all',
        //       ['<=', ['get', item[1]], item[3]],
        //       ['>=', ['get', item[1]], item[4]],
        //     ]);
        //   }
        // }
      });

      if (!array.length) {
        return ['!has', '34e34dfl23!'];
      }

      const filters = ['all', ...array];
      return filters;
    }
    return ['!has', '34e34dfl23!'];
  }
};

export const getCheckedKeys = (
  childFilter: string,
  filters: Dictionary[]
): Dictionary[] => {
  let keys: Dictionary[] = [];
  filters.forEach((item) => {
    if (item[1] === childFilter) {
      keys = item[2];
    }
  });
  return keys;
};

/**
 * @param {props} columnIndex
 * @param {string} value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMinMaxZoom = (property: string, layer: LayerProps): any => {
  let min = layer.minZoom || 0;
  let max = layer.maxZoom || 22;
  if (layer.minMaxZoom) {
    if (layer?.minMaxZoom[0]) {
      min = layer.minMaxZoom[0];
    }
    if (layer.minMaxZoom[1]) {
      max = layer.minMaxZoom[1];
    }
  }
  if (property === 'min') {
    return min;
  }
  if (property === 'max') {
    return max;
  }
  return null;
};

/**
 * @param {props} columnIndex
 * @param {string} value
 */
export const mapHasToggleLayers = (layers: MapLayer[]): boolean => {
  let hasToggle = false;
  if (layers) {
    layers.forEach((item: MapLayer) => {
      if (item.hasToggle) {
        hasToggle = true;
      }
    });
  }
  return hasToggle;
};

/**
 * @param {props} columnIndex
 * @param {string} value
 */
export const getHalo = (color: string): string => {
  if (color === '#ffffff') {
    return '#555555';
  }
  if (color === '#444444') {
    return '#ffffff';
  }
  return color;
};

export const makeId = (length: number): string => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const mapLayerControl = (
  layers: MapLayer[],
  actionComponentMapLayerVisibleEdit: (x: Dictionary) => void,
  componentIndex: number
): JSX.Element => {
  return (
    <div className='map-layer-control'>
      <label>Layers</label>
      {layers.map(
        (item: MapLayer, index: number) =>
          item.hasToggle && (
            <div key={index} className='map-layer-control--layer'>
              <Checkbox
                checked={item.visible}
                onChange={(e) => {
                  actionComponentMapLayerVisibleEdit({
                    componentIndex: componentIndex,
                    layerIndex: index,
                    value: e.target.checked,
                  });
                }}
              >
                {item.layerName || item.layerGroupName || 'Layer name'}
              </Checkbox>
            </div>
          )
      )}
    </div>
  );
};
