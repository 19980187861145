import { GroupFieldProps } from '../types';

export const table: GroupFieldProps = {
  groups: ['Settings', 'Pagination', 'Style', 'Pivot', 'Sort', 'Filters', 'Export'],
  styles: ['Options', 'Header'],
  sections: {},
  events: false,
  filters: true,
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Settings',
      placeHolder: 'Table Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Settings',
      placeHolder: 'Table Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Settings',
      placeHolder: 'Table Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Options',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'fontFamily',
      property: 'fontFamily',
      inherit: 'tableFontFamily',
      placeHolder: 'PT Serif',
      label: 'Font family',
      group: 'Options',
    },
    {
      type: 'color',
      property: 'colorText',
      inherit: 'colorText',
      label: 'Text color',
      disableOpacity: true,
      group: 'Options'
    },
    {
      type: 'number',
      property: 'fontSize',
      label: 'Font size',
      inherit: 'cellFontSize',
      group: 'Options',
      max: 100,
      placeHolder: '12',
    },
    {
      type: 'number',
      property: 'cellPaddingBlock',
      label: 'Cell padding',
      inherit: 'cellPaddingBlock',
      group: 'Options',
      min: 0,
      max: 100,
      placeHolder: '12',
    },
    {
      type: 'color',
      property: 'borderColor',
      inherit: 'tableBorderColor',
      label: 'Border Color',
      disableOpacity: true,
      defaultValue: '#eeeeee',
      group: 'Options',
    },
    {
      type: 'switch',
      label: 'Show header',
      property: 'showHeader',
      group: 'Header',
    },
    {
      type: 'switch',
      label: 'Fixed header',
      property: 'fixedHeader',
      group: 'Header',
    },
    {
      type: 'color',
      property: 'colorBgContainer',
      inherit: 'colorBgContainer',
      disableOpacity: true,
      label: 'Background',
      defaultValue: '#ffffff',
      group: 'Options',
    },
    {
      type: 'color',
      property: 'headerBg',
      label: 'Background',
      disableOpacity: true,
      defaultValue: '#eeeeee',
      group: 'Header',
    },
    {
      type: 'color',
      property: 'headerColor',
      disableOpacity: true,
      inherit: 'headerColor',
      label: 'Text color',
      defaultValue: '#eeeeee',
      group: 'Header',
    },
    {
      type: 'color',
      property: 'headerSortActiveBg',
      label: 'Active bg',
      defaultValue: '#eeeeee',
      group: 'Header',
    },
    {
      type: 'color',
      property: 'headerSortHoverBg',
      disableOpacity: true,
      label: 'Hover bg',
      defaultValue: '#eeeeee',
      group: 'Header',
    },
    {
      type: 'color',
      property: 'headerSplitColor',
      disableOpacity: true,
      label: 'Divider',
      defaultValue: '#eeeeee',
      group: 'Header',
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Settings',
    },
    {
      type: 'dimension',
      label: 'Context',
      property: 'context',
      group: 'Settings',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },
    {
      type: 'switch',
      label: 'Enable Pagination',
      property: 'pagination',
      group: 'Pagination',
    },
    {
      type: 'switch',
      label: 'Simple pager',
      property: 'simplePager',
      group: 'Pagination',
    },
    {
      type: 'number',
      label: 'Records',
      property: 'defaultPageSize',
      placeHolder: '10',
      group: 'Pagination',
      dependsOn: [{ property: 'pagination', value: true }],
    },
    {
      type: 'switch',
      label: 'Show elipsis on cell overflow',
      property: 'ellipsis',
      group: 'Options',
    },
    {
      type: 'switch',
      label: 'Show table border',
      property: 'bordered',
      group: 'Options',
    },
    {
      type: 'switch',
      label: 'Table Border',
      property: 'bordered',
      group: 'Layout',
    },
    {
      type: 'array',
      label: '',
      property: 'filters',
      addButtonText: 'Add Filter',
      group: 'Filters',
      groups: ['Filter'],
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      collapseProperty: '1',
      collapseParent: 'filters',
      collapse: false,
      itemLabel: 'Filter',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },

        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },

        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate', '<=', '>='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>=', '<=', '<', '>'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          label: 'Inherit filter value',
          group: 'Options',
          cols: 24,
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          cols: 24,
          property: 'expose',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          cols: 24,
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          cols: 24,
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Options',
          cols: 24,
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          cols: 24,
          group: 'Options',
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',
          cols: 24,
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<'],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          cols: 24,
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'text',
          label: 'Name',
          group: 'Filter',
          property: 'name',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Filter',
          property: 'cols',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable cascading filters',
      property: 'cascade',
      group: 'Filters',
    },
    {
      type: 'dimension',
      label: 'Rows',
      property: 'pivotRows',
      group: 'Pivot',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Enter Rows Value',
    },
    {
      type: 'dimension',
      label: 'Columns',
      property: 'pivotColumns',
      group: 'Pivot',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Enter Column Value',
    },

    {
      type: 'dimension',
      label: 'Values',
      property: 'pivotValues',
      group: 'Pivot',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Enter Pivot Values',
    },
    {
      type: 'array',
      property: 'properties',
      label: 'Columns',
      item: 'Column',
      itemLabel: 'Column',
      collapseProperty: 'value',
      collapseParent: 'properties',
      group: 'Columns',
      settings: [
        {
          label: 'Column',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Style',
          dependsOn: [],
          collapse: true,
        },
        {
          label: 'Icon',
          dependsOn: [],
          collapse: true,
        },
        {
          label: 'Heatmap',
          dependsOn: [],
          collapse: true,
        },
      ],
      defaultValue: {
        columnWidth: 10,
        iconSize: 20,
        iconColor: '#252525',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          property: 'value',
          group: 'Column',
          showMeasures: true,
          showDimensions: true,
          parents: ['properties'],
          setType: true,
          setDataType: true,
          dataTypeKey: 'data-type',
          typeKey: 'type',
          placeHolder: 'Set Field To Populate Column',
        },
        {
          type: 'switch',
          label: 'Enable component insert',
          property: 'contextFromRow',
          dependsOn: [{ property: 'value', value: '*' }],
          group: 'Column',
          parents: ['properties'],
        },
        {
          type: 'switch',
          label: 'Enable property alias',
          property: 'enablePropertyAlias',
          dependsOn: [{ property: 'value', value: '*' }],
          group: 'Column',
          parents: ['properties'],
        },
        {
          type: 'text',
          label: 'Column property alias',
          property: 'propertyAlias',
          group: 'Column',
          placeHolder: 'Property Alias',
          parents: ['properties'],
          dependsOn: [{ property: 'enablePropertyAlias', value: true }],
        },
        {
          type: 'select',
          label: 'Granularity',
          property: 'granularity',
          dependsOn: [{ property: 'value', value: 'time', dataTypeKey: 'data-type' }],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'second', label: 'Second' },
            { value: 'minute', label: 'Minute' },
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' },
          ],
          group: 'Column',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'select',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: '!' },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'yyyy', label: 'YYYY' },
            { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
            { value: 'MM-dd-yyy', label: 'MM-DD-YYYY' },
            { value: 'dd-yyyy-MM', label: 'DD-YYYY-MM' },
            { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
            { value: 'MM', label: 'MM' },
          ],
          group: 'Column',
          labelLink: 'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'text',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: true },
          ],
          group: 'Column',
          labelLink: 'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'switch',
          label: 'Use custom date format',
          property: 'dateFormatText',
          dependsOn: [{ property: 'value', value: 'time', dataTypeKey: 'data-type' }],
          group: 'Column',
          placeHolder: 'Toggle Format field to text',
          parents: ['properties'],
        },
        {
          type: 'slider',
          label: 'Width',
          property: 'columnWidth',
          min: 0,
          max: 1000,
          group: 'Style',
          parents: ['properties'],
          placeHolder: '0',
        },
        {
          type: 'select',
          label: 'Format',
          property: 'format',
          group: 'Style',
          parents: ['properties'],
          options: [
            { value: 'value', label: 'Value' },
            { value: 'icon', label: 'Icon' },
          ],
          placeHolder: 'Choose Table Format',
        },
        {
          type: 'switch',
          label: 'Fixed Column',
          property: 'fixedColumn',
          group: 'Style',
          parents: ['properties'],
        },
        {
          type: 'select',
          label: 'Column Position',
          property: 'columnPosition',
          group: 'Style',
          parents: ['properties'],
          options: [
            { value: 'left', label: 'Left' },
            { value: 'right', label: 'Right' },
          ],
          placeHolder: 'Choose Column Position',
          dependsOn: [
            {
              property: 'fixedColumn',
              value: true,
            },
          ],
        },
        {
          type: 'icon',
          label: 'Icon',
          property: 'icon',
          group: 'Icon',
          parents: ['properties'],
          dependsOn: [
            {
              property: 'format',
              value: 'icon',
            },
          ],
        },
        {
          type: 'slider',
          min: 20,
          max: 60,
          label: 'Icon size',
          property: 'iconSize',
          parents: ['properties'],
          group: 'Icon',
          dependsOn: [
            {
              property: 'format',
              value: 'icon',
            },
          ],
          placeHolder: '0',
        },
        {
          type: 'color',
          disableOpacity: true,
          label: 'Color',
          property: 'iconColor',
          parents: ['properties'],
          group: 'Icon',
          dependsOn: [
            {
              property: 'format',
              value: 'icon',
            },
          ],
        },
        {
          type: 'dimension',
          label: 'Icon based on',
          property: 'iconField',
          group: 'Icon',
          parents: ['properties'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: 'format',
              value: 'icon',
            },
          ],
          placeHolder: 'Field That Drives Symbols',
        },
        {
          type: 'array',
          label: 'Icon categories',
          property: 'iconCategories',
          group: 'Icon',
          groups: ['Categories'],
          settings: [
            {
              label: 'Categories',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          parents: ['properties'],
          itemLabel: 'Category',
          collapseProperty: 'property',
          collapseParent: 'iconCategories',
          defaultValue: {
            property: '',
            value: '',
          },
          fields: [
            {
              type: 'text',
              label: 'Property',
              group: 'Categories',
              property: 'property',
              parents: ['properties', 'iconCategories'],
              placeHolder: 'Value for the Symbol below',
            },
            {
              type: 'icon',
              label: 'Icon',
              group: 'Categories',
              property: 'value',
              parents: ['properties', 'iconCategories'],
            },
          ],
          dependsOn: [
            {
              property: 'iconField',
              value: '*',
            },
            {
              property: 'format',
              value: 'icon',
            },
          ],
        },
        {
          type: 'dimension',
          label: 'Color based on',
          property: 'colorField',
          group: 'Icon',
          parents: ['properties'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: 'format',
              value: 'icon',
            },
            {
              property: 'format',
              value: 'icon',
            },
          ],
          placeHolder: 'Field That Drives Icon Colors',
        },
        {
          type: 'array',
          label: 'Icon colors',
          property: 'colors',
          groups: ['Color'],
          settings: [
            {
              label: 'Color',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          group: 'Icon',
          itemLabel: 'Color',
          parents: ['properties'],
          collapseProperty: 'property',
          collapseParent: 'colors',
          defaultValue: {
            property: '',
            value: '',
          },
          fields: [
            {
              type: 'text',
              label: 'Property',
              group: 'Color',
              property: 'property',
              parents: ['properties', 'colors'],
              placeHolder: 'Value For The Color Below',
            },
            {
              type: 'color',
              disableOpacity: true,
              label: 'color',
              group: 'Color',
              property: 'value',
              parents: ['properties', 'colors'],
            },
          ],
          dependsOn: [
            {
              property: 'colorField',
              value: '*',
            },
            {
              property: 'format',
              value: 'icon',
            },
          ],
        },
        {
          type: 'selectSteps',
          label: 'Color mode',
          property: 'colorMode',
          group: 'Heatmap',
          parents: ['properties'],
          options: [
            { value: 'steps', label: 'Steps' },
            { value: 'categories', label: 'Categories' },
            { value: 'generatedStepsBrakes', label: 'Generate Steps' },
          ],
          placeHolder: 'Coloring Method',
        },
        {
          type: 'select',
          label: 'Color Scale',
          property: 'colorScale',
          group: 'Heatmap',
          options: [
            { value: 'ckmeans', label: 'Jenks Natural Breaks' },
            { value: 'linear', label: 'Equal Interval' },
            { value: 'quantile', label: 'Quantiles' },
          ],
          parents: ['properties'],
          placeHolder: 'Jenks Natural Breaks',
          dependsOn: [
            {
              property: 'colorMode',
              value: ['!', 'steps', 'categories'],
            },
            {
              property: 'colorMode',
              value: '*',
            },
          ],
        },
        {
          type: 'color-range',
          label: 'color',
          property: 'colorRange',
          group: 'Heatmap',
          parents: ['properties'],
          dependsOn: [
            {
              property: 'colorMode',
              value: ['!', 'steps', 'categories'],
            },
            {
              property: 'colorMode',
              value: '*',
            },
          ],
        },
        {
          type: 'array',
          label: 'Steps',
          property: 'colorSteps',
          group: 'Heatmap',
          groups: ['Step'],
          settings: [
            {
              label: 'Step',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          parents: ['properties'],
          itemLabel: 'Step',
          collapseProperty: 'value',
          collapseParent: 'colorSteps',
          defaultValue: {
            value: '0',
            color: 'red',
          },
          fields: [
            {
              type: 'text',
              addonBefore: '<=',
              label: 'Value',
              property: 'value',
              group: 'Step',
              parents: ['properties', 'colorSteps'],
            },
            {
              type: 'color',
              label: 'Color',
              property: 'color',
              group: 'Step',
              parents: ['properties', 'colorSteps'],
            },
          ],
          dependsOn: [
            {
              property: 'colorMode',
              value: 'steps',
            },
          ],
        },
        {
          type: 'array',
          label: 'Categories',
          property: 'colorCategories',
          parents: ['properties'],
          group: 'Heatmap',
          groups: ['Category'],
          settings: [
            {
              label: 'Category',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          itemLabel: 'Category',
          collapseProperty: 'value',
          collapseParent: 'colorCategories',
          defaultValue: {
            value: '0',
            color: 'red',
          },
          fields: [
            {
              type: 'text',
              label: 'Category',
              property: 'value',
              group: 'Category',
              addonBefore: '=',
              parents: ['properties', 'colorCategories'],
            },
            {
              type: 'color',
              label: 'Color',
              property: 'color',
              group: 'Category',
              parents: ['properties', 'colorCategories'],
            },
          ],
          dependsOn: [
            {
              property: 'colorMode',
              value: 'categories',
            },
          ],
        },
        {
          type: 'array',
          label: 'Steps',
          property: 'generatedSteps',
          group: 'Heatmap',
          groups: ['Step'],
          settings: [
            {
              label: 'Step',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          parents: ['properties'],
          itemLabel: 'Step',
          collapseProperty: 'value',
          collapseParent: 'generatedSteps',
          defaultValue: {
            value: '0',
            color: 'red',
          },
          fields: [
            {
              type: 'text',
              addonBefore: '<=',
              label: 'Value',
              property: 'value',
              group: 'Step',
              parents: ['properties', 'generatedSteps'],
            },
            {
              type: 'color',
              label: 'Color',
              property: 'color',
              group: 'Step',
              parents: ['properties', 'generatedSteps'],
            },
          ],
          dependsOn: [
            {
              property: 'colorMode',
              value: 'generatedStepsBrakes',
            },
          ],
        },
      ],
    },

    {
      type: 'dimension',
      label: 'Sort Field',
      property: 'sortField',
      showMeasures: true,
      showDimensions: true,
      group: 'Sort',
      placeHolder: 'Set value to Sort by',
    },
    {
      type: 'select',
      label: 'Sort order',
      property: 'sortOrder',
      options: [
        { value: 'desc', label: 'Descending' },
        { value: 'asc', label: 'Ascending' },
      ],
      group: 'Sort',
      placeHolder: 'Set Order to Sort by',
    },
    {
      type: 'number',
      label: 'Limit',
      cols: 12,
      property: 'limit',
      group: 'Sort',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'switch',
      label: 'Allow data export',
      cols: 24,
      property: 'dataExport',
      group: 'Export',
      placeHolder: '0',
    },
    {
      type: 'switch',
      label: 'Allow component image export',
      cols: 24,
      property: 'componentImageExport',
      group: 'Export',
      placeHolder: '0',
    },
  ],
};
