import { Dictionary } from '@onaio/utils/dist/types/types';

export const replaceEventTokens = (event: Dictionary, field: string): string => {
  let result = field;
  const regex = /{{(.*?)}}/g;
  const results = [...field.matchAll(regex)];
  if (results.length > 0) {
    results.forEach((item: Dictionary) => {
      const tokenValue = getTokenValue(item[1], event);
      if (tokenValue) {
        result = result.replace(new RegExp(item[0], 'g'), tokenValue);
      }
    });
  }
  return result;
};

export const getTokenValue = (token: string, event: Dictionary): string => {
  let tokenValue = token;
  if (event.feature !== undefined && event.feature?.properties && event.feature.properties[token]) {
    tokenValue = event.feature.properties[token];
    return tokenValue;
  }
  if (event.data) {
    Object.keys(event.data).forEach((key: string) => {
      if (key.split('.')[1] === token) {
        tokenValue = event.data[key];
      }
    });
  }
  return tokenValue;
};
