import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import MarkdownIt from 'markdown-it';
import { useFetchComponentData } from '../../../hooks';
import { TextComponent as TextComponentType } from '../../../../../configs/component-types';
import { actionPostComponentEdit, actionPostKeyEdit, actionConfigComponentIndexActive } from '../../../actions';
import { GenericComponent } from '../../../Component';
import { Button, Select } from 'antd';
import moment from 'moment';
import { getContextRow, processTokens } from '../../../helpers';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TextEditor } from '../../../Settings/components/TextEditor';
import { getComponentById, getComponentSource } from '../../../../../reducers/selectors/post';
import { useMemo } from 'react';
import { updateStore } from '../../../Settings/components/TextInput/helpers/helpers';
import { set } from 'lodash';
const { Option } = Select;

export interface BottomRegionProps {
    id: string
}

const BottomRegion: React.FC<BottomRegionProps> = (props: BottomRegionProps) => {
  const post = useSelector((store: Dictionary) => store.post);
  const config = useSelector((store: Dictionary) => store.config);
  const { activeComponentIndex, activeLayerIndex, activeColumnIndex } = config;
  const component = post.components[activeComponentIndex];
  const [text, setText] = useState<string>('');
  const [textDrawerOpen, setTextDrawerOpen] = useState<boolean>(true);
  const dispatch = useDispatch();
  const error = () => undefined;

  useEffect(() => {
    if (component?.text) {
      setText(component.text);
      setTextDrawerOpen(true);
      document.body.classList.add('editing-text');
    }
  }, [activeComponentIndex])

useEffect(() => {
    const timer = setTimeout(() => {
      updateStore(
        text,
        error,
        {
          item: component,
          property: 'text',
          parents: [],
          itemIndex: undefined,
          childIndex: undefined,
          componentIndex: activeComponentIndex,
        },
        /* @ts-ignore */
        dispatch
      );
    }, 500)
    return () => clearTimeout(timer)
  }, [text])


  if (textDrawerOpen && config.activeComponentIndex && post.components[config.activeComponentIndex]?.type === 'text' ||
      textDrawerOpen && config.activeComponentIndex && post.components[config.activeComponentIndex]?.type === 'html') {
  return (
    <div 
     className="editor-bottom-region"
     onMouseEnter={(e) => document.body.classList.add('bottom-region-active')}
     onMouseLeave={(e) => document.body.classList.remove('bottom-region-active')}
    >
      <div className="content">
      <div className="post--component-text--editor">
            <p style={{ fontFamily: 'Poppins', fontSize: '12px' }}>
              You may use{' '}
              <a target="blank" href="https://www.markdownguide.org/basic-syntax/">
                Markdown
              </a>
              .
            </p>
            <TextEditor value={text} onChange={(text) => setText(text)} />
            <Button
              type="primary"
              size="small"
              className="btn-save-txt"
              onClick={(e) => {
                setTextDrawerOpen(false);
                document.body.classList.remove('editing-text');
                dispatch(actionConfigComponentIndexActive({
                  value: -1
                }))
                const save = document.getElementById('btn--post-save');
                document.body.classList.remove('bottom-region-active');
              }}
            >
              <CheckOutlined />
            </Button>
            <Button
              className="btn-close-txt"
              size="small"
              onClick={(e) => {
                setTextDrawerOpen(false);
                document.body.classList.remove('editing-text');
                document.body.classList.remove('bottom-region-active');
                dispatch(actionConfigComponentIndexActive({
                  value: -1
                }))
              }}
            >
              <CloseOutlined />
            </Button>
          </div>
       </div>
    </div>
  )
  } 
  
  return null;
}

export { BottomRegion }