import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutSeventeen = (props) => {
  return (
    <div>
      <Row gutter={20}>
        <Col xs={24} sm={12}>
          <Region regionIndex={1} {...props} />
        </Col>
        <Col xs={24} sm={12}>
          <Region regionIndex={2} {...props} />
          <Region regionIndex={3} {...props} />
          <Region regionIndex={4} {...props} />
        </Col>
        <Col xs={24} sm={24}>
          <Region regionIndex={5} {...props} />
          <Region regionIndex={6} {...props} />
          <Region regionIndex={7} {...props} />
        </Col>
      </Row>
    </div>
  );
};

export { LayoutSeventeen };
