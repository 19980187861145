export const getIcons = (baseURL = 'https://assets.akuko.io/maki-icons/') => {
  return [
    {
      id: 'aerialway-15-custom',
      src: `${baseURL}aerialway-15.svg`,
      title: 'aerialway',
    },
    {
      id: 'airfield-15-custom',
      src: `${baseURL}airfield-15.svg`,
      title: 'airfield',
    },
    {
      id: 'airport-15-custom',
      src: `${baseURL}airport-15.svg`,
      title: 'airport',
    },
    {
      id: 'alcohol-shop-15-custom',
      src: `${baseURL}alcohol-shop-15.svg`,
      title: 'alcohol-shop',
    },
    {
      id: 'american-football-15-custom',
      src: `${baseURL}american-football-15.svg`,
      title: 'american-football',
    },
    {
      id: 'amusement-park-15-custom',
      src: `${baseURL}amusement-park-15.svg`,
      title: 'amusement-park',
    },
    {
      id: 'aquarium-15-custom',
      src: `${baseURL}aquarium-15.svg`,
      title: 'aquarium',
    },
    {
      id: 'art-gallery-15-custom',
      src: `${baseURL}art-gallery-15.svg`,
      title: 'art-gallery',
    },
    {
      id: 'attraction-15-custom',
      src: `${baseURL}attraction-15.svg`,
      title: 'attraction',
    },
    {
      id: 'bakery-15-custom',
      src: `${baseURL}bakery-15.svg`,
      title: 'bakery',
    },
    {
      id: 'bank-15-custom',
      src: `${baseURL}bank-15.svg`,
      title: 'bank',
    },
    {
      id: 'bank-JP-15-custom',
      src: `${baseURL}bank-JP-15.svg`,
      title: 'bank-JP',
    },
    {
      id: 'bar-15-custom',
      src: `${baseURL}bar-15.svg`,
      title: 'bar',
    },
    {
      id: 'barrier-15-custom',
      src: `${baseURL}barrier-15.svg`,
      title: 'barrier',
    },
    {
      id: 'baseball-15-custom',
      src: `${baseURL}baseball-15.svg`,
      title: 'baseball',
    },
    {
      id: 'basketball-15-custom',
      src: `${baseURL}basketball-15.svg`,
      title: 'basketball',
    },
    {
      id: 'bbq-15-custom',
      src: `${baseURL}bbq-15.svg`,
      title: 'bbq',
    },
    {
      id: 'beach-15-custom',
      src: `${baseURL}beach-15.svg`,
      title: 'beach',
    },
    {
      id: 'beer-15-custom',
      src: `${baseURL}beer-15.svg`,
      title: 'beer',
    },
    {
      id: 'bicycle-15-custom',
      src: `${baseURL}bicycle-15.svg`,
      title: 'bicycle',
    },
    {
      id: 'bicycle-share-15-custom',
      src: `${baseURL}bicycle-share-15.svg`,
      title: 'bicycle-share',
    },
    {
      id: 'blood-bank-15-custom',
      src: `${baseURL}blood-bank-15.svg`,
      title: 'blood-bank',
    },
    {
      id: 'bowling-alley',
      src: `${baseURL}bowling-alley-15.svg`,
      title: 'bowling-alley',
    },
    {
      id: 'bridge-15-custom',
      src: `${baseURL}bridge-15.svg`,
      title: 'bridge',
    },
    {
      id: 'building-15-custom',
      src: `${baseURL}building-15.svg`,
      title: 'building',
    },
    {
      id: 'bus-15-custom',
      src: `${baseURL}bus-15.svg`,
      title: 'bus',
    },
    {
      id: 'cafe-15-custom',
      src: `${baseURL}cafe-15.svg`,
      title: 'cafe',
    },
    {
      id: 'campsite-15-custom',
      src: `${baseURL}campsite-15.svg`,
      title: 'campsite',
    },
    {
      id: 'car-15-custom',
      src: `${baseURL}car-15.svg`,
      title: 'car',
    },
    {
      id: 'car-rental-15-custom',
      src: `${baseURL}car-rental-15.svg`,
      title: 'car-rental',
    },
    {
      id: 'car-repair-15-custom',
      src: `${baseURL}car-repair-15.svg`,
      title: 'car-repair',
    },
    {
      id: 'casino-15-custom',
      src: `${baseURL}casino-15.svg`,
      title: 'casino',
    },
    {
      id: 'castle-15-custom',
      src: `${baseURL}castle-15.svg`,
      title: 'castle',
    },
    {
      id: 'castle-JP-15-custom',
      src: `${baseURL}castle-JP-15.svg`,
      title: 'castle-JP',
    },
    {
      id: 'cemetery-15-custom',
      src: `${baseURL}cemetery-15.svg`,
      title: 'cemetery',
    },
    {
      id: 'cemetery-JP-15-custom',
      src: `${baseURL}cemetery-JP-15.svg`,
      title: 'cemetery-JP',
    },
    {
      id: 'charging-station-15-custom',
      src: `${baseURL}charging-station-15.svg`,
      title: 'charging-station',
    },
    {
      id: 'cinema-15-custom',
      src: `${baseURL}cinema-15.svg`,
      title: 'cinema',
    },
    {
      id: 'circle-15-custom',
      src: `${baseURL}circle-15.svg`,
      title: 'circle',
    },
    {
      id: 'circle-stroked-15-custom',
      src: `${baseURL}circle-stroked-15.svg`,
      title: 'circle-stroked',
    },
    {
      id: 'city-15-custom',
      src: `${baseURL}city-15.svg`,
      title: 'city',
    },
    {
      id: 'clothing-store-15-custom',
      src: `${baseURL}clothing-store-15.svg`,
      title: 'clothing-store',
    },
    {
      id: 'college-15-custom',
      src: `${baseURL}college-15.svg`,
      title: 'college',
    },
    {
      id: 'college-JP-15-custom',
      src: `${baseURL}college-JP-15.svg`,
      title: 'college-JP',
    },
    {
      id: 'commercial-15-custom',
      src: `${baseURL}commercial-15.svg`,
      title: 'commercial',
    },
    {
      id: 'communications-tower-15-custom',
      src: `${baseURL}communications-tower-15.svg`,
      title: 'communications-tower',
    },
    {
      id: 'confectionery-15-custom',
      src: `${baseURL}confectionery-15.svg`,
      title: 'confectionery',
    },
    {
      id: 'convenience-15-custom',
      src: `${baseURL}convenience-15.svg`,
      title: 'convenience',
    },
    {
      id: 'cricket-15-custom',
      src: `${baseURL}cricket-15.svg`,
      title: 'cricket',
    },
    {
      id: 'cross-15-custom',
      src: `${baseURL}cross-15.svg`,
      title: 'cross',
    },
    {
      id: 'dam-15-custom',
      src: `${baseURL}dam-15.svg`,
      title: 'dam',
    },
    {
      id: 'danger-15-custom',
      src: `${baseURL}danger-15.svg`,
      title: 'danger',
    },
    {
      id: 'defibrillator-15-custom',
      src: `${baseURL}defibrillator-15.svg`,
      title: 'defibrillator',
    },
    {
      id: 'dentist-15-custom',
      src: `${baseURL}dentist-15.svg`,
      title: 'dentist',
    },
    {
      id: 'doctor-15-custom',
      src: `${baseURL}doctor-15.svg`,
      title: 'doctor',
    },
    {
      id: 'dog-park-15-custom',
      src: `${baseURL}dog-park-15.svg`,
      title: 'dog-park',
    },
    {
      id: 'drinking-water-15-custom',
      src: `${baseURL}drinking-water-15.svg`,
      title: 'drinking-water',
    },
    {
      id: 'embassy-15-custom',
      src: `${baseURL}embassy-15.svg`,
      title: 'embassy',
    },
    {
      id: 'emergency-phone-15-custom',
      src: `${baseURL}emergency-phone-15.svg`,
      title: 'emergency-phone',
    },
    {
      id: 'entrance-15-custom',
      src: `${baseURL}entrance-15.svg`,
      title: 'entrance',
    },
    {
      id: 'entrance-alt1-15-custom',
      src: `${baseURL}entrance-alt1-15.svg`,
      title: 'entrance-alt1',
    },
    {
      id: 'farm-15-custom',
      src: `${baseURL}farm-15.svg`,
      title: 'farm',
    },
    {
      id: 'fast-food-15-custom',
      src: `${baseURL}fast-food-15.svg`,
      title: 'fast-food',
    },
    {
      id: 'fence-15-custom',
      src: `${baseURL}fence-15.svg`,
      title: 'fence',
    },
    {
      id: 'ferry-15-custom',
      src: `${baseURL}ferry-15.svg`,
      title: 'ferry',
    },
    {
      id: 'fire-station-15-custom',
      src: `${baseURL}fire-station-15.svg`,
      title: 'fire-station',
    },
    {
      id: 'fire-station-JP-15-custom',
      src: `${baseURL}fire-station-JP-15.svg`,
      title: 'fire-station-JP',
    },
    {
      id: 'fitness-centre-15-custom',
      src: `${baseURL}fitness-centre-15.svg`,
      title: 'fitness-centre',
    },
    {
      id: 'florist-15-custom',
      src: `${baseURL}florist-15.svg`,
      title: 'florist',
    },
    {
      id: 'fuel-15-custom',
      src: `${baseURL}fuel-15.svg`,
      title: 'fuel',
    },
    {
      id: 'furniture-15-custom',
      src: `${baseURL}furniture-15.svg`,
      title: 'furniture',
    },
    {
      id: 'gaming-15-custom',
      src: `${baseURL}gaming-15.svg`,
      title: 'gaming',
    },
    {
      id: 'garden-15-custom',
      src: `${baseURL}garden-15.svg`,
      title: 'garden',
    },
    {
      id: 'garden-centre-15-custom',
      src: `${baseURL}garden-centre-15.svg`,
      title: 'garden-centre',
    },
    {
      id: 'gift-15-custom',
      src: `${baseURL}gift-15.svg`,
      title: 'gift',
    },
    {
      id: 'globe-15-custom',
      src: `${baseURL}globe-15.svg`,
      title: 'globe',
    },
    {
      id: 'golf-15-custom',
      src: `${baseURL}golf-15.svg`,
      title: 'golf',
    },
    {
      id: 'grocery-15-custom',
      src: `${baseURL}grocery-15.svg`,
      title: 'grocery',
    },
    {
      id: 'hairdresser-15-custom',
      src: `${baseURL}hairdresser-15.svg`,
      title: 'hairdresser',
    },
    {
      id: 'harbor-15-custom',
      src: `${baseURL}harbor-15.svg`,
      title: 'harbor',
    },
    {
      id: 'hardware-15-custom',
      src: `${baseURL}hardware-15.svg`,
      title: 'hardware',
    },
    {
      id: 'heart-15-custom',
      src: `${baseURL}heart-15.svg`,
      title: 'heart',
    },
    {
      id: 'heliport-15-custom',
      src: `${baseURL}heliport-15.svg`,
      title: 'heliport',
    },
    {
      id: 'home-15-custom',
      src: `${baseURL}home-15.svg`,
      title: 'home',
    },
    {
      id: 'horse-riding-15-custom',
      src: `${baseURL}horse-riding-15.svg`,
      title: 'horse-riding',
    },
    {
      id: 'hospital-15-custom',
      src: `${baseURL}hospital-15.svg`,
      title: 'hospital',
    },
    {
      id: 'hospital-JP-15-custom',
      src: `${baseURL}hospital-JP-15.svg`,
      title: 'hospital-JP',
    },
    {
      id: 'ice-cream-15-custom',
      src: `${baseURL}ice-cream-15.svg`,
      title: 'ice-cream',
    },
    {
      id: 'industry-15-custom',
      src: `${baseURL}industry-15.svg`,
      title: 'industry',
    },
    {
      id: 'information-15-custom',
      src: `${baseURL}information-15.svg`,
      title: 'information',
    },
    {
      id: 'jewelry-store-15-custom',
      src: `${baseURL}jewelry-store-15.svg`,
      title: 'jewelry-store',
    },
    {
      id: 'karaoke-15-custom',
      src: `${baseURL}karaoke-15.svg`,
      title: 'karaoke',
    },
    {
      id: 'landmark-15-custom',
      src: `${baseURL}landmark-15.svg`,
      title: 'landmark',
    },
    {
      id: 'landmark-JP-15-custom',
      src: `${baseURL}landmark-JP-15.svg`,
      title: 'landmark-JP',
    },
    {
      id: 'landuse-15-custom',
      src: `${baseURL}landuse-15.svg`,
      title: 'landuse',
    },
    {
      id: 'laundry-15-custom',
      src: `${baseURL}laundry-15.svg`,
      title: 'laundry',
    },
    {
      id: 'library-15-custom',
      src: `${baseURL}library-15.svg`,
      title: 'library',
    },
    {
      id: 'lighthouse-15-custom',
      src: `${baseURL}lighthouse-15.svg`,
      title: 'lighthouse',
    },
    {
      id: 'lodging-15-custom',
      src: `${baseURL}lodging-15.svg`,
      title: 'lodging',
    },
    {
      id: 'logging-15-custom',
      src: `${baseURL}logging-15.svg`,
      title: 'logging',
    },
    {
      id: 'marker-15-custom',
      src: `${baseURL}marker-15.svg`,
      title: 'marker',
    },
    {
      id: 'marker-stroked-15-custom',
      src: `${baseURL}marker-stroked-15.svg`,
      title: 'marker-stroked',
    },
    {
      id: 'mobile-phone-15-custom',
      src: `${baseURL}mobile-phone-15.svg`,
      title: 'mobile-phone',
    },
    {
      id: 'monument-15-custom',
      src: `${baseURL}monument-15.svg`,
      title: 'monument',
    },
    {
      id: 'mountain-15-custom',
      src: `${baseURL}mountain-15.svg`,
      title: 'mountain',
    },
    {
      id: 'museum-15-custom',
      src: `${baseURL}museum-15.svg`,
      title: 'museum',
    },
    {
      id: 'music-15-custom',
      src: `${baseURL}music-15.svg`,
      title: 'music',
    },
    {
      id: 'natural-15-custom',
      src: `${baseURL}natural-15.svg`,
      title: 'natural',
    },
    {
      id: 'optician-15-custom',
      src: `${baseURL}optician-15.svg`,
      title: 'optician',
    },
    {
      id: 'paint-15-custom',
      src: `${baseURL}paint-15.svg`,
      title: 'paint',
    },
    {
      id: 'park-15-custom',
      src: `${baseURL}park-15.svg`,
      title: 'park',
    },
    {
      id: 'park-alt1-15-custom',
      src: `${baseURL}park-alt1-15.svg`,
      title: 'park-alt1',
    },
    {
      id: 'parking-15-custom',
      src: `${baseURL}parking-15.svg`,
      title: 'parking',
    },
    {
      id: 'parking-garage-15-custom',
      src: `${baseURL}parking-garage-15.svg`,
      title: 'parking-garage',
    },
    {
      id: 'pharmacy-15-custom',
      src: `${baseURL}pharmacy-15.svg`,
      title: 'pharmacy',
    },
    {
      id: 'picnic-site-15-custom',
      src: `${baseURL}picnic-site-15.svg`,
      title: 'picnic-site',
    },
    {
      id: 'pitch-15-custom',
      src: `${baseURL}pitch-15.svg`,
      title: 'pitch',
    },
    {
      id: 'place-of-worship-15-custom',
      src: `${baseURL}place-of-worship-15.svg`,
      title: 'place-of-worship',
    },
    {
      id: 'playground-15-custom',
      src: `${baseURL}playground-15.svg`,
      title: 'playground',
    },
    {
      id: 'police-15-custom',
      src: `${baseURL}police-15.svg`,
      title: 'police',
    },
    {
      id: 'police-JP-15-custom',
      src: `${baseURL}police-JP-15.svg`,
      title: 'police-JP',
    },
    {
      id: 'post-15-custom',
      src: `${baseURL}post-15.svg`,
      title: 'post',
    },
    {
      id: 'post-JP-15-custom',
      src: `${baseURL}post-JP-15.svg`,
      title: 'post-JP',
    },
    {
      id: 'prison-15-custom',
      src: `${baseURL}prison-15.svg`,
      title: 'prison',
    },
    {
      id: 'rail-15-custom',
      src: `${baseURL}rail-15.svg`,
      title: 'rail',
    },
    {
      id: 'rail-light-15-custom',
      src: `${baseURL}rail-light-15.svg`,
      title: 'rail-light',
    },
    {
      id: 'rail-metro-15-custom',
      src: `${baseURL}rail-metro-15.svg`,
      title: 'rail-metro',
    },
    {
      id: 'ranger-station-15-custom',
      src: `${baseURL}ranger-station-15.svg`,
      title: 'ranger-station',
    },
    {
      id: 'recycling-15-custom',
      src: `${baseURL}recycling-15.svg`,
      title: 'recycling',
    },
    {
      id: 'religious-buddhist-15-custom',
      src: `${baseURL}religious-buddhist-15.svg`,
      title: 'religious-buddhist',
    },
    {
      id: 'religious-christian-15-custom',
      src: `${baseURL}religious-christian-15.svg`,
      title: 'religious-christian',
    },
    {
      id: 'religious-jewish-15-custom',
      src: `${baseURL}religious-jewish-15.svg`,
      title: 'religious-jewish',
    },
    {
      id: 'religious-shinto-15-custom',
      src: `${baseURL}religious-shinto-15.svg`,
      title: 'religious-shinto',
    },
    {
      id: 'residential-community-15-custom',
      src: `${baseURL}residential-community-15.svg`,
      title: 'residential-community',
    },
    {
      id: 'restaurant-15-custom',
      src: `${baseURL}restaurant-15.svg`,
      title: 'restaurant',
    },
    {
      id: 'restaurant-noodle-15-custom',
      src: `${baseURL}restaurant-noodle-15.svg`,
      title: 'restaurant-noodle',
    },
    {
      id: 'restaurant-pizza-15-custom',
      src: `${baseURL}restaurant-pizza-15.svg`,
      title: 'restaurant-pizza',
    },
    {
      id: 'restaurant-seafood-15-custom',
      src: `${baseURL}restaurant-seafood-15.svg`,
      title: 'restaurant-seafood',
    },
    {
      id: 'roadblock-15-custom',
      src: `${baseURL}roadblock-15.svg`,
      title: 'roadblock',
    },
    {
      id: 'rocket-15-custom',
      src: `${baseURL}rocket-15.svg`,
      title: 'rocket',
    },
    {
      id: 'school-15-custom',
      src: `${baseURL}school-15.svg`,
      title: 'school',
    },
    {
      id: 'school-JP-15-custom',
      src: `${baseURL}school-JP-15.svg`,
      title: 'school-JP',
    },
    {
      id: 'scooter-15-custom',
      src: `${baseURL}scooter-15.svg`,
      title: 'scooter',
    },
    {
      id: 'shelter-15-custom',
      src: `${baseURL}shelter-15.svg`,
      title: 'shelter',
    },
    {
      id: 'shoe-15-custom',
      src: `${baseURL}shoe-15.svg`,
      title: 'shoe',
    },
    {
      id: 'shop-15-custom',
      src: `${baseURL}shop-15.svg`,
      title: 'shop',
    },
    {
      id: 'skateboard-15-custom',
      src: `${baseURL}skateboard-15.svg`,
      title: 'skateboard',
    },
    {
      id: 'skiing-15-custom',
      src: `${baseURL}skiing-15.svg`,
      title: 'skiing',
    },
    {
      id: 'slaughterhouse-15-custom',
      src: `${baseURL}slaughterhouse-15.svg`,
      title: 'slaughterhouse',
    },
    {
      id: 'slipway-15-custom',
      src: `${baseURL}slipway-15.svg`,
      title: 'slipway',
    },
    {
      id: 'snowmobile-15-custom',
      src: `${baseURL}snowmobile-15.svg`,
      title: 'snowmobile',
    },
    {
      id: 'soccer-15-custom',
      src: `${baseURL}soccer-15.svg`,
      title: 'soccer',
    },
    {
      id: 'square-15-custom',
      src: `${baseURL}square-15.svg`,
      title: 'square',
    },
    {
      id: 'square-stroked-15-custom',
      src: `${baseURL}square-stroked-15.svg`,
      title: 'square-stroked',
    },
    {
      id: 'stadium-15-custom',
      src: `${baseURL}stadium-15.svg`,
      title: 'stadium',
    },
    {
      id: 'star-15-custom',
      src: `${baseURL}star-15.svg`,
      title: 'star',
    },
    {
      id: 'star-stroked-15-custom',
      src: `${baseURL}star-stroked-15.svg`,
      title: 'star-stroked',
    },
    {
      id: 'suitcase-15-custom',
      src: `${baseURL}suitcase-15.svg`,
      title: 'suitcase',
    },
    {
      id: 'sushi-15-custom',
      src: `${baseURL}sushi-15.svg`,
      title: 'sushi',
    },
    {
      id: 'swimming-15-custom',
      src: `${baseURL}swimming-15.svg`,
      title: 'swimming',
    },
    {
      id: 'table-tennis-15-custom',
      src: `${baseURL}table-tennis-15.svg`,
      title: 'table-tennis',
    },
    {
      id: 'teahouse-15-custom',
      src: `${baseURL}teahouse-15.svg`,
      title: 'teahouse',
    },
    {
      id: 'telephone-15-custom',
      src: `${baseURL}telephone-15.svg`,
      title: 'telephone',
    },
    {
      id: 'tennis-15-custom',
      src: `${baseURL}tennis-15.svg`,
      title: 'tennis',
    },
    {
      id: 'theatre-15-custom',
      src: `${baseURL}theatre-15.svg`,
      title: 'theatre',
    },
    {
      id: 'toilet-15-custom',
      src: `${baseURL}toilet-15.svg`,
      title: 'toilet',
    },
    {
      id: 'town-15-custom',
      src: `${baseURL}town-15.svg`,
      title: 'town',
    },
    {
      id: 'town-hall-15-custom',
      src: `${baseURL}town-hall-15.svg`,
      title: 'town-hall',
    },
    {
      id: 'triangle-15-custom',
      src: `${baseURL}triangle-15.svg`,
      title: 'triangle',
    },
    {
      id: 'triangle-stroked-15-custom',
      src: `${baseURL}triangle-stroked-15.svg`,
      title: 'triangle-stroked',
    },
    {
      id: 'veterinary-15-custom',
      src: `${baseURL}veterinary-15.svg`,
      title: 'veterinary',
    },
    {
      id: 'viewpoint-15-custom',
      src: `${baseURL}viewpoint-15.svg`,
      title: 'viewpoint',
    },
    {
      id: 'village-15-custom',
      src: `${baseURL}village-15.svg`,
      title: 'village',
    },
    {
      id: 'volcano-15-custom',
      src: `${baseURL}volcano-15.svg`,
      title: 'volcano',
    },
    {
      id: 'volleyball-15-custom',
      src: `${baseURL}volleyball-15.svg`,
      title: 'volleyball',
    },
    {
      id: 'warehouse-15-custom',
      src: `${baseURL}warehouse-15.svg`,
      title: 'warehouse',
    },
    {
      id: 'waste-basket-15-custom',
      src: `${baseURL}waste-basket-15.svg`,
      title: 'waste-basket',
    },
    {
      id: 'watch-15-custom',
      src: `${baseURL}watch-15.svg`,
      title: 'watch',
    },
    {
      id: 'water-15-custom',
      src: `${baseURL}water-15.svg`,
      title: 'water',
    },
    {
      id: 'waterfall-15-custom',
      src: `${baseURL}waterfall-15.svg`,
      title: 'waterfall',
    },
    {
      id: 'watermill-15-custom',
      src: `${baseURL}watermill-15.svg`,
      title: 'watermill',
    },
    {
      id: 'wetland-15-custom',
      src: `${baseURL}wetland-15.svg`,
      title: 'wetland',
    },
    {
      id: 'wheelchair-15-custom',
      src: `${baseURL}wheelchair-15.svg`,
      title: 'wheelchair',
    },
    {
      id: 'windmill-15-custom',
      src: `${baseURL}windmill-15.svg`,
      title: 'windmill',
    },
    {
      id: 'zoo-15-custom',
      src: `${baseURL}zoo-15.svg`,
      title: 'zoo',
    },
  ];
};
