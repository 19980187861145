import React, { useEffect, useState } from 'react';
import { Select, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../actions';
const { Option } = Select;
export interface LayerSelectInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const LayerSelectInput: React.FC<LayerSelectInputProps> = (props: LayerSelectInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, placeHolder, excludeLayer } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const [layers, setLayers] = useState([]);
  const [value, setValue] = useState('');
  const [mapComponentId, setMapComponentId] = useState<string>();

  useEffect(() => {
    if (post.components[componentIndex].type === 'map') {
      setMapComponentId(post.components[componentIndex].id);
      setLayers(post.components[componentIndex].layers);
    }
  }, []);

  useEffect(() => {
    const mapComponent = post.components.filter((item: Dictionary) => item.id === mapComponentId);
    setLayers(mapComponent[0]?.layers);
  }, [mapComponentId]);

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      const componentId =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          'componentId'
        ];
      setValue(currentValue);
      if (componentId) {
        setMapComponentId(componentId);
      }
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      const componentId =
        post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.['componentId'];
      setValue(currentValue);
      if (componentId) {
        setMapComponentId(componentId);
      }
    }
    if (componentIndex && !parents) {
      setValue(post.components[componentIndex][property]);
      const componentId = post.components[componentIndex]?.['componentId'];
      if (componentId) {
        setMapComponentId(componentId);
      }
    }
    if (item.entity === 'post') {
      if (post && post[property]) {
        setValue(post[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <>
    <Row gutter={10}>
      <Col sm={6}>
      <label>Select map</label>
      </Col>
      <Col sm={18}>
      <Select
        allowClear
        showSearch
        getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
        value={mapComponentId}
        onChange={(value: string) => {
          setMapComponentId(value);
          setTimeout(() => {
            dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: 'componentId',
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                value: value,
              })
            );
          }, 500);
        }}
      >
        {post.components.map(
          (item: Dictionary, index: number) =>
            item.type === 'map' && (
              <Option key={index} value={item.id}>
                {item.name || item.id}
              </Option>
            )
        )}
      </Select>
      </Col>
      </Row>
     
      {layers && layers.length > 0 && !excludeLayer && (
         <Row gutter={10}>
          <Col sm={6}>
          {label && <label>{label}</label>}
          </Col>
          <Col sm={18}>
          <Select
            placeholder={placeHolder ? placeHolder : undefined}
            value={value ? value : undefined}
            allowClear
            showSearch
            mode={'multiple'}
            getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
            onChange={(value) => {
              setValue(value);
              setTimeout(() => {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    value: value,
                  })
                );
              }, 500);
            }}
          >
            {layers.map((layer: Dictionary, index: number) => (
              <Option key={index} value={layer.id}>
                {layer.layerName || layer.id}
              </Option>
            ))}
          </Select>
          </Col>
          </Row>
      )}

     
    </>
  );
};

export { LayerSelectInput };
