import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { ComponentMenuItem } from './components/ComponentMenuItems';
import './style.css';
import { GenericPostComponent } from '../../../configs/component-types';
import { ghostComponentIds } from './helpers/helpers';
import { ErrorComponentMenuItem } from './components/ErrorComponentMenuItem';

export interface PostComponentsProps {
  isErrorListing: boolean;
}

const PostComponents = (props: PostComponentsProps): JSX.Element => {
  const post = useSelector((state: Dictionary) => state.post);
  const { isErrorListing } = props;

  if (isErrorListing) {
    const errors = post.errors && Object.keys(post.errors).length ? Object.keys(post.errors) : [];
    return (
      <div className="post-components-menu">
        {errors.map(
          (item: string) =>
            !(ghostComponentIds(post).indexOf(item) !== -1) && (
              <>
                <ErrorComponentMenuItem item={post.errors[item]} />
              </>
            )
        )}
        {!errors || errors.length < 1 &&
          <div style={{ padding: '20px'}}>
          <p>No errors found.</p>
          </div>
        }
      </div>
    );
  }

  return (
    <div className="post-components-menu">
      {post.components.map(
        (item: GenericPostComponent, index: number) =>
          !(ghostComponentIds(post).indexOf(item.id) !== -1) && (
            <>
              <ComponentMenuItem index={index} item={item} />
            </>
          )
      )}
    </div>
  );
};

export { PostComponents };
