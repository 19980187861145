import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, message, Form, Row, Col, Collapse } from 'antd';
import { Dictionary } from '@onaio/utils';

import { actionSourceSave } from '../actions';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../configs/env';
import {
  AWS_S3_DETAILS,
  S3ParquetSourceParams,
  Source,
  UrlSourceCreationJobStatusTypes,
  sourceAsyncOperationsJobStatusTypes,
  updateS3UserSecretsForS3ParquetSourceParams,
} from '../../../configs/component-types';
import {
  startAsycS3ParquetSourceCreation,
  updateS3UserSecretsForS3ParquetSource,
} from '../helpers/helpers';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { UrlSourceCreationProgressSteps } from '../components/UrlSourceCreationProgressSteps';

const { Panel } = Collapse;

export interface SourceS3ParquetInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
  refresh?: boolean;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
  refresh: false,
};

const SourceS3ParquetInput: React.FC<SourceS3ParquetInputProps> = (
  props: SourceS3ParquetInputProps
) => {
  const { sourceActionSaveCreator, refresh } = props;
  const dispatch = useDispatch();
  const source = useSelector((store: Dictionary) => store.source);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});
  const [formInitVals, setInitialValues] = useState<Dictionary>({});

  useEffect(() => {
    if (refresh === true && source?.config?.connectionMetadata !== undefined) {
      setInitialValues({
        aws_s3_region: source?.config?.connectionMetadata?.aws_s3_region,
        aws_s3_bucket_name: source?.config?.connectionMetadata?.aws_s3_bucket_name,
        aws_s3_file_path: source?.config?.connectionMetadata?.aws_s3_file_path,
        aws_s3_access_key_id: '',
        aws_s3_secret_key_id: '',
      });
    }
  }, [refresh, source]);

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }
      setLoading(false);
      message.success('Source saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const createS3ParquetSource = ({ payload }: { payload: S3ParquetSourceParams }) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });

    triggerAsycSourceOperation({
      asynFunc: startAsycS3ParquetSourceCreation,
      asynFuncArgs: {
        sourceId: payload?.sourceId,
        cube_name: payload?.cube_name,
        aws_s3_details: payload?.aws_s3_details,
      },
      asyncTaskName: 'S3 Parquet Source Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const updateS3ParquetSourceSecrets = ({
    payload,
  }: {
    payload: updateS3UserSecretsForS3ParquetSourceParams;
  }) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });

    triggerAsycSourceOperation({
      asynFunc: updateS3UserSecretsForS3ParquetSource,
      asynFuncArgs: {
        ...payload,
      },
      asyncTaskName: 'Update S3 Parquet Source Secrets',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const isVisible = () => {
    const isCreatingNewParquetS3Source =
      source.inputType === 'aws_s3' && source.dimensions && source.dimensions.length === 0;
    if (
      (refresh === true && source?.config?.connectionMetadata !== undefined) ||
      isCreatingNewParquetS3Source
    ) {
      return true;
    }
    return false;
  };

  if (isVisible()) {
    return (
      <>
        <Form
          key={JSON.stringify(formInitVals)}
          initialValues={formInitVals}
          onFinish={(values) => {
            if (refresh === false) {
              const awsS3Details: AWS_S3_DETAILS = {
                aws_s3_region: values['aws_s3_region'],
                aws_s3_bucket_name: values['aws_s3_bucket_name'],
                aws_s3_file_path: values['aws_s3_file_path'],
              };
              if (
                values['aws_s3_access_key_id'] !== undefined &&
                values['aws_s3_access_key_id'].trim() !== '' &&
                values['aws_s3_secret_key_id'] !== undefined &&
                values['aws_s3_secret_key_id'].trim() !== ''
              ) {
                awsS3Details.aws_s3_credentials = {
                  aws_s3_access_key_id: values['aws_s3_access_key_id'],
                  aws_s3_secret_key_id: values['aws_s3_secret_key_id'],
                };
              }
              const payload: S3ParquetSourceParams = {
                sourceId: source.uuid,
                cube_name: source?.cube,
                aws_s3_details: awsS3Details,
              };
              createS3ParquetSource({
                payload,
              });
            }

            if (refresh === true) {
              const payload: updateS3UserSecretsForS3ParquetSourceParams = {
                sourceId: source.uuid,
                awsS3Credentials: {
                  aws_s3_access_key_id: values['aws_s3_access_key_id'],
                  aws_s3_secret_key_id: values['aws_s3_secret_key_id'],
                },
              };
              updateS3ParquetSourceSecrets({
                payload,
              });
            }
          }}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="aws_s3_region"
                label="Region"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="region" disabled={loading || refresh} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="aws_s3_bucket_name"
                label="Bucket Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="bucket name" disabled={loading || refresh} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                name="aws_s3_file_path"
                label="S3 File path"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="s3 file path" disabled={loading || refresh} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Collapse>
                <Panel key={1} header="AWS S3 Credentials (Optional fields)">
                  <Form.Item
                    name="aws_s3_access_key_id"
                    label="AWS Access Key ID"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.Password placeholder="access key id" disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    name="aws_s3_secret_key_id"
                    label="AWS Secret Key ID"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.Password placeholder="secret key id" disabled={loading} />
                  </Form.Item>
                  {refresh === true && (
                    <Col xs={24} sm={24}>
                      <Button id="btn-connect" htmlType="submit" type="primary" disabled={loading}>
                        Update Credentials
                      </Button>
                    </Col>
                  )}
                </Panel>
              </Collapse>
            </Col>
            {refresh !== true && (
              <Col xs={24} sm={24}>
                <Button id="btn-connect" htmlType="submit" type="primary" disabled={loading}>
                  Connect
                </Button>
              </Col>
            )}
          </Row>
        </Form>
        {loading === true && (
          <div className="input-field">
            <label>Progress</label>
            <UrlSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

SourceS3ParquetInput.defaultProps = defaultProps;

export { SourceS3ParquetInput };
