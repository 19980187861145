import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';

import App from './components/App/App';
import store from './configs/store';
import { onSigninCallback, userManager } from './configs/env';
window.store = store;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <Router>
    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
      <App />
    </AuthProvider>
    </Router>
    </Provider>
  </React.StrictMode>
);