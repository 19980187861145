import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router';

export const UnauthorizedPage: React.FC = () => {
  const history = useHistory();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          onClick={() => {
            history.push(`/`);
          }}
          type="primary"
        >
          Back Home
        </Button>
      }
    />
  );
};
