import { GroupFieldProps } from '../types';

export const chart: GroupFieldProps = {
  events: false,
  filters: true,
  groups: ['Settings', 'Sort', 'Filters', 'Export'],
  styles: [
    'Width',
    'Style',
    'Padding',
    'Series Labels',
    'Category Labels',
    'Data Labels',
  ],
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Settings',
      placeHolder: 'Chart Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Settings',
      placeHolder: 'Chart Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Settings',
      placeHolder: 'Chart Identifier Name',
    },

    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'slider',
      label: 'Height',
      property: 'height',
      min: 20,
      max: 1500,
      group: 'Settings',
    },
    {
      type: 'color',
      label: 'Background',
      property: 'backgroundColor',
      group: 'Style',
      section: 'Colors',
      placeHolder: '#ffffff',
      defaultValue: '#ffffff',
    },
    {
      type: 'slider',
      label: 'Height',
      property: 'height',
      min: 20,
      max: 1000,
      group: 'Height',
      section: 'Chart',
    },
    {
      type: 'slider',
      label: 'Bar Width',
      property: 'maxColumnWidth',
      min: 2,
      max: 50,
      group: 'Style',
      section: 'Chart',
    },
    {
      type: 'slider',
      label: 'Top',
      defaultValueType: 'array',
      property: 'padding',
      arrayIndex: 0,
      min: 0,
      max: 300,
      group: 'Padding',
    },
    {
      type: 'slider',
      label: 'Right',
      defaultValueType: 'array',
      property: 'padding',
      arrayIndex: 1,
      min: 0,
      max: 1700,
      group: 'Padding',
    },
    {
      type: 'slider',
      label: 'Bottom',
      defaultValueType: 'array',
      property: 'padding',
      arrayIndex: 2,
      min: 0,
      max: 300,
      group: 'Padding',
    },
    {
      type: 'slider',
      label: 'Left',
      defaultValueType: 'array',
      property: 'padding',
      arrayIndex: 3,
      min: 0,
      max: 300,
      group: 'Padding',
    },
    {
      type: 'color',
      label: 'Color',
      group: 'Category Labels',
      property: 'labelFill',
      section: 'Colors',
      placeHolder: 'Set Colors For Chart Labels',
    },
    {
      type: 'color',
      label: 'Color',
      group: 'Series Labels',
      property: 'labelFill',
      section: 'Colors',
      placeHolder: 'Set Colors For Chart Labels',
    },
    {
      type: 'number',
      label: 'Length',
      group: 'Series Labels',
      property: 'ytextMaxLength',
      min: 1,
      max: 50,
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Tick Count',
      property: 'seriesTickCount',
      min: 0,
      max: 100,
      group: 'Series Labels',
      placeHolder: '5',
      section: 'Label Ticks',
    },
    {
      type: 'number',
      label: 'Tick Interval',
      property: 'seriesTickInterval',
      min: 0,
      max: 100,
      group: 'Series Labels',
      placeHolder: '5',
      section: 'Label Ticks',
    },
    {
      type: 'number',
      label: 'Length',
      group: 'Category Labels',
      property: 'xtextMaxLength',
      min: 1,
      max: 50,
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Tick Count',
      property: 'categoryTickCount',
      min: 0,
      max: 200,
      group: 'Category Labels',
      section: 'Label Ticks',
      placeHolder: '5',
    },
    {
      type: 'number',
      label: 'Tick Interval',
      property: 'categoryTickInterval',
      min: 0,
      max: 100,
      group: 'Category Labels',
      section: 'Label Ticks',
    },
    {
      type: 'number',
      label: 'Rotation',
      property: 'yLabelRotate',
      min: 0,
      max: 360,
      group: 'Series Labels',
      section: 'Label rotation',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Offset',
      property: 'yLabelOffset',
      min: 0,
      max: 100,
      group: 'Series Labels',
      section: 'Label offset',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Rotation',
      property: 'xLabelRotate',
      min: 0,
      max: 360,
      step: 1,
      group: 'Category Labels',
      section: 'Label rotation',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Offset',
      property: 'xLabelOffset',
      min: 0,
      max: 50,
      group: 'Category Labels',
      section: 'Label offset',
      placeHolder: '0',
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Settings',
    },
    {
      type: 'dimension',
      label: 'Context',
      property: 'context',
      group: 'Settings',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },
    {
      type: 'dimension',
      label: 'Sort Field',
      property: 'sortField',
      showMeasures: true,
      showDimensions: true,
      group: 'Sort',
      placeHolder: 'Set value to Sort by',
      limitSortOptions: true,
    },
    {
      type: 'select',
      label: 'Sort order',
      property: 'sortOrder',
      options: [
        { value: 'desc', label: 'Descending' },
        { value: 'asc', label: 'Ascending' },
      ],
      group: 'Sort',
      placeHolder: 'Set Order to Sort by',
    },
    {
      type: 'select',
      label: 'Type',
      property: 'chartType',
      options: [
        { value: 'area', label: 'Area' },
        { value: 'bar', label: 'Bar' },
        { value: 'column', label: 'Column' },
        { value: 'line', label: 'Line' },
        { value: 'pie', label: 'Pie' },
        { value: 'polar', label: 'Polar' },
        { value: 'radar', label: 'Radar' },
        { value: 'scatterPlot', label: 'Scatter' },
        { value: 'bubble', label: 'Bubble (beta)' },
        { value: 'treeMap', label: 'Tree Map (beta)' },
        { value: 'barStack', label: 'Stacked Bar' },
        { value: 'areaStack', label: 'Stacked Area' },
        { value: 'columnStack', label: 'Stacked Column' },
      ],
      placeHolder: 'Choose Chart type',
      group: 'Settings',
      dependsOn: [
        {
          property: 'source',
          value: '*',
        },
      ],
    },
    {
      type: 'select',
      label: 'Granularity',
      property: 'granularity',
      dependsOn: [
        { property: 'xAxis', value: 'time', nestedDataTypeKey: 'dataType' },
      ],
      options: [
        // day, month, year, week, hour, minute, second, null
        { value: 'second', label: 'Second' },
        { value: 'minute', label: 'Minute' },
        { value: 'hour', label: 'Hour' },
        { value: 'day', label: 'Day' },
        { value: 'week', label: 'Week' },
        { value: 'month', label: 'Month' },
        { value: 'quarter', label: 'Quarter' },
        { value: 'year', label: 'Year' },
      ],
      group: 'Settings',
      placeHolder: 'Select time granularity option',
    },
    {
      type: 'select',
      label: 'Date Format',
      property: 'dateFormat',
      dependsOn: [
        { property: 'xAxis', value: 'time', nestedDataTypeKey: 'dataType' },
        { property: 'dateFormatText', value: '!' },
      ],
      options: [
        { value: 'yyyy', label: 'YYYY' },
        { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
        { value: 'MM-dd-yyy', label: 'MM-DD-YYYY' },
        { value: 'dd-MM-yyyy', label: 'DD-MM-YYYY' },
        { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
        { value: 'MM-yyyy', label: 'MM-YYYY' },
        { value: 'MM', label: 'MM' },
      ],
      group: 'Settings',
      labelLink:
        'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
      placeHolder: 'Select time granularity option',
    },
    {
      type: 'text',
      label: 'Date Format',
      property: 'dateFormat',
      dependsOn: [
        { property: 'xAxis', value: 'time', nestedDataTypeKey: 'dataType' },
        { property: 'dateFormatText', value: true },
      ],
      group: 'Settings',
      labelLink:
        'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
      placeHolder: 'Select time granularity option',
    },
    {
      type: 'switch',
      label: 'Use custom date format',
      property: 'dateFormatText',
      dependsOn: [
        { property: 'xAxis', value: 'time', nestedDataTypeKey: 'dataType' },
      ],
      group: 'Settings',
      placeHolder: 'Toggle Format field to text',
    },
    {
      type: 'slider',
      label: 'Circle Size',
      property: 'circleSize',
      min: 1,
      max: 20,
      step: 1,
      group: 'Style',
      section: 'Scale',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'chartType',
          value: ['scatterPlot'],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Circle Labels',
      property: 'circleLabels',
      group: 'Style',
      section: 'Label visibility',
      dependsOn: [
        {
          property: 'chartType',
          value: ['scatterPlot'],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Hide grid lines',
      property: 'gridLines',
      section: 'Chart',
      group: 'Style',
    },
    {
      type: 'switch',
      label: 'Hide tooltip',
      property: 'hideTooltip',
      section: 'Chart',
      group: 'Style',
    },
    {
      type: 'switch',
      label: 'Show Legend',
      property: 'legend',
      group: 'Style',
      section: 'Chart',
    },
    {
      type: 'number',
      label: 'Min Value',
      property: 'yAxisMin',
      group: 'Series Labels',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Max Value',
      property: 'yAxisMax',
      group: 'Series Labels',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Min Value',
      property: 'xAxisMin',
      group: 'Category Labels',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Max Value',
      property: 'xAxisMax',
      group: 'Category Labels',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Limit',
      property: 'limit',
      group: 'Sort',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'number',
      label: 'Offset',
      property: 'offset',
      group: 'Sort',
      section: 'Scale',
      placeHolder: '0',
    },
    {
      type: 'switch',
      label: 'Allow data export',
      property: 'dataExport',
      group: 'Export',
      section: 'Chart',
      placeHolder: '0',
    },
    {
      type: 'switch',
      label: 'Allow component image export',
      property: 'componentImageExport',
      group: 'Export',
      section: 'Chart',
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Bubble Min',
      property: 'bubbleMin',
      min: 0,
      max: 100,
      step: 1,
      group: 'Style',
      section: 'Scale',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'slider',
      label: 'Bubble Max',
      property: 'bubbleMax',
      min: 0,
      max: 100,
      step: 1,
      group: 'Style',
      section: 'Scale',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'slider',
      label: 'Line Width',
      property: 'lineWidth',
      min: 0,
      max: 10,
      step: 0.1,
      group: 'Style',
      section: 'Colors',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'color',
      label: 'Stroke Color',
      property: 'strokeColor',
      group: 'Style',
      section: 'Colors',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'slider',
      label: 'Stroke Opacity',
      property: 'strokeOpacity',
      min: 0,
      max: 10,
      step: 0.1,
      group: 'Style',
      section: 'Colors',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'dimension',
      label: 'Dimension',
      property: 'xAxis',
      defaultValue: {
        value: '',
        type: 'dimension',
      },
      expectedType: 'dimension',
      objectKey: 'value',
      setNestedType: true,
      setNestedDataType: true,
      typeKey: 'type',
      nestedDataTypeKey: 'dataType',
      showMeasures: true,
      showDimensions: true,
      group: 'Settings',
      placeHolder: 'Select Dimension',
      dependsOn: [
        {
          property: 'chartType',
          value: '*',
        },
      ],
    },
    {
      type: 'array',
      label: '',
      property: 'series',
      group: 'Settings',
      groups: ['Series'],
      addButtonText: 'Add Series',
      dependsOn: [{ property: 'chartType', value: '*' }],
      settings: [
        {
          label: 'Series',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Series',
      collapse: false,
      collapseProperty: 'value',
      collapseParent: 'series',
      defaultValue: {
        color: '#75A3FA',
        value: '',
        value_type: '',
        groupByColors: [],
        colorMode: 'defaultColor',
        colorScale: 'ckmeans',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Series',
          group: 'Series',
          property: 'value',
          showMeasures: true,
          showDimensions: true,
          parents: ['series'],
          setType: true,
          typeKey: 'value_type',
          expectedDataType: 'number',
          placeHolder: 'Select numeric value',
        },
        {
          type: 'selectSteps',
          label: 'Color mode',
          property: 'colorMode',
          group: 'Series',
          parents: ['series'],
          options: [
            { value: 'defaultColor', label: 'Simple' },
            { value: 'generatedStepsBrakes', label: 'Steps' },
          ],
          dependsOn: [
            {
              parent: 'series',
              property: 'chartType',
              value: [
                'bar',
                'column',
                'polar',
                'radar',
                'scatterPlot',
                'treeMap',
              ],
            },
            {
              property: 'groupBy',
              value: '!',
              parent: 'series',
            },
          ],
          placeHolder: 'Coloring Method',
        },
        {
          type: 'selectSteps',
          label: 'Color mode',
          property: 'colorMode',
          group: 'Series',
          parents: ['series'],
          options: [{ value: 'defaultColor', label: 'Simple' }],
          dependsOn: [
            {
              parent: 'series',
              property: 'chartType',
              value: [
                'area',
                'line',
                'bubble',
                'treeMap',
                'barStack',
                'areaStack',
                'columnStack',
                'pie',
              ],
            },
            {
              property: 'groupBy',
              value: '!',
              parent: 'series',
            },
          ],
          placeHolder: 'Coloring Method',
        },
        {
          type: 'color',
          label: 'Color',
          group: 'Series',
          property: 'color',
          parents: ['series'],
          placeHolder: 'Series Property color',
          dependsOn: [
            {
              property: 'colorMode',
              value: ['defaultColor'],
            },

            {
              property: 'groupBy',
              value: '!',
              parent: 'series',
            },
          ],
        },
        {
          type: 'select',
          label: 'Color Scale',
          cols: 24,
          popOver: true,
          property: 'colorScale',
          group: 'Series',
          options: [
            { value: 'ckmeans', label: 'Jenks Natural Breaks' },
            { value: 'linear', label: 'Equal Interval' },
            { value: 'quantile', label: 'Quantiles' },
          ],
          parents: ['series'],
          placeHolder: 'Jenks Natural Breaks',
          dependsOn: [
            {
              property: 'colorMode',
              value: ['!', 'defaultColor'],
            },
            {
              property: 'colorMode',
              value: '*',
            },
          ],
        },

        {
          type: 'color-range',
          label: 'color',
          property: 'colorRange',
          group: 'Series',
          parents: ['series'],
          hideClassInput: true,
          dependsOn: [
            {
              property: 'colorMode',
              value: ['!', 'defaultColor'],
            },
            {
              property: 'colorMode',
              value: '*',
            },
          ],
        },

        {
          type: 'switch',
          label: 'Reverse Colors',
          property: 'reverseColors',
          parents: ['series'],
          cols: 12,
          group: 'Series',
          size: 'small',
          dependsOn: [
            {
              property: 'colorRange',
              value: '*',
            },
          ],
        },
        {
          type: 'slider',
          group: 'Series',
          groups: ['Series'],
          label: 'Opacity',
          property: 'opacity',
          min: 0.1,
          max: 1,
          step: 0.1,
          section: 'Colors',
          placeHolder: '0',
        },
        {
          type: 'dimension-group-by',
          label: 'Group By',
          group: 'Series',
          groups: ['Series'],
          showMeasures: false,
          showDimensions: true,
          property: 'groupBy',
          parents: ['series'],
          setType: true,
          typeKey: 'groupBy_type',
          expectedType: 'dimension',
          dependsOn: [
            {
              property: 'colorMode',
              value: ['!', 'generatedStepsBrakes'],
            },
          ],
          placeHolder: 'Series Group-by Field',
        },
        {
          type: 'switch',
          label: 'Do not plot null values',
          group: 'Series',
          groups: ['Series'],
          property: 'disableFillNullValues',
          dependsOn: [
            {
              property: 'colorMode',
              value: ['!', 'generatedStepsBrakes'],
            },
          ],
        },
        {
          type: 'color-range',
          label: 'color',
          property: 'colorRange',
          hideClassInput: true,
          parents: ['series'],
          group: 'Series',
          groups: ['Series'],
          dependsOn: [
            {
              property: 'groupBy',
              value: '*',
            },
            {
              property: 'colorMode',
              value: 'defaultColor',
            },
          ],
        },

        {
          type: 'array',
          label: 'Colors',
          property: 'groupByColors',
          group: 'Series',
          groups: ['Series'],
          parents: ['series'],
          settings: [
            {
              label: 'Series',
              dependsOn: [],
              showCollapse: false,
            },
          ],
          itemLabel: 'Color',
          collapseProperty: 'value',
          collapseParent: 'groupByColors',
          dependsOn: [
            { property: 'groupBy', value: '*' },
            {
              property: 'colorMode',
              value: ['!', 'generatedStepsBrakes'],
            },
          ],
          fields: [
            {
              type: 'group-by',
              label: 'Property',
              group: 'Series',
              groups: ['Series'],
              property: 'value',
              parents: ['series', 'groupByColors'],
              placeHolder: 'Series Group-by Property',
            },
            {
              type: 'color',
              label: 'Color',
              property: 'color',
              group: 'Series',
              groups: ['Series'],
              parents: ['series', 'groupByColors'],
              placeHolder: 'Series Group-by Property Color',
            },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Percentage chart',
      property: 'showPercentageChart',
      group: 'Settings',
      dependsOn: [
        {
          property: 'chartType',
          value: ['barStack', 'columnStack', 'areaStack', 'bar', 'column'],
        },
      ],
    },
    {
      type: 'dimension',
      label: 'Bubble size',
      defaultValue: {
        value: '',
        source_type: '',
      },
      setNestedType: true,
      setNestedDataType: true,
      typeKey: 'source_type',
      nestedDataTypeKey: 'dataType',
      showMeasures: true,
      showDimensions: true,
      group: 'Settings',
      expectedDataType: 'number',
      property: 'bubbleProperties',
      objectKey: 'bubbleSize',
      // setType: true,
      placeHolder: 'Buble size Property',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'dimension',
      label: 'Custom Tooltip Field',
      defaultValue: {
        source_type: '',
      },
      setNestedType: true,
      setDataType: true,
      typeKey: 'source_type',
      dataTypeKey: 'dataType',
      showMeasures: true,
      showDimensions: true,
      group: 'Group',
      property: 'customToolTipProperties',
      // setType: true,
      placeHolder: 'Custom Tooltip Property',
      objectKey: 'customToolTipField',
      dependsOn: [
        {
          property: 'chartType',
          value: ['bubble'],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Show Data Labels',
      property: 'showBarLabels',
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'chartType',
          value: ['barStack', 'columnStack', 'bar', 'column', 'treeMap'],
        },
      ],
    },
    {
      type: 'slider',
      label: 'Font size',
      property: 'fontSize',
      min: 1,
      max: 50,
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },

    {
      type: 'slider',
      label: 'Font Weight',
      min: 100,
      step: 100,
      max: 900,
      property: 'fontWeight',
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },

    {
      type: 'checkbox',
      label: 'Format Labels',
      property: 'formatLabels',
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'chartType',
          value: ['barStack', 'columnStack', 'areaStack', 'bar', 'column'],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },

    {
      type: 'switch',
      label: 'disable auto contrast',
      property: 'disableAutoGeneratedColors',
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },
    {
      type: 'color',
      label: 'Color',
      property: 'barLabelColor',
      placeHolder: 'Select color',
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'disableAutoGeneratedColors',
          value: true,
        },
      ],
    },
    {
      type: 'slider',
      label: 'Offset',
      property: 'labelOffset',
      min: -100,
      max: 100,
      group: 'Data Labels',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },
    {
      type: 'slider',
      label: 'Rotate',
      property: 'barLabelRotate',
      min: 0,
      max: 360,
      group: 'Data Labels',
      step: 1,
      placeHolder: '0',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },
    {
      type: 'select',
      label: 'Text Align',
      property: 'barLabelTextAlign',
      group: 'Data Labels',
      options: [
        { value: 'right', label: 'Right' },
        { value: 'center', label: 'Center' },
        { value: 'left', label: 'Left' },
      ],
      placeHolder: 'Bar Label Text Align',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },
    {
      type: 'select',
      label: 'Base line',
      property: 'barLabelTextBaseLine',
      group: 'Data Labels',
      options: [
        { value: 'bottom', label: 'Bottom' },
        { value: 'start', label: 'Start' },
        { value: 'middle', label: 'Middle' },
        { value: 'top', label: 'Top' },
      ],
      placeHolder: 'Bar Label Text Align',
      dependsOn: [
        {
          property: 'chartType',
          value: [
            'barStack',
            'columnStack',
            'areaStack',
            'bar',
            'column',
            'treeMap',
          ],
        },
        {
          property: 'showBarLabels',
          value: true,
        },
      ],
    },
    {
      type: 'switch',
      label: 'Hide series labels',
      property: 'hideSeriesLabel',
      group: 'Series Labels',
      section: 'Label visibility',
    },
    {
      type: 'switch',
      label: 'Hide category labels',
      property: 'hideCategoryLabel',
      group: 'Category Labels',
      section: 'Label visibility',
    },
    {
      type: 'array',
      label: '',
      property: 'filters',
      group: 'Filters',
      groups: ['Filter'],
      addButtonText: 'Add Filter',
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: true,
        },
      ],
      collapse: false,
      itemLabel: 'Filter',
      collapseProperty: '1',
      collapseParent: 'filters',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Series',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          group: 'Options',
          label: 'Inherit filter value',
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          property: 'expose',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Options',
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Options',
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<'],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'text',
          label: 'Name',
          group: 'Options',
          property: 'name',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          property: 'cols',
          group: 'Options',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable cascading filters',
      group: 'Filters',
      property: 'cascade',
    },
    // {
    //   type: 'array',
    //   group: 'Annotations',
    //   property: 'annotations',
    //   label: 'Annotations',
    //   groups: ['Annotation'],
    //   itemLabel: 'Annotation',
    //   collapseProperty: 'value',
    //   collapseParent: 'annotations',
    //   defaultValue: {
    //     value: '',
    //   },
    //   fields: [
    //     {
    //       type: 'text',
    //       property: 'value',
    //       group: 'Annotation',
    //       label: 'Annotation',
    //       parents: ['annotations'],
    //       placeHolder: 'Set Annotation Value',
    //     },
    //   ],
    // },
  ],
};
