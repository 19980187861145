import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, ConfigProvider } from 'antd';
import {
  getComponentById,
  getComponentSource,
} from '../../../reducers/selectors/post';
import { actionComponentFilterEdit } from '../actions';
import { useFetchSingleFilterData } from '../hooks';
import { Operator, OPERATORS } from '../../App/Filter';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  buildUniqueFilterOptionValues,
  InputComponentGetter,
} from './utils/utils';
import {
  FilterOption,
  GenericPostComponent,
} from '../../../configs/component-types';

/** selector factory */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

export interface FilterControlProps {
  componentIndex: number; // index of the post component
  componentId: string; // id of the post component
  filterIndex: number; // index of the filter
  editFilterActionCreator?: typeof actionComponentFilterEdit; // redux action to edit filter
}

/** default component props */
const defaultProps: Partial<FilterControlProps> = {
  editFilterActionCreator: actionComponentFilterEdit,
};

const FilterControl: React.FC<FilterControlProps> = (
  props: FilterControlProps
) => {
  const dispatch = useDispatch();
  // memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);
  const [operatorObj, setOperatorObj] = useState<Operator>();
  const [filterValue, setFilterValue] = useState<string | undefined>();
  const { componentIndex, componentId, filterIndex, editFilterActionCreator } =
    props;
  /* @ts-ignore */
  const component = useSelector((state: any) =>
    selectComponentById(state, { componentId })
  );
  /* @ts-ignore */
  const source = useSelector((state: any) =>
    selectComponentSource(state, { componentId })
  );
  const { cube, filters } = component as GenericPostComponent;
  const labels = source?.labels;
  const [, data] = useFetchSingleFilterData(componentId, filterIndex);
  const property = filters?.[filterIndex][1] as string;
  const separateTextByCommas = filters?.[filterIndex]?.['separateTextByCommas'];
  const dataType = filters?.[filterIndex]['dataType'];
  const showActualDateFormat = filters?.[filterIndex]['actualDateFormat'];
  const operator = filters?.[filterIndex][0] as string;
  const firstRangeValue = filters?.[filterIndex][3];
  const secondRangeValue = filters?.[filterIndex][4];
  const rangeOperators = ['notInDateRange', 'inDateRange'].includes(
    operator || ''
  );
  const textValue = filters?.[filterIndex][2];
  const renderText = filters?.[filterIndex]?.['showTextField'];
  const labelledProperty = labels?.[property]?.title || property;
  const filterObj = component?.filters?.[filterIndex] as FilterOption;

  const heading =
    property && operator
      ? `${dataType === 'time' ? '' : labelledProperty} ${operatorObj?.name}`
      : '';
  const getIndex = data.findIndex(
    (record) => record[`${source?.cube}.${property}`] === filterValue
  );
  const rangeSliderValues: [number, number] = [0, 0];
  data.forEach((item, key) => {
    if (item[`${cube}.${property}`] === filterObj?.[3]) {
      rangeSliderValues[0] = key;
    }
    if (item[`${cube}.${property}`] === filterObj?.[4]) {
      rangeSliderValues[1] = key;
    }
  });
  const [sliderIndex, setSliderIndex] = useState<number>(getIndex);
  const [sliderValue, setSliderValue] = useState<number | number[]>(
    sliderIndex || 0
  );
  const [rangeFilterValue, setRangeFilterValue] =
    useState<[number, number]>(rangeSliderValues);

  useEffect(() => {
    if (rangeOperators) {
      setRangeFilterValue(rangeSliderValues);
    } else {
      setSliderIndex(getIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  useEffect(() => {
    if (Array.isArray(sliderValue)) {
      setRangeFilterValue(sliderValue as [number, number]);
    } else {
      setSliderIndex(sliderValue);
    }
  }, [sliderValue]);

  useEffect(() => {
    if (filterValue) {
      setSliderIndex(getIndex);
    }
  }, [filterValue, getIndex]);

  useEffect(() => {
    if (rangeOperators) {
      setRangeFilterValue(rangeSliderValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRangeValue, secondRangeValue]);

  useEffect(() => {
    if (operator) {
      const operatorObj = OPERATORS.find((op) => op.value === operator);
      if (operatorObj) {
        setOperatorObj(operatorObj);
      }
    }
  }, [operator]);

  useEffect(() => {
    if (textValue) {
      setFilterValue(textValue);
    }
  }, [textValue]);

  if (!component || !source) return null;

  // create unique filter option values splitting on comma
  /* @ts-ignore */
  const optionsArray: string[] = buildUniqueFilterOptionValues(
    data,
    source,
    property,
    separateTextByCommas || false
  );
  const textInputOperators = ['>', '<'].includes(operator);
  // params to build inputs for the various options we got
  const inputTypes = {
    slider: filterObj?.slider,
    datePicker: filterObj?.datePicker,
    textInput: textInputOperators || renderText,
  };
  const stateMethods = {
    setFilterValue,
    setSliderValue,
    dispatch,
    editFilterActionCreator,
  };
  const configs = {
    componentIndex,
    filterIndex,
    filterObj,
    filterObjs: filters as FilterOption[],
    sliderValue,
    filterValue,
    property,
    cube,
    sliderIndex,
    showActualDateFormat,
    dataType,
    rangeFilterValue,
    cascade: component?.cascade,
  };
  const filterInputsData = {
    data,
    optionsArray,
  };
  return (
    <Col xs={Number(filterObj?.cols) | 8}>
      <div className='filter-control'>
        <label>{filterObj?.['name'] || heading}</label>
        {['isNull', 'notNull'].includes(operator)
          ? ''
          : InputComponentGetter(
              rangeOperators,
              inputTypes,
              stateMethods,
              configs,
              filterInputsData
            )}
      </div>
    </Col>
  );
};

FilterControl.defaultProps = defaultProps;

export { FilterControl };
