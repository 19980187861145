import React, { useEffect, useState } from 'react';
import { Select, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME, QUERY_API } from '../../../../../configs/env';
import { isGlobalFilter } from '../../helpers/helpers';
import { dimensionInputQueryBuilder } from './utils/utils';
import { FilterOption } from '../../../../../configs/component-types';
import { generateJWTToken } from '../../../utils';
const { Option } = Select;

export interface DimensionValueInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const DimensionValueInput: React.FC<DimensionValueInputProps> = (
  props: DimensionValueInputProps
) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, placeHolder, group } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const [filterValues, setFilterValues] = useState<Dictionary[]>([]);
  const [value, setValue] = useState('');
  const [granularity, setGranularity] = useState('');
  const [disableClear, setDisableClear] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(false);
  const [selectMode, setSelectMode] = useState<boolean | undefined>(false);
  const [isTimeProperty, setIsTimeProperty] = useState<boolean | undefined>(false);

  let source = '';
  let targetProperty = '';
  let showActualDateFormat = '';
  let filterGranularity = '';
  let filterObjects: FilterOption[] = [];
  let cascadeFlag = component?.cascade;

  if (!parents) {
    source = component?.source;
  }
  if (parents && parents.length === 1 && itemIndex !== undefined) {
    source = component?.source;
    targetProperty = component?.[parents[0]]?.[itemIndex]?.[1];
    filterObjects = component?.[parents[0]];
    showActualDateFormat = component?.[parents[0]]?.[itemIndex]?.['actualDateFormat'];
    filterGranularity = component?.[parents[0]]?.[itemIndex]?.['granularity'];
  }
  if (parents && parents.length === 2 && childIndex !== undefined && itemIndex !== undefined) {
    source = component?.[parents[0]]?.[childIndex]?.source;
    targetProperty = component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[1];
    filterObjects = component?.[parents[0]]?.[childIndex]?.[parents[1]];
    showActualDateFormat =
      component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.['actualDateFormat'];
    cascadeFlag = component?.[parents[0]]?.[childIndex]?.cascade;
  }

  // button: get dimensions value for event
  if (!source && childIndex !== undefined && itemIndex !== undefined) {
    source = component.source;
    targetProperty = component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex].filter;
  }

  const sourceObj = post.sources[source];
  const sourceId = sourceObj?.uuid;
  const id = component.id;
  const filterObjProp = filterObjects?.map((filterItem) => filterItem[2]);

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentLocation =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex];
      setValue(currentLocation?.[property]);
      setDisableClear(currentLocation?.disableClear);
      setSelectMode(currentLocation.multiple);
      if (currentLocation?.dataType === 'time') {
        setIsTimeProperty(true);
      }
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentLocation = post.components[componentIndex]?.[parents[0]]?.[itemIndex];
      setValue(currentLocation?.[property]);
      setDisableClear(currentLocation?.disableClear);
      setSelectMode(currentLocation?.multiple);
      if (currentLocation?.dataType === 'time') {
        setIsTimeProperty(true);
        setGranularity(currentLocation.granularity);
      }
    }
    if (!parents) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }

    // get dimension value input values from store
    if (!(filterValues?.length > 0) && group === 'Filter') {
      // eslint-disable-next-line no-debugger
      let filterData;
      if (childIndex !== undefined && parents?.length > 1) {
        const nestedComponent = component?.[parents[0]]?.[childIndex];
        const { id } = nestedComponent;
        filterData = post.data?.[`${id}-filter-${itemIndex}`];
      } else {
        const { id } = component;
        filterData = post.data?.[`${id}-filter-${itemIndex}`];
      }
      if (filterData?.length) {
        setFilterValues(filterData);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  useEffect(() => {
    if (source && targetProperty) {
      setLoading(true);
      const queryConfigs = {
        componentIndex,
        id,
        sourceId,
        cube: sourceObj.cube,
        targetProperty,
        granularity,
        type: component.type,
        refreshKey: sourceObj?.refresh_key,
      };
      const stateSetter = {
        setLoading,
        setFilterValues,
      };
      if (filterObjects.length > 1 && itemIndex && cascadeFlag) {
        dimensionInputQueryBuilder(filterObjects, queryConfigs, stateSetter);
      } else {
        const dimensions = [`${sourceObj.cube}.${targetProperty}`];
        const query =
          filterGranularity && component.type === 'filter'
            ? {
                timeDimensions: [
                  {
                    dimension: `${sourceObj.cube}.${targetProperty}`,
                    granularity: filterGranularity,
                  },
                ],

                measures: [],
                limit: 20000,
                renewQuery: true,
              }
            : {
                dimensions: dimensions,
                measures: [],
                limit: 20000,
                renewQuery: true,
              };
        generateJWTToken({
          sourceId: sourceId,
          cubeName: sourceObj?.cube,
          refreshKey: sourceObj?.refresh_key,
        }).then(res => {
          const token = res;
          const headers = {
            [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
            'Content-Type': 'application/json'
          };
          const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
          service.create({
            query: query,
          })
          .then((data) => {
            const response = data as Dictionary;
            const queryResult = response.data as Dictionary[];
            setLoading(false);
            setFilterValues(queryResult);
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetProperty, source, granularity, cascadeFlag, JSON.stringify(filterObjProp)]);

  return (
    <Row gutter={10}>
      <Col sm={6}>
      <label>{label}</label>
      </Col>
      <Col sm={18}>
      <Select
        placeholder={placeHolder ? placeHolder : undefined}
        loading={loading}
        disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
        showSearch
        allowClear={disableClear ? false : true}
        mode={selectMode ? 'multiple' : undefined}
        value={value ? value : undefined}
        getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
        onChange={(selectValue) => {
          if (selectValue) {
            setValue(selectValue);
          } else {
            // if cascade filters and filter value has been cleared.
            // Clear consequtive filter values
            if (cascadeFlag && filterObjects?.length > 1 && itemIndex !== undefined) {
              filterObjects.forEach((filterItem: FilterOption, key) => {
                if (itemIndex < key) {
                  dispatch(
                    actionPostComponentSettingEdit({
                      parents: parents,
                      property: property,
                      componentIndex: componentIndex,
                      itemIndex: key,
                      value: undefined,
                      childIndex: childIndex,
                    })
                  );
                }
              });
            }
          }
          dispatch(
            actionPostComponentSettingEdit({
              parents: parents,
              property: property,
              componentIndex: componentIndex,
              itemIndex: itemIndex,
              childIndex: childIndex,
              value: selectValue,
            })
          );
        }}
      >
        {filterValues?.length > 0 &&
          filterValues.map((item: Dictionary, index: number) => (
            <Option key={index} value={item[`${sourceObj?.cube}.${targetProperty}`]}>
              {isTimeProperty && !showActualDateFormat
                ? item[`${sourceObj?.cube}.${targetProperty}`]?.split('T')[0]
                : item[`${sourceObj?.cube}.${targetProperty}`]}
            </Option>
          ))}
      </Select>
      </Col>
    </Row>
  );
};

export { DimensionValueInput };
