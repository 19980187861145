import React from 'react';
import { Result } from 'antd';

export const MaintenancePage: React.FC = () => {
  return (
    <Result
      status="500"
      title="Site Under Maintenance"
      subTitle="Sorry, the site is currently undergoing maintenance. We'll be back shortly."
    />
  );
};
