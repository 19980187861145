import { Dictionary } from '@onaio/utils';
import { message } from 'antd';
import { Dispatch } from 'redux';
import { AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME, QUERY_API } from '../../../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../../../configs/constants';
import { AkukoAPIService } from '../../../../../../../services/serviceClass';
import { actionComponentSourceQuery } from '../../../../../actions';
import { generateJWTToken } from '../../../../../utils';

/**
 *
 * @param field string dimension to filter by
 * @param indexes componentid, id and sourceid
 * @param setLoading setloading state method
 * @param setSelectValues select value options to state
 * @param groupByColors existing groupbycolors from store
 */
export const createSourceBasedOnGroupBy = (
  field: string,
  indexes: Dictionary,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  cubeName: string,
  refreshKey: number | string
): void => {
  const { sourceId, groupById, filters } = indexes;
  const query = {
    dimensions: [field],
    measures: [],
    limit: 20000,
    renewQuery: true,
    filters: filters,
  };
  generateJWTToken({
    sourceId: sourceId,
    cubeName: cubeName,
    refreshKey: refreshKey,
  }).then(res => {
    const token = res;
    const headers = {
      [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
      'Content-Type': 'application/json'
    };
    const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
    service.create({
      query: query,
    })
    .then((data) => {
      const response = data as Dictionary;
      const queryResult = response?.data as Dictionary[];
      dispatch(actionComponentSourceQuery(queryResult, groupById, query, null));
    })
    .catch((err) => {
      message.error(err.message || ERROR_GENERIC);
    });
  })
};
