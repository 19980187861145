import React from 'react';
import { useSelector } from 'react-redux';
import { ENV, AKUKO_APP_DOMAINS, AKUKO_APP_SITE_UNDER_MAINTENANCE } from '../../../configs/env';
import { domains } from '../../../configs/domains';

export const RootComponent = (props) => {
  const user = useSelector((state) => state.user);
  const domain = window.location.hostname;
  const subdomain = domain.split('.akuko.io');
  const isAkukoAppDomain =
    AKUKO_APP_DOMAINS.split(',').find((x) => domain.includes(x.trim())) !== undefined;
  if (AKUKO_APP_SITE_UNDER_MAINTENANCE === 'true') {
    window.location.href = `/maintenance`;
  } else {
    if (!user.email) {
      if (isAkukoAppDomain) {
        // this is not a custom domain
        if (subdomain[0] === 'app' || subdomain[0] === 'localhost' || ENV === 'stg') {
          window.location.href = `/dashboard`;
        } else {
          window.location.href = `/space/${subdomain[0]}`;
        }
      }
      if (!isAkukoAppDomain) {
        // this is a custom domain
        Object.keys(domains).forEach((key) => {
          if (domain === key) {
            window.location.href = `https://${domain}/${domains[key]}`;
          }
        });
      }
    } else {
      if (isAkukoAppDomain) {
        // behavior when user is authenticated
        if (subdomain[0] === 'app' || subdomain[0] === 'localhost' || ENV === 'stg') {
          window.location.href = `/dashboard`;
        } else {
          window.location.href = `/space/${subdomain[0]}`;
        }
      }
      if (!isAkukoAppDomain) {
        // this is a custom domain
        Object.keys(domains).forEach((key) => {
          if (domain === key) {
            window.location.href = `https://${domain}/${domains[key]}`;
          }
        });
      }
    }
  }
  return null;
};
