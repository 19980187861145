import React, { useEffect, useRef, useState } from 'react';
import { Select, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../../actions';
import { clearGroupByColor, prePopulateGroupByColor } from './helpers/utils/utils';
import { createSourceBasedOnGroupBy } from './helpers/dataLoaders/dataLoaders';
const { Option } = Select;

export interface DimensionGroupByProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

export interface prevValueRefProps {
  currentValue: string;
  itemIndex: number | undefined;
  id: string;
}

const DimensionGroupBy: React.FC<DimensionGroupByProps> = (props: DimensionGroupByProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, setType, setNestedType, typeKey, objectKey, parents } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const { id, cube } = component;
  const groupById = `${id}-group-by-${itemIndex}`;
  const [value, setValue] = useState<string>('');
  //the useRef Hook allows you to persist data between renders
  const prevValueRef = useRef<prevValueRefProps>();

  let source = '';
  if (!parents) {
    source = component?.source;
  }
  if (parents && parents.length === 1 && parents[0] === 'layers' && itemIndex !== undefined) {
    source = component?.[parents[0]]?.[itemIndex]?.source;
  }
  if (parents && parents.length === 1 && parents[0] !== 'layers' && itemIndex !== undefined) {
    source = component?.source;
  }
  if (parents && parents.length === 2 && childIndex !== undefined && itemIndex !== undefined) {
    source = component?.[parents[0]]?.[childIndex]?.source;
  }
  const sourceObj = post.sources[source];

  useEffect(() => {
    let currentValue;
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      currentValue = component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }

    if (parents?.length === 1 && itemIndex !== undefined) {
      currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      const groupByColorsLength = component?.[parents[0]]?.[itemIndex]?.groupByColors?.length;
      const colorRange = component?.[parents[0]]?.[itemIndex]?.colorRange;

      // clear group by colors if the group by is changed or cleared
      const { current } = prevValueRef;
      const doClear =
        current?.currentValue !== currentValue &&
        itemIndex === current?.itemIndex &&
        current?.id === component.id;

      if (doClear) {
        if (value && property === 'groupBy') {
          // clear groupByColors array
          const indexes = {
            componentIndex: componentIndex,
            id: id,
            sourceId: component.source,
            groupById: groupById,
          };
          clearGroupByColor(
            {
              componentIndex,
              itemIndex,
            },
            dispatch
          );
          // get source for the newly selected field
          createSourceBasedOnGroupBy(
            `${cube}.${value}`,
            indexes,
            dispatch,
            sourceObj?.cube,
            sourceObj?.refresh_key
          );
        } else if (!value && property === 'groupBy') {
          // clear groupByColors in the event no groupBy is selected
          clearGroupByColor(
            {
              componentIndex,
              itemIndex,
            },
            dispatch
          );
        }
      }
      // Pre-populate the groupByColors array with first ten indicators
      if (
        currentValue !== '' &&
        currentValue !== undefined &&
        !groupByColorsLength &&
        post.data?.[groupById]
      ) {
        const data = post.data[groupById];
        const indexes = {
          componentIndex,
          itemIndex,
        };
        prePopulateGroupByColor(data, `${cube}.${currentValue}`, dispatch, indexes, colorRange);
      }

      setValue(currentValue);
    }
    if (!parents) {
      if (objectKey) {
        setValue(component[property][objectKey]);
      } else {
        setValue(component[property]);
      }
    }
    prevValueRef.current = {
      currentValue: currentValue,
      itemIndex: itemIndex,
      id: component.id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex, component]);

  return (
    <Row gutter={10}>
      <Col sm={6}>
      <label>{label}</label>
      </Col>
      <Col sm={18}>
      <Select
        showSearch
        allowClear
        value={value}
        getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
        onChange={(value, e: Dictionary) => {
          if (value) {
            setValue(value);
            dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: property,
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                objectKey: objectKey,
                value: value,
              })
            );
            if (setType) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: typeKey,
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  value: e['data-type'],
                })
              );
            }
            if (setNestedType) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: property,
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  objectKey: typeKey,
                  value: e['data-type'],
                })
              );
            }
          } else {
            setValue('');
            dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: property,
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                value: '',
              })
            );
            if (setType) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: typeKey,
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  value: '',
                })
              );
            }
            if (setNestedType) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: parents,
                  property: property,
                  componentIndex: componentIndex,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  objectKey: 'type',
                  value: '',
                })
              );
            }
          }
        }}
      >
        {sourceObj &&
          sourceObj?.dimensions?.map((dimension: Dictionary, index: number) => (
            <Option data-type="dimension" key={`d-${index}`} value={dimension.value}>
              {dimension.value}
            </Option>
          ))}
        {sourceObj &&
          sourceObj?.measures?.map((measure: Dictionary, index: number) => (
            <Option data-type="measure" key={`m-${index}`} value={measure.name}>
              {measure.name}
            </Option>
          ))}
      </Select>
      </Col>
      </Row>
  );
};

export { DimensionGroupBy };
