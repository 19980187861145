import React, { useState, useEffect } from 'react';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../actions';
import { Checkbox, Row, Col } from 'antd';
import './style.css';
import { isGlobalFilter } from '../helpers/helpers';

export interface CheckboxInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const CheckboxInput: React.FC<CheckboxInputProps> = (props: CheckboxInputProps) => {
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, parents, objectKey } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = useSelector((state: Dictionary) => state.post.components[componentIndex]);
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      let currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      let currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue = component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (!parents && !itemIndex) {
      setValue(component[property]);
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);
  return (
    <Row gutter={10}>
      <Col sm={21}>
      <label>{item?.label}</label>
      </Col>
      <Col sm={3}>
      <Checkbox
        disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
        checked={value}
        onChange={(e) => {
          setValue(e.target?.checked);
          setTimeout(() => {
            dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: property,
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                value: e.target?.checked,
                objectKey: objectKey,
              })
            );
          }, 500);
        }}
     />
      
        </Col>
      </Row>
  );
};
export { CheckboxInput };
