import produce from 'immer';
import { makeId, createUniqueIndex, makeFriendlyName } from './helpers';

const source = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case 'SOURCE_GET':
      nextState = produce(state, (draftState) => {
        return action.data;
      });
      return nextState;
    case 'SOURCE_EDIT':
      nextState = produce(state, (draftState) => {
        Object.keys(action.data).forEach((key) => {
          draftState[key] = action.data[key];
        });
      });
      return nextState;
    case 'SOURCE_NESTED_ITEM_EDIT':
      nextState = produce(state, (draftState) => {
        const { value, parentProperty, property, itemIndex } = action.data;
        draftState[parentProperty][itemIndex][property] = value;
      });
      return nextState;
    case 'SOURCE_NAME_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.name = action.data.value;
        draftState.cube = makeFriendlyName(action.data.value);
      });
      return nextState;
    case 'SOURCE_CACHE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.refresh_key = action.data.value;
      });
      return nextState;
    case 'SOURCE_URL_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.dataUrl) {
          draftState.dataUrl = '';
        }
        if (action.data.value === '') {
          delete draftState.dataUrl;
          delete draftState.sheet;
          delete draftState.sourceType;
          delete draftState.isJSON;
          draftState.columns = [];
          draftState.data = [];
        }
        draftState.dataUrl = action.data.value;
      });
      return nextState;
    case 'SOURCE_DATA_URL_VALIDATE':
      nextState = produce(state, (draftState) => {
        if (action.payload.data && action.payload.data.sourceOrigin) {
          draftState.sourceType = {
            valid: action.payload.data.sourceOrigin.valid,
            origin: action.payload.data.sourceOrigin.origin,
            sheets: action.payload.data.sourceOrigin.sheets,
          };
        }
      });
      return nextState;
    case 'SOURCE_SHEET_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.sheet = action.data.value;
      });
      return nextState;
    case 'SOURCE_SHEET_RANGE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.range = action.data.value;
      });
      return nextState;
    case 'SOURCE_GEOMETRY_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.geometries) {
          draftState.geometries = [];
        }
        draftState.geometries.push({
          type: '' || action.data?.type,
          name: '' || action.data?.name,
          id: '' || action.data?.id,
          dimensions: [] || action.data.dimensions,
        });
      });
      return nextState;
    case 'SOURCE_GEOMETRY_EDIT_DIMENSIONS':
      nextState = produce(state, (draftState) => {
        draftState.geometries.find((geometry) => geometry.id === action.data?.id).dimensions =
          action.data?.dimensions;
      });
      return nextState;
    case 'SOURCE_GEOMETRY_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.geometries.splice(action.data.index, 1);
      });
      return nextState;
    case 'SOURCE_GEOMETRY_SOURCE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.geometries[action.data.index].source = action.data.value;
      });
      return nextState;
    case 'SOURCE_GEOMETRY_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.geometries[action.data.index][action.data.field]) {
          draftState.geometries[action.data.index][action.data.field] = '';
        }
        draftState.geometries[action.data.index][action.data.field] = action.data.value;
      });
      return nextState;
    case 'SOURCE_DATA_SOURCE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.type = action.data.value;
      });
      return nextState;
    case 'SOURCE_BASE_TABLE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.base_table = action.data.value;
      });
      return nextState;
    case 'SOURCE_CONFIG_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.config = action.data.value;
      });
      return nextState;
    case 'SOURCE_BASE_SQL_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.baseSql = action.data.value;
      });
      return nextState;
    case 'SOURCE_QUERY':
      nextState = produce(state, (draftState) => {
        draftState.query = action.data;
      });
      return nextState;
    case 'SOURCE_DIMENSION_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.dimensions) {
          draftState.dimensions = [];
        }
        draftState.dimensions.push(action.data.value);
      });
      return nextState;
    case 'SOURCE_DIMENSION_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.dimensions[action.data.index][action.data.field] = action.data.value;
      });
      return nextState;
    case 'SOURCE_MEASURE_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.measures) {
          draftState.measures = [];
        }
        draftState.measures.push(action.data.value);
      });
      return nextState;
    case 'SOURCE_MEASURE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.measures[action.data.index][action.data.field] = action.data.value;
      });
      return nextState;
    case 'SOURCE_DIMENSION_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.dimensions.splice(action.data.index, 1);
      });
      return nextState;
    case 'SOURCE_MEASURE_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.measures.splice(action.data.index, 1);
      });
      return nextState;
    case 'SOURCE_FILE_SCHEMA_GET':
      nextState = produce(state, (draftState) => {
        // temp fix while we refactor actions
        let payload = action.payload;
        if (action.payload.data) {
          payload = action.payload.data;
        }
        const dimensions = payload.map((item) => {
          let dimension = {
            ...item,
          };

          if (draftState.dimensions !== null) {
            // merge in schema changes
            let found = false; // flag to exit the loop if we found incoming item in store
            let i = 0;

            while (!found && i < draftState.dimensions.length) {
              const existing = draftState.dimensions[i];

              if (existing.value === item.value) {
                found = true;
                dimension = {
                  ...dimension,
                  type: existing.type, // retain existing type
                  title: existing.title, // retain existing title
                };
              }

              i += 1;
            }
          }

          return dimension;
        });

        draftState.dimensions = dimensions;
      });
      return nextState;
    case 'SOURCE_TABLE_SCHEMA_GET':
      nextState = produce(state, (draftState) => {
        draftState.dimensions = action.data;
      });
      return nextState;
    case 'SOURCE_UPLOAD_FILE':
      nextState = produce(state, (draftState) => {
        draftState.upload = 'upload';
      });
      return nextState;
    case 'SOURCE_SAVE':
      nextState = produce(state, () => action.data);
      return nextState;
    default:
      return state;
  }
};

export default source;
