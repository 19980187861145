import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, message } from 'antd';
import { doFileRead, uploadDone } from './helpers/utils/utils';
import * as imageService from './helpers/dataLoaders/dataLoaders';
import { Dictionary } from '@onaio/utils';
import { LoadingOutlined } from '@ant-design/icons';
import { actionPostComponentErrorAdd } from '../../../actions';

export interface ImageInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const ImageInput: React.FC<ImageInputProps> = (props: ImageInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const post = useSelector((state: Dictionary) => state.post);
  const { property, entity, parents } = item;
  const [value, setValue] = useState();
  const [currentFile, setCurrentFile] = useState<string | Blob>('');
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const imgRef: React.RefObject<HTMLImageElement> = createRef();
  const component = post?.components?.[componentIndex];

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue = post?.components?.[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      setValue(currentValue);
    } else if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post?.components?.[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    } else if (componentIndex && !parents) {
      setValue(post?.components?.[componentIndex]?.[property]);
    } else if (entity === 'post') {
      setValue(post?.[property]);
    }
  }, [post, parents, itemIndex, childIndex, componentIndex, property, entity]);

  useEffect(() => {
    if (isUploading) {
      imageService
        .imageUploadService(currentFile)
        .then((id: string | undefined) => {
          uploadDone(id, dispatch, property, componentIndex, childIndex, itemIndex, parents, entity, {
            setCurrentFile,
            setIsUploading,
          });
        })
        .catch(function (error) {
          setCurrentFile('');
          setIsUploading(false);
          dispatch(
            actionPostComponentErrorAdd({
              id: component?.id,
              name: component?.name,
              type: component?.type,
              errors: error,
            })
          );
          message.error(error.message);
        });
    }
  }, [isUploading, currentFile, dispatch, property, componentIndex, entity, component]);

  /* @ts-ignore */
  const handleChange = useCallback(({ target }) => {
    if (target.files[0]) {
      setCurrentFile(target.files[0]);
      doFileRead(target.files[0], setImgUrl);
    }
  }, []);

  const handleClick = useCallback(() => {
    const minAllowedImgWidth = 100;
    const maxAllowedImgWidth = 5000;
    if (
      imgRef.current &&
      imgRef.current.width >= minAllowedImgWidth &&
      imgRef.current.width <= maxAllowedImgWidth
    ) {
      const width = 2000;
      const height = 1125; // Height calculated from min img width using an aspect ratio of 16:9
      setIsUploading(true);
    } else {
      setImgUrl(undefined);
      if (imgRef.current && imgRef.current.width <= minAllowedImgWidth) {
        message.error('The image width is less than 1000px');
      } else {
        message.error('Maximum image size exceeded.');
      }
    }
  }, [imgRef]);

  return (
    <Row>
      <Col sm={24}>
        <label htmlFor="file-uploader">{item.label}</label>
        {value && !currentFile && (
          <img style={{ width: '100%', marginBottom: '10px' }} src={value} alt="current" />
        )}
        <input
          id="file-uploader"
          style={{ width: '100%' }}
          type="file"
          multiple={false}
          accept="image/png, image/jpeg, image/webp"
          disabled={isUploading}
          onChange={handleChange}
        />
        {currentFile && !isUploading && (
          <>
            <img ref={imgRef} alt="" hidden src={imgUrl} />
            <br />
            <br />
            <img alt="" style={{ width: '-webkit-fill-available' }} src={imgUrl} />
          </>
        )}
        <Button
          style={{ marginTop: '.8em' }}
          onClick={handleClick}
          disabled={!currentFile || isUploading}
        >
          Upload {isUploading && <LoadingOutlined />}
        </Button>
      </Col>
    </Row>
  );
};

export { ImageInput };
