import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { message } from 'antd';
import { SOURCES_WEB_SOCKET_URL } from '../../../../../configs/env';
import { startAsycCubeDataExport } from '../../../helpers/helpers';
import { Dictionary } from '@onaio/utils';
import { QueryExportParams } from '../../../../../configs/component-types';

export const checkExportProgress = async (
  jobId: string,
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
  setDataInCSV: (csvData: string) => void,
  setLoading: (loadingState: boolean) => void
): Promise<void> => {
  socket?.on(jobId, (data: { progress: { stage: string; csv_data?: string } }) => {
    if (data?.progress?.stage === 'DONE') {
      setDataInCSV(data?.progress?.csv_data as string);
      // setLoading(false);
      socket?.disconnect();
    }
    if (data?.progress?.stage === 'FAILED') {
      message.error('Error exporting data, please try again', 2.5);
      setLoading(false);
      socket?.disconnect();
    }
  });
};

export const triggerAsycDataExport = (
  query: QueryExportParams,
  setDataInCSV: (csvData: string) => void,
  setLoading: (loadingState: boolean) => void
): void => {
  const socket = io(SOURCES_WEB_SOCKET_URL, {
    path: '/v1/socket.io',
    withCredentials: true,
  });
  socket.on('connect', () => {
    // start asyc task for export source query data
    startAsycCubeDataExport({
      queryExportParams: query,
    }).then(async (res) => {
      const data = res as Dictionary;
      await checkExportProgress(data.job_uuid, socket, setDataInCSV, setLoading);
    });
    // eslint-disable-next-line no-console
    console.log(
      `client connected to websock server ${SOURCES_WEB_SOCKET_URL}, socket id: ${socket.id}`
    );
  });
  socket.on('connect_error', () => {
    setTimeout(() => socket.connect(), 5000);
  });
  socket.on('disconnect', () => {
    // eslint-disable-next-line no-console
    console.log(`client disconnected from websock server ${SOURCES_WEB_SOCKET_URL}`);
  });
};
