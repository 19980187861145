import produce from 'immer';
import { ENV } from '../configs/env';

const users = (state =  {}, action) => {
  let nextState;
  switch (action.type) {
    case 'USER_CACHE':
      nextState = produce(state, (draftState) => {
        draftState[action.data.email] = { 
            name: action.data.name,
            image: action.data.image
        }
      });
      return nextState;
    default:
      return state;
  }
};

export default users;
