import { Dictionary } from '@onaio/utils/dist/types/types';
import { actionPostComponentSettingEdit } from '../../../actions';

/**
 * Validate default page size input
 *
 * @param {string} value default page size
 * @returns {Promise} promise
 */
export const validateDefaultPageSize = async (
  value: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  if (!value) {
    return Promise.resolve();
  }

  return Promise.resolve();
};

export interface DefaultPageSize {
  defaultPageSize: string;
}

export const handlePayloadDispatch = (
  dispatch: (action: Dictionary) => void,
  props: Dictionary
): void => {
  const {
    parentProperty,
    property,
    componentIndex,
    itemIndex,
    childIndex,
    value,
    setDefaultPageSizeInputError,
    propertyKey,
    objectKey,
    parents,
  } = props;
  if (property === 'defaultPageSize') {
    validateDefaultPageSize(value)
      .then(() => {
        dispatch(
          actionPostComponentSettingEdit({
            parentProperty: parentProperty,
            property: property,
            componentIndex: componentIndex,
            itemIndex: itemIndex,
            childIndex: childIndex,
            parents,
            objectKey,
            value: propertyKey ? { [propertyKey]: value } : value,
          })
        );
        setDefaultPageSizeInputError('');
      })
      .catch((err) => {
        setDefaultPageSizeInputError(err);
      });
  } else {
    dispatch(
      actionPostComponentSettingEdit({
        parentProperty: parentProperty,
        property: property,
        componentIndex: componentIndex,
        itemIndex: itemIndex,
        childIndex: childIndex,
        parents,
        objectKey,
        value: propertyKey ? { [propertyKey]: value } : value,
      })
    );
  }
};
