import React from 'react';
import { Link } from 'react-router-dom';
import { ENV } from '../../../configs/env';
import { SpaceComponent } from '../Dashboard/components/SpaceComponents';

export const shouldShowDefaultHeader = (props: any) => {
  if (!props.post.space_id && props?.posts && !props?.posts[0]?.space_id) {
    return true;
  }
  return false;
};

export const shouldShowSpaceHeader = (props: any) => {
  if (props.post.space_id) {
    return true;
  }
  if (props?.posts && props?.posts[0]?.space_id && props.space.id) {
    return true;
  }
  if (props.space.id) {
    return true;
  }
  return false;
};

export const getDashboardLink = (props: any, path: any) => {
  if (props.post.space_id) {
    return `/space/${props.post.space_id}/${path}`;
  } else {
    return `/dashboard/${path}`;
  }
};

export const getColor = (props: any) => {
  if (props.post.space_id || (props?.posts && props?.posts[0]?.space_id && props.space.id)) {
    if (props.space && props.space.config) {
      if (props.space.config.header_text_color) {
        return props.space.config.header_text_color;
      }
    }
  }
  return '#333';
};

export const getHeaderColor = (props: any) => {
  if (props.post.space_id) {
    return props.space?.config?.color;
  }
  if (props?.posts && props?.posts[0]?.space_id && props.space.id) {
    return props.space.config.color;
  }
  return 'transparent';
};

export const getName = (props: any) => {
  if (props.post.space_id) {
    return props.space.name;
  }
  if (props.space) {
    return props.space.name;
  }
  return props.post.account_id || props.user.handle;
};

export const allowLayoutOnHeader = (props: any) => {
  const { post, space, posts } = props;
  if (post.space_id) {
    return space?.config?.layout_on_header;
  }
  if (posts?.[0]?.space_id && space?.id) {
    return space.config.layout_on_header;
  }
  return false;
};

export const spaceContent = (props: any, hasLayout: boolean): JSX.Element => {
  const { space } = props;
  const { config, id } = space;
  if (hasLayout) {
    return <></>;
  } else {
    return config?.logo_file ? (
      <Link
        to={() => {
          return `/space/${id}`;
        }}
      >
        <img
          alt="logo"
          width={config.logo_width || 200}
          className="space-logo"
          src={`https://assets.akuko.io/${config.logo_file}`}
        />
      </Link>
    ) : (
      <Link
        to={() => {
          return `/space/${id}`;
        }}
        className="account-brand"
      >
        <span className="account-name" style={{ color: getColor(props) }}>
          {getName(props)}
        </span>
      </Link>
    );
  }
};
