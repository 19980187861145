import React, { useMemo, useEffect, useState } from 'react';
import './style.css';
import { Button, Checkbox, Select, Radio, Dropdown, Menu, Row, Col, ConfigProvider } from 'antd';
import { GenericComponent } from '../Component';

import { getComponentById } from '../../../reducers/selectors/post';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useResolvedStyles } from '../hooks';
import { Dictionary } from '@onaio/utils';
import { DownOutlined, BarsOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  actionComponentEventAdd,
  actionPostComponentSettingEdit,
} from '../actions';
import { getContrast } from '../../App/ColorSettings/helpers';
import {
  componentIsVisible,
  layerIsVisble,
  layerPropertyIsActive,
} from './helpers';
import { useFetchComponentData } from '../hooks';
import { getContextRow } from '../helpers';
import { OptionsProps } from '../../../configs/types';
const { Option } = Select;
const { SubMenu } = Menu;

/** selector factories */
const makeGetComponentById = () => getComponentById;

/** interface for component props */
export interface MenuProps {
  componentId: string; // id of the icon component
  componentIndex: number; // index of the icon component
  dataRow?: Dictionary;
}

const MenuComponent: React.FC<MenuProps> = (props: MenuProps) => {
  const { componentId, componentIndex, dataRow } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const [current, setCurrent] = useState('0');

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);

  const button = useSelector((state) => {
    /* @ts-ignore */
    const component = selectComponentById(state, { componentId });

    if (!component) {
      return undefined;
    }
    return component;
  });

  const [isLoading, data] = useFetchComponentData(componentId);

  const { filteredData } = getContextRow(dataRow, data, component, true);

  const styles = useResolvedStyles();

  const dataRowKeys = filteredData?.[0] ? Object.keys(filteredData[0]) : null;
  const dimensionMatchDataRow: Dictionary = {};
  dataRowKeys?.map((key) => {
    if (key.split('.')[1]) {
      dimensionMatchDataRow[key.split('.')[1]] = filteredData?.[0][key];
    }
    dimensionMatchDataRow[key] = filteredData?.[0][key];
  });

  // set button value in cases where context is inherited

  const buttonValue = filteredData?.[0]
    ? dimensionMatchDataRow[component.context]
    : '';

  const genericComponentProps = {
    dataRow,
    componentIndex,
    componentId,
    drawerTitle: 'Button',
  };

  useEffect(() => {
    getKey();
  }, []);

  useEffect(() => {
    getKey();
  }, [post.uuid]);

  const isRoute = (events: Dictionary[]) => {
    let value;
    events?.forEach((item: Dictionary) => {
      if (item.effect === 'navigateToUrl' && item.url?.charAt(0) === '/') {
        value = item.url;
      }
    });
    return value;
  };

  const getKey = () => {
    button?.items?.forEach((item: Dictionary) => {
      if (item?.events?.length > 0) {
        item?.events?.forEach((event: Dictionary) => {
          if (window.location.pathname.includes(event.url)) {
            setCurrent(item.id);
          }
        });
      }
    });
  };

  const handleEvents = (e: Dictionary, btn: Dictionary) => {
    if (e?.preventDefault && e?.persist) {
      e.preventDefault();
      e.persist();
    }

    const value = e?.currentTarget?.value;
    if (value != null) {
      for (const [itemKey, item] of button?.items?.entries() ?? []) {
        if (item.events?.length) {
          for (const [eventKey, { effect, filter }] of item.events.entries()) {
            if (effect === 'updateFilterValue' && filter) {
              dispatch(
                actionPostComponentSettingEdit({
                  parents: ['items', 'events'],
                  property: 'value',
                  componentIndex,
                  itemIndex: eventKey,
                  value,
                  childIndex: itemKey,
                })
              );
            }
          }
        }
      }
    }
    dispatch(
      actionComponentEventAdd({
        type: 'onButtonClick',
        event: {
          target: {
            checked: e?.target?.checked,
            key: e?.key, // horizontal menu key
            target: e?.target?.outerHTML,
          },
        },
        data: filteredData[0],
        componentId: btn?.id,
      })
    );
  };

  const menu = (
    <Menu>
      {button?.items?.map((btn: Dictionary, index: number) => (
        <Menu.Item
          onClick={(e: Dictionary) => {
            handleEvents(e, btn);
          }}
          key={index}
        >
          {buttonValue && btn.labelContext ? buttonValue : btn.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const getMenu = () => {
    const array:any[] = [];
    button?.items?.forEach((item: Dictionary, index: number) => {
       if (!item.parent && item.visible !== false) {
        if (item.divider === true) {
          array.push({
            key: index,
            type: 'divider'
          })
        } else {
        array.push({
          key: index,
          label: (<>
          {item.image && 
          <img height={item.imageHeight || 20} style={{ marginTop: '10px' }} src={item.image} />
           }
          { !item.image &&
            <div>
            {item.label || 'Menu item'}
            </div>
           }
          </>
          ),
          children: getMenuChildren(item.id),
          onClick: (e: Dictionary) => {       
            setCurrent(e.key);
            if (item.events?.length > 0) {
              const route = isRoute(item.events);
              if (route !== undefined) {
                return history.push(route);
              } else {
                return handleEvents(e, item);
              }
            }
          }
        })
        }
       }
      
    })
    return array;
  }

  const getMenuChildren = (id: string) => {
    const array:any[] = [];
    button?.items?.forEach((item: Dictionary, index: number) => {
      if (item.visible !== false) {
      if (item.parent === id) {
        if (item.divider === true) {
          array.push({
            key: index,
            type: 'divider'
          })
        } else {
       array.push({
         key: index,
         label: item.label || 'Menu item',
         onClick: (e: Dictionary) => {       
          setCurrent(e.key);
          if (item.events?.length > 0) {
            const route = isRoute(item.events);
            if (route !== undefined) {
              return history.push(route);
            } else {
              return handleEvents(e, item);
            }
          }
        }
       })
      }
      }
     }
   })
   if (array.length > 0) {
     return array;
   } else {
    return null
   }
  }

  const horizontalMenu = (
    <Menu
      theme={component.theme || 'light'}
      mode='horizontal'
      style={{ lineHeight: 3 }}
      selectedKeys={[current]}
      items={getMenu()}
    />
  );

  const verticalMenu = (
    <Menu
      theme={component.theme || 'light'}
      mode='vertical'
      selectedKeys={[current]}
      items={getMenu()}
    />
  );

  const inlineMenu = (
    <Menu
      theme={component.theme || 'light'}
      mode='inline'
      selectedKeys={[current]}
      items={getMenu()}
    />
  );


  return (
    <GenericComponent {...genericComponentProps}>
       <Row gutter={10}>
       <Col xs={24} sm={24}>
        {isLoading && (
          <div className='component-loader'>
            <LoadingOutlined />
          </div>
        )}
        <ConfigProvider
          theme={{
           components: {
            Menu: {
              colorPrimary: component.color || styles.primaryColor || '#111',
              itemHoverColor: component.itemHoverColor || styles.itemHoverColor,
              itemBg: component.backgroundColor || styles.itemBg,
              subMenuItemBg: component.backgroundColor || styles.itemBg,
              itemHoverBg: component.itemHoverBg || styles.itemHoverBg,
              itemSelectedBg: component.itemSelectedBg || styles.itemSelectedBg,
              itemColor: component.color || styles.primaryColor || '#111',
              itemSelectedColor: component.itemSelectedColor || styles.primaryColor,
              popupBg: component.backgroundColor || styles.itemBg,
              fontSize: component.fontSize || styles.fontSize,
              fontFamily: component?.fontFamily || styles.fontFamily || 'Poppins',
              itemActiveBg: component.backgroundColor || styles.itemBg
            }
           }
          }}
          >
          {button?.format === 'horizontalMenu' && (
            <>
              {horizontalMenu}
            </>
          )}
          {button?.format === 'verticalMenu' && (
            <>
              {verticalMenu}
            </>
          )}
          {button?.format === 'inlineMenu' && (
            <>
              {inlineMenu}
            </>
          )}
          {!button?.items && (
            <Row gutter={10}>
            <Col xs={24} sm={24}>
            <div className='component-empty'>
              <BarsOutlined />
            </div>
            </Col>
            </Row>
          )}
          </ConfigProvider>
      </Col>
      </Row>
    </GenericComponent>
  );
};

export { MenuComponent };
