import React, { useState, useEffect } from 'react';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../../actions';
import { Radio, Row, Col } from 'antd';
import './style.css';

export interface PillsInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const PillsInput: React.FC<PillsInputProps> = (props: PillsInputProps) => {
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents } = item;
  const post = useSelector((state: any) => state.post);
  const component = useSelector((state: Dictionary) => state.post.components[componentIndex])
  const dispatch = useDispatch();
  const [value, setValue] = useState()

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (!parents) {
      setValue(post.components[componentIndex][property])
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    } 
  }, [post, parents, itemIndex, childIndex, componentIndex])
  

  return(
   <Row gutter={10}>
    <Col sm={6}>
    <label>{ item?.label }</label>
    </Col>
    <Col sm={18}>
    <Radio.Group 
      value={value}
      buttonStyle="solid"
      onChange={(e) => {
        dispatch(actionPostComponentSettingEdit({
          parents: parents,
          property: property,
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          childIndex: childIndex,
          value: e.target.value
        }));
      }}
      >
   { item?.options.map((pill:Dictionary, index: number) => (
      <Radio.Button key={index} value={pill.value}>{pill.label}</Radio.Button>
   ))}
   </Radio.Group>
   </Col>
   </Row>
 )
}
export { PillsInput }
