import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Select,
  Input,
  message,
  Form,
  Table,
  Modal,
  Alert,
  Popconfirm,
  Breadcrumb,
  Button,
  Drawer,
} from 'antd';
import { userCanNotChangeRole, generatePassword, userIsAddedToAccount } from '../../../helpers';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserPic } from '../../../../Post/UserPic';
import { withRouter } from 'react-router-dom';
import Loader from '../../../../Post/Loader/Loader';
import moment from 'moment';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserAdd,
  actionUserSpaceRoleEdit,
} from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, NOTIFICATIONS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import axios from 'axios';
import { genericWriteAccessHandler } from '../../../../Profile/components/ProfileMenu/helpers';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionAccountUsersGet,
  actionUserSpaceRoleEdit,
  actionUserAdd,
};

const SpaceUsers = (props) => {
  const [roleUpdateInProgress, setRoleUpdateInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [newUserMessage, setNewUserMessage] = useState();
  const params = useParams();
  const [loading, setLoading] = useState();
  const userForm = useRef();

  useEffect(() => {
    setLoading(true);
    const service = new AkukoAPIService(IDENTITY_API, 'space');
    service
    .read(params.id)
    .then((space) => {
    props.actionAccountSpaceGet(space);
      const service = new AkukoAPIService(IDENTITY_API, `user/space/${space.account_id}/${params.id}`);
      return service.list();
    })
    .then((users) => {
      props.actionAccountUsersGet(users);
    })
    .finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const columns = [
    {
      title: '',
      width: '10px',
      render: (record) => {
        return <UserPic author={record.email} />;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '80%',
    },
    {
      title: 'Role',
      dataIndex: 'value',
      key: 'role',
      render: (text, user) => {
        return (
          <Select
            onChange={async (value) => {
              try {
                setRoleUpdateInProgress(true);
                const service = new AkukoAPIService(IDENTITY_API, `user/role/${props.space.account_id}/${props.space.id}`);
                await service.update({
                  userId: user?.uuid,
                  role: value,
                });
                const spaces = Object.assign([], user.spaces);
                spaces.forEach((item, index) => {
                  if (item.id === props.space.id) {
                    const space = Object.assign({}, item);
                    space.role = value;
                    spaces[index] = space;
                  }
                });
                const userObj = Object.assign({}, user);
                userObj.spaces = spaces;
                props.actionUserSpaceRoleEdit({
                  userId: userObj.uuid,
                  spaceId: props.space.id,
                  value: value,
                });
                message.success('User role updated');
              } catch (error) {
                message.error(error.message || ERROR_GENERIC);
              } finally {
                setRoleUpdateInProgress(false);
              }
            }}
            value={user.value.role}
            style={{ width: '120px' }}
            disabled={userCanNotChangeRole(props) || roleUpdateInProgress}
          >
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="editor">Editor</Select.Option>
            <Select.Option value="viewer">Viewer</Select.Option>
          </Select>
        );
      },
    },
  ];

  if (!userCanNotChangeRole(props)) {
    columns.push({
      title: '',
      dataIndex: 'remove',
      key: 'remove',
      render: (text, result) => {
        return (
          <Popconfirm
            placement="left"
            title="Are you sure you want to remove this user?"
            onConfirm={async () => {
              try {
                const service = new AkukoAPIService(IDENTITY_API, `user/delete/${props.space.account_id}/${props.space.id}`);
                await service.update({
                  userId: result?.uuid,
                });
                const usersService = new AkukoAPIService(IDENTITY_API, `user/space/${props.space.account_id}/${params.id}`);
                const users = await usersService.list();
                props.actionAccountUsersGet(users);
                message.success('User removed successfully')
              } catch (error) {
                message.error(error.message || ERROR_GENERIC);
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    });
  }

  if (props.space.id) {
    return (
      <div className="dashboard">
        <div className="spaces">
          <div className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>{props.space.account_id}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>spaces</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${props.space.id}`}>{props.space.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>users</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1>Users</h1>
          {newUserMessage && <Alert message={newUserMessage} type="success" />}
          {showLoader && <Loader />}
          <div className="space-header">
            <Row gutter={20}>
              <Col xs={24}>
                <Button
                  type="primary"
                  hidden={!genericWriteAccessHandler(props.user, props.space?.id)}
                  onClick={(e) => {
                    setShowModal(true);
                  }}
                >
                  Add User
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={24} md={24} xl={24}>
              <div className="table-wrapper">
                <Table
                  loading={loading}
                  pagination={{
                    defaultPageSize: 20,
                  }}
                  columns={columns}
                  dataSource={props.space.users}
                />
              </div>
            </Col>
          </Row>
          <Modal
            visible={showModal}
            footer={null}
            onCancel={() => {
              setShowModal(false);
            }}
          >
            <Form
              layout="vertical"
              ref={userForm}
              onFinish={async (values) => {
                const email = values['email'].toLowerCase().trim();
                const isAdded = userIsAddedToAccount(email, props);
                if (isAdded) {
                  message.warning('This user already has access to this account.');
                } else {
                  try {
                    setShowModal(false);
                    setLoading(true);
                    const service = new AkukoAPIService(IDENTITY_API, `user/add/${props.space.account_id}/${props.space.id}`);
                    const users = await service.update({
                      email: email,
                      role: 'viewer',
                    });
                    message.success('User added');
                    props.actionAccountUsersGet(users);
                    axios.get(
                      `${NOTIFICATIONS_API}message/notify/space/${props.space.id}/${email}`
                    );
                  } catch (error) {
                    message.error(error.message || ERROR_GENERIC);
                  } finally {
                    setLoading(false);
                    userForm.current.resetFields();
                  }
                }
              }}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Add user
              </Button>
            </Form>
          </Modal>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SpaceUsersContainer = connect(mapStateToProps, mapDispatchToProps)(SpaceUsers);

export default withRouter(SpaceUsersContainer);
