import { Dictionary } from '@onaio/utils/dist/types/types';
import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import { UPLOADS_API } from '../../../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../../../configs/constants';
import { AkukoAPIService } from '../../../../../../../services/serviceClass';

export const imageUploadService = async (file: Blob | string): Promise<string | undefined> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const service = new AkukoAPIService(UPLOADS_API, `images`);
    const res = await service.create(formData);
    const response = res as Dictionary;

    return response.image_id;
  } catch (e) {
    const error = e as AxiosError;
    message.error(error.response ? JSON.stringify(error.response?.data) : error.message);
  }
};

export const imageResizeService = async (image: Dictionary): Promise<string | undefined> => {
  const { id, dimensions } = image;
  const { width, height } = dimensions;
  let imageId;
  await axios
    .get(`${UPLOADS_API}images/${width}x${height}/${id}`)
    .then(({ data: { image_id } }) => {
      imageId = image_id;
    })
    .catch((error) => {
      message.error(error.message || error?.response?.data?.error || ERROR_GENERIC);
    });

  return imageId;
};

export default {
  imageUploadService,
  imageResizeService,
};
