export const actionSourceGet = (data) => {
  return {
    type: 'SOURCE_GET',
    data,
  };
};

export const actionSourceSave = (data) => {
  return {
    type: 'SOURCE_SAVE',
    data,
  };
};

export const actionSourceEdit = (data) => {
  return {
    type: 'SOURCE_EDIT',
    data,
  };
};

export const actionSourceDelete = (data) => {
  return {
    type: 'SOURCE_DELETE',
    data,
  };
};

export const actionSourceNestedItemEdit = (data) => {
  return {
    type: 'SOURCE_NESTED_ITEM_EDIT',
    data,
  };
};

export const actionSourceNameEdit = (data) => {
  return {
    type: 'SOURCE_NAME_EDIT',
    data,
  };
};

export const actionSourceCacheEdit = (data) => {
  return {
    type: 'SOURCE_CACHE_EDIT',
    data,
  };
};

export const actionSourceUrlEdit = (data) => {
  return {
    type: 'SOURCE_URL_EDIT',
    data,
  };
};

export const actionSourceSheetEdit = (data) => {
  return {
    type: 'SOURCE_SHEET_EDIT',
    data,
  };
};

export const actionSourceSheetRangeEdit = (data) => {
  return {
    type: 'SOURCE_SHEET_RANGE_EDIT',
    data,
  };
};

export const actionSourceGeometryAdd = (data) => {
  return {
    type: 'SOURCE_GEOMETRY_ADD',
    data,
  };
};

export const actionSourceGeometryDelete = (data) => {
  return {
    type: 'SOURCE_GEOMETRY_DELETE',
    data,
  };
};

export const actionSourceGeometrySourceEdit = (data) => {
  return {
    type: 'SOURCE_GEOMETRY_SOURCE_EDIT',
    data,
  };
};

export const actionSourceGeometryEdit = (data) => {
  return {
    type: 'SOURCE_GEOMETRY_EDIT',
    data,
  };
};

export const actionSourceGeometryEditDimensions = (data) => {
  return {
    type: 'SOURCE_GEOMETRY_EDIT_DIMENSIONS',
    data,
  };
};

export const actionSourceColumnAdd = (column) => {
  return {
    type: 'SOURCE_COLUMN_ADD',
    column,
  };
};

export const actionSourceDataSourceEdit = (data) => {
  return {
    type: 'SOURCE_DATA_SOURCE_EDIT',
    data,
  };
};

export const actionSourceQuery = (data) => {
  return {
    type: 'SOURCE_QUERY',
    data,
  };
};

export const actionSourceUploadFile = (data) => {
  return {
    type: 'SOURCE_UPLOAD_FILE',
    payload: data,
  };
};

export const actionSourceFileSchemaGet = (data) => {
  return {
    type: 'SOURCE_FILE_SCHEMA_GET',
    payload: data,
  };
};

export const actionSourceCreateFileSchema = (data) => {
  return {
    type: 'SOURCE_FILE_SCHEMA_ADD',
    payload: data,
  };
};

export const actionSourceEditFileSchema = (data) => {
  return {
    type: 'SOURCE_FILE_SCHEMA_EDIT',
    payload: data,
  };
};

export const actionPostgresConnectionTest = (data) => {
  return {
    type: 'SOURCE_TABLE_SCHEMA_GET',
    data,
  };
};

export const actionSourceAccessTokenGet = (data) => {
  return {
    type: 'SOURCE_TOKEN_GET',
    payload: data,
  };
};

export const actionSourceConfigEdit = (data) => {
  return {
    type: 'SOURCE_CONFIG_EDIT',
    data,
  };
};

export const actionSourceBaseTableEdit = (data) => {
  return {
    type: 'SOURCE_BASE_TABLE_EDIT',
    data,
  };
};

export const actionSourceBaseSqlEdit = (data) => {
  return {
    type: 'SOURCE_BASE_SQL_EDIT',
    data,
  };
};

export const actionSourceDimensionAdd = (data) => {
  return {
    type: 'SOURCE_DIMENSION_ADD',
    data,
  };
};

export const actionSourceMeasureAdd = (data) => {
  return {
    type: 'SOURCE_MEASURE_ADD',
    data,
  };
};

export const actionSourceDimensionEdit = (data) => {
  return {
    type: 'SOURCE_DIMENSION_EDIT',
    data,
  };
};

export const actionSourceMeasureEdit = (data) => {
  return {
    type: 'SOURCE_MEASURE_EDIT',
    data,
  };
};

export const actionSourceMeasureDelete = (data) => {
  return {
    type: 'SOURCE_MEASURE_DELETE',
    data,
  };
};

export const actionSourceDimensionDelete = (data) => {
  return {
    type: 'SOURCE_DIMENSION_DELETE',
    data,
  };
};
