import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, MenuProps, Button, Space } from 'antd';
import { ExportOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getComponentSource } from '../../../reducers/selectors/post';
import { GenericPostComponent } from '../../../configs/component-types';
import { buildQueryObj } from '../utils';
import { CARD_DEFAULT_QUERY_LIMIT } from '../../../configs/constants';
import { triggerAsycDataExport } from '../../Source/components/ExportSourceQuery/helpers/helpers';
import { ExportPostComponent } from '../PostComponents/components/ExportPostComponent';

/** interface for component export menu  */
export interface ComponentExportMenuProps {
  component: GenericPostComponent;
  postId: string;
}

const makeGetComponentSource = () => getComponentSource;

const ComponentExportMenu: React.FC<ComponentExportMenuProps> = (
  props: ComponentExportMenuProps
) => {
  const { component, postId } = props;
  const componentId = component.id;
  const selectComponentSource = useMemo(makeGetComponentSource, []);
  const [loading, setLoading] = useState(false);
  const [exportComponent, setExportComponent] = useState(false);
  const [dataInCSV, setDataInCSV] = useState<string | undefined>(undefined);
  const csvDownloadLink = useRef<HTMLInputElement>(null);
  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const componentQuery =
    component && source?.uuid
      ? buildQueryObj(
          component,
          source.uuid,
          CARD_DEFAULT_QUERY_LIMIT,
          source?.refresh_key,
          source?.cube
        )
      : null;
  const query = {
    cube_query: {
      query: {
        query: {
          ...componentQuery?.query,
        },
        uuid: source?.uuid,
      },
    },
    refresh_key: source?.refresh_key as number,
    cube_name: source?.cube as string,
  };
  const items: MenuProps['items'] = [];

  // add component data export option to exports options
  if (component?.dataExport) {
    items.push({
      key: '1',
      disabled: loading,
      label: (
        <>
          {dataInCSV !== undefined ? (
            <Button
              type="primary"
              className="hidden"
              href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
              download={`${query.cube_query.query.uuid}.csv`}
              target="_blank"
              /* @ts-ignore */
              ref={csvDownloadLink}
            />
          ) : null}
          <Space
            onClick={(e) => {
              setLoading(true);
              triggerAsycDataExport(query, setDataInCSV, setLoading);
              e.stopPropagation();
            }}
          >
            {loading ? <LoadingOutlined /> : <ExportOutlined />}
            {loading ? 'Preparing export...' : 'Export csv'}
          </Space>
        </>
      ),
    });
  }

  // add component image export option to exports options
  if (component?.componentImageExport) {
    items.push({
      key: '2',
      disabled: exportComponent,
      label: (
        <ExportPostComponent
          componentName={component?.title}
          postUrl={window.location.href}
          selector={`#component-${component?.id}`}
          format="png"
          setExportPostComponent={setExportComponent}
          exportComponent={exportComponent}
          postId={postId}
        />
      ),
    });
  }

  useEffect(() => {
    if (dataInCSV !== undefined) {
      csvDownloadLink.current?.click();
      setDataInCSV(undefined);
      setLoading(false);
    }
  }, [dataInCSV]);

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
    >
      <button className="btn-export-options">
        <MoreOutlined />
      </button>
    </Dropdown>
  );
};

export { ComponentExportMenu };
