import React, { useMemo } from 'react';
import './style.css';
import { GenericComponent } from '../Component';
import { Image } from 'antd';
import { getComponentById } from '../../../reducers/selectors/post';
import { useSelector } from 'react-redux';
import { IconComponent, SelectOption } from '../../../configs/component-types';
import { Dictionary } from '@onaio/utils';
import { HeatMapOutlined } from '@ant-design/icons';
import { hexToCSSFilter } from 'hex-to-css-filter';

/** selector factories */
const makeGetComponentById = () => getComponentById;

/** interface for component props */
export interface IconProps {
  componentId: string; // id of the icon component
  componentIndex: number; // index of the icon component
  contextOptions?: SelectOption[]; // options for the context select field
  dataRow?: Dictionary;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { componentId, componentIndex, dataRow } = props;

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);

  const icon = useSelector((state) => {
    /* @ts-ignore */
    const component = selectComponentById(state, { componentId });

    if (!component) {
      return undefined;
    }
    return component as IconComponent;
  });

  if (!icon) {
    return null;
  }

  const genericComponentProps = {
    dataRow,
    componentIndex,
    componentId,
    drawerTitle: 'Icon',
  };

  const { width, categories, useCategories } = icon;

  const getColor = () => {
    if (icon?.icon?.color) {
      // check if is valid hex color
      if (/^#[0-9A-F]{6}$/i.test(icon.icon.color)) {
        const hex = hexToCSSFilter(icon.icon.color).filter.replace(';', '');
        return hex;
      }
      return '';
    } else {
      return '';
    }
  };

  const imageProps = {
    preview: false,
    width: Number(width) || 50,
    src: icon.icon?.src,
    alt: icon.icon?.title,
    style: {
      filter: getColor(),
    },
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div
        className="icon-component"
        style={{ background: icon.backgroundColor || 'rgba(0,0,0,0.02)' }}
      >
        {(!useCategories && !icon.icon?.src) || (useCategories && !categories?.length) ? (
          <div className="component-empty">
            <HeatMapOutlined />
          </div>
        ) : (
          <Image {...imageProps} />
        )}
      </div>
    </GenericComponent>
  );
};

export { Icon };
