import React from 'react';
import { useDispatch } from 'react-redux';
import { message, Popconfirm } from 'antd';
import { actionConfigComponentIndexActive, actionConfigDrawerOpenEdit } from '../../../actions';
import { actionPostComponentDelete } from '../../../actions';
import {
  AlignLeftOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  BookOutlined,
  PictureOutlined,
  TableOutlined,
  LayoutOutlined,
  NumberOutlined,
  FilterOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { GenericPostComponent } from '../../../../../configs/component-types';

export interface ComponentMenuItemProps {
  index: number;
  item: GenericPostComponent;
  childLevel?: number;
}

const ComponentMenuItem: React.FC<ComponentMenuItemProps> = (props: ComponentMenuItemProps) => {
  const dispatch = useDispatch();
  const { index, item } = props;

  return (
    <div
      className="post-components-menu--item"
      key={index}
      onMouseOver={() => {
        const el = document.getElementById(`component-${item.id}`);
        el?.classList.add('component-active');
      }}
      onMouseOut={() => {
        const el = document.getElementById(`component-${item.id}`);
        el?.classList.remove('component-active');
      }}
      onClick={() => {
        const el = document.getElementById(`component-${item.id}`);
        el?.classList.remove('component-active');
        dispatch(actionConfigDrawerOpenEdit({ value: true }));
        dispatch(actionConfigComponentIndexActive({ value: index }));
      }}
    >
      {item.type === 'title' && <BookOutlined />}
      {item.type === 'map' && <EnvironmentOutlined />}
      {item.type === 'layout' && <LayoutOutlined />}
      {item.type === 'image' && <PictureOutlined />}
      {item.type === 'chart' && <LineChartOutlined />}
      {item.type === 'table' && <TableOutlined />}
      {item.type === 'text' && <AlignLeftOutlined />}
      {item.type === 'card' && <AppstoreOutlined />}
      {item.type === 'number' && <NumberOutlined />}
      {item.type === 'icon' && <NumberOutlined />}
      {item.type === 'filter' && <FilterOutlined />}
      {item.type === 'button' && <UnorderedListOutlined />}
      &nbsp;&nbsp;&nbsp;&nbsp;
      {item.name || item.type}
      <Popconfirm
        title="Are you sure you want to delete this component?"
        okText="Yes"
        onConfirm={(e) => {
          e?.stopPropagation();
          message.success('Component deleted');
          dispatch(
            actionPostComponentDelete({
              index: index,
            })
          );
        }}
        onCancel={(e) => {
          e?.stopPropagation();
        }}
      >
        <DeleteOutlined
          className="delete-component"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Popconfirm>
    </div>
  );
};

export { ComponentMenuItem };
