import { Dictionary } from '@onaio/utils';
import { actionPostComponentSettingEdit } from '../../../../actions';
import { Dispatch } from 'redux';

/**
 * Validates if a given value exists in a list of options.
 * e.g filter operator defaulting to equal (==) for date/time fields
 *
 * @param {string} value - The value to be validated.
 * @param {Dictionary[] | undefined} options - The list of options to validate against.
 * @return {string | undefined} The validated value or undefined if it doesn't exist in the options list.
 */
export const selectValueValidator = (
  value: string | string[] | undefined,
  options: Dictionary[] | undefined,
  componentConfigs: Dictionary,
  dispatch: Dispatch
): string | undefined | string[] => {
  const {
    parents,
    property,
    componentIndex,
    itemIndex,
    childIndex,
    refreshKey,
    objectKey,
    entity,
  } = componentConfigs;
  const valueExistInOptions = options?.some(
    (option: Dictionary) => option.value === value
  );
  if (valueExistInOptions || entity === 'post') {
    return value;
  } else if (value && property !== 'categoricalClasses') {
    dispatch(
      actionPostComponentSettingEdit({
        parents: parents,
        property: property,
        componentIndex: componentIndex,
        itemIndex: itemIndex,
        childIndex: childIndex,
        refreshKey: refreshKey,
        objectKey: objectKey,
        value: options?.[0].value,
      })
    );
    return options?.[0].value;
  }
  return undefined;
};

export const categoricalClassesValue = (
  property: string,
  colorSchemeConfigs: Dictionary
): string | undefined => {
  if (
    property === 'categoricalClasses' &&
    colorSchemeConfigs?.layerType === 'symbol'
  ) {
    return colorSchemeConfigs?.symbolCategories?.length
      ? `${
          colorSchemeConfigs?.symbolCategories?.length ||
          colorSchemeConfigs?.categoricalClasses
        } Categories`
      : undefined;
  }
  if (property === 'categoricalClasses') {
    return colorSchemeConfigs?.colorCategories?.length
      ? `${colorSchemeConfigs?.colorCategories?.length} Categories`
      : undefined;
  } else if (property === 'classes' && !colorSchemeConfigs.classes) {
    return '6';
  }
};
