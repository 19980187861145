import { Dictionary } from '@onaio/utils';
import { POSTS_API } from '../../../../configs/env';
import { PostExportParams, PostScheduleSendRequestPayload } from '../../../../configs/component-types';
import { AkukoAPIService } from '../../../../services/serviceClass';

export const ghostComponentIds = (post: Dictionary): string[] => {
  const ghosts: string[] = [];
  // look through all components
  post.components.forEach((component: Dictionary) => {
    // if component is using layout
    if (component.layout) {
      let isGhost = true;
      post.components.forEach((child: Dictionary) => {
        // and see if any are using this layout
        if (component.layout === child.id) {
          isGhost = false;
        }
      });
      // if not push to ghosts array
      if (isGhost) {
        ghosts.push(component.id);
      }
    }
  });
  return ghosts;
};

export const startAsycJobForCreatingPostScheduleSend = async ({
  postId,
  payload,
}: PostScheduleSendRequestPayload): Promise<Dictionary> => {
  const service = new AkukoAPIService(POSTS_API, `export/schedules/${postId}`);
  const res = await service.create(payload);
  return res as Dictionary;
};

export const startAsycJobForUpdatingPostScheduleSend = async ({
  postId,
  payload,
}: PostScheduleSendRequestPayload): Promise<Dictionary> => {
  const service = new AkukoAPIService(POSTS_API, `export/schedules/${postId}`);
  const res = await service.update(payload);
  return res as Dictionary;
};

export const startAsycPostExport = async ({
  postExportParams,
}: {
  postExportParams: PostExportParams;
}): Promise<Dictionary> => {
  const service = new AkukoAPIService(POSTS_API, `export`);
  const res = await service.create({ ...postExportParams });
  return res as Dictionary;
};
