

export const menu = [
  {
    type: 'font',
    placeholder: 'Poppins',
    value: 'fontFamily',
    label: 'Font family'
  },
  {
    type: 'number',
    placeholder: '12',
    min: 9,
    value: 'fontSize',
    label: 'Font size'
  },
  {
    type: 'color',
    defaultValue: "#000",
    value: 'primaryColor',
    label: 'Color'
  },
  {
    type: 'color',
    value: 'itemBg',
    defaultValue: "#eee",
    label: 'Background'
  },
  {
    type: 'color',
    defaultValue: "#ddd",
    value: 'itemSelectedBg',
    label: 'Selected bg'
  },
  {
    type: 'color',
    defaultValue: "#000",
    value: 'itemSelectedColor',
    label: 'Selected'
  },
  {
    type: 'color',
    defaultValue: "#eee",
    value: 'itemHoverBg',
    label: 'Hover bg'
  },
  {
    type: 'color',
    value: 'itemHoverColor',
    defaultValue: "#000",
    label: 'Hover color'
  },
]