import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, message, Form, Row, Col } from 'antd';
import { io } from 'socket.io-client'
import { Dictionary } from '@onaio/utils';

import { actionSourceSave } from '../../actions';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { SOURCES_API, SOURCES_WEB_SOCKET_URL } from '../../../../configs/env';
import { Source } from '../../../../configs/component-types';
import { onadataSourceJobStatusTypes, onadataSourceJobStatusTypesDescriptions } from '../constants';
import { ProgressModal } from './ProgressModal';

export interface SourceOnadataInputProps {
    sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
    sourceActionSaveCreator: actionSourceSave,
};

const OnadataSourceType: React.FC<SourceOnadataInputProps> = (
    props: SourceOnadataInputProps
) => {
    const { sourceActionSaveCreator } = props;
    const dispatch = useDispatch();
    const source = useSelector((store: Dictionary) => store.source);
    const [progress, setProgress] = useState<Dictionary>({});
    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [current, setCurrent] = useState(1);

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (loading === true && progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE] && source.type === 'onadata' && source?.dimensions !== null && source?.dimensions.length !== 0) {
            handleModalCancel();
            message.success("Source created successfully!", 2.5);
            setLoading(false);
            setProgress({});
            setCurrent(0);
        }
    }, [progress, loading, source, isModalOpen]);

    useEffect(() => {
        if (progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE]) {
            const sourceService = new AkukoAPIService(SOURCES_API, 'source');
            sourceService.read(source.uuid).then((res) => {
                const source = res as Source;
                if (sourceActionSaveCreator) {
                    /* @ts-ignore */
                    dispatch(sourceActionSaveCreator({ ...source }));
                }
            })
        } else if (progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.FAILED]) {
            handleModalCancel();
            message.error("Error creating source", 2.5);
            setLoading(false);
            setProgress({});
            setCurrent(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    const triggerWebsocket = async (jobId: string) => {
        const socket = io(SOURCES_WEB_SOCKET_URL, {
            path: '/v1/socket.io',
            withCredentials: true
        });
        // eslint-disable-next-line no-console
        socket.on('connect', () => console.log(`client connected to websock server ${SOURCES_WEB_SOCKET_URL}, socket id: ${socket.id}`));
        socket.on('connect_error', () => {
            setTimeout(() => socket.connect(), 5000);
        })
        socket.on(jobId, (data: { progress: { stage: string} }) => {
            const currentStep = Object.keys(onadataSourceJobStatusTypesDescriptions).indexOf(data.progress.stage);
            setCurrent(currentStep);
            setProgress(data?.progress);
            if (data?.progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE] || data?.progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.FAILED]) {
                socket.disconnect();
            }
        })
        // eslint-disable-next-line no-console
        socket.on('disconnect', () => console.log(`client disconnected from websock server ${SOURCES_WEB_SOCKET_URL}`))
    };

    if (source?.type === 'onadata' && source?.dimensions?.length === 0) {
        return (
            <Form
                className="source-onadata-form"
                key={source}
                onFinish={async (values) => {
                    setCurrent(0);
                    setIsModalOpen(true);
                    setLoading(true);
                    setProgress({});
                    const service = new AkukoAPIService(SOURCES_API, 'source/onadata');
                    try {
                        const res = await service.create({
                            source_name: source.name,
                            source_id: source.uuid,
                            form_id: values['form_id'],
                            token: values['token'],
                            host: values['host'],
                        });
                        const data = res as { job_uuid: string };
                        await triggerWebsocket(data.job_uuid);
                    } catch (error) {
                        setCurrent(0);
                        setLoading(false);
                        setProgress({});
                        message.error('Error creating source');
                    }
                }}
                layout="vertical"
            >
                <ProgressModal
                  title="Source creation progress"
                  progress={progress}
                  current={current}
                  isModalOpen={isModalOpen}
                  handleModalCancel={handleModalCancel}
                />
                <Row gutter={10}>
                    <Col xs={24} sm={8}>
                        <Form.Item
                            name="form_id"
                            label="Form ID"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input id="input-form-id" placeholder="ona form id" disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16}>
                        <Form.Item
                            name="token"
                            label="Token"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.Password id="input-api-token" placeholder="ona api token" disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Form.Item
                            name="host"
                            label="Host"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input id="input-host" placeholder="ona api domain e.g. api.ona.io, defaults to api.ona.io" disabled={loading} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24}>
                        <Button id="btn-connect" htmlType="submit" type="primary" disabled={loading}>
                            Create Source
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    } else {
        return null;
    }
};

OnadataSourceType.defaultProps = defaultProps;

export { OnadataSourceType };
