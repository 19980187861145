import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import MarkdownIt from 'markdown-it';
import { useFetchComponentData } from '../hooks';
import { ReferenceComponent as ReferenceComponentType } from '../../../configs/component-types';
import { actionPostComponentEdit, actionPostKeyEdit } from '../actions';
import { GenericComponent } from '../Component';
import './style.css';
import { Button, message } from 'antd';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { getContextRow, processTokens } from '../helpers';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TextEditor } from '../Settings/components/TextEditor';
import { getComponentById, getComponentSource } from '../../../reducers/selectors/post';
import { useMemo } from 'react';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

export interface ReferenceComponentProps {
  componentId: string;
  componentIndex: number;
  dataRow?: Dictionary;
  region?: number;
  layout?: string;
  cardIndex?: number; // card index
  isEmbed?: boolean;
  editPostKeyActionCreator?: typeof actionPostKeyEdit;
  editComponentActionCreator?: typeof actionPostComponentEdit;
}

const defaultProps = {
  editPostKeyActionCreator: actionPostKeyEdit,
  editComponentActionCreator: actionPostComponentEdit,
};



const ReferenceComponent: React.FC<ReferenceComponentProps> = (props: ReferenceComponentProps) => {
  const dispatch = useDispatch();
  // memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const {
    componentId,
    componentIndex,
    dataRow,
    cardIndex,
    editComponentActionCreator,
    editPostKeyActionCreator,
    isEmbed,
  } = props;

  

  const component = useSelector((state) => {
    /* @ts-ignore */
    const reference = selectComponentById(state, { componentId });

    if (!reference) {
      return undefined;
    }
    return reference as unknown as ReferenceComponentType;
  });

  const created = useSelector((state: Dictionary) => state.post.created);

  const activeComponentIndex: number = useSelector(
    (state) => (state as Dictionary).config.activeComponentIndex
  );

  const drawerIsOpen: boolean = useSelector((state) => (state as Dictionary).config.drawerOpen);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const post = useSelector((store: Dictionary) => store.post);


  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Text',
    isEmbed: isEmbed,
  };

  useEffect(() => {
    if (post.components.length > 0 && component?.componentId) {
      message.error('Reference component not found.')
    }
  }, []);
 

  return (
    <GenericComponent {...genericComponentProps}>
      <div className="pointer-component">
        <div className="content">
      { component?.componentId ? (
        <p>Save and <a 
         href="#reload"
         onClick={(e) => {
          e.preventDefault();
          location.reload();
         }}
        >reload
        </a> post to view component.</p>
      ) : (
        <p>Select a component to insert.</p>
      )}
       </div>
      </div>
    </GenericComponent>
  );
};

ReferenceComponent.defaultProps = defaultProps;

export { ReferenceComponent };
