export const createUniqueIndex = (key, data) => {
  let match = false;
  Object.entries(data).forEach(([k, val]) => {
    if (k === key) {
      match = true;
    }
  });
  if (match) {
    return `__${key}_${makeId()}`;
  } else {
    return key;
  }
};

export const makeId = () => {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const randInt = () => {
  return Math.floor(Math.random() * Math.floor(10000));
};

export const makeFriendlyName = (string) => {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join("");
};
