import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, message, Form, Row, Col, Switch } from 'antd';
import { Dictionary } from '@onaio/utils';
import { actionSourceSave } from '../../actions';
import {
  MySQLSourceTypePayload,
  MySQLSourceTypeRequestPayload,
  Source,
  UrlSourceCreationJobStatusTypes,
  sourceAsyncOperationsJobStatusTypes,
} from '../../../../configs/component-types';
import { startAsycJobForCreatingDatabaseSourceType } from '../../helpers/helpers';
import { triggerAsycSourceOperation } from '../../components/SourceWebsocketsUpdatesHelpers';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../../configs/env';
import { UrlSourceCreationProgressSteps } from '../../components/UrlSourceCreationProgressSteps';

export interface MySQLSourceTypeProps {
  sourceActionSaveCreator?: (obj: Source) => void;
  refresh?: boolean;
  asyncTaskName: string;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
  refresh: false,
  asyncTaskName: 'Source Creation',
};

const MySQLSourceType: React.FC<MySQLSourceTypeProps> = (props: MySQLSourceTypeProps) => {
  const { sourceActionSaveCreator, refresh, asyncTaskName } = props;
  const source = useSelector((store: Dictionary) => store.source);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ssl, setSSL] = useState<boolean>(false);
  const formData = new FormData();
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }
      setLoading(false);
      message.success('Source saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleSourceCreation = (payload: MySQLSourceTypeRequestPayload) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });
    triggerAsycSourceOperation({
      asynFunc: startAsycJobForCreatingDatabaseSourceType,
      asynFuncArgs: payload,
      asyncTaskName: asyncTaskName,
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const sslOnChange = (checked: boolean) => {
    setSSL(checked);
  };

  if (source?.type === 'mysql') {
    return (
      <>
        <Form
          className="source-mysql-form"
          onFinish={(values) => {
            const payload = {
              source_id: source?.uuid,
              source_type: source?.type,
              source_name: source?.name,
              refresh: refresh !== undefined ? refresh : false,
              table: values['table'],
              user: values['username'],
              host: values['host'],
              database: values['database'],
              password: values['password'],
              port: values['port'],
              cube_name: source?.cube,
            } as MySQLSourceTypePayload;
            // remove ssl cert file if ssl is disabled but file still exists formData
            if (ssl === false && formData.get('file')) {
              formData.delete('file');
            }
            formData.append(
              'json_data',
              new Blob([JSON.stringify(payload)], { type: 'application/json' })
            );
            handleSourceCreation({
              sourceId: source?.uuid,
              sourceType: source?.type,
              formData: formData,
            });
          }}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={16}>
              <Form.Item
                name="host"
                label="Host"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-host" placeholder="host" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="port"
                label="Port"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-port" placeholder="port" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="database"
                label="Database"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-database" placeholder="database" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="table"
                label="Table"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-table" placeholder="table" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-username" placeholder="username" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password id="input-password" placeholder="password" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="ssh_enabled"
                label="SSL"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Switch defaultChecked={false} onChange={sslOnChange} disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} style={{ display: ssl === true ? 'block' : 'none' }}>
              <Form.Item
                name="cert_file"
                label="Certificate"
                rules={[
                  {
                    required: ssl,
                  },
                ]}
              >
                <Input
                  type="file"
                  disabled={loading}
                  accept=".pem"
                  onChange={(e: Dictionary) => {
                    if (e.target.files) {
                      formData.append('file', e.target.files[0]);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Button id="btn-connect" htmlType="submit" type="primary" disabled={loading}>
                Connect
              </Button>
            </Col>
          </Row>
        </Form>
        {loading === true && (
          <div className="input-field">
            <label>Progress</label>
            <UrlSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

MySQLSourceType.defaultProps = defaultProps;

export { MySQLSourceType };
