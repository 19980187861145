export const actionComponentSortFieldEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CHART_SORT_FIELD_EDIT',
    data,
  };
};

export const actionComponentChartColorFieldEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CHART_COLOR_FIELD_EDIT',
    data,
  };
};

export const actionComponentChartLimitEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CHART_LIMIT_EDIT',
    data,
  };
};

export const actionComponentChartSettingEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CHART_SETTING_EDIT',
    data,
  };
};

export const actionComponentSeriesColorModeEdit = (data) => {
  return {
    type: 'POST_COMPONENT_SERIES_COLOR_MODE_EDIT',
    data,
  };
};

export const actionComponentChartGroupByColorsEdit = (data) => {
  return {
    type: 'POST_COMPONENT_GROUP_BY_COLORS_EDIT',
    data,
  };
};

export const actionComponentChartGroupByColorEdit = (data) => {
  return {
    type: 'POST_COMPONENT_GROUP_BY_COLOR_EDIT',
    data,
  };
};
