import React, { useEffect, useState } from 'react';
import { ColorPicker, Row, Col, Popover } from 'antd';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionPostComponentSettingEdit,
  actionPostPropertyEdit,
} from '../../../actions';
import './style.css';

export interface ColorInputProps {
  componentIndex: number;
  item: Dictionary;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  renderAsColumn?: boolean;
}

const ColorInput: React.FC<ColorInputProps> = (props: ColorInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item, renderAsColumn } = props;
  const {
    property,
    label,
    parents,
    objectKey,
    disableOpacity,
    inherit,
    defaultValue,
    size,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const space = useSelector((state: Dictionary) => state.space);
  const [value, setValue] = useState(defaultValue ? defaultValue : '#000000');
  const [inherited, setInherited] = useState(false);

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      let currentValue;
      if (objectKey !== undefined) {
        currentValue =
          post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[
            parents[1]
          ]?.[itemIndex]?.[property]?.[objectKey];
      } else {
        currentValue =
          post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[
            parents[1]
          ]?.[itemIndex]?.[property];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      let currentValue;
      if (objectKey !== undefined) {
        currentValue =
          post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[
            property
          ]?.[objectKey];
      } else {
        currentValue =
          post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[
            property
          ];
      }
      setValue(currentValue);
    }
    if (componentIndex !== undefined && !parents) {
      if (objectKey !== undefined) {
        setValue(post.components[componentIndex]?.[property]?.[objectKey]);
      } else {
        setValue(post.components[componentIndex]?.[property]);
      }
    }
    if (item.entity === 'post') {
      if (post?.[property] && objectKey) {
        setValue(post?.[property][objectKey]);
      } else {
        setValue(post?.[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  useEffect(() => {
    if (post?.config?.[inherit] || space?.config?.[inherit]) {
      setInherited(true);
    } else {
      setInherited(false);
    }
  }, [post?.config, space?.config]);

  const getInherit = () => {
    if (space?.config?.[inherit]) {
      return (
        <>
          [space.config]: {space?.config?.[inherit]}
          <span
            style={{ background: space?.config?.[inherit] }}
            className='inherit-color-swatch'
          ></span>
        </>
      );
    }
    if (post?.config?.[inherit]) {
      return (
        <>
          [post.config]: {post.config[inherit]}
          <span
            style={{ background: post.config[inherit] }}
            className='inherit-color-swatch'
          ></span>
        </>
      );
    }
    return null;
  };

  const handleUpdateStore = (color: string | null) => {
    if (item.entity === 'post') {
      setValue(color);
      dispatch(
        actionPostPropertyEdit({
          property: property,
          objectKey: objectKey,
          value: color,
        })
      );
    } else {
      setValue(color);
      dispatch(
        actionPostComponentSettingEdit({
          parents: parents,
          property: property,
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          childIndex: childIndex,
          objectKey: objectKey,
          value: color,
        })
      );
      if (
        parents?.[0] &&
        ['series', 'layers', 'properties'].includes(parents?.[0]) &&
        childIndex !== undefined
      ) {
        dispatch(
          actionPostComponentSettingEdit({
            parents: [parents[0]],
            property: 'colorRange',
            componentIndex: componentIndex,
            itemIndex: childIndex,
            value: undefined,
          })
        );
      }
    }
  };

  return renderAsColumn ? (
    <>
      {label && <label>{label}</label>}
      <ColorPicker
        allowClear
        onChangeComplete={(e) => {
          if (e.cleared) {
            handleUpdateStore(null);
          } else {
            handleUpdateStore(`#${e.toHex()}`);
          }
        }}
        placement='topLeft'
        arrow={false}
        size={size ? size : undefined}
        presets={[
          {
            label: 'Palette',
            colors: [
              '#000000',
              '#737373',
              '#545454',
              '#A6A6A6',
              '#D9D9D9',
              '#FFFFFF',
              '#FF1616',
              '#FF5757',
              '#5E17EB',
              '#964B00',
              '#03989E',
              '#00C2CB',
              '#5CE1E6',
              '#1890FF',
              '#38B6FF',
              '#5271FF',
              '#004AAD',
              '#008037',
              '#7ED957',
              '#C9E265',
              '#FFDE59',
              '#FFBD59',
              '#FF914D',
            ],
          },
        ]}
        value={value}
        showText
        disabledAlpha={disableOpacity}
        defaultValue={defaultValue ? defaultValue : undefined}
      />
    </>
  ) : (
    <Row gutter={10}>
      {inherited && (
        <Popover overlayClassName='inherit-info' content={getInherit()}>
          <span className='setting-inherited'></span>
        </Popover>
      )}
      {label && (
        <Col sm={6}>
          <label>{label}</label>
        </Col>
      )}
      <Col sm={18}>
        <ColorPicker
          allowClear
          onChangeComplete={(e) => {
            if (e.cleared) {
              handleUpdateStore(null);
            } else {
              handleUpdateStore(`#${e.toHex()}`);
            }
          }}
          arrow={false}
          size={size ? size : undefined}
          presets={[
            {
              label: 'Palette',
              colors: [
                '#000000',
                '#737373',
                '#545454',
                '#A6A6A6',
                '#D9D9D9',
                '#FFFFFF',
                '#FF1616',
                '#FF5757',
                '#5E17EB',
                '#964B00',
                '#03989E',
                '#00C2CB',
                '#5CE1E6',
                '#1890FF',
                '#38B6FF',
                '#5271FF',
                '#004AAD',
                '#008037',
                '#7ED957',
                '#C9E265',
                '#FFDE59',
                '#FFBD59',
                '#FF914D',
              ],
            },
          ]}
          value={value}
          showText
          disabledAlpha={disableOpacity}
          defaultValue={defaultValue ? defaultValue : undefined}
        />
      </Col>
    </Row>
  );
};

export { ColorInput };
