import { GroupFieldProps } from '../types';

export const post: GroupFieldProps = {
  groups: ['Template', 'Background', 'Publish', 'Post Image', 'Tags'],
  sections: {},
  fields: [

    {
      type: 'text',
      label: 'Published date',
      property: 'published',
      group: 'Publish',
      entity: 'post',
    },
    {
      type: 'color',
      label: 'Background color',
      placeHolder: '#ffffff',
      property: 'config',
      objectKey: 'backgroundColor',
      group: 'Background',
      entity: 'post',
    },
    {
      type: 'select',
      label: 'Tags',
      property: 'tags',
      group: 'Tags',
      entity: 'post',
      selectMode: 'tags',
    },
    {
    type: 'select',
    label: 'Post type',
    property: 'config',
    objectKey: 'type',
    group: 'Template',
    placeHolder: 'Notebook',
    entity: 'post',
    options: [
      { value: 'default', label: 'Notebook' },
      { value: 'map', label: 'Map' }
    ]
  },
    {
      type: 'switch',
      label: 'Published',
      property: 'public',
      group: 'Publish',
      entity: 'post',
    },
    {
      type: 'image',
      label: 'Select image',
      property: 'image',
      group: 'Post Image',
      entity: 'post',
    },
  ],
};
