import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { PhotoshopPicker } from 'react-color';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionUserCache } from '../../Account/actions';
import { Popover } from 'antd';
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API } from '../../../configs/env';
import './style.css';

const Author = (props) => {
  const { style, color } = props;
  const dispatch = useDispatch();
  const [user, setUser] = useState({ image: 'user-icon.png' });
  const post = useSelector((state) => state.post);

  useEffect(() => {
    if (props.author) {
      const service = new AkukoAPIService(IDENTITY_API, `user/${props.author}/public/metadata`);
      service.list().then((user) => {
        setUser(user);
        dispatch(
          actionUserCache(user)
        )
      });
    }
  }, [props.author]);

  if (props.author && user.image) {
    return (
      <div className="author-wrapper">
        <div className="content"
        >
        <Popover
         content={(
          <>
          <strong>{user.name || user.handle}</strong><br/>
          <p>{user.tagline }</p>
          </>
          )}
         placement="right"
        >
        <div
          key={props.key}
          className="author-pic"
          style={{ 
            color: `${color} || ${post.config?.textColor}`,
            backgroundImage: `url(https://assets.akuko.io/${user.image})` 
        }}
        ></div>
        </Popover>
        <div className="author-info"
        style={{ 
          color: `${color}`
        }}
        >
          { user.name &&
            <><span 
            style={{ 
              color: `${color}`
            }}
            className="author-name">{user.name}</span> { ' ' } </>
          }
          <span 
          style={{ 
            color: `${color}`
          }}
          className="author-handle"
          >
            @{ user?.handle }
          </span>
        </div>
        </div>
      </div>
    );
  } else {
    return (
    <div className="author-wrapper">
      <div className="content"
      
      >
      <Popover
         content={(
          <>
          <strong>{user.name || user.handle}</strong><br/>
          <p>{user.tagline}</p>
          </>
          )}
         placement="right"
        >

      <div
        key={props.key}
        className="author-pic"
        style={{ backgroundImage: `url(https://assets.akuko.io/user-icon.png)` }}
      ></div>

      </Popover>
        <div className="author-info"
       
        >
          { user.name &&
            <><span style={{ 
              color: `${color}`
            }} 
            className="author-name">{user.name}</span> { ' ' }</>
          }
          <span 
          style={{ 
            color: `${color}`
          }}
          className="author-handle">
  
            @{ user?.handle }
      
          </span>
          <p>{user.tagline}</p>
        </div>
      </div>
    </div>
    );
  }
};

export default Author;
