import { GroupFieldProps } from '../types';

export const reference: GroupFieldProps = {
  groups: ['Reference'],
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Reference',
      placeHolder: 'Text Identifier Name',
    },
    {
      type: 'text',
      label: 'Component',
      property: 'componentId',
      group: 'Reference',
      placeHolder: 'Component ID',
    },
  ],
};
