import React, { useEffect } from 'react';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { actionSourceGet } from '../../../../Source/actions';
import { Breadcrumb } from 'antd';
import { actionAccountSpaceGet, actionAccountSourcesGet } from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, SOURCES_API } from '../../../../../configs/env';
import SourceComponent from '../../../../Source';

export interface SourceParams {
  id: string;
  sourceId: string;
}

const Source: React.FC = () => {
  const dispatch = useDispatch();
  const params: SourceParams = useParams();
  const { id, sourceId } = params;
  const source = useSelector((store: Dictionary) => store.source);
  const space = useSelector((store: Dictionary) => store.space);
  const user = useSelector((store: Dictionary) => store.user);
  const { account_id } = space;

  useEffect(() => {
    const service = new AkukoAPIService(SOURCES_API, 'source');
    if (id && sourceId && account_id) {
      service
        .read(sourceId)
        .then((source) => {
          dispatch(actionSourceGet(source));
          const service = new AkukoAPIService(IDENTITY_API, 'space');
          return service.read(id);
        })
        .then((space) => {
          dispatch(actionAccountSpaceGet(space));
          const service = new AkukoAPIService(SOURCES_API, `source/space/${id}`);
          return service.list();
        })
        .then((sources) => {
          dispatch(actionAccountSourcesGet(sources));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sourceId, account_id]);

  return (
    <div className="dashboard">
      <div className="spaces">
        <div className="breadcrumb">
          {id && (
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/account/${account_id}`}>{account_id}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/account/${account_id}`}>spaces</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${space.id}`}>{space.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${space.id}/sources`}>sources</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
          {!id && (
            <Breadcrumb>
              <Breadcrumb.Item>{user.handle}</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/dashboard/sources">sources</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        </div>
        <h1>{source.name}</h1>
        <SourceComponent sourceId={sourceId} />
      </div>
    </div>
  );
};

export { Source };
