import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const ColsFour = (props) => {
  const { rowMargin } = props;
  const array = []
  const gridProps = {
    [props.grid]: 6,
    [props.grid === 'sm' ? 'xs' : '']: 24
  }
  for (var i = 1; i < props.rows+1; i++) {
    array.push(
    <Row 
    key={i} 
    style={{ marginBottom: rowMargin === 0 ? 0 : rowMargin }}
    gutter={props?.gutter || 0}>
      <Col {...gridProps}>
        <Region regionIndex={`${i}-a`} {...props} />
      </Col>
      <Col {...gridProps}>
        <Region regionIndex={`${i}-b`} {...props} />
      </Col>
      <Col {...gridProps}>
        <Region regionIndex={`${i}-c`} {...props} />
      </Col>
      <Col {...gridProps}>
        <Region regionIndex={`${i}-d`} {...props} />
      </Col>
    </Row>);
}
  return (
    array
  );
};

export { ColsFour };
