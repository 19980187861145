import { Dictionary } from '@onaio/utils';
import chroma from 'chroma-js';
import React from 'react';
import { useDispatch } from 'react-redux';
import { handleUpdateStore } from '../helpers/helpers';

interface ColorRangeProps {
  classes: number;
  configs: Dictionary;
  scheme?: string;
  reverseColors?: boolean;
  colorList?: Dictionary[];
}

const generateColors = (scheme: string, classes: number, reverse: boolean) => {
  const colors = chroma.scale(scheme).padding(0.1).colors(classes);
  return reverse ? colors.reverse() : colors;
};

const generateCustomColors = (colorList: Dictionary[]) => {
  const colors = colorList.map((item) => item.color);
  return colors;
};

const ColorRange: React.FC<ColorRangeProps> = React.memo(
  ({
    scheme,
    classes,
    configs,
    reverseColors = false,
    colorList = [],
  }: ColorRangeProps) => {
    const dispatch = useDispatch();
    const { setValue } = configs;
    const hasCustomColors = colorList.length > 0 && scheme === undefined;
    const schemeOverride = !scheme && !colorList?.length ? 'Oranges' : scheme;

    const colors = hasCustomColors
      ? generateCustomColors(colorList)
      : generateColors(scheme || 'Oranges', classes, reverseColors);

    const handleClick = () => {
      setValue(schemeOverride);
      handleUpdateStore(schemeOverride, classes, dispatch, configs);
    };

    return (
      <div className='color-range-input' onClick={handleClick}>
        {colors.map(
          (color, index) =>
            index < classes && (
              <div
                key={index}
                style={{ background: color, width: `${100 / classes}%` }}
                className='item-color-range-input'
              />
            )
        )}
      </div>
    );
  }
);

ColorRange.displayName = 'ColorRange';
export { ColorRange };
