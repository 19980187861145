import React, { useMemo, useEffect } from 'react';
import { GenericComponent } from '../Component';
import { getComponentById } from '../../../reducers/selectors/post';
import { useSelector } from 'react-redux';
import { FilterOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Dictionary } from '@onaio/utils';
import './style.css';
import { ConfigProvider } from 'antd';
import { FilterStructure } from '../Settings/components/GlobalFilterComponent';


/** selector factories */
const makeGetComponentById = () => getComponentById;

/** interface for component props */
export interface FilterProps {
  componentId: string; // id of the filter component
  componentIndex: number; // index of the filter component
}

const Filter: React.FC<FilterProps> = (props: FilterProps) => {
  const { componentId, componentIndex } = props;

  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);

  const filter = useSelector((state) => {
    /* @ts-ignore */
    const component = selectComponentById(state, { componentId });

    if (!component) {
      return undefined;
    }
    return component as Dictionary;
  });

  const history = useHistory();


  if (!filter) {
    return null;
  }



  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Global Filter',
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div className="global-filter-component">
      
        {!filter?.filters?.find((filterItem: FilterStructure) => filterItem?.expose) && (
          <div className="component-empty">
            <FilterOutlined />
          </div>
        )}
   
      </div>
    </GenericComponent>
  );
};

export { Filter };
