import React from 'react';
import { Modal } from 'antd';
import { Dictionary } from '@onaio/utils';
import { UrlSourceCreationProgressSteps } from '../../../components/UrlSourceCreationProgressSteps';

export interface SourceRefreshProgressModalProps {
  progress: Dictionary;
  isModalOpen: boolean;
  handleModalCancel: () => void;
  title: string;
}

const SourceRefreshProgressModal: React.FC<SourceRefreshProgressModalProps> = (
  props: SourceRefreshProgressModalProps
) => {
  const { progress, isModalOpen, handleModalCancel, title } = props;

  return (
    <Modal
      title={title}
      visible={isModalOpen}
      onOk={handleModalCancel}
      onCancel={handleModalCancel}
      footer={null}
      width={400}
    >
      <UrlSourceCreationProgressSteps progress={progress} direction="vertical" />
    </Modal>
  );
};

export { SourceRefreshProgressModal };
