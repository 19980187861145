import produce from 'immer';
import { ENV } from '../configs/env';

const profile = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case 'PROFILE_GET':
      nextState = produce(state, (draftState) => action.data);
      return nextState;
    default:
      return state;
  }
};

export default profile;
