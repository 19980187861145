import React, { useState, useEffect, useRef } from 'react';
import { Input, InputRef, Tag, Tooltip, theme } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface PostFiltersProps {
  setPostFilters: (options: string[]) => void;
  postFilters: string[];
  disable?: boolean;
}

/** default component props */
const defaultProps: Partial<PostFiltersProps> = {
  disable: false,
};

const PostFilters: React.FC<PostFiltersProps> = (props: PostFiltersProps) => {
  const { postFilters, setPostFilters, disable } = props;
  const [postFilterOptionInputVisible, setPostFilterOptionInputVisible] = useState(false);
  const [postFilterOptionInputValue, setPostFilterOptionInputValue] = useState('');
  const [editPostFilterOptionInputValue, setEditPostFilterOptionInputValue] = useState('');
  const [editPostFilterOptionInputIndex, setEditPostFilterOptionInputIndex] = useState(-1);
  const postFilterOptionInputRef = useRef<InputRef>(null);
  const editPostFilterOptionInputRef = useRef<InputRef>(null);
  const [postFilterOptionInputStyle, setPostFilterOptionInputStyle] = useState<React.CSSProperties>(
    {
      width: '15em',
      height: 30,
      marginInlineEnd: 8,
      verticalAlign: 'top',
    }
  );
  const { token } = theme.useToken();
  const [postFilterOptionPlusStyle, setPostFilterOptionPlusStyle] = useState<React.CSSProperties>({
    height: 22,
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  });

  useEffect(() => {
    if (disable === true) {
      setPostFilterOptionInputStyle({
        ...postFilterOptionInputStyle,
        color: '#aaa',
        cursor: 'not-allowed',
      });
      setPostFilterOptionPlusStyle({
        ...postFilterOptionPlusStyle,
        color: '#aaa',
        cursor: 'not-allowed',
      });
    }
    if (disable === false) {
      const inputStyle = {
        ...postFilterOptionInputStyle,
      };
      delete inputStyle.color;
      delete inputStyle.cursor;
      setPostFilterOptionInputStyle({
        ...inputStyle,
      });
      const plusStyle = {
        ...postFilterOptionPlusStyle,
      };
      delete plusStyle.color;
      delete plusStyle.cursor;
      setPostFilterOptionPlusStyle({
        ...plusStyle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable]);

  useEffect(() => {
    if (postFilterOptionInputVisible) {
      postFilterOptionInputRef.current?.focus();
    }
  }, [postFilterOptionInputVisible]);

  useEffect(() => {
    editPostFilterOptionInputRef.current?.focus();
  }, [editPostFilterOptionInputValue]);

  const handlePostFilterOptionsClose = (removedPostFilterOption: string) => {
    const newPostFilterOptions = postFilters.filter(
      (option: string) => option !== removedPostFilterOption
    );
    setPostFilters(newPostFilterOptions);
  };

  const showPostFilterOptionInput = () => {
    setPostFilterOptionInputVisible(true);
  };

  const handlePostFilterOptionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostFilterOptionInputValue(e.target.value);
  };

  const handlePostFilterOptionInputConfirm = () => {
    if (postFilterOptionInputValue && !postFilters.includes(postFilterOptionInputValue)) {
      setPostFilters([...postFilters, postFilterOptionInputValue]);
    }
    setPostFilterOptionInputVisible(false);
    setPostFilterOptionInputValue('');
  };

  const handlePostFilterOptionEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditPostFilterOptionInputValue(e.target.value);
  };

  const handlePostFilterOptionEditInputConfirm = () => {
    const newPostFilterOptionsList = [...postFilters];
    newPostFilterOptionsList[editPostFilterOptionInputIndex] = editPostFilterOptionInputValue;
    setPostFilters(newPostFilterOptionsList);
    setEditPostFilterOptionInputIndex(-1);
    setEditPostFilterOptionInputValue('');
  };

  return (
    <>
      {postFilters.map((postFilterOption: string, index: number) => {
        if (editPostFilterOptionInputIndex === index) {
          return (
            <Input
              ref={editPostFilterOptionInputRef}
              key={postFilterOption}
              size="large"
              placeholder="Post filter option"
              style={postFilterOptionInputStyle}
              value={editPostFilterOptionInputValue}
              onChange={handlePostFilterOptionEditInputChange}
              onBlur={handlePostFilterOptionEditInputConfirm}
              onPressEnter={handlePostFilterOptionEditInputConfirm}
              suffix={
                <Tooltip title="Post filter options e.g. post_filter_name=filter_by_value">
                  <InfoCircleOutlined style={{ fontSize: '12px' }} />
                </Tooltip>
              }
            />
          );
        }
        const isLongOption = postFilterOption.length > 20;
        const postFilterOptionElem = (
          <Tag
            key={postFilterOption}
            closable={disable ? false : true}
            style={{ userSelect: 'none' }}
            onClose={() => handlePostFilterOptionsClose(postFilterOption)}
          >
            <span
              style={disable ? { color: '#aaa', cursor: 'not-allowed' } : {}}
              onDoubleClick={(e) => {
                setEditPostFilterOptionInputIndex(index);
                setEditPostFilterOptionInputValue(postFilterOption);
                e.preventDefault();
              }}
            >
              {isLongOption ? `${postFilterOption.slice(0, 20)}...` : postFilterOption}
            </span>
          </Tag>
        );
        return isLongOption ? (
          <Tooltip title={postFilterOption} key={postFilterOption}>
            {postFilterOptionElem}
          </Tooltip>
        ) : (
          postFilterOptionElem
        );
      })}
      {postFilterOptionInputVisible ? (
        <Input
          ref={postFilterOptionInputRef}
          type="text"
          size="large"
          placeholder="Post filter option"
          style={postFilterOptionInputStyle}
          value={postFilterOptionInputValue}
          onChange={handlePostFilterOptionInputChange}
          onBlur={handlePostFilterOptionInputConfirm}
          onPressEnter={handlePostFilterOptionInputConfirm}
          suffix={
            <Tooltip title="Post filter options e.g. post_filter_name=filter_by_value">
              <InfoCircleOutlined style={{ fontSize: '12px' }} />
            </Tooltip>
          }
        />
      ) : (
        <Tag
          style={postFilterOptionPlusStyle}
          icon={<PlusOutlined />}
          onClick={showPostFilterOptionInput}
        >
          New Option
        </Tag>
      )}
    </>
  );
};

PostFilters.defaultProps = defaultProps;

export { PostFilters };
