/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { message, Button } from 'antd';
import { useAuth } from "react-oidc-context";
import 'react-markdown-editor-lite/lib/index.css';
import {
  actionPostGet,
  actionSpaceClearConfig,
  actionPostClearConfig,
  actionConfigEdit
} from './actions';
import './style.css';
import Nav from '../Account/Nav/Nav';
import { applyFiltersFromUrl, handleError } from './helpers';
import { IDENTITY_API, POSTS_API } from '../../configs/env';
import { AkukoAPIService } from '../../services/serviceClass';
import { handleComponentEvent } from './events';
import { addFonts, handlePostType } from './helpers';
import { useHistory } from 'react-router';
import { NotebookTemplate } from './Templates/Notebook';
import { MapTemplate } from './Templates/Map';
import { ProfileMenu } from '../Profile/components/ProfileMenu';
import { GlobalNav } from '../Account/GlobalNav';
import { actionConfigActivePostAdd } from './actions';
import mixpanel from 'mixpanel-browser';

const PostContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const [postLocation, setPostLocation] = useState();
  const post = useSelector((store) => store.post);
  const user = useSelector((store) => store.user);
  const events = useSelector((store) => store.post?.events);
  const [space, setSpace] = useState({});
  const { isAuthenticated, signinRedirect, isLoading } = useAuth();
  const history = useHistory();
  const [embedArray, setEmbedArray] = useState([]);

  const isEmbed = () => {
    if (location.pathname.includes('/embed/')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (location.search.includes('?components=')) {
      const split = location.search.split('?components=');
      try {
        const componentIds = JSON.parse(decodeURI(split[1]));
        setEmbedArray(componentIds);
      } catch {
        setEmbedArray([]);
        message.error('Malformed embed url');
      }
    }
  }, []);

  useEffect(() => {
    dispatch(
      actionConfigActivePostAdd({
        value: params.id,
      })
    );

    // on component unmount
    return () => {
      dispatch(actionPostClearConfig());
      dispatch(actionSpaceClearConfig());
    };
  }, []);

  useEffect(() => {
    if (user.email) {
      mixpanel.track('View Post', { post: params.id });
    }
  }, []);

  useEffect(() => {
    if (user.email) {
      mixpanel.track('View Post', { post: params.id });
    }
  }, [params.id]);

  useEffect(() => {
    // redirect to editor, if editing
    if (user.email && post.edit === user.email) {
      history.replace(`/post/${params.id}/edit`);
    }
  }, [post.uuid]);

  useEffect(() => {
    getPost();
    window.scrollTo(0, 0);
    return () => {
      document.body.classList.remove('page-post');
      document.body.classList.remove('post-in-space');
      dispatch(actionConfigEdit({ property: 'activeComponentIndex', value: 0 }))
      window.akukoMaps = {};
    };
  }, [params.id, isAuthenticated]);

  useEffect(() => {
    setPostLocation(location);
  }, [post]);

  useEffect(() => {
    addFonts(post, space);
  }, [post.components, JSON.stringify(post.config), JSON.stringify(space.config)]);

  useEffect(() => {
    if (post.uuid && postLocation && !isEmbed()) {
      if (window.akukoMaps && Object.keys(window.akukoMaps).length) {
        Object.keys(window.akukoMaps).forEach((mapId) => {
          if (window.akukoMaps[mapId]) {
            window.akukoMaps[mapId]?.once('styledata', () => {
              applyFiltersFromUrl(postLocation, post, dispatch);
            });
          }
        });
      } else {
        applyFiltersFromUrl(postLocation, post, dispatch);
      }
    }
  }, [post?.components?.length, JSON.stringify(Object.keys(window.akukoMaps))]);

  useEffect(() => {
    addFonts(post, space);
  }, []);

  useEffect(() => {
    addFonts(post, space);
  }, [space.uuid]);

  useEffect(() => {
    if (post?.config?.type === 'map') {
      handlePostType(post, dispatch);
    }
  }, [post?.config?.type]);

  useEffect(() => {
    handleComponentEvent(events, post, dispatch);
  }, [events?.length]);

  const getPost = () => {
    const service = new AkukoAPIService(POSTS_API, 'post');
    service
      .read(params.id)
      .then((post) => {
        dispatch(actionPostGet(post));
        document.title = `${space.name ? `${space.name} |` : ''} ${post.title}`;
        if (post.space_id === null) {
          return null;
        }
        const service = new AkukoAPIService(IDENTITY_API, 'space/metadata');
        return service.read(post.space_id);
      })
      .then((spaceMetadata) => {
        if (spaceMetadata !== null) {
          setSpace(spaceMetadata)
        }
      })
      .catch((error) => {
        if (isLoading === false) {
          handleError(error.message, isAuthenticated, history, location, signinRedirect);
        }
      });
  };

  if (params.id === 'null' || params.id === 'undefined') {
    return (
      <div className="new-post-prompt">
        <h2>You&apos;re not currently editing a Post.</h2>

        <Button
          onClick={() => {
            const service = new AkukoAPIService(POSTS_API, 'post');
            service
              .create({
                account_id: userProfile?.handle,
                space_id: null,
                title: 'My new post',
                edit: user.email,
                author: user.email,
                components: [
                  {
                    type: 'title',
                    description: '',
                  },
                ],
                data: {},
                sources: {},
              })
              .then((res) => {
                history.push(`/post/${res.uuid}`);
              })
              .catch((error) => {
                message.error(error.message || 'Error');
              });
          }}
        >
          Create New Post
        </Button>
      </div>
    );
  }
  if (post.config?.type !== 'map') {
    return (
      <div>
        {!isEmbed() && <GlobalNav />}
        <Nav />
        
        <div>
          <NotebookTemplate post={post} embedComponents={embedArray} embed={isEmbed()} />
        </div>
      </div>
    );
  }

  if (post.config?.type === 'map') {
    return (
      <div>
        {!isEmbed() && <GlobalNav />}
        <div style={{ fontFamily: space.config?.font?.bodyFontFamily }}>
          <MapTemplate user={user} post={post} embedComponents={embedArray} embed={isEmbed()} />
        </div>
      </div>
    );
  }

  return null;
};

export default PostContainer;
