import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutThirteen = (props) => {
  return (
    <Row gutter={1}>
      <Col xs={24} sm={24}>
        <Region regionIndex={1} {...props} />
      </Col>
      <Col xs={24} sm={24}>
        <Region regionIndex={2} {...props} />
      </Col>
    </Row>
  );
};

export { LayoutThirteen };
