import produce from 'immer';
import moment from 'moment';

const sources = (state = { items: [], index: {} }, action) => {
  let nextState;
  switch (action.type) {
    case 'POST_COMPONENT_SOURCES_GET':
      nextState = produce(state, (draftState) => {
        if (action.data && !draftState.items) {
          draftState.items = action.data;
        }
        if (action.data && draftState.items && draftState.index) {
          action.data.forEach((item) => {
            if (draftState.index[item.uuid] === undefined) {
              draftState.items.push(item);
            }
          });
        }
        // sort the items
        draftState.items.sort((a, b) => moment(b.updated) - moment(a.updated));
        // rebuild the index
        const obj = {};
        draftState.items.forEach((item, index) => {
          obj[item.uuid] = index;
        });

        draftState.index = obj;
      });
      return nextState;
    case 'SOURCE_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.items.forEach((item, index) => {
          if (item.uuid === action.data.uuid) {
            draftState.items.splice(index, 1);
          }
        });
      });
      return nextState;
    case 'SOURCES_GET':
      nextState = produce(state, (draftState) => {
        draftState.items = action.data;

        // sort the items
        draftState.items.sort((a, b) => moment(b.updated) - moment(a.updated));
        // rebuild the index
        const obj = {};
        draftState.items.forEach((item, index) => {
          obj[item.uuid] = index;
        });

        draftState.index = obj;
      });
      return nextState;
    default:
      return state;
  }
};

export default sources;
