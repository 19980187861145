import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs,
  Button,
  Form,
  Input,
  Select,
  TimePicker,
  Popconfirm,
  Modal,
  Collapse,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import type { CollapseProps } from 'antd';
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils';
import moment from 'moment';

import { actionPostGet } from '../../Post/actions';
import { postScheduleSendJobStatusTypes } from '../../../configs/component-types';
import { PostFilters } from './PostFilters';
import {
  PickerType,
  buildPostUrl,
  handlePostScheduleDelete,
  handleTestSchedule,
  onFinishHandler,
  range,
  updateProgressData,
} from './helpers';
import './style.css';

const { Option } = Select;

export interface ScheduledSendProps {
  postActionSaveCreator?: (obj: Dictionary) => void;
  open: boolean;
  onCancel: () => void;
}

/** default component props */
const defaultProps = {
  postActionSaveCreator: actionPostGet,
};

enum AsyncTaskTypes {
  SCHEDULE_SEND = 'Schedule Send',
  TEST_SCHEDULE = 'Test Schedule',
}

const ScheduledSend: React.FC<ScheduledSendProps> = (props: ScheduledSendProps) => {
  const dispatch = useDispatch();
  const [type, setType] = useState<PickerType>();
  const post = useSelector((store: Dictionary) => store.post);
  const [form] = Form.useForm();
  const { postActionSaveCreator, open, onCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<
    | {
        stage: postScheduleSendJobStatusTypes;
        data: Dictionary[];
        errors: Dictionary[];
        taskName: string;
      }
    | Dictionary
  >({});
  const [scheduleEmails, setEmails] = useState<Dictionary>({});
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [postFilters, setPostFilters] = useState<string[]>([]);
  const [asyncTaskType, setAsyncTaskType] = useState<AsyncTaskTypes>();
  const postHasSchedules =
    post?.config?.export_schedules !== undefined &&
    Object.keys(post?.config?.export_schedules).length > 0;

  useEffect(() => {
    updateProgressData({
      progress: progress,
      loading: loading,
      asyncTaskType: asyncTaskType,
      setProgress: setProgress,
      setAsyncTaskType: setAsyncTaskType,
      postActionSaveCreator: postActionSaveCreator,
      dispatch: dispatch,
      post: post,
      setType: setType,
      setEmails: setEmails,
      setPostFilters: setPostFilters,
      form: form,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, progress]);

  const onFinish = (values: Dictionary) => {
    onFinishHandler({
      values: values,
      post: post,
      type: type,
      postUrl: `${window.location.origin}/post/${post?.uuid}`,
      setAsyncTaskType: setAsyncTaskType,
      setProgress: setProgress,
      scheduleEmails: scheduleEmails,
      postFilters: postFilters,
      setLoading: setLoading,
      setEmails: setEmails,
    });
  };

  const tabs = [
    {
      key: '1',
      label: 'New Schedule',
      children: (
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="source-refresh"
          key={type}
        >
          <Form.Item
            name="schedule_name"
            label="Schedule Name"
            rules={[{ required: true, message: 'Please enter a schedule name' }]}
          >
            <Input placeholder="Schedule name" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="type"
            label="Schedule Type"
            rules={[{ required: true, message: 'Please select a schedule type' }]}
          >
            <Select value={type} onChange={setType} disabled={loading}>
              <Option value="daily">Daily</Option>
              <Option value="weekly">Weekly</Option>
              <Option value="monthly">Monthly</Option>
              <Option value="cronString">Cron Expression</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="day"
            label="Days of the Week"
            style={{ display: type === 'weekly' ? 'block' : 'none' }}
            rules={[
              { required: type === 'weekly' ? true : false, message: 'Please select days of week' },
            ]}
          >
            <Select mode={'multiple'} disabled={loading}>
              <Option value="monday">Monday</Option>
              <Option value="tuesday">Tuesday</Option>
              <Option value="wednesday">Wednesday</Option>
              <Option value="thursday">Thursday</Option>
              <Option value="friday">Friday</Option>
              <Option value="saturday">Saturday</Option>
              <Option value="sunday">Sunday</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="month"
            label="Months for the schedule to run"
            style={{ display: type === 'monthly' ? 'block' : 'none' }}
            rules={[
              { required: type === 'monthly' ? true : false, message: 'Please select months' },
            ]}
          >
            <Select mode={'multiple'} disabled={loading}>
              <Option value="JANUARY">January</Option>
              <Option value="FEBRUARY">February</Option>
              <Option value="MARCH">March</Option>
              <Option value="APRIL">April</Option>
              <Option value="MAY">May</Option>
              <Option value="JUNE">June</Option>
              <Option value="JULY">July</Option>
              <Option value="AUGUST">August</Option>
              <Option value="SEPTEMBER">September</Option>
              <Option value="OCTOBER">October</Option>
              <Option value="NOVEMBER">November</Option>
              <Option value="DECEMBER">December</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="dayOfMonth"
            label="Dates for the schedule to run"
            style={{ display: type === 'monthly' ? 'block' : 'none' }}
            rules={[
              {
                required: type === 'monthly' ? true : false,
                message: 'Please select dates for the schedule',
              },
            ]}
          >
            <Select mode={'multiple'} disabled={loading}>
              {range(1, 32).map((num) => (
                <Option key={num} value={num}>
                  {num}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="schedule_daily"
            key={type}
            label="Schedule Daily Time in UTC"
            style={{ display: type === 'daily' ? 'block' : 'none' }}
            rules={[{ required: type === 'daily', message: 'Please select time' }]}
          >
            <TimePicker key={type} disabled={loading} format={'HH:mm'} />
          </Form.Item>
          <Form.Item
            name="schedule_weekly"
            key={type}
            label="Schedule Time in UTC"
            style={{ display: type === 'weekly' || type === 'monthly' ? 'block' : 'none' }}
            rules={[
              {
                required: type === 'weekly' || type === 'monthly',
                message: 'Please enter schedule time in utc',
              },
            ]}
          >
            <TimePicker key={type} disabled={loading} format={'HH:mm'} />
          </Form.Item>
          <Form.Item
            name="schedule_cron_expression"
            label="Cron String"
            rules={[{ required: false }]}
            style={{ display: type === 'cronString' ? 'block' : 'none' }}
          >
            <Input.TextArea placeholder="* * * * *" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="post_filters"
            label="Post Filters"
            style={{ display: type !== undefined ? 'block' : 'none' }}
            rules={[{ required: false }]}
          >
            <PostFilters
              setPostFilters={setPostFilters}
              postFilters={postFilters}
              disable={loading}
            />
          </Form.Item>
          <Form.List name="emails">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      label={key === 0 ? 'Emails' : ''}
                      key={key}
                      rules={[
                        {
                          required:
                            Object.values(scheduleEmails).filter((email) => email !== '').length ===
                            0,
                          message: 'Please provide at least one email',
                        },
                      ]}
                    >
                      <Input
                        placeholder="example@example.com"
                        disabled={loading}
                        value={scheduleEmails[key] || ''}
                        style={{ width: '80%', marginRight: 8 }}
                        onChange={(e) => {
                          const userInput = e.target.value;
                          setEmails({
                            ...scheduleEmails,
                            [key]: userInput,
                          });
                        }}
                      />
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Button
                    disabled={loading}
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            name="schedule_comment"
            label="Description"
            rules={[{ required: false }]}
            style={{ display: type !== undefined ? 'block' : 'none' }}
          >
            <Input.TextArea placeholder="Describe schedule" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="report_format"
            label="Report format"
            rules={[{ required: true, message: 'Please select report format' }]}
          >
            <Select disabled={loading}>
              <Option value="pdf">PDF</Option>
              <Option value="png">PNG</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading && asyncTaskType === AsyncTaskTypes.SCHEDULE_SEND && <LoadingOutlined />}{' '}
              {loading && asyncTaskType === AsyncTaskTypes.SCHEDULE_SEND ? 'Saving...' : 'Save'}
            </Button>{' '}
            <Button
              type="primary"
              style={{ display: type !== undefined ? '' : 'none' }}
              disabled={loading}
              onClick={async () => {
                try {
                  const validateFields = await form.validateFields();
                  handleTestSchedule({
                    payload: {
                      post_id: post?.uuid,
                      post_url: `${window.location.origin}/post/${post?.uuid}`,
                      format: validateFields?.report_format,
                      download_file_name: `${post?.title?.split(' ').join('')}.${validateFields?.report_format}`,
                      selector: '.post--content',
                      emails: Object.values(scheduleEmails)
                        .filter((email) => email !== '')
                        .map((email: string) => email),
                      post_filters: postFilters,
                      schedule_name: validateFields?.schedule_name,
                    },
                    setAsyncTaskType: setAsyncTaskType,
                    setLoading: setLoading,
                    setProgress: setProgress,
                  });
                } catch (error) {
                  return;
                }
              }}
            >
              {loading && asyncTaskType === AsyncTaskTypes.TEST_SCHEDULE && <LoadingOutlined />}{' '}
              {loading && asyncTaskType === AsyncTaskTypes.TEST_SCHEDULE
                ? 'Testing...'
                : 'Test schedule'}
            </Button>{' '}
            <Button
              type="primary"
              disabled={loading}
              style={{ display: type !== undefined ? '' : 'none' }}
              href={`${buildPostUrl({
                filters: postFilters,
                postId: post?.uuid,
              })}`}
              target="blank"
            >
              Preview report
            </Button>
          </Form.Item>
        </Form>
      ),
    },
  ];

  if (postHasSchedules) {
    const schedules: CollapseProps['items'] = Object.keys(post?.config?.export_schedules).map(
      (scheduleId, index) => {
        const schedule = post?.config?.export_schedules[scheduleId];
        const scheduleType = schedule?.schedule_type as PickerType;
        const scheduleDescription = schedule?.schedule_comment;
        const scheduleEmails = schedule?.workflow_args?.emails;
        const scheduleReportFormat = schedule?.workflow_args?.format;
        const schedulePostFilters: string[] | undefined = schedule?.workflow_args?.post_filters;
        const scheduleCronExpressions = schedule?.schedule_config?.cronExpressions;
        const calendars = schedule?.schedule_config?.calendars;
        const calendarHours = calendars && calendars[0]?.hour;
        const calendarMinutes = calendars && calendars[0]?.minute;
        const months = calendars && calendars[0]?.month;
        const dayOfMonth = calendars && calendars[0]?.dayOfMonth;
        const dayOfWeek = calendars && calendars[0]?.dayOfWeek;
        let time;
        if ((calendarHours || calendarHours === 0) && (calendarMinutes || calendarMinutes === 0)) {
          time = moment.utc(`${calendarHours}:${calendarMinutes}`, 'HH:mm').local().format('HH:mm');
        }
        return {
          key: `${index}`,
          label: schedule?.schedule_name,
          children: (
            <div style={{ margin: '0 1em' }}>
              {scheduleType === 'daily' ? (
                <div>
                  <label>Daily at</label>
                  {`${time} `}(Local Time)
                </div>
              ) : null}
              {scheduleType === 'weekly' ? (
                <>
                  <div>
                    <label>Weekly at</label>
                    {`${time} `}(Local Time)
                  </div>
                  <div>
                    <label>Days of the week</label>
                    {`${dayOfWeek}`}
                  </div>
                </>
              ) : null}
              {scheduleType === 'monthly' ? (
                <>
                  <div>
                    <label>Monthly at</label>
                    {`${time} `}(Local Time)
                  </div>
                  <div>
                    <label>Months for the schedule to run</label>
                    {`${months}`}
                  </div>
                  <div>
                    <label>Dates for the schedule to run</label>
                    {`${dayOfMonth}`}
                  </div>
                </>
              ) : null}
              {scheduleType === 'cronString' && scheduleCronExpressions !== undefined ? (
                <div>
                  <label>Cron Expression</label>
                  {`${scheduleCronExpressions[0]}`}
                </div>
              ) : null}
              {schedulePostFilters !== undefined && schedulePostFilters.length > 0 ? (
                <div>
                  <label>Post Filters</label>
                  {schedulePostFilters.map((postFilterOption: string) => {
                    const isLongOption = postFilterOption.length > 20;
                    const postFilterOptionElem = (
                      <Tag key={postFilterOption} closable={false} style={{ userSelect: 'none' }}>
                        <span>
                          {isLongOption ? `${postFilterOption.slice(0, 20)}...` : postFilterOption}
                        </span>
                      </Tag>
                    );
                    return isLongOption ? (
                      <Tooltip title={postFilterOption} key={postFilterOption}>
                        {postFilterOptionElem}
                      </Tooltip>
                    ) : (
                      postFilterOptionElem
                    );
                  })}
                </div>
              ) : null}
              {scheduleEmails !== undefined ? (
                <div>
                  <label>Emails</label>
                  <Space size={[0, 8]} wrap>
                    {scheduleEmails.map((email: string) => {
                      return <Tag key={email}>{email}</Tag>;
                    })}
                  </Space>
                </div>
              ) : null}
              {scheduleReportFormat !== undefined ? (
                <div>
                  <label>Report format</label>
                  {`${scheduleReportFormat.toUpperCase()}`}
                </div>
              ) : null}
              {scheduleDescription !== undefined ? (
                <div>
                  <label>Description</label>
                  {`${scheduleDescription}`}
                </div>
              ) : null}
              <div className="input-field">
                <Popconfirm
                  title="Are you sure you want to delete this schedule?"
                  onConfirm={() => {
                    handlePostScheduleDelete({
                      scheduleId: scheduleId,
                      setShowDeleteSpinner: setShowDeleteSpinner,
                      postActionSaveCreator: postActionSaveCreator,
                      dispatch: dispatch,
                      post: post,
                    });
                  }}
                >
                  <Button danger disabled={showDeleteSpinner}>
                    {showDeleteSpinner && <LoadingOutlined />}{' '}
                    {showDeleteSpinner ? 'Deleting...' : 'Delete'}
                  </Button>
                </Popconfirm>
              </div>
            </div>
          ),
        };
      }
    );

    tabs.push({
      key: '2',
      label: 'Existing Schedules',
      children: (
        <Collapse
          bordered={true}
          items={schedules}
          style={{ borderBottom: '1px solid #d9d9d9' }}
          className="schedules-collapse"
        />
      ),
    });
  }

  return (
    <Modal open={open} onCancel={onCancel} footer={null} title={'Scheduled Send form'}>
      <Tabs defaultActiveKey="1" items={tabs} />
    </Modal>
  );
};

ScheduledSend.defaultProps = defaultProps;
export { ScheduledSend };
