import React, { useEffect, useState } from 'react';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  actionSourceGet,
  actionSourceGeometryDelete,
  actionSourceGeometryAdd,
  actionSourceMeasureAdd,
  actionSourceMeasureDelete,
  actionSourceSave,
  actionSourceDimensionAdd,
  actionSourceDimensionDelete,
} from './actions';
import { actionAccountSpaceGet } from '../Account/actions';
import { Row, Col, Button, Collapse, Tooltip, message } from 'antd';
import { AkukoAPIService } from '../../services/serviceClass';
import { SOURCES_API, IDENTITY_API, TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../configs/env';
import { SettingsForm } from './components/SettingsForm';
import { MeasureForm } from './components/MeasureForm';
import { GeometryForm } from './components/GeometryForm';
import { DimensionForm } from './components/DimensionForm';
import { QueryForm } from './components/QueryForm';
import {
  DeleteOutlined,
  NumberOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  FieldStringOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import './style.css';
import { CubeSchemaForm } from './components/CubeSchemaForm';
import { deleteSourceCubeDimensions, deleteSourceCubeMeasures, transformSourceUIDimensionsAndMeasuresToCubeFormat } from './helpers/helpers';
import { defaultProgressStatusTypesForAsyncTasks, Source } from '../../configs/component-types';
import { ERROR_GENERIC } from '../../configs/constants';
import { triggerAsycSourceOperation } from './components/SourceWebsocketsUpdatesHelpers';
const { Panel } = Collapse;
export interface SourceProps {
  sourceId?: string;
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

enum CubePropertiesTypes {
  DIMENSION = 'Dimension',
  MEASURE = 'Measure',
}

const SourceComponent: React.FC<SourceProps> = (props: SourceProps) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { sourceId } = props;
  const params: Dictionary = useParams();
  const location: Dictionary = useLocation();
  const source = useSelector((store: Dictionary) => store.source);
  const { sourceActionSaveCreator } = props;
  const [cubePropertyDeletionData, setCubePropertyDeletionData] = useState<{
    propertyName: string | undefined,
    propertyIndex: number | undefined,
  }>({
    propertyName: undefined,
    propertyIndex: undefined,
  });
  const [deletingCubeProperty, setDeletingCubeProperty] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState<
      | { stage: defaultProgressStatusTypesForAsyncTasks; data: Dictionary[]; errors: Dictionary[] }
      | Dictionary
    >({});

  useEffect(() => {
      if (deleteProgress?.stage === defaultProgressStatusTypesForAsyncTasks.DONE) {
        if (deleteProgress?.data[0]?.source) {
          if (sourceActionSaveCreator) {
            dispatch(
              /* @ts-ignore */
              sourceActionSaveCreator({
                ...deleteProgress?.data[0]?.source,
              })
            );
          }
        }
        message.success(`${cubePropertyDeletionData?.propertyName || 'Cube property'} deleted successfully`);
        setCubePropertyDeletionData({
          propertyName: undefined,
          propertyIndex: undefined,
        });
        setDeleteProgress({});
        setDeletingCubeProperty(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteProgress]);

  useEffect(() => {
    const service = new AkukoAPIService(SOURCES_API, 'source');
    if (sourceId) {
      service.read(sourceId).then((source) => {
        dispatch(actionSourceGet(source));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId]);

  useEffect(() => {
    if (params.id && location.pathname.includes('sources/')) {
      const service = new AkukoAPIService(IDENTITY_API, 'space');
      service.read(params.id).then((space) => {
        dispatch(actionAccountSpaceGet(space));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <div className="source-form">
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={[1, 2, 3, 4, 5, 6]} expandIconPosition={'right'}>
            <Panel key={1} header={'Settings'}>
              <SettingsForm />
            </Panel>
            {Object.keys(source).length !== 0 && source?.dimensions.length !== 0 && (
              <>
                <Panel
                  key={2}
                  header={
                    <Tooltip
                      title={`Dimensions are referred to as categorical data, such as state, gender, product name,
                  or units of time (e.g., day, week, month).`}
                    >
                      Dimensions ({source?.dimensions?.length || 0}) &nbsp;
                      <InfoCircleOutlined style={{ fontSize: '12px' }} />
                    </Tooltip>
                  }
                >
                  {source?.dimensions?.map((item: Dictionary, index: number) => (
                    <Collapse key={index} expandIconPosition={'right'}>
                      <Panel
                        key={index}
                        className="dimension-panel"
                        header={
                          <>
                            <span className="collapse-header-label">
                              <span className="ui-icon ui-icon--dimension">
                                {item.type === 'string' && <FieldStringOutlined />}
                                {item.type === 'number' && <NumberOutlined />}
                                {item.type === 'time' && <CalendarOutlined />}
                              </span>
                              {item?.value || `Dimension ${index + 1}`}
                            </span>
                            {item.title && (
                              <>
                                &nbsp;&nbsp;<span>{item.title}</span>
                              </>
                            )}
                            {(cubePropertyDeletionData?.propertyName === CubePropertiesTypes.DIMENSION &&
                              cubePropertyDeletionData?.propertyIndex === index &&
                              deletingCubeProperty === true
                            ) ? (
                              <LoadingOutlined
                                className="btn--item-loading"
                              />
                            ): (
                              !source.public && (
                                <DeleteOutlined
                                  className="btn--item-delete"
                                  onClick={() => {
                                    if (item?.value) {
                                      setDeletingCubeProperty(true);
                                      setCubePropertyDeletionData({
                                        propertyName: CubePropertiesTypes.DIMENSION,
                                        propertyIndex: index,
                                      });
                                      triggerAsycSourceOperation({
                                        asynFunc: deleteSourceCubeDimensions,
                                        asynFuncArgs: {
                                          sourceId: source?.uuid,
                                          dimensionNames: [item?.value],
                                          cubeName: source?.cube,
                                        },
                                        asyncTaskName: "Dimension deletion",
                                        setAsyncTaskProgressData: setDeleteProgress,
                                        setLoading: setDeletingCubeProperty,
                                        webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
                                      });
                                    } else {
                                      dispatch(
                                        actionSourceDimensionDelete({
                                          index: index,
                                        })
                                      );
                                    }
                                  }}
                                />
                              )
                            )
                            }
                          </>
                        }
                      >
                        <DimensionForm key={JSON.stringify(item)} item={item} />
                      </Panel>
                    </Collapse>
                  ))}
                  <Button
                    disabled={source.public ? true : false}
                    onClick={() => {
                      dispatch(
                        actionSourceDimensionAdd({
                          value: {
                            value: '',
                            custom: true,
                          },
                        })
                      );
                    }}
                  >
                    Add dimension
                  </Button>
                </Panel>
                <Panel
                  key={3}
                  header={
                    <Tooltip
                      title={`Measures are referred to as quantitative data, such as number of units sold, number
                    of unique visits, profit, and so on.`}
                    >
                      Measures ({source?.measures?.length || 0}) &nbsp;
                      <InfoCircleOutlined style={{ fontSize: '12px' }} />
                    </Tooltip>
                  }
                >
                  {source?.measures?.map((item: Dictionary, index: number) => (
                    <Collapse key={index} expandIconPosition={'right'}>
                      <Panel
                        key={index}
                        className="measure-panel"
                        header={
                          <>
                            <span className="collapse-header-label">
                              <span className="ui-icon ui-icon--measure">
                                <NumberOutlined />
                              </span>
                              {item?.name || `Measure ${index + 1}`}
                            </span>
                            {item.title && (
                              <>
                                &nbsp;&nbsp;<span>{item.title}</span>
                              </>
                            )}
                            {(cubePropertyDeletionData?.propertyName === CubePropertiesTypes.MEASURE &&
                              cubePropertyDeletionData?.propertyIndex === index &&
                              deletingCubeProperty === true
                            ) ? (
                              <LoadingOutlined
                                className="btn--item-loading"
                              />
                            ): (
                              !source.public && (
                                <DeleteOutlined
                                  className="btn--item-delete"
                                  onClick={() => {
                                    if (item?.name) {
                                      setDeletingCubeProperty(true);
                                      setCubePropertyDeletionData({
                                        propertyName: CubePropertiesTypes.MEASURE,
                                        propertyIndex: index,
                                      });
                                      triggerAsycSourceOperation({
                                        asynFunc: deleteSourceCubeMeasures,
                                        asynFuncArgs: {
                                          sourceId: source?.uuid,
                                          measureNames: [item?.name],
                                          cubeName: source?.cube,
                                        },
                                        asyncTaskName: "Measure deletion",
                                        setAsyncTaskProgressData: setDeleteProgress,
                                        setLoading: setDeletingCubeProperty,
                                        webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
                                      });
                                    } else {
                                      dispatch(
                                        actionSourceMeasureDelete({
                                          index: index,
                                        })
                                      );
                                    }
                                  }}
                                />
                              )
                            )
                          }
                          </>
                        }
                      >
                        <MeasureForm key={JSON.stringify(item)} item={item} />
                      </Panel>
                    </Collapse>
                  ))}
                  <Button
                    disabled={source.public ? true : false}
                    onClick={() => {
                      dispatch(
                        actionSourceMeasureAdd({
                          value: {
                            name: '',
                          },
                        })
                      );
                    }}
                  >
                    Add measure
                  </Button>
                </Panel>
                <Panel
                  key={4}
                  header={
                    <Tooltip
                      title={`Geometries are spatially bounded entities. You can join a Source to a Geometry by
                    using a Dimension with the same value as a property in the Geometry.`}
                    >
                      Geometries ({source?.geometries?.length || 0}) &nbsp;
                      <InfoCircleOutlined style={{ fontSize: '12px' }} />
                    </Tooltip>
                  }
                >
                  {source?.geometries?.map((item: Dictionary, index: number) => (
                    <Collapse key={index} expandIconPosition={'right'}>
                      <Panel
                        key={index}
                        header={
                          <>
                            <span>
                              <EnvironmentOutlined /> {item?.name || `Geometry ${index + 1}`}
                            </span>
                            {!source.public && (
                              <DeleteOutlined
                                className="btn--item-delete"
                                onClick={() => {
                                  dispatch(
                                    actionSourceGeometryDelete({
                                      index: index,
                                    })
                                  );
                                  // save source
                                  setTimeout(() => {
                                    const currentState = store.getState();
                                    const service = new AkukoAPIService(
                                      SOURCES_API,
                                      `source/${source.uuid}`
                                    );
                                    /* @ts-ignore */
                                    service.update(currentState.source).catch((error) => {
                                      message.error(error.message || ERROR_GENERIC);
                                    });
                                  }, 1000);
                                }}
                              />
                            )}
                          </>
                        }
                      >
                        {!source.public ? (
                          <GeometryForm itemIndex={index} item={item} />
                        ) : (
                          <p>This geometry can not be edited as this is a public source.</p>
                        )}
                      </Panel>
                    </Collapse>
                  ))}
                  <Button
                    disabled={source.public ? true : false}
                    onClick={() => {
                      dispatch(
                        actionSourceGeometryAdd({
                          value: {
                            name: '',
                          },
                        })
                      );
                    }}
                  >
                    Add geometry
                  </Button>
                </Panel>
                <Panel key={5} header={'Cube'}>
                  <CubeSchemaForm />
                </Panel>
                <Panel key={6} header={'Query'}>
                  <QueryForm />
                </Panel>
              </>
            )}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

SourceComponent.defaultProps = defaultProps;
export default SourceComponent;
