import { Dictionary } from '@onaio/utils/dist/types/types';

export const layerIsVisble = (post: Dictionary, mapId: string, layerId: string): boolean => {
  if (layerId && mapId) {
    const targetComponent = post.components.filter(
      (component: Dictionary) => component.id === mapId
    );
    const targetLayer = targetComponent?.[0].layers?.filter(
      (layer: Dictionary) => layer.id === layerId[0]
    );
    if (targetLayer?.[0]?.visible) {
      return true;
    }
  }
  return false;
};

export const componentIsVisible = (post: Dictionary, componentId: string): boolean => {
  if (componentId) {
    const targetComponent = post.components.find(
      (component: Dictionary) => component.id === componentId
    );
    if (targetComponent?.hidden) {
      return false;
    }
    return true;
  }
  return false;
};

export const layerPropertyIsActive = (
  post: Dictionary,
  mapId: string,
  layerId: string,
  event: Dictionary
): boolean | undefined => {
  if (layerId && mapId) {
    const targetComponent = post.components.filter(
      (component: Dictionary) => component.id === mapId
    );
    const targetLayer = targetComponent?.[0].layers?.filter(
      (layer: Dictionary) => layer.id === layerId[0]
    );
    if (targetLayer?.[0]?.[event.property] === event.value) {
      return true;
    }
  }
};
