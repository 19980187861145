import React from 'react';
import { Dictionary } from '@onaio/utils/dist/types/types';
import {
  TableOutlined,
  SettingOutlined,
  DatabaseOutlined,
  LayoutOutlined,
  AlignLeftOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  AppstoreOutlined,
  PictureOutlined,
  FilterOutlined,
  NumberOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { POSTS_API } from '../../../../configs/env';
import { TextInput } from '../components/TextInput';
import { TextAreaInput } from '../components/TextAreaInput';
import { SelectInput } from '../components/SelectInput';
import { CheckboxInput } from '../components/CheckboxInput';
import { SliderInput } from '../components/SliderInput';
import { ArrayInput } from '../components/ArrayInput';
import { ColorInput } from '../components/ColorInput';
import { ColorRangeInput } from '../components/ColorRangeInput';
import { SourceInput } from '../components/SourceInput';
import { PointerInput } from '../components/PointerInput';
import { DimensionInput } from '../components/DimensionInput';
import { IconInput } from '../components/IconInput';
import { ImageInput } from '../components/ImageInput';
import { LayoutInput } from '../components/LayoutInput';
import { FontFamilyInput } from '../components/FontFamilyInput';
import { FontSizeInput } from '../components/FontSizeInput';
import { LayerSelectInput } from '../components/LayerSelectInput';
import {
  NumberInput,
} from '../components/NumberInput';
import { PillsInput } from '../components/PillsInput';
import { SwitchInput, SwitchSettingsProps } from '../components/SwitchInput';
import { DimensionValueInput } from '../components/DimensionValueInput';
import { GeometryInput } from '../components/GeometryInput';
import { GroupByColor } from '../components/GroupByColor';
import { LatLngInput } from '../components/LatLngInput';
import { DimensionGroupBy } from '../components/DimensionGroupBy';
import { Properties } from '../components/GlobalFilterComponent';
import { SelectStepsInput } from '../components/SelectStepsInput';
import { ComponentInput } from '../components/ComponentInput';
import { ActionCreator, AnyAction, Dispatch } from 'redux';
import { message } from 'antd';

/**
 * Utility function that determines whether to show a field based on dependencies
 * @param array array of components as defined in the post
 * @param itemIndex index of the nested component
 * @param field field property on settings definition
 * @returns
 */
export const shouldShowPostField = (
  post: Dictionary,
  field: Dictionary
): boolean => {
  const dependsOnStatus: boolean[] = [];
  let result = true;
  if (!field.dependsOn) {
    return true;
  }
  field.dependsOn.forEach((item: Dictionary) => {
    if (post[item.property] === item.value) {
      dependsOnStatus.push(true);
    } else {
      dependsOnStatus.push(false);
    }
  });
  result = dependsOnStatus.every((item) => item);
  return result;
};

/**
 * Utility function that determines whether to show a field based on dependencies
 * @param array array of components as defined in the post
 * @param itemIndex index of the nested component
 * @param field field property on settings definition
 * @returns
 */
export const shouldShowField = (
  array: Dictionary,
  itemIndex: number,
  field: Dictionary,
  component?: Dictionary
): boolean => {
  if (field.hasContext) {
    const component = array?.[itemIndex];
    if (component.region || component.layout) {
      return true;
    }
    return false;
  }
  const dependsOnStatus: boolean[] = [];
  let result = true;
  if (!field.dependsOn) {
    return true;
  }
  field.dependsOn.forEach((item: Dictionary) => {
    if (Array.isArray(item.value) && item.value[0] === '!') {
      // don't show if property is in array
      result = !item.value.includes(array?.[itemIndex]?.[item.property]);
    } else if (item.parent && Array.isArray(item.value)) {
      result = item.value.includes(component?.[item.property]);
    } else if (Array.isArray(item.value) && !item.parent) {
      // show if property has any value in the array
      result = item.value.includes(array?.[itemIndex]?.[item.property]);
    } else if (Array.isArray(item.property)) {
      if (component?.[item.property[0]].length > 1) {
        result = false;
      }
    } else if (item.value === '*') {
      if (
        array?.[itemIndex]?.[item.property] === undefined ||
        array?.[itemIndex]?.[item.property] === '' ||
        array?.[itemIndex]?.[item.property] === null
      ) {
        result = false;
      }
      // don't show if property has any value
    } else if (item.value === '!') {
      if (array?.[itemIndex]?.[item.property]) {
        result = false;
      }
    } else if (item.nestedDataTypeKey) {
      if (
        array?.[itemIndex]?.[item.property] &&
        array?.[itemIndex][item.property]?.[item.nestedDataTypeKey] !==
          item.value
      ) {
        result = false;
      }
    } else if (item.dataTypeKey) {
      if (array?.[itemIndex]?.[item.dataTypeKey] !== item.value) {
        result = false;
      }
    } else {
      // show if property matches value
      if (array?.[itemIndex]?.[item.property] !== item.value) {
        result = false;
      }
    }
    if (field.entity === 'post') {
      result = component?.[item.property] === item.value;
    }
    dependsOnStatus.push(result);
  });
  result = dependsOnStatus.every((item) => item);
  return result;
};

/**
 * Decide Icon to load
 * @param group Component Settings Group
 * @returns Ant Design Icon
 */

export const getIcon = (group: string): JSX.Element => {
  if (group === 'Settings') {
    return <SettingOutlined />;
  }
  if (group === 'Source') {
    return <DatabaseOutlined />;
  }
  if (group === 'Style') {
    return <LayoutOutlined />;
  }
  if (group === 'Export') {
    return <ExportOutlined />;
  }
  return <TableOutlined />;
};

/**
 *
 * @param item component settings properties
 * @param componentIndex component index with reference to stores post.components
 * @param index field index with reference to stores post.components[componentIndex].fields
 * @returns Component Setting
 */

export const getComponentBasedOnType = (
  item: Dictionary,
  componentIndex: number,
  index: number,
  childIndex?: number,
  itemIndex?: number
): JSX.Element => {
  const { type } = item;
  switch (type) {
    case 'text':
      return (
        <TextInput
          key={index}
          item={item}
          componentIndex={componentIndex}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'textarea':
      return (
        <TextAreaInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'select':
      return (
        <SelectInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'fontFamily':
      return (
        <FontFamilyInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'fontSize':
      return (
        <FontSizeInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'selectSteps':
      return (
        <SelectStepsInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'checkbox':
      return (
        <CheckboxInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'pointer-input':
      return (
        <PointerInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'layout':
      return (
        <LayoutInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'layer':
      return (
        <LayerSelectInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'slider':
      return (
        <SliderInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'source':
      return (
        <SourceInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'lat-lng':
      return (
        <LatLngInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'array':
      return (
        <ArrayInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'color':
      return (
        <ColorInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'component':
      return (
        <ComponentInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'color-range':
      return (
        <ColorRangeInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'pills':
      return (
        <PillsInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'icon':
      return (
        <IconInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'dimension':
      return (
        <DimensionInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'switch':
      return (
        <SwitchInput
          key={index}
          componentIndex={componentIndex}
          item={item as SwitchSettingsProps}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'number':
      return (
        <NumberInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'image':
      return (
        <ImageInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'dimension-value':
      return (
        <DimensionValueInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex as number}
        />
      );
    case 'geometry':
      return (
        <GeometryInput
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'group-by':
      return (
        <GroupByColor
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'dimension-group-by':
      return (
        <DimensionGroupBy
          key={index}
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
        />
      );
    case 'filter':
      return (
        <Properties key={index} componentIndex={componentIndex} item={item} />
      );
    default:
      // returns empty component
      return <></>;
  }
};

export const isGlobalFilter = (
  component: Dictionary,
  parents: string[],
  childIndex?: number,
  itemIndex?: number
): boolean => {
  let isGlobal;
  if (
    parents?.length === 2 &&
    itemIndex !== undefined &&
    childIndex !== undefined
  ) {
    isGlobal =
      component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]
        ?.isGlobal;
  } else if (parents?.length === 1 && itemIndex !== undefined) {
    isGlobal = component?.[parents[0]]?.[itemIndex]?.isGlobal;
  }
  return isGlobal ? true : false;
};

export const isInterpolationSyntax = (text: string): boolean => {
  return !text?.includes('##');
};

/**
 *
 * @param item component settings properties
 * @returns Component Setting
 */

export const getComponentIconAndText = (
  item: Dictionary,
  layer?: Dictionary
): JSX.Element => {
  const { type, name } = item;
  switch (type) {
    case 'text':
      return (
        <>
          <AlignLeftOutlined />
          &nbsp;{' '}
          {isInterpolationSyntax(item.text)
            ? `${item.text.substring(0, 15)}...`
            : name || type}
        </>
      );
    case 'number':
      return (
        <>
          <NumberOutlined />
          &nbsp; {name || type}
        </>
      );

    case 'map':
      return (
        <>
          <EnvironmentOutlined />
          &nbsp; {layer?.layerName || 'layer'}
        </>
      );
    case 'chart':
      return (
        <>
          <LineChartOutlined />
          &nbsp; {name || type}
        </>
      );
    case 'card':
      return (
        <>
          <AppstoreOutlined />
          &nbsp; {name || type}
        </>
      );
    case 'table':
      return (
        <>
          <TableOutlined />
          &nbsp; {name || type}
        </>
      );
    case 'image':
      return (
        <>
          <PictureOutlined />
          &nbsp; {name || type}
        </>
      );
    case 'layout':
      return (
        <>
          <LayoutOutlined />
          &nbsp; {name || type}
        </>
      );
    case 'filter':
      return (
        <>
          <FilterOutlined />
          &nbsp; {name || type}
        </>
      );
    default:
      // returns empty component
      return <></>;
  }
};

export const closeProfileMenu = (
  dispatch: Dispatch,
  drawerOpenAction: ActionCreator<AnyAction>,
  componentIndexActivationAction: ActionCreator<AnyAction>
): void => {
  dispatch(drawerOpenAction({ value: false }));
  dispatch(componentIndexActivationAction({ value: null }));
  document.body.classList.remove('drawer-open');
};

export const SavePost = (post: Dictionary) => {
  const service = new AkukoAPIService(POSTS_API, `post/${post.uuid}`);
  return service
    .update({
      ...post,
      data: {},
      sources: {},
    })
    .catch((err) => {
      message.error(err);
    });
};
