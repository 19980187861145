import { Dictionary } from '@onaio/utils/';

export const title: Dictionary = {
  groups: ['Post title', 'Template'],
  styles: ['Width', 'Title', 'Subtitle'],
  sections: {},
  fields: [
    {
      type: 'text',
      entity: 'post',
      label: 'Title',
      property: 'title',
      group: 'Post title',
    },
    {
      type: 'text',
      entity: 'post',
      label: 'Subtitle',
      placeHolder: 'A great post description',
      property: 'description',
      group: 'Post title',
    },
    {
      type: 'switch',
      label: 'Hide author',
      property: 'hideAuthor',
      group: 'Post title',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'number',
      property: 'titleFontSize',
      label: 'Font size',
      group: 'Title',
      min: 10,
      max: 100,
      defaultValue: 36,
      placeHolder: '36',
    },
    {
      type: 'fontFamily',
      property: 'titleFontFamily',
      placeHolder: 'PT Serif',
      label: 'Font family',
      group: 'Title',
    },
    {
      type: 'color',
      property: 'headingColor',
      label: 'Color',
      placeHolder: '#111111',
      group: 'Title',
    },
    {
      type: 'number',
      property: 'fontSize',
      label: 'Font size',
      group: 'Subtitle',
      min: 10,
      max: 100,
      defaultValue: 14,
      placeHolder: '14',
    },
    {
      type: 'fontFamily',
      property: 'fontFamily',
      placeHolder: 'PT Serif',
      label: 'Font family',
      group: 'Subtitle',
    },
    {
      type: 'color',
      property: 'color',
      label: 'Text color',
      placeHolder: '#111111',
      group: 'Subtitle',
    },
    {
      type: 'select',
      label: 'Post type',
      property: 'config',
      objectKey: 'type',
      group: 'Template',
      placeHolder: 'Notebook',
      entity: 'post',
      options: [
        { value: 'default', label: 'Notebook' },
        { value: 'map', label: 'Map' },
      ],
    },
  ],
};
