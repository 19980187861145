import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, Modal, Button, Tabs, Input } from 'antd';
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API, SOURCES_API } from '../../../configs/env';
import {
  actionComponentSourcesGet
} from '../../Post/actions';
import { Link } from 'react-router-dom';
import { EnvironmentOutlined, LinkOutlined } from '@ant-design/icons';
import { actionSourceEdit } from '../actions';
import { Dictionary } from '@onaio/utils';
const { Option } = Select;
const { TabPane } = Tabs;
const { Search } = Input;

export interface SourceGeojsonInputProps {
  onChange: (value: Dictionary) => void;
  value: string;
}

const SourceGeojsonInput: React.FC<SourceGeojsonInputProps> = (props: SourceGeojsonInputProps) => {
  const { value } = props;
  const source = useSelector((store: Dictionary) => store.source);
  const sources = useSelector((state: Dictionary) => state.sources);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const spaceId = source.space_id;
  const [search, setSearch] = useState('');

  useEffect(() => {
    const service = new AkukoAPIService(SOURCES_API, `source/space/${spaceId}`);
    service.list().then((sources) => {
      dispatch(actionComponentSourcesGet(sources));
    });
  }, [spaceId]);

  useEffect(() => {
    const service = new AkukoAPIService(SOURCES_API, `source/public`);
    service.list().then((sources) => {
        dispatch(actionComponentSourcesGet(sources));
      })
  }, [spaceId]);

  const getGeometryName = (value: string) => {
    let sourceName = 'Source'
    let geometryName = 'Geometry'
    sources?.items.forEach((item: Dictionary) => {
      item?.geometries?.forEach((geometry: Dictionary) => {
        if (geometry.id === value) {
          sourceName = item.name;
          geometryName = geometry.name;
        }
      })
    })
    return `${sourceName} - ${geometryName}`;
  }

 return (
   <div>
    <label>Geometry</label>
   <Button onClick={() => {
     setShowModal(true)
   }}>
      { props.value ? (
         getGeometryName(props.value)
        ) : (
          'Select Geometry'
        )
      }
   </Button>
   <Modal
     className="geometry-select-modal"
     visible={showModal}
     onCancel={() => {
      setShowModal(false)
     }}
     footer={false}
   >
     <Tabs>
       <TabPane tab="My Geometries" key="private">
       <Search
        placeholder="Search Sources..." 
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
       { sources?.items.map((item: Dictionary, index: number) => (
         !item.public &&
       item?.geometries?.length > 0 &&
       item?.geometries.map((geometry: Dictionary, index: number) => (
         geometry?.id &&
         (`${item.name} - ${geometry.name}`).toLowerCase().includes(search.toLowerCase()) &&
         <div className="geometry-item" key={index}>
          <Link target="blank" className="geometry-link" to={`/source/${item.uuid}`}>
           <LinkOutlined />
         </Link>
         <h2>{item.name} - {geometry.name }</h2>
         <div className="geometry-thumb"><EnvironmentOutlined /></div>
          <p>id: { geometry.id }</p>
          <Button 
            type="primary"
            onClick={(e) => {
              setShowModal(false);
              props.onChange({
                value: geometry.id,
                dimensions: geometry.dimensions,
              });
            }}
            >
            Select Geometry
          </Button>
        </div>
       ))
     ))}
       </TabPane>
       <TabPane tab="Public Geometries" key="public">
       <Search
        placeholder="Search Sources..." 
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
     { sources?.items.map((item: Dictionary, index: number) => (
       item.public &&
       item?.geometries?.length > 0 &&
       item?.geometries.map((geometry: Dictionary, index: number) => (
         geometry?.id &&
         (`${item.name} - ${geometry.name}`).toLowerCase()?.includes(search.toLowerCase()) &&
         <div className="geometry-item" key={index}>
          <Link target="blank" className="geometry-link" to={`/source/${item.uuid}`}>
           <LinkOutlined />
         </Link>
          <h2>{item.name} - {geometry.name }</h2>
          <div className="geometry-thumb"><EnvironmentOutlined /></div>
          <p>id: { geometry.id }</p>
          <Button 
            type="primary"
            onClick={(e) => {
              setShowModal(false);
              props.onChange({
                value: geometry.id,
                dimensions: geometry.dimensions,
              });
            }}
            >
            Select Geometry
          </Button>
        </div>
       ))
     ))}
     </TabPane>
     </Tabs>
   </Modal>
   </div>
 )
};

export { SourceGeojsonInput };
