import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { PostComponentProps } from '../../PostComponent';
import Region from './Region';

export interface LayoutTypesProps {
  post: PostComponentProps;
  componentIndex: number;
}

export const DrawerLayout = (props: LayoutTypesProps): JSX.Element => {
  const { componentIndex, post } = props;
  const component = post.components?.[componentIndex as number];
  const [open, setOpen] = useState<boolean>(component?.hidden ? false : true);

  useEffect(() => {
    setOpen(component?.hidden ? false : true);
  }, [component?.hidden]);
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title="Drawer with extra actions"
      placement={component?.placement || 'left'}
      width={500}
      onClose={onClose}
      open={open || false}
    >
      <Region regionIndex={1} {...props} />
    </Drawer>
  );
};
