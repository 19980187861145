import React, { Component } from 'react';
import { BugOutlined } from '@ant-design/icons';
import './Error.css';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className="error-page">
          <div className="bug">
            <BugOutlined />
          </div>
          <h1>Uh oh!</h1>
          <p>You found a bug. We will get a fix pushed asap.</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
