import { Dictionary } from '@onaio/utils/dist/types/types';

export const getAuthors = (posts: Dictionary[]) => {
  return new Promise((resolve, reject) => {
    const array: string[] = [];
    if (posts) {
    posts.forEach((item: Dictionary) => {
      array.push(item.author)
    })
    const unique = new Set(array);
    resolve(Array.from(unique));
   }
  }) 
  }
