import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { Row, Col, Collapse } from 'antd';
import {
  CloseOutlined,
  DragOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  actionPostArrayItemDelete,
  actionPostArrayItemMove,
  actionPostComponentSettingEdit,
} from '../../../../actions';
import { getComponentBasedOnType, isGlobalFilter, shouldShowField } from '../../../helpers/helpers';

import { ArrayItemGroup } from './ArrayItemGroup';
import './style.css';
const { Panel } = Collapse;

export interface ArrayItemProps {
  componentIndex: number;
  itemIndex: number;
  childIndex?: number;
  item: Dictionary;
  step: Dictionary;
}

const ArrayItem: React.FC<ArrayItemProps> = (props: ArrayItemProps) => {
  const { componentIndex, childIndex, itemIndex, item } = props;
  const {
    property,
    groups,
    settings,
    parents,
    collapseProperty,
    collapseParent,
    fields,
    itemLabel,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const component = post.components[componentIndex];
  const [itemIndexDragged, setItemIndexDragged] = useState(0);
  const [dragTarget, setDragTarget] = useState(0);
  const dispatch = useDispatch();

  let array: Dictionary = [];
  if (!parents) {
    array = component[property];
  }
  if (parents && parents.length === 1) {
    array = component?.[parents[0]]?.[itemIndex]?.[property];
  }

  const isGlobal =
    property === 'filters' &&
    (isGlobalFilter(component, ['filters'], childIndex, itemIndex) ||
      isGlobalFilter(component, ['layers', 'filters'], childIndex, itemIndex));

  const getPanelLabel = () => {
    let label = `${itemLabel} ${itemIndex + 1}`;
    if (collapseProperty && collapseParent && childIndex !== undefined) {
      label =
        component?.[parents[0]]?.[childIndex || 0]?.[collapseParent]?.[itemIndex]?.[
          collapseProperty
        ];
      if (!label) {
        label = `${itemLabel} ${itemIndex + 1}`;
      }
    }
    if (!parents) {
      label = component?.[property][itemIndex][collapseProperty] || label;
    }
    return `${label}${isGlobal ? ' (Global)' : ''}`;
  };

  const AdvancedControl = (
    <MoreOutlined
      onClick={() => {
        setShowAdvanced(showAdvanced === true ? false : true);
      }}
      style={{ fontSize: '14px' }}
    />
  );

  const showGroup = (group: Dictionary) => {
    if (group.label === 'Source') {
      return true;
    }
    if (group.label !== 'Source' && array?.[itemIndex]?.source !== '') {
      return true;
    }
    return false;
  };

  return (
    <div className="field-input--item">
      <Collapse
        className="field-input--collapse"
        expandIconPosition={'right'}
        defaultActiveKey={['0']}
        collapsible="header"
      >
        <Panel
          className="field-input--collapse-panel"
          header={
            <>
              {getPanelLabel()}
              {array?.[itemIndex]?.visible === true && (
                <EyeOutlined
                  style={{ position: 'absolute', right: '75px', top: '11px', fontSize: '11px' }}
                  onClick={(e) => {
                    dispatch(
                      actionPostComponentSettingEdit({
                        property: 'visible',
                        componentIndex: componentIndex,
                        itemIndex: itemIndex,
                        parents: [collapseParent.toLowerCase()],
                        value: false,
                      })
                    );
                    e.stopPropagation();
                  }}
                />
              )}
              {array?.[itemIndex]?.visible === false && (
                <EyeInvisibleOutlined
                  style={{ position: 'absolute', right: '75px', top: '11px', fontSize: '11px' }}
                  onClick={(e) => {
                    dispatch(
                      actionPostComponentSettingEdit({
                        property: 'visible',
                        componentIndex: componentIndex,
                        itemIndex: itemIndex,
                        parents: [collapseParent.toLowerCase()],
                        value: true,
                      })
                    );
                    e.stopPropagation();
                  }}
                />
              )}
              <DragOutlined
                disabled={isGlobal}
                style={
                  isGlobal
                    ? { display: 'none' }
                    : {
                        cursor: 'move',
                        fontSize: '11px',
                        position: 'absolute',
                        top: '35%',
                        right: '55px',
                      }
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onMouseDown={(e) => {
                  let nestedItem;
                  if (parents && parents.length > 0) {
                    nestedItem = [parents[0], item.property];
                  } else {
                    nestedItem = [collapseParent.toLowerCase()];
                  }
                  dispatch(
                    actionPostComponentSettingEdit({
                      property: 'draggable',
                      componentIndex: componentIndex,
                      itemIndex: itemIndex,
                      childIndex: childIndex,
                      parents: nestedItem,
                      value: true,
                    })
                  );
                }}
              />
              <CloseOutlined
                style={isGlobal ? { display: 'none' } : {}}
                disabled={isGlobal}
                className="btn--item-delete"
                onClick={() => {
                  dispatch(
                    actionPostArrayItemDelete({
                      componentIndex: componentIndex,
                      itemIndex: itemIndex,
                      parents: parents,
                      childIndex: childIndex,
                      property: property,
                    })
                  );
                }}
              />
            </>
          }
          key={itemIndex}
        >
          {settings?.length > 0 &&
            settings?.map(
              (group: Dictionary, groupIndex: number) =>
                showGroup(group) && (
                  <ArrayItemGroup
                    key={groupIndex}
                    fields={fields}
                    group={group}
                    groupIndex={groupIndex}
                    componentIndex={componentIndex}
                    childIndex={childIndex}
                    itemIndex={itemIndex}
                    array={array}
                  />
                )
            )}
        </Panel>
      </Collapse>
    </div>
  );
};

export { ArrayItem };
