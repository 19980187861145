import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Form, Input, Upload, Button, message, Breadcrumb } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, UPLOADS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
import Loader from '../../../../Post/InlineLoader/Loader.js';
import ImgCrop from 'antd-img-crop';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionPostsGet,
  actionUserLogout,
  actionUserGet,
} from '../../../actions';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionPostsGet,
  actionUserLogout,
  actionUserGet,
};

const AccountForm = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [account, setAccount] = useState();
  const [accountImage, setAccountImage] = useState();
  const [uploading, setUploading] = useState(false);
  const params = useParams();

  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, `account`);
    service.read(params.id).then((res) => {
      setAccount(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountImage]);

  const uploadButton = (
    <div>
      {account?.image && (
        <img
          style={{ width: '40px', height: '40px' }}
          src={`https://assets.akuko.io/${account.image}`}
        />
      )}
      <div style={{ marginTop: 8 }}>{uploading ? `Uploading...` : 'Upload'}</div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files.');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB.');
      return false;
    }
    return true;
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const service = new AkukoAPIService(UPLOADS_API, `images`);
      const response = await service.create(formData);
      setAccountImage(response?.image_id);
      account.image = response?.image_id;
      const accountService = new AkukoAPIService(IDENTITY_API, `account/${account?.id}`);
      await accountService.update(account);
      const userProfileService = new AkukoAPIService(IDENTITY_API, `user/profile`);
      const user = await userProfileService.read(props?.user?.uuid);
      props.actionUserGet(user);
      setAccount(account);
      message.success('Account icon updated');
      onSuccess(response);
    } catch (error) {
      onError(error);
      message.error(error.message || ERROR_GENERIC);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="dashboard">
      <div className="spaces">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/account/${params.id}`}>{params.id}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>settings</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1>Account</h1>
        <Row gutter={40}>
          <Col sm={12}>
            {account === undefined && <Loader />}
            {account && account.uuid && (
              <Form
                layout="vertical"
                onFinish={(values) => {
                  setShowLoader(true);
                  const service = new AkukoAPIService(IDENTITY_API, `account/${account.id}`);
                  service
                    .update(values)
                    .then(() => {
                      message.success('Account settings updated');
                    })
                    .catch((error) => {
                      message.error(error.message || ERROR_GENERIC);
                    }).finally(() => {
                      setShowLoader(false);
                    });
                }}
              >
                <Form.Item
                  name="name"
                  label="Account name"
                  initialValue={account?.name}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" disabled={showLoader} />
                </Form.Item>
                <Form.Item name="id" label="Account id" initialValue={params.id}>
                  <Input size="large" disabled />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Primary contact"
                  initialValue={account?.email}
                  rules={[
                    {
                      type: 'email',
                      required: true,
                    },
                  ]}
                >
                  <Input size="large" placeholder="Primary contact email" disabled={showLoader} />
                </Form.Item>
                <Button htmlType="submit" size="large" type="primary" disabled={showLoader}>
                  { showLoader && <LoadingOutlined /> } { showLoader ? 'Updating account...' : 'Update Account' }
                </Button>
              </Form>
            )}
          </Col>
          <Col sm={8}>
            <label>Account Icon</label>
            <ImgCrop>
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={customRequest}
              >
                {uploadButton}
              </Upload>
            </ImgCrop>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const AccountFormContainer = connect(mapStateToProps, mapDispatchToProps)(AccountForm);

export default withRouter(AccountFormContainer);
