import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../actions';

export interface LayoutInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const LayoutInput: React.FC<LayoutInputProps> = (props: LayoutInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, options } = item;
  const post = useSelector((state: any) => state.post);
  const [value, setValue] = useState('')

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (!parents) {
      setValue(post.components[componentIndex][property])
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    } 
  }, [post, parents, itemIndex, childIndex, componentIndex])
 

  const isSelected = (option: string) => {
    if (option === value) {
      return 'selected'
    }
    return 'not-selected'
  }

  
  return(
    <>
     <label>{label}</label>
     <Row gutter={10}>
     { options.map((option: Dictionary, index: number) => (
      <Col 
       className={isSelected(option.value)}
       onClick={() => {
        setValue(option.value)
        dispatch(actionPostComponentSettingEdit({
          parents: parents,
          property: property,
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          childIndex: childIndex,
          value: option.value
        }));
       }}
       key={index} 
       xs={8}>
        <span className="layout-id">{ option.value }</span>
        { option.label }
      </Col> 
     ))}
     </Row>
    </>
  )
}

export { LayoutInput };