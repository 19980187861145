import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../../../actions';
import format from 'date-fns/format';
import { Dictionary } from '@onaio/utils';

export interface componentSettingsProps {
  item: Dictionary;
  parents: string[];
  componentIndex: number | string;
  property: string;
  itemIndex: number | undefined;
  childIndex: number | undefined;
}

export const updateStore = (
  value: string,
  setError: (errorState: string | undefined) => void,
  componentSettings: componentSettingsProps,
  dispatch: (action: unknown) => void
): void => {
  const { item, property, parents, componentIndex, itemIndex, childIndex } = componentSettings;
  if (item?.entity === 'post') {
    dispatch(
      actionPostPropertyEdit({
        property: property,
        value: value,
      })
    );
  } else {
    let invalidDate = false;
    if (property === 'dateFormat') {
      const date = new Date();
      try {
        /* @ts-ignore */
        format(date, value);
        setError(undefined);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        invalidDate = true;
        const message = `${error?.message?.split('for')?.[0]} see: ${
          error?.message?.split('see:')?.[1]
        }`;
        setError(message);
      }
    }
    if (!invalidDate) {
      dispatch(
        actionPostComponentSettingEdit({
          parents: parents,
          property: property,
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          childIndex: childIndex,
          value: value,
        })
      );
    }
  }
};
