import React, { useEffect, useState } from 'react';
import { Select, Tooltip, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionPostComponentSettingEdit,
  actionPostPropertyEdit,
} from '../../../actions';
import { isGlobalFilter } from '../../helpers/helpers';
import { QuestionCircleFilled, LinkOutlined } from '@ant-design/icons';
import {
  categoricalClassesValue,
  selectValueValidator,
} from './helpers/helpers';
import { ColorStylePopover } from '../ColorStylePopover';
const { Option } = Select;

export interface SelectInputProps {
  componentIndex: number;
  item: Dictionary;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  renderAsColumn?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = (props: SelectInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item, renderAsColumn } = props;
  const {
    property,
    label,
    options,
    refreshKey,
    parents,
    selectMode,
    placeHolder,
    objectKey,
    inherit,
    tooltip,
    labelLink,
    entity,
    popOver,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const space = useSelector((state: Dictionary) => state.space);
  const component = post.components[componentIndex];
  const [value, setValue] = useState<string | string[] | undefined>(undefined);
  const [colorSchemeConfigs, setColorSchemeConfigs] = useState<Dictionary>({});
  const config = post.config;
  const [inherited, setInherited] = useState(false);

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      let currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
            property
          ]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const colorScaleConfigs = component?.[parents[0]]?.[itemIndex];
      let currentValue = component?.[parents[0]]?.[itemIndex]?.[property];

      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setColorSchemeConfigs(colorScaleConfigs);
      setValue(currentValue);
    }
    if (!parents && componentIndex >= 0) {
      setValue(post.components[componentIndex][property]);
    }
    if (entity === 'post') {
      if (post[property] && objectKey) {
        setValue(post[property][objectKey]);
      } else {
        setValue(post[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);



  const getInherit = () => {
    if (space.config[inherit]) {
      return `[space.config]: ${space.config[inherit]}`
    }
    if (post.config[inherit]) {
      return `[post.config]: ${post.config[inherit]}`
    }
    return null;
  }

  const componentConfigs = {
    parents: parents,
    property: property,
    componentIndex: componentIndex,
    itemIndex: itemIndex,
    childIndex: childIndex,
    refreshKey: refreshKey,
    objectKey: objectKey,
    entity: entity,
  };
  const selectValue = selectValueValidator(
    value,
    options,
    componentConfigs,
    dispatch
  );
  const schemeClassValue = categoricalClassesValue(
    property,
    colorSchemeConfigs
  );

  return renderAsColumn ? (
    <>
      {label && (
        <label>
          {label}
          &nbsp;
          {tooltip && (
            <Tooltip title={tooltip}>
              <QuestionCircleFilled />
            </Tooltip>
          )}
          {labelLink && (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={labelLink} target='_blank'>
              <LinkOutlined />
            </a>
          )}
        </label>
      )}
      {popOver ? (
        <ColorStylePopover
          selectValue={selectValue}
          componentIndex={componentIndex}
          childIndex={childIndex}
          itemIndex={itemIndex}
          item={item}
        />
      ) : (
        <Select
          placeholder={placeHolder ? placeHolder : undefined}
          disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
          value={schemeClassValue || selectValue}
          allowClear
          showSearch
          mode={selectMode}
          onChange={(value) => {
            setValue(value);
            setTimeout(() => {
              if (item.entity === 'post') {
                dispatch(
                  actionPostPropertyEdit({
                    property: property,
                    objectKey: objectKey,
                    value: value,
                  })
                );
              } else {
                dispatch(
                  actionPostComponentSettingEdit({
                    parents: parents,
                    property: property,
                    componentIndex: componentIndex,
                    itemIndex: itemIndex,
                    childIndex: childIndex,
                    refreshKey: refreshKey,
                    objectKey: objectKey,
                    value: value,
                  })
                );
              }
            }, 500);
          }}
        >
          {options &&
            options.map((item: Dictionary, index: number) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
        </Select>
      )}
    </>
  ) : (
    <Row>
      <Col sm={6}>
        {label && (
          <label>
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <QuestionCircleFilled />
              </Tooltip>
            )}
            {labelLink && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={labelLink} target='_blank'>
                <LinkOutlined />
              </a>
            )}
          </label>
        )}
        </Col>
        <Col sm={18}>
        {popOver ? (
          <ColorStylePopover
            selectValue={selectValue}
            componentIndex={componentIndex}
            childIndex={childIndex}
            itemIndex={itemIndex}
            item={item}
          />
        ) : (
          <Select
            placeholder={placeHolder ? placeHolder : undefined}
            disabled={isGlobalFilter(component, parents, childIndex, itemIndex)}
            value={schemeClassValue || selectValue}
            allowClear
            showSearch
            mode={selectMode}
            onChange={(value) => {
              setValue(value);
              setTimeout(() => {
                if (item.entity === 'post') {
                  dispatch(
                    actionPostPropertyEdit({
                      property: property,
                      objectKey: objectKey,
                      value: value,
                    })
                  );
                } else {
                  dispatch(
                    actionPostComponentSettingEdit({
                      parents: parents,
                      property: property,
                      componentIndex: componentIndex,
                      itemIndex: itemIndex,
                      childIndex: childIndex,
                      refreshKey: refreshKey,
                      objectKey: objectKey,
                      value: value,
                    })
                  );
                }
              }, 500);
            }}
          >
            {options &&
              options.map((item: Dictionary, index: number) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
          </Select>
        )}
      </Col>
    </Row>
  );
};

export { SelectInput };
