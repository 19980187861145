/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** Returns a value in window._env_ or process.env or the defaultValue passed in.
 *
 * @param name - The configurable name that is a property of the environment/configuration.
 * @param defaultValue - The default value to return the value is not defined in process.env and window._env_.
 */
export const setEnv = (key: string, defaultValue: string) => {
  return process.env[key] || defaultValue;
};