import { Dictionary } from '@onaio/utils';
import { Store } from 'redux';

/**
 * get logged in user email
 *
 * @param {Partial<Store>} state redux store
 * @returns {string} email
 */
export const userEmailSelector = (state: Partial<Store>): string => {
  return (state as Dictionary).user.email;
};

/**
 * get logged in user handle
 *
 * @param {Partial<Store>} state redux store
 * @returns {string} handle
 */
export const userHandleSelector = (state: Partial<Store>): string => {
  return (state as Dictionary).user.handle;
};
