import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { SourceTitleInput } from '../settings/SourceTitleInput';
import { SourceTypeInput } from '../settings/SourceTypeInput';
import { MySQLSourceType } from '../settings/MySQLSourceType';
import { SourceUploadInput } from '../settings/SourceUploadInput';
import { ParquetFileUploadInput } from '../settings/ParquetFileUploadInput';
import { ClickHouseSourceInput } from '../settings/ClickHouseSourceInput';
import { GeoParquetSourceFromFileInput } from '../settings/GeoParquetSourceFromFileInput';
import { GeoParquetSourceFromUrlInput } from '../settings/GeoParquetSourceFromUrlInput';
import { SourceGoogleSheetInput } from '../settings/SourceGoogleSheetInput';
import { SourcePostgresInput } from '../settings/SourcePostgresInput';
import { SourceUrlInput } from '../settings/SourceUrlInput';
import { SourceInputType } from '../settings/SourceInputType';
import { SourceRefreshScheduler } from '../settings/SourceRefreshScheduler';
import { ConnectionForm } from './ConnectionForm';
import { OnadataSourceType } from '../settings/OnadataSourceType';
import { REFRESHABLE_DATABASE_SOURCES_TYPES } from '../../../configs/env';
import { ParquetSourceInputType } from '../settings/ParquetSourceInputType';
import { GeoParquetSourceInputType } from '../settings/GeoParquetSourceInputType';
import { SourceS3ParquetInput } from '../settings/SourceS3ParquetInput';
import { AKUKO_SOURCE_TYPES } from '../../../configs/types';

const SettingsForm: React.FC = () => {
  const source = useSelector((store: Dictionary) => store.source);
  const renderSourceRefreshScheduler =
    !source.public &&
    source?.type !== 'onadata' &&
    source?.dimensions?.length !== 0 &&
    source?.space_id !== null &&
    (source?.url != null ||
      source?.config?.['sheet-url'] !== undefined ||
      REFRESHABLE_DATABASE_SOURCES_TYPES.includes(source?.type));

  return (
    <>
      {Object.keys(source).length !== 0 && source?.dimensions?.length !== 0 && <SourceTitleInput />}
      <SourceTypeInput />
      <SourceInputType />
      <ParquetSourceInputType />
      <GeoParquetSourceInputType />
      {['csv', 'geojson'].includes(source?.type) && !source?.dimensions?.length && (
        <SourceUploadInput />
      )}
      {source?.type === 'parquet' && !source?.dimensions?.length && <ParquetFileUploadInput />}
      {source?.type === AKUKO_SOURCE_TYPES.CLICKHOUSE && !source?.dimensions?.length && (
        <ClickHouseSourceInput asyncTaskName={'Source creation'} refresh={false} />
      )}
      {source?.type === 'geoparquet' && !source?.dimensions?.length && (
        <GeoParquetSourceFromFileInput />
      )}
      {['csv', 'geojson'].includes(source?.type) && <SourceUrlInput />}
      {source?.type === 'onadata' && <OnadataSourceType />}
      {source?.type === 'google-sheet' && <SourceGoogleSheetInput />}
      {source?.type === 'mysql' && !source?.dimensions?.length && (
        <MySQLSourceType asyncTaskName="Source Creation" />
      )}
      {source?.type === 'postgres' && !source?.dimensions?.length && <SourcePostgresInput />}
      {source?.type === 'parquet' && !source?.dimensions?.length && <SourceS3ParquetInput />}
      {source?.type === 'geoparquet' && !source?.dimensions?.length && (
        <GeoParquetSourceFromUrlInput />
      )}
      {!source.public && <ConnectionForm />}
      {renderSourceRefreshScheduler && <SourceRefreshScheduler />}
    </>
  );
};

export { SettingsForm };
