import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Tag, Space, Popconfirm, Table, Button, Breadcrumb, message } from 'antd';
import { SearchOutlined, DeleteOutlined, DingtalkSquareFilled } from '@ant-design/icons';
import { UserPic } from '../../Post/UserPic';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { getTags } from './helpers.js';
import { ENV, IDENTITY_API, POSTS_API } from '../../../configs/env';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionUserGet,
  actionPostsGet,
  actionUserLogout,
} from '../actions';
import moment from 'moment';
import { actionPostDelete } from '../../Post/actions';
import './style.css';
import { AkukoAPIService } from '../../../services/serviceClass';
import { ERROR_GENERIC } from '../../../configs/constants';
import mixpanel from 'mixpanel-browser';

const mapStateToProps = (state) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionPostsGet,
  actionUserGet,
  actionUserLogout,
  actionPostDelete,
};

const Dashboard = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  // eslint-disable-next-line
  const [searchColumn, setSearchColumn] = useState();
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState();

  const [sourceToDeleteUuid, setSourceToDeleteUuid] = useState(null);
  // eslint-disable-next-line
  let searchInput;
  const [posts, setPosts] = useState([]);
  const actionPostsGet = props.actionPostsGet;
  const actionUserGet = props.actionUserGet;
  const split = window.location.hostname.split('.akuko.io');

  useEffect(() => {
    if (props.user.email) {
      setShowLoader(true);
      const service = new AkukoAPIService(IDENTITY_API, `user`);
      service
        .read(props.user.email)
        .then((res) => {
          props.actionUserGet(res);
          const service = new AkukoAPIService(POSTS_API, `post/account/${props.user.handle}`);
          return service.list();
        })
        .then((data) => {
          props.actionPostsGet(data);
          setPosts(data);
        })
        .catch((error) => {
          message.error(error.message || ERROR_GENERIC);
        })
        .finally(() => {
          setShowLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.handle, actionPostsGet, actionUserGet, sourceToDeleteUuid]);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn('title');
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  return (
    <div className="dashboard">
      <div className="spaces">
        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>{props.user.handle}</Breadcrumb.Item>
            <Breadcrumb.Item>posts</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1>Posts</h1>
        <div className="space-header">
          <Row gutter={20}>
            <Col xs={24}>
              <Button
                id="btn-new-post"
                type="primary"
                onClick={() => {
                  const service = new AkukoAPIService(POSTS_API, 'post');
                  service
                    .create({
                      account_id: props.user.handle,
                      space_id: null,
                      title: 'My new post',
                      edit: props.user.email,
                      author: props.user.email,
                      components: [
                        {
                          type: 'title',
                          description: '',
                        },
                      ],
                      data: {},
                      sources: {},
                    })
                    .then((res) => {
                      mixpanel.track('Create Post', { post: res.uuid });
                      props.history.push(`/post/${res.uuid}`);
                    })
                    .catch((error) => {
                      message.error(error.message || ERROR_GENERIC);
                    });
                }}
              >
                New Post
              </Button>
            </Col>
          </Row>
        </div>
        <Row gutter={20}>
          <Col xs={24} md={24} xl={24}>
            <div className="table-wrapper">
              <Table
                pagination={{
                  defaultPageSize: 20,
                }}
                loading={showLoader}
                columns={[
                  {
                    title: '',
                    width: '10px',
                    render: (record) => {
                      return <UserPic author={record.author} />;
                    },
                  },
                  {
                    title: 'Title',
                    dataIndex: 'title',
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                      <div style={{ padding: 8 }}>
                        <Input
                          ref={(node) => {
                            // eslint-disable-next-line
                            searchInput = node;
                          }}
                          placeholder={`Search title`}
                          value={selectedKeys[0]}
                          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                          onPressEnter={() => handleSearch(selectedKeys, confirm, 'title')}
                          style={{ marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, 'title')}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                          >
                            Search
                          </Button>
                          <Button
                            type="link"
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                          >
                            Reset
                          </Button>
                        </Space>
                      </div>
                    ),
                    onFilter: (value, record) =>
                      record['title']
                        ? record['title'].toString().toLowerCase().includes(value.toLowerCase())
                        : '',
                    key: 'title',
                    render: (text, record) => {
                      return <Link to={`/post/${record.uuid}`}>{text}</Link>;
                    },
                  },
                  {
                    title: 'Updated',
                    dataIndex: 'updated',
                    key: 'updated',
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => moment(a.updated) - moment(b.updated),
                    render: (text, record) => {
                     return(<span className="updated">
                       {moment(text).format('MMM DD YYYY - HH:mm')}
                      </span>)
                    }
                  },
                  {
                    title: 'Tags',
                    dataIndex: 'tags',
                    filters: getTags(props.posts),
                    onFilter: (value, record) => {
                      if (record.tags) {
                        return record.tags.includes(value);
                      } else {
                        return null;
                      }
                    },
                    key: 'tags',
                    render: (text, record) => {
                      if (record.tags && record.tags.length) {
                        return (
                          <>
                            {record.tags &&
                              record.tags.map((tag) => (
                                <Tag key={tag} color="blue">
                                  {tag}
                                </Tag>
                              ))}
                          </>
                        );
                      } else {
                        return <Tag>None</Tag>;
                      }
                    },
                  },
                  {
                    title: '',
                    dataIndex: 'delete',
                    key: 'delete',
                    render: (text, record) => {
                      return (
                        <div style={{ textAlign: 'right' }}>
                          <Popconfirm
                            title="Are you sure you want to delete this post?"
                            onConfirm={() => {
                              setSourceToDeleteUuid(record.uuid);
                              const service = new AkukoAPIService(POSTS_API, `post/${record.uuid}`);
                              service.delete().then(() => {
                                props.actionPostDelete({
                                  uuid: record.uuid,
                                });
                                message.success('Post deleted');
                              });
                            }}
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </div>
                      );
                    },
                  },
                ]}
                dataSource={posts}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withRouter(DashboardContainer);
