import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import MarkdownIt from 'markdown-it';
import { useFetchComponentData } from '../hooks';
import { TextComponent as TextComponentType } from '../../../configs/component-types';
import { actionPostComponentEdit, actionPostKeyEdit } from '../actions';
import { GenericComponent } from '../Component';
import './style.css';
import { Button } from 'antd';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { getContextRow, processTokens } from '../helpers';
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TextEditor } from '../Settings/components/TextEditor';
import { getComponentById, getComponentSource } from '../../../reducers/selectors/post';
import { useMemo } from 'react';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

export interface TextComponentProps {
  componentId: string;
  componentIndex: number;
  dataRow?: Dictionary;
  region?: number;
  layout?: string;
  cardIndex?: number; // card index
  isEmbed?: boolean;
  editPostKeyActionCreator?: typeof actionPostKeyEdit;
  editComponentActionCreator?: typeof actionPostComponentEdit;
}

const defaultProps = {
  editPostKeyActionCreator: actionPostKeyEdit,
  editComponentActionCreator: actionPostComponentEdit,
};



const HtmlComponent: React.FC<TextComponentProps> = (props: TextComponentProps) => {
  const dispatch = useDispatch();
  // memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const {
    componentId,
    componentIndex,
    dataRow,
    cardIndex,
    editComponentActionCreator,
    editPostKeyActionCreator,
    isEmbed,
  } = props;

  

  const component = useSelector((state) => {
    /* @ts-ignore */
    const text = selectComponentById(state, { componentId });

    if (!text) {
      return undefined;
    }
    return text as unknown as TextComponentType;
  });

  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));
  const created = useSelector((state: Dictionary) => state.post.created);

  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });


  const activeComponentIndex: number = useSelector(
    (state) => (state as Dictionary).config.activeComponentIndex
  );
  const [isLoading, data] = useFetchComponentData(componentId, 50000, cardIndex);

  const drawerIsOpen: boolean = useSelector((state) => (state as Dictionary).config.drawerOpen);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const post = useSelector((store: Dictionary) => store.post);

  const Paragraph = (props: Dictionary) => {
    return <p style={{ 
      color: component?.color || post.config?.textColor
       
     }}>
     { props.children }
    </p>
  }

  useEffect(() => {
    if (component?.text) {
      setText(component.text);
    }
  }, [component?.text]);

  useEffect(() => {
    if (activeComponentIndex === componentIndex) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
    if (
      activeComponentIndex === componentIndex &&
      drawerIsOpen === true &&
      text !== '' &&
      text !== component?.text
    ) {
      handleSave();
    }
    // eslint-disable-next-line
  }, [activeComponentIndex, componentIndex, drawerIsOpen, text]);

  const { filteredData } = getContextRow(dataRow, data, component, true);

  const getParsedText = () => {
    if (filteredData[0]) {
      return processTokens(text, component, filteredData[0], source);
    } else {
      return text;
    }
  };

  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Text',
    isEmbed: isEmbed,
  };

  const handleSave = () => {
    if (editComponentActionCreator && editPostKeyActionCreator) {
      dispatch(
        editComponentActionCreator({
          index: componentIndex,
          field: 'text',
          value: text,
        })
      );
    }
  };

  return (
    <GenericComponent {...genericComponentProps}>
      {isLoading && (
        <div className="component-loader">
          <LoadingOutlined />
        </div>
      )}
      <div
        className="post--component-content"
        style={isLoading ? { opacity: 0.1 } : { opacity: 1 }}
      >
     <div className="post--component-html-content">
           <div
              style={{ 
                color: component?.color,
                fontSize: `${component?.fontSize || 19}px`,
                fontFamily: component?.fontFamily || 'PT Serif',
              }}
              
              dangerouslySetInnerHTML={{
                __html: mdParser.render(getParsedText()),
              }}
            ></div>
          </div>
      
      </div>
    </GenericComponent>
  );
};

HtmlComponent.defaultProps = defaultProps;

export { HtmlComponent };
