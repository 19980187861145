import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Popconfirm } from 'antd';
import {
  actionConfigComponentIndexActive,
  actionConfigDrawerOpenEdit,
  actionPostComponentErrorDelete,
} from '../../../actions';
import {
  AlignLeftOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  BookOutlined,
  PictureOutlined,
  TableOutlined,
  LayoutOutlined,
  NumberOutlined,
  FilterOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Dictionary } from '@onaio/utils/';
import { GenericPostComponent } from '../../../../../configs/component-types';

export interface ErrorItem {
  id: string;
  name: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: string | any;
}

export interface ErrorComponentMenuItemProps {
  item: ErrorItem;
  childLevel?: number;
}

const ErrorComponentMenuItem: React.FC<ErrorComponentMenuItemProps> = (
  props: ErrorComponentMenuItemProps
) => {
  const post = useSelector((state: Dictionary) => state.post);
  const dispatch = useDispatch();
  const { item } = props;
  const index = post.components.findIndex(
    (component: GenericPostComponent) => component.id === item.id
  );
  return (
    <>
      <div
        className="post-components-menu--item"
        key={index}
        onMouseOver={() => {
          const el = document.getElementById(`component-${item.id}`);
          el?.classList.add('component-active');
        }}
        onMouseOut={() => {
          const el = document.getElementById(`component-${item.id}`);
          el?.classList.remove('component-active');
        }}
        onClick={() => {
          const el = document.getElementById(`component-${item.id}`);
          el?.classList.remove('component-active');
          dispatch(actionConfigDrawerOpenEdit({ value: true }));
          dispatch(actionConfigComponentIndexActive({ value: index }));
        }}
      >
        {item.type === 'title' && <BookOutlined />}
        {item.type === 'map' && <EnvironmentOutlined />}
        {item.type === 'layout' && <LayoutOutlined />}
        {item.type === 'image' && <PictureOutlined />}
        {item.type === 'chart' && <LineChartOutlined />}
        {item.type === 'table' && <TableOutlined />}
        {item.type === 'text' && <AlignLeftOutlined />}
        {item.type === 'card' && <AppstoreOutlined />}
        {item.type === 'number' && <NumberOutlined />}
        {item.type === 'icon' && <NumberOutlined />}
        {item.type === 'filter' && <FilterOutlined />}
        {item.type === 'button' && <UnorderedListOutlined />}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {item.name || item.type}
        <Popconfirm
          title="Are you sure you want to delete this error?"
          okText="Yes"
          onConfirm={(e) => {
            e?.stopPropagation();
            message.success('Error deleted');
            dispatch(
              actionPostComponentErrorDelete({
                id: item.id,
              })
            );
            const el = document.getElementById(`component-${item.id}`);
            el?.classList.remove('component-active');
          }}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
        >
          <DeleteOutlined
            className="delete-component"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Popconfirm>
      </div>
      <div
        className="post-components-menu--item-errors"
        onMouseOver={() => {
          const el = document.getElementById(`component-${item.id}`);
          el?.classList.add('component-active');
        }}
        onMouseOut={() => {
          const el = document.getElementById(`component-${item.id}`);
          el?.classList.remove('component-active');
        }}
        onClick={() => {
          const el = document.getElementById(`component-${item.id}`);
          el?.classList.remove('component-active');
          dispatch(actionConfigDrawerOpenEdit({ value: true }));
          dispatch(actionConfigComponentIndexActive({ value: index }));
        }}
      >
        {item.errors?.message || item.errors}
      </div>
    </>
  );
};

export { ErrorComponentMenuItem };
