import React, { useEffect } from "react";
import { Select, Popover, Row, Col } from "antd";
import { Dictionary } from "@onaio/utils/dist/types/types";
import { SwitchInput } from "../../../SwitchInput";
import { ColorInput } from "../../../ColorInput";
import { TextInput } from "../../../TextInput";
import { DeleteOutlined } from "@ant-design/icons";
import { CategorySelect } from "../../../CategorySelect";
import { useDispatch, useSelector } from "react-redux";
import {
  actionPostArrayItemAdd,
  actionPostArrayItemDelete,
  actionPostComponentSettingEdit,
} from "../../../../../actions";
import { makeId } from "../../../ArrayInput/utils/helpers";
import { SelectInput } from "../../../SelectInput";
import "../colorStyles.css";

export interface CategoricalPopOverProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
  selectValue: string | string[] | undefined;
  componentLevelColors: Dictionary;
}

const CategoricalPopOver: React.FC<CategoricalPopOverProps> = (
  props: CategoricalPopOverProps
) => {
  const {
    componentIndex,
    childIndex,
    itemIndex,
    item,
    componentLevelColors,
    selectValue,
  } = props;
  const post = useSelector((state: Dictionary) => state.post);
  const { showOtherCategories } = componentLevelColors;

  const dispatch = useDispatch();
  const defaultCategoryColorsExist =
    componentLevelColors?.defaultCategoryColors?.length;

  const categoryClassSelectValue = componentLevelColors?.colorCategories?.length
    ? `${componentLevelColors?.colorCategories?.length} Categories`
    : undefined;
  const popoverListItems = (componentLevelColors: Dictionary): Dictionary[] => {
    if (
      (componentLevelColors?.colorMode === "categories" ||
        componentLevelColors?.colorMethod === "categorical") &&
      componentLevelColors?.colorCategories?.length
    ) {
      return componentLevelColors?.colorCategories;
    }
    return [];
  };

  const data: Dictionary[] = popoverListItems(componentLevelColors);

  useEffect(() => {
    if (showOtherCategories && !defaultCategoryColorsExist) {
      dispatch(
        actionPostArrayItemAdd({
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          parents: item?.parents,
          property: "defaultCategoryColors",
          value: {
            id: makeId(),
            ...{
              value: "other",
              color: "#D9D9D9",
            },
          },
        })
      );
    } else if (defaultCategoryColorsExist && !showOtherCategories) {
      dispatch(
        actionPostComponentSettingEdit({
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          parents: item?.parents,
          property: "defaultCategoryColors",
          value: [],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOtherCategories]);

  const defaultColumn = (defaultData: Dictionary[]) => {
    if (defaultData?.length) {
      return (
        <>
          {defaultData.map((defaultItem: Dictionary, index: number) => {
            return (
              <>
                <Row key={index}>
                  {
                    <ColorInput
                      componentIndex={componentIndex}
                      item={{
                        property: "color",
                        parents: [
                          `${item.parents[0]}`,
                          "defaultCategoryColors",
                        ],
                        size: "small",
                        width: "25%",
                      }}
                      renderAsColumn={true}
                      childIndex={itemIndex}
                      itemIndex={index}
                    />
                  }
                  &nbsp;
                  {
                    <TextInput
                      componentIndex={componentIndex}
                      item={{
                        property: "value",
                        parents: [
                          `${item.parents[0]}`,
                          "defaultCategoryColors",
                        ],
                        size: "small",
                        width: "25%",
                      }}
                      renderAsColumn={true}
                      childIndex={itemIndex}
                      itemIndex={index}
                    />
                  }
                </Row>
              </>
            );
          })}
        </>
      );
    }
  };

  const dataColumns = (data: Dictionary[]) => {
    if (data.length) {
      return (
        <>
          {data.map((dataItem: Dictionary, index: number) => {
            return (
              <>
                <Row key={index}>
                  {
                    <ColorInput
                      componentIndex={componentIndex}
                      item={{
                        property: "color",
                        parents: [`${item.parents[0]}`, "colorCategories"],
                        size: "small",
                        width: "25%",
                      }}
                      childIndex={itemIndex}
                      renderAsColumn={true}
                      itemIndex={index}
                    />
                  }
                  &nbsp;
                  {
                    <TextInput
                      componentIndex={componentIndex}
                      item={{
                        property: "value",
                        parents: [`${item.parents[0]}`, "colorCategories"],
                        size: "small",
                        width: "25%",
                      }}
                      childIndex={itemIndex}
                      renderAsColumn={true}
                      itemIndex={index}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;
                  {
                    <DeleteOutlined
                      onClick={() => {
                        const deletedOtherItem =
                          itemIndex !== undefined &&
                          post?.components[componentIndex]?.[
                            `${item.parents[0]}`
                          ]?.[itemIndex]?.["colorCategories"]?.[index]
                            ?.value === "other";
                        dispatch(
                          actionPostArrayItemDelete({
                            componentIndex: componentIndex,
                            itemIndex: index,
                            parents: [`${item.parents[0]}`],
                            childIndex: itemIndex,
                            property: "colorCategories",
                          })
                        );

                        dispatch(
                          actionPostComponentSettingEdit({
                            componentIndex: componentIndex,
                            itemIndex: itemIndex,
                            parents: [`${item.parents[0]}`],
                            property: "regenerateBreaks",
                            value: false,
                          })
                        );
                        dispatch(
                          actionPostComponentSettingEdit({
                            componentIndex: componentIndex,
                            itemIndex: itemIndex,
                            parents: [`${item.parents[0]}`],
                            property: "categoricalClasses",
                            value: data.length - 1,
                          })
                        );

                        if (deletedOtherItem) {
                          dispatch(
                            actionPostComponentSettingEdit({
                              parents: [`${item.parents[0]}`],
                              property: "showOtherCategories",
                              componentIndex: componentIndex,
                              itemIndex: itemIndex,
                              value: false,
                            })
                          );
                        }
                      }}
                    />
                  }
                  &nbsp;
                </Row>
                <br />
              </>
            );
          })}
        </>
      );
    }
    return <></>;
  };
  const content = (
    <>
      <Row>
        <Col span={12}>Showing</Col>
        <Col span={12}>
          {
            <SelectInput
              componentIndex={componentIndex}
              item={{
                ...item,
                label: "",
                property: "categoricalClasses",
                parents: item.parents,
                popOver: false,
                entity: "",
                options: [
                  {
                    value: 10,
                    label: "10 Categories",
                  },
                  {
                    value: 20,
                    label: "20 Categories",
                  },
                  {
                    value: 30,
                    label: "30 Categories",
                  },
                  {
                    value: 40,
                    label: "40 Categories",
                  },
                  {
                    value: 50,
                    label: "50 Categories",
                  },
                  {
                    value: 250,
                    label: "250 Categories",
                  },
                ],
              }}
              childIndex={childIndex}
              itemIndex={itemIndex}
              renderAsColumn={true}
            />
          }
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={12}>Show Other</Col>
        <Col span={12}>
          {
            <SwitchInput
              componentIndex={componentIndex}
              item={{
                ...item,
                label: "",
                property: "showOtherCategories",
                parents: item.parents,
                entity: "",
                size: "small",
              }}
              childIndex={childIndex}
              itemIndex={itemIndex}
            />
          }
        </Col>
      </Row>

      <br />
      <div className="breaks-breakdown">
        {dataColumns(data)}
        {componentLevelColors?.showOtherCategories &&
          defaultColumn(componentLevelColors?.defaultCategoryColors)}
      </div>
      <br />
      <Row>
        <Col span={14}>
          {
            <CategorySelect
              componentIndex={componentIndex}
              item={{ ...item, label: "" }}
              childIndex={childIndex}
              itemIndex={itemIndex}
              componentLevelColors={componentLevelColors}
            />
          }
        </Col>
      </Row>
    </>
  );

  return (
    <Popover
      style={{ width: "100%" }}
      placement="left"
      content={content}
      trigger="click"
      title="Set Categories"
      arrow={{ pointAtCenter: false }}
      // autoAdjustOverflow={false}
    >
      <Select
        value={categoryClassSelectValue || selectValue || "10 Categories"}
        notFoundContent={null}
      />
    </Popover>
  );
};

export { CategoricalPopOver };
