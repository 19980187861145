import React, { useEffect, useState } from 'react';
import { message, Modal, Form, Select, Button } from 'antd';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { LoadingOutlined } from '@ant-design/icons';

import { PostExportParams, postExportJobStatusTypes } from '../../../../../configs/component-types';
import { TEMPORAL_POST_WORKER_WEB_SOCKET_URL } from '../../../../../configs/env';
import { startAsycPostExport } from '../helpers';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { PostFilters } from '../../../../Account/ScheduledSend/PostFilters';
import { buildPostUrl } from '../../../../Account/ScheduledSend/helpers';
import { useSelector } from 'react-redux';

const { Option } = Select;

interface ExportPostProps {
  open: boolean;
  onCancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExportPost = (props: ExportPostProps) => {
  const { open, onCancel } = props;
  const post = useSelector((store: Dictionary) => store.post);
  const [jobUuid, setJobUuid] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [postFilters, setPostFilters] = useState<string[]>([]);
  const [postUrl, setPostUrl] = useState<string>(`${window.location.origin}/post/${post?.uuid}`);

  const [form] = Form.useForm();

  useEffect(() => {
    const url = buildPostUrl({
      filters: postFilters,
      postId: post?.uuid,
    });
    setPostUrl(url);
  }, [postFilters, post?.uuid]);

  const onFinish = (values: Dictionary) => {
    setLoading(true);
    triggerAsycPostExport({
      post_id: post?.uuid,
      post_url: postUrl,
      selector: '.post--content',
      format: values?.format,
      download_file_name: `${post?.title?.split(' ').join('')}.${values?.format}`,
    });
  };

  const checkExportProgress = async (
    jobId: string,
    socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
    download_file_name: string
  ): Promise<void> => {
    socket?.on(
      jobId,
      async (data: {
        progress: {
          stage: postExportJobStatusTypes;
          data: Dictionary[];
          errors: Dictionary[];
        };
      }) => {
        let postBlobObjectUrl;
        if (data?.progress?.stage === 'DONE') {
          try {
            const downloadUrl = data?.progress?.data[0]?.renderUrl;
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', download_file_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (err) {
            message.error('Error exporting post, please try again', 2.5);
          } finally {
            if (postBlobObjectUrl !== undefined) {
              URL.revokeObjectURL(postBlobObjectUrl);
            }
            setLoading(false);
            setJobUuid('');
            socket?.disconnect();
          }
        }
        if (data?.progress?.errors.length !== 0) {
          message.error('Error exporting post, please try again', 2.5);
          setLoading(false);
          setJobUuid('');
          socket?.disconnect();
        }
      }
    );
  };

  const triggerAsycPostExport = (query: PostExportParams): void => {
    const socket = io(TEMPORAL_POST_WORKER_WEB_SOCKET_URL, {
      path: '/v1/socket.io',
      withCredentials: true,
    });
    socket.on('connect', async () => {
      if (jobUuid !== '') {
        await checkExportProgress(jobUuid, socket, query?.download_file_name);
      } else {
        // start asyc job for exporting post
        startAsycPostExport({
          postExportParams: query,
        }).then(async (res) => {
          const data = res as Dictionary;
          setJobUuid(data.job_uuid);
          await checkExportProgress(data.job_uuid, socket, query?.download_file_name);
        });
      }
      // eslint-disable-next-line no-console
      console.log(
        `client connected to websock server ${TEMPORAL_POST_WORKER_WEB_SOCKET_URL}, socket id: ${socket.id}`
      );
    });
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => {
      // eslint-disable-next-line no-console
      console.log(`client disconnected from websock server ${TEMPORAL_POST_WORKER_WEB_SOCKET_URL}`);
    });
  };

  return (
    <>
      <Modal open={open} onCancel={onCancel} footer={null} title={'Export post'}>
        <Form form={form} onFinish={onFinish} layout="vertical" className="post-export">
          <Form.Item
            name="format"
            label="Format"
            rules={[{ required: true, message: 'Please select export format' }]}
          >
            <Select disabled={loading}>
              <Option value="pdf">PDF</Option>
              <Option value="png">PNG</Option>
            </Select>
          </Form.Item>
          <Form.Item name="post_filters" label="Post Filters" rules={[{ required: false }]}>
            <PostFilters
              setPostFilters={setPostFilters}
              postFilters={postFilters}
              disable={loading}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading && <LoadingOutlined />} {loading ? 'Exporting post...' : 'Export post'}
            </Button>
            {postFilters.length > 0 && postUrl && (
              <>
                {' '}
                <Button type="primary" disabled={loading} href={postUrl} target="blank">
                  Preview filtered post
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export { ExportPost };
