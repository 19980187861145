import { Dictionary } from '@onaio/utils';
import React from 'react';
import { Action } from 'redux';
import { FilterStructure, TreeDataProps } from '..';
import {
  actionComponentGlobalFilterAdd,
  actionComponentWithGlobalFilters,
  actionComponentRemoveGlobalFilter,
} from '../../../../actions';
import { getComponentIconAndText } from '../../../helpers/helpers';

export const buildBulkSelectFragment = (
  selectedValues: string[],
  treeData: TreeDataProps[],
  allIds: string[],
  setSelectedValues: (arg: string[]) => void
): JSX.Element => {
  return (
    <React.Fragment>
      {selectedValues.length === treeData.length && (
        <span
          style={{
            display: 'inline-block',
            color: '#ccc',
            cursor: 'not-allowed',
          }}
        >
          Select all
        </span>
      )}
      {selectedValues.length < treeData.length && (
        <span
          onClick={() => setSelectedValues(allIds)}
          style={{
            display: 'inline-block',
            color: '#286FBE',
            cursor: 'pointer',
          }}
        >
          Select all
        </span>
      )}
      &nbsp;&nbsp;&nbsp;
      {selectedValues.length === 0 && (
        <span
          style={{
            display: 'inline-block',
            color: '#ccc',
            cursor: 'not-allowed',
          }}
        >
          Unselect all
        </span>
      )}
      {selectedValues.length > 0 && (
        <span
          onClick={() => setSelectedValues([])}
          style={{
            display: 'inline-block',
            color: '#286FBE',
            cursor: 'pointer',
          }}
        >
          Unselect all
        </span>
      )}
    </React.Fragment>
  );
};

export const getComponentsWithSameDimensions = (
  lookupSourceDimensions: string[],
  componentSourceDimensions: string[],
  filterProperties: string[]
): boolean => {
  return (
    filterProperties.every((item) => componentSourceDimensions.includes(item)) &&
    componentSourceDimensions.some((item) => lookupSourceDimensions.includes(item))
  );
};

export const getComponentsBasedOnSource = (
  source: string,
  components: Dictionary[],
  allSources: Dictionary,
  filters: FilterStructure[] = []
): TreeDataProps[] => {
  // get all labels for the filter source at play
  const lookupSourceDimensions = Object.keys(allSources[source].labels);

  // get all dimensions/measures applied to the filter
  const filterProperties = filters.length ? filters.map((item) => item[1]) : [];

  const componentsWithMatchingSourcesAndDimensions: TreeDataProps[] = [];

  components.forEach((component: Dictionary) => {
    // get map/layers based on:
    // 1. matching sources,
    // 2. layer dimensions and global filter dimensions

    if (component.type === 'map') {
      component.layers.forEach((layer: Dictionary) => {
        if (layer.source === source) {
          componentsWithMatchingSourcesAndDimensions.push({
            title: (
              <div
                onMouseOver={() => {
                  const el = document.getElementById(`component-${component.id}`);
                  el?.classList.add('component-active');
                }}
                onMouseOut={() => {
                  const el = document.getElementById(`component-${component.id}`);
                  el?.classList.remove('component-active');
                }}
              >
                {getComponentIconAndText(component, layer)}
              </div>
            ),
            value: `${component.id}/${layer.id}`,
          });
        } else {
          if (layer.source) {
            const layerSourceDimensions =
              allSources[layer.source] &&
              Object.keys(allSources?.[layer.source] && allSources[layer.source].labels);
            const hasSameDimension =
              layerSourceDimensions &&
              getComponentsWithSameDimensions(
                lookupSourceDimensions,
                layerSourceDimensions,
                filterProperties
              );
            if (hasSameDimension) {
              componentsWithMatchingSourcesAndDimensions.push({
                title: (
                  <div
                    onMouseOver={() => {
                      const el = document.getElementById(`component-${component.id}`);
                      el?.classList.add('component-active');
                    }}
                    onMouseOut={() => {
                      const el = document.getElementById(`component-${component.id}`);
                      el?.classList.remove('component-active');
                    }}
                  >
                    {getComponentIconAndText(component, layer)}
                  </div>
                ),
                value: `${component.id}/${layer.id}`,
              });
            }
          }
        }
      });
    } else {
      // non map/layers components
      // 1. when sources match
      // 2. when source dimensions and global filter dimensions  match
      if (source && !['filter', 'layout'].includes(component.type)) {
        if (component.source === source) {
          componentsWithMatchingSourcesAndDimensions.push({
            title: (
              <div
                onMouseOver={() => {
                  const el = document.getElementById(`component-${component.id}`);
                  el?.classList.add('component-active');
                }}
                onMouseOut={() => {
                  const el = document.getElementById(`component-${component.id}`);
                  el?.classList.remove('component-active');
                }}
              >
                {getComponentIconAndText(component)}
              </div>
            ),
            value: component.id,
          });
        } else {
          if (component.source) {
            const componentSourceDimensions = allSources[component.source]?.labels
              ? Object.keys(allSources[component.source]?.labels)
              : undefined;

            const hasSameDimension =
              componentSourceDimensions &&
              getComponentsWithSameDimensions(
                lookupSourceDimensions,
                componentSourceDimensions,
                filterProperties
              );
            if (hasSameDimension) {
              componentsWithMatchingSourcesAndDimensions.push({
                title: (
                  <div
                    onMouseOver={() => {
                      const el = document.getElementById(`component-${component.id}`);
                      el?.classList.add('component-active');
                    }}
                    onMouseOut={() => {
                      const el = document.getElementById(`component-${component.id}`);
                      el?.classList.remove('component-active');
                    }}
                  >
                    {getComponentIconAndText(component)}
                  </div>
                ),
                value: component.id,
              });
            }
          }
        }
      }
    }
  });
  return componentsWithMatchingSourcesAndDimensions;
};

export const checkFilters = (filters: FilterStructure[] = []): boolean => {
  return filters.every((filter: FilterStructure) => filter[0] && filter[1]);
};

/**
 * Utility that Updates childcomponents filters to store
 */
export const updateChildComponentFilters = (
  selections: string[],
  dispatch: (x: Action) => void,
  filters: FilterStructure[],
  reduxAction: typeof actionComponentGlobalFilterAdd
): void => {
  if (selections.length > 0) {
    selections.forEach((selection) => {
      dispatch(
        reduxAction({
          componentId: selection,
          filters: filters,
        })
      );
    });
  }
};

export const addListOfComponentsWithGlobalFilters = (
  selections: string[],
  componentIndex: number,
  dispatch: (x: Action) => void
): void => {
  dispatch(
    actionComponentWithGlobalFilters({
      componentsWithGlobalFilters: selections,
      componentIndex: componentIndex,
    })
  );
};

export const filterOutduplicates = (list: string[], duplicates: string[]): string[] => {
  const uniqueResult = list.filter((element) => {
    return duplicates.indexOf(element) < 0;
  });
  return uniqueResult;
};

export const toggleFilters = (
  filters: FilterStructure[],
  componentIndex: number,
  previousSelections: string[],
  currentSelections: string[],
  dispatch: (x: Action) => void
): void | boolean => {
  if (JSON.stringify(previousSelections) === JSON.stringify(currentSelections)) {
    return true;
  }
  let filterWithGlobalField = [...filters];
  filterWithGlobalField = filterWithGlobalField.map((filter: FilterStructure) => {
    return {
      ...filter,
      expose: false,
      isGlobal: true,
    };
  });
  // find duplicates between previous and current filters
  const duplicates = currentSelections.filter((val) => {
    return previousSelections.indexOf(val) != -1;
  });

  const currentOptions = filterOutduplicates(currentSelections, duplicates);

  const previousOptions = filterOutduplicates(previousSelections, duplicates);
  // Adds filters
  if (currentOptions.length > 0) {
    updateChildComponentFilters(
      currentOptions,
      dispatch,
      filterWithGlobalField,
      actionComponentGlobalFilterAdd
    );
  }
  // Remove filters
  if (previousOptions.length > 0) {
    updateChildComponentFilters(
      previousOptions,
      dispatch,
      filterWithGlobalField,
      actionComponentRemoveGlobalFilter
    );
  }

  // Persist list of components with global filters
  addListOfComponentsWithGlobalFilters(currentSelections, componentIndex, dispatch);
};
