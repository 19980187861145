import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import { actionSourceEdit } from '../actions';
import { Dictionary } from '@onaio/utils';
const { Option } = Select;

const SourceTypeInput: React.FC = () => {
  const dimensions = useSelector((state: Dictionary) => state.source.dimensions);
  const source = useSelector((state: Dictionary) => state.source);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(source.type);
  }, [source.type]);

  const isDisabled = () => {
    if (dimensions?.length > 0) {
      return true;
    }
    return false;
  };

  if (source?.dimensions === null || source?.dimensions?.length == 0) {
    return (
      <div className="input-field">
        <label>Type</label>
        <Select
          disabled={isDisabled()}
          placeholder="Select source type"
          value={value}
          onChange={(value) => {
            setValue(value);
            dispatch(
              actionSourceEdit({
                type: value,
              })
            );
          }}
        >
          <Option value="csv">
            <span className="csv-icon" /> CSV
          </Option>
          <Option value="parquet">
            <span className="parquet-icon" /> Parquet
          </Option>
          <Option value="geojson">
            <span className="geojson-icon" /> GeoJSON
          </Option>
          <Option value="geoparquet">
            <span className="geoparquet-icon" /> GeoParquet <b>(Beta)</b>
          </Option>
          <Option value="google-sheet">
            <span className="sheet-icon" /> Google Sheet
          </Option>
          <Option value="onadata">
            <span className="onadata-icon" /> Ona Data <b>(Beta)</b>
          </Option>
          {source.space_id && (
            <Option value="postgres">
              <span className="postgres-icon" /> PostgreSQL
            </Option>
          )}
          {source.space_id && (
            <Option value="clickhouse">
              <span className="clickhouse-icon" /> ClickHouse
            </Option>
          )}
          {source.space_id && (
            <Option value="mysql">
              <span className="mysql-icon" /> MySQL
            </Option>
          )}
        </Select>
      </div>
    );
  } else {
    return null;
  }
};

export { SourceTypeInput };
