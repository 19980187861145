import { GroupFieldProps } from '../types';

export const icon: GroupFieldProps = {
  groups: ['Settings', 'Icon', 'Size', 'Color', 'Background'],
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Settings',
      placeHolder: 'Icon Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Settings',
      placeHolder: 'Icon Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Settings',
      placeHolder: 'Icon Identifier Name',
    },
    {
      type: 'icon',
      label: 'Icon',
      property: 'icon',
      group: 'Icon',
    },
    {
      type: 'color',
      disableOpacity: true,
      label: 'Icon color',
      property: 'icon',
      objectKey: 'color',
      group: 'Color',
    },
    {
      type: 'color',
      label: 'Background color',
      property: 'backgroundColor',
      group: 'Background',
    },
    {
      type: 'slider',
      min: 20,
      max: 400,
      label: 'Icon size',
      property: 'width',
      group: 'Size',
      placeHolder: '0',
    },
  ],
};
