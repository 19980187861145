import React from 'react';
import { Select, Popover, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { TextInput } from '../../../TextInput';
import { DeleteOutlined } from '@ant-design/icons';
import { CategorySelect } from '../../../CategorySelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionPostArrayItemDelete,
  actionPostComponentSettingEdit,
} from '../../../../../actions';
import { SelectInput } from '../../../SelectInput';
import '../colorStyles.css';

export interface CategorcialRadiusPopoverProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
  selectValue: string | string[] | undefined;
  componentLevelColors: Dictionary;
}

const CategorcialRadiusPopover: React.FC<CategorcialRadiusPopoverProps> = (
  props: CategorcialRadiusPopoverProps
) => {
  const {
    componentIndex,
    childIndex,
    itemIndex,
    item,
    componentLevelColors,
    selectValue,
  } = props;
  const post = useSelector((state: Dictionary) => state.post);

  const dispatch = useDispatch();

  const categoryClassSelectValue = componentLevelColors?.radiusCategories
    ?.length
    ? `${componentLevelColors?.radiusCategories?.length} Categories`
    : undefined;
  const popoverListItems = (componentLevelColors: Dictionary): Dictionary[] => {
    if (
      componentLevelColors?.radiusScale === 'categorical' &&
      componentLevelColors?.radiusCategories?.length
    ) {
      return componentLevelColors?.radiusCategories;
    }
    return [];
  };

  const data: Dictionary[] = popoverListItems(componentLevelColors);

  const dataColumns = (data: Dictionary[]) => {
    if (data.length) {
      return (
        <>
          {data.map((dataItem: Dictionary, index: number) => {
            return (
              <>
                <Row key={index}>
                  {
                    <TextInput
                      componentIndex={componentIndex}
                      item={{
                        property: 'radius',
                        parents: [`${item.parents[0]}`, 'radiusCategories'],
                        size: 'small',
                        width: '25%',
                      }}
                      childIndex={itemIndex}
                      renderAsColumn={true}
                      itemIndex={index}
                    />
                  }
                  &nbsp;
                  {
                    <TextInput
                      componentIndex={componentIndex}
                      item={{
                        property: 'value',
                        parents: [`${item.parents[0]}`, 'radiusCategories'],
                        size: 'small',
                        width: '25%',
                      }}
                      childIndex={itemIndex}
                      renderAsColumn={true}
                      itemIndex={index}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;
                  {
                    <DeleteOutlined
                      onClick={() => {
                        dispatch(
                          actionPostArrayItemDelete({
                            componentIndex: componentIndex,
                            itemIndex: index,
                            parents: [`${item.parents[0]}`],
                            childIndex: itemIndex,
                            property: 'radiusCategories',
                          })
                        );

                        dispatch(
                          actionPostComponentSettingEdit({
                            componentIndex: componentIndex,
                            itemIndex: itemIndex,
                            parents: [`${item.parents[0]}`],
                            property: 'regenerateBreaks',
                            value: false,
                          })
                        );
                        dispatch(
                          actionPostComponentSettingEdit({
                            componentIndex: componentIndex,
                            itemIndex: itemIndex,
                            parents: [`${item.parents[0]}`],
                            property: 'categoricalRadiusClasses',
                            value: data.length - 1,
                          })
                        );
                      }}
                    />
                  }
                  &nbsp;
                </Row>
                <br />
              </>
            );
          })}
        </>
      );
    }
    return <></>;
  };
  const content = (
    <>
      <Row>
        <Col span={12}>Showing</Col>
        <Col span={12}>
          {
            <SelectInput
              componentIndex={componentIndex}
              item={{
                ...item,
                label: '',
                property: 'categoricalRadiusClasses',
                parents: item.parents,
                popOver: false,
                entity: '',
                options: [
                  {
                    value: 10,
                    label: '10 Categories',
                  },
                  {
                    value: 20,
                    label: '20 Categories',
                  },
                  {
                    value: 30,
                    label: '30 Categories',
                  },
                  {
                    value: 40,
                    label: '40 Categories',
                  },
                  {
                    value: 50,
                    label: '50 Categories',
                  },
                  {
                    value: 250,
                    label: '250 Categories',
                  },
                ],
              }}
              childIndex={childIndex}
              itemIndex={itemIndex}
              renderAsColumn={true}
            />
          }
        </Col>
      </Row>
      <br />
      <div className='breaks-breakdown'>{dataColumns(data)}</div>
      <br />
      <Row>
        <Col span={14}>
          {
            <CategorySelect
              componentIndex={componentIndex}
              item={{ ...item, label: '' }}
              childIndex={childIndex}
              itemIndex={itemIndex}
              componentLevelColors={componentLevelColors}
            />
          }
        </Col>
      </Row>
    </>
  );

  return (
    <Popover
      style={{ width: '100%' }}
      placement='left'
      content={content}
      trigger='click'
      title='Set Categories'
      arrow={{ pointAtCenter: false }}
    >
      <Select
        value={categoryClassSelectValue || selectValue || '10 Categories'}
        notFoundContent={null}
      />
    </Popover>
  );
};

export { CategorcialRadiusPopover };
