import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapInstance from './components/MapInstance';
import { GenericComponent } from '../Component';
import './style.css';
import { Dictionary } from '@onaio/utils';
import { Legend } from './components/Legend';
import { LayerProps } from '../../../configs/types';

export interface OwnPropsFields {
  isEmbed: boolean;
  componentId: string;
  index: number;
}

export interface ComponentProps {
  id: string;
  key: string;
  height: number;
  layers: LayerProps[];
}

export interface MapComponentProps {
  componentId: string;
  index: number;
  component: ComponentProps;
  isEmbed: boolean;
}

const mapStateToProps = (state: Dictionary, ownProps: OwnPropsFields) => {
  if (state) {
    return {
      edit: state.post.edit,
      config: state.config,
      user: state.user,
      component: state.post.components[ownProps.index],
      postSources: state.post.sources,
      space: state.space,
      account: state.post.account_id,
      sources: state.sources,
    };
  } else {
    return null;
  }
};

class MapComponent extends Component<MapComponentProps> {
  state = {
    edit: false,
    mapVisible: true,
  };

  render() {
    const { component, index, isEmbed } = this.props;
    let mapLegendAboveLayers: LayerProps[] = [];
    let mapLegendBelowLayers: LayerProps[] = [];
    if (component.layers) {
      mapLegendAboveLayers = component.layers.filter(
        (layer: Dictionary) =>
          layer.legend && layer.legend.position === 'above' && layer.legend.isEnabled
      );
      mapLegendBelowLayers = component.layers.filter(
        (layer: Dictionary) =>
          layer.legend && layer.legend.position === 'below' && layer.legend.isEnabled
      );
    }

    const genericComponentProps = {
      componentIndex: index,
      isEmbed: isEmbed,
      drawerTitle: 'Map',
      componentId: component.id,
    };

    return (
      <GenericComponent {...genericComponentProps}>
        <div>
          {mapLegendAboveLayers.length > 0 && <Legend layers={mapLegendAboveLayers} />}
          <div
            className="map"
            style={{
              height: component.height || 500,
            }}
          >
            <MapInstance index={index} />
          </div>
          {mapLegendBelowLayers.length > 0 && <Legend layers={mapLegendBelowLayers} />}
        </div>
      </GenericComponent>
    );
  }
}

const MapComponentContainer = connect(mapStateToProps)(MapComponent);

export default MapComponentContainer;
