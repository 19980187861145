import React from 'react';
import { Row, Col } from 'antd';
import { ColorValueProps } from '../../../../../../configs/types';

interface CategoriesLegendProps {
  colorCategories: ColorValueProps[];
}

const CategoriesLegend = (props: CategoriesLegendProps): JSX.Element => {
  const { colorCategories } = props;

  return (
    <div>
      <Row gutter={10}>
        {colorCategories.map((category, i) => (
          <Col sm={6} key={i}>
            <span className="vertical-legend--color" style={{ background: category.color }}></span>
            <span>{category.value}</span>
          </Col>
        ))}
      </Row>
    </div>
  );
};

CategoriesLegend.defaultProps = {
  colorCategories: [],
};

export { CategoriesLegend };
