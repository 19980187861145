import React, { useEffect, useState } from 'react';
import { Input, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../actions';

export interface TextInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const TextAreaInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, placeHolder } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const [value, setValue] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      updateStore();
    }, 500);
    return () => clearTimeout(timer);
  }, [value]);

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (!parents && !item.entity) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentIndex, childIndex, itemIndex, parents]);

  const updateStore = () => {
    if (item.entity === 'post') {
      dispatch(
        actionPostPropertyEdit({
          property: property,
          value: value,
        })
      );
    } else {
      dispatch(
        actionPostComponentSettingEdit({
          parents: parents,
          property: property,
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          childIndex: childIndex,
          value: value,
        })
      );
    }
  };

  return (
    <Row gutter={10}>
      <Col sm={6}>
      <label>{label}</label>
      </Col>
      <Col sm={18}>
      <Input.TextArea
        placeholder={placeHolder ? placeHolder : undefined}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      </Col>
    </Row>
  );
};

export { TextAreaInput };
