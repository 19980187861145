import { Dictionary } from '@onaio/utils';
import { InputNumber } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { handleUpdateStore } from '../helpers/helpers';

interface ClassesInputProps {
  classes: number;
  configs: Dictionary;
  hideClassInput: boolean;
  scheme?: string;
}

const ClassesInput: React.FC<ClassesInputProps> = React.memo(
  (props: ClassesInputProps) => {
    const dispatch = useDispatch();
    const { scheme, classes, configs, hideClassInput } = props;
    const { setClasses } = configs;

    return (
      <div
        style={{
          display: hideClassInput ? 'none' : 'block',
        }}
      >
        <label>Classes</label>
        <InputNumber
          size={'small'}
          min={3}
          max={12}
          step={1}
          value={classes}
          onChange={(classCount) => {
            setClasses(classCount);
            handleUpdateStore(
              scheme || 'Oranges',
              classCount,
              dispatch,
              configs
            );
          }}
        />
      </div>
    );
  }
);

ClassesInput.displayName = 'ClassesInput';
export { ClassesInput };
