import React, { ReactChild, useState } from 'react';
import { useSelector } from 'react-redux';
import { Settings } from '../../../Settings';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils/dist/types/types';

export interface RightRegionProps {
    id: string,
    children?: any[]
}

const RightRegion: React.FC<RightRegionProps> = (props: RightRegionProps) => {
  const { children } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
    { children &&
      <div 
      className="right-resize-control"
      onClick={() => {
        if (document.body.classList.contains('right-menu-hide')) {
          document.body.classList.remove('right-menu-hide');
          setExpanded(false)
        } else {
          document.body.classList.add('right-menu-hide');
          setExpanded(true)
        }
      }}
      >
        { expanded ? 
        <CaretLeftOutlined />
        :
        <CaretRightOutlined />
        }
      </div>
      }
    <div className="editor-right-region">
      { children }
    </div>
    </>
  )
}

export { RightRegion }