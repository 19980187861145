import { GroupFieldProps } from '../types';

export const item: GroupFieldProps = {
  groups: [
    'Settings', 'Events',
  ],
  sections: {},
  fields: [
    {
      type: 'text',
      property: 'label',
      label: 'Text',
      parents: ['items'],
      group: 'Settings',
      placeHolder: 'Label',
    },
    {
      type: 'switch',
      label: 'Use as divider',
      property: 'divider',
      group: 'Settings',
      parents: ['items'],
    },
    {
      type: 'image',
      label: 'Image',
      property: 'image',
      group: 'Settings',
      parents: ['items'],
    },
    {
      type: 'slider',
      label: 'Image height',
      property: 'imageHeight',
      group: 'Settings',
      defaultValue: 25,
      parents: ['items'],
    },
    {
      type: 'array',
      label: 'Events',
      property: 'events',
      itemLabel: 'Event',
      addButtonText: 'Add Event',
      parents: ['items'],
      collapseProperty: 'effect',
      collapseParent: 'events',
      group: 'Events',
      groups: ['Events'],
      settings: [
        {
          label: 'Events',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      defaultValueType: 'array',
      fields: [
        {
          type: 'select',
          label: 'Trigger',
          property: 'type',
          parents: ['items', 'events'],
          placeHolder: 'Select trigger',
          options: [{ value: 'onButtonClick', label: 'Click' }],
          group: 'Events',
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['items', 'events'],
          placeHolder: 'Select event',
          options: [
            { value: 'updateFilterValue', label: 'Update filter value' },
            { value: 'clearFilterValue', label: 'Clear filter value' },
            { value: 'showLayer', label: 'Show layer' },
            { value: 'hideLayer', label: 'Hide layer' },
            { value: 'toggleLayer', label: 'Toggle layer' },
            { value: 'showLayout', label: 'Show layout' },
            { value: 'hideLayout', label: 'Hide layout' },
            { value: 'toggleLayout', label: 'Toggle layout' },
            {
              value: 'updateLayerProperty',
              label: 'Update layer property',
            },
            { value: 'updateMapStyle', label: 'Update map style' },
            { value: 'flyTo', label: 'Fly to' },
            { value: 'collapseLayout', label: 'Collapse layout' },
            { value: 'webhook', label: 'Fire webhook' },
            { value: 'navigateToUrl', label: 'Navigate to URL' },
            { value: 'uncollapseLayout', label: 'Uncollapse layout' },
          ],
          group: 'Events',
          dependsOn: [
            { property: 'type', value: 'onButtonClick' },
            {
              parent: 'component',
              property: 'format',
              value: ['button', 'select', 'horizontalMenu', 'verticalMenu'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['items', 'events'],
          placeHolder: 'Select event',
          options: [
            { value: 'toggleLayer', label: 'Toggle layer' },
            { value: 'toggleLayout', label: 'Toggle layout' },
            { value: 'updateFilterValue', label: 'Update filter value' },
          ],
          group: 'Events',
          dependsOn: [
            { property: 'type', value: 'onButtonClick' },
            {
              parent: 'component',
              property: 'format',
              value: ['checkbox'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['items', 'events'],
          placeHolder: 'Select event',
          options: [
            { value: 'toggleLayer', label: 'Toggle layer' },
            { value: 'toggleLayout', label: 'Toggle layout' },
            { value: 'updateFilterValue', label: 'Update filter value' },
            { value: 'updateMapStyle', label: 'Update map style' },
          ],
          group: 'Events',
          dependsOn: [
            { property: 'type', value: 'onButtonClick' },
            { parent: 'items', property: 'format', value: ['radio'] },
          ],
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['items', 'events'],
          placeHolder: 'Select event',
          options: [
            { value: 'toggleLayer', label: 'Toggle layer' },
            { value: 'toggleLayout', label: 'Toggle layout' },
            { value: 'updateFilterValue', label: 'Update filter value' },
            { value: 'updateMapStyle', label: 'Update map style' },
          ],
          group: 'Events',
          dependsOn: [
            { property: 'type', value: 'onButtonClick' },
            { parent: 'items', property: 'format', value: ['pills'] },
          ],
        },
        {
          type: 'text',
          label: 'URL',
          property: 'url',
          parents: ['items', 'events'],
          placeHolder: 'https://',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
        },
        {
          type: 'checkbox',
          label: 'Open in new window',
          property: 'blank',
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
        },
        {
          type: 'select',
          label: 'Method',
          property: 'method',
          parents: ['items', 'events'],
          placeHolder: 'GET',
          options: [
            { value: 'GET', label: 'GET' },
            { value: 'POST', label: 'POST' },
            { value: 'PUT', label: 'PUT' },
            { value: 'DELETE', label: 'DELETE' },
          ],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'textarea',
          label: 'Headers',
          property: 'headers',
          parents: ['items', 'events'],
          placeHolder: `{}`,
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'text',
          label: 'URL',
          property: 'url',
          parents: ['items', 'events'],
          placeHolder: 'https://',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'textarea',
          label: 'body',
          property: 'body',
          parents: ['items', 'events'],
          placeHolder: '',
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'webhook' },
            { property: 'method', value: ['POST', 'PUT'] },
          ],
        },
        {
          type: 'text',
          label: 'Success message',
          property: 'message',
          parents: ['items', 'events'],
          placeHolder: 'Webhook fired',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'layer',
          label: 'Layer',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Select layer',
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'showLayer' },
          ],
        },
        {
          type: 'layer',
          label: 'Layer',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Select layer',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'hideLayer' }],
        },
        {
          type: 'layer',
          label: 'Layer',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Select layer',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'updateLayerProperty' }],
        },
        {
          type: 'layer',
          property: 'componentId',
          parents: ['items', 'events'],
          excludeLayer: true,
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'updateMapStyle' }],
        },
        {
          type: 'select',
          label: 'Map style',
          property: 'target',
          parents: ['items', 'events'],
          options: [
            { value: 'default-style', label: 'Light' },
            { value: 'dark', label: 'Dark' },
            { value: 'outdoors', label: 'Outdoors' },
            { value: 'terrain', label: 'Terrain' },
            { value: 'satstreets', label: 'Satelitte Streets' },
            { value: 'sat', label: 'Satelitte' },
            { value: 'url-style', label: 'Custom style URL' },
            { value: 'no-style', label: 'No style' },
          ],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'updateMapStyle' }],
        },
        {
          type: 'text',
          label: 'Style url',
          property: 'customStyle',
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [
            {
              property: 'target',
              value: 'url-style',
            },
          ],
        },
        {
          type: 'color',
          label: 'Map background color',
          parents: ['items', 'events'],
          property: 'styleColor',
          group: 'Events',
          dependsOn: [
            {
              property: 'target',
              value: 'no-style',
            },
          ],
        },
        {
          type: 'layer',
          label: 'Layer to update',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Select layer',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'toggleLayer' }],
        },
        {
          type: 'dimension',
          label: 'Filter',
          property: 'filter',
          parents: ['items', 'events'],
          placeHolder: 'Select filter',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'clearFilterValue' }],
        },
        {
          type: 'dimension',
          label: 'Filter',
          property: 'filter',
          parents: ['items', 'events'],
          placeHolder: 'Filter',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'updateFilterValue' }],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          property: 'value',
          parents: ['items', 'events'],
          group: 'Events',
          showDimensions: true,
          showMeasures: false,
          dependsOn: [{ property: 'filter', value: '*' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'setActiveTab' }],
        },
        {
          type: 'component',
          label: 'Layout to update',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'toggleLayout' }],
        },
        {
          type: 'component',
          label: 'Layout to update',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'showLayout' }],
        },
        {
          type: 'component',
          label: 'Layout to update',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'hideLayout' }],
        },
        {
          type: 'component',
          label: 'Layout to update',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'collapseLayout' }],
        },
        {
          type: 'component',
          label: 'Layout to update',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'uncollapseLayout' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['items', 'events'],
          placeHolder: 'Component',
          componentType: 'map',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Latitude',
          property: 'lat',
          parents: ['items', 'events'],
          placeHolder: 'Latitude',
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: ['!', true] },
          ],
        },
        {
          type: 'dimension',
          label: 'Longitude',
          property: 'lng',
          parents: ['items', 'events'],
          placeHolder: 'Longitude',
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: ['!', true] },
          ],
        },
        {
          type: 'select',
          label: 'Property',
          property: 'property',
          parents: ['items', 'events'],
          placeHolder: 'Property',
          options: [{ value: 'colorField', label: 'Color based on' }],
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'updateLayerProperty' },
            { property: 'target', value: '*' },
          ],
        },
        {
          type: 'dimension',
          label: 'value',
          property: 'value',
          parents: ['items', 'events'],
          placeHolder: 'Select value',
          group: 'Events',
          dependsOn: [{ property: 'property', value: '*' }],
        },
        /*
        {
          type: 'lat-lng',
          label: 'Lat / Lng',
          property: 'latLng',
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: true }
          ]
        },
        */
        {
          type: 'text',
          label: 'Longitude',
          property: 'customLng',
          parents: ['items', 'events'],
          placeHolder: '36.817223',
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: true },
          ],
        },
        {
          type: 'text',
          label: 'Latitude',
          property: 'customLat',
          parents: ['items', 'events'],
          placeHolder: '-1.28638',
          group: 'Events',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: true },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use custom lat / lng',
          property: 'customLatLng',
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Zoom',
          property: 'zoom',
          parents: ['items', 'events'],
          min: 1,
          max: 20,
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Pitch',
          property: 'pitch',
          min: 0,
          max: 90,
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Bearing',
          property: 'bearing',
          min: 0,
          max: 360,
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Duration',
          property: 'duration',
          min: 1000,
          max: 5000,
          step: 1000,
          placeHolder: '1000',
          parents: ['items', 'events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'select',
          label: 'Select tab',
          property: 'tab',
          parents: ['items', 'events'],
          placeHolder: 'Select tab',
          group: 'Events',
          options: [
            { value: '1', label: 'Tab 1' },
            { value: '2', label: 'Tab 2' },
            { value: '3', label: 'Tab 3' },
          ],
          dependsOn: [{ property: 'target', value: 'layout' }],
        },
      ],
    },
  ],
};
