import React, { useEffect, useState } from 'react';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useSelector } from 'react-redux';
import { CategoricalPopOver } from './components/CategoricalPopOver'; // Import your separate components here
import { StepsPopOver } from './components/StepsPopOver';
import { SymbolPopOver } from './components/SymbolPopOver';
import { RadiusPopOver } from './components/RadiusPopover';
import { CategorcialRadiusPopover } from './components/CategorcialRadiusPopover';

interface StepsPopoverProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
  selectValue: string | string[] | undefined;
}

const ColorStylePopover: React.FC<StepsPopoverProps> = (
  props: StepsPopoverProps
) => {
  const { componentIndex, childIndex, itemIndex, item, selectValue } = props;
  const { parents, property } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const [componentLevelColors, setComponentLevelColors] = useState<Dictionary>(
    {}
  );

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      const currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex];
      setComponentLevelColors(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = component?.[parents[0]]?.[itemIndex];
      setComponentLevelColors(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  const popoverItems = () => {
    // Determine which type of component to render based on componentLevelColors
    // You might have to adjust the condition based on your actual logic

    if (
      (componentLevelColors?.colorMode === 'generatedStepsBrakes' ||
        componentLevelColors?.colorMethod === 'breaks') &&
      componentLevelColors?.layerType !== 'symbol' &&
      ![
        'circleRadiusScale',
        'categoricalRadiusClasses',
        'circleRadiusScale',
      ].includes(property)
    ) {
      return (
        <StepsPopOver
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
          selectValue={selectValue}
          componentLevelColors={componentLevelColors}
        />
      );
    } else if (
      (componentLevelColors?.colorMode === 'categories' ||
        componentLevelColors?.colorMethod === 'categorical') &&
      componentLevelColors?.layerType !== 'symbol' &&
      ![
        'circleRadiusScale',
        'categoricalRadiusClasses',
        'circleRadiusScale',
      ].includes(property)
    ) {
      return (
        <CategoricalPopOver
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
          selectValue={selectValue}
          componentLevelColors={componentLevelColors}
        />
      );
    } else if (
      componentLevelColors?.symbolMethod === 'categorical' &&
      ![
        'circleRadiusScale',
        'categoricalRadiusClasses',
        'circleRadiusScale',
      ].includes(property)
    ) {
      return (
        <SymbolPopOver
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
          selectValue={selectValue}
          componentLevelColors={componentLevelColors}
        />
      );
    } else if (componentLevelColors?.radiusScale === 'steps') {
      return (
        <RadiusPopOver
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
          selectValue={selectValue}
          componentLevelColors={componentLevelColors}
        />
      );
    } else if (
      [
        'circleRadiusScale',
        'categoricalRadiusClasses',
        'circleRadiusScale',
      ].includes(property)
    ) {
      return (
        <CategorcialRadiusPopover
          componentIndex={componentIndex}
          item={item}
          childIndex={childIndex}
          itemIndex={itemIndex}
          selectValue={selectValue}
          componentLevelColors={componentLevelColors}
        />
      );
    } else {
      return <></>;
    }
  };

  return popoverItems();
};

export { ColorStylePopover };
