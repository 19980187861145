export const getIcons = (baseURL = 'https://assets.akuko.io/unicef-icons/') => {
  return [
    {
      id: '10year-girl',
      src: `${baseURL}10year-girl.svg`,
      title: '10year girl',
    },
    {
      id: '15year-girl',
      src: `${baseURL}15year-girl.svg`,
      title: '15year girl',
    },
    {
      id: '2year-girl',
      src: `${baseURL}2year-girl.svg`,
      title: '2year girl',
    },
    {
      id: '5year-girl',
      src: `${baseURL}5year-girl.svg`,
      title: '5year girl',
    },
    {
      id: 'advocacy',
      src: `${baseURL}advocacy.svg`,
      title: 'advocacy',
    },
    {
      id: 'aeroplane',
      src: `${baseURL}aeroplane.svg`,
      title: 'aeroplane',
    },
    {
      id: 'affected-children',
      src: `${baseURL}affected-children.svg`,
      title: 'affected children',
    },
    {
      id: 'affected-population',
      src: `${baseURL}affected-population.svg`,
      title: 'affected population',
    },
    {
      id: 'agriculture',
      src: `${baseURL}agriculture.svg`,
      title: 'agriculture',
    },
    {
      id: 'analysis',
      src: `${baseURL}analysis.svg`,
      title: 'analysis',
    },
    {
      id: 'anouncement',
      src: `${baseURL}anouncement.svg`,
      title: 'anouncement',
    },
    {
      id: 'army',
      src: `${baseURL}army.svg`,
      title: 'army',
    },
    {
      id: 'arrested',
      src: `${baseURL}arrested.svg`,
      title: 'arrested',
    },
    {
      id: 'assesment',
      src: `${baseURL}assesment.svg`,
      title: 'assesment',
    },
    {
      id: 'awareness',
      src: `${baseURL}awareness.svg`,
      title: 'awareness',
    },
    {
      id: 'baby',
      src: `${baseURL}baby.svg`,
      title: 'baby',
    },
    {
      id: 'blankets',
      src: `${baseURL}blankets.svg`,
      title: 'blankets',
    },
    {
      id: 'boat',
      src: `${baseURL}boat.svg`,
      title: 'boat',
    },
    {
      id: 'boatsupplies',
      src: `${baseURL}boatsupplies.svg`,
      title: 'boatsupplies',
    },
    {
      id: 'borehole',
      src: `${baseURL}borehole.svg`,
      title: 'borehole',
    },
    {
      id: 'boy',
      src: `${baseURL}boy.svg`,
      title: 'boy',
    },
    {
      id: 'breast-feeding',
      src: `${baseURL}breast-feeding.svg`,
      title: 'breast feeding',
    },
    {
      id: 'budgeting',
      src: `${baseURL}budgeting.svg`,
      title: 'budgeting',
    },
    {
      id: 'calendar',
      src: `${baseURL}calendar.svg`,
      title: 'calendar',
    },
    {
      id: 'cash-donation',
      src: `${baseURL}cash-donation.svg`,
      title: 'cash donation',
    },
    {
      id: 'chart',
      src: `${baseURL}chart.svg`,
      title: 'chart',
    },
    {
      id: 'child-combatant',
      src: `${baseURL}child-combatant.svg`,
      title: 'child combatant',
    },
    {
      id: 'child-protection',
      src: `${baseURL}child-protection.svg`,
      title: 'child protection',
    },
    {
      id: 'children-disability',
      src: `${baseURL}children-disability.svg`,
      title: 'children disability',
    },
    {
      id: 'clothes',
      src: `${baseURL}clothes.svg`,
      title: 'clothes',
    },
    {
      id: 'coldwave',
      src: `${baseURL}coldwave.svg`,
      title: 'coldwave',
    },
    {
      id: 'communication',
      src: `${baseURL}communication.svg`,
      title: 'communication',
    },
    {
      id: 'community-building',
      src: `${baseURL}community-building.svg`,
      title: 'community building',
    },
    {
      id: 'conflict',
      src: `${baseURL}conflict.svg`,
      title: 'conflict',
    },
    {
      id: 'construction',
      src: `${baseURL}construction.svg`,
      title: 'construction',
    },
    {
      id: 'contract',
      src: `${baseURL}contract.svg`,
      title: 'contract',
    },
    {
      id: 'coordination',
      src: `${baseURL}coordination.svg`,
      title: 'coordination',
    },
    {
      id: 'country-office',
      src: `${baseURL}country-office.svg`,
      title: 'country office',
    },
    {
      id: 'damaged',
      src: `${baseURL}damaged.svg`,
      title: 'damaged',
    },
    {
      id: 'danger',
      src: `${baseURL}danger.svg`,
      title: 'danger',
    },
    {
      id: 'data-collection',
      src: `${baseURL}data-collection.svg`,
      title: 'data collection',
    },
    {
      id: 'data',
      src: `${baseURL}data.svg`,
      title: 'data',
    },
    {
      id: 'deployment',
      src: `${baseURL}deployment.svg`,
      title: 'deployment',
    },
    {
      id: 'destroyed',
      src: `${baseURL}destroyed.svg`,
      title: 'destroyed',
    },
    {
      id: 'digger',
      src: `${baseURL}digger.svg`,
      title: 'digger',
    },
    {
      id: 'distribution-site',
      src: `${baseURL}distribution-site.svg`,
      title: 'distribution site',
    },
    {
      id: 'document',
      src: `${baseURL}document.svg`,
      title: 'document',
    },
    {
      id: 'drinking-water',
      src: `${baseURL}drinking-water.svg`,
      title: 'drinking water',
    },
    {
      id: 'drought',
      src: `${baseURL}drought.svg`,
      title: 'drought',
    },
    {
      id: 'drowned',
      src: `${baseURL}drowned.svg`,
      title: 'drowned',
    },
    {
      id: 'earthquake',
      src: `${baseURL}earthquake.svg`,
      title: 'earthquake',
    },
    {
      id: 'education',
      src: `${baseURL}education.svg`,
      title: 'education',
    },
    {
      id: 'emergency',
      src: `${baseURL}emergency.svg`,
      title: 'emergency',
    },
    {
      id: 'epidemic',
      src: `${baseURL}epidemic.svg`,
      title: 'epidemic',
    },
    {
      id: 'facebook',
      src: `${baseURL}facebook.svg`,
      title: 'facebook',
    },
    {
      id: 'financing',
      src: `${baseURL}financing.svg`,
      title: 'financing',
    },
    {
      id: 'fire',
      src: `${baseURL}fire.svg`,
      title: 'fire',
    },
    {
      id: 'flash-flood',
      src: `${baseURL}flash-flood.svg`,
      title: 'flash flood',
    },
    {
      id: 'flood',
      src: `${baseURL}flood.svg`,
      title: 'flood',
    },
    {
      id: 'foetus',
      src: `${baseURL}foetus.svg`,
      title: 'foetus',
    },
    {
      id: 'food-security',
      src: `${baseURL}food-security.svg`,
      title: 'food security',
    },
    {
      id: 'food-water',
      src: `${baseURL}food-water.svg`,
      title: 'food water',
    },
    {
      id: 'forced-entry',
      src: `${baseURL}forced-entry.svg`,
      title: 'forced entry',
    },
    {
      id: 'forklift',
      src: `${baseURL}forklift.svg`,
      title: 'forklift',
    },
    {
      id: 'fuel',
      src: `${baseURL}fuel.svg`,
      title: 'fuel',
    },
    {
      id: 'gap-analysis',
      src: `${baseURL}gap-analysis.svg`,
      title: 'gap analysis',
    },
    {
      id: 'gender-balance',
      src: `${baseURL}gender-balance.svg`,
      title: 'gender balance',
    },
    {
      id: 'girl',
      src: `${baseURL}girl.svg`,
      title: 'girl',
    },
    {
      id: 'google-plus',
      src: `${baseURL}google-plus.svg`,
      title: 'google plus',
    },
    {
      id: 'gov-building',
      src: `${baseURL}gov-building.svg`,
      title: 'gov building',
    },
    {
      id: 'harassment',
      src: `${baseURL}harassment.svg`,
      title: 'harassment',
    },
    {
      id: 'headquarters',
      src: `${baseURL}headquarters.svg`,
      title: 'headquarters',
    },
    {
      id: 'health',
      src: `${baseURL}health.svg`,
      title: 'health',
    },
    {
      id: 'heatwave',
      src: `${baseURL}heatwave.svg`,
      title: 'heatwave',
    },
    {
      id: 'heavy-rain',
      src: `${baseURL}heavy-rain.svg`,
      title: 'heavy rain',
    },
    {
      id: 'hivaids',
      src: `${baseURL}hivaids.svg`,
      title: 'hivaids',
    },
    {
      id: 'home-destroyed',
      src: `${baseURL}home-destroyed.svg`,
      title: 'home destroyed',
    },
    {
      id: 'hospital',
      src: `${baseURL}hospital.svg`,
      title: 'hospital',
    },
    {
      id: 'hotel',
      src: `${baseURL}hotel.svg`,
      title: 'hotel',
    },
    {
      id: 'infant',
      src: `${baseURL}infant.svg`,
      title: 'infant',
    },
    {
      id: 'info-mngmt',
      src: `${baseURL}info-mngmt.svg`,
      title: 'info mngmt',
    },
    {
      id: 'injured',
      src: `${baseURL}injured.svg`,
      title: 'injured',
    },
    {
      id: 'innovation',
      src: `${baseURL}innovation.svg`,
      title: 'innovation',
    },
    {
      id: 'instagram',
      src: `${baseURL}instagram.svg`,
      title: 'instagram',
    },
    {
      id: 'internet',
      src: `${baseURL}internet.svg`,
      title: 'internet',
    },
    {
      id: 'itc',
      src: `${baseURL}itc.svg`,
      title: 'itc',
    },
    {
      id: 'jerrycan',
      src: `${baseURL}jerrycan.svg`,
      title: 'jerrycan',
    },
    {
      id: 'latrine',
      src: `${baseURL}latrine.svg`,
      title: 'latrine',
    },
    {
      id: 'leadership',
      src: `${baseURL}leadership.svg`,
      title: 'leadership',
    },
    {
      id: 'legacy-giving',
      src: `${baseURL}legacy-giving.svg`,
      title: 'legacy giving',
    },
    {
      id: 'linkedin',
      src: `${baseURL}linkedin.svg`,
      title: 'linkedin',
    },
    {
      id: 'man',
      src: `${baseURL}man.svg`,
      title: 'man',
    },
    {
      id: 'map',
      src: `${baseURL}map.svg`,
      title: 'map',
    },
    {
      id: 'medecine',
      src: `${baseURL}medecine.svg`,
      title: 'medecine',
    },
    {
      id: 'medical-supplies',
      src: `${baseURL}medical-supplies.svg`,
      title: 'medical supplies',
    },
    {
      id: 'medicalsupplies',
      src: `${baseURL}medicalsupplies.svg`,
      title: 'medicalsupplies',
    },
    {
      id: 'meeting',
      src: `${baseURL}meeting.svg`,
      title: 'meeting',
    },
    {
      id: 'mental-health',
      src: `${baseURL}mental-health.svg`,
      title: 'mental health',
    },
    {
      id: 'microcephaly',
      src: `${baseURL}microcephaly.svg`,
      title: 'microcephaly',
    },
    {
      id: 'mine',
      src: `${baseURL}mine.svg`,
      title: 'mine',
    },
    {
      id: 'monitoring',
      src: `${baseURL}monitoring.svg`,
      title: 'monitoring',
    },
    {
      id: 'mosquito-net',
      src: `${baseURL}mosquito-net.svg`,
      title: 'mosquito net',
    },
    {
      id: 'mosquito',
      src: `${baseURL}mosquito.svg`,
      title: 'mosquito',
    },
    {
      id: 'mother-baby',
      src: `${baseURL}mother-baby.svg`,
      title: 'mother baby',
    },
    {
      id: 'murder',
      src: `${baseURL}murder.svg`,
      title: 'murder',
    },
    {
      id: 'muscle',
      src: `${baseURL}muscle.svg`,
      title: 'muscle',
    },
    {
      id: 'natcom',
      src: `${baseURL}natcom.svg`,
      title: 'natcom',
    },
    {
      id: 'not-affected',
      src: `${baseURL}not-affected.svg`,
      title: 'not affected',
    },
    {
      id: 'nutrition',
      src: `${baseURL}nutrition.svg`,
      title: 'nutrition',
    },
    {
      id: 'oral-rehyd-salts',
      src: `${baseURL}oral-rehyd-salts.svg`,
      title: 'oral rehyd salts',
    },
    {
      id: 'partnerships',
      src: `${baseURL}partnerships.svg`,
      title: 'partnerships',
    },
    {
      id: 'people',
      src: `${baseURL}people.svg`,
      title: 'people',
    },
    {
      id: 'photo',
      src: `${baseURL}photo.svg`,
      title: 'photo',
    },
    {
      id: 'phsyco-support',
      src: `${baseURL}phsyco-support.svg`,
      title: 'phsyco support',
    },
    {
      id: 'plastic-sheets',
      src: `${baseURL}plastic-sheets.svg`,
      title: 'plastic sheets',
    },
    {
      id: 'pledge-giving',
      src: `${baseURL}pledge-giving.svg`,
      title: 'pledge giving',
    },
    {
      id: 'population-displacement',
      src: `${baseURL}population-displacement.svg`,
      title: 'population displacement',
    },
    {
      id: 'population-growth',
      src: `${baseURL}population-growth.svg`,
      title: 'population growth',
    },
    {
      id: 'population-return',
      src: `${baseURL}population-return.svg`,
      title: 'population return',
    },
    {
      id: 'pregnant',
      src: `${baseURL}pregnant.svg`,
      title: 'pregnant',
    },
    {
      id: 'private-fundraising',
      src: `${baseURL}private-fundraising.svg`,
      title: 'private fundraising',
    },
    {
      id: 'procurement',
      src: `${baseURL}procurement.svg`,
      title: 'procurement',
    },
    {
      id: 'profile-pic',
      src: `${baseURL}profile-pic.svg`,
      title: 'profile pic',
    },
    {
      id: 'radio',
      src: `${baseURL}radio.svg`,
      title: 'radio',
    },
    {
      id: 'rebel',
      src: `${baseURL}rebel.svg`,
      title: 'rebel',
    },
    {
      id: 'regional-office',
      src: `${baseURL}regional-office.svg`,
      title: 'regional office',
    },
    {
      id: 'registration',
      src: `${baseURL}registration.svg`,
      title: 'registration',
    },
    {
      id: 'report',
      src: `${baseURL}report.svg`,
      title: 'report',
    },
    {
      id: 'reporting',
      src: `${baseURL}reporting.svg`,
      title: 'reporting',
    },
    {
      id: 'response',
      src: `${baseURL}response.svg`,
      title: 'response',
    },
    {
      id: 'robbery',
      src: `${baseURL}robbery.svg`,
      title: 'robbery',
    },
    {
      id: 'rutf',
      src: `${baseURL}rutf.svg`,
      title: 'rutf',
    },
    {
      id: 'sanitation',
      src: `${baseURL}sanitation.svg`,
      title: 'sanitation',
    },
    {
      id: 'scales',
      src: `${baseURL}scales.svg`,
      title: 'scales',
    },
    {
      id: 'school-affected',
      src: `${baseURL}school-affected.svg`,
      title: 'school affected',
    },
    {
      id: 'school-destroyed',
      src: `${baseURL}school-destroyed.svg`,
      title: 'school destroyed',
    },
    {
      id: 'school-not-affected',
      src: `${baseURL}school-not-affected.svg`,
      title: 'school not affected',
    },
    {
      id: 'school',
      src: `${baseURL}school.svg`,
      title: 'school',
    },
    {
      id: 'shelter',
      src: `${baseURL}shelter.svg`,
      title: 'shelter',
    },
    {
      id: 'shower',
      src: `${baseURL}shower.svg`,
      title: 'shower',
    },
    {
      id: 'snowfall',
      src: `${baseURL}snowfall.svg`,
      title: 'snowfall',
    },
    {
      id: 'soap',
      src: `${baseURL}soap.svg`,
      title: 'soap',
    },
    {
      id: 'social-inclusion',
      src: `${baseURL}social-inclusion.svg`,
      title: 'social inclusion',
    },
    {
      id: 'staff-mngmt',
      src: `${baseURL}staff-mngmt.svg`,
      title: 'staff mngmt',
    },
    {
      id: 'storm',
      src: `${baseURL}storm.svg`,
      title: 'storm',
    },
    {
      id: 'supplies',
      src: `${baseURL}supplies.svg`,
      title: 'supplies',
    },
    {
      id: 'supplies2',
      src: `${baseURL}supplies2.svg`,
      title: 'supplies2',
    },
    {
      id: 'supplies3',
      src: `${baseURL}supplies3.svg`,
      title: 'supplies3',
    },
    {
      id: 'syringe',
      src: `${baseURL}syringe.svg`,
      title: 'syringe',
    },
    {
      id: 'syringes',
      src: `${baseURL}syringes.svg`,
      title: 'syringes',
    },
    {
      id: 'tarpaulin',
      src: `${baseURL}tarpaulin.svg`,
      title: 'tarpaulin',
    },
    {
      id: 'techno-disaster',
      src: `${baseURL}techno-disaster.svg`,
      title: 'techno disaster',
    },
    {
      id: 'telecommunications',
      src: `${baseURL}telecommunications.svg`,
      title: 'telecommunications',
    },
    {
      id: 'tent',
      src: `${baseURL}tent.svg`,
      title: 'tent',
    },
    {
      id: 'toilet',
      src: `${baseURL}toilet.svg`,
      title: 'toilet',
    },
    {
      id: 'tornado',
      src: `${baseURL}tornado.svg`,
      title: 'tornado',
    },
    {
      id: 'tracking',
      src: `${baseURL}tracking.svg`,
      title: 'tracking',
    },
    {
      id: 'training',
      src: `${baseURL}training.svg`,
      title: 'training',
    },
    {
      id: 'truck',
      src: `${baseURL}truck.svg`,
      title: 'truck',
    },
    {
      id: 'tsunami',
      src: `${baseURL}tsunami.svg`,
      title: 'tsunami',
    },
    {
      id: 'twitter-bird',
      src: `${baseURL}twitter-bird.svg`,
      title: 'twitter bird',
    },
    {
      id: 'twitter',
      src: `${baseURL}twitter.svg`,
      title: 'twitter',
    },
    {
      id: 'un-compound',
      src: `${baseURL}un-compound.svg`,
      title: 'un compound',
    },
    {
      id: 'urban-area',
      src: `${baseURL}urban-area.svg`,
      title: 'urban area',
    },
    {
      id: 'vaccines-supplies',
      src: `${baseURL}vaccines-supplies.svg`,
      title: 'vaccines supplies',
    },
    {
      id: 'video-camera',
      src: `${baseURL}video-camera.svg`,
      title: 'video camera',
    },
    {
      id: 'volcano',
      src: `${baseURL}volcano.svg`,
      title: 'volcano',
    },
    {
      id: 'warehouse',
      src: `${baseURL}warehouse.svg`,
      title: 'warehouse',
    },
    {
      id: 'wash',
      src: `${baseURL}wash.svg`,
      title: 'wash',
    },
    {
      id: 'waste',
      src: `${baseURL}waste.svg`,
      title: 'waste',
    },
    {
      id: 'water-source',
      src: `${baseURL}water-source.svg`,
      title: 'water source',
    },
    {
      id: 'water-tank',
      src: `${baseURL}water-tank.svg`,
      title: 'water tank',
    },
    {
      id: 'water-trucking',
      src: `${baseURL}water-trucking.svg`,
      title: 'water trucking',
    },
    {
      id: 'weibo',
      src: `${baseURL}weibo.svg`,
      title: 'weibo',
    },
    {
      id: 'well',
      src: `${baseURL}well.svg`,
      title: 'well',
    },
    {
      id: 'winter-nfi',
      src: `${baseURL}winter-nfi.svg`,
      title: 'winter nfi',
    },
    {
      id: 'winterization',
      src: `${baseURL}winterization.svg`,
      title: 'winterization',
    },
    {
      id: 'woman',
      src: `${baseURL}woman.svg`,
      title: 'woman',
    },
    {
      id: 'youtube',
      src: `${baseURL}youtube.svg`,
      title: 'youtube',
    },
  ];
};
