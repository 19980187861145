import React from 'react';
import { Row, Col } from 'antd';
import { getLegend } from './utils';
import { LayerProps } from '../../../../../configs/types';

interface LegendProps {
  layers: LayerProps[];
}

const Legend = (props: LegendProps): JSX.Element => {
  const { layers } = props;

  return (
    <div className="map-legend-container">
      <Row>
        {layers
          .filter((layer) => layer.visible && layer.legend?.isEnabled)
          .map((layer, i) => (
            <Col span={24} key={i}>
              <div className="map-legend">
                <span className="map-legend--name">{layer.layerGroupName}</span>
                {getLegend(layer)}
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export { Legend };
