import React, { useRef, useState } from 'react';
import { InputRef, Table } from 'antd';
import { Dictionary } from '@onaio/utils';
import { useSelector } from 'react-redux';
import { getTableDataFromCubeQueryResults } from '../../helpers/helpers';

interface CubeQueryResultsTableProps {
  data: Dictionary[];
  loading: boolean;
}

const CubeQueryResultsTable: React.FC<CubeQueryResultsTableProps> = (
  props: CubeQueryResultsTableProps
) => {
  const source = useSelector((state: Dictionary) => state.source);
  const { data, loading } = props;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const searchUtils = {
    searchText,
    setSearchText,
    searchedColumn,
    setSearchedColumn,
    searchInput,
  };

  const { columns, rows } = getTableDataFromCubeQueryResults(data, source, searchUtils);

  return (
    <Table
      key={JSON.stringify(data)}
      size={'small'}
      className="query-results-table"
      loading={loading}
      scroll={{ x: true }}
      pagination={{
        defaultPageSize: 10,
      }}
      columns={columns}
      dataSource={rows}
      bordered
    />
  );
};

export { CubeQueryResultsTable };
