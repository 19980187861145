import React, { useEffect, useState } from 'react';
import { Select, Tooltip, Row, Col, Popover } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../../actions';
import { QuestionCircleFilled, LinkOutlined } from '@ant-design/icons';

const { Option } = Select;

export interface FontFamilyInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const fonts = [
  {
    label: 'Sans Serif',
    options: [
      { value: 'DM Sans', label: 'DM Sans' },
      { value: 'Lato', label: 'Lato' },
      { value: 'Inter', label: 'Inter' },
      { value: 'Montserrat', label: 'Montserrat' },
      { value: 'Mulish', label: 'Mulish' },
      { value: 'Noto Sans', label: 'Noto Sans' },
      { value: 'Nunito', label: 'Nunito' },
      { value: 'Open Sans', label: 'Open Sans' },
      { value: 'Oswald', label: 'Oswald' },
      { value: 'Poppins', label: 'Poppins' },
      { value: 'PT Sans', label: 'PT Sans' },
      { value: 'Raleway', label: 'Raleway' },
      { value: 'Roboto', label: 'Roboto' },
      { value: 'Radio Canada', label: 'Radio Canada' },
      { value: 'Ubuntu', label: 'Ubuntu' },
      
    ]
  },
  {
  label: 'Serif',
  options: [
  { value: 'Adamina', label: 'Adamina' },
  { value: 'Libre Baskerville', label: 'Libre Baskerville' },
  { value: 'PT Serif', label: 'PT Serif' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Roboto Slab', label: 'Roboto Slab' },
  ]
  },
  {
    label: 'Display',
    options: [
      { value: 'Chewy', label: 'Chewy' },
      { value: 'Koulen', label: 'Koulen' },
      { value: 'Pacifico', label: 'Pacifico' },
    ]
  },{
    label: 'Handwriting',
    options: [
      { value: 'Architects Daughter', label: 'Architects Daughter' },
      { value: 'Beth Ellen', label: 'Beth Ellen' },
      { value: 'Caveat', label: 'Caveat' },
      { value: 'Kalam', label: 'Kalam' },
      { value: 'Mansalva', label: 'Mansalva' },
      { value: 'Nanum Pen Script', label: 'Nanum Pen Script' },
      { value: 'Permanent Marker', label: 'Permanent Marker' },
      { value: 'Special Elite', label: 'Special Elite' },
    ]
  }
]

const FontFamilyInput: React.FC<FontFamilyInputProps> = (props: FontFamilyInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const {
    property,
    label,
    options,
    refreshKey,
    parents,
    selectMode,
    inherit,
    placeHolder,
    objectKey,
    tooltip,
    labelLink,
  } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const space = useSelector((state: Dictionary) => state.space);

  const component = post.components[componentIndex];
  const [value, setValue] = useState('');
  const [inherited, setInherited] = useState(false);

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      let currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      let currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue = component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (!parents && componentIndex >= 0) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      if (post[property] && objectKey) {
        setValue(post[property][objectKey]);
      } else {
        setValue(post[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  useEffect(() => {
    if (post?.config?.[inherit] || space?.config?.[inherit]) {
      setInherited(true)
    } else {
      setInherited(false)
    }
  }, [post?.config, space?.config])

  const componentConfigs = {
    parents: parents,
    property: property,
    componentIndex: componentIndex,
    itemIndex: itemIndex,
    childIndex: childIndex,
    refreshKey: refreshKey,
    objectKey: objectKey,
  };

  const getInherit = () => {
    if (space?.config?.[inherit]) {
      return `[space.config]: ${space?.config?.[inherit]}`
    }
    if (post.config[inherit]) {
      return `[post.config]: ${post?.config?.[inherit]}`
    }
    return null;
  }

  return (
    <Row gutter={10}>
      <Col sm={6}>
      <label>
      { inherited && 
      <Popover
       overlayClassName='inherit-info'
       content={getInherit()}
      >
      <span className="setting-inherited"></span> 
      </Popover>
      }
        {label}
        &nbsp;
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleFilled />
          </Tooltip>
        )}
        {labelLink && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={labelLink} target="_blank">
            <LinkOutlined />
          </a>
        )}
      </label>
      </Col>
      <Col sm={18}>
      <Select
        placeholder={placeHolder ? placeHolder : inherit}
        value={value}
        allowClear
        showSearch
        mode={selectMode}
        onChange={(value) => {
          setValue(value);
          if (item.entity !== 'post') {
           return dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: property,
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                refreshKey: refreshKey,
                objectKey: objectKey,
                value: value === undefined ? undefined : value,
              })
            );
          }
         return setTimeout(() => {
            if (item.entity === 'post') {
              dispatch(
                actionPostPropertyEdit({
                  property: property,
                  objectKey: objectKey,
                  value: value,
                })
              );
              if (objectKey === 'fontFamily') {
              post.components.forEach((item: Dictionary, index: number) => {
                if (item.type === 'text' ||
                item.type === 'title') {
                  dispatch(
                  actionPostComponentSettingEdit({
                    property: 'fontFamily',
                    componentIndex: index,
                    value: value,
                  })
                  )
                }
              })
             }
            }
            
          }, 500);   
        }}
        options={fonts}
      />
      </Col>
    </Row>
  );
};

export { FontFamilyInput };