import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import { Dropdown } from 'antd';
import { PostComponentType } from '../../../../configs/component-types';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { componentsMenuBuilder } from './componentsMenuBuilder';

/** interface for component props */
export interface AddComponentMenuProps {
  index?: number; // index the component would be added added at, if not provided component will be added as last
  regionIndex?: number; // region index for layouts
  layoutId?: string; //  layout id for layouts
  spaceComponent?: boolean; // space component
  activeMenuItems?: PostComponentType[]; // menu items to be made available for respective types of component
}

export interface AddComponentMenuLink {
  title: string;
  type: PostComponentType;
  onClick: () => void;
}

const AddComponentMenu: React.FC<AddComponentMenuProps> = (props: AddComponentMenuProps) => {
  const dispatch = useDispatch();
  const post = useSelector((state: Dictionary) => state.post);

  const { regionIndex, layoutId, activeMenuItems, index, spaceComponent } = props;

  const menu = componentsMenuBuilder({
    regionIndex: regionIndex,
    layoutId: layoutId,
    activeMenuItems: activeMenuItems,
    index: index,
    spaceComponent: spaceComponent,
    dispatch: dispatch,
    postType: post?.config?.type,
    componentsLength: post?.components.length,
  });

  return (
    <div className="add-component-wrapper">
      <Dropdown overlay={menu} trigger={['click']}>
        <div
          style={{ cursor: 'pointer', color: '#111' }}
          id="btn-add-component"
          className="btn-add"
          onClick={(e) => {
            e.preventDefault;
          }}
        >
          <PlusOutlined style={{ fontSize: '20px' }} />
        </div>
      </Dropdown>
    </div>
  );
};

export { AddComponentMenu };
