export interface colorSchemesProps {
  type: string;
  colors: string[];
}
export const colorSchemes = [
  {
    type: 'Sequential',
    colors: [
      'BuGn',
      'BuPu',
      'GnBu',
      'OrRd',
      'PuBu',
      'PuBuGn',
      'PuRd',
      'RdPu',
      'YlGn',
      'YlGnBu',
      'YlOrBr',
      'YlOrRd',
      'Blues',
      'Greens',
      'Greys',
      'Oranges',
      'Purples',
      'Reds',
    ],
  },
  {
    type: 'Diverging',
    colors: ['BrBG', 'PiYG', 'PRGn', 'PuOr', 'RdBu', 'RdGy', 'RdYlBu', 'RdYlGn', 'Spectral'],
  },
  {
    type: 'Qualitative',
    colors: ['Paired', 'Pastel1', 'Pastel2', 'Set1', 'Set2', 'Set3'],
  },
];
