/**
 * used when we have an error arising from a non-200 http status
 */
export class HTTPError extends Error {
  public statusCode: number;
  public message: string;
  constructor(message: string, status: number) {
    super();
    this.statusCode = status;
    this.message = message;
  }
}
