import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import { actionAccountSpaceAdd, actionAccountSpaceGet } from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
};

export const AccountAdd = (props) => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(params.id);
  const [form] = Form.useForm();

  const makeAccountId = (name) => {
    const accountId = name.replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'"<>,.? ])+/g, '-');
    return accountId.toLowerCase();
  };

  useEffect(() => {
    setAccountId(params.id);
  }, [params.id]);

  return (
    <Modal
      visible={props.showModal}
      footer={null}
      onCancel={() => {
        props.setShowModal(false);
      }}
    >
      <Form
        form={form}
        layout={'vertical'}
        onValuesChange={(values) => {
          if (values.name) {
            form.setFieldsValue({ id: makeAccountId(values.name) });
          }
          if (values.id) {
            form.setFieldsValue({ id: makeAccountId(values.id) });
          }
        }}
        onFinish={(values) => {
          setLoading(true);
          const service = new AkukoAPIService(IDENTITY_API, 'account');
          service
            .create({ name: values.name, id: values.id, config: {}, email: props.user.email })
            .then(() => {
              setTimeout(() => {
                setLoading(false);
                form.resetFields();
                props.setShowModal(false);
                history.push(`/account/${values.id}`);
                message.success('Account created');
              }, 3000);
            })
            .catch((error) => {
              setLoading(false);
              if (error?.message && error.message.includes('Forbidden')) {
                message.error('Permission denied');
              } else {
                message.error(error?.message || 'Account already exists');
              }
            });
        }}
      >
        <Form.Item
          name="name"
          label="Organization Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input size="large" placeholder="Organization name" />
        </Form.Item>
        <Form.Item
          name="id"
          label="URL"
          hasFeedback
          validateTrigger="onChange"
          rules={[
            {
              required: true,
              message: 'Account URL is not available',
              validator: (field, value) => {
                if (value.length > 2) {
                  const service = new AkukoAPIService(IDENTITY_API, 'space');
                  return service.read(value).then((data) => {
                    if (data.msg) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  }).catch(e => {
                    if (e?.statusCode === 404) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  });
                } else {
                  return Promise.reject();
                }
              },
            },
          ]}
        >
          <Input
            addonBefore="https://"
            placeholder="organization-name"
            size="large"
            addonAfter="akuko.io"
          />
        </Form.Item>
        <Button disabled={loading} type="primary" size="large" htmlType="submit">
          {loading && <LoadingOutlined />} {loading ? 'Creating Organization...' : 'Create Organization'}
        </Button>
      </Form>
    </Modal>
  );
};

const AccountAddContainer = connect(mapStateToProps, mapDispatchToProps)(AccountAdd);

export default AccountAddContainer;
