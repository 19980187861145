import React, { useEffect } from 'react';
import { Col, Row, Tabs } from 'antd';
import Region from './Region';
const { TabPane } = Tabs;

const LayoutTabs = (props) => {
  const { componentIndex, post } = props;

  return (
    <div className="layout-tabs">
      <Row gutter={15}>
        <Col xs={24}>
          <Tabs defaultActiveKey="2">
            {post.components[componentIndex]?.tabs?.map((item, index) => (
              <TabPane tab={item.title} key={index + 1}>
                <Region regionIndex={index + 1} {...props} />
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export { LayoutTabs };
