import produce from 'immer';
import { message } from 'antd';
import { aC } from 'lazysizes';

const account = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case 'SPACE_GET':
      if (action.data) {
        if (action.data.users) {
          nextState = produce(state, (draftState) => action.data);
        } else {
          if (action.data.emails) {
            const users = [];
            action.data.emails.forEach((item) => {
              users.push({ id: item, key: item, role: 'viewer' });
            });
            action.data.users = users;
          }
          nextState = produce(state, (draftState) => action.data);
        }
      } else {
        return state;
      }
      return nextState;
    case 'SPACE_CLEAR_CONFIG':
      nextState = {};
      return nextState;
    case 'ACCOUNT_USERS_GET':
      nextState = produce(state, (draftState) => {
        if (action.data.length) {
          draftState.users = action.data.sort((a, b) => a.email.localeCompare(b.email));
        } else {
          draftState.users = [];
        }
      });
      return nextState;
    case 'ACCOUNT_USER_ROLE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.users.forEach((user) => {
          if (user.uuid === action.data.userId) {
            user.value.role = action.data.value;
          }
        });
      });
      return nextState;
    case 'SPACE_PROPERTY_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.config[action.data.property] = action.data.value;
      });
      return nextState;
    case 'SPACE_COMPONENT_ADD':
      nextState = produce(state, (draftState) => {
        const { data } = action;
        if (!draftState.config?.components) {
          draftState.config.components = [];
        }
        draftState.config?.components?.push(data);
      });
      return nextState;
    case 'SPACE_NAME_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.name = action.data.value;
      });
      return nextState;
    case 'SPACE_MENU_ITEM_ADD':
      nextState = produce(state, (draftState) => {
        if (!draftState.config.menu) {
          draftState.config.menu = [];
        }
        draftState.config.menu.push({
          item: 'item',
        });
      });
      return nextState;
    case 'SPACE_MENU_ITEM_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.config.menu[action.data.index][action.data.property] = action.data.value;
      });
      return nextState;
    case 'SPACE_MENU_ITEM_DELETE':
      nextState = produce(state, (draftState) => {
        draftState.config.menu.splice(action.data.index, 1);
      });
      return nextState;
    case 'SPACE_LOGO_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.config.logo_file = action.data.value;
      });
      return nextState;
    case 'SPACE_LOGO_WIDTH_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.config.logo_width = action.data.value;
      });
      return nextState;
    default:
      return state;
  }
};

export default account;
