import React, { useState, useEffect, useRef } from 'react';
import { Button, Space } from 'antd';
import { QueryExportParams } from '../../../../configs/component-types';
import { ExportOutlined, LoadingOutlined } from '@ant-design/icons';
import { triggerAsycDataExport } from './helpers/helpers';

interface ExportSourceQueryProps {
  query: QueryExportParams;
  componentDataExport?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExportSourceQuery = (props: ExportSourceQueryProps): any => {
  const { query, componentDataExport } = props;
  const [loading, setLoading] = useState(false);
  const [dataInCSV, setDataInCSV] = useState<string | undefined>(undefined);
  const csvDownloadLink = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setLoading(false);
    setDataInCSV(undefined);
  }, [query]);

  useEffect(() => {
    if (dataInCSV !== undefined) {
      csvDownloadLink.current?.click();
      setDataInCSV(undefined);
      setLoading(false);
    }
  }, [dataInCSV]);

  const componentMenuExport = componentDataExport && [
    {
      key: '1',
      disabled: { loading },
      label: (
        <>
          {dataInCSV !== undefined && (
            <Button
              type="primary"
              className="hidden"
              href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
              download={`${query.cube_query.query.uuid}.csv`}
              target="_blank"
              /* @ts-ignore */
              ref={csvDownloadLink}
            />
          )}
          <Space
            onClick={(e) => {
              setLoading(true);
              triggerAsycDataExport(query, setDataInCSV, setLoading);
              e.stopPropagation();
            }}
          >
            <ExportOutlined /> &nbsp; &nbsp;
            {loading && <LoadingOutlined />} {loading ? 'Preparing export...' : 'Export csv'}
          </Space>
        </>
      ),
    },
  ];

  return componentDataExport ? (
    componentMenuExport
  ) : (
    <>
      {dataInCSV !== undefined ? (
        <Button
          type="primary"
          className="hidden"
          href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
          download={`${query.cube_query.query.uuid}.csv`}
          target="_blank"
          /* @ts-ignore */
          ref={csvDownloadLink}
        />
      ) : null}
      <Button
        type="primary"
        disabled={loading}
        onClick={() => {
          setLoading(true);
          triggerAsycDataExport(query, setDataInCSV, setLoading);
        }}
      >
        {loading && <LoadingOutlined />} {loading ? 'Preparing export...' : 'Export data'}
      </Button>
    </>
  );
};

export { ExportSourceQuery };
