import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TopRegion } from './components/TopRegion';
import { RightRegion } from './components/RightRegion';
import { MainRegion } from './components/MainRegion';
import { LeftRegion } from './components/LeftRegion';
import { BottomRegion } from './components/BottomRegion';
import { LeftMenu } from './components/LeftMenu';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { ComponentMenu } from './components/ComponentMenu';
import { PostComponents } from '../PostComponents';
import PostContainer from '../index';
import { SourceMenu } from './components/SourceMenu';
import { PostSettings } from './components/PostSettings';
import { StyleSettings } from '../../Account/StyleSettings';
import SourceComponent from '../../Source';
import { Settings } from '../Settings';
import { Row, Col } from 'antd';
import './style.css';

export interface EditorProps {
  id: string;
}

const Editor: React.FC<EditorProps> = (props: EditorProps) => {
  const location = useLocation<Dictionary>();
  const params = useParams<Dictionary>();
  const user = useSelector((store: Dictionary) => store.user);
  const post = useSelector((store: Dictionary) => store.post);
  const config = useSelector((store: Dictionary) => store.config);
  const source = useSelector((store: Dictionary) => store.source);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!user.uuid) {
      return setShowLoader(true);
    }
    return setShowLoader(false);
  }, [user]);

  useEffect(() => {
    document.body.classList.add('editor-ui');
    return () => {
      document.body.classList.remove('editor-ui');
      document.body.classList.remove('dark-mode');
      document.body.classList.remove('hide-settings');
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/data')) {
      if (!document.body.classList.contains('menu-expanded')) {
        document.body.classList.add('menu-expanded');
        document.body.classList.add('data-ui');
        document.body.classList.remove('hide-left-region');
      }
    } else {
      if (document.body.classList.contains('menu-expanded')) {
        document.body.classList.remove('menu-expanded');
      }
      if (document.body.classList.contains('data-ui')) {
        document.body.classList.remove('data-ui');
      }
    }
  }, [location.pathname]);

  if (location.pathname.includes('/post')) {
    return (
      <>
        <TopRegion id="id" />
        <LeftMenu />
        <LeftRegion id="id">
          <>
            {post.uuid && config.activeSettingId === 'components' && <ComponentMenu />}
            {post.uuid && config.activeSettingId === 'settings' && <PostSettings />}
            {post.uuid && config.activeSettingId === 'errors' && (
              <PostComponents isErrorListing={true} />
            )}
          </>
        </LeftRegion>
        <MainRegion id="id">
          <div className="editor-post-container">
            <PostContainer />
          </div>
        </MainRegion>
        <RightRegion id="id">
          { config.activeComponentIndex < 0 &&
            <StyleSettings />
          }
          { config.activeComponentIndex >= 0 &&
            <Settings componentIndex={config.activeComponentIndex} />
          }
        </RightRegion>
        <BottomRegion id="id" />
      </>
    );
  }

  if (location.pathname.includes('/data')) {
    return (
      <>
        <TopRegion id="id" />
        <LeftRegion id="id">
          <SourceMenu />
        </LeftRegion>
        <MainRegion id="id">
          <div className="editor-source-container">
            {params.id ? (
              <>
                <h2>{source.name}</h2>
                <SourceComponent sourceId={params?.id} />
              </>
            ) : (
              <div className="source-select">
                <Row gutter={20}>
                  <Col xs={24} sm={24}>
                    <div className="content">
                      <p>No Source selected.</p>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </MainRegion>
      </>
    );
  }

  return null;
};

export { Editor };
