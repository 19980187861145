import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../../../../../configs/components';
import { Button, Collapse, message, Row, Col, Popconfirm } from 'antd';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { POSTS_API } from '../../../../../configs/env';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';
import {
  actionConfigComponentIndexActive,
  actionConfigDrawerOpenEdit,
  actionPostDelete,
  actionPostPropertyEdit,
} from '../../../actions';
import {
  closeProfileMenu,
  getComponentBasedOnType,
  getIcon,
  shouldShowField,
  shouldShowPostField,
} from '../../../Settings/helpers/helpers';
import { AddComponentMenu } from '../../../helpers/Settings/add-component-menu';
import { Scrollbars } from 'react-custom-scrollbars';
import { ERROR_GENERIC } from '../../../../../configs/constants';
const { Panel } = Collapse;

const PostSettings = () => {
  const post = useSelector((store: Dictionary) => store.post);
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();

  return (
    <div>
        {config['post'].groups?.map((group: string, groupIndex: number) => (
          <Collapse expandIconPosition={'right'} key={groupIndex} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
            <Panel key={groupIndex} header={group}>
              <Row gutter={10}>
                {config['post'].fields.map(
                  (item: Dictionary, index: number) =>
                    item.group === group && (
                      <Col key={index} xs={item.cols || 24}>
                        <div key={index}>
                        {shouldShowField(
                            [],
                            index,
                            item,
                            post) &&
                         getComponentBasedOnType(item, 0, index, 0)}    
                        </div>
                      </Col>
                    )
                )}
              </Row>
            </Panel>
          </Collapse>
        ))}
        
        <div className="drawer-footer">
          <Popconfirm
            title="Are you sure you want to delete this post?"
            onConfirm={() => {
              const service = new AkukoAPIService(POSTS_API, `post/${post.uuid}`);
              service.delete().then(() => {
                message.success('Post Deleted');
                dispatch(
                  actionPostDelete({
                    uuid: post.uuid,
                  })
                );
                history.push('/dashboard/posts');
                message.success('Post deleted');
              });
            }}
          >
            <Button
              danger
              id="btn-post-delete"
              type="primary"
            >
              Delete Post
            </Button>
            &nbsp;&nbsp;&nbsp;
          </Popconfirm>
          
          </div>
     
    </div>
  );
}

export { PostSettings }




          