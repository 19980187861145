import produce from 'immer';
import moment from 'moment';

const posts = (state = [], action) => {
  let nextState;
  switch (action.type) {
    case 'POSTS_GET':
      if (action.error) {
        //window.location = '/user/login';
      }
      if (action.data) {
        nextState = produce(state, (draftState) => action.data);
      }
      return nextState;
    case 'POST_DELETE':
        nextState = produce(state, (draftState) => {
          draftState.forEach((item, index) => {
            if (item.uuid === action.data.uuid) {
              draftState.splice(
                index || 0,
                1
              );
            }
          })
        })
      return nextState;
    default:
      return state;
  }
};

export default posts;
