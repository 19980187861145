import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { PhotoshopPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { AkukoAPIService } from '../../../services/serviceClass';
import { actionUserCache } from '../../Account/actions';
import { IDENTITY_API } from '../../../configs/env';
import './style.css';

const UserPic = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [user, setUser] = useState({ image: 'user-icon.png' });

  useEffect(() => {
    if (props.author) {
      if (users[props.author]) {
        setUser(users[props.author]);
      } else {
      const service = new AkukoAPIService(IDENTITY_API, 'user');
      service.read(props.author).then((user) => {
        setUser(user);
        dispatch(actionUserCache(user));
      });
      }
    }
  }, [props.author]);

  if (props.author && user.image) {
    return (
      <div
        key={user.uuid}
        className="user-pic"
        style={{ backgroundImage: `url(https://assets.akuko.io/${user.image})` }}
      ></div>
    );
  } else {
    return (
      <div
        key={user.uuid}
        className="user-pic"
        style={{ backgroundImage: `url(https://assets.akuko.io/user-icon.png)` }}
      ></div>
    );
  }
};

export { UserPic };
