import React, {
  useState,
  createRef,
} from 'react';
import { Modal, Select, message, Switch } from 'antd';
import { IDENTITY_API, POSTS_API } from '../../../configs/env';
import { actionPostPropertyEdit } from '../../Post/actions';
import { AkukoAPIService } from '../../../services/serviceClass';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { actionPostTagsEdit, actionPostConfigEdit } from '../../Post/actions';
import { Dictionary } from '@onaio/utils';
import Draggable from 'react-draggable';
import './style.css';

interface PostSettingsProps {
  open: boolean;
  onCancel: () => void;
}

export const PostSettings = (props: PostSettingsProps) => {
  const dispatch = useDispatch();
  const store = useStore();
  const space = useSelector((store: Dictionary) => store.space);
  const post = useSelector((store: Dictionary) => store.post);
  const { open, onCancel } = props;
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = createRef<HTMLDivElement>();

 

  const onStart = (_event: Dictionary, uiData: Dictionary) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Modal
      className='post-settings-modal'
      open={open}
      mask={false}
      onCancel={onCancel}
      onOk={() => {
        onCancel();
        // save post config
        const state = store.getState();
        /* @ts-ignore */
        const postObj = Object.assign({}, state.post);
        const postService = new AkukoAPIService(POSTS_API, `post/${post.uuid}`);
        postService
          .update(postObj)
          .then((res) => {
            const obj = res as Dictionary;
            dispatch(
              actionPostPropertyEdit({
                property: 'updated',
                value: obj.updated,
              })
            );
            message.success('Settings saved');
          })
          .catch((error) => {
            message.error(error.message);
          });

        if (space.uuid) {
          // Save space config
          const sourceService = new AkukoAPIService(
            IDENTITY_API,
            `space/${space.uuid}`
          );
          sourceService
            .update(space)
            .then(() => {
              //message.success('Space settings updated');
            })
            .catch((error) => {
              message.error(error.message);
            });
        }
      }}
      width={312}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <h2>Post settings</h2>
      <div className="field">
        <label>Post tags</label>
        <Select
          mode="tags" 
          value={post.tags}
          allowClear
          onChange={(values) => {
            dispatch(actionPostTagsEdit(values))
          }}
        />
      </div>
      <div  className="field">
        <Switch 
          value={post.config?.showInlineControls}
          onChange={(value) => {
            dispatch(actionPostConfigEdit({
              property: 'showInlineControls',
              value: value
            }))
          }}
        /> Show inline component controls
      </div>
    </Modal>
  );
};
