import React from 'react';
import { TwitterOutlined } from '@ant-design/icons';
import './style.css';

export const Footer = (props) => {
  return (
    <div className="global-footer" style={{ 
      textAlign: props.align || 'center',
      paddingRight: props.align ? '40px' : '0px',
      paddingBottom: props.position ? '0px' : '40px',
      paddingTop: props.position ? '0px' : '40px',
      position: props.position ? 'absolute' : 'relative',
      bottom: props.position ? '40px' : '0px',
      [props.align]: props.position ? '20px' : '0px'
      }}>
      <a target="blank" href="https://akuko.io">&copy; Akuko</a>
      <a target="blank" href="https://akuko.io/terms">Terms</a>
      <a target="blank"href="https://akuko.io/privacy">Privacy</a>
    </div>
  );
};

export default Footer;
