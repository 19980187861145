import React from 'react';
import { GenericPostComponent } from '../../../configs/component-types';

export interface PostComponentProps {
  componentIndex?: number; // component index
  showEditMenu?: boolean; // boolean to display/hide edit menu
  showWidthMenu?: boolean; // enable/disable width menu
  displayInsert?: boolean; // show insert component
  children: React.ReactNode; // component children
  displayEmbed?: boolean; // show embed control
  displayDrag?: boolean; // hide/display move iconIn
  displayMoveUp?: boolean; // hide display move up icon
  displayMoveDown?: boolean; // hide display move down icon
  onEdit: () => void; // function to handle when edit button is clicked
  onUp?: () => void; // function to handle to handle move up menu item
  onDown?: () => void; // function to handle move down menu item
  onDelete: () => void; // function to handle delete menu item
  onWidthChange?: (value: string) => void;
  onDrag?: () => void;
  onEmbed?: () => void;
  onInsert?: (componentType: string) => void;
  onCopy?: () => void;
  className?: string; // optional CSS class name
  components?: GenericPostComponent[];
}

const defaultProps: Partial<PostComponentProps> = {
  showEditMenu: true,
  showWidthMenu: false,
  displayDrag: false,
  displayEmbed: false,
  displayMoveUp: true,
  displayMoveDown: true,
  displayInsert: true,
  className: '',
};

const PostComponent: React.FC<PostComponentProps> = (props: PostComponentProps) => {
  const { children, componentIndex, className } = props;

  return (
    <div id={`post-component-${componentIndex}`} className={`post--component ${className}`}>
      {children}
    </div>
  );
};

PostComponent.defaultProps = defaultProps;

export { PostComponent };
