import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { message } from 'antd';
import { TEMPORAL_POST_WORKER_WEB_SOCKET_URL } from '../../../../../configs/env';
import { Dictionary } from '@onaio/utils';
import { postScheduleSendJobStatusTypes } from '../../../../../configs/component-types';
import {
  startAsycJobForCreatingPostScheduleSend,
  startAsycJobForUpdatingPostScheduleSend,
  startAsycPostExport,
} from '../../helpers/helpers';

export const checkAsyncTaskProgress = async (
  jobId: string,
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
  setAsyncTaskProgressData: (progress: Dictionary) => void,
  asyncTaskName: string,
  setLoading: (loadingState: boolean) => void
): Promise<void> => {
  socket?.on(
    jobId,
    (data: {
      progress: {
        stage: postScheduleSendJobStatusTypes;
        data: Dictionary[];
        errors: Dictionary[];
      };
    }) => {
      switch (data?.progress?.stage) {
        case postScheduleSendJobStatusTypes.DONE:
          setAsyncTaskProgressData({
            ...data?.progress,
          });
          setLoading(false);
          socket?.disconnect();
          break;
        default:
          if (data?.progress?.errors.length !== 0) {
            const errorMessage = data?.progress?.errors[0]?.message;
            if (errorMessage) {
              message.error(`${errorMessage}`, 2.5);
            } else {
              message.error(`${asyncTaskName} failed`, 2.5);
            }
            setAsyncTaskProgressData({});
            setLoading(false);
            socket?.disconnect();
          } else {
            setAsyncTaskProgressData({
              ...data?.progress,
            });
          }
          break;
      }
    }
  );
};

export const triggerAsycPostOperation = ({
  asynFunc,
  asynFuncArgs,
  setAsyncTaskProgressData,
  asyncTaskName,
  setLoading,
  webSocketUrl,
}: {
  asynFunc:
    | typeof startAsycJobForCreatingPostScheduleSend
    | typeof startAsycJobForUpdatingPostScheduleSend
    | typeof startAsycPostExport;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asynFuncArgs: any;
  setAsyncTaskProgressData: (progress: Dictionary) => void;
  asyncTaskName: string;
  setLoading: (loadingState: boolean) => void;
  webSocketUrl: string;
}): void => {
  let jobId: string | undefined = undefined;
  const socket = io(webSocketUrl, {
    path: '/v1/socket.io',
    withCredentials: true,
  });
  socket.on('connect', async () => {
    if (jobId !== undefined) {
      await checkAsyncTaskProgress(
        jobId,
        socket,
        setAsyncTaskProgressData,
        asyncTaskName,
        setLoading
      );
    } else {
      // start asyc source operation
      asynFunc(asynFuncArgs).then(async (res) => {
        const data = res as Dictionary;
        jobId = data.job_uuid;
        await checkAsyncTaskProgress(
          data.job_uuid,
          socket,
          setAsyncTaskProgressData,
          asyncTaskName,
          setLoading
        );
      });
    }
    // eslint-disable-next-line no-console
    console.log(
      `client connected to websock server ${TEMPORAL_POST_WORKER_WEB_SOCKET_URL}, socket id: ${socket.id}`
    );
  });
  socket.on('disconnect', () => {
    // eslint-disable-next-line no-console
    console.log(`client disconnected from websock server ${TEMPORAL_POST_WORKER_WEB_SOCKET_URL}`);
  });
};
