import React from 'react';
import AccountMenu from './AccountMenu';
import SpaceMenu from './SpaceMenu';
import UserMenu from './UserMenu';
import { withRouter, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionUserLogout } from '../../../actions';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionUserLogout,
};

const DashboardMenu = (props) => {
  const params = useParams();
  return (
    <div className="dashboard-menu">
      <div className="content">
        {props.match.path.includes('/dashboard') && <UserMenu {...props} />}
        {props.match.path.includes('/source/') && <UserMenu {...props} />}
        {props.match.path.includes('/account') && <AccountMenu {...props} />}
        {props.match.path.includes('/space') && <SpaceMenu {...props} />}
      </div>
    </div>
  );
};

const DashboardMenuContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardMenu);

export default withRouter(DashboardMenuContainer);
