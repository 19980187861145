import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Nav from '../Nav/Nav.js';
import { Input, message, Slider, Popover, Select, Checkbox } from 'antd';
import { withRouter, Link, useParams } from 'react-router-dom';
import { ChromePicker } from 'react-color';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import './style.css';
import {
  actionAccountSpaceGet,
  actionSpacePropertyEdit,
  actionSpaceMenuItemAdd,
  actionSpaceMenuItemEdit,
  actionSpaceMenuItemDelete,
  actionSpaceLogoWidthEdit,
  actionPostsGet,
  actionSpaceComponentAdd,
} from '../actions/index.js';
import { getTags } from '../Dashboard/helpers.js';
import SectionItem from './components/SectionItem.js';
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API, POSTS_API } from '../../../configs/env';
import { ERROR_GENERIC } from '../../../configs/constants';

const fontsArray = [
  {
    name: 'Poppins / PT Serif',
    headingFontFamily: 'Poppins',
    headingFontWeight: 600,
    bodyFontFamily: 'PT Serif',
    bodyFontWeight: 400,
  },
  {
    name: 'Barlow Condensed / Open Sans',
    headingFontFamily: 'Barlow Condensed',
    headingFontWeight: 700,
    bodyFontFamily: 'Open Sans',
    bodyFontWeight: 400,
  },
  {
    name: 'Barlow / Open Sans',
    headingFontFamily: 'Barlow',
    headingFontWeight: 700,
    bodyFontFamily: 'Open Sans',
    bodyFontWeight: 400,
  },
  {
    name: 'Roboto',
    headingFontFamily: 'Roboto',
    headingFontWeight: 700,
    bodyFontFamily: 'Roboto',
    bodyFontWeight: 400,
  },
  {
    name: 'Source Serif Pro',
    headingFontFamily: 'Source Serif Pro',
    headingFontWeight: 700,
    bodyFontFamily: 'Source Serif Pro',
    bodyFontWeight: 400,
  },
  {
    name: 'Source Serif Pro / Open Sans',
    headingFontFamily: 'Source Serif Pro',
    headingFontWeight: 700,
    bodyFontFamily: 'Open Sans',
    bodyFontWeight: 400,
  },
  {
    name: 'Ubuntu',
    headingFontFamily: 'Ubuntu',
    headingFontWeight: 700,
    bodyFontFamily: 'Open Sans',
    bodyFontWeight: 400,
  },
];

const mapStateToProps = (state, ownProps) => {
  if (!state) {
    return null;
  } else {
    return state;
  }
};

const mapDispatchToProps = {
  actionSpacePropertyEdit,
  actionAccountSpaceGet,
  actionPostsGet,
  actionSpaceMenuItemAdd,
  actionSpaceMenuItemEdit,
  actionSpaceMenuItemDelete,
  actionSpaceLogoWidthEdit,
  actionSpaceComponentAdd,
};

const DesignSpace = (props) => {
  const params = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [tagsInUse, setTagsInUse] = useState([]);

  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, 'space');

    service
      .read(params.id)
      .then((space) => {
        props.actionAccountSpaceGet(space);

        const service = new AkukoAPIService(POSTS_API, `post/space/${params.id}`);
        return service.list();
      })
      .then((posts) => {
        props.actionPostsGet(posts);

        const tags = getTags(posts);
        setTagsInUse(tags);
        setShowLoader(false);
      })
      .catch((error) => {
        message.error(error.message || ERROR_GENERIC);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleEdit = () => {
    const service = new AkukoAPIService(IDENTITY_API, `space/${props.space.uuid}`);
    service.update(props.space).catch((error) => {
      message.error(error.message || ERROR_GENERIC);
    });
  };

  return (
    <div className="space-designer">
      {props.space.id && (
        <div className="design-drawer">
          <div className="content" id="design-drawer--content">
            <div className="close-link">
              <Link
                onClick={() => {
                  handleEdit();
                }}
                to={`/space/${props.match.params.id}/posts`}
              >
                <CloseOutlined />
              </Link>
            </div>
            <div className="settings-group">
              <h2>Header</h2>
              <div className="field">
                <label>Background color</label>
                <Popover
                  trigger="click"
                  content={
                    <ChromePicker
                      color={props.space.config.color}
                      onChange={(color) => {
                        props.actionSpacePropertyEdit({
                          property: 'color',
                          value: color.hex,
                        });
                      }}
                    />
                  }
                >
                  <div
                    className="color-swatch"
                    style={{ backgroundColor: props.space.config.color }}
                  ></div>
                </Popover>
              </div>

              <div className="field">
                <label>Text color</label>
                <Popover
                  trigger="click"
                  content={
                    <ChromePicker
                      color={props.space.config.header_text_color}
                      onChange={(color) => {
                        props.actionSpacePropertyEdit({
                          property: 'header_text_color',
                          value: color.hex,
                        });
                      }}
                    />
                  }
                >
                  <div
                    className="color-swatch"
                    style={{ backgroundColor: props.space.config.header_text_color }}
                  ></div>
                </Popover>
              </div>
              <div className="field">
                <label>layout on header </label>
                <Checkbox
                  onChange={(e) => {
                    props.actionSpacePropertyEdit({
                      property: 'layout_on_header',
                      value: e.target?.checked,
                    });
                    if (e.target?.checked && !props.space.config?.components?.length) {
                      props.actionSpaceComponentAdd({
                        id: 'ZSNhAtdrRV',
                        rows: 1,
                        tabs: [],
                        collapse: [],
                        type: 'layout',
                        width: 'large',
                        columns: 2,
                        xScroll: false,
                        yScroll: false,
                        layoutId: '24',
                        marginTop: 0,
                        paddingTop: 0,
                        pixelWidth: 280,
                        marginRight: 0,
                        paddingLeft: 0,
                        pixelHeight: 80,
                        widthMetric: 'px',
                        heightMetric: 'px',
                        layoutShadow: false,
                        paddingRight: 0,
                        percentWidth: 30,
                        paddingBottom: 0,
                        percentHeight: 30,
                        verticalValue: 40,
                        horizontalValue: 40,
                        positionVertical: 'top',
                        positionHorizontal: 'left',
                        layoutBackgroundColor: '#ffffff',
                        spaceComponent: true,
                      });
                    }
                  }}
                  checked={props.space.config.layout_on_header || false}
                />
              </div>
            </div>
            <div className="settings-group">
              <h2>Brand</h2>

              <div className="field">
                <label>Link</label>
                <Select
                  value={props.space.config.brand_link}
                  getPopupContainer={() => document.getElementById('design-drawer--content')}
                  style={{ width: '100% ' }}
                  onChange={(value) => {
                    props.actionSpacePropertyEdit({
                      property: 'brand_link',
                      value: value,
                    });
                  }}
                >
                  <Select.Option value="default">All Posts</Select.Option>
                  <Select.Option value="post">Post</Select.Option>
                  <Select.Option value="tag">Tag</Select.Option>
                </Select>
              </div>
              {props.space.config.brand_link === 'post' && (
                <div className="field">
                  <label>Posts</label>
                  <Select
                    value={props.space.config.brand_link_url}
                    getPopupContainer={() => document.getElementById('design-drawer--content')}
                    style={{ width: '100% ' }}
                    onChange={(value) => {
                      props.actionSpacePropertyEdit({
                        property: 'brand_link_url',
                        value: value,
                      });
                    }}
                  >
                    {props.posts.map((post, index) => (
                      <Select.Option key={index} value={`/post/${post.uuid} `}>
                        {post.title}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {props.space.config.brand_link === 'tag' && (
                <div className="field">
                  <label>Tags</label>
                  <Select
                    value={props.space.config.brand_link_url}
                    getPopupContainer={() => document.getElementById('design-drawer--content')}
                    style={{ width: '100% ' }}
                    onChange={(value) => {
                      props.actionSpacePropertyEdit({
                        property: 'brand_link_url',
                        value: value,
                      });
                    }}
                  >
                    {tagsInUse.map((tag, index) => (
                      <Select.Option
                        key={`${tag.value}-index`}
                        value={`/space/${props.space.id}/tag/${tag.value}`}
                      >
                        {tag.text}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {props.space.config.logo_file && (
                <div className="field">
                  <label>Logo size</label>
                  <Slider
                    value={props.space.config.logo_width}
                    defaultValue={200}
                    min={0}
                    max={400}
                    onChange={(value) => {
                      props.actionSpaceLogoWidthEdit({
                        value: value,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            {/*}
            <div className="settings-group">
              <h2>Page</h2>
              <div className="field">
                <label>Background color</label>
                <Popover
                  trigger="click"
                  content={
                    <ChromePicker
                      color={props.space.config.page_background_color}
                      onChange={(color) => {
                        props.actionSpacePropertyEdit({
                          property: 'page_background_color',
                          value: color.hex,
                        });
                      }}
                    />
                  }
                >
                  <div
                    className="color-swatch"
                    style={{ backgroundColor: props.space.config.page_background_color }}
                  ></div>
                </Popover>
              </div>
            </div>
                {*/}

            {/*}
              <div className="field">
                <label>Text color</label>
                <Popover
                  trigger="click"
                  content={
                    <ChromePicker
                      color={props.space.config.page_text_color}
                      onChange={(color) => {
                        props.actionSpacePropertyEdit({
                          property: 'page_text_color',
                          value: color.hex,
                        });
                      }}
                      onChangeComplete={(color) => {
                        handleEdit();
                      }}
                    />
                  }
                >
                  <div
                    className="color-swatch"
                    style={{ backgroundColor: props.space.config.page_text_color }}
                  ></div>
                </Popover>
              </div>
                {*/}

            {/*}
            <div className="settings-group">
              <h2>Front page</h2>
                <Select 
                  value={props.space.config.front_page_layout}
                  defaultValue="list"
                  style={{ width: '100%'}}
                  getPopupContainer={() => document.getElementById('design-drawer--content')}
                  onChange={(value) => {
                    props.actionSpacePropertyEdit({
                      property: 'front_page_layout',
                      value: value,
                    });
                  }}
                >
                  <Select.Option value="list">List</Select.Option>
                  <Select.Option value="grid">Grid</Select.Option>
                </Select>
            </div>
                {*/}
            <div className="settings-group">
              <h2>Sections</h2>
              {props.space &&
                props.space.config.menu &&
                props.space.config.menu.map((item, index) => (
                  <SectionItem key={index} item={item} index={index} tags={tagsInUse} {...props} />
                ))}
              <button
                onClick={() => {
                  props.actionSpaceMenuItemAdd();
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="space-preview">
        <Nav />
        <div className="post-preview">
          <h1
            style={{
              fontFamily: props.space?.config?.font?.headingFontFamily || 'Poppins',
              fontWeight: props.space?.config?.font?.headingFontWeight || 600,
              fontSize: '40px',
            }}
          >
            My great post
          </h1>
          <p
            style={{
              fontFamily: props.space?.config?.font?.bodyFontFamily || 'PT Serif',
              fontWeight: props.space?.config?.font?.bodyFontWeight || 400,
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sagittis lobortis ipsum
            eu mattis. Duis ac magna vel nulla porttitor fringilla. Mauris dignissim felis diam, vel
            egestas sapien pharetra eu. Sed <a href="#">convallis metus</a> erat, nec rutrum mi
            semper id. Sed non nunc ac ipsum hendrerit laoreet. Vivamus tincidunt erat justo, sed
            maximus quam rutrum vel. Etiam metus lorem, vulputate eget ex volutpat, pellentesque
            hendrerit augue.
          </p>
          <p
            style={{
              fontFamily: props.space?.config?.font?.bodyFontFamily || 'PT Serif',
              fontWeight: props.space?.config?.font?.bodyFontWeight || 400,
            }}
          >
            <h2
              style={{
                fontFamily: props.space?.config?.font?.headingFontFamily || 'Poppins',
                fontWeight: props.space?.config?.font?.headingFontWeight || 600,
              }}
            >
              Duis ac magna vel nulla porttitor
            </h2>
            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Egestas sapien pharetra eu</li>
              <li>Nunc semper metus sit amet</li>
            </ul>
          </p>
          <p
            style={{
              fontFamily: props.space?.config?.font?.bodyFontFamily || 'PT Serif',
              fontWeight: props.space?.config?.font?.bodyFontWeight || 400,
            }}
          >
            <h3
              style={{
                fontFamily: props.space?.config?.font?.headingFontFamily || 'Poppins',
                fontWeight: props.space?.config?.font?.headingFontWeight || 600,
              }}
            >
              Curabitur facilisis ligula
            </h3>
            Nunc semper metus sit amet massa ultrices vehicula. Sed vel augue vel urna tristique
            dictum. Curabitur facilisis ligula eget dui elementum, et posuere urna dignissim. Donec
            vehicula mi at ipsum porta imperdiet.
          </p>
        </div>
      </div>
    </div>
  );
};

const DesignSpaceContainer = connect(mapStateToProps, mapDispatchToProps)(DesignSpace);

export default withRouter(DesignSpaceContainer);
