import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Button, Col, Collapse, Input, message, Space } from 'antd';
import { Dictionary } from '@onaio/utils';
import { actionSourceSave } from '../actions';
import {
  Source,
  sourceAsyncOperationsJobStatusTypes,
  FileSourceCreationJobStatusTypes,
} from '../../../configs/component-types';
import { createGeoParquetSourceFromFile } from '../helpers/helpers';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../configs/env';
import { FileSourceCreationProgressSteps } from '../components/FileSourceCreationProgressSteps';
import { TippecanoeOptionsTag } from '../components/TippecanoeOptionsTag';

const { Panel } = Collapse;

export interface GeoParquetSourceFromFileInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const GeoParquetSourceFromFileInput: React.FC<GeoParquetSourceFromFileInputProps> = (
  props: GeoParquetSourceFromFileInputProps
) => {
  const { sourceActionSaveCreator } = props;
  const source = useSelector((store: Dictionary) => store.source);
  const store = useStore();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});
  const [formDataVal, setFormDataVal] = useState<FormData>();
  const [fileSize, setFileSize] = useState<number>();
  const [tippecanoeOptionsList, setTippecanoeOptionsList] = useState<string[]>(['-zg']);

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }
      setLoading(false);
      message.success('Source saved');
      setFormDataVal(undefined);
      setFileSize(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const isVisible = () => {
    if (source.dimensions && source.dimensions.length > 0) {
      return false;
    } else {
      if (source.inputType === 'file') {
        return true;
      }
    }
    return false;
  };

  const handleSourceCreation = (formData: FormData, fileSize: number) => {
    setLoading(true);
    setProgress({
      stage: FileSourceCreationJobStatusTypes.UPLOADING,
      data: [
        {
          pending: [],
          done: [],
          percentage: 0,
        },
      ],
    });
    const onUploadProgress = (e: Dictionary) => {
      const calc = (e.loaded / Number(fileSize)) * 100;
      if (calc > 0 || calc <= 100) {
        setProgress({
          stage: FileSourceCreationJobStatusTypes.UPLOADING,
          data: [
            {
              pending: [],
              done: [],
              percentage: calc,
            },
          ],
        });
      }
      if (calc > 100) {
        setProgress({
          stage: FileSourceCreationJobStatusTypes.STARTING,
          data: [
            {
              pending: [],
              done: [],
            },
          ],
        });
      }
    };
    const currentState = store.getState();
    const payload = {
      /* @ts-ignore */
      sourceId: currentState?.source?.uuid,
      /* @ts-ignore */
      cubeName: currentState?.source?.cube,
      tippecanoe_options: tippecanoeOptionsList,
      formData: formData,
      onUploadProgress: onUploadProgress,
    } as Dictionary;
    triggerAsycSourceOperation({
      asynFunc: createGeoParquetSourceFromFile,
      asynFuncArgs: payload,
      asyncTaskName: 'Source Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  if (isVisible() || loading === true) {
    return (
      <>
        <div className="input-field">
          <label>Upload</label>
          <Input
            type="file"
            disabled={loading}
            accept=".parquet"
            onChange={(e: Dictionary) => {
              if (e?.target?.files.length === 0) {
                setFormDataVal(undefined);
                setFileSize(undefined);
              }
              if (e?.target?.files.length !== 0) {
                const fileSize = e.target.files[0].size;
                const formData = new FormData();
                formData.append('file', e.target.files[0]);
                formData.append('fileType', source.type);
                setFormDataVal(formData);
                setFileSize(fileSize);
              }
            }}
          />
        </div>
        <Col xs={24} sm={24}>
          <Collapse>
            <Panel key={1} header="Advanced settings">
              <Space style={{ width: '100%' }} direction="vertical">
                <Col xs={24}>
                  <label>Tile Generation Options</label>
                  <TippecanoeOptionsTag
                    disable={loading}
                    tippecanoeOptionsList={tippecanoeOptionsList}
                    setTippecanoeOptionsList={setTippecanoeOptionsList}
                  />
                </Col>
              </Space>
            </Panel>
          </Collapse>
        </Col>
        <div className="input-field">
          <Button
            disabled={formDataVal === undefined || loading || tippecanoeOptionsList.length === 0}
            type="primary"
            onClick={() => {
              if (formDataVal !== undefined && fileSize !== undefined) {
                handleSourceCreation(formDataVal, fileSize);
              }
            }}
          >
            Upload
          </Button>
        </div>
        {loading === true && (
          <div className="input-field">
            <label>Progress</label>
            <FileSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

GeoParquetSourceFromFileInput.defaultProps = defaultProps;

export { GeoParquetSourceFromFileInput };
