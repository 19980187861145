import { Dictionary } from '@onaio/utils';
import { LayerProps } from '../../../../configs/types';

export const actionComponentMapLayerBuildGeojson = (
  res: Dictionary,
  layer: LayerProps,
  queryObj: Dictionary,
  source: Dictionary
): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_LAYER_BUILD_GEOJSON',
    data: {
      res,
      layer,
      queryObj,
      source,
    },
  };
};

export const actionComponentMapLayerGeojsonEdit = (data: Dictionary[]): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_LAYER_GEOJSON_EDIT',
    data,
  };
};

export const actionComponentMapLayerFetchGeojson = (data: Dictionary[]): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_LAYER_FETCH_GEOJSON',
    data,
  };
};

export const actionMapEventAdd = (data: Dictionary[]): Dictionary => {
  return {
    type: 'POST_MAP_EVENT_ADD',
    data,
  };
};

export const actionComponentMapLayerFilterEdit = (data: Dictionary[]): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_LAYER_FILTER_EDIT',
    data,
  };
};

export const actionComponentMapLayerVisibleEdit = (data: Dictionary): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_LAYER_VISIBLE_EDIT',
    data,
  };
};

export const actionComponentMapTilesetIdEdit = (data: Dictionary): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_TILESET_ID_EDIT',
    data,
  };
};

export const actionComponentMapStoreAdd = (data: Dictionary): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_STORE_ADD',
    data,
  };
};

export const actionComponentMapStyleGet = (data: Dictionary): Dictionary => {
  return {
    type: 'POST_COMPONENT_MAP_STYLE_GET',
    data,
  };
};
