import { Dictionary } from "@onaio/utils/dist/types/types";

export const hasPublicPosts = (posts: Dictionary[]) => {
  let check = false;
  posts.forEach((post: Dictionary) => {
    if (post.public) {
      check = true;
    }
  })
  return check;
}