import { Dictionary } from '@onaio/utils';
import { AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME, QUERY_API } from '../../../../../../configs/env';
import { FilterOption } from '../../../../../../configs/component-types';
import { AkukoAPIService } from '../../../../../../services/serviceClass';
import { getFilters } from '../../../../helpers';
import { generateJWTToken } from '../../../../utils';

export interface QueryConfigProps {
  componentIndex: number;
  id: string;
  sourceId: string;
  cube: string;
  refreshKey?: string | number;
  targetProperty: string | number;
  granularity: string | undefined;
  type: string;
}

export interface StateSetterProps {
  setLoading: (loadingState: boolean) => void;
  setFilterValues: (data: Dictionary[]) => void;
}

export const dimensionInputQueryBuilder = (
  filters: FilterOption[] | [],
  queryConfigs: QueryConfigProps,
  stateSetters: StateSetterProps
): void => {
  const { sourceId, cube, targetProperty, granularity, type, refreshKey } = queryConfigs;
  const { setLoading, setFilterValues } = stateSetters;
  // pick all existing pre existing filter & apply it to the current query
  const excludeFinalFilters = [...filters];
  excludeFinalFilters.pop();
  const selectFilters = getFilters(excludeFinalFilters, cube);
  const dimensions = [`${cube}.${targetProperty}`];
  const query =
    granularity && type === 'filter'
      ? {
          timeDimensions: [
            {
              dimension: `${cube}.${targetProperty}`,
              granularity: granularity,
            },
          ],

          measures: [],
          limit: 20000,
          renewQuery: true,
          filters: selectFilters,
        }
      : {
          dimensions: dimensions,
          measures: [],
          limit: 20000,
          renewQuery: true,
          filters: selectFilters,
        };
  generateJWTToken({
    sourceId: sourceId,
    cubeName: cube,
    refreshKey: refreshKey,
  }).then(res => {
    const token = res;
    const headers = {
      [AKUKO_QUERY_API_JWT_TOKEN_HEADER_NAME]: token,
      'Content-Type': 'application/json'
    };
    const service = new AkukoAPIService(QUERY_API, '/cubejs-api/v1/load', undefined, headers);
    service.create({
      query: query,
    })
    .then((data) => {
      setLoading(false);
      const response = data as Dictionary;
      const queryResult = response.data as Dictionary[];
      setFilterValues(queryResult);
    });
  });
};
