import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LogoutOutlined, DatabaseOutlined, ProfileOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from "react-oidc-context";
import { AkukoAPIService } from '../../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../../configs/env';
import { actionUserLogout } from '../../../../actions';

const UserMenu = (props) => {
  const { signoutRedirect } = useAuth();
  const dispatch = useDispatch();
  const handleLogout = () => {
    void signoutRedirect();
    dispatch(actionUserLogout());
  };
  return (
    <div className="account-settings-menu">
      <div className="menu-name">
        <h2>{props.user.handle}</h2>
      </div>
      <Link to={`/dashboard/posts`}>
        <FileOutlined /> Posts
      </Link>
      <Link to={`/dashboard/sources`}>
        <DatabaseOutlined /> Sources
      </Link>
      <Link to={`/dashboard/settings`}>
        <UserOutlined /> My account
      </Link>
      {/* <Button
        type='link'
        icon={<LogoutOutlined />}
        onClick={handleLogout}
        // onClick={(e) => {
        //   e.preventDefault();
        //   const service = new AkukoAPIService(IDENTITY_API, 'user/auth');

        //   service.delete().then(() => {
        //     props.actionUserLogout();
        //   });
        // }}
      >
        Sign out
      </Button> */}
      <a
        href="#logout"
        onClick={handleLogout}
      >
        <LogoutOutlined /> Sign out
      </a>
      {/* <a
        href="#logout"
        onClick={(e) => {
          e.preventDefault();
          const service = new AkukoAPIService(IDENTITY_API, 'user/auth');

          service.delete().then(() => {
            props.actionUserLogout();
          });
        }}
      >
        <LogoutOutlined /> Sign out
      </a> */}
    </div>
  );
};

export default UserMenu;
