import React from 'react';
import { Steps } from 'antd';
import { Dictionary } from '@onaio/utils';
import { LoadingOutlined } from '@ant-design/icons';

import {
  GeometryUpdateJobStatusTypes,
  GeometryUpdateJobStatusTypesDescriptions,
} from '../../../../configs/component-types';

export interface GeometryUpdateProgressStepsProps {
  progress: Dictionary;
}

const GeometryUpdateProgressSteps: React.FC<GeometryUpdateProgressStepsProps> = (
  props: GeometryUpdateProgressStepsProps
) => {
  const { progress } = props;
  const getCurrentStage = (stage?: string) => {
    let currentStage = 0;
    if (stage === undefined) {
      return currentStage;
    }

    switch (stage) {
      case GeometryUpdateJobStatusTypes.DONE:
        currentStage = 2;
        break;
      default:
        currentStage = 1;
        break;
    }
    return currentStage;
  };
  const currentStage = getCurrentStage(progress?.stage);

  return (
    <Steps
      size="small"
      current={currentStage}
      percent={currentStage === 0 ? undefined : progress?.data[0]?.percentage}
      items={[
        {
          title: GeometryUpdateJobStatusTypesDescriptions.STARTING,
          icon: currentStage === 0 ? <LoadingOutlined /> : undefined,
        },
        {
          title: GeometryUpdateJobStatusTypesDescriptions.PROCESSING,
        },
        {
          title: GeometryUpdateJobStatusTypesDescriptions.DONE,
        },
      ]}
    />
  );
};

export { GeometryUpdateProgressSteps };
