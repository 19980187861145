const fonts = [
    {
      label: 'Sans Serif',
      options: [
        { value: 'DM Sans', label: 'DM Sans', weights: ['400', '500', '700'] },
        { value: 'Inter', label: 'Inter', weights: ['400', '500', '600', '700', '800'] },
        { value: 'Lato', label: 'Lato', weights: ['400', '700', '900'] },
        { value: 'Montserrat', label: 'Montserrat', weights: ['400', '500', '600', '700', '800'] },
        { value: 'Mulish', label: 'Mulish', weights: ['400', '700'] },
        { value: 'Noto Sans', label: 'Noto Sans', weights: ['400', '700'] },
        { value: 'Nunito', label: 'Nunito', weights: ['400', '600', '700', '800'] },
        { value: 'Open Sans', label: 'Open Sans', weights: ['400', '600', '700'] },
        { value: 'Oswald', label: 'Oswald', weights: ['400', '500', '700'] },
        { value: 'Poppins', label: 'Poppins', weights: ['400', '500', '600', '700'] },
        { value: 'PT Sans', label: 'PT Sans', weights: ['400', '700'] },
        { value: 'Raleway', label: 'Raleway', weights: ['400', '600', '700', '800'] },
        { value: 'Roboto', label: 'Roboto', weights: ['400', '500', '700', '900'] },
        { value: 'Radio Canada', label: 'Radio Canada', weights: ['400', '700'] },
        { value: 'Ubuntu', label: 'Ubuntu', weights: ['400', '500', '700'] },
      ]
    },
    {
      label: 'Serif',
      options: [
        { value: 'Adamina', label: 'Adamina', weights: ['400'] },
        { value: 'Libre Baskerville', label: 'Libre Baskerville', weights: ['400', '700'] },
        { value: 'PT Serif', label: 'PT Serif', weights: ['400', '700'] },
        { value: 'Playfair Display', label: 'Playfair Display', weights: ['400', '700', '900'] },
        { value: 'Roboto Slab', label: 'Roboto Slab', weights: ['400', '700'] },
      ]
    },
    {
      label: 'Display',
      options: [
        { value: 'Chewy', label: 'Chewy', weights: ['400'] },
        { value: 'Koulen', label: 'Koulen', weights: ['400'] },
        { value: 'Pacifico', label: 'Pacifico', weights: ['400'] },
      ]
    },
    {
      label: 'Handwriting',
      options: [
        { value: 'Architects Daughter', label: 'Architects Daughter', weights: ['400'] },
        { value: 'Beth Ellen', label: 'Beth Ellen', weights: ['400'] },
        { value: 'Caveat', label: 'Caveat', weights: ['400', '700'] },
        { value: 'Kalam', label: 'Kalam', weights: ['400', '700'] },
        { value: 'Mansalva', label: 'Mansalva', weights: ['400'] },
        { value: 'Nanum Pen Script', label: 'Nanum Pen Script', weights: ['400'] },
        { value: 'Permanent Marker', label: 'Permanent Marker', weights: ['400'] },
        { value: 'Special Elite', label: 'Special Elite', weights: ['400'] },
      ]
    }
]
  
export default fonts;