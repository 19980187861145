import { GroupFieldProps } from '../types';

export const column: GroupFieldProps = {
  groups: ['Column', 'Style'],
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Name',
      property: 'propertyAlias',
      group: 'Column',
      placeHolder: 'Name',
      parents: ['properties'],
    },
    {
      type: 'dimension',
      label: 'Property',
      property: 'value',
      group: 'Column',
      showMeasures: true,
      showDimensions: true,
      parents: ['properties'],
      setType: true,
      setDataType: true,
      dataTypeKey: 'data-type',
      typeKey: 'type',
      placeHolder: 'Set Field To Populate Column',
    },
    {
      type: 'switch',
      label: 'Enable component insert',
      property: 'contextFromRow',
      size: 'small',
      dependsOn: [{ property: 'value', value: '*' }],
      group: 'Style',
      parents: ['properties'],
    },
    {
      type: 'select',
      label: 'Granularity',
      property: 'granularity',
      dependsOn: [
        { property: 'value', value: 'time', dataTypeKey: 'data-type' },
      ],
      options: [
        // day, month, year, week, hour, minute, second, null
        { value: 'second', label: 'Second' },
        { value: 'minute', label: 'Minute' },
        { value: 'hour', label: 'Hour' },
        { value: 'day', label: 'Day' },
        { value: 'week', label: 'Week' },
        { value: 'month', label: 'Month' },
        { value: 'quarter', label: 'Quarter' },
        { value: 'year', label: 'Year' },
      ],
      group: 'Style',
      placeHolder: 'Select time granularity option',
      parents: ['properties'],
    },
    {
      type: 'select',
      label: 'Date Format',
      property: 'dateFormat',
      dependsOn: [
        { property: 'value', value: 'time', dataTypeKey: 'data-type' },
        { property: 'dateFormatText', value: '!' },
      ],
      options: [
        // day, month, year, week, hour, minute, second, null
        { value: 'yyyy', label: 'YYYY' },
        { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
        { value: 'MM-dd-yyy', label: 'MM-DD-YYYY' },
        { value: 'dd-yyyy-MM', label: 'DD-YYYY-MM' },
        { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
        { value: 'MM', label: 'MM' },
      ],
      group: 'Style',
      labelLink:
        'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
      placeHolder: 'Select time granularity option',
      parents: ['properties'],
    },
    {
      type: 'text',
      label: 'Date Format',
      property: 'dateFormat',
      dependsOn: [
        { property: 'value', value: 'time', dataTypeKey: 'data-type' },
        { property: 'dateFormatText', value: true },
      ],
      group: 'Style',
      labelLink:
        'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
      placeHolder: 'Select time granularity option',
      parents: ['properties'],
    },
    {
      type: '',
      label: 'Use custom date format',
      property: 'dateFormatText',
      dependsOn: [
        { property: 'value', value: 'time', dataTypeKey: 'data-type' },
      ],
      group: 'Style',
      placeHolder: 'Toggle Format field to text',
      parents: ['properties'],
    },
    {
      type: 'slider',
      label: 'Width',
      property: 'columnWidth',
      min: 0,
      max: 1000,
      group: 'Style',
      parents: ['properties'],
      placeHolder: '0',
    },
    {
      type: 'select',
      label: 'Format',
      property: 'format',
      group: 'Style',
      parents: ['properties'],
      options: [
        { value: 'value', label: 'Value' },
        { value: 'icon', label: 'Icon' },
      ],
      placeHolder: 'Choose Table Format',
    },
    {
      type: 'switch',
      size: 'small',
      label: 'Fixed Column',
      property: 'fixedColumn',
      group: 'Style',
      parents: ['properties'],
    },
    {
      type: 'select',
      label: 'Position',
      property: 'columnPosition',
      group: 'Style',
      parents: ['properties'],
      options: [
        { value: 'left', label: 'Left' },
        { value: 'right', label: 'Right' },
      ],
      placeHolder: 'Choose Column Position',
      dependsOn: [
        {
          property: 'fixedColumn',
          value: true,
        },
      ],
    },
    {
      type: 'icon',
      label: 'Icon',
      property: 'icon',
      group: 'Style',
      parents: ['properties'],
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
    },
    {
      type: 'slider',
      min: 20,
      max: 60,
      label: 'Icon size',
      property: 'iconSize',
      parents: ['properties'],
      group: 'Style',
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'color',
      label: 'Color',
      property: 'iconColor',
      parents: ['properties'],
      disableOpacity: true,
      group: 'Style',
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
    },
    {
      type: 'dimension',
      label: 'Icon based on',
      property: 'iconField',
      group: 'Style',
      parents: ['properties'],
      showMeasures: true,
      showDimensions: true,
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
      placeHolder: 'Field That Drives Symbols',
    },
    {
      type: 'array',
      label: 'Icon categories',
      property: 'iconCategories',
      group: 'Style',
      groups: ['Categories'],
      settings: [
        {
          label: 'Categories',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      parents: ['properties'],
      itemLabel: 'Category',
      collapseProperty: 'property',
      collapseParent: 'iconCategories',
      defaultValue: {
        property: '',
        value: '',
      },
      fields: [
        {
          type: 'text',
          label: 'Property',
          group: 'Categories',
          property: 'property',
          parents: ['properties', 'iconCategories'],
          placeHolder: 'Value for the Symbol below',
        },
        {
          type: 'icon',
          label: 'Icon',
          group: 'Categories',
          property: 'value',
          parents: ['properties', 'iconCategories'],
        },
      ],
      dependsOn: [
        {
          property: 'iconField',
          value: '*',
        },
        {
          property: 'format',
          value: 'icon',
        },
      ],
    },
    {
      type: 'dimension',
      label: 'Color based on',
      property: 'colorField',
      group: 'Style',
      parents: ['properties'],
      showMeasures: true,
      showDimensions: true,
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
        {
          property: 'format',
          value: 'icon',
        },
      ],
      placeHolder: 'Field That Drives Icon Colors',
    },
    {
      type: 'array',
      label: 'Icon colors',
      property: 'colors',
      groups: ['Color'],
      settings: [
        {
          label: 'Color',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      group: 'Style',
      itemLabel: 'Color',
      parents: ['properties'],
      collapseProperty: 'property',
      collapseParent: 'colors',
      defaultValue: {
        property: '',
        value: '',
      },
      fields: [
        {
          type: 'text',
          label: 'Property',
          group: 'Color',
          property: 'property',
          parents: ['properties', 'colors'],
          placeHolder: 'Value For The Color Below',
        },
        {
          type: 'color',
          disableOpacity: true,
          label: 'color',
          group: 'Color',
          property: 'value',
          parents: ['properties', 'colors'],
        },
      ],
      dependsOn: [
        {
          property: 'colorField',
          value: '*',
        },
        {
          property: 'format',
          value: 'icon',
        },
      ],
    },
    {
      type: 'selectSteps',
      label: 'Color mode',
      property: 'colorMode',
      group: 'Style',
      parents: ['properties'],
      options: [
        { value: 'categories', label: 'Categories' },
        { value: 'generatedStepsBrakes', label: 'Steps' },
      ],
      placeHolder: 'Coloring Method',
    },
    {
      type: 'select',
      label: 'Color Scale',
      popOver: true,
      property: 'colorScale',
      group: 'Style',
      options: [
        { value: 'ckmeans', label: 'Jenks Natural Breaks' },
        { value: 'linear', label: 'Equal Interval' },
        { value: 'quantile', label: 'Quantiles' },
      ],
      parents: ['properties'],
      placeHolder: 'Jenks Natural Breaks',
      dependsOn: [
        {
          property: 'colorMode',
          value: ['!', 'steps', 'categories'],
        },
        {
          property: 'colorMode',
          value: '*',
        },
      ],
    },
    {
      type: 'select',
      label: 'Classes',
      popOver: true,
      property: 'categoricalClasses',
      group: 'Style',
      options: [
        {
          value: 10,
          label: '10 Categories',
        },
        {
          value: 20,
          label: '20 Categories',
        },
        {
          value: 30,
          label: '30 Categories',
        },
        {
          value: 40,
          label: '40 Categories',
        },
        {
          value: 50,
          label: '50 Categories',
        },
        {
          value: 250,
          label: '250 Categories',
        },
      ],
      parents: ['properties'],
      placeHolder: 'Class',
      dependsOn: [
        {
          property: 'colorMode',
          value: ['!', 'steps', 'generatedStepsBrakes'],
        },
        {
          property: 'colorMode',
          value: '*',
        },
      ],
    },
    {
      type: 'color-range',
      label: 'color',
      property: 'colorRange',
      hideClassInput: true,
      group: 'Style',
      parents: ['properties'],
      dependsOn: [
        {
          property: 'colorMode',
          value: ['!', 'steps'],
        },
        {
          property: 'colorMode',
          value: '*',
        },
      ],
    },
    {
      type: 'switch',
      label: 'Reverse Colors',
      size: 'small',
      property: 'reverseColors',
      parents: ['properties'],

      group: 'Style',
      dependsOn: [
        {
          property: 'colorMode',
          value: '*',
        },
        {
          property: 'colorRange',
          value: '*',
        },
      ],
    },
  ],
};
