import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import placeholder from './placeholder.jpg';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

const Symbol = (props) => {
  const { icon, onChangeComplete } = props;

  return (
    <Col className="symbol-picker--wrapper" span={4} onClick={() => onChangeComplete(icon)}>
      {/* We are using lazysizes to lazy load images. The class "lazyload" is added to className */}
      <img
        src={placeholder}
        className={`symbol-picker--icon lazyload`}
        data-src={icon.src}
        alt={`${icon.title}`}
        title={icon.title}
      />
    </Col>
  );
};

Symbol.propTypes = {
  onChangeComplete: PropTypes.func.isRequired, // function to handle icon click
  icon: PropTypes.object.isRequired, // icon to display
};

export { Symbol };
