import React from 'react';
import { Tooltip, Image } from 'antd';
import {
  getColorFromScalarBreaks,
  getColorBrewerPallet,
  getUniqueValues,
  getScalarBreaks,
  getSymbolColor,
  getContrast,
} from '../../../App/ColorSettings/helpers';
import { formatValue } from '../../helpers';
import { hexToCSSFilter } from 'hex-to-css-filter';
import moment from 'moment';
import { genericDateFormatter } from '../../utils';
import { LayoutContainer } from '../../Layout';
import { getCellColor } from './getCellColor';


export const getDefaultSort = (component, column) => {
  if (component.sort && component.sort.field) {
    const split = component.sort.field.split('.');
    const id = split[1];
    if (id === column.value) {
      return component.sort.order;
    }
  }
  return null;
};

export const genericTableSorter = (property, a, b) => {
  if (a !== undefined && b !== undefined) {
    if (property?.['data-type'] === 'time') {
      return moment.utc(a).valueOf() - moment.utc(b).valueOf();
    } else {
      if (a && b && isNaN(a) && isNaN(b)) {
        return a.localeCompare(b);
      } else {
        return a - b;
      }
    }
  }
  return 1;
};

export const pivotTableSorter = (a, b) => {
  const enumA = moment(a, true).format('x');
  const enumB = moment(b, true).format('x');

  if (enumA !== 'Invalid date' && enumB !== 'Invalid date') {
    return moment.utc(a).valueOf() - moment.utc(b).valueOf();
  } else if (enumA !== 'Invalid date' || enumB !== 'Invalid date') {
    return 1;
  } else {
    if (isNaN(a) && isNaN(a)) {
      return a.localeCompare(b);
    } else {
      return a - b;
    }
  }
};

export const getColor = (item, result, component) => {
  if (item.colors) {
    let color = item?.iconColor || '#000000';

    item.colors.forEach((category) => {
      if (category.property === result[`${component.cube}.${item.colorField}`]) {
        color = category.value;
      }
    });
    const hex = color ? hexToCSSFilter(color).filter.replace(';', '') : '';
    return hex;
  }
  if (item?.iconColor) {
    const hex = hexToCSSFilter(item.iconColor).filter.replace(';', '');
    return hex;
  }
  return '';
};

export const getPivotColor = (item, value, component) => {
  if (item.colors) {
    let color = item?.iconColor || '#000000';
    item.colors.forEach((category) => {
      if (category.property === value) {
        color = category.value;
      }
    });
    if (color) {
      const hex = hexToCSSFilter(color).filter.replace(';', '');
      return hex;
    }
  }
  if (item?.iconColor) {
    const hex = hexToCSSFilter(item.iconColor).filter.replace(';', '');
    return hex;
  }
  return '';
};

export const getPivotValue = (data, table, pivotRow, pivotColumn) => {
  let value;
  data.forEach((item) => {
    if (
      item[`${table?.cube}.${table?.pivotRows}`] === pivotRow &&
      item[`${table?.cube}.${table?.pivotColumns}`] === pivotColumn
    ) {
      value = item[`${table?.cube}.${table?.pivotValues}`];
    }
  });
  return value;
};

export const getPivotData = (table, data) => {
  if (table && table.pivotRows && table.pivotColumns && table.pivotValues && data) {
    const pivotTableData = [];
    const pivotTableColumns = [];
    const pivotTableRows = [];
    // make unique arrays

    const formattedData = data.map((record) => {
      const dateFormatProps = table.properties?.filter((property) => property.dateFormat);
      const itemCopy = { ...record };
      dateFormatProps.forEach((prop) => {
        let dateFieldValue = itemCopy?.[`${table.cube}.${prop.value}`];
        if (dateFieldValue) {
          itemCopy[`${table.cube}.${prop.value}`] = genericDateFormatter(
            prop.dateFormat,
            dateFieldValue
          );
        }
      });
      return itemCopy;
    });
    formattedData.forEach((item) => {
      const column = item[`${table.cube}.${table.pivotColumns}`];
      const row = item[`${table.cube}.${table.pivotRows}`];
      if (!pivotTableColumns.includes(column)) {
        pivotTableColumns.push(column);
      }
      if (!pivotTableRows.includes(row)) {
        pivotTableRows.push(row);
      }
    });
    // add rows
    pivotTableRows.forEach((pivotRow) => {
      const row = {
        [table.pivotRows]: pivotRow,
      };
      // add columns
      pivotTableColumns.forEach((pivotColumn) => {
        // add value
        row[pivotColumn] = getPivotValue(formattedData, table, pivotRow, pivotColumn);
      });
      pivotTableData.push(row);
    });
    return pivotTableData;
  }
  return [];
};

export const getPivotColumns = (pivotTableData, component, source) => {
  let columns = [];
  if (component.pivotRows && component.pivotColumns && component.pivotValues) {
    const property = component.properties.find((item) => item.value === component.pivotValues);
    if (pivotTableData?.length > 0) {
      const columnHeaders = pivotTableData[0];
      const propertyRows = component.properties.find((item) => item.value === component.pivotRows);
      Object.keys(columnHeaders).forEach((key, index) => {
        const item = component.properties.find((itemProps) => itemProps.value === key);
        if (key !== '') {
          columns.push({
            title: (source?.labels && source.labels[key]?.title) || key,
            dataIndex: key,
            key: key,
            ellipsis: component.ellipsis,
            fixed: item?.fixedColumn === true ? item.columnPosition || 'left' : undefined,
            sorter: (a, b) => {
              return pivotTableSorter(a[key], b[key]);
            },
            render: (value, result) => {
              const color = getCellColor(property, value);
              let textAlign = 'center';
              let opacity = 1;
              if (color) {
                textAlign = 'center';
                opacity = 1;
              }
              if (isNaN(value)) {
                textAlign = 'left';
              }
              if (
                (key !== component.pivotRows &&
                  property?.format === 'icon' &&
                  !property?.iconCategories) ||
                property?.iconCategories?.length === 0
              ) {
                const imageProps = {
                  preview: false,
                  width: property.iconSize || 30,
                  src: property.icon?.src,
                  alt: property.icon?.title,
                  style: {
                    filter: getColor(property, result, component),
                  },
                };
                return (
                  <div style={{ textAlign: 'center' }}>
                    <Image {...imageProps} />
                  </div>
                );
              }
              if (
                key !== component.pivotRows &&
                property?.format === 'icon' &&
                property?.iconCategories?.length > 0
              ) {
                const imageProps = {
                  preview: false,
                  width: property.iconSize || 30,
                  src: property.icon?.src,
                  alt: property.icon?.title,
                  style: {
                    filter: getPivotColor(property, value, component),
                  },
                };
                property.iconCategories.forEach((category) => {
                  if (category.property === value) {
                    imageProps.src = category.value.src;
                    imageProps.alt = category.value.alt;
                  }
                });
                return (
                  <div style={{ textAlign: 'center' }}>
                    <Image {...imageProps} />
                  </div>
                );
              }
              return (
                <div
                  style={{
                    color: color ? getContrast(color) : '',
                    height: '100%',
                    textAlign: textAlign,
                    backgroundColor: color,
                    width: key === component.pivotRows ? propertyRows?.columnWidth : 'auto',
                  }}
                >
                  {!isNaN(value) ? formatValue(property, value, source) : value}
                </div>
              );
            },
          });
        }
      });
    }
    // default column sort to asc order if not set
    if (!component.sortField) {
      columns.sort((a, b) => {
        return pivotTableSorter(a.dataIndex, b.datataIndex);
      });
    }
    // ensure pivotRows column is always first
    const firstColumn = columns.find((item) => item.dataIndex === component.pivotRows);
    const firstColumnIndex = columns.findIndex((item) => item.dataIndex === component.pivotRows);
    columns.unshift(firstColumn);
    columns.splice(firstColumnIndex + 1, 1);
  }
  return columns;
};

export const getTableColumns = (component, source, componentIndex) => {
  let columns = [];
  if (component.properties) {
    component.properties.forEach((item, columnIndex) => {
      if (item.visible || item.visible === undefined) {
        columns.push({
          title: item.propertyAlias
            ? item.propertyAlias
            : (source?.labels && source.labels[item.value]?.title) || item.value,
          dataIndex: `${component.cube}.${item.value}`,
          defaultSortOrder: getDefaultSort(component, item),
          key: `${component.cube}.${item.value}`,
          width: `${item?.columnWidth}px`,
          ellipsis: component.ellipsis,
          fixed: item.fixedColumn === true ? item.columnPosition || 'left' : undefined,
          sorter: (a, b) => {
            return genericTableSorter(
              item,
              a[`${component.cube}.${item.value}`],
              b[`${component.cube}.${item.value}`]
            );
          },
          render: (value, result, index) => {
            if (item.contextFromRow) {
              const layoutContainerProps = {
                componentIndex,
                parentIndex: componentIndex,
                dataRow: result,
                cardIndex: index,
                componentId: `${component.id}`,
                isEmbed: false,
              };

              return (
                <div style={{ width: `${item?.columnWidth}px` }}>
                  <LayoutContainer
                    regionIndex={`${item.id}`} // regionIndex="1-a"
                    columnInTable={true}
                    key={0}
                    {...layoutContainerProps}
                  />
                </div>
              );
            }
            if (
              (item.format === 'icon' && !item?.iconCategories) ||
              item?.iconCategories?.length === 0
            ) {
              const imageProps = {
                preview: false,
                width: item.iconSize || 30,
                src: item.icon?.src,
                alt: item.icon?.title,
                style: {
                  filter: getColor(item, result, component),
                },
              };
              return (
                <div style={{ textAlign: 'center' }}>
                  <Image {...imageProps} />
                </div>
              );
            }
            if (
              (item.format === 'icon' && item?.iconCategories) ||
              item?.iconCategories?.length > 0
            ) {
              const imageProps = {
                preview: false,
                width: item.iconSize || 30,
                src: item.icon?.src,
                alt: item.icon?.title,
                style: {
                  filter: getColor(item, result, component),
                },
              };
              item.iconCategories.forEach((category) => {
                if (category.property === result[`${component.cube}.${item.iconField}`]) {
                  imageProps.src = category.value.src;
                  imageProps.alt = category.value.alt;
                }
              });
              return (
                <div style={{ textAlign: 'center' }}>
                  <Image {...imageProps} />
                </div>
              );
            }
            if (value === undefined) {
              return <div style={{ textAlign: 'center' }}>-</div>;
            }
            const color = getCellColor(item, value);
            let textAlign = 'center';
            let opacity = 1;
            if (color) {
              textAlign = 'center';
              opacity = 1;
            }
            if (isNaN(value)) {
              textAlign = 'left';
            }
            if (color) {
              return (
                <div
                  style={{
                    color: color ? getContrast(color) : '',
                    opacity: opacity,
                    textAlign: 'center',
                    height: '100%',
                    position: 'absolute',
                    backgroundColor: color,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <div style={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)'
                   }}>
                  {formatValue(item, value, source)}
                  </div>
                </div>
              );
            } else {
              return (
               
                  formatValue(item, value, source)
               
              );
            }
          },
        });
      }
    });
  }
  return columns;
};

export const getName = (item, props) => {
  if (
    props.postSources[props.component.source] &&
    props.postSources[props.component.source].labels &&
    props.postSources[props.component.source].labels[item.value] &&
    props.postSources[props.component.source].labels[item.value].title
  ) {
    return props.postSources[props.component.source].labels[item.value].title;
  } else {
    return item.value;
  }
};

/**
 * @param {}
 * @returns {}
 */
export const hasSummaryRow = (columns) => {
  let hasSummaryRow = false;
  columns.forEach((column) => {
    if (column.sum) {
      hasSummaryRow = true;
    }
  });
  return hasSummaryRow;
};

/**
 * @param {}
 * @returns {}
 */
export const getSum = (column, data) => {
  let total = 0;
  data.forEach((row) => {
    const value = Number(row[column.dataIndex]);
    if (!isNaN(value)) {
      total = total + value;
    }
  });
  return numberWithCommas(total);
};

/**
 * @param {}
 * @returns {}
 */
export const getColumn = (columns, dataIndex) => {
  if (columns) {
    const column = columns.filter((item) => item.dataIndex === dataIndex);
    return column[0];
  }
};

/**
 * @param {}
 * @returns {}
 */
export const compareByAlph = (a, b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
};

export const numberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatCellValue = (value, item) => {
  if (Number(value) || value === 0) {
    return (
      <div>
        {item && item.prefix && <span>{item.prefix}</span>}
        {value}
        {item && item.suffix && <span>{item.suffix}</span>}
      </div>
    );
  } else {
    return (
      <>
        {item && item.prefix && <span>{item.prefix}</span>}
        {value}
        {item && item.suffix && <span>{item.suffix}</span>}
      </>
    );
  }
};

/**
 * @param {}
 * @returns {}
 */
export const formatTableColumns = (viewColumns, tableColumns) => {
  let output = [];
  if (tableColumns) {
    tableColumns.forEach((item, index) => {
      if (item.visible !== false) {
        const sourceColumn = getColumn(viewColumns, item.dataIndex);
        const column = getColumn(tableColumns, item.dataIndex);
        const col = Object.assign({}, sourceColumn, column);
        if (!item.format || item.format === 'None') {
          col.sorter = (a, b) => compareByAlph(a[item.dataIndex], b[item.dataIndex]);
          col.render = (a) => {
            return formatCellValue(a, item);
          };
        }
        if (item.format === 'format-heatmap' && item.breaks && item.colors) {
          col.sorter = (a, b) => b[item.dataIndex] - a[item.dataIndex];
          col.render = (a) => {
            let color;
            if (item.colorType === 'clusters') {
              color = getColorFromScalarBreaks(item.colors, item.breaks, a);
            }
            if (item.colorType === 'categorical') {
              if (item.categories) {
                const colorIndex = item.categories.indexOf(a);
                if (colorIndex !== -1) {
                  color = item.colors[colorIndex];
                } else {
                  color = '#eeeeee';
                }
              }
            }
            return (
              <div
                className="categorical"
                style={{
                  color: color ? getContrast(color) : '',
                  opacity: 0.7,
                  textAlign: 'center',
                  position: 'absolute',
                  backgroundColor: color,
                  height: '35px',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                }}
              >
                {formatCellValue(a, item)}
              </div>
            );
          };
        }
        if (item.format === 'format-square') {
          col.render = (a) => (
            <Tooltip title={`${a}%`}>
              <div
                key={item.dataIndex}
                className="square"
                style={{
                  backgroundColor: getSymbolColor(tableColumns[index], a),
                  width: `${a / 3}px`,
                  height: `${a / 3}px`,
                }}
              />
            </Tooltip>
          );
        }
        if (item.format === 'format-circle') {
          col.render = (a) => (
            <Tooltip title={`${a}%`}>
              <div
                key={item.dataIndex}
                className="circle"
                style={{
                  backgroundColor: getSymbolColor(tableColumns[index], a),
                  width: `${a / 3}px`,
                  height: `${a / 3}px`,
                }}
              />
            </Tooltip>
          );
        }
        if (item.format === 'format-bar') {
          col.sorter = (a, b) => b[item.dataIndex] - a[item.dataIndex];
          col.render = (a) => (
            <Tooltip title={`${a}%`}>
              <div key={item.dataIndex} className="bar-wrapper">
                <div
                  className="bar"
                  style={{
                    backgroundColor: getSymbolColor(tableColumns[index], a),
                    width: `${a}%`,
                  }}
                />
              </div>
            </Tooltip>
          );
        }
        output.push(col);
      }
    });
  }
  return output;
};

/**
 * @param {}
 * @returns {}
 */
export const columnIsAdded = (tableColumns, dataIndex) => {
  let isAdded = false;
  tableColumns.forEach((item) => {
    if (dataIndex === item.dataIndex) {
      isAdded = true;
    }
  });
  return isAdded;
};

/**
 * @param {}
 * @returns {}
 */
export const orderColumns = (viewColumns, tableColumns) => {
  let columns = [];
  if (viewColumns) {
    tableColumns.forEach((item) => {
      const column = getColumn(viewColumns, item.dataIndex);
      columns.push(column);
    });
    viewColumns.forEach((item) => {
      if (!columnIsAdded(tableColumns, item.dataIndex)) {
        columns.push(item);
      }
    });
  }
  return columns;
};

/**
 * @param {}
 * @returns {}
 */
export const handlePagination = (props) => {
  if (
    props.post.components[props.index].pagination &&
    props.post.components[props.index].pagination.defaultPageSize
  ) {
    if (
      props.post.data[props.post.components[props.index].source].length >
      props.post.components[props.index].pagination.defaultPageSize
    ) {
      return {
        defaultPageSize: Number(props.post.components[props.index].pagination.defaultPageSize),
      };
    } else {
      return false;
    }
  } else {
    if (props.post.data[props.post.components[props.index].source].length > 20) {
      return {
        defaultPageSize: 10,
      };
    } else {
      return false;
    }
  }
};

/**
 * @param {}
 * @returns {}
 */
export const setHeatmapColor = (value, count, columnIndex, props) => {
  const colors = getColorBrewerPallet(value, count);
  props.actionComponentColorsEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: colors,
  });
  props.actionComponentColorKeyEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: value,
  });
};

/**
 * @param {}
 * @returns {}
 */
export const setClusterCount = (value, columnIndex, props) => {
  props.actionComponentClustersEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: value,
  });
  const breaks = getScalarBreaks(
    props.post.components[props.index].columns[columnIndex],
    value,
    props.post.data[props.post.components[props.index].source]
  );
  props.actionComponentBreaksEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: breaks,
  });
  setHeatmapColor(
    props.post.components[props.index].columns[columnIndex].colorKey,
    value,
    columnIndex,
    props
  );
};

/**
 * @param {}
 * @returns {}
 */
export const setColorDefaults = (columnIndex, props, value) => {
  if (value) {
    props.actionComponentFormatEdit({
      componentIndex: props.index,
      columnIndex: columnIndex,
      value: value,
    });
  } else {
    props.actionComponentFormatEdit({
      componentIndex: props.index,
      columnIndex: columnIndex,
      value: '',
    });
    return false;
  }
  const breaks = getScalarBreaks(
    props.post.components[props.index].columns[columnIndex],
    3,
    props.post.data[props.post.components[props.index].source]
  );
  const colors = getColorBrewerPallet('Oranges', 3);
  const categories = getUniqueValues(
    props.post.data[props.post.components[props.index].source],
    props.post.components[props.index].columns[columnIndex].dataIndex
  );
  props.actionComponentColorsEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: colors,
  });
  props.actionComponentColorKeyEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: 'Oranges',
  });
  props.actionComponentClustersEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: 3,
  });
  props.actionComponentColorTypeEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: 'clusters',
  });
  props.actionComponentBreaksEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: breaks,
  });
  props.actionComponentCategoriesEdit({
    componentIndex: props.index,
    columnIndex: columnIndex,
    value: categories,
  });
};
