import React, { useEffect } from 'react';
import { Row, Col, Select, Checkbox } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { SelectOption } from '../../../configs/component-types';

const { Option } = Select;

export interface Operator {
  name: string; // name of the operator
  value:
    | '=='
    | '!='
    | '>'
    | '<'
    | '>='
    | '<='
    | 'notNull'
    | 'isNull'
    | 'contains'
    | 'beforeDate'
    | 'afterDate'
    | 'inDateRange'; // value of the operator
  supportsMultipleValues: boolean; // whether the operator supports multiple values or not
  supportsMeasures: boolean; // operator supports measures as well in addition to working with dimensions
  dataType: ('string' | 'number' | 'time')[]; // type of data supported by operator
}

export const OPERATORS: Operator[] = [
  {
    name: 'equal to',
    value: '==',
    supportsMultipleValues: true,
    supportsMeasures: false,
    dataType: ['string', 'number', 'time'],
  },
  {
    name: 'not equal to',
    value: '!=',
    supportsMultipleValues: true,
    supportsMeasures: false,
    dataType: ['string', 'number', 'time'],
  },
  {
    name: 'greater than',
    value: '>',
    supportsMultipleValues: false,
    supportsMeasures: true,
    dataType: ['number'],
  },
  {
    name: 'greater than or equal to',
    value: '>=',
    supportsMultipleValues: false,
    supportsMeasures: true,
    dataType: ['number'],
  },
  {
    name: 'contains',
    value: 'contains',
    supportsMultipleValues: true,
    supportsMeasures: false,
    dataType: ['string', 'number', 'time'],
  },
  {
    name: 'less than',
    value: '<',
    supportsMultipleValues: false,
    supportsMeasures: true,
    dataType: ['number'],
  },
  {
    name: 'less than or equal to',
    value: '<=',
    supportsMultipleValues: false,
    supportsMeasures: true,
    dataType: ['number'],
  },
  {
    name: 'not null',
    value: 'notNull',
    supportsMultipleValues: true,
    supportsMeasures: true,
    dataType: ['string', 'number', 'time'],
  },
  {
    name: 'is null',
    value: 'isNull',
    supportsMultipleValues: true,
    supportsMeasures: true,
    dataType: ['string', 'number', 'time'],
  },
  {
    name: 'before date',
    value: 'beforeDate',
    supportsMultipleValues: true,
    supportsMeasures: true,
    dataType: ['time'],
  },
  {
    name: 'after date',
    value: 'afterDate',
    supportsMultipleValues: true,
    supportsMeasures: true,
    dataType: ['time'],
  },
  {
    name: 'in date range',
    value: 'inDateRange',
    supportsMultipleValues: true,
    supportsMeasures: true,
    dataType: ['time'],
  },
];

export interface FilterOption {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export interface FilterProps {
  operator: string | undefined;
  value: string | undefined;
  property: string | undefined;
  optionsProperty: SelectOption[];
  optionsValue: SelectOption[];
  filterOptions: FilterOption[];
  onOperatorChange: (value: string, obj?: Operator) => void;
  onPropertyChange: (value: string) => void;
  onValueChange: (value: string) => void;
  onDelete: () => void;
}

const defaultProps: Partial<FilterProps> = {
  optionsValue: [],
  optionsProperty: [],
  filterOptions: [],
};

const Filter: React.FC<FilterProps> = (props: FilterProps) => {
  const [operator, setOperator] = useState<string | undefined>(undefined);
  const [property, setProperty] = useState<string | undefined>(undefined);
  const [value, setValue] = useState<string | undefined>(undefined);
  const [operatorObj, setOperatorObj] = useState<Operator>();

  const {
    onDelete,
    onOperatorChange,
    onPropertyChange,
    onValueChange,
    optionsProperty,
    optionsValue,
    filterOptions,
  } = props;
  useEffect(() => {
    setOperator(props.operator);
  }, [props.operator]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setProperty(props.property);
  }, [props.property]);

  useEffect(() => {
    const selected = OPERATORS.find((op) => op.value === operator);

    if (operator) {
      setOperatorObj(selected);
    }
  }, [operator]);

  let setSingle;
  if (filterOptions.length > 0) {
    const option = filterOptions.find((option: FilterOption) => option.name === 'Single');
    if (option?.checked) {
      setSingle = option.checked;
    }
  }

  return (
    <Row gutter={5} style={{ marginBottom: '5px' }}>
      <Col sm={24}>
        <button
          onClick={() => {
            onDelete();
          }}
        >
          <DeleteOutlined />
        </button>
        <label htmlFor="filter-operator-input">Operator</label>
        <Select
          id="filter-operator-input"
          style={{ width: '100%' }}
          getPopupContainer={() => document.getElementById('post--component-drawer') as HTMLElement}
          value={operator}
          onChange={(value) => {
            setOperator(value);
            const obj = OPERATORS.find((op) => op.value === value);
            onOperatorChange(value, obj);
          }}
        >
          {OPERATORS.map((operator) => {
            return {
              name: operator.name,
              value: operator.value,
            };
          }).map((item, index) => (
            <Option key={index} value={item.value}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col sm={24}>
        <label htmlFor="filter-property-input">Property</label>
        <Select
          id="filter-property-input"
          showSearch
          style={{ width: '100%' }}
          getPopupContainer={() => document.getElementById('post--component-drawer') as HTMLElement}
          value={property}
          onChange={(value) => {
            setProperty(value);
            onPropertyChange(value);
          }}
        >
          {optionsProperty.map((item, index) => (
            <Option key={index} value={item.name}>
              {item.value}
            </Option>
          ))}
        </Select>
      </Col>
      {optionsValue.length > 0 && (
        <Col sm={24}>
          <label htmlFor="filter-value-input">Value</label>
          <Select
            id="filter-value-input"
            mode={operatorObj?.supportsMultipleValues && !setSingle ? 'multiple' : undefined}
            allowClear
            showSearch
            style={{ width: '100%' }}
            getPopupContainer={() =>
              document.getElementById('post--component-drawer') as HTMLElement
            }
            value={value}
            onChange={(value) => {
              setValue(value);
              onValueChange(value);
            }}
          >
            {optionsValue.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Col>
      )}

      {filterOptions.map((option, index) => (
        <Col key={index} sm={24}>
          <Checkbox
            checked={option.checked}
            onChange={(e) => {
              option.onChange(e.target.checked);
            }}
          >
            {option.name}
          </Checkbox>
        </Col>
      ))}
    </Row>
  );
};

Filter.defaultProps = defaultProps;

export { Filter };
