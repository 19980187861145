import React, { useState, useEffect } from 'react';
import { message, notification, Button } from 'antd';
import { AkukoAPIService } from '../../../services/serviceClass';
import { POSTS_API } from '../../../configs/env';
import { ERROR_GENERIC } from '../../../configs/constants';
import { useHistory } from 'react-router-dom';
import { actionPostPropertyEdit, actionPostEdit } from '../../Post/actions';
import { useSelector, useDispatch } from 'react-redux';
import { userHasWriteAccess } from '../../Profile/components/ProfileMenu/helpers';
import { GenericComponentProps } from '../../Post/Component';
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils/dist/types/types';

const EditPostButton = () => {
  const user = useSelector((state: Dictionary) => state.user);
  const post = useSelector((state: Dictionary) => state.post);
  const [loadingPost, setLoadingPost] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const enterPost = () => {
    const service = new AkukoAPIService(POSTS_API, `post/${post.uuid}`);
    service
      .update({
        ...post,
        // remove edit
        edit: user.email,
        data: {},
        sources: {},
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setTimeout(() => {
          setLoadingPost(false);
          history.push(`/post/${post.uuid}/edit`);
        }, 500);

        dispatch(
          actionPostPropertyEdit({
            property: 'updated',
            value: res.updated,
          })
        );
        dispatch(
          actionPostPropertyEdit({
            property: 'edit',
            value: user.email,
          })
        );
      })
      .catch((err) => {
        message.error(err.message || ERROR_GENERIC);
      });
  };

  const exitPost = () => {
    const service = new AkukoAPIService(POSTS_API, `post/${post.uuid}`);
    const updatedPost = {
      ...post,
      components: post.components.filter(
        (component: GenericComponentProps) => !component.spaceComponent
      ),
    };
    service
      .update({
        ...updatedPost,
        // remove edit
        edit: null,
        data: {},
        sources: {},
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setTimeout(() => {
          setLoadingPost(false);
          history.push(`/post/${post.uuid}`);
        }, 500);
        dispatch(
          actionPostPropertyEdit({
            property: 'edit',
            value: null,
          })
        );
        dispatch(
          actionPostPropertyEdit({
            property: 'updated',
            value: res.updated,
          })
        );
      })
      .catch((err) => {
        message.error(err.message || ERROR_GENERIC);
      });
  };

  if (user.uuid && userHasWriteAccess(user, location, post, true)) {
    return (
      <Button
        type={post.edit === user.email ? 'primary' : 'default'}
        className="btn-edit-post"
        onClick={() => {
          setLoadingPost(true);
          if (post.edit === null) {
            return enterPost();
          }
          if (post.edit === user.email) {
            return exitPost();
          }
          if (post.edit !== user.email) {
            // other user is editing
            const key = `open${Date.now()}`;
            const btn = (
              <Button
                id="btn-post-lock"
                onClick={() => {
                  dispatch(
                    actionPostEdit({
                      value: user.email,
                    })
                  );
                  enterPost();
                }}
              >
                Break Lock
              </Button>
            );
            notification.open({
              message: `Post Locked`,
              description: `This Post is being edited by ${post.edit}.`,
              btn,
              key,
            });
          }
        }}
      >
        {loadingPost ? (
          <LoadingOutlined />
        ) : (
          <>
            <EditOutlined /> Edit
          </>
        )}
      </Button>
    );
  } else {
    return null;
  }
};

export { EditPostButton };
