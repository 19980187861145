import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const SectionItem = (props) => {
  const [name, setName] = useState();
  const [type, setType] = useState();

  return (
    <div className="section-item">
      <div className="delete">
        <DeleteOutlined
          onClick={() => {
            props.actionSpaceMenuItemDelete({
              index: props.index,
            });
          }}
        />
      </div>
      <div className="field">
        <label>Name</label>
        <Input
          value={props.item.name}
          onChange={(e) => {
            props.actionSpaceMenuItemEdit({
              index: props.index,
              property: 'name',
              value: e.target.value,
            });
          }}
        />
      </div>
      <div className="field">
        <label>Type</label>
        <Select
          style={{ width: '100%' }}
          value={props.item.type}
          getPopupContainer={() => document.getElementById('design-drawer--content')}
          onChange={(value) => {
            props.actionSpaceMenuItemEdit({
              index: props.index,
              property: 'type',
              value: value,
            });
          }}
        >
          <Select.Option value={'tag'}>Tag</Select.Option>
          <Select.Option value={'post'}>Post</Select.Option>
          <Select.Option value={'url'}>External link</Select.Option>
        </Select>
      </div>
      {props.item.type === 'tag' && (
        <div className="field">
          <label>Tags</label>
          <Select
            getPopupContainer={() => document.getElementById('design-drawer--content')}
            value={props.item.tag}
            style={{ width: '100%' }}
            onChange={(value) => {
              props.actionSpaceMenuItemEdit({
                index: props.index,
                property: 'tag',
                value: value,
              });
            }}
          >
            {props.tags &&
              props.tags.map((tag, index) => (
                <Select.Option key={index} value={tag.value}>
                  {tag.text}
                </Select.Option>
              ))}
          </Select>
        </div>
      )}
      {props.item.type === 'post' && (
        <div className="field">
          <label>Posts</label>
          <Select
            getPopupContainer={() => document.getElementById('design-drawer--content')}
            value={props.item.post}
            style={{ width: '100%' }}
            onChange={(value) => {
              props.actionSpaceMenuItemEdit({
                index: props.index,
                property: 'post',
                value: value,
              });
            }}
          >
            {props.posts &&
              props.posts.map((post, index) => (
                <Select.Option key={index} value={post.uuid}>
                  {post.title}
                </Select.Option>
              ))}
          </Select>
        </div>
      )}
      {props.item.type === 'url' && (
        <div className="field">
          <label>URL</label>
          <Input
            value={props.item.url}
            onChange={(e) => {
              props.actionSpaceMenuItemEdit({
                index: props.index,
                property: 'url',
                value: e.target.value,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SectionItem;
