import { Dictionary } from '@onaio/utils';
import { Store } from 'redux';

/**
 * get active space id
 *
 * @param {Partial<Store>} state redux store
 * @returns {string} email
 */
export const spaceIDSelector = (state: Partial<Store>): string => {
  return (state as Dictionary).space.id;
};
