import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutSixteen = (props) => {
  return (
    <div>
    <Row gutter={20}>
      <Col xs={24} sm={16}>
         <Region regionIndex={2} {...props} />
      </Col>
      <Col xs={23} sm={8}>
         <Region regionIndex={3} {...props} />
         <Region regionIndex={4} {...props} />
         <Region regionIndex={5} {...props} />
      </Col>
    </Row>
    </div>
  );
};

export { LayoutSixteen };
