import React, { useEffect, useState } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { Input, Button, message, Select } from 'antd';
import { actionSourceEdit, actionSourceSave } from '../actions';
import { removeGoogleSheetGIDSuffix } from '../helpers';
import { AkukoAPIService } from '../../../services/serviceClass';
import { SOURCES_API, TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../configs/env';
import {
  Source,
  sourceAsyncOperationsJobStatusTypes,
  UrlSourceCreationJobStatusTypes,
} from '../../../configs/component-types';
import { startAsycGoogleSheetSourceCreation } from '../helpers/helpers';
import { Dictionary } from '@onaio/utils';
import { LoadingOutlined } from '@ant-design/icons';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { UrlSourceCreationProgressSteps } from '../components/UrlSourceCreationProgressSteps';
const { Option } = Select;

export interface SourceGoogleSheetInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const SourceGoogleSheetInput: React.FC<SourceGoogleSheetInputProps> = (
  props: SourceGoogleSheetInputProps
) => {
  const { sourceActionSaveCreator } = props;
  const dispatch = useDispatch();
  const source = useSelector((store: Dictionary) => store.source);
  const store = useStore();
  const [url, setUrl] = useState('');
  const [sheets, setSheets] = useState([]);
  const [sheet, setSheet] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }
      setLoading(false);
      message.success('Source saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleSheetConnect = () => {
    setLoading(true);
    const service = new AkukoAPIService(SOURCES_API, 'connect/gsheet-info');
    service
      .create({
        url: source.config['sheet-url'],
      })
      .then((res) => {
        setLoading(false);
        const data = res as [];
        if (Array.isArray(data)) {
          setSheets(data);
        } else {
          message.error('Connection error');
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message || 'Connection error');
      });
  };

  const createGoogleSheetSource = () => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });

    const currentState = store.getState();
    triggerAsycSourceOperation({
      asynFunc: startAsycGoogleSheetSourceCreation,
      asynFuncArgs: {
        /* @ts-ignore */
        sourceId: currentState?.source?.uuid,
        /* @ts-ignore */
        url: removeGoogleSheetGIDSuffix(url),
        /* @ts-ignore */
        sheet: currentState?.source?.config.sheet,
        /* @ts-ignore */
        type: currentState?.source?.type,
        /* @ts-ignore */
        name: currentState?.source?.name,
        /* @ts-ignore */
        cube: currentState?.source?.cube,
      },
      asyncTaskName: 'Source Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const isDisabled = () => {
    if (source?.dimensions?.length > 0) {
      return true;
    }
    return false;
  };

  if (
    (source?.type === 'google-sheet' && (!source?.dimensions || !source?.dimensions?.length)) ||
    (source?.type === 'google-sheet' && source?.dimensions && loading === true)
  ) {
    return (
      <div className="input-group">
        <div className="input-field">
          <label>Url</label>
          <Input
            value={url}
            placeholder="Google Sheet URL"
            disabled={isDisabled()}
            onChange={(e: Dictionary) => {
              setUrl(e.target.value);
            }}
            onBlur={() => {
              dispatch(
                actionSourceEdit({
                  config: {
                    ['sheet-url']: url,
                  },
                })
              );
            }}
          />
        </div>
        <div className="input-field">
          You must share your sheet with our service user:{' '}
          <code>googlesheets@onaio-268121.iam.gserviceaccount.com</code>
        </div>
        {sheets?.length > 0 && (
          <div className="input-field">
            <label>Select sheet</label>
            <Select
              onChange={(value: string) => {
                setSheet(value);
              }}
              disabled={isDisabled()}
              onBlur={() => {
                dispatch(
                  actionSourceEdit({
                    config: {
                      ['sheet']: sheet,
                      ...source.config,
                      connector: 'clickhouse',
                    },
                  })
                );
              }}
            >
              {sheets?.map((item: Dictionary, index: number) => (
                <Option key={index} value={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
        )}

        {!sheet && (
          <div className="btn-field">
            <Button
              disabled={loading}
              type="primary"
              onClick={() => {
                handleSheetConnect();
              }}
            >
              {loading && <LoadingOutlined />} Connect
            </Button>
          </div>
        )}

        {sheet && (
          <div className="btn-field">
            <Button
              disabled={loading}
              type="primary"
              onClick={() => {
                createGoogleSheetSource();
              }}
            >
              Connect
            </Button>
          </div>
        )}
        {loading === true && sheet && (
          <div className="input-field">
            <label>Progress</label>
            <UrlSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

SourceGoogleSheetInput.defaultProps = defaultProps;

export { SourceGoogleSheetInput };
