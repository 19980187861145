import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutTwelve = (props) => {
  return (
    <Row gutter={15}>
      <Col xs={24} sm={18}>
        <Region regionIndex={1} {...props} />
      </Col>
      <Col xs={24} sm={6}>
        <div className="layout--region-content">
          <Region regionIndex={2} {...props} />
        </div>
      </Col>
    </Row>
  );
};

export { LayoutTwelve };
