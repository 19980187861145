import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, message } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ProfileMenu } from '../Profile/components/ProfileMenu';
import { Dictionary } from '@onaio/utils/dist/types/types';
import './style.css';
import { POSTS_API } from '../../configs/env';
import { AkukoAPIService } from '../../services/serviceClass';
import { ERROR_GENERIC } from '../../configs/constants';
import Loader from '../Post/Loader/Loader';
import Footer from '../Post/Footer';
import { CalendarOutlined } from '@ant-design/icons';
import { actionPostsGet } from '../Account/actions';
import { getAuthors } from '../Space/helpers';
import { GlobalNav } from '../Account/GlobalNav';
import Author from '../Post/Author';
import moment from 'moment';
const { TabPane } = Tabs;

export interface FeedProps {
  user: Dictionary;
}

const Feed: React.FC<FeedProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: Dictionary) => state.user);
  const posts = useSelector((state: Dictionary) => state.posts);
  const [trending, setTrending] = useState<Dictionary[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [authors, setAuthors] = useState<string[]>([]);

  document.title = `Akuko – Tell stories with data`;

  useEffect(() => {
    document.body.classList.add('main-feed');
    return () => {
      document.body.classList.remove('main-feed');
    };
  }, []);

  useEffect(() => {
    getAuthors([...trending, ...posts]).then((data) => {
      setAuthors(data as string[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  useEffect(() => {
    setShowLoader(true);
    const service = new AkukoAPIService(POSTS_API, `post`);
    service
      .read('public')
      .then((posts) => {
        dispatch(actionPostsGet(posts));
      })
      .catch((err) => {
        message.error(err.message || ERROR_GENERIC);
      })
      .finally(() => {
        setShowLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowLoader(true);
    const service = new AkukoAPIService(POSTS_API, `post`);
    service
      .read('trending')
      .then((res) => {
        const posts = res as Dictionary[];
        setTrending(posts);
      })
      .catch((err) => {
        message.error(err.message || ERROR_GENERIC);
      })
      .finally(() => {
        setShowLoader(false);
      });
  }, []);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="feed">
      <GlobalNav />
      <Row gutter={0}>
        <Col xs={24} sm={24} lg={16}>
          <div className="feed-header">
            <div className="callout">
              <h1>Welcome to Akuko</h1>
              <p>Akuko is a platform for telling stories with data. </p>
            </div>
          </div>
          <div className="feed-posts">
            <Tabs>
              <TabPane tab="Trending" key={'trending'}>
                {trending.map(
                  (item: Dictionary, index: number) =>
                    item.config?.trending && (
                      <div className="feed-post" key={index}>
                        {item.space_id && (
                          <div className="feed-post--space">
                            <span
                              className="feed-post--space-account"
                              style={{
                                backgroundImage: `url(https://assets.akuko.io/${item.account_id}-icon.png)`,
                              }}
                            >
                              {item.account_id}
                            </span>{' '}
                            <small>
                              Published in{' '}
                              <span className="feed-post--space-name">{item.space_id}</span>
                            </small>
                          </div>
                        )}
                        <div className="content">
                          {item.image && (
                            <div
                              className="feed-post--thumb"
                              style={{ background: `url(${item.image}) no-repeat center center` }}
                            ></div>
                          )}
                          {!item.image && (
                            <div
                              className="feed-post--thumb no-thumb"
                              style={{
                                background: `#f9f9f9 url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center`,
                              }}
                            ></div>
                          )}
                          <Link to={`/post/${item.uuid}`}>
                            <h2>{item.title}</h2>
                          </Link>
                          <div className="feed-post--date">
                            <CalendarOutlined /> {moment(item.published).format('MMMM DD, YYYY')}
                          </div>
                          {item.summary ? <p>{item.summary}</p> : <p>{item.description}</p>}
                          {!item.summary && !item.description && <p>...</p>}
                        </div>
                      </div>
                    )
                )}
              </TabPane>
              <TabPane tab="Latest" key={'latest'}>
                {posts.map(
                  (item: Dictionary, index: number) =>
                    item.public && (
                      <div className="feed-post" key={index}>
                        {item.space_id && (
                          <div className="feed-post--space">
                            <span
                              className="feed-post--space-account"
                              style={{
                                backgroundImage: `url(https://assets.akuko.io/${item.account_id}-icon.png)`,
                              }}
                            >
                              {item.account_id}
                            </span>{' '}
                            <small>
                              Published in{' '}
                              <span className="feed-post--space-name">{item.space_id}</span>
                            </small>
                          </div>
                        )}
                        <div className="content">
                          {item.image && (
                            <div
                              className="feed-post--thumb"
                              style={{ background: `url(${item.image}) no-repeat center center` }}
                            ></div>
                          )}
                          {!item.image && (
                            <div
                              className="feed-post--thumb no-thumb"
                              style={{
                                background: `#f9f9f9 url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center`,
                              }}
                            ></div>
                          )}
                          <Link to={`/post/${item.uuid}`}>
                            <h2>{item.title}</h2>
                          </Link>
                          <div className="feed-post--date">
                            <CalendarOutlined /> {moment(item.published).format('MMMM DD, YYYY')}
                          </div>
                          {item.summary ? <p>{item.summary}</p> : <p>{item.description}</p>}
                          {!item.summary && !item.description && <p>...</p>}
                        </div>
                      </div>
                    )
                )}
              </TabPane>
            </Tabs>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={8}>
          <div className="feed--sidebar">
            <div className="content">
              {/*}
                <div className="feed-sidebar--search">
                  <Search 
                    placeholder="Search..." 
                    onSearch={(value) => {
                      console.log(value)
                      // search endpoint?
                    }}
                  />
                </div>
                  {*/}
              <div className="feed-sidebar--authors">
                <h3>Contributors</h3>
                {authors &&
                  authors.map(
                    (item: string, index: number) =>
                      item !== null && <Author key={index} author={item} />
                  )}
              </div>
              <Footer align="left" position="bottom" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { Feed };
