import { GroupFieldProps } from '../types';

export const layer: GroupFieldProps = {
  groups: [
    'Settings',
    'Control',
    'Legend',
    'Popup',
    'Labels',
    'Events',
    'Filters',
  ],
  sections: {},
  fields: [
    {
      type: 'text',
      property: 'layerName',
      label: 'Layer name',
      parents: ['layers'],
      group: 'Settings',
      placeHolder: 'Enter Layer Name',
    },
    {
      type: 'switch',
      property: 'hasToggle',
      label: 'Enable layer toggle',
      parents: ['layers'],
      group: 'Control',
      size: 'small',
    },
    {
      type: 'switch',
      label: 'Enable Legend',
      property: 'legend',
      defaultValue: {
        position: 'below',
        isEnabled: false,
        startAtZero: false,
      },
      objectKey: 'isEnabled',
      setNestedType: true,
      parents: ['layers'],
      group: 'Legend',
      size: 'small',
    },
    {
      type: 'select',
      label: 'Position',
      property: 'legend',
      defaultValue: {
        position: 'below',
        isEnabled: false,
        startAtZero: false,
      },
      objectKey: 'position',
      setNestedType: true,
      options: [
        { value: 'below', label: 'Below' },
        { value: 'above', label: 'Above' },
      ],
      parents: ['layers'],
      group: 'Legend',
    },
    {
      type: 'switch',
      label: 'Start at Zero',
      property: 'legend',
      defaultValue: {
        position: 'below',
        isEnabled: false,
        startAtZero: false,
      },
      objectKey: 'startAtZero',
      setNestedType: true,
      parents: ['layers'],
      group: 'Legend',
      size: 'small',
    },
    {
      type: 'source',
      property: 'source',
      label: 'Source',
      parents: ['layers'],
      isMapLayer: true,
      hasGeometries: true,
      group: 'Settings',
      dependsOn: [{ property: 'source', value: '' }],
    },
    {
      type: 'source',
      property: 'source',
      label: 'Source',
      parents: ['layers'],
      isMapLayer: true,
      hasGeometries: true,
      disabled: true,
      group: 'Settings',
      dependsOn: [{ property: 'source', value: ['!', ''] }],
    },
    {
      type: 'geometry',
      label: 'Geometry',
      property: 'geometryIndex',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'source',
          value: '*',
        },
      ],
      placeHolder: 'Set Layer Geometry',
    },
    {
      type: 'select',
      label: 'Layer type',
      property: 'layerType',
      refreshKey: true,
      group: 'Settings',
      parents: ['layers'],
      options: [
        { value: 'fill', label: 'Fill' },
        { value: 'line', label: 'Line' },
        { value: 'circle', label: 'Circle' },
        { value: 'symbol', label: 'Symbol' },
        { value: 'fill-extrusion', label: 'Fill extrusion' },
      ],
      dependsOn: [
        {
          property: 'geometryIndex',
          value: '*',
        },
      ],
      placeHolder: 'Set Layer Type',
    },

    {
      type: 'selectSteps',
      label: 'Color mode',
      property: 'symbolMethod',
      parents: ['layers'],
      group: 'Settings',
      options: [
        { value: 'simple', label: 'Simple' },
        { value: 'categorical', label: 'Categories' },
      ],
      dependsOn: [
        {
          property: 'layerType',
          value: ['symbol'],
        },
      ],
      placeHolder: 'Layer Coloring Method',
    },

    {
      type: 'dimension',
      label: 'Symbol Field',
      property: 'symbolField',
      showMeasures: true,
      showDimensions: true,
      group: 'Settings',
      parents: ['layers'],
      setType: true,
      typeKey: 'symbolFieldType',
      showGeometryProperties: true,
      dependsOn: [
        {
          property: 'layerType',
          value: 'symbol',
        },
        {
          property: 'symbolMethod',
          value: 'categorical',
        },
      ],
      placeHolder: 'Field That Drives Symbols',
    },

    {
      type: 'select',
      label: 'Classes',
      popOver: true,
      property: 'categoricalClasses',
      group: 'Settings',
      options: [
        {
          value: 10,
          label: '10 Categories',
        },
        {
          value: 20,
          label: '20 Categories',
        },
        {
          value: 30,
          label: '30 Categories',
        },
        {
          value: 40,
          label: '40 Categories',
        },
        {
          value: 50,
          label: '50 Categories',
        },
        {
          value: 250,
          label: '250 Categories',
        },
      ],
      parents: ['layers'],
      placeHolder: 'Classes',
      dependsOn: [
        {
          property: 'symbolMethod',
          value: 'categorical',
        },
        {
          property: 'layerType',
          value: 'symbol',
        },
        {
          property: 'symbolField',
          value: '*',
        },
      ],
    },

    {
      type: 'icon',
      label: 'Symbol',
      property: 'icon',
      group: 'Settings',
      parents: ['layers'],
      excludeTabs: ['gggi'],
      dependsOn: [
        {
          property: 'layerType',
          value: 'symbol',
        },
        {
          property: 'symbolMethod',
          value: 'simple',
        },
      ],
    },
    {
      type: 'color',
      disableOpacity: false,
      label: 'Color',
      property: 'iconColor',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'symbol',
        },
        // {
        //   property: 'symbolMethod',
        //   value: 'simple',
        // },
      ],
    },
    {
      type: 'slider',
      min: 0.01,
      max: 1,
      step: 0.01,
      label: 'Symbol size',
      property: 'iconSize',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'symbol',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      min: 0.1,
      max: 1,
      step: 0.1,
      label: 'Opacity',
      property: 'layerOpacity',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'symbol',
        },
      ],
    },

    {
      type: 'switch',
      label: 'Allow symbol overlap',
      property: 'iconAllowOverlap',
      parents: ['layers'],
      group: 'Settings',
      groups: ['Symbol'],
      settings: [
        {
          label: 'Symbol',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Category',
      defaultValue: {
        value: '',
        symbol: '',
      },
      fields: [
        {
          type: 'text',
          property: 'value',
          group: 'Symbol',
          label: 'Category',
          parents: ['layers', 'symbolCategories'],
          placeHolder: 'Value for the Symbol below',
        },
        {
          type: 'icon',
          property: 'symbol',
          group: 'Symbol',
          label: 'Symbol',
          excludeTabs: ['gggi'],
          parents: ['layers', 'symbolCategories'],
        },
        {
          type: 'color',
          disableOpacity: false,
          label: 'Symbol color',
          property: 'color',
          parents: ['layers', 'symbolCategories'],
          group: 'Symbol',
        },
      ],
      dependsOn: [
        {
          property: 'layerType',
          value: 'symbol',
        },
      ],
      size: 'small',
    },

    {
      type: 'selectSteps',
      label: 'Scale',
      property: 'radiusScale',
      group: 'Settings',
      options: [
        { value: 'simple', label: 'Simple' },
        // { value: 'sqrt', label: 'Sqrt' },
        { value: 'steps', label: 'Steps' },
        // { value: 'categorical', label: 'Categorical' },
      ],
      parents: ['layers'],
      dependsOn: [
        {
          property: 'layerType',
          value: ['circle'],
        },
      ],
      placeHolder: 'Sqrt',
    },
    {
      type: 'slider',
      label: 'Circle radius',
      property: 'circleRadius',
      parents: ['layers'],
      group: 'Settings',
      min: 1,
      max: 100,
      step: 1,
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
        {
          property: 'radiusScale',
          value: ['!', 'steps'],
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'dimension',
      label: 'Size by',
      property: 'circleRadiusField',
      showMeasures: true,
      showDimensions: true,
      parents: ['layers'],
      group: 'Settings',
      getMinMax: true,
      setType: true,
      setDataType: true,
      showGeometryProperties: true,
      dataTypeKey: 'circleRadiusDataType',
      typeKey: 'circleRadiusFieldType',
      expectedDataType: 'number',
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
        {
          property: 'radiusScale',
          value: ['sqrt', 'steps'],
        },
      ],
      placeHolder: 'Field That Drives Radius',
    },

    {
      type: 'dimension',
      label: 'Size by',
      property: 'categoryCircleRadiusField',
      showMeasures: true,
      showDimensions: true,
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
        {
          property: 'radiusScale',
          value: ['categorical'],
        },
      ],
      placeHolder: 'Field That Drives Radius',
    },

    {
      type: 'select',
      label: 'Radius Scale',
      property: 'circleRadiusScale',
      parents: ['layers'],
      popOver: true,
      group: 'Settings',
      placeHolder: 'Equal Intervals',
      options: [
        { value: 'ckmeans', label: 'Jenks Natural Breaks' },
        { value: 'linear', label: 'Equal Intervals' },
        { value: 'quantile', label: 'Quantiles' },
      ],
      dependsOn: [
        { property: 'circleRadiusField', value: '*' },
        { property: 'radiusScale', value: 'steps' },
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
    },

    {
      type: 'select',
      label: 'Radius Classes',
      popOver: true,
      property: 'categoricalRadiusClasses',
      group: 'Settings',
      options: [
        {
          value: 10,
          label: '10 Categories',
        },
        {
          value: 20,
          label: '20 Categories',
        },
        {
          value: 30,
          label: '30 Categories',
        },
        {
          value: 40,
          label: '40 Categories',
        },
        {
          value: 50,
          label: '50 Categories',
        },
        {
          value: 250,
          label: '250 Categories',
        },
      ],
      parents: ['layers'],
      placeHolder: 'Classes',
      dependsOn: [
        {
          property: 'radiusScale',
          value: 'categorical',
        },
        {
          property: 'categoryCircleRadiusField',
          value: '*',
        },
      ],
    },

    {
      type: 'number',
      label: 'Min Radius',
      property: 'minCircleRadius',
      placeHolder: '0px',
      parents: ['layers'],
      group: 'Settings',

      dependsOn: [
        { property: 'circleRadiusField', value: '*' },
        { property: 'radiusScale', value: 'steps' },
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
    },

    {
      type: 'number',
      label: 'Max Radius',
      property: 'maxCircleRadius',
      placeHolder: '0px',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        { property: 'circleRadiusField', value: '*' },
        { property: 'radiusScale', value: 'steps' },
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
    },

    {
      type: 'number',
      label: 'Max value',
      property: 'maxValue',
      placeHolder: '0',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        { property: 'circleRadiusField', value: '*' },
        { property: 'radiusScale', value: 'sqrt' },
      ],
    },

    {
      type: 'array',
      label: 'Circle Radius categories',
      property: 'radiusCategories',
      parents: ['layers'],
      group: 'Settings',
      collapseProperty: 'value',
      collapseParent: 'radiusCategories',
      groups: ['RadiusCategories'],
      settings: [
        {
          label: 'RadiusCategories',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Radius Category',
      defaultValue: {
        value: '',
        radius: 0,
      },
      fields: [
        {
          type: 'text',
          property: 'value',
          label: 'Category',
          group: 'RadiusCategories',
          parents: ['layers', 'radiusCategories'],
          placeHolder: 'Value For The Radius Below',
        },
        {
          type: 'slider',
          property: 'radius',
          min: 0,
          max: 100,
          step: 1,
          label: 'Radius',
          group: 'RadiusCategories',
          parents: ['layers', 'radiusCategories'],
        },
      ],
      dependsOn: [
        {
          property: 'circleRadiusField',
          value: '*',
        },
        {
          property: 'radiusScale',
          value: 'categorical',
        },
      ],
    },
    {
      type: 'selectSteps',
      label: 'Color mode',
      property: 'colorMethod',
      parents: ['layers'],
      group: 'Settings',
      options: [
        { value: 'simple', label: 'Simple' },
        { value: 'interpolate', label: 'Continuous' },
        { value: 'breaks', label: 'Generated Steps' },
        { value: 'categorical', label: 'Categories' },
      ],
      dependsOn: [
        {
          property: 'layerType',
          value: ['fill', 'circle', 'line', 'fill-extrusion'],
        },
      ],
      placeHolder: 'Layer Coloring Method',
    },

    // continous color
    {
      type: 'dimension',
      label: 'Color by',
      property: 'colorField',
      showMeasures: true,
      showDimensions: true,
      getMinMax: true,
      parents: ['layers'],
      group: 'Settings',
      setType: true,
      typeKey: 'colorFieldType',
      expectedDataType: 'number',
      showGeometryProperties: true,
      dependsOn: [
        {
          property: 'layerType',
          value: ['fill', 'circle', 'line', 'fill-extrusion'],
        },
        {
          property: 'colorMethod',
          value: ['interpolate', 'breaks', 'manual'],
        },
      ],
      placeHolder: 'Field That Drives Layer Colors',
    },

    {
      type: 'dimension',
      label: 'Color by',
      property: 'colorField',
      showMeasures: true,
      showDimensions: true,
      getMinMax: true,
      parents: ['layers'],
      group: 'Settings',
      setType: true,
      typeKey: 'colorFieldType',
      showGeometryProperties: true,
      dependsOn: [
        {
          property: 'layerType',
          value: ['fill', 'circle', 'line', 'fill-extrusion'],
        },
        {
          property: 'colorMethod',
          value: ['custom', 'categorical'],
        },
      ],
      placeHolder: 'Field That Drives Layer Colors',
    },

    {
      type: 'select',
      label: 'Color Scale',
      property: 'colorScale',
      group: 'Settings',
      popOver: true,
      options: [
        { value: 'ckmeans', label: 'Jenks Natural Breaks' },
        { value: 'linear', label: 'Equal Intervals' },
        { value: 'quantile', label: 'Quantiles' },
      ],
      parents: ['layers'],
      placeHolder: 'Linear (Equal Interval)',
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'breaks',
        },
        {
          property: 'layerType',
          value: ['fill', 'circle', 'line', 'fill-extrusion'],
        },
        {
          property: 'colorField',
          value: '*',
        },
      ],
    },

    {
      type: 'select',
      label: 'Classes',
      popOver: true,
      property: 'categoricalClasses',
      group: 'Settings',
      options: [
        {
          value: 10,
          label: '10 Categories',
        },
        {
          value: 20,
          label: '20 Categories',
        },
        {
          value: 30,
          label: '30 Categories',
        },
        {
          value: 40,
          label: '40 Categories',
        },
        {
          value: 50,
          label: '50 Categories',
        },
        {
          value: 250,
          label: '250 Categories',
        },
      ],
      parents: ['layers'],
      placeHolder: 'Classes',
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'categorical',
        },
        {
          property: 'colorField',
          value: '*',
        },
      ],
    },

    {
      type: 'color-range',
      label: 'Color Palette',
      property: 'colorRange',
      group: 'Settings',
      hideClassInput: true,
      parents: ['layers'],
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'categorical',
        },
        {
          property: 'colorField',
          value: '*',
        },
        {
          property: 'layerType',
          value: ['!', 'symbol'],
        },
      ],
    },

    {
      type: 'color-range',
      label: 'Color Scheme',
      property: 'colorRange',
      group: 'Settings',
      parents: ['layers'],
      hideClassInput: true,
      dependsOn: [
        {
          property: 'colorMethod',
          value: ['breaks'],
        },
        {
          property: 'colorField',
          value: '*',
        },
        {
          property: 'layerType',
          value: ['!', 'symbol'],
        },
      ],
    },

    {
      type: 'switch',
      label: 'Reverse Colors',
      property: 'reverseColors',
      parents: ['layers'],
      cols: 24,
      group: 'Settings',
      size: 'small',
      dependsOn: [
        {
          property: 'colorMethod',
          value: ['categorical', 'breaks'],
        },

        {
          property: 'colorField',
          value: '*',
        },
        {
          property: 'colorRange',
          value: '*',
        },
      ],
    },
    {
      type: 'text',
      label: 'Min value',
      property: 'fillFieldMinValue',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'interpolate',
        },
        {
          property: 'colorField',
          value: '*',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'color',
      disableOpacity: false,
      label: 'Min color',
      property: 'fillFieldStartColor',
      group: 'Settings',
      parents: ['layers'],
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'interpolate',
        },
        {
          property: 'colorField',
          value: '*',
        },
      ],
    },
    {
      type: 'text',
      label: 'Max value',
      property: 'fillFieldMaxValue',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'interpolate',
        },
        {
          property: 'colorField',
          value: '*',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'color',
      disableOpacity: false,
      label: 'Max color',
      property: 'fillFieldEndColor',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'colorMethod',
          value: 'interpolate',
        },
        {
          property: 'colorField',
          value: '*',
        },
      ],
    },

    {
      type: 'color',
      disableOpacity: false,
      label: 'Fill',
      property: 'fillColor',
      group: 'Settings',
      parents: ['layers'],
      dependsOn: [
        {
          property: 'layerType',
          value: ['fill', 'circle', 'line', 'fill-extrusion'],
        },
        {
          property: 'colorMethod',
          value: ['!', 'categorical', 'breaks', 'interpolate', 'manual'],
        },
      ],
    },
    {
      type: 'slider',
      label: 'Line width',
      min: 0,
      max: 8,
      step: 1,
      property: 'lineWidth',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'line',
        },
      ],
    },
    {
      type: 'slider',
      min: 0.1,
      max: 1,
      step: 0.1,
      label: 'Line opacity',
      property: 'layerOpacity',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'line',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Dash width',
      property: 'lineDashLength',
      min: 0,
      max: 5,
      step: 1,
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'line',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Gap width',
      property: 'patternGaps',
      min: 0,
      max: 5,
      step: 1,
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'line',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Fill opacity',
      property: 'layerOpacity',
      parents: ['layers'],
      group: 'Settings',
      min: 0,
      max: 1,
      step: 0.1,
      dependsOn: [
        {
          property: 'layerType',
          value: 'fill',
        },
      ],
    },
    {
      type: 'color',
      disableOpacity: false,
      label: 'Fill outline',
      property: 'fillOutlineColor',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'fill',
        },
      ],
    },

    {
      type: 'slider',
      label: 'Circle opacity',
      property: 'layerOpacity',
      min: 0,
      max: 1,
      step: 0.1,
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
      placeHolder: '0',
    },

    {
      type: 'color',
      disableOpacity: false,
      label: 'Stroke',
      property: 'circleStrokeColor',
      parents: ['layers'],
      group: 'Settings',
      placeHolder: '#FFFFFF',
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
    },
    {
      type: 'number',
      label: 'Stroke width',
      property: 'circleStrokeWidth',
      parents: ['layers'],
      group: 'Settings',
      min: 1,
      max: 100,
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Stroke opacity',
      property: 'circleStrokeOpacity',
      min: 0.1,
      max: 1,
      step: 0.1,
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Circle blur',
      property: 'circleBlur',
      parents: ['layers'],
      group: 'Settings',
      min: 0,
      max: 1,
      step: 0.1,
      dependsOn: [
        {
          property: 'layerType',
          value: 'circle',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'dimension',
      label: 'Height by',
      property: 'heightField',
      showMeasures: true,
      showDimensions: true,
      parents: ['layers'],
      group: 'Settings',
      setType: true,
      typeKey: 'heightFieldType',
      dependsOn: [
        {
          property: 'layerType',
          value: 'fill-extrusion',
        },
      ],
      placeHolder: 'Field That Drives Height',
    },
    {
      type: 'select',
      label: 'Height method',
      property: 'heightMethod',
      parents: ['layers'],
      group: 'Settings',
      options: [{ value: 'interpolate', label: 'Interpolate' }],
      dependsOn: [
        {
          property: 'layerType',
          value: 'fill-extrusion',
        },
        {
          property: 'heightField',
          value: '*',
        },
      ],
      placeHolder: 'Height Extrusion Method',
    },
    {
      type: 'text',
      label: 'Height min',
      placeHolder: '0',
      property: 'extrusionFieldMinValue',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'heightMethod',
          value: 'interpolate',
        },
        {
          property: 'layerType',
          value: 'fill-extrusion',
        },
        {
          property: 'heightField',
          value: '*',
        },
      ],
    },
    {
      type: 'text',
      label: 'Height max',
      placeHolder: '0',
      property: 'extrusionFieldMaxValue',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'heightMethod',
          value: 'interpolate',
        },
        {
          property: 'layerType',
          value: 'fill-extrusion',
        },
      ],
    },
    {
      type: 'slider',
      min: 0.1,
      max: 1,
      step: 0.1,
      label: 'Fill opacity',
      property: 'layerOpacity',
      parents: ['layers'],
      group: 'Settings',
      dependsOn: [
        {
          property: 'layerType',
          value: 'fill-extrusion',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'switch',
      label: 'Enable popup',
      property: 'hasPopup',
      group: 'Popup',
      parents: ['layers'],
      size: 'small',
    },
    {
      type: 'textarea',
      label: 'Popup text',
      property: 'popupText',
      group: 'Popup',
      parents: ['layers'],
      placeHolder: 'E.g. Name: {location} ',
      dependsOn: [
        {
          property: 'contextFromPopup',
          value: '!',
        },
        {
          property: 'hasPopup',
          value: true,
        },
      ],
    },
    {
      type: 'slider',
      label: 'Popup Width',
      property: 'popupWidth',
      group: 'Popup',
      dependsOn: [
        {
          property: 'contextFromPopup',
          value: '*',
        },
        {
          property: 'hasPopup',
          value: true,
        },
      ],
      min: 1,
      max: 1000,
      step: 1,
      parents: ['layers'],
    },
    {
      type: 'switch',
      label: 'Enable component insert',
      property: 'contextFromPopup',
      group: 'Popup',
      parents: ['layers'],
      size: 'small',
    },
    {
      type: 'array',
      label: '',
      property: 'labels',
      itemLabel: 'Label',
      group: 'Labels',
      addButtonText: 'Add Label',
      groups: ['Label', 'Settings'],
      settings: [
        {
          label: 'Label',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      parents: ['layers'],
      collapseProperty: 'symbolField',
      collapseParent: 'labels',
      defaultValue: {
        symbolField: '',
        symbolPrefix: '',
        symbolSuffix: '',
        symbolSize: 8,
        symbolOffsetX: '',
        symbolOffsetY: '1.25',
        symbolColor: '#444444',
        symbolFontFamily: 'Arial Unicode MS Bold',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          property: 'symbolField',
          group: 'Label',
          showMeasures: true,
          showDimensions: true,
          parents: ['layers', 'labels'],
          placeHolder: 'Set Label Field ',
        },
        {
          type: 'select',
          label: 'Label color',
          property: 'symbolColor',
          group: 'Label',
          parents: ['layers', 'labels'],
          options: [
            { value: '#444444', label: 'Dark' },
            { value: '#ffffff', label: 'Light' },
          ],
        },
        {
          type: 'select',
          label: 'Font family',
          property: 'symbolFontFamily',
          group: 'Label',
          parents: ['layers', 'labels'],
          options: [
            { value: 'Arial Unicode MS Regular', label: 'Sans-serif Regular' },
            { value: 'Arial Unicode MS Bold', label: 'Sans-serif Bold' },
            { value: 'Droid Serif Bold', label: 'Serif Bold' },
            { value: 'Droid Serif Regular', label: 'Serif Regular' },
          ],
        },
        {
          type: 'slider',
          label: 'Label size',
          property: 'symbolSize',
          group: 'Label',
          min: 1,
          max: 20,
          step: 1,
          parents: ['layers', 'labels'],
          placeHolder: '0',
        },
        {
          type: 'text',
          label: 'Prefix',
          property: 'symbolPrefix',
          parents: ['layers', 'labels'],
          group: 'Options',
          placeHolder: 'Label Prefix',
        },
        {
          type: 'text',
          label: 'Suffix',
          property: 'symbolSuffix',
          parents: ['layers', 'labels'],
          group: 'Options',
          placeHolder: 'Label Suffix',
        },
        {
          type: 'text',
          label: 'Offset X',
          property: 'symbolOffsetX',
          parents: ['layers', 'labels'],
          group: 'Options',
          placeHolder: 'X Axis Label Offset',
        },
        {
          type: 'text',
          label: 'Offset Y',
          property: 'symbolOffsetY',
          parents: ['layers', 'labels'],
          group: 'Options',
          placeHolder: 'Y Axis Label Offset',
        },
        {
          type: 'switch',
          label: 'Allow label overlap',
          property: 'iconAllowOverlap',
          parents: ['layers', 'labels'],
          group: 'Options',
        },
      ],
    },
    {
      type: 'number',
      property: 'minZoom',
      label: 'Min zoom',
      min: 1,
      max: 22,
      placeHolder: '1',
      parents: ['layers'],
      group: 'Settings',
    },
    {
      type: 'number',
      property: 'maxZoom',
      min: 1,
      max: 22,
      placeHolder: '22',
      label: 'Max zoom',
      parents: ['layers'],
      group: 'Settings',
    },
    {
      type: 'switch',
      property: 'hasToggle',
      label: 'Enable toggle',
      parents: ['layers'],
      group: 'Options',
      size: 'small',
    },
    {
      type: 'switch',
      property: 'hasMenuItem',
      label: 'Add to menu',
      parents: ['layers'],
      group: 'Options',
      size: 'small',
    },
    {
      type: 'array',
      label: '',
      property: 'events',
      itemLabel: 'Event',
      collapseProperty: 'effect',
      collapseParent: 'events',
      addButtonText: 'Add Event',
      parents: ['layers'],
      group: 'Events',
      groups: ['Settings'],
      settings: [
        {
          label: 'Settings',
          dependsOn: [],
          collapse: false,
          showCollapse: false,
        },
      ],
      defaultValueType: 'array',
      fields: [
        {
          type: 'select',
          label: 'Trigger',
          property: 'type',
          parents: ['layers', 'events'],
          placeHolder: 'Select trigger',
          options: [
            { value: 'onLayerClick', label: 'On Layer click' },
            { value: 'onFilterChange', label: 'On Filter value change' },
          ],
          group: 'Settings',
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['layers', 'events'],
          placeHolder: 'Select event',
          options: [
            { value: 'updateFilterValue', label: 'Update Filter' },
            { value: 'clearFilterValue', label: 'Clear Filter' },
            { value: 'showLayout', label: 'Show Layout' },
            { value: 'hideLayout', label: 'Hide Layout' },
            { value: 'collapseLayout', label: 'Collapse Layout' },
            { value: 'uncollapseLayout', label: 'Uncollapse Layout' },
            { value: 'showLayer', label: 'Show Layer' },
            { value: 'hideLayer', label: 'Hide Layer' },
            { value: 'highlightCard', label: 'Highlight Card' },
            { value: 'centerMap', label: 'Center Map' },
            { value: 'flyTo', label: 'Fly to' },
            { value: 'webhook', label: 'Fire webhook' },
            { value: 'zoomToBounds', label: 'Zoom to bounds' },
          ],
          group: 'Settings',
          dependsOn: [{ property: 'type', value: 'onLayerClick' }],
        },
        {
          type: 'select',
          label: 'Method',
          property: 'method',
          parents: ['layers', 'events'],
          placeHolder: 'GET',
          options: [
            { value: 'GET', label: 'GET' },
            { value: 'POST', label: 'POST' },
            { value: 'PUT', label: 'PUT' },
            { value: 'DELETE', label: 'DELETE' },
          ],
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'textarea',
          label: 'Headers',
          property: 'headers',
          parents: ['layers', 'events'],
          placeHolder: `{}`,
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'text',
          label: 'URL',
          property: 'url',
          parents: ['layers', 'events'],
          placeHolder: 'https://',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'textarea',
          label: 'body',
          property: 'body',
          parents: ['layers', 'events'],
          placeHolder: '',
          group: 'Settings',
          dependsOn: [
            { property: 'effect', value: 'webhook' },
            { property: 'method', value: ['POST', 'PUT'] },
          ],
        },
        {
          type: 'text',
          label: 'Success message',
          property: 'message',
          parents: ['layers', 'events'],
          placeHolder: 'Webhook fired',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'webhook' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select component',
          componentType: 'map',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Latitude',
          property: 'lat',
          parents: ['layers', 'events'],
          placeHolder: 'Latitude',
          group: 'Settings',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: ['!', true] },
          ],
        },
        {
          type: 'dimension',
          label: 'Longitude',
          property: 'lng',
          parents: ['layers', 'events'],
          placeHolder: 'Longitude',
          group: 'Settings',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: ['!', true] },
          ],
        },
        {
          type: 'text',
          label: 'Longitude',
          property: 'customLng',
          parents: ['layers', 'events'],
          placeHolder: '36.817223',
          group: 'Settings',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: true },
          ],
        },
        {
          type: 'text',
          label: 'Latitude',
          property: 'customLat',
          parents: ['layers', 'events'],
          placeHolder: '-1.28638',
          group: 'Settings',
          dependsOn: [
            { property: 'effect', value: 'flyTo' },
            { property: 'customLatLng', value: true },
          ],
        },
        {
          type: 'switch',
          label: 'Use custom lat / lng',
          property: 'customLatLng',
          parents: ['layers', 'events'],
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Zoom',
          property: 'zoom',
          parents: ['layers', 'events'],
          min: 1,
          max: 20,
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Pitch',
          property: 'pitch',
          min: 0,
          max: 90,
          parents: ['layers', 'events'],
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Bearing',
          property: 'bearing',
          min: 0,
          max: 360,
          parents: ['layers', 'events'],
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Filter',
          property: 'filter',
          parents: ['layers', 'events'],
          placeHolder: 'Filter',
          group: 'Settings',
          dependsOn: [{ property: 'type', value: 'onFilterChange' }],
        },
        {
          type: 'dimension',
          label: 'Filter',
          property: 'filter',
          parents: ['layers', 'events'],
          placeHolder: 'Select filter',
          group: 'Settings',
          dependsOn: [{ property: 'type', value: 'onClearFilter' }],
        },

        {
          type: 'dimension',
          label: 'Bounds',
          property: 'bounds',
          parents: ['layers', 'events'],
          placeHolder: 'Select bounds',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'zoomToBounds' }],
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['layers', 'events'],
          placeHolder: 'Select event',
          options: [{ value: 'zoomToBounds', label: 'Zoom to bounds' }],
          group: 'Settings',
          dependsOn: [{ property: 'type', value: 'onClearFilter' }],
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['layers', 'events'],
          placeHolder: 'Select event',
          options: [
            { value: 'zoomToBounds', label: 'Zoom to bounds' },
            { value: 'zoomToFilteredBounds', label: 'Zoom to filtered bounds' },
            { value: 'flyTo', label: 'Fly to' },
          ],
          group: 'Settings',
          dependsOn: [{ property: 'type', value: 'onFilterChange' }],
        },
        {
          type: 'slider',
          label: 'Padding',
          property: 'padding',
          parents: ['layers', 'events'],
          min: 0,
          max: 200,
          placeHolder: '40',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'zoomToBounds' }],
        },
        {
          type: 'slider',
          label: 'Padding',
          property: 'padding',
          parents: ['layers', 'events'],
          min: 0,
          max: 200,
          placeHolder: '40',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'zoomToFilteredBounds' }],
        },
        {
          type: 'dimension',
          label: 'Filter',
          property: 'filter',
          parents: ['layers', 'events'],
          placeHolder: 'Select filter',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'updateFilterValue' }],
        },
        {
          type: 'dimension',
          label: 'Filter to clear',
          property: 'filter',
          parents: ['layers', 'events'],
          placeHolder: 'Select filter',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'clearFilterValue' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select component',
          componentType: 'layout',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'showLayout' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select component',
          componentType: 'layout',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'collapseLayout' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select component',
          componentType: 'layout',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'uncollapseLayout' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select component',
          componentType: 'card',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'highlightCard' }],
        },
        {
          type: 'dimension',
          label: 'Join field',
          property: 'value',
          parents: ['layers', 'events'],
          placeHolder: 'Select field',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'highlightCard' }],
        },
        {
          type: 'component',
          label: 'Component',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select component',
          componentType: 'layout',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'hideLayout' }],
        },
        {
          type: 'layer',
          label: 'Layer to update',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select layer',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'showLayer' }],
        },
        {
          type: 'layer',
          label: 'Layer to update',
          property: 'target',
          parents: ['layers', 'events'],
          placeHolder: 'Select layer',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'hideLayer' }],
        },
        {
          type: 'select',
          label: 'Select tab',
          property: 'tab',
          parents: ['layers', 'events'],
          placeHolder: 'Select tab',
          group: 'Settings',
          options: [
            { value: '1', label: 'Tab 1' },
            { value: '2', label: 'Tab 2' },
            { value: '3', label: 'Tab 3' },
          ],
          dependsOn: [{ property: 'target', value: 'layout' }],
        },
        {
          type: 'slider',
          label: 'Padding top',
          property: 'paddingTop',
          parents: ['layers', 'events'],
          placeHolder: '0',
          min: 0,
          max: 600,
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'centerMap' }],
        },
        {
          type: 'slider',
          label: 'Padding right',
          property: 'paddingRight',
          parents: ['layers', 'events'],
          placeHolder: '0',
          min: 0,
          max: 600,
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'centerMap' }],
        },
        {
          type: 'slider',
          label: 'Padding bottom',
          property: 'paddingBottom',
          parents: ['layers', 'events'],
          min: 0,
          max: 600,
          placeHolder: '0',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'centerMap' }],
        },
        {
          type: 'slider',
          label: 'Padding left',
          property: 'paddingLeft',
          parents: ['layers', 'events'],
          min: 0,
          max: 600,
          placeHolder: '0',
          group: 'Settings',
          dependsOn: [{ property: 'effect', value: 'centerMap' }],
        },
      ],
    },
    {
      type: 'array',
      label: '',
      property: 'filters',
      itemLabel: 'Filter',
      parents: ['layers'],
      group: 'Filters',
      addButtonText: 'Add Filter',
      groups: ['Filters'],
      collapseProperty: '1',
      collapseParent: 'filters',
      settings: [
        {
          label: 'Filters',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          property: '1',
          group: 'Filters',
          showMeasures: true,
          showDimensions: true,
          parents: ['layers', 'filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          property: '0',
          group: 'Filters',
          parents: ['layers', 'filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['layers', 'filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filters',
          property: '0',
          parents: ['layers', 'filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          property: '2',
          group: 'Filters',
          parents: ['layers', 'filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },

        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filters',
          property: '2',
          parents: ['layers', 'filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filters',
          property: '3',
          parents: ['layers', 'filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filters',
          property: '4',
          parents: ['layers', 'filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filters',
          property: '2',
          parents: ['layers', 'filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<', '<=', '>=', 'contains'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filters',
          property: '2',
          parents: ['layers', 'filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', '<=', '>=', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          label: 'Inherit filter value',
          property: 'inherit',
          group: 'Options',
          parents: ['layers', 'filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          property: 'expose',
          parents: ['layers', 'filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          property: 'multiple',
          parents: ['layers', 'filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          property: 'slider',
          parents: ['layers', 'filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Options',
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['layers', 'filters'],
        },

        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',

          property: 'showTextField',
          parents: ['layers', 'filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<', '<=', '>='],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          property: 'actualDateFormat',
          parents: ['layers', 'filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },

        {
          type: 'text',
          label: 'Filter name',
          group: 'Options',
          property: 'name',
          parents: ['layers', 'filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Options',
          property: 'cols',
          parents: ['layers', 'filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable cascading filters',
      property: 'cascade',
      group: 'Filters',
      parents: ['layers'],
      size: 'small',
    },
  ],
};
