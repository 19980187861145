import React, { useState, useEffect } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { Input, Button, message, Col } from 'antd';
import { AkukoAPIService } from '../../../services/serviceClass';
import {
  REFRESHABLE_DATABASE_SOURCES_TYPES,
  SOURCES_API,
  TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL,
} from '../../../configs/env';
import { ERROR_GENERIC } from '../../../configs/constants';
import { CACHE_CLEARED_MESSAGE, CLEAR_CACHE_BUTTON_TEXT } from '../../../configs/constants';
import { actionSourceEdit, actionSourceSave } from '../actions';
import { LoadingOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils';
import {
  Source,
  SourceRefreshRequestPayload,
  sourceRefreshStatusTypes,
} from '../../../configs/component-types';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { startAsycJobForSourceRefresh } from '../helpers/helpers';
import { SourceRefreshProgressModal } from './SourceRefreshScheduler/ProgressModal';

export interface SourceTitleInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const SourceTitleInput: React.FC<SourceTitleInputProps> = (props: SourceTitleInputProps) => {
  const { sourceActionSaveCreator } = props;
  const source = useSelector((state: Dictionary) => state.source);
  const dispatch = useDispatch();
  const [value, setValue] = useState(source.name);
  const [descriptionValue, setDescriptionValue] = useState(source.config?.description);
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const [refreshingData, setRefreshingData] = useState(false);
  const [progress, setProgress] = useState<Dictionary>({});
  const sourceIsRefreshable =
    source?.type !== 'onadata' &&
    source?.dimensions?.length !== 0 &&
    source?.space_id !== null &&
    (source?.url != null ||
      source?.config?.['sheet-url'] !== undefined ||
      REFRESHABLE_DATABASE_SOURCES_TYPES.includes(source?.type));

  const handleSourceRefresh = ({
    payload,
    asyncTaskName,
  }: {
    payload: SourceRefreshRequestPayload;
    asyncTaskName: string;
  }) => {
    triggerAsycSourceOperation({
      asynFunc: startAsycJobForSourceRefresh,
      asynFuncArgs: payload,
      asyncTaskName: asyncTaskName,
      setAsyncTaskProgressData: setProgress,
      setLoading: setRefreshingData,
      webSocketUrl: TEMPORAL_SOURCES_REFRESH_WORKER_WEB_SOCKET_URL,
    });
  };

  const getSourceFromApiAndUpdateRedux = async () => {
    const sourceGetService = new AkukoAPIService(SOURCES_API, 'source');
    const res = await sourceGetService.read(`${source?.uuid}`);
    const sourceObj = res as Source;
    if (sourceActionSaveCreator) {
      dispatch(
        /* @ts-ignore */
        sourceActionSaveCreator({
          ...sourceObj,
        })
      );
    }
  };

  useEffect(() => {
    if (progress?.stage === sourceRefreshStatusTypes.DONE) {
      (async () => {
        await getSourceFromApiAndUpdateRedux();
      })();
      setRefreshingData(false);
      setProgress({});
      message.success('Source refreshed successfully');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  useEffect(() => {
    setValue(source.name);
  }, [source.name]);

  useEffect(() => {
    setDescriptionValue(source.config?.description);
  }, [source.config?.description]);

  return (
    <div className="input-group">
      <div className="input-field">
        <label>Name</label>
        <Input
          size="large"
          disabled={source.public ? true : false}
          value={value}
          onChange={(e: Dictionary) => {
            setValue(e.target.value);
          }}
          onBlur={() => {
            dispatch(
              actionSourceEdit({
                name: value,
              })
            );
          }}
        />
      </div>
      <div className="input-field">
        <label>Description</label>
        <Input.TextArea
          placeholder="Source Description"
          disabled={source.public ? true : false}
          value={descriptionValue || ''}
          onChange={(e: Dictionary) => {
            setDescriptionValue(e.target.value);
          }}
          onBlur={() => {
            dispatch(
              actionSourceEdit({
                config: {
                  ...source.config,
                  description: descriptionValue,
                },
              })
            );
          }}
        />
      </div>
      {source.cube ? (
        <Col span={24}>
          <label>Cube Name</label>
          <span>{`${source.cube}`}</span>
        </Col>
      ) : null}
      {source.dimensions?.length > 0 && (
        <div className="btn-field">
          <Button
            disabled={source.public ? true : false}
            type="primary"
            onClick={() => {
              const service = new AkukoAPIService(SOURCES_API, `source/${source.uuid}`);
              service.update(source).then(() => {
                message.success('Source saved');
              });
            }}
          >
            Save
          </Button>{' '}
          {sourceIsRefreshable && (
            <>
              {refreshingData ? (
                <SourceRefreshProgressModal
                  title="Source refresh progress"
                  progress={progress}
                  isModalOpen={refreshingData}
                  handleModalCancel={() => setRefreshingData(false)}
                />
              ) : null}{' '}
              <Button
                type="primary"
                disabled={refreshingData}
                onClick={() => {
                  setRefreshingData(true);
                  handleSourceRefresh({
                    payload: {
                      sourceId: source?.uuid,
                    },
                    asyncTaskName: 'Source refresh',
                  });
                }}
              >
                {refreshingData && <LoadingOutlined />} Refresh
              </Button>
            </>
          )}{' '}
          <Button
            disabled={source.public ? true : false || loading}
            onClick={() => {
              setLoading(true);
              dispatch(
                actionSourceEdit({
                  refresh_key: source.refresh_key + 1,
                  schema: { ...source.schema, refreshKey: source.refresh_key + 1 },
                })
              );
              setTimeout(() => {
                const service = new AkukoAPIService(
                  SOURCES_API,
                  `source/cube/clear_cache/${source.uuid}`
                );
                const currentState = store.getState();
                service
                /* @ts-ignore */
                  .update({ refresh_key: currentState.source.refresh_key })
                  .then(() => {
                    message.success(CACHE_CLEARED_MESSAGE);
                    setLoading(false);
                  })
                  .catch((error) => {
                    message.error(error.message || ERROR_GENERIC);
                    setLoading(false);
                  });
              }, 1000);
            }}
          >
            {loading && <LoadingOutlined />} {CLEAR_CACHE_BUTTON_TEXT}
          </Button>
        </div>
      )}
    </div>
  );
};

SourceTitleInput.defaultProps = defaultProps;
export { SourceTitleInput };
