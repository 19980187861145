import React from 'react';
import { Modal, Steps } from 'antd';
import { LoadingOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import {
  onadataSourceJobStatusTypes,
  onadataSourceJobStatusTypesDescriptions,
} from '../../constants';
import { Dictionary } from '@onaio/utils';

export interface ProgressModalProps {
  progress: Dictionary;
  current: number;
  isModalOpen: boolean;
  handleModalCancel: () => void;
  title: string;
}

const ProgressModal: React.FC<ProgressModalProps> = (props: ProgressModalProps) => {
  const { progress, current, isModalOpen, handleModalCancel, title } = props;
  const { Step } = Steps;

  const handleStepTitle = (stageName: string, nextStages: Array<string>) => {
    if (progress?.stage === undefined) {
      if (
        stageName === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GETTING_ONA_FORM_DATA]
      ) {
        return <span>In Progress</span>;
      }
      return <span>Waiting</span>;
    } else {
      if (progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE]) {
        return <span>Finished</span>;
      } else {
        if (progress?.stage === stageName) {
          return <span>In Progress</span>;
        } else {
          if (nextStages.includes(progress?.stage)) {
            return <span>Finished</span>;
          } else {
            return <span>Waiting</span>;
          }
        }
      }
    }
  };
  const handleStepIcon = (stageName: string, nextStages: Array<string>) => {
    if (
      progress?.stage === undefined &&
      stageName === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GETTING_ONA_FORM_DATA]
    ) {
      return <LoadingOutlined />;
    } else {
      if (progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE]) {
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      } else {
        if (progress?.stage === stageName) {
          return <LoadingOutlined />;
        } else {
          if (nextStages.includes(progress?.stage)) {
            return <CheckCircleTwoTone twoToneColor="#52c41a" />;
          } else {
            return null;
          }
        }
      }
    }
  };
  return (
    <Modal
      title={title}
      visible={isModalOpen}
      onOk={handleModalCancel}
      onCancel={handleModalCancel}
      footer={null}
      width={400}
    >
      <Steps direction="vertical" size="small" current={current}>
        <Step
          icon={handleStepIcon(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GETTING_ONA_FORM_DATA],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.RETRIEVING_EXPORT_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GENERATING_SCHEMAS],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          title={handleStepTitle(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GETTING_ONA_FORM_DATA],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.RETRIEVING_EXPORT_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GENERATING_SCHEMAS],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          description={onadataSourceJobStatusTypesDescriptions.GETTING_ONA_FORM_DATA}
        />
        <Step
          icon={handleStepIcon(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.RETRIEVING_EXPORT_DATA],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GENERATING_SCHEMAS],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          title={handleStepTitle(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.RETRIEVING_EXPORT_DATA],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GENERATING_SCHEMAS],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          description={onadataSourceJobStatusTypesDescriptions.RETRIEVING_EXPORT_DATA}
        />
        <Step
          icon={handleStepIcon(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GENERATING_SCHEMAS],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          title={handleStepTitle(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.GENERATING_SCHEMAS],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          description={onadataSourceJobStatusTypesDescriptions.GENERATING_SCHEMAS}
        />
        <Step
          icon={handleStepIcon(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          title={handleStepTitle(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.UPLOADING_DATA],
            [
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
              onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE],
            ]
          )}
          description={onadataSourceJobStatusTypesDescriptions.UPLOADING_DATA}
        />
        <Step
          icon={handleStepIcon(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
            [onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE]]
          )}
          title={handleStepTitle(
            onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.SOURCE_CUBE_CREATION],
            [onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE]]
          )}
          description={onadataSourceJobStatusTypesDescriptions.SOURCE_CUBE_CREATION}
        />
        <Step
          icon={handleStepIcon(onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE], [])}
          title={handleStepTitle(onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE], [])}
          description={onadataSourceJobStatusTypesDescriptions.DONE}
        />
      </Steps>
    </Modal>
  );
};

export { ProgressModal };
