import { Dictionary } from '@onaio/utils';
import { Store } from 'redux';

/**
 * get drawerOpen
 *
 * @param {Partial<Store>} state redux store
 * @returns {boolean} drawer open status
 */
export const drawerOpenSelector = (state: Partial<Store>): boolean => {
  return (state as Dictionary).config.drawerOpen;
};

/**
 * get active component index
 *
 * @param {Partial<Store>} state redux store
 * @returns {number} component index
 */
export const activeComponentIndexSelector = (state: Partial<Store>): number => {
  return (state as Dictionary).config.activeComponentIndex;
};
