import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, Form, Upload, Switch, Input, Breadcrumb, Button, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { withRouter } from 'react-router-dom';
import {
  actionAccountSpacesGet,
  actionAccountSpaceAdd,
  actionAccountSpaceGet,
  actionPostsGet,
  actionSpaceLogoEdit,
  actionSpaceNameEdit,
} from '../../../actions';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API, POSTS_API, UPLOADS_API } from '../../../../../configs/env';
import { ERROR_GENERIC } from '../../../../../configs/constants';
const { TextArea } = Input;

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return state;
  }
  return null;
};

const mapDispatchToProps = {
  actionAccountSpaceAdd,
  actionAccountSpacesGet,
  actionAccountSpaceGet,
  actionPostsGet,
  actionSpaceNameEdit,
  actionSpaceLogoEdit,
};

const SpaceSettings = (props) => {
  const params = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const service = new AkukoAPIService(IDENTITY_API, 'space');
    service
      .read(params.id)
      .then((space) => {
        props.actionAccountSpaceGet(space);

        const service = new AkukoAPIService(POSTS_API, `post/space/${params.id}`);
        return service.list();
      })
      .then((posts) => {
        props.actionPostsGet(posts);
      })
      .catch((error) => {
        if (error?.message === 'Unauthorized') {
          const service = new AkukoAPIService(POSTS_API, `post/space/${params.id}/public`);
          service.list()
          .then((posts) => {
            props.actionPostsGet(posts);
          });
        } else {
          message.error(error?.message || ERROR_GENERIC)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const uploadButton = (
    <div className="space-logo-uploader">
    {props?.space?.config?.logo_file && (
      <>
        <img
          style={{ height: '40px' }}
          src={`https://assets.akuko.io/${props?.space?.config?.logo_file}`}
        />
      </>
    )}
    <div style={{ marginTop: 8 }}>{uploading ? `Uploading...` : 'Upload'}</div>
  </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const service = new AkukoAPIService(UPLOADS_API, `images`);
      const response = await service.create(formData);
      props.actionSpaceLogoEdit({
        value: response?.image_id,
      });
      const space = Object.assign({}, props.space);
      const config = Object.assign({}, space.config);
      config.logo_file = response?.image_id;
      space.config = config;
      const updateSpaceService = new AkukoAPIService(IDENTITY_API, `space/${space.uuid}`);
      await updateSpaceService.update(space);
      message.success('Space logo updated');
      onSuccess(response);
    } catch (error) {
      onError(error);
      message.error(error.message || ERROR_GENERIC);
    } finally {
      setUploading(false);
    }
  };

  if (props.space.id) {
    return (
      <div className="dashboard">
        <div className="spaces">
          <div className="breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>{props.space.account_id}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/account/${props.space.account_id}`}>spaces</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/space/${props.space.id}`}>{props.space.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>settings</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h1>Settings</h1>
          <Row gutter={40}>
            <Col xs={12}>
              <Form
                layout="vertical"
                onFinish={(values) => {
                  setShowLoader(true);
                  props.actionSpaceNameEdit({
                    value: values.space_name,
                  });
                  const space = Object.assign({}, props.space);
                  space.name = values.space_name;
                  const config = Object.assign({}, space.config);
                  config.description = values.space_description;
                  config.isPublic = values.isPublic;
                  config.website = values.website;
                  config.showContributors = values.showContributors;
                  space.config = config;
                  const service = new AkukoAPIService(IDENTITY_API, `space/${space.uuid}`);
                  service
                    .update(space)
                    .then(() => {
                      message.success('Space settings updated');
                    })
                    .catch((error) => {
                      message.error(error.message || ERROR_GENERIC);
                    }).finally(() => {
                      setShowLoader(false);
                    })
                }}
              >
                <Form.Item
                  name="space_name"
                  label="Space name"
                  initialValue={props.space.name}
                  rules={[{ required: true }]}
                >
                  <Input size="large" disabled={showLoader} />
                </Form.Item>
                <Form.Item name="space_url" label="Space URL" initialValue={props.space.id}>
                  <Input
                    disabled
                    size="large"
                    addonBefore="https://"
                    addonAfter=".akuko.io"
                    placeholder="my-space"
                  />
                </Form.Item>
                <Form.Item
                  initialValue={props.space.config.description}
                  name="space_description"
                  label="Space description"
                >
                  <TextArea rows={8} disabled={showLoader} />
                </Form.Item>
                <Form.Item initialValue={props.space.config.website} name="website" label="Website">
                  <Input disabled={showLoader} />
                </Form.Item>
                <Form.Item
                  name="isPublic"
                  valuePropName="checked"
                  initialValue={props.space.config.isPublic}
                  label="Public Space"
                  help={
                    'This will allow anyone to view your space index page. You will still need to make your Posts public.'
                  }
                >
                  <Switch size="large" disabled={showLoader} />
                </Form.Item>
                <Form.Item
                  name="showContributors"
                  valuePropName="checked"
                  initialValue={props.space.config.showContributors}
                  label="Show contributors"
                  help={'Shows a contributors block on your space index.'}
                >
                  <Switch size="large" disabled={showLoader} />
                </Form.Item>
                {/*}
                <Form.Item
                  name="space_archive"
                  label="Archive Space"
                  help={
                    'This will deactivate the space and hide it from your dashboard. Posts in this space will no longer be accessible.'
                  }
                >
                  <Switch disabled size="large" />
                </Form.Item>
                {*/}
                <br />
                <br />
                <Button type="primary" size="large" htmlType="submit" disabled={showLoader}>
                { showLoader && <LoadingOutlined /> } { showLoader ? 'Updating space...' : 'Update Space' }
                </Button>
              </Form>
            </Col>
            <Col sm={8}>
              <label>Space logo</label>
              <ImgCrop>
                <Upload
                  disabled={showLoader || uploading}
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  customRequest={customRequest}
                >
                  {uploadButton}
                </Upload>
            </ImgCrop>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SpaceSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(SpaceSettings);

export default withRouter(SpaceSettingsContainer);

{
  /*}
rules={[
  {
    required: true,
    message: 'Space URL is not available',
    validator: () => {
      return Promise.reject();
    },
  },
]}
{*/
}
