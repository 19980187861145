import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { getComponentById, getComponentSource } from '../../../reducers/selectors/post';
import { GenericComponent } from '../Component';
import { Row, Col } from 'antd';
import { useFetchComponentData } from '../hooks';
import { getContextRow, formatValue } from '../helpers';
import { NumberComponent } from '../../../configs/component-types';
import { useMemo } from 'react';
import { Donut } from './components/donut';
import { Icon } from './components/icon';
import { LoadingOutlined, NumberOutlined } from '@ant-design/icons';
import './style.css';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

export interface NumberProps {
  componentIndex: number; // index of the post component
  componentId: string; // id of the post component
  isEmbed: boolean;
  dataRow?: Dictionary;
}

const BigNumber: React.FC<NumberProps> = React.memo((props: NumberProps) => {
  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const { componentIndex, componentId, isEmbed, dataRow } = props;

  const component = useSelector((state) => {
    /* @ts-ignore */
    const number = selectComponentById(state, { componentId });

    if (!number) {
      return undefined;
    }
    return number as NumberComponent;
  });

  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));

  const [isLoading, data] = useFetchComponentData(componentId);

  const { filteredData } = getContextRow(dataRow, data, component, component?.properties?.[0]);

  const genericComponentProps = {
    dataRow,
    componentId,
    componentIndex,
    isEmbed: isEmbed,
    drawerTitle: 'Number',
  };

  return (
    <Row gutter={10}>
      <Col xs={24} sm={24}>
        <GenericComponent {...genericComponentProps}>
          {isLoading && (
            <div className="component-loader">
              <LoadingOutlined />
            </div>
          )}
          {filteredData && filteredData[0] && component?.properties[0] && (
            <>
              {component?.format === 'donut' && (
                <Donut
                  dataRow={dataRow}
                  componentIndex={componentIndex}
                  componentId={componentId}
                />
              )}
              {component?.format === 'icon' && (
                <Icon dataRow={dataRow} componentIndex={componentIndex} componentId={componentId} />
              )}
              {!component?.format && (
                <div
                  className="component--number"
                  style={{
                    color: component?.color || '#111111',
                    fontSize: component?.fontSize || `30px`,
                    fontFamily: component?.fontFamily || `Poppins`,
                    background: component?.backgroundColor || 'rgba(0,0,0,0.02)',
                  }}
                >
                  <div 
                    className="component--number-value"
                  >
                    {formatValue(
                      component?.properties[0],
                      filteredData[0][`${component?.cube}.${component?.properties[0].value}`],
                      source
                    )}
                  </div>
                </div>
              )}
              {component.format === 'number' && (
                <div
                  className="component--number"
                  style={{
                    color: component?.color || '#111111',
                    fontSize: component?.fontSize || `30px`,
                    fontFamily: component?.fontFamily || `Poppins`,
                    background: component?.backgroundColor || 'rgba(0,0,0,0.02)',
                  }}
                >
                  <div 
                    className="component--number-value"
                  >
                    {formatValue(
                      component?.properties[0],
                      filteredData[0][`${component?.cube}.${component?.properties[0].value}`],
                      source
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          
          {!component?.properties[0] && (
            <div className="component-empty">
              <NumberOutlined />
            </div>
          )}
        </GenericComponent>
      </Col>
    </Row>
  );
});

BigNumber.displayName = 'Number';

export { BigNumber };
