/* eslint-disable @typescript-eslint/no-explicit-any */
import { message, FormInstance } from 'antd';
import { Dictionary } from '@onaio/utils';

import {
  PostScheduleSendRequestPayload,
  postScheduleSendJobStatusTypes,
} from '../../../../configs/component-types';
import {
  startAsycJobForCreatingPostScheduleSend,
  startAsycPostExport,
} from '../../../../components/Post/PostComponents/helpers/helpers';
import { POSTS_API, TEMPORAL_POST_WORKER_WEB_SOCKET_URL } from '../../../../configs/env';
import { triggerAsycPostOperation } from '../../../Post/PostComponents/components/PostWebsocketsUpdatesHelpers';
import { Dispatch } from 'redux';
import { AkukoAPIService } from '../../../../services/serviceClass';
import { ERROR_GENERIC } from '../../../../configs/constants';

export enum AsyncTaskTypes {
  SCHEDULE_SEND = 'Schedule Send',
  TEST_SCHEDULE = 'Test Schedule',
}

interface ScheduleHandlerInterface {
  setAsyncTaskType: (arg: AsyncTaskTypes) => void;
  setLoading: (arg: boolean) => void;
  setProgress: (arg: Dictionary) => void;
}

interface ScheduleSendHandlerInterface extends ScheduleHandlerInterface {
  payload: PostScheduleSendRequestPayload;
}

interface TestScheduleSendHandlerInterface extends ScheduleHandlerInterface {
  payload: Dictionary;
}

export type PickerType = 'daily' | 'weekly' | 'monthly' | 'cronString';

export const handlePostScheduleSend = ({
  payload,
  setAsyncTaskType,
  setLoading,
  setProgress,
}: ScheduleSendHandlerInterface): void => {
  setAsyncTaskType(AsyncTaskTypes.SCHEDULE_SEND);
  setLoading(true);
  triggerAsycPostOperation({
    asynFunc: startAsycJobForCreatingPostScheduleSend,
    asynFuncArgs: payload,
    asyncTaskName: AsyncTaskTypes.SCHEDULE_SEND,
    setAsyncTaskProgressData: setProgress,
    setLoading: setLoading,
    webSocketUrl: TEMPORAL_POST_WORKER_WEB_SOCKET_URL,
  });
};

export const handleTestSchedule = ({
  payload,
  setAsyncTaskType,
  setLoading,
  setProgress,
}: TestScheduleSendHandlerInterface): void => {
  setAsyncTaskType(AsyncTaskTypes.TEST_SCHEDULE);
  setLoading(true);
  triggerAsycPostOperation({
    asynFunc: startAsycPostExport,
    asynFuncArgs: {
      postExportParams: payload,
    },
    asyncTaskName: AsyncTaskTypes.TEST_SCHEDULE,
    setAsyncTaskProgressData: setProgress,
    setLoading: setLoading,
    webSocketUrl: TEMPORAL_POST_WORKER_WEB_SOCKET_URL,
  });
};

export const range = (start: number, end: number, step = 1): number[] => {
  const output = [];
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const buildPostUrl = ({
  filters,
  postId,
}: {
  filters: string[];
  postId: string;
}): string => {
  let postUrl = `${window.location.origin}/post/${postId}`;
  if (filters.length > 0) {
    postUrl = `${postUrl}#${filters.join('&')}`;
  }
  return postUrl;
};

export const createScheduleConfigFromScheduleType = ({
  values,
}: {
  values: Dictionary;
}): Dictionary => {
  let schedule_config = {};
  const type = values?.type as PickerType;
  switch (type) {
    case 'daily':
      schedule_config = {
        calendars: [
          {
            hour: values?.schedule_daily?.$H,
            minute: values?.schedule_daily?.$m,
            comment: values?.schedule_comment,
          },
        ],
      };
      break;
    case 'weekly':
      schedule_config = {
        calendars: [
          {
            dayOfWeek:
              Array.isArray(values.day) === true
                ? values.day.map((i: string) => i.toUpperCase())
                : `${values?.day?.toUpperCase()}`,
            hour: values?.schedule_weekly?.$H,
            minute: values?.schedule_weekly?.$m,
            comment: values?.schedule_comment,
          },
        ],
      };
      break;
    case 'monthly':
      schedule_config = {
        calendars: [
          {
            month: values?.month,
            dayOfMonth: values?.dayOfMonth,
            hour: values?.schedule_weekly?.$H,
            minute: values?.schedule_weekly?.$m,
            comment: values?.schedule_comment,
          },
        ],
      };
      break;
    case 'cronString':
      schedule_config = {
        cronExpressions: [values?.schedule_cron_expression],
      };
      break;
    default:
      break;
  }

  return schedule_config;
};

export const updateProgressData = ({
  progress,
  loading,
  asyncTaskType,
  setProgress,
  setAsyncTaskType,
  postActionSaveCreator,
  dispatch,
  post,
  setType,
  setEmails,
  setPostFilters,
  form,
}: {
  progress:
    | {
        stage: postScheduleSendJobStatusTypes;
        data: Dictionary[];
        errors: Dictionary[];
        taskName: string;
      }
    | Dictionary;
  loading: boolean;
  asyncTaskType: AsyncTaskTypes | undefined;
  setAsyncTaskType: (arg: AsyncTaskTypes | undefined) => void;
  setProgress: (arg: Dictionary) => void;
  postActionSaveCreator?: (obj: Dictionary) => void;
  dispatch: Dispatch<any>;
  post: Dictionary;
  setType: (arg: undefined) => void;
  setEmails: (arg: Dictionary) => void;
  setPostFilters: (arg: []) => void;
  form: FormInstance<any>;
}): void => {
  switch (progress?.stage) {
    case loading === false && postScheduleSendJobStatusTypes.DONE:
      if (asyncTaskType === AsyncTaskTypes.TEST_SCHEDULE) {
        setProgress({});
        setAsyncTaskType(undefined);
        message.success('Test successful! Please check the inbox for the emails provided.');
        break;
      }
      if (postActionSaveCreator) {
        dispatch(
          postActionSaveCreator({
            ...post,
            config: progress?.data[0]?.config,
          })
        );
      }
      setProgress({});
      setAsyncTaskType(undefined);
      setType(undefined);
      setEmails({});
      setPostFilters([]);
      message.success('Post scheduled created successfully');
      form.resetFields();
      break;
    default:
      break;
  }
};

export const handlePostScheduleDelete = ({
  scheduleId,
  setShowDeleteSpinner,
  postActionSaveCreator,
  dispatch,
  post,
}: {
  scheduleId: string;
  setShowDeleteSpinner: (arg: boolean) => void;
  postActionSaveCreator?: (obj: Dictionary) => void;
  dispatch: Dispatch<any>;
  post: Dictionary;
}): void => {
  setShowDeleteSpinner(true);
  const service = new AkukoAPIService(POSTS_API, `export/schedules/${post?.uuid}`);
  service
    .delete({
      schedule_id: scheduleId,
    })
    .then(() => {
      if (postActionSaveCreator) {
        const postExportSchedules = {
          ...post.config.export_schedules,
        };
        delete postExportSchedules[scheduleId];
        dispatch(
          postActionSaveCreator({
            ...post,
            config: {
              ...post?.config,
              export_schedules: {
                ...postExportSchedules,
              },
            },
          })
        );
      }
      message.success('Post schedule deleted');
      setShowDeleteSpinner(false);
    })
    .catch((err) => {
      if (err.message) {
        message.error(err.message);
      } else {
        message.error(ERROR_GENERIC);
      }
      setShowDeleteSpinner(false);
    });
};

export const onFinishHandler = ({
  values,
  post,
  type,
  postUrl,
  setAsyncTaskType,
  setProgress,
  scheduleEmails,
  postFilters,
  setLoading,
  setEmails,
}: {
  values: Dictionary;
  postUrl: string;
  post: Dictionary;
  type: PickerType | undefined;
  setAsyncTaskType: (arg: AsyncTaskTypes | undefined) => void;
  setProgress: (arg: Dictionary) => void;
  setEmails: (arg: Dictionary) => void;
  setLoading: (arg: boolean) => void;
  scheduleEmails: Dictionary;
  postFilters: string[];
}): void => {
  try {
    const schedule_config = createScheduleConfigFromScheduleType({
      values: values,
    });
    handlePostScheduleSend({
      payload: {
        postId: post?.uuid,
        payload: {
          schedule_config,
          schedule_type: type,
          schedule_name: values?.schedule_name,
          schedule_comment: values?.schedule_comment,
          workflow_args: {
            url: postUrl,
            format: values?.report_format,
            download_file_name: `${post?.title?.split(' ').join('')}.${values?.report_format}`,
            selector: '.post--content',
            emails: Object.values(scheduleEmails)
              .filter((email) => email !== '')
              .map((email: string) => email),
            post_filters: postFilters,
          },
        },
      },
      setAsyncTaskType: setAsyncTaskType,
      setLoading: setLoading,
      setProgress: setProgress,
    });
  } catch (err) {
    const error = new Error(err as string);
    setEmails({});
    setProgress({});
    message.error(`Failed to create schedule, ${error?.message}`);
  }
};
