import React, { useEffect, useState } from 'react';
import { message, Space } from 'antd';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { LoadingOutlined, ExportOutlined } from '@ant-design/icons';

import { PostExportParams, postExportJobStatusTypes } from '../../../../../configs/component-types';
import { TEMPORAL_POST_WORKER_WEB_SOCKET_URL } from '../../../../../configs/env';
import { startAsycPostExport } from '../helpers';
import { Dictionary } from '@onaio/utils/dist/types/types';

interface ExportPostComponentProps {
  postUrl: string;
  componentName: string;
  selector: string;
  format: string;
  exportComponent: boolean;
  setExportPostComponent: (state: boolean) => void;
  postId: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExportPostComponent = (props: ExportPostComponentProps) => {
  const { postUrl, componentName, selector, format, setExportPostComponent, exportComponent, postId } =
    props;
  const [jobUuid, setJobUuid] = useState('');

  useEffect(() => {
    if (exportComponent === true) {
      triggerAsycPostExport({
        post_id: postId,
        post_url: postUrl,
        selector,
        format,
        download_file_name: `${componentName?.split(' ').join('')}.${format}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportComponent, postUrl, selector, format]);

  const checkExportProgress = async (
    jobId: string,
    socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined,
    download_file_name: string
  ): Promise<void> => {
    socket?.on(
      jobId,
      async (data: {
        progress: {
          stage: postExportJobStatusTypes;
          data: Dictionary[];
          errors: Dictionary[];
        };
      }) => {
        let postBlobObjectUrl;
        if (data?.progress?.stage === 'DONE') {
          try {
            const downloadUrl = data?.progress?.data[0]?.renderUrl;
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', download_file_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (err) {
            message.error('Error exporting post, please try again', 2.5);
          } finally {
            if (postBlobObjectUrl !== undefined) {
              URL.revokeObjectURL(postBlobObjectUrl);
            }
            setExportPostComponent(false);
            setJobUuid('');
            socket?.disconnect();
          }
        }
        if (data?.progress?.errors.length !== 0) {
          message.error('Error exporting post, please try again', 2.5);
          setExportPostComponent(false);
          setJobUuid('');
          socket?.disconnect();
        }
      }
    );
  };

  const triggerAsycPostExport = (query: PostExportParams): void => {
    const socket = io(TEMPORAL_POST_WORKER_WEB_SOCKET_URL, {
      path: '/v1/socket.io',
      withCredentials: true,
    });
    socket.on('connect', async () => {
      if (jobUuid !== '') {
        await checkExportProgress(jobUuid, socket, query?.download_file_name);
      } else {
        // start asyc job for exporting post
        startAsycPostExport({
          postExportParams: query,
        }).then(async (res) => {
          const data = res as Dictionary;
          setJobUuid(data.job_uuid);
          await checkExportProgress(data.job_uuid, socket, query?.download_file_name);
        });
      }
      // eslint-disable-next-line no-console
      console.log(
        `client connected to websock server ${TEMPORAL_POST_WORKER_WEB_SOCKET_URL}, socket id: ${socket.id}`
      );
    });
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on('disconnect', () => {
      // eslint-disable-next-line no-console
      console.log(`client disconnected from websock server ${TEMPORAL_POST_WORKER_WEB_SOCKET_URL}`);
    });
  };

  return (
    <>
      <Space
        onClick={(e) => {
          setExportPostComponent(true);
          e.stopPropagation();
        }}
      >
        {exportComponent ? <LoadingOutlined /> : <ExportOutlined />}
        {exportComponent ? 'Preparing export...' : `Export ${format}`}
      </Space>
    </>
  );
};

export { ExportPostComponent };
