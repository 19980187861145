import React, { useEffect, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import './SymbolPicker.css';
import { useState } from 'react';
import { Empty, Input, Row, Tabs } from 'antd';
import { getIcons as getOchaIcons } from './ocha-icons';
import { getIcons as getMakiIcons } from './maki-icons';
import { getIcons as getUnicefIcons } from './unicef-icons';
import { getIcons as getGGGIIcons } from './gggi';
import { Symbol } from './symbol';
import useDebounce from '../../../../../helpers/use-debounce';

const { TabPane } = Tabs;
const defaultOchaIcons = getOchaIcons();
const defaultMakiIcons = getMakiIcons();
const defaultUnicefIcons = getUnicefIcons();
const defaultGGGIIcons = getGGGIIcons();
export interface Icon {
  id: string;
  src: string;
  title: string;
}

type SetIcons = (icons: Icon[]) => void;

/** interface for component props */
export interface SymbolPickerProps {
  onChangeComplete: (icon: Icon) => void;
  excludeTabs?: string[];
}

const SymbolPicker: React.FC<SymbolPickerProps> = (
  props: SymbolPickerProps
) => {
  const { onChangeComplete, excludeTabs } = props;
  const [searchText, setSearchText] = useState('');
  const [ochaIcons, setOchaIcons] = useState(defaultOchaIcons);
  const [makiIcons, setMakiIcons] = useState(defaultMakiIcons);
  const [unicefIcons, setUnicefIcons] = useState(defaultUnicefIcons);
  const [gggiIcons, setGggiIcons] = useState(defaultGGGIIcons);
  const [activeKey, setActiveKey] = useState('ocha');

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value.toLowerCase());
  };

  // Define the union type for activeKey
  type ActiveKey = 'ocha' | 'maki' | 'unicef' | 'gggi';

  // Type definition for the icon map
  type IconMap = {
    [key in ActiveKey]: {
      defaultIcons: Icon[];
      setIcons: SetIcons;
    };
  };

  // Map activeKey to corresponding icon set and state updater
  const iconMap: IconMap = {
    ocha: { defaultIcons: defaultOchaIcons, setIcons: setOchaIcons },
    maki: { defaultIcons: defaultMakiIcons, setIcons: setMakiIcons },
    unicef: { defaultIcons: defaultUnicefIcons, setIcons: setUnicefIcons },
    gggi: { defaultIcons: defaultGGGIIcons, setIcons: setGggiIcons },
  };

  const debouncedSearchText = useDebounce(searchText, 1000);

  const filterIcons = (
    defaultIcons: Icon[],
    debouncedSearchText: string,
    setIcons: SetIcons
  ) => {
    const iconsToSet = debouncedSearchText
      ? defaultIcons.filter((icon) =>
          icon.title.toLowerCase().includes(debouncedSearchText)
        )
      : defaultIcons;
    setIcons(iconsToSet);
  };

  useEffect(() => {
    const activeIconSet = iconMap[activeKey as ActiveKey];

    if (activeIconSet) {
      filterIcons(
        activeIconSet.defaultIcons,
        debouncedSearchText,
        activeIconSet.setIcons
      );
    }
  }, [activeKey, debouncedSearchText]);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div className='symbol-picker'>
      <Input
        className='symbol-picker--search'
        onChange={handleSearch}
        placeholder='Search symbol'
      />

      <Tabs defaultActiveKey='ocha' onChange={handleTabChange}>
        <TabPane tab='OCHA' key='ocha'>
          <Row>
            {ochaIcons.length ? (
              ochaIcons.map((icon, i) => {
                return (
                  <Symbol
                    key={i}
                    icon={icon}
                    onChangeComplete={() => onChangeComplete(icon)}
                  />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </TabPane>
        <TabPane tab='MAKI' key='maki'>
          <Row>
            {makiIcons.length ? (
              makiIcons.map((icon, i) => {
                return (
                  <Symbol
                    key={i}
                    icon={icon}
                    onChangeComplete={() => onChangeComplete(icon)}
                  />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </TabPane>
        <TabPane tab='UNICEF' key='unicef'>
          <Row>
            {unicefIcons.length ? (
              unicefIcons.map((icon, i) => {
                return (
                  <Symbol
                    key={i}
                    icon={icon}
                    onChangeComplete={() => onChangeComplete(icon)}
                  />
                );
              })
            ) : (
              <Empty />
            )}
          </Row>
        </TabPane>
        {!excludeTabs?.includes('gggi') && (
          <TabPane tab='GGGI' key='gggi'>
            <Row>
              {gggiIcons.length ? (
                gggiIcons.map((icon, i) => {
                  return (
                    <Symbol
                      key={i}
                      icon={icon}
                      onChangeComplete={() => onChangeComplete(icon)}
                    />
                  );
                })
              ) : (
                <Empty />
              )}
            </Row>
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

SymbolPicker.propTypes = {
  onChangeComplete: PropTypes.func.isRequired, // function to handle icon click
};

export { SymbolPicker };

export const MemoizedSymbolPicker = React.memo(SymbolPicker);
