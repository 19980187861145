import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutTwentyOne = (props) => {
  return (
    <div>
      <Row gutter={15}>
        <Col xs={12} sm={6}>
          <Region regionIndex={1} {...props} />
        </Col>
        <Col xs={12} sm={6}>
          <Region regionIndex={2} {...props} />
        </Col>
        <Col xs={12} sm={6}>
          <Region regionIndex={3} {...props} />
        </Col>
        <Col xs={12} sm={6}>
          <Region regionIndex={4} {...props} />
        </Col>
        <Col xs={24} sm={24}>
          <Region regionIndex={5} {...props} />
        </Col>
      </Row>
    </div>
  );
};

export { LayoutTwentyOne };
