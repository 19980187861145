import { Dictionary } from '@onaio/utils';
import chroma from 'chroma-js';
import { Dispatch } from 'redux';
import { actionComponentChartGroupByColorsEdit } from '../../../../../Chart/actions';
/**
 * Prepopulates groupby colors with default colors (should only happen when no groupby colors are set)
 * @param data Array of objects
 * @param dimension string keying data from post data
 * @param dispatch redux dispatch
 * @param indexes keys to help access component and the specific series in question
 */
export const prePopulateGroupByColor = (
  data: Dictionary[],
  dimension: string,
  dispatch: Dispatch,
  indexes: Dictionary,
  colorRange: string | undefined
): void => {
  const { componentIndex, itemIndex } = indexes;
  const groupByColor: Dictionary[] = [];
  const paired = chroma
    .scale(colorRange || 'Oranges')
    .padding(0.1)
    .colors(data.length > 12 ? 12 : data.length);
  paired.forEach((color: string, key: number) => {
    if (data[key] && data[key][dimension]) {
      groupByColor.push({
        value: data[key][dimension],
        color: color,
      });
    }
  });
  if (groupByColor.length > 0) {
    dispatch(
      actionComponentChartGroupByColorsEdit({
        componentIndex: componentIndex,
        seriesIndex: itemIndex,
        value: groupByColor,
      })
    );
  }
};

export const clearGroupByColor = (indexes: Dictionary, dispatch: Dispatch): void => {
  const { componentIndex, itemIndex } = indexes;
  dispatch(
    actionComponentChartGroupByColorsEdit({
      componentIndex: componentIndex,
      seriesIndex: itemIndex,
      value: [],
    })
  );
};
