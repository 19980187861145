import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { UserOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { AkukoAPIService } from '../../../../../services/serviceClass';
import { IDENTITY_API } from '../../../../../configs/env';
import { AccountAdd } from '../AccountAdd';
import { Scrollbars } from 'react-custom-scrollbars';

const mapStateToProps = (state, ownProps) => {
  if (state) {
    return {
      user: state.user,
    };
  }
  return null;
};

const mapDispatchToProps = {};

export const AccountsMenu = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [spaceCount, setSpaceCount] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.body.classList.add('left-margin');

    return function cleanup() {
      document.body.classList.remove('left-margin');
    };
  });

  const getAbrAccount = (id) => {
    const firstChar = id.substring(0, 3);
    let secondChar = '';
    const split = id.split('-');
    if (split[1]) {
      secondChar = split[1].substring(1, 0);
    }
    return `${firstChar}`;
  };

  const userCanAddAccounts = () => {
    let canAdd = false;
    const hasOna = props?.user?.accounts?.filter((item) => item.id === 'ona');
    if (hasOna?.[0]) {
      canAdd = true;
    }
    return canAdd;
  };

  if (props.user.authenticated) {
    return (
      <>
        <div id="accounts-menu" className="accounts-menu">
          <Scrollbars style={{ width: '70px', height: '100%' }}>
            <div className="content">
              <Link className="feed-link" to="/dashboard">
                Akuko
              </Link>
              <Tooltip
                getPopupContainer={() => document.getElementById('accounts-menu')}
                title="My account"
                placement="right"
              >
                {props.user.image ? (
                  <Link
                    className="my-account"
                    style={{ backgroundImage: `url(https://assets.akuko.io/${props.user.image})` }}
                    to={`/dashboard`}
                  >
                    My account
                  </Link>
                ) : (
                  <Link className="my-account no-image" to={`/dashboard`}>
                    <UserOutlined />
                  </Link>
                )}
              </Tooltip>
              {props.user?.accounts?.map((item) => (
                <Tooltip
                  key={item.account}
                  getPopupContainer={() => document.getElementById('accounts-menu')}
                  title={item.id}
                  placement="right"
                >
                  {item.icon ? (
                    <Link
                      className="account"
                      style={{
                        background: `#fff url(https://assets.akuko.io/${item.icon}) no-repeat center center`,
                      }}
                      to={`/account/${item.id}`}
                    >
                      {item.id}
                    </Link>
                  ) : (
                    <Link className="account--no-image" to={`/account/${item.id}`}>
                      {getAbrAccount(item.id)}
                    </Link>
                  )}
                </Tooltip>
              ))}
              {userCanAddAccounts() && (
                <Tooltip
                  getPopupContainer={() => document.getElementById('accounts-menu')}
                  title="Add organization"
                  placement="right"
                >
                  <a
                    href="#add-account"
                    className="btn-add-account"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <PlusCircleOutlined />
                  </a>
                </Tooltip>
              )}
            </div>
          </Scrollbars>
        </div>
        {userCanAddAccounts() && (
          <AccountAdd
            {...props}
            showModal={showModal}
            setShowModal={() => {
              setShowModal(false);
            }}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

const AccountsMenuContainer = connect(mapStateToProps, mapDispatchToProps)(AccountsMenu);

export default withRouter(AccountsMenuContainer);
