import { GroupFieldProps } from '../types';

export const number: GroupFieldProps = {
  groups: ['Settings', 'Format', 'Filters'],
  styles: ['Style'],
  sections: {},
  filters: true,
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Settings',
      placeHolder: 'Settings Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      placeHolder: 'Settings Description',
      group: 'Settings',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      placeHolder: 'Settings Identifier Name',
      group: 'Settings',
    },
    {
      type: 'number',
      property: 'fontSize',
      label: 'Font Size',
      group: 'Style',
      defaultValue: 30,
    },
    {
      type: 'fontFamily',
      property: 'fontFamily',
      label: 'Font family',
      group: 'Style',
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Settings',
    },

    {
      type: 'dimension',
      label: 'Inherit context from card',
      property: 'context',
      group: 'Settings',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },

    {
      type: 'dimension',
      label: 'Inherit context from card',
      property: 'context',
      group: 'Source',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },
    {
      type: 'array',
      label: 'Properties',
      property: 'properties',
      group: 'Settings',
      groups: ['Property'],
      settings: [
        {
          label: 'Property',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Property',
      collapse: false,
      collapseProperty: 'value',
      collapseParent: 'properties',
      defaultValue: {
        value: '',
        type: '',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Property',
          property: 'value',
          showMeasures: true,
          showDimensions: true,
          parents: ['properties'],
          setType: true,
          setDataType: true,
          dataTypeKey: 'data-type',
          typeKey: 'type',
          placeHolder: 'Interpolate Property',
        },
        {
          type: 'select',
          label: 'Granularity',
          property: 'granularity',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'second', label: 'Second' },
            { value: 'minute', label: 'Minute' },
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' },
          ],
          group: 'Property',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'select',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: '!' },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'yyyy', label: 'YYYY' },
            { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
            { value: 'MM-dd-yyy', label: 'MM-DD-YYYY' },
            { value: 'dd-yyyy-MM', label: 'DD-YYYY-MM' },
            { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
            { value: 'MM', label: 'MM' },
          ],
          group: 'Property',
          labelLink:
            'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'text',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: true },
          ],
          group: 'Property',
          labelLink:
            'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'checkbox',
          label: 'Use custom date format',
          property: 'dateFormatText',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
          ],
          group: 'Property',
          placeHolder: 'Toggle Format field to text',
          parents: ['properties'],
        },
      ],
    },
    {
      type: 'array',
      label: '',
      addButtonText: 'Add Filter',
      property: 'filters',
      group: 'Filters',
      groups: ['Filter'],
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      collapse: false,
      itemLabel: 'Filter',
      collapseProperty: '1',
      collapseParent: 'filters',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },

        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },

        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate', '<=', '>='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          group: 'Options',
          label: 'Inherit filter value',
          cols: 24,
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          cols: 24,
          property: 'expose',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          cols: 24,
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          cols: 24,
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Options',
          cols: 24,
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Options',
          cols: 24,
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',
          cols: 24,
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<', '<=', '>='],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          cols: 24,
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'text',
          label: 'Name',
          group: 'Options',
          property: 'name',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Options',
          property: 'cols',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'checkbox',
      label: 'Enable Cascade',
      property: 'cascade',
      group: 'Filters',
    },
    {
      type: 'select',
      label: 'Format',
      property: 'format',
      group: 'Format',
      options: [
        { value: 'number', label: 'Settings' },
        { value: 'icon', label: 'Icon' },
        { value: 'donut', label: 'Donut' },
      ],
      placeHolder: 'Choose Settings Format',
    },
    {
      type: 'icon',
      label: 'Icon',
      property: 'icon',
      group: 'Format',
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
    },
    {
      type: 'color',
      disableOpacity: true,
      label: 'Color',
      property: 'color',
      group: 'Style',
    },
    {
      type: 'color',
      label: 'Background',
      property: 'backgroundColor',
      group: 'Style',
      dependsOn: [
        {
          property: 'format',
          value: ['!', 'donut'],
        },
      ],
    },
    {
      type: 'slider',
      min: 10,
      max: 400,
      label: 'Icon size',
      property: 'height',
      group: 'Format',
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'dimension',
      label: 'Icon based on',
      property: 'iconField',
      group: 'Format',
      showMeasures: true,
      showDimensions: true,
      dependsOn: [
        {
          property: 'format',
          value: 'icon',
        },
      ],
      placeHolder: 'Field That Drives Icons',
    },
    {
      type: 'array',
      label: 'Icon categories',
      property: 'iconCategories',
      group: 'Format',
      groups: ['Categories'],
      settings: [
        {
          label: 'Categories',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Category',
      collapse: false,
      collapseProperty: 'property',
      collapseParent: 'iconCategories',
      defaultValue: {
        property: '',
        value: '',
      },
      fields: [
        {
          type: 'text',
          label: 'Property',
          group: 'Categories',
          property: 'property',
          parents: ['iconCategories'],
          placeHolder: 'Value for the Icon below',
        },
        {
          type: 'icon',
          label: 'Icon',
          group: 'Categories',
          property: 'value',
          parents: ['iconCategories'],
        },
      ],
      dependsOn: [
        {
          property: 'iconField',
          value: '*',
        },
      ],
    },
    {
      type: 'dimension',
      label: 'Color based on',
      property: 'colorField',
      group: 'Format',
      showMeasures: true,
      showDimensions: true,
      dependsOn: [
        {
          property: 'format',
          value: '*',
        },
      ],
      placeHolder: 'Field That Drives Settings Color',
    },
    {
      type: 'array',
      label: 'Icon colors',
      property: 'colors',
      groups: ['Color'],
      group: 'Format',
      itemLabel: 'Color',
      collapse: false,
      collapseProperty: 'property',
      collapseParent: 'colors',
      settings: [
        {
          label: 'Color',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      defaultValue: {
        property: '',
        value: '',
      },
      fields: [
        {
          type: 'text',
          label: 'Property',
          group: 'Color',
          property: 'property',
          parents: ['colors'],
          placeHolder: 'Value For The Color Below',
        },
        {
          type: 'color',
          disableOpacity: true,
          label: 'color',
          group: 'Color',
          property: 'value',
          parents: ['colors'],
        },
      ],
      dependsOn: [
        {
          property: 'colorField',
          value: '*',
        },
      ],
    },
    {
      type: 'slider',
      label: 'Size',
      property: 'height',
      min: 40,
      max: 200,
      step: 1,
      group: 'Format',
      dependsOn: [
        {
          property: 'format',
          value: 'donut',
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'checkbox',
      label: 'Show value in center',
      property: 'showValue',
      group: 'Format',
      dependsOn: [
        {
          property: 'format',
          value: 'donut',
        },
      ],
    },
    {
      type: 'slider',
      label: 'Height',
      property: 'numberHeight',
      min: 70,
      max: 200,
      group: 'Format',
      dependsOn: [
        {
          property: 'format',
          value: '!',
        },
      ],
      placeHolder: '0',
    },
  ],
};
