import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { PostComponentProps } from '../../PostComponent';
import Region from './Region';

export interface LayoutTypesProps {
  post: PostComponentProps;
  componentIndex: number;
}

export const ModalLayout = (props: LayoutTypesProps): JSX.Element => {
  const { componentIndex, post } = props;
  const component = post.components?.[componentIndex as number];
  const [open, setOpen] = useState<boolean>(component?.hidden ? false : true);

  useEffect(() => {
    setOpen(component?.hidden ? false : true);
  }, [component?.hidden]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal title="Basic Modal" open={open} onCancel={handleCancel} footer={[]}>
      <Region regionIndex={1} {...props} />
    </Modal>
  );
};
