export default async function readFileAsDataURL(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', (evt) => {
      if (reader.result) {
        resolve(reader.result);
      }
    });
    reader.readAsDataURL(file);
  });
}

// Helper function to determine cursor style based on events
export const getCursorStyle = (events) =>
  events?.length > 0 ? 'pointer' : 'default';

// Helper function to determine height for large image case
export const getHeightStyle = (component) =>
  !component.height && component.region ? 275 : component.height;

// Helper function to determine image style based on region and alignment
export const getImageStyle = (component, region) => {
  const baseStyle = {
    width: `${component.imageWidth}px`,
    cursor: getCursorStyle(component?.events),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  if (region) {
    return {
      ...baseStyle,
      float: component.align !== 'center' ? component.align : 'none',
      marginLeft: component.align !== 'center' ? 0 : 'auto',
      marginRight: component.align !== 'center' ? 0 : 'auto',
    };
  }

  return baseStyle;
};

// Helper function to render the image based on component state
export const renderImage = (component, props, region, handleEvents, data) => {
  if (
    component.imageSource === 'dimension' &&
    component?.cube &&
    component?.context &&
    component?.dimensionImageUrl
  ) {
    return (
      <img
        onClick={(e) => handleEvents(e, component)}
        style={{ cursor: getCursorStyle(component?.events) }}
        src={
          props.dataRow?.[`${component?.cube}.${component?.dimensionImageUrl}`]
        }
        alt={component.caption}
      />
    );
  }

  if (
    component.imageSource === 'dimension' &&
    component?.cube &&
    !component?.context &&
    component?.dimensionImageUrl
  ) {
    return (
      <img
        onClick={(e) => handleEvents(e, component)}
        style={{ cursor: getCursorStyle(component?.events) }}
        src={data?.[0]?.[`${component?.cube}.${component?.dimensionImageUrl}`]}
        alt={component.caption}
      />
    );
  }

  if (component?.imageUrl) {
    if (!region && component?.width === 'large') {
      return (
        <div
          className='post--component-image--image'
          style={{
            height: getHeightStyle(component),
            backgroundImage: `url(${component.imageUrl})`,
          }}
        ></div>
      );
    }

    return (
      <img
        onClick={(e) => handleEvents(e, component)}
        style={getImageStyle(component, region)}
        alt={component.caption}
        src={component.imageUrl}
      />
    );
  }

  return null;
};
