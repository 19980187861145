import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { Row, Col, Collapse } from 'antd';
import { CloseOutlined, DragOutlined, SettingOutlined, MoreOutlined } from '@ant-design/icons';
import { actionPostArrayItemDelete } from '../../../../actions';
import { getComponentBasedOnType, isGlobalFilter, shouldShowField } from '../../../helpers/helpers';
import './style.css';
const { Panel } = Collapse;

export interface ArrayItemGroupProps {
  componentIndex: number;
  groupIndex: number;
  itemIndex: number;
  childIndex?: number;
  group: Dictionary;
  fields: Dictionary[];
  array: Dictionary;
}

const ArrayItemGroup: React.FC<ArrayItemGroupProps> = (props: ArrayItemGroupProps) => {
  const { componentIndex, groupIndex, fields, group, childIndex, itemIndex } = props;
  let { array } = props;
  const [showAdvanced, setShowAdvanced] = useState(false);
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const dispatch = useDispatch();

  const AdvancedControl = (
    <MoreOutlined
      onClick={() => {
        setShowAdvanced(showAdvanced === true ? false : true);
      }}
      style={{ fontSize: '14px' }}
    />
  );

  return (
    <div style={{ paddingTop: '8px' }}>
      {group.showCollapse === false &&
        fields.map((field: Dictionary, index: number) => {
          const componentsBasedOnType = getComponentBasedOnType(
            field,
            componentIndex,
            index,
            childIndex,
            itemIndex
          );
          const { parents } = field;
          if (parents?.length > 1 && childIndex !== undefined) {
            array = component?.[parents[0]]?.[childIndex]?.[parents[1]];
          }
          return (
            <Fragment key={index}>
              {field.advanced === true &&
                showAdvanced === true &&
                shouldShowField(array, itemIndex, field, component) &&
                field.group === group.label &&
                componentsBasedOnType && (
                  <Col key={index} xs={field.cols || 24}>
                    {componentsBasedOnType}
                  </Col>
                )}
              {!field.advanced &&
                shouldShowField(array, itemIndex, field, component) &&
                field.group === group.label &&
                componentsBasedOnType && (
                  <Col key={index} xs={field.cols || 24}>
                    {componentsBasedOnType}
                  </Col>
                )}
            </Fragment>
          );
        })}
      {group.showCollapse !== false && (
        <Collapse
          collapsible={'header'}
          expandIconPosition={'right'}
        >
          <Panel
            className="field-input--collapse-child"
            header={group.label}
            key={0}
          >
            {fields.map((field: Dictionary, index: number) => {
              const componentsBasedOnType = getComponentBasedOnType(
                field,
                componentIndex,
                index,
                childIndex,
                itemIndex
              );
              const { parents } = field;
              if (parents?.length > 1 && childIndex !== undefined) {
                array = component?.[parents[0]]?.[childIndex]?.[parents[1]];
              }
              return (
                <Fragment key={index}>
                  {field.advanced === true &&
                    showAdvanced === true &&
                    shouldShowField(array, itemIndex, field, component) &&
                    field.group === group.label &&
                    componentsBasedOnType && (
                      <Col key={index} xs={field.cols || 24}>
                        {componentsBasedOnType}
                      </Col>
                    )}
                  {!field.advanced &&
                    shouldShowField(array, itemIndex, field, component) &&
                    field.group === group.label &&
                    componentsBasedOnType && (
                      <Col key={index} xs={field.cols || 24}>
                        {componentsBasedOnType}
                      </Col>
                    )}
                </Fragment>
              );
            })}
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export { ArrayItemGroup };
