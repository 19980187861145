import { GroupFieldProps } from '../types';

export const text: GroupFieldProps = {
  groups: ['Text', 'Style', 'Source', 'Filters'],
  styles: ['Width', 'Style'],
  filters: true,
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Text',
      placeHolder: 'Text Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'pills',
      label: 'Align',
      property: 'textAlign',
      placeHolder: 'Left',
      group: 'Style',
      options: [
        { value: 'left', label: 'Left' },
        { value: 'center', label: 'Center' },
        { value: 'right', label: 'Right' },
      ],
    },
    {
      type: 'number',
      property: 'fontSize',
      label: 'Font size',
      inherit: 'textFontSize',
      group: 'Style',
      min: 10,
      max: 100,
      placeHolder: '18',
    },
    {
      type: 'fontFamily',
      property: 'fontFamily',
      placeHolder: 'PT Serif',
      inherit: 'bodyFontFamily',
      label: 'Text font',
      group: 'Style',
    },
    {
      type: 'color',
      property: 'color',
      label: 'Text color',
      inherit: 'textColor',
      disableOpacity: true,
      group: 'Style'
    },
    {
      type: 'slider',
      property: 'lineHeight',
      placeHolder: '1',
      defaultValue: 1,
      min: 0,
      max: 4,
      step: 0.1,
      label: 'Line height',
      group: 'Style',
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Source',
    },
    {
      type: 'dimension',
      label: 'Context',
      property: 'context',
      group: 'Source',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },
    {
      type: 'array',
      label: 'Properties',
      property: 'properties',
      group: 'Source',
      groups: ['Property'],
      settings: [
        {
          label: 'Property',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Property',
      collapse: false,
      collapseProperty: 'value',
      collapseParent: 'properties',
      defaultValue: {
        value: '',
        type: '',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Property',
          property: 'value',
          showMeasures: true,
          showDimensions: true,
          parents: ['properties'],
          setType: true,
          setDataType: true,
          dataTypeKey: 'data-type',
          typeKey: 'type',
          placeHolder: 'Select',
        },
        {
          type: 'select',
          label: 'Granularity',
          property: 'granularity',
          dependsOn: [{ property: 'value', value: 'time', dataTypeKey: 'data-type' }],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'second', label: 'Second' },
            { value: 'minute', label: 'Minute' },
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' },
          ],
          group: 'Property',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'select',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: '!' },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'yyyy', label: 'YYYY' },
            { value: 'MM/dd/yyyy', label: 'MM/DD/YYYY' },
            { value: 'MM-dd-yyy', label: 'MM-DD-YYYY' },
            { value: 'dd-yyyy-MM', label: 'DD-YYYY-MM' },
            { value: 'yyyy-MM-dd', label: 'YYYY-MM-DD' },
            { value: 'MM', label: 'MM' },
          ],
          group: 'Property',
          labelLink: 'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'text',
          label: 'Date Format',
          property: 'dateFormat',
          dependsOn: [
            { property: 'value', value: 'time', dataTypeKey: 'data-type' },
            { property: 'dateFormatText', value: true },
          ],
          group: 'Property',
          labelLink: 'https://app.akuko.io/post/d70419a9-1c97-4a09-a3f7-5e1d545afe50',
          placeHolder: 'Select time granularity option',
          parents: ['properties'],
        },
        {
          type: 'switch',
          label: 'Use custom date format',
          property: 'dateFormatText',
          dependsOn: [{ property: 'value', value: 'time', dataTypeKey: 'data-type' }],
          group: 'Property',
          placeHolder: 'Toggle Format field to text',
          parents: ['properties'],
        },
      ],
    },
    {
      type: 'array',
      label: 'Filters',
      property: 'filters',
      addButtonText: 'Add Filter',
      group: 'Filters',
      groups: ['Filter'],
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      collapse: false,
      itemLabel: 'Filter',
      collapseProperty: '1',
      collapseParent: 'filters',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },

        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },

        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate', '<=', '>='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          group: 'Filter',
          label: 'Inherit filter value',
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Filter',
          cols: 24,
          property: 'expose',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Filter',
          cols: 24,
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Filter',
          cols: 24,
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Filter',
          cols: 24,
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Filter',
          cols: 24,
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Filter',
          cols: 24,
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<'],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Filter',
          cols: 24,
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'text',
          label: 'Name',
          group: 'Filter',
          property: 'name',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Filter',
          property: 'cols',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable cascading filters',
      property: 'cascade',
      group: 'Filters',
    },
  ],
};
