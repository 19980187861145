import React from 'react';
import { Col, Row, Tabs } from 'antd';
import Region from './Region';
const { TabPane } = Tabs;

const TabsLayout = (props) => {
  const { componentIndex, post } = props;

  return (
    <div className="layout-tabs">
      <Row gutter={15}>
        <Col xs={24}>
          <Tabs
            activeKey={post.components[componentIndex]?.activeTab || undefined}
            defaultActiveKey={'1'}
            tabBarGutter={0}
    
            tabPosition={post.components[componentIndex]?.tabLayout || 'top'}
          >
            {post.components[componentIndex]?.tabs?.map((item, index) => (
              <TabPane tab={(<div style={{ padding: post.components[componentIndex]?.tabPadding >= 0 ? post.components[componentIndex]?.tabPadding : '10px' }}>{ item.title }</div>)} key={index + 1}>
                <Region regionIndex={item.id} {...props} />
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export { TabsLayout };
