export enum onadataSourceJobStatusTypes {
    GETTING_ONA_FORM_DATA,
    RETRIEVING_EXPORT_DATA,
    GENERATING_SCHEMAS,
    UPLOADING_DATA,
    SOURCE_CUBE_CREATION,
    DONE,
    FAILED,
}
export enum onadataSourceJobStatusTypesDescriptions {
    GETTING_ONA_FORM_DATA = 'Getting ona form data',
    RETRIEVING_EXPORT_DATA = 'Retrieving export data',
    GENERATING_SCHEMAS = 'Generating file(s) schema(s)',
    UPLOADING_DATA = 'Uploading file(s)',
    SOURCE_CUBE_CREATION = 'Creating source(s) and cube(s)',
    DONE = 'Done',
}
