import React, { useState } from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const ColsOne = (props) => {
  const { rowMargin } = props;
  const array = [];
  const popupRows = 1;
  const rows = props.rows ? props.rows : popupRows;
  for (var i = 1; i < rows + 1; i++) {
    array.push(
      <Row 
      key={i} 
      style={{ marginBottom: rowMargin === 0 ? 0 : rowMargin }}
      gutter={props?.gutter || 0}>
        <Col xs={24}>
          <Region regionIndex={`${i}-a`} {...props} />
        </Col>
      </Row>
    );
  }
  return array;
};

export { ColsOne };
