import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import './style.css';
import { typography } from '../../../configs/styles/typogrpahy';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { Settings } from './components/Settings';
import { table } from '../../../configs/styles/table';
import { text } from '../../../configs/styles/text';
import { menu } from '../../../configs/styles/menu';
import { global } from '../../../configs/styles/global';
import { Dictionary } from '@onaio/utils';


const StyleSettings = (props: any) => {
    const [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const store = useStore();
    const space = useSelector((store: Dictionary) => store.space);
    const post = useSelector((store: Dictionary) => store.post);

    const postSections = [
      {
        key: "text",
        label: "Text",
        children: (
          <Settings scope="post" label="Text" config={text} disabled={(value) => setDisabled(value)}/>
        )
      },
      
      {
        key: "menus",
        label: "Menus",
        children: (
          <Settings scope="post" label="Menus" config={menu} disabled={(value) => setDisabled(value)}/>
        )
      },
      
      {
        key: "tables",
        label: "Tables",
        children: (
        <Settings scope="post" label="Tables" config={table} disabled={(value) => setDisabled(value)}/>
        )
      },
      {
        key: "global",
        label: "Global",
        children: (
        <Settings scope="post" label="Global" config={global} disabled={(value) => setDisabled(value)}/>
        )
      },
    ]

    const spaceSections = [
      {
        key: "text",
        label: "Text",
        children: (
          <Settings scope="space" label="Global" config={text} disabled={(value) => setDisabled(value)}/>
        )
      },
      {
        key: "tables",
        label: "Tables",
        children: (
        <Settings scope="space" label="Tables" config={table} disabled={(value) => setDisabled(value)}/>
        )
      },
      {
        key: "global",
        label: "Global",
        children: (
        <Settings scope="space" label="Global" config={global} disabled={(value) => setDisabled(value)}/>
        )
      }
    ]

    const items = [
        {
          key: 'post',
          label: 'Post',
          children: <Tabs className="style-settings-child-tabs" size='small' items={postSections}></Tabs>,
        },
      ];
    
      if (space?.uuid) {
        items.push({
          key: 'space',
          label: 'Space',
          children: <Tabs className="style-settings-child-tabs" size='small' items={spaceSections}></Tabs>,
        });
      }

  return(
    <div className="style-settings">
      <div className="content">
      <Tabs size='small' className="style-settings-parent-tabs" defaultActiveKey='post' items={items} />
      </div>
    </div>
  )
}

export { StyleSettings }