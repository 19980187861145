import { Dictionary } from '@onaio/utils';
import { title } from './title';
import { icon } from './icon';
import { map } from './map';
import { text } from './text';
import { card } from './card';
import { number } from './number';
import { layout } from './layout';
import { image } from './image';
import { table } from './table';
import { column } from './column';
import { chart } from './chart';
import { filter } from './filter';
import { button } from './button';
import { menu } from './menu';
import { layer } from './layer';
import { html } from './html';
import { reference } from './reference';
import { item } from './item';
import { post } from './post';

export const config: Dictionary = {
  title: title,
  card: card,
  icon: icon,
  number: number,
  layout: layout,
  chart: chart,
  map: map,
  layer: layer,
  item: item,
  reference: reference,
  column: column,
  text: text,
  menu: menu,
  table: table,
  image: image,
  filter: filter,
  button: button,
  html: html,
  post: post,
};
