import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, message, Form, Row, Col, Collapse, Select } from 'antd';
import { Dictionary } from '@onaio/utils';
import { actionSourceSave } from '../actions';
import {
  ClickHouseSourceTypePayload,
  ClickHouseSourceTypeRequestPayload,
  Source,
  UrlSourceCreationJobStatusTypes,
  sourceAsyncOperationsJobStatusTypes,
} from '../../../configs/component-types';
import {
  createClickHouseSourceType,
  updateClickHouseSourceTypeConnectionConfigs,
} from '../helpers/helpers';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../configs/env';
import { UrlSourceCreationProgressSteps } from '../components/UrlSourceCreationProgressSteps';
import { AKUKO_SOURCE_TYPES } from '../../../configs/types';

const { Panel } = Collapse;
const { Option } = Select;

export interface ClickHouseSourceInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
  asyncTaskName: string;
  refresh: boolean;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
  asyncTaskName: 'Source Creation',
};

const ClickHouseSourceInput: React.FC<ClickHouseSourceInputProps> = (
  props: ClickHouseSourceInputProps
) => {
  const { sourceActionSaveCreator, asyncTaskName, refresh } = props;
  const source = useSelector((store: Dictionary) => store.source);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const formData = new FormData();
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});
  const [formInitVals, setInitialValues] = useState<Dictionary>({});

  useEffect(() => {
    if (refresh === true && source?.config?.connectionMetadata !== undefined) {
      setInitialValues({
        table: source?.config?.connectionMetadata?.table,
        username: source?.config?.connectionMetadata?.username,
        database: source?.config?.connectionMetadata?.database,
        host: '',
        password: '',
        port: '',
      });
    }
  }, [refresh, source]);

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            /* @ts-ignore */
            ...progress.data[0]?.source,
          })
        );
      }
      setLoading(false);
      message.success('Source saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleSourceCreation = (payload: ClickHouseSourceTypeRequestPayload) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });
    triggerAsycSourceOperation({
      asynFunc: createClickHouseSourceType,
      asynFuncArgs: payload,
      asyncTaskName: asyncTaskName,
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const handleSourceConnectionConfigsUpdate = (payload: ClickHouseSourceTypeRequestPayload) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });
    triggerAsycSourceOperation({
      asynFunc: updateClickHouseSourceTypeConnectionConfigs,
      asynFuncArgs: payload,
      asyncTaskName: asyncTaskName,
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const selectBefore = (
    <Form.Item initialValue="https" name="protocol" noStyle>
      <Select defaultValue="https">
        <Option value="https">https</Option>
        <Option value="http">http</Option>
      </Select>
    </Form.Item>
  );

  if (source?.type === AKUKO_SOURCE_TYPES.CLICKHOUSE) {
    return (
      <>
        <Form
          className="clickhouse-source-type"
          key={JSON.stringify(formInitVals)}
          initialValues={formInitVals}
          onFinish={(values) => {
            const payload = {
              host: values['host'],
              protocol: values['protocol'],
              table: values['table'],
              username: values['username'],
              database: values['database'],
              password: values['password'],
              port: values['port'],
            } as ClickHouseSourceTypePayload;
            formData.append('connectionConfigs', JSON.stringify(payload));
            if (refresh === false) {
              handleSourceCreation({
                sourceId: source?.uuid,
                formData: formData,
              });
            }
            if (refresh === true) {
              handleSourceConnectionConfigsUpdate({
                sourceId: source?.uuid,
                formData: formData,
              });
            }
          }}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={16}>
              <Form.Item
                name="host"
                label="Host"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  addonBefore={selectBefore}
                  id="input-host"
                  placeholder="host"
                  disabled={loading}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="port"
                label="Port"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-port" placeholder="port" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="database"
                label="Database"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-database" placeholder="database" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="table"
                label="Table"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-table" placeholder="table" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-username" placeholder="username" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password id="input-password" placeholder="password" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Collapse>
                <Panel key={1} header="SSL (Optional fields)">
                  <Form.Item
                    name="caSSLCertFile"
                    label="CA SSL Certificate"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      disabled={loading}
                      accept=".pem, .crt"
                      onChange={(e: Dictionary) => {
                        if (e.target.files) {
                          formData.append('caSSLCertFile', e.target.files[0]);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="certSSLCertFile"
                    label="CERT SSL Certificate"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      disabled={loading}
                      accept=".pem, .crt"
                      onChange={(e: Dictionary) => {
                        if (e.target.files) {
                          formData.append('certSSLCertFile', e.target.files[0]);
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="keySSLCertFile"
                    label="Key SSL Certificate"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      disabled={loading}
                      accept=".pem, .key"
                      onChange={(e: Dictionary) => {
                        if (e.target.files) {
                          formData.append('keySSLCertFile', e.target.files[0]);
                        }
                      }}
                    />
                  </Form.Item>
                </Panel>
              </Collapse>
            </Col>
            <Col xs={24} sm={24}>
              <Button id="btn-connect" htmlType="submit" type="primary" disabled={loading}>
                Connect
              </Button>
            </Col>
          </Row>
        </Form>
        {loading === true && (
          <div className="input-field">
            <label>Progress</label>
            <UrlSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

ClickHouseSourceInput.defaultProps = defaultProps;

export { ClickHouseSourceInput };
