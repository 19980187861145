import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { GenericComponent } from '../Component';
import { useResolvedStyles } from '../hooks';
import Author from '../Author';
import './style.css';

export interface TitleProps {
  componentIndex: number; // index of the table component
  componentId: string; // id of the table component

}

const TitleComponent: React.FC<TitleProps> = (props: TitleProps) => {
  const { componentIndex, componentId } = props;
  const post = useSelector((store: Dictionary) => store.post);
  const component = post.components[componentIndex];
  const genericComponentProps = {
    componentIndex,
    componentId,
    showWidthMenu: false
  };

    // get styles
    const styles = useResolvedStyles();

  if (!component.hideTitle) {
  return(
    <GenericComponent {...genericComponentProps} >
      <h1
      style={{ 
        color: component?.headingColor ? component?.headingColor : styles.headingColor,
        fontSize: component?.titleFontSize ? component?.titleFontSize : styles.headingFontSize,
        fontWeight: component?.headingFontWeight ? component.headingFontWeight : styles.headingFontWeight,
        fontFamily: component?.titleFontFamily ? component?.titleFontFamily : styles.headingFontFamily,
      }}
      >{post?.title}</h1>
      { post?.description &&
        <p 
         className="description"
         style={{ 
          fontSize: `${post?.fontSize || component?.fontSize}px`,
          color: component?.color || post.config?.textColor,
          fontWeight: component?.fontWeight,
          fontFamily: component?.fontFamily || 'Poppins',
        }}
        >{post.description}</p>
      }
      { post?.author && !component?.hideAuthor &&
        <Author 
        color={component?.color || post.config?.textColor}
        author={post?.author} 
        />
      }
    </GenericComponent>
  )
  } else {
    return null
  }
}

export { TitleComponent }