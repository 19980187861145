import produce from 'immer';

const getDefaultState = () => {
    return {
      drawerOpen: false,
      darkMode: false,
      activeLayerIndex: -1,
      activeColumnIndex: -1,
      activeItemIndex: -1,
      activeComponentIndex: 0,
      activeSettingId: 'components'
    };
  }

const config = (state = getDefaultState(), action) => {
  let nextState;
  switch (action.type) {
    case 'CONFIG_EDIT':
      nextState = produce(state, (draftState) => {
        draftState[action.data.property] = action.data.value;
      });
      return nextState;
    case 'CONFIG_DRAWER_OPEN_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.drawerOpen = action.data.value;
      });
      return nextState;
    case 'CONFIG_COMPONENT_INDEX_ACTIVE':
      nextState = produce(state, (draftState) => {
        draftState.activeComponentIndex = action.data.value;
      });
      return nextState;
    case 'CONFIG_COMPONENT_LAYER_ACTIVE':
      nextState = produce(state, (draftState) => {
        draftState.activeLayerIndex = action.data.value;
      });
      return nextState;
    case 'CONFIG_COMPONENT_COLUMN_ACTIVE':
      nextState = produce(state, (draftState) => {
        draftState.activeColumnIndex = action.data.value;
      });
      return nextState;
    case 'CONFIG_COMPONENT_ITEM_ACTIVE':
      nextState = produce(state, (draftState) => {
        draftState.activeItemIndex = action.data.value;
      });
      return nextState;
    case 'CONFIG_ACTIVE_POST_ADD':
      nextState = produce(state, (draftState) => {
        draftState.activePostId = action.data.value;
      });
      return nextState;
    case 'CONFIG_ACTIVE_SETTING_ADD':
      nextState = produce(state, (draftState) => {
        draftState.activeSettingId = action.data.value;
      });
      return nextState;
    case 'CONFIG_POST_MENU_SECTION_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.postMenuSection = action.data.value;
      });
      return nextState;
    case 'CONFIG_ROLE_SET':
      nextState = produce(state, (draftState) => {
        draftState.role = action.data.value;
      });
      return nextState;
    case 'CONFIG_DARK_MODE':
      nextState = produce(state, (draftState) => {
        draftState.darkMode = action.data.value;
        localStorage.setItem('darkMode', JSON.stringify(action.data.value));
      });
      return nextState;
    case 'POST_COMPONENT_MAP_CURRENT_LAT_LNG_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.latLng = [action.data.lng, action.data.lat];
      });
      return nextState;
    default:
      return state;
  }
};

export default config;
