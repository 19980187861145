import React from 'react';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { Button, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { AkukoAPIService } from '../../../../../../services/serviceClass';
import { SOURCES_API } from '../../../../../../configs/env';
import { Link } from 'react-router-dom';
import { actionPostComponentSettingEdit, actionComponentSourceGet } from '../../../../actions';
import { DatabaseOutlined, LinkOutlined } from '@ant-design/icons';
import { isCurrentSource } from '../helpers';

export interface SourceItemProps {
  value: string;
  componentIndex: number;
  childIndex?: number | undefined;
  itemIndex: number | undefined;
  parents?: Dictionary[] | undefined;
  property: string;
  isMapLayer?: boolean | undefined;
  setModalVisible: (value: boolean) => void;
  item: Dictionary;
}

export const SourceItem: React.FC<SourceItemProps> = (props: SourceItemProps) => {
  const dispatch = useDispatch();
  const {
    value,
    item,
    property,
    parents,
    setModalVisible,
    isMapLayer,
    componentIndex,
    itemIndex,
    childIndex,
  } = props;

  if (isMapLayer && !item?.geometries) {
    return null;
  }
  return (
    <Col sm={12}>
      <div className={`source-item ${isCurrentSource(value, item)}`}>
        <h2>{item.name}</h2>

        <Link
          target="blank"
          className="source-link"
          to={
            item.space_id === null
              ? `/source/${item.uuid}`
              : `/space/${item.space_id}/sources/${item.uuid}`
          }
        >
          <LinkOutlined />
        </Link>
        <div className="source-id">Id: {item.uuid}</div>
        <div className="source-thumb">
          <DatabaseOutlined />
        </div>
        <div className="source-attribution">{item?.config?.attribution}</div>

        <Button
          onClick={async () => {
            setModalVisible(false);

            const service = new AkukoAPIService(SOURCES_API, 'source');
            const res = await service.read(item.uuid);
            const selectedSource = res as Dictionary;
            dispatch(actionComponentSourceGet(res));
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: componentIndex,
                parents: parents,
                itemIndex: itemIndex,
                childIndex: childIndex,
                property: property,
                value: item.uuid,
              })
            );
            // add cube
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: componentIndex,
                parents: parents,
                itemIndex: itemIndex,
                childIndex: childIndex,
                property: 'cube',
                value: selectedSource?.cube,
              })
            );

            if (isMapLayer) {
              // clear geometries
              dispatch(
                actionPostComponentSettingEdit({
                  componentIndex: componentIndex,
                  parents: parents,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  property: 'geometries',
                  value: selectedSource?.geometries,
                })
              );
              // clear geometry index
              // clear geometries
              dispatch(
                actionPostComponentSettingEdit({
                  componentIndex: componentIndex,
                  parents: parents,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  property: 'geometryIndex',
                  value: 0,
                })
              );
              dispatch(
                actionPostComponentSettingEdit({
                  componentIndex: componentIndex,
                  parents: parents,
                  itemIndex: itemIndex,
                  childIndex: childIndex,
                  property: 'layerType',
                  value: selectedSource?.geometries?.[0]?.type === 'latLng' ? 'circle' : 'fill',
                })
              );
            }
          }}
          type="primary"
        >
          Select Source
        </Button>
      </div>
    </Col>
  );
};
