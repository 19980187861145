import React from 'react';
import { Steps } from 'antd';
import { Dictionary } from '@onaio/utils';
import { LoadingOutlined } from '@ant-design/icons';

import {
  UrlSourceCreationJobStatusTypes,
  UrlSourceCreationJobStatusTypesDescriptions,
} from '../../../../configs/component-types';

type DirectionType = 'horizontal' | 'vertical';
export interface UrlSourceCreationProgressStepsProps {
  progress: Dictionary;
  direction?: DirectionType;
}

/** default component props */
const defaultProps = {
  direction: 'horizontal' as DirectionType,
};

const UrlSourceCreationProgressSteps: React.FC<UrlSourceCreationProgressStepsProps> = (
  props: UrlSourceCreationProgressStepsProps
) => {
  let { progress } = props;
  if (Object.keys(progress).length === 0) {
    progress = {
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    };
  }

  const getCurrentStage = (stage?: string) => {
    let currentStage = 0;
    switch (stage) {
      case UrlSourceCreationJobStatusTypes.STARTING:
        currentStage = 0;
        break;
      case UrlSourceCreationJobStatusTypes.DONE:
        currentStage = 2;
        break;
      default:
        currentStage = 1;
        break;
    }
    return currentStage;
  };
  const currentStage = getCurrentStage(progress?.stage);

  return (
    <Steps
      size="small"
      direction={props?.direction}
      current={currentStage}
      percent={
        progress?.stage === UrlSourceCreationJobStatusTypes.STARTING
          ? undefined
          : progress?.data[0]?.percentage
      }
      items={[
        {
          title: UrlSourceCreationJobStatusTypesDescriptions.STARTING,
          icon:
            progress?.stage === UrlSourceCreationJobStatusTypes.STARTING ? (
              <LoadingOutlined />
            ) : undefined,
        },
        {
          title: UrlSourceCreationJobStatusTypesDescriptions.PROCESSING,
        },
        {
          title: UrlSourceCreationJobStatusTypesDescriptions.DONE,
        },
      ]}
    />
  );
};

UrlSourceCreationProgressSteps.defaultProps = defaultProps;
export { UrlSourceCreationProgressSteps };
