export const getIcons = (
  baseURL = 'https://assets.akuko.io/ocha-icons-black-svg/'
) => {
  return [
    {
      id: 'Abduction-kidnapping',
      src: `${baseURL}Abduction-kidnapping.svg`,
      title: 'Abduction-kidnapping',
    },
    {
      id: 'About',
      src: `${baseURL}About.svg`,
      title: 'About',
    },
    {
      id: 'Add',
      src: `${baseURL}Add.svg`,
      title: 'Add',
    },
    {
      id: 'Add-document',
      src: `${baseURL}Add-document.svg`,
      title: 'Add-document',
    },
    {
      id: 'Advocacy',
      src: `${baseURL}Advocacy.svg`,
      title: 'Advocacy',
    },
    {
      id: 'Affected-population',
      src: `${baseURL}Affected-population.svg`,
      title: 'Affected-population',
    },
    {
      id: 'Agile',
      src: `${baseURL}Agile.svg`,
      title: 'Agile',
    },
    {
      id: 'Agriculture',
      src: `${baseURL}Agriculture.svg`,
      title: 'Agriculture',
    },
    {
      id: 'Airport',
      src: `${baseURL}Airport.svg`,
      title: 'Airport',
    },
    {
      id: 'Airport-affected',
      src: `${baseURL}Airport-affected.svg`,
      title: 'Airport-affected',
    },
    {
      id: 'Airport-closed',
      src: `${baseURL}Airport-closed.svg`,
      title: 'Airport-closed',
    },
    {
      id: 'Airport-destroyed',
      src: `${baseURL}Airport-destroyed.svg`,
      title: 'Airport-destroyed',
    },
    {
      id: 'Airport-military',
      src: `${baseURL}Airport-military.svg`,
      title: 'Airport-military',
    },
    {
      id: 'Airport-not-affected',
      src: `${baseURL}Airport-not-affected.svg`,
      title: 'Airport-not-affected',
    },
    {
      id: 'Alert',
      src: `${baseURL}Alert.svg`,
      title: 'Alert',
    },
    {
      id: 'Analysis',
      src: `${baseURL}Analysis.svg`,
      title: 'Analysis',
    },
    {
      id: 'Apps',
      src: `${baseURL}Apps.svg`,
      title: 'Apps',
    },
    {
      id: 'Arrest-detention',
      src: `${baseURL}Arrest-detention.svg`,
      title: 'Arrest-detention',
    },
    {
      id: 'Assault',
      src: `${baseURL}Assault.svg`,
      title: 'Assault',
    },
    {
      id: 'Assembly-point',
      src: `${baseURL}Assembly-point.svg`,
      title: 'Assembly-point',
    },
    {
      id: 'Assessment',
      src: `${baseURL}Assessment.svg`,
      title: 'Assessment',
    },
    {
      id: 'Attack',
      src: `${baseURL}Attack.svg`,
      title: 'Attack',
    },
    {
      id: 'Bacteria',
      src: `${baseURL}Bacteria.svg`,
      title: 'Bacteria',
    },
    {
      id: 'Blanket',
      src: `${baseURL}Blanket.svg`,
      title: 'Blanket',
    },
    {
      id: 'Blog',
      src: `${baseURL}Blog.svg`,
      title: 'Blog',
    },
    {
      id: 'Boat',
      src: `${baseURL}Boat.svg`,
      title: 'Boat',
    },
    {
      id: 'Bookmark',
      src: `${baseURL}Bookmark.svg`,
      title: 'Bookmark',
    },
    {
      id: 'Border-closed',
      src: `${baseURL}Border-closed.svg`,
      title: 'Border-closed',
    },
    {
      id: 'Border-crossing',
      src: `${baseURL}Border-crossing.svg`,
      title: 'Border-crossing',
    },
    {
      id: 'Borehole',
      src: `${baseURL}Borehole.svg`,
      title: 'Borehole',
    },
    {
      id: 'Bottled-water',
      src: `${baseURL}Bottled-water.svg`,
      title: 'Bottled-water',
    },
    {
      id: 'Bridge',
      src: `${baseURL}Bridge.svg`,
      title: 'Bridge',
    },
    {
      id: 'Bridge-affected',
      src: `${baseURL}Bridge-affected.svg`,
      title: 'Bridge-affected',
    },
    {
      id: 'Bridge-closed',
      src: `${baseURL}Bridge-closed.svg`,
      title: 'Bridge-closed',
    },
    {
      id: 'Bridge-destroyed',
      src: `${baseURL}Bridge-destroyed.svg`,
      title: 'Bridge-destroyed',
    },
    {
      id: 'Bridge-not-affected',
      src: `${baseURL}Bridge-not-affected.svg`,
      title: 'Bridge-not-affected',
    },
    {
      id: 'Bucket',
      src: `${baseURL}Bucket.svg`,
      title: 'Bucket',
    },
    {
      id: 'Buddhist-temple',
      src: `${baseURL}Buddhist-temple.svg`,
      title: 'Buddhist-temple',
    },
    {
      id: 'Building',
      src: `${baseURL}Building.svg`,
      title: 'Building',
    },
    {
      id: 'Building-closed',
      src: `${baseURL}Building-closed.svg`,
      title: 'Building-closed',
    },
    {
      id: 'Building-facility-affected',
      src: `${baseURL}Building-facility-affected.svg`,
      title: 'Building-facility-affected',
    },
    {
      id: 'Building-facility-destroyed',
      src: `${baseURL}Building-facility-destroyed.svg`,
      title: 'Building-facility-destroyed',
    },
    {
      id: 'Building-facility-not-affected',
      src: `${baseURL}Building-facility-not-affected.svg`,
      title: 'Building-facility-not-affected',
    },
    {
      id: 'Bus',
      src: `${baseURL}Bus.svg`,
      title: 'Bus',
    },
    {
      id: 'COVID-19',
      src: `${baseURL}COVID-19.svg`,
      title: 'COVID-19',
    },
    {
      id: 'CSV-file',
      src: `${baseURL}CSV-file.svg`,
      title: 'CSV-file',
    },
    {
      id: 'Calendar',
      src: `${baseURL}Calendar.svg`,
      title: 'Calendar',
    },
    {
      id: 'Camp-Coordination-and-Camp-Management',
      src: `${baseURL}Camp-Coordination-and-Camp-Management.svg`,
      title: 'Camp-Coordination-and-Camp-Management',
    },
    {
      id: 'Car',
      src: `${baseURL}Car.svg`,
      title: 'Car',
    },
    {
      id: 'Carjacking',
      src: `${baseURL}Carjacking.svg`,
      title: 'Carjacking',
    },
    {
      id: 'Case-management',
      src: `${baseURL}Case-management.svg`,
      title: 'Case-management',
    },
    {
      id: 'Cash-transfer',
      src: `${baseURL}Cash-transfer.svg`,
      title: 'Cash-transfer',
    },
    {
      id: 'Cell-tower',
      src: `${baseURL}Cell-tower.svg`,
      title: 'Cell-tower',
    },
    {
      id: 'Chart',
      src: `${baseURL}Chart.svg`,
      title: 'Chart',
    },
    {
      id: 'Chat',
      src: `${baseURL}Chat.svg`,
      title: 'Chat',
    },
    {
      id: 'Checked-mail',
      src: `${baseURL}Checked-mail.svg`,
      title: 'Checked-mail',
    },
    {
      id: 'Checkpoint',
      src: `${baseURL}Checkpoint.svg`,
      title: 'Checkpoint',
    },
    {
      id: 'Child-care-child-friendly',
      src: `${baseURL}Child-care-child-friendly.svg`,
      title: 'Child-care-child-friendly',
    },
    {
      id: 'Child-combatant',
      src: `${baseURL}Child-combatant.svg`,
      title: 'Child-combatant',
    },
    {
      id: 'Child-protection',
      src: `${baseURL}Child-protection.svg`,
      title: 'Child-protection',
    },
    {
      id: 'Children',
      src: `${baseURL}Children.svg`,
      title: 'Children',
    },
    {
      id: 'Church',
      src: `${baseURL}Church.svg`,
      title: 'Church',
    },
    {
      id: 'Civil-military-coordination',
      src: `${baseURL}Civil-military-coordination.svg`,
      title: 'Civil-military-coordination',
    },
    {
      id: 'Clinic',
      src: `${baseURL}Clinic.svg`,
      title: 'Clinic',
    },
    {
      id: 'Clothing',
      src: `${baseURL}Clothing.svg`,
      title: 'Clothing',
    },
    {
      id: 'Cold-wave',
      src: `${baseURL}Cold-wave.svg`,
      title: 'Cold-wave',
    },
    {
      id: 'Communal-latrine',
      src: `${baseURL}Communal-latrine.svg`,
      title: 'Communal-latrine',
    },
    {
      id: 'Community-building',
      src: `${baseURL}Community-building.svg`,
      title: 'Community-building',
    },
    {
      id: 'Community-engagement',
      src: `${baseURL}Community-engagement.svg`,
      title: 'Community-engagement',
    },
    {
      id: 'Computer',
      src: `${baseURL}Computer.svg`,
      title: 'Computer',
    },
    {
      id: 'Confined',
      src: `${baseURL}Confined.svg`,
      title: 'Confined',
    },
    {
      id: 'Conflict',
      src: `${baseURL}Conflict.svg`,
      title: 'Conflict',
    },
    {
      id: 'Coordinated-assessement',
      src: `${baseURL}Coordinated-assessement.svg`,
      title: 'Coordinated-assessement',
    },
    {
      id: 'Coordination',
      src: `${baseURL}Coordination.svg`,
      title: 'Coordination',
    },
    {
      id: 'Copy',
      src: `${baseURL}Copy.svg`,
      title: 'Copy',
    },
    {
      id: 'Country',
      src: `${baseURL}Country.svg`,
      title: 'Country',
    },
    {
      id: 'Cyclone',
      src: `${baseURL}Cyclone.svg`,
      title: 'Cyclone',
    },
    {
      id: 'DOCX-file',
      src: `${baseURL}DOCX-file.svg`,
      title: 'DOCX-file',
    },
    {
      id: 'Damaged-Affected',
      src: `${baseURL}Damaged-Affected.svg`,
      title: 'Damaged-Affected',
    },
    {
      id: 'Dangerous-area',
      src: `${baseURL}Dangerous-area.svg`,
      title: 'Dangerous-area',
    },
    {
      id: 'Data',
      src: `${baseURL}Data.svg`,
      title: 'Data',
    },
    {
      id: 'Dead',
      src: `${baseURL}Dead.svg`,
      title: 'Dead',
    },
    {
      id: 'Debris-management',
      src: `${baseURL}Debris-management.svg`,
      title: 'Debris-management',
    },
    {
      id: 'Delete-account',
      src: `${baseURL}Delete-account.svg`,
      title: 'Delete-account',
    },
    {
      id: 'Deployment',
      src: `${baseURL}Deployment.svg`,
      title: 'Deployment',
    },
    {
      id: 'Destroyed',
      src: `${baseURL}Destroyed.svg`,
      title: 'Destroyed',
    },
    {
      id: 'Detergent',
      src: `${baseURL}Detergent.svg`,
      title: 'Detergent',
    },
    {
      id: 'Diplomatic-mission',
      src: `${baseURL}Diplomatic-mission.svg`,
      title: 'Diplomatic-mission',
    },
    {
      id: 'Distribution-site',
      src: `${baseURL}Distribution-site.svg`,
      title: 'Distribution-site',
    },
    {
      id: 'Doctor',
      src: `${baseURL}Doctor.svg`,
      title: 'Doctor',
    },
    {
      id: 'Document',
      src: `${baseURL}Document.svg`,
      title: 'Document',
    },
    {
      id: 'Down',
      src: `${baseURL}Down.svg`,
      title: 'Down',
    },
    {
      id: 'Download',
      src: `${baseURL}Download.svg`,
      title: 'Download',
    },
    {
      id: 'Drought',
      src: `${baseURL}Drought.svg`,
      title: 'Drought',
    },
    {
      id: 'Drowned',
      src: `${baseURL}Drowned.svg`,
      title: 'Drowned',
    },
    {
      id: 'E-mail',
      src: `${baseURL}E-mail.svg`,
      title: 'E-mail',
    },
    {
      id: 'Early-Recovery',
      src: `${baseURL}Early-Recovery.svg`,
      title: 'Early-Recovery',
    },
    {
      id: 'Earthmound',
      src: `${baseURL}Earthmound.svg`,
      title: 'Earthmound',
    },
    {
      id: 'Earthquake',
      src: `${baseURL}Earthquake.svg`,
      title: 'Earthquake',
    },
    {
      id: 'Edit',
      src: `${baseURL}Edit.svg`,
      title: 'Edit',
    },
    {
      id: 'Education',
      src: `${baseURL}Education.svg`,
      title: 'Education',
    },
    {
      id: 'Elderly',
      src: `${baseURL}Elderly.svg`,
      title: 'Elderly',
    },
    {
      id: 'Emergency-Telecommunications',
      src: `${baseURL}Emergency-Telecommunications.svg`,
      title: 'Emergency-Telecommunications',
    },
    {
      id: 'Environment',
      src: `${baseURL}Environment.svg`,
      title: 'Environment',
    },
    {
      id: 'Epidemic',
      src: `${baseURL}Epidemic.svg`,
      title: 'Epidemic',
    },
    {
      id: 'Exit-Cancel',
      src: `${baseURL}Exit-Cancel.svg`,
      title: 'Exit-Cancel',
    },
    {
      id: 'Expand-down',
      src: `${baseURL}Expand-down.svg`,
      title: 'Expand-down',
    },
    {
      id: 'Expand-left',
      src: `${baseURL}Expand-left.svg`,
      title: 'Expand-left',
    },
    {
      id: 'Expand-right',
      src: `${baseURL}Expand-right.svg`,
      title: 'Expand-right',
    },
    {
      id: 'Expand-up',
      src: `${baseURL}Expand-up.svg`,
      title: 'Expand-up',
    },
    {
      id: 'Famine',
      src: `${baseURL}Famine.svg`,
      title: 'Famine',
    },
    {
      id: 'Favourite',
      src: `${baseURL}Favourite.svg`,
      title: 'Favourite',
    },
    {
      id: 'Fax',
      src: `${baseURL}Fax.svg`,
      title: 'Fax',
    },
    {
      id: 'Ferry',
      src: `${baseURL}Ferry.svg`,
      title: 'Ferry',
    },
    {
      id: 'Film',
      src: `${baseURL}Film.svg`,
      title: 'Film',
    },
    {
      id: 'Filter',
      src: `${baseURL}Filter.svg`,
      title: 'Filter',
    },
    {
      id: 'Financing',
      src: `${baseURL}Financing.svg`,
      title: 'Financing',
    },
    {
      id: 'Fire',
      src: `${baseURL}Fire.svg`,
      title: 'Fire',
    },
    {
      id: 'Fishery',
      src: `${baseURL}Fishery.svg`,
      title: 'Fishery',
    },
    {
      id: 'Flash-flood',
      src: `${baseURL}Flash-flood.svg`,
      title: 'Flash-flood',
    },
    {
      id: 'Flood',
      src: `${baseURL}Flood.svg`,
      title: 'Flood',
    },
    {
      id: 'Flour',
      src: `${baseURL}Flour.svg`,
      title: 'Flour',
    },
    {
      id: 'Folder',
      src: `${baseURL}Folder.svg`,
      title: 'Folder',
    },
    {
      id: 'Food',
      src: `${baseURL}Food.svg`,
      title: 'Food',
    },
    {
      id: 'Food-Security',
      src: `${baseURL}Food-Security.svg`,
      title: 'Food-Security',
    },
    {
      id: 'Food-warehouse',
      src: `${baseURL}Food-warehouse.svg`,
      title: 'Food-warehouse',
    },
    {
      id: 'Forced-entry',
      src: `${baseURL}Forced-entry.svg`,
      title: 'Forced-entry',
    },
    {
      id: 'Forced-recruitment',
      src: `${baseURL}Forced-recruitment.svg`,
      title: 'Forced-recruitment',
    },
    {
      id: 'Fund',
      src: `${baseURL}Fund.svg`,
      title: 'Fund',
    },
    {
      id: 'Gap-analysis',
      src: `${baseURL}Gap-analysis.svg`,
      title: 'Gap-analysis',
    },
    {
      id: 'Gas-station',
      src: `${baseURL}Gas-station.svg`,
      title: 'Gas-station',
    },
    {
      id: 'Gender',
      src: `${baseURL}Gender.svg`,
      title: 'Gender',
    },
    {
      id: 'Gender-based-violence',
      src: `${baseURL}Gender-based-violence.svg`,
      title: 'Gender-based-violence',
    },
    {
      id: 'Go',
      src: `${baseURL}Go.svg`,
      title: 'Go',
    },
    {
      id: 'Government-office',
      src: `${baseURL}Government-office.svg`,
      title: 'Government-office',
    },
    {
      id: 'Group',
      src: `${baseURL}Group.svg`,
      title: 'Group',
    },
    {
      id: 'Handwashing',
      src: `${baseURL}Handwashing.svg`,
      title: 'Handwashing',
    },
    {
      id: 'Harassment-intimidation',
      src: `${baseURL}Harassment-intimidation.svg`,
      title: 'Harassment-intimidation',
    },
    {
      id: 'Health',
      src: `${baseURL}Health.svg`,
      title: 'Health',
    },
    {
      id: 'Health-facility',
      src: `${baseURL}Health-facility.svg`,
      title: 'Health-facility',
    },
    {
      id: 'Health-facility-affected',
      src: `${baseURL}Health-facility-affected.svg`,
      title: 'Health-facility-affected',
    },
    {
      id: 'Health-facility-destroyed',
      src: `${baseURL}Health-facility-destroyed.svg`,
      title: 'Health-facility-destroyed',
    },
    {
      id: 'Health-facility-not-affected',
      src: `${baseURL}Health-facility-not-affected.svg`,
      title: 'Health-facility-not-affected',
    },
    {
      id: 'Health-post',
      src: `${baseURL}Health-post.svg`,
      title: 'Health-post',
    },
    {
      id: 'Health-worker',
      src: `${baseURL}Health-worker.svg`,
      title: 'Health-worker',
    },
    {
      id: 'Heatwave',
      src: `${baseURL}Heatwave.svg`,
      title: 'Heatwave',
    },
    {
      id: 'Heavy-rain',
      src: `${baseURL}Heavy-rain.svg`,
      title: 'Heavy-rain',
    },
    {
      id: 'Helicopter',
      src: `${baseURL}Helicopter.svg`,
      title: 'Helicopter',
    },
    {
      id: 'Helipad',
      src: `${baseURL}Helipad.svg`,
      title: 'Helipad',
    },
    {
      id: 'Help',
      src: `${baseURL}Help.svg`,
      title: 'Help',
    },
    {
      id: 'Hidden',
      src: `${baseURL}Hidden.svg`,
      title: 'Hidden',
    },
    {
      id: 'Hindu-temple',
      src: `${baseURL}Hindu-temple.svg`,
      title: 'Hindu-temple',
    },
    {
      id: 'Hospital',
      src: `${baseURL}Hospital.svg`,
      title: 'Hospital',
    },
    {
      id: 'Hospital-bed',
      src: `${baseURL}Hospital-bed.svg`,
      title: 'Hospital-bed',
    },
    {
      id: 'Hotel',
      src: `${baseURL}Hotel.svg`,
      title: 'Hotel',
    },
    {
      id: 'House',
      src: `${baseURL}House.svg`,
      title: 'House',
    },
    {
      id: 'House-affected',
      src: `${baseURL}House-affected.svg`,
      title: 'House-affected',
    },
    {
      id: 'House-burned',
      src: `${baseURL}House-burned.svg`,
      title: 'House-burned',
    },
    {
      id: 'House-destroyed',
      src: `${baseURL}House-destroyed.svg`,
      title: 'House-destroyed',
    },
    {
      id: 'House-lockdown',
      src: `${baseURL}House-lockdown.svg`,
      title: 'House-lockdown',
    },
    {
      id: 'House-not-affected',
      src: `${baseURL}House-not-affected.svg`,
      title: 'House-not-affected',
    },
    {
      id: 'Humanitarian-access',
      src: `${baseURL}Humanitarian-access.svg`,
      title: 'Humanitarian-access',
    },
    {
      id: 'Humanitarian-programme-cycle',
      src: `${baseURL}Humanitarian-programme-cycle.svg`,
      title: 'Humanitarian-programme-cycle',
    },
    {
      id: 'IDP-refugee-camp',
      src: `${baseURL}IDP-refugee-camp.svg`,
      title: 'IDP-refugee-camp',
    },
    {
      id: 'Indigenous people',
      src: `${baseURL}Indigenous people.svg`,
      title: 'Indigenous people',
    },
    {
      id: 'Infant',
      src: `${baseURL}Infant.svg`,
      title: 'Infant',
    },
    {
      id: 'Infant-formula',
      src: `${baseURL}Infant-formula.svg`,
      title: 'Infant-formula',
    },
    {
      id: 'Infected',
      src: `${baseURL}Infected.svg`,
      title: 'Infected',
    },
    {
      id: 'Infection-control',
      src: `${baseURL}Infection-control.svg`,
      title: 'Infection-control',
    },
    {
      id: 'Information-management',
      src: `${baseURL}Information-management.svg`,
      title: 'Information-management',
    },
    {
      id: 'Information-technology',
      src: `${baseURL}Information-technology.svg`,
      title: 'Information-technology',
    },
    {
      id: 'Infrastructure',
      src: `${baseURL}Infrastructure.svg`,
      title: 'Infrastructure',
    },
    {
      id: 'Injured',
      src: `${baseURL}Injured.svg`,
      title: 'Injured',
    },
    {
      id: 'Innovation',
      src: `${baseURL}Innovation.svg`,
      title: 'Innovation',
    },
    {
      id: 'Insect-infestation',
      src: `${baseURL}Insect-infestation.svg`,
      title: 'Insect-infestation',
    },
    {
      id: 'Internally-displaced',
      src: `${baseURL}Internally-displaced.svg`,
      title: 'Internally-displaced',
    },
    {
      id: 'Internet',
      src: `${baseURL}Internet.svg`,
      title: 'Internet',
    },
    {
      id: 'Landslide-mudslide',
      src: `${baseURL}Landslide-mudslide.svg`,
      title: 'Landslide-mudslide',
    },
    {
      id: 'Laptop',
      src: `${baseURL}Laptop.svg`,
      title: 'Laptop',
    },
    {
      id: 'Latrine-cabin',
      src: `${baseURL}Latrine-cabin.svg`,
      title: 'Latrine-cabin',
    },
    {
      id: 'Leadership',
      src: `${baseURL}Leadership.svg`,
      title: 'Leadership',
    },
    {
      id: 'Learning',
      src: `${baseURL}Learning.svg`,
      title: 'Learning',
    },
    {
      id: 'Life-saving',
      src: `${baseURL}Life-saving.svg`,
      title: 'Life-saving',
    },
    {
      id: 'Link',
      src: `${baseURL}Link.svg`,
      title: 'Link',
    },
    {
      id: 'Livelihood',
      src: `${baseURL}Livelihood.svg`,
      title: 'Livelihood',
    },
    {
      id: 'Livestock',
      src: `${baseURL}Livestock.svg`,
      title: 'Livestock',
    },
    {
      id: 'Location',
      src: `${baseURL}Location.svg`,
      title: 'Location',
    },
    {
      id: 'Location-lockdown',
      src: `${baseURL}Location-lockdown.svg`,
      title: 'Location-lockdown',
    },
    {
      id: 'Locust-infestation',
      src: `${baseURL}Locust-infestation.svg`,
      title: 'Locust-infestation',
    },
    {
      id: 'Logistics',
      src: `${baseURL}Logistics.svg`,
      title: 'Logistics',
    },
    {
      id: 'Map',
      src: `${baseURL}Map.svg`,
      title: 'Map',
    },
    {
      id: 'Market',
      src: `${baseURL}Market.svg`,
      title: 'Market',
    },
    {
      id: 'Market-closed',
      src: `${baseURL}Market-closed.svg`,
      title: 'Market-closed',
    },
    {
      id: 'Mask',
      src: `${baseURL}Mask.svg`,
      title: 'Mask',
    },
    {
      id: 'Mattress',
      src: `${baseURL}Mattress.svg`,
      title: 'Mattress',
    },
    {
      id: 'Medical-supply',
      src: `${baseURL}Medical-supply.svg`,
      title: 'Medical-supply',
    },
    {
      id: 'Medicine',
      src: `${baseURL}Medicine.svg`,
      title: 'Medicine',
    },
    {
      id: 'Meeting',
      src: `${baseURL}Meeting.svg`,
      title: 'Meeting',
    },
    {
      id: 'Menu',
      src: `${baseURL}Menu.svg`,
      title: 'Menu',
    },
    {
      id: 'Military-gate',
      src: `${baseURL}Military-gate.svg`,
      title: 'Military-gate',
    },
    {
      id: 'Mine',
      src: `${baseURL}Mine.svg`,
      title: 'Mine',
    },
    {
      id: 'Missing',
      src: `${baseURL}Missing.svg`,
      title: 'Missing',
    },
    {
      id: 'Mobile-clinic',
      src: `${baseURL}Mobile-clinic.svg`,
      title: 'Mobile-clinic',
    },
    {
      id: 'Mobile-phone',
      src: `${baseURL}Mobile-phone.svg`,
      title: 'Mobile-phone',
    },
    {
      id: 'Monitor',
      src: `${baseURL}Monitor.svg`,
      title: 'Monitor',
    },
    {
      id: 'Monitoring',
      src: `${baseURL}Monitoring.svg`,
      title: 'Monitoring',
    },
    {
      id: 'More-options',
      src: `${baseURL}More-options.svg`,
      title: 'More-options',
    },
    {
      id: 'Mosque',
      src: `${baseURL}Mosque.svg`,
      title: 'Mosque',
    },
    {
      id: 'Mosquito-net',
      src: `${baseURL}Mosquito-net.svg`,
      title: 'Mosquito-net',
    },
    {
      id: 'Multi-cluster-sector',
      src: `${baseURL}Multi-cluster-sector.svg`,
      title: 'Multi-cluster-sector',
    },
    {
      id: 'Murder',
      src: `${baseURL}Murder.svg`,
      title: 'Murder',
    },
    {
      id: 'NGO-office',
      src: `${baseURL}NGO-office.svg`,
      title: 'NGO-office',
    },
    {
      id: 'National-army',
      src: `${baseURL}National-army.svg`,
      title: 'National-army',
    },
    {
      id: 'Needs-assessment',
      src: `${baseURL}Needs-assessment.svg`,
      title: 'Needs-assessment',
    },
    {
      id: 'Next-item',
      src: `${baseURL}Next-item.svg`,
      title: 'Next-item',
    },
    {
      id: 'Non-food-items',
      src: `${baseURL}Non-food-items.svg`,
      title: 'Non-food-items',
    },
    {
      id: 'Non-food-items-2',
      src: `${baseURL}Non-food-items-2.svg`,
      title: 'Non-food-items-2',
    },
    {
      id: 'Not-affected',
      src: `${baseURL}Not-affected.svg`,
      title: 'Not-affected',
    },
    {
      id: 'Not-infected',
      src: `${baseURL}Not-infected.svg`,
      title: 'Not-infected',
    },
    {
      id: 'Not-secured',
      src: `${baseURL}Not-secured.svg`,
      title: 'Not-secured',
    },
    {
      id: 'Notification',
      src: `${baseURL}Notification.svg`,
      title: 'Notification',
    },
    {
      id: 'Nutrition',
      src: `${baseURL}Nutrition.svg`,
      title: 'Nutrition',
    },
    {
      id: 'Observation-tower',
      src: `${baseURL}Observation-tower.svg`,
      title: 'Observation-tower',
    },
    {
      id: 'Oil',
      src: `${baseURL}Oil.svg`,
      title: 'Oil',
    },
    {
      id: 'Oil-facility',
      src: `${baseURL}Oil-facility.svg`,
      title: 'Oil-facility',
    },
    {
      id: 'Out-of-platform',
      src: `${baseURL}Out-of-platform.svg`,
      title: 'Out-of-platform',
    },
    {
      id: 'PDF-file',
      src: `${baseURL}PDF-file.svg`,
      title: 'PDF-file',
    },
    {
      id: 'Partnership',
      src: `${baseURL}Partnership.svg`,
      title: 'Partnership',
    },
    {
      id: 'Password',
      src: `${baseURL}Password.svg`,
      title: 'Password',
    },
    {
      id: 'Pause',
      src: `${baseURL}Pause.svg`,
      title: 'Pause',
    },
    {
      id: 'Peacekeeping-force',
      src: `${baseURL}Peacekeeping-force.svg`,
      title: 'Peacekeeping-force',
    },
    {
      id: 'People-in-need',
      src: `${baseURL}People-in-need.svg`,
      title: 'People-in-need',
    },
    {
      id: 'People-targeted',
      src: `${baseURL}People-targeted.svg`,
      title: 'People-targeted',
    },
    {
      id: 'People-with-physical-impairments',
      src: `${baseURL}People-with-physical-impairments.svg`,
      title: 'People-with-physical-impairments',
    },
    {
      id: 'Permanent-camp',
      src: `${baseURL}Permanent-camp.svg`,
      title: 'Permanent-camp',
    },
    {
      id: 'Person-1',
      src: `${baseURL}Person-1.svg`,
      title: 'Person-1',
    },
    {
      id: 'Person-2',
      src: `${baseURL}Person-2.svg`,
      title: 'Person-2',
    },
    {
      id: 'Photo',
      src: `${baseURL}Photo.svg`,
      title: 'Photo',
    },
    {
      id: 'Physical-closure',
      src: `${baseURL}Physical-closure.svg`,
      title: 'Physical-closure',
    },
    {
      id: 'Physical-distancing',
      src: `${baseURL}Physical-distancing.svg`,
      title: 'Physical-distancing',
    },
    {
      id: 'Plastic-sheeting',
      src: `${baseURL}Plastic-sheeting.svg`,
      title: 'Plastic-sheeting',
    },
    {
      id: 'Police-station',
      src: `${baseURL}Police-station.svg`,
      title: 'Police-station',
    },
    {
      id: 'Policy',
      src: `${baseURL}Policy.svg`,
      title: 'Policy',
    },
    {
      id: 'Population-growth',
      src: `${baseURL}Population-growth.svg`,
      title: 'Population-growth',
    },
    {
      id: 'Population-return',
      src: `${baseURL}Population-return.svg`,
      title: 'Population-return',
    },
    {
      id: 'Port',
      src: `${baseURL}Port.svg`,
      title: 'Port',
    },
    {
      id: 'Port-affected',
      src: `${baseURL}Port-affected.svg`,
      title: 'Port-affected',
    },
    {
      id: 'Port-closed',
      src: `${baseURL}Port-closed.svg`,
      title: 'Port-closed',
    },
    {
      id: 'Port-destroyed',
      src: `${baseURL}Port-destroyed.svg`,
      title: 'Port-destroyed',
    },
    {
      id: 'Port-not-affected',
      src: `${baseURL}Port-not-affected.svg`,
      title: 'Port-not-affected',
    },
    {
      id: 'Potable-water',
      src: `${baseURL}Potable-water.svg`,
      title: 'Potable-water',
    },
    {
      id: 'Potable-water-source',
      src: `${baseURL}Potable-water-source.svg`,
      title: 'Potable-water-source',
    },
    {
      id: 'Poverty',
      src: `${baseURL}Poverty.svg`,
      title: 'Poverty',
    },
    {
      id: 'Power-electricity',
      src: `${baseURL}Power-electricity.svg`,
      title: 'Power-electricity',
    },
    {
      id: 'Power-electricity-affected',
      src: `${baseURL}Power-electricity-affected.svg`,
      title: 'Power-electricity-affected',
    },
    {
      id: 'Power-electricity-not-affected',
      src: `${baseURL}Power-electricity-not-affected.svg`,
      title: 'Power-electricity-not-affected',
    },
    {
      id: 'Power-outage',
      src: `${baseURL}Power-outage.svg`,
      title: 'Power-outage',
    },
    {
      id: 'Pregnant',
      src: `${baseURL}Pregnant.svg`,
      title: 'Pregnant',
    },
    {
      id: 'Preparedness',
      src: `${baseURL}Preparedness.svg`,
      title: 'Preparedness',
    },
    {
      id: 'Previous-item',
      src: `${baseURL}Previous-item.svg`,
      title: 'Previous-item',
    },
    {
      id: 'Print',
      src: `${baseURL}Print.svg`,
      title: 'Print',
    },
    {
      id: 'Protection',
      src: `${baseURL}Protection.svg`,
      title: 'Protection',
    },
    {
      id: 'Public-information',
      src: `${baseURL}Public-information.svg`,
      title: 'Public-information',
    },
    {
      id: 'Radio',
      src: `${baseURL}Radio.svg`,
      title: 'Radio',
    },
    {
      id: 'Rebel',
      src: `${baseURL}Rebel.svg`,
      title: 'Rebel',
    },
    {
      id: 'Reconstruction',
      src: `${baseURL}Reconstruction.svg`,
      title: 'Reconstruction',
    },
    {
      id: 'Refugee',
      src: `${baseURL}Refugee.svg`,
      title: 'Refugee',
    },
    {
      id: 'Registration',
      src: `${baseURL}Registration.svg`,
      title: 'Registration',
    },
    {
      id: 'Relief-goods',
      src: `${baseURL}Relief-goods.svg`,
      title: 'Relief-goods',
    },
    {
      id: 'Remote-support',
      src: `${baseURL}Remote-support.svg`,
      title: 'Remote-support',
    },
    {
      id: 'Remove',
      src: `${baseURL}Remove.svg`,
      title: 'Remove',
    },
    {
      id: 'Remove-document',
      src: `${baseURL}Remove-document.svg`,
      title: 'Remove-document',
    },
    {
      id: 'Report',
      src: `${baseURL}Report.svg`,
      title: 'Report',
    },
    {
      id: 'Reporting',
      src: `${baseURL}Reporting.svg`,
      title: 'Reporting',
    },
    {
      id: 'Resilience',
      src: `${baseURL}Resilience.svg`,
      title: 'Resilience',
    },
    {
      id: 'Respiratory',
      src: `${baseURL}Respiratory.svg`,
      title: 'Respiratory',
    },
    {
      id: 'Response',
      src: `${baseURL}Response.svg`,
      title: 'Response',
    },
    {
      id: 'Return',
      src: `${baseURL}Return.svg`,
      title: 'Return',
    },
    {
      id: 'Rice',
      src: `${baseURL}Rice.svg`,
      title: 'Rice',
    },
    {
      id: 'Road',
      src: `${baseURL}Road.svg`,
      title: 'Road',
    },
    {
      id: 'Road-affected',
      src: `${baseURL}Road-affected.svg`,
      title: 'Road-affected',
    },
    {
      id: 'Road-barrier',
      src: `${baseURL}Road-barrier.svg`,
      title: 'Road-barrier',
    },
    {
      id: 'Road-destroyed',
      src: `${baseURL}Road-destroyed.svg`,
      title: 'Road-destroyed',
    },
    {
      id: 'Road-not-affected',
      src: `${baseURL}Road-not-affected.svg`,
      title: 'Road-not-affected',
    },
    {
      id: 'Roadblock',
      src: `${baseURL}Roadblock.svg`,
      title: 'Roadblock',
    },
    {
      id: 'Robbery',
      src: `${baseURL}Robbery.svg`,
      title: 'Robbery',
    },
    {
      id: 'Rule-of-law-and-justice',
      src: `${baseURL}Rule-of-law-and-justice.svg`,
      title: 'Rule-of-law-and-justice',
    },
    {
      id: 'Rural',
      src: `${baseURL}Rural.svg`,
      title: 'Rural',
    },
    {
      id: 'Rural-exodus',
      src: `${baseURL}Rural-exodus.svg`,
      title: 'Rural-exodus',
    },
    {
      id: 'Safety-and-security',
      src: `${baseURL}Safety-and-security.svg`,
      title: 'Safety-and-security',
    },
    {
      id: 'Salt',
      src: `${baseURL}Salt.svg`,
      title: 'Salt',
    },
    {
      id: 'Sanitation',
      src: `${baseURL}Sanitation.svg`,
      title: 'Sanitation',
    },
    {
      id: 'Sanitizer',
      src: `${baseURL}Sanitizer.svg`,
      title: 'Sanitizer',
    },
    {
      id: 'Satellite-dish',
      src: `${baseURL}Satellite-dish.svg`,
      title: 'Satellite-dish',
    },
    {
      id: 'Save',
      src: `${baseURL}Save.svg`,
      title: 'Save',
    },
    {
      id: 'Scale-down-operation',
      src: `${baseURL}Scale-down-operation.svg`,
      title: 'Scale-down-operation',
    },
    {
      id: 'Scale-up-operation',
      src: `${baseURL}Scale-up-operation.svg`,
      title: 'Scale-up-operation',
    },
    {
      id: 'School',
      src: `${baseURL}School.svg`,
      title: 'School',
    },
    {
      id: 'School-affected',
      src: `${baseURL}School-affected.svg`,
      title: 'School-affected',
    },
    {
      id: 'School-closed',
      src: `${baseURL}School-closed.svg`,
      title: 'School-closed',
    },
    {
      id: 'School-destroyed',
      src: `${baseURL}School-destroyed.svg`,
      title: 'School-destroyed',
    },
    {
      id: 'School-not-affected',
      src: `${baseURL}School-not-affected.svg`,
      title: 'School-not-affected',
    },
    {
      id: 'Search',
      src: `${baseURL}Search.svg`,
      title: 'Search',
    },
    {
      id: 'Search-and-rescue',
      src: `${baseURL}Search-and-rescue.svg`,
      title: 'Search-and-rescue',
    },
    {
      id: 'Secured',
      src: `${baseURL}Secured.svg`,
      title: 'Secured',
    },
    {
      id: 'Security',
      src: `${baseURL}Security.svg`,
      title: 'Security',
    },
    {
      id: 'See',
      src: `${baseURL}See.svg`,
      title: 'See',
    },
    {
      id: 'Selected',
      src: `${baseURL}Selected.svg`,
      title: 'Selected',
    },
    {
      id: 'Services-and-tools',
      src: `${baseURL}Services-and-tools.svg`,
      title: 'Services-and-tools',
    },
    {
      id: 'Settings',
      src: `${baseURL}Settings.svg`,
      title: 'Settings',
    },
    {
      id: 'Sexual-and-reproductive-health',
      src: `${baseURL}Sexual-and-reproductive health.svg`,
      title: 'Sexual-and-reproductive-health',
    },
    {
      id: 'Sexual-violence',
      src: `${baseURL}Sexual-violence.svg`,
      title: 'Sexual-violence',
    },
    {
      id: 'Share',
      src: `${baseURL}Share.svg`,
      title: 'Share',
    },
    {
      id: 'Shelter',
      src: `${baseURL}Shelter.svg`,
      title: 'Shelter',
    },
    {
      id: 'Ship',
      src: `${baseURL}Ship.svg`,
      title: 'Ship',
    },
    {
      id: 'Shower',
      src: `${baseURL}Shower.svg`,
      title: 'Shower',
    },
    {
      id: 'Smartphone',
      src: `${baseURL}Smartphone.svg`,
      title: 'Smartphone',
    },
    {
      id: 'Snow-avalanche',
      src: `${baseURL}Snow-avalanche.svg`,
      title: 'Snow-avalanche',
    },
    {
      id: 'Snowfall',
      src: `${baseURL}Snowfall.svg`,
      title: 'Snowfall',
    },
    {
      id: 'Soap',
      src: `${baseURL}Soap.svg`,
      title: 'Soap',
    },
    {
      id: 'Solid-waste',
      src: `${baseURL}Solid-waste.svg`,
      title: 'Solid-waste',
    },
    {
      id: 'Spontaneous-site',
      src: `${baseURL}Spontaneous-site.svg`,
      title: 'Spontaneous-site',
    },
    {
      id: 'Spring-water',
      src: `${baseURL}Spring-water.svg`,
      title: 'Spring-water',
    },
    {
      id: 'Staff-management',
      src: `${baseURL}Staff-management.svg`,
      title: 'Staff-management',
    },
    {
      id: 'Stop',
      src: `${baseURL}Stop.svg`,
      title: 'Stop',
    },
    {
      id: 'Storm',
      src: `${baseURL}Storm.svg`,
      title: 'Storm',
    },
    {
      id: 'Storm-surge',
      src: `${baseURL}Storm-surge.svg`,
      title: 'Storm-surge',
    },
    {
      id: 'Stove',
      src: `${baseURL}Stove.svg`,
      title: 'Stove',
    },
    {
      id: 'Submersible-pump',
      src: `${baseURL}Submersible-pump.svg`,
      title: 'Submersible-pump',
    },
    {
      id: 'Sugar',
      src: `${baseURL}Sugar.svg`,
      title: 'Sugar',
    },
    {
      id: 'Table',
      src: `${baseURL}Table.svg`,
      title: 'Table',
    },
    {
      id: 'Tarpaulin',
      src: `${baseURL}Tarpaulin.svg`,
      title: 'Tarpaulin',
    },
    {
      id: 'Technological-disaster',
      src: `${baseURL}Technological-disaster.svg`,
      title: 'Technological-disaster',
    },
    {
      id: 'Temporary-camp',
      src: `${baseURL}Temporary-camp.svg`,
      title: 'Temporary-camp',
    },
    {
      id: 'Tent',
      src: `${baseURL}Tent.svg`,
      title: 'Tent',
    },
    {
      id: 'Testing',
      src: `${baseURL}Testing.svg`,
      title: 'Testing',
    },
    {
      id: 'Time',
      src: `${baseURL}Time.svg`,
      title: 'Time',
    },
    {
      id: 'Toilet',
      src: `${baseURL}Toilet.svg`,
      title: 'Toilet',
    },
    {
      id: 'Top-ranking',
      src: `${baseURL}Top-ranking.svg`,
      title: 'Top-ranking',
    },
    {
      id: 'Tornado',
      src: `${baseURL}Tornado.svg`,
      title: 'Tornado',
    },
    {
      id: 'Trade-and-market',
      src: `${baseURL}Trade-and-market.svg`,
      title: 'Trade-and-market',
    },
    {
      id: 'Train',
      src: `${baseURL}Train.svg`,
      title: 'Train',
    },
    {
      id: 'Training',
      src: `${baseURL}Training.svg`,
      title: 'Training',
    },
    {
      id: 'Transition-site',
      src: `${baseURL}Transition-site.svg`,
      title: 'Transition-site',
    },
    {
      id: 'Trending',
      src: `${baseURL}Trending.svg`,
      title: 'Trending',
    },
    {
      id: 'Truck',
      src: `${baseURL}Truck.svg`,
      title: 'Truck',
    },
    {
      id: 'Tsunami',
      src: `${baseURL}Tsunami.svg`,
      title: 'Tsunami',
    },
    {
      id: 'Tunnel',
      src: `${baseURL}Tunnel.svg`,
      title: 'Tunnel',
    },
    {
      id: 'UN-compound-office',
      src: `${baseURL}UN-compound-office.svg`,
      title: 'UN-compound-office',
    },
    {
      id: 'UN-vehicle',
      src: `${baseURL}UN-vehicle.svg`,
      title: 'UN-vehicle',
    },
    {
      id: 'University',
      src: `${baseURL}University.svg`,
      title: 'University',
    },
    {
      id: 'Up',
      src: `${baseURL}Up.svg`,
      title: 'Up',
    },
    {
      id: 'Upload',
      src: `${baseURL}Upload.svg`,
      title: 'Upload',
    },
    {
      id: 'Urban',
      src: `${baseURL}Urban.svg`,
      title: 'Urban',
    },
    {
      id: 'Urban-rural',
      src: `${baseURL}Urban-rural.svg`,
      title: 'Urban-rural',
    },
    {
      id: 'User',
      src: `${baseURL}User.svg`,
      title: 'User',
    },
    {
      id: 'Users',
      src: `${baseURL}Users.svg`,
      title: 'Users',
    },
    {
      id: 'Vaccine',
      src: `${baseURL}Vaccine.svg`,
      title: 'Vaccine',
    },
    {
      id: 'Validate-account',
      src: `${baseURL}Validate-account.svg`,
      title: 'Validate-account',
    },
    {
      id: 'Ventilator',
      src: `${baseURL}Ventilator.svg`,
      title: 'Ventilator',
    },
    {
      id: 'Video-',
      src: `${baseURL}Video-.svg`,
      title: 'Video-',
    },
    {
      id: 'Violent-wind',
      src: `${baseURL}Violent-wind.svg`,
      title: 'Violent-wind',
    },
    {
      id: 'Virus',
      src: `${baseURL}Virus.svg`,
      title: 'Virus',
    },
    {
      id: 'Volcano',
      src: `${baseURL}Volcano.svg`,
      title: 'Volcano',
    },
    {
      id: 'Walkie-talkie',
      src: `${baseURL}Walkie-talkie.svg`,
      title: 'Walkie-talkie',
    },
    {
      id: 'Warning-Error',
      src: `${baseURL}Warning-Error.svg`,
      title: 'Warning-Error',
    },
    {
      id: 'Water-Sanitation-and-Hygiene',
      src: `${baseURL}Water-Sanitation-and-Hygiene.svg`,
      title: 'Water-Sanitation-and-Hygiene',
    },
    {
      id: 'Water-source',
      src: `${baseURL}Water-source.svg`,
      title: 'Water-source',
    },
    {
      id: 'Water-trucking',
      src: `${baseURL}Water-trucking.svg`,
      title: 'Water-trucking',
    },
    {
      id: 'Work-from-home',
      src: `${baseURL}Work-from-home.svg`,
      title: 'Work-from-home',
    },
    {
      id: 'Worm-infestation',
      src: `${baseURL}Worm-infestation.svg`,
      title: 'Worm-infestation',
    },
    {
      id: 'XLSX-file',
      src: `${baseURL}XLSX-file.svg`,
      title: 'XLSX-file',
    },
    {
      id: 'ZIP-compressed',
      src: `${baseURL}ZIP-compressed.svg`,
      title: 'ZIP-compressed',
    },
  ];
};
