import { ENV, IDENTITY_API } from '../../configs/env';
import { AkukoAPIService } from '../../services/serviceClass';

export const userCanNotChangeRole = (props) => {
  const { user, space } = props;

  if (user.spaces) {
    const account = user.spaces.find((userSpace) => space.id === userSpace.id);

    if (account && account.role === 'admin') {
      return false;
    }
  }

  return true;
};

export const generatePassword = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 12; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const userIsAddedToAccount = (email, props) => {
  let isAdded = false;
  props.space.users.forEach((item) => {
    if (item.email === email) {
      isAdded = true;
    }
  });
  return isAdded;
};

export const getAccount = (props) => {
  if (ENV === 'dev') {
    return 'ona';
  }
  if (ENV === 'stg') {
    return 'ona';
  }
  if (ENV === 'prod') {
    const domain = window.location.hostname.split('.');
    const account = domain[0];
    return account;
  }
};

export const getAccountUrl = (accountId) => {
  if (ENV === 'dev') {
    return `/${accountId}`;
  }
  if (ENV === 'stg') {
    return `/${accountId}`;
  }
  if (ENV === 'prod') {
    return `https://${accountId}.akuko.io/${accountId}`;
  }
};

export const userCanEdit = (props, spaceId) => {
  let canEdit = false;
  if (props.post.account_id === props.user.handle) {
    canEdit = true;
    return canEdit;
  }
  if (props.source.account_id === props.user.handle) {
    canEdit = true;
    return canEdit;
  }
  if (props.user.spaces) {
    props.user.spaces.forEach((item) => {
      if (item.id === spaceId) {
        if (item.role === 'editor' || item.role === 'admin') {
          canEdit = true;
        }
      }
    });
  }
  return canEdit;
};

export const userIsAdmin = (props, spaceId) => {
  let isAdmin = false;
  if (props.post.account_id === props.user.handle) {
    isAdmin = true;
    return isAdmin;
  }
  if (props.source.account_id === props.user.handle) {
    isAdmin = true;
    return isAdmin;
  }
  if (props.user.spaces) {
    props.user.spaces.forEach((item) => {
      if (item.id === spaceId) {
        if (item.role === 'admin') {
          isAdmin = true;
        }
      }
    });
  }
  return isAdmin;
};

export const getAccountRole = (props) => {
  const account = localStorage.getItem('account');
  let role;

  const service = new AkukoAPIService(IDENTITY_API, `user`);

  service.read(props.user.email).then((response) => {
    props.actionUserGet(response);

    const { config } = response;

    config.accounts.forEach((item) => {
      if (item.id === account) {
        if (item.role) {
          role = item.role;
        }
      }
    });

    if (!role) {
      role = 'admin';
    }
  });
};

/*
 * Get the active account
 */
export const getActiveAccount = (props) => {
  let account;

  if (props.location && props.location.search) {
    const search = props.location.search.split('=');
    account = search[1];
    return account;
  }

  if (isPost(props)) {
    account = props.post.account_id;
    localStorage.setItem('account', account);
    return account;
  }

  if (isSource(props)) {
    account = props.source.account_id || localStorage.getItem('account');
    localStorage.setItem('account', account);
    return account;
  }

  if (isSources(props)) {
    account = props.match.params.id;
    localStorage.setItem('account', account);
    return account;
  }

  if (isAccount(props)) {
    account = props.account.id;
    localStorage.setItem('account', account);
    return account;
  }

  return localStorage.getItem('account');
};

export const isPost = (props) => {
  if (props.location && props.location.pathname.includes('/post/')) {
    return true;
  } else {
    return false;
  }
};

export const isSource = (props) => {
  if (props.location && props.location.pathname.includes('/source/')) {
    return true;
  } else {
    return false;
  }
};

export const isSources = (props) => {
  if (props.location && props.location.pathname.includes('sources')) {
    return true;
  } else {
    return false;
  }
};

export const isAccount = (props) => {
  if (props.location && props.location.pathname) {
    const args = props.location.pathname.split('/');
    if (args.length === 2) {
      return true;
    }
  }
  return false;
};
