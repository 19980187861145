import React from 'react';
import { Dictionary } from '@onaio/utils';
import { Input, Select, Slider, DatePicker } from 'antd';
import { Dispatch } from 'redux';
import { actionComponentFilterEdit } from '../../actions';
import { FilterOption } from '../../../../configs/component-types';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/lib/date-picker';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

const { Option } = Select;
const { RangePicker } = DatePicker;
/** dayjs support hack https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400 */
dayjs.extend(weekday);
dayjs.extend(localeData);

export interface InputTypesProps {
  slider: boolean | undefined;
  textInput: boolean | undefined;
  datePicker: boolean | undefined;
}

export interface stateMethodProps {
  setFilterValue: (a: string) => void;
  setSliderValue: (a: number | number[]) => void;
  dispatch: Dispatch;
  editFilterActionCreator: typeof actionComponentFilterEdit | undefined;
}

export interface configProps {
  componentIndex: number;
  filterIndex: number;
  sliderIndex: number;
  sliderValue: number | number[];
  filterObj: FilterOption;
  showActualDateFormat: boolean | undefined;
  dataType: string | undefined;
  filterValue: string | undefined;
  property: string;
  cube: string;
  rangeFilterValue: [number, number];
  filterObjs: FilterOption[];
  cascade?: boolean;
}

export interface filterInputsDataProps {
  data: Dictionary[];
  optionsArray: string[];
}

export const buildUniqueFilterOptionValues = (
  data: Dictionary[],
  source: Dictionary,
  property: string,
  separateTextByCommas?: boolean
): string[] => {
  // create unique filter option values splitting on comma
  const optionsObj: Dictionary = {};
  const optionsArray: string[] = [];
  data.forEach((item: Dictionary) => {
    const value = item[`${source.cube}.${property}`];
    let split;
    if (value && value.split && separateTextByCommas) {
      split = value.split(',');
    }
    if (split && separateTextByCommas) {
      split.forEach((option: string) => {
        const optionTrimmed = String(option).trim();
        optionsObj[optionTrimmed] = {};
      });
    } else {
      if (separateTextByCommas) {
        const valueTrimmed = String(value).trim();
        optionsObj[valueTrimmed] = {};
      } else {
        optionsObj[value] = {};
      }
    }
  });
  Object.keys(optionsObj).forEach((key) => {
    if (key !== 'null') {
      optionsArray.push(key);
    }
  });
  return optionsArray;
};

export const getComponent = (
  type: string,
  stateMethods: stateMethodProps,
  configs: configProps,
  filterInputData: filterInputsDataProps
): JSX.Element => {
  const { editFilterActionCreator, dispatch, setFilterValue, setSliderValue } =
    stateMethods;
  const {
    componentIndex,
    filterIndex,
    filterValue,
    property,
    cube,
    filterObj,
    filterObjs,
    sliderIndex,
    showActualDateFormat,
    dataType,
    rangeFilterValue,
    cascade,
  } = configs;
  const { data, optionsArray } = filterInputData;

  const minDate = optionsArray[0];
  const maxDate = optionsArray[optionsArray.length - 1];
  const dateFormat = 'YYYY/MM/DD';

  const disabledRangePicker: RangePickerProps['disabledDate'] = (date: any) => {
    if (date == null) {
      return false;
    }

    if (!optionsArray?.length) {
      return false;
    }
    if (minDate && maxDate) {
      return (
        dayjs(minDate).isAfter(date) ||
        dayjs(maxDate).add(1, 'day').isBefore(date)
      );
    }

    return false;
  };

  switch (type) {
    case 'text':
      return (
        <Input
          placeholder='Set value to Filter by'
          value={filterValue || undefined}
          onChange={(e: Dictionary) => {
            setFilterValue(e?.target.value);
          }}
          onBlur={() => {
            if (editFilterActionCreator) {
              dispatch(
                editFilterActionCreator({
                  componentIndex,
                  filterIndex,
                  propertyIndex: 2,
                  value: filterValue,
                })
              );
            }
          }}
          onPressEnter={(e: Dictionary) => {
            if (editFilterActionCreator) {
              if (
                cascade &&
                filterObjs?.length > 1 &&
                filterIndex !== undefined &&
                !e?.target?.value
              ) {
                filterObjs.forEach((filterItem: FilterOption, key: number) => {
                  if (filterIndex < key) {
                    dispatch(
                      editFilterActionCreator({
                        componentIndex: componentIndex,
                        filterIndex: key,
                        propertyIndex: 2,
                        value: undefined,
                      })
                    );
                  }
                });
              }
              dispatch(
                editFilterActionCreator({
                  componentIndex,
                  filterIndex,
                  propertyIndex: 2,
                  value: filterValue,
                })
              );
            }
          }}
        />
      );

    case 'slider':
      return (
        <Slider
          min={0}
          value={sliderIndex === -1 ? 0 : sliderIndex}
          max={data.length - 1}
          tipFormatter={(value) => {
            const toolTipValue =
              data?.[value as number]?.[`${cube}.${property}`];
            return dataType === 'time' && !showActualDateFormat
              ? toolTipValue?.split('T')[0]
              : toolTipValue;
          }}
          onChange={(value: number) => {
            setSliderValue(value);
          }}
          onAfterChange={(value) => {
            if (editFilterActionCreator) {
              dispatch(
                editFilterActionCreator({
                  componentIndex,
                  filterIndex,
                  propertyIndex: 2,
                  value: data?.[value]?.[`${cube}.${property}`],
                })
              );
            }
          }}
        />
      );
    case 'date-picker':
      return (
        <DatePicker
          disabledDate={disabledRangePicker}
          defaultPickerValue={dayjs(minDate, dateFormat) as any}
          value={
            filterObj?.[2]?.length
              ? (dayjs(filterObj?.[2], dateFormat) as any)
              : undefined
          }
          onChange={(value, dateString) => {
            if (editFilterActionCreator) {
              if (
                cascade &&
                filterObjs?.length > 1 &&
                filterIndex !== undefined &&
                !value
              ) {
                filterObjs.forEach((filterItem: FilterOption, key: number) => {
                  if (filterIndex < key) {
                    dispatch(
                      editFilterActionCreator({
                        componentIndex: componentIndex,
                        filterIndex: key,
                        propertyIndex: 2,
                        value: undefined,
                      })
                    );
                  }
                });
              }
              if (!dateString) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex: componentIndex,
                    filterIndex,
                    propertyIndex: 2,
                    value: undefined,
                  })
                );
              } else {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 2,
                    value: dateString,
                  })
                );
              }
            }
          }}
        />
      );

    case 'select':
      return (
        <Select
          mode={filterObj?.multiple ? 'multiple' : undefined}
          allowClear={filterObj?.disableClear ? false : true}
          showSearch
          style={{ width: '100%' }}
          placeholder='Select...'
          value={filterObj?.[2]}
          onChange={(value) => {
            if (editFilterActionCreator) {
              if (
                cascade &&
                filterObjs?.length > 1 &&
                filterIndex !== undefined
              ) {
                filterObjs.forEach((filterItem: FilterOption, key: number) => {
                  if (filterIndex < key) {
                    dispatch(
                      editFilterActionCreator({
                        componentIndex: componentIndex,
                        filterIndex: key,
                        propertyIndex: 2,
                        value: undefined,
                      })
                    );
                  }
                });
              }
              dispatch(
                editFilterActionCreator({
                  componentIndex,
                  filterIndex,
                  propertyIndex: 2,
                  value: value,
                })
              );
            }
          }}
        >
          {data &&
            optionsArray &&
            optionsArray.map((item: string, index: number) => (
              <Option key={index} value={item}>
                {dataType === 'time' && !showActualDateFormat
                  ? item.split('T')[0]
                  : item}
              </Option>
            ))}
        </Select>
      );
    case 'range-text':
      return (
        <>
          <Input
            placeholder='Set value to Filter by'
            value={
              dataType === 'time' && !showActualDateFormat
                ? filterObj?.[3]?.split('T')[0]
                : filterObj?.[3]
            }
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            onBlur={() => {
              if (editFilterActionCreator) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 3,
                    value: filterValue,
                  })
                );
              }
            }}
            onPressEnter={() => {
              if (editFilterActionCreator) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 3,
                    value: filterValue,
                  })
                );
              }
            }}
          />
          <br />
          <br />
          <Input
            placeholder='Set value to Filter by'
            value={
              dataType === 'time' && !showActualDateFormat
                ? filterObj?.[4]?.split('T')[0]
                : filterObj?.[4]
            }
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            onBlur={() => {
              if (editFilterActionCreator) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 4,
                    value: filterValue,
                  })
                );
              }
            }}
            onPressEnter={() => {
              if (editFilterActionCreator) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 4,
                    value: filterValue,
                  })
                );
              }
            }}
          />
        </>
      );
    case 'range-slider':
      return (
        <Slider
          min={0}
          range={{ draggableTrack: true }}
          defaultValue={rangeFilterValue}
          value={rangeFilterValue}
          max={data.length - 1}
          tipFormatter={(value) => {
            const toolTipValue =
              data?.[value as number]?.[`${cube}.${property}`];
            return dataType === 'time' && !showActualDateFormat
              ? toolTipValue?.split('T')[0]
              : toolTipValue;
          }}
          onChange={(value: number[]) => {
            setSliderValue(value);
          }}
          onAfterChange={(value: number[]) => {
            if (editFilterActionCreator) {
              dispatch(
                editFilterActionCreator({
                  componentIndex,
                  filterIndex,
                  propertyIndex: 3,
                  value: data?.[value[0]]?.[`${cube}.${property}`],
                })
              );
              dispatch(
                editFilterActionCreator({
                  componentIndex,
                  filterIndex,
                  propertyIndex: 4,
                  value: data?.[value[1]]?.[`${cube}.${property}`],
                })
              );
            }
          }}
        />
      );
    case 'range-select':
      return (
        <>
          <Select
            mode={filterObj?.multiple ? 'multiple' : undefined}
            allowClear={filterObj?.disableClear ? false : true}
            showSearch
            style={{ width: '100%' }}
            placeholder='Select...'
            value={filterObj?.[3]}
            onChange={(value) => {
              if (editFilterActionCreator) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 3,
                    value: value,
                  })
                );
              }
            }}
          >
            {data &&
              optionsArray &&
              optionsArray.map((item: string, index: number) => (
                <Option key={index} value={item}>
                  {dataType === 'time' && !showActualDateFormat
                    ? item.split('T')[0]
                    : item}
                </Option>
              ))}
          </Select>
          <br />
          <br />
          <Select
            mode={filterObj?.multiple ? 'multiple' : undefined}
            allowClear={filterObj?.disableClear ? false : true}
            showSearch
            style={{ width: '100%' }}
            placeholder='Select...'
            value={filterObj?.[4]}
            onChange={(value) => {
              if (editFilterActionCreator) {
                dispatch(
                  editFilterActionCreator({
                    componentIndex,
                    filterIndex,
                    propertyIndex: 4,
                    value: value,
                  })
                );
              }
            }}
          >
            {data &&
              optionsArray &&
              optionsArray.map((item: string, index: number) => (
                <Option key={index} value={item}>
                  {dataType === 'time' && !showActualDateFormat
                    ? item.split('T')[0]
                    : item}
                </Option>
              ))}
          </Select>
        </>
      );
    case 'range-date-picker':
      if (minDate && maxDate) {
        return (
          <RangePicker
            disabledDate={disabledRangePicker}
            defaultPickerValue={
              [dayjs(minDate, dateFormat), dayjs(maxDate, dateFormat)] as any
            }
            value={
              filterObj?.[3]?.length && filterObj?.[4]?.length
                ? ([
                    dayjs(filterObj?.[3], dateFormat),
                    dayjs(filterObj?.[4], dateFormat),
                  ] as any)
                : []
            }
            onChange={(value, dateString) => {
              if (editFilterActionCreator) {
                if (dateString[0] || dateString[1]) {
                  dispatch(
                    editFilterActionCreator({
                      componentIndex,
                      filterIndex,
                      propertyIndex: 3,
                      value: `${dateString[0]}T00:00:00.000`,
                    })
                  );
                  dispatch(
                    editFilterActionCreator({
                      componentIndex,
                      filterIndex,
                      propertyIndex: 4,
                      value: `${dateString[1]}T00:00:00.000`,
                    })
                  );
                } else {
                  dispatch(
                    editFilterActionCreator({
                      componentIndex,
                      filterIndex,
                      propertyIndex: 3,
                      value: undefined,
                    })
                  );
                  dispatch(
                    editFilterActionCreator({
                      componentIndex,
                      filterIndex,
                      propertyIndex: 4,
                      value: undefined,
                    })
                  );
                }
              }
            }}
          />
        );
      }

      return <RangePicker disabledDate={disabledRangePicker} />;
    default:
      return <></>;
  }
};

export const InputComponentGetter = (
  rangeOperators: boolean,
  inputTypes: InputTypesProps,
  stateMethods: stateMethodProps,
  configs: configProps,
  filterInputsData: filterInputsDataProps
): JSX.Element => {
  const { slider, textInput, datePicker } = inputTypes;
  if (rangeOperators) {
    if (slider) {
      return getComponent(
        'range-slider',
        stateMethods,
        configs,
        filterInputsData
      );
    } else if (textInput) {
      return getComponent(
        'range-text',
        stateMethods,
        configs,
        filterInputsData
      );
    } else if (datePicker) {
      return getComponent(
        'range-date-picker',
        stateMethods,
        configs,
        filterInputsData
      );
    } else {
      return getComponent(
        'range-select',
        stateMethods,
        configs,
        filterInputsData
      );
    }
  } else {
    if (slider) {
      return getComponent('slider', stateMethods, configs, filterInputsData);
    } else if (textInput) {
      return getComponent('text', stateMethods, configs, filterInputsData);
    } else if (datePicker) {
      return getComponent(
        'date-picker',
        stateMethods,
        configs,
        filterInputsData
      );
    } else {
      return getComponent('select', stateMethods, configs, filterInputsData);
    }
  }
};
