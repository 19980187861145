import React from 'react';
import { Steps } from 'antd';
import { Dictionary } from '@onaio/utils';
import { LoadingOutlined } from '@ant-design/icons';

import {
  FileSourceCreationJobStatusTypes,
  FileSourceCreationJobStatusTypesDescriptions,
} from '../../../../configs/component-types';

export interface FileSourceCreationProgressStepsProps {
  progress: Dictionary;
}

const FileSourceCreationProgressSteps: React.FC<FileSourceCreationProgressStepsProps> = (
  props: FileSourceCreationProgressStepsProps
) => {
  let { progress } = props;
  if (Object.keys(progress).length === 0) {
    progress = {
      stage: FileSourceCreationJobStatusTypes.UPLOADING,
      data: [
        {
          pending: [],
          done: [],
          percentage: 0,
        },
      ],
    };
  }
  const getCurrentStage = (stage?: string) => {
    let currentStage = 0;
    switch (stage) {
      case FileSourceCreationJobStatusTypes.UPLOADING:
        currentStage = 0;
        break;
      case FileSourceCreationJobStatusTypes.STARTING:
        currentStage = 1;
        break;
      case FileSourceCreationJobStatusTypes.DONE:
        currentStage = 3;
        break;
      default:
        currentStage = 2;
        break;
    }
    return currentStage;
  };
  const currentStage = getCurrentStage(progress?.stage);

  return (
    <Steps
      size="small"
      current={currentStage}
      percent={
        progress?.stage === FileSourceCreationJobStatusTypes.STARTING
          ? undefined
          : progress?.data[0]?.percentage
      }
      items={[
        {
          title: FileSourceCreationJobStatusTypesDescriptions.UPLOADING,
        },
        {
          title: FileSourceCreationJobStatusTypesDescriptions.STARTING,
          icon:
            progress?.stage === FileSourceCreationJobStatusTypes.STARTING ? (
              <LoadingOutlined />
            ) : undefined,
        },
        {
          title: FileSourceCreationJobStatusTypesDescriptions.PROCESSING,
        },
        {
          title: FileSourceCreationJobStatusTypesDescriptions.DONE,
        },
      ]}
    />
  );
};

export { FileSourceCreationProgressSteps };
