import React from 'react';
import { BugOutlined, AppstoreAddOutlined, QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { actionConfigActiveSettingAdd } from '../../../actions';
import { useDispatch } from 'react-redux';
import { resizeMaps } from '../../../Map/helpers';

const LeftMenu = () => {
  const dispatch = useDispatch();

  return(
    <div className="editor-left-menu-region">
      <ul className="editor-left-menu">
        <li
        onClick={() => {
          if (document.body.classList.contains('hide-left-region')) {
             document.body.classList.remove('hide-left-region');
             resizeMaps();
          }
            if (document.body.classList.contains('menu-expanded')) {
                document.body.classList.remove('menu-expanded');
                resizeMaps();
              } 
            dispatch(
                actionConfigActiveSettingAdd({ value: 'components'})
            )
        }}
        ><AppstoreAddOutlined />
        </li>
        <li
          onClick={() => {
            if (document.body.classList.contains('hide-left-region')) {
              document.body.classList.remove('hide-left-region');
              resizeMaps();
           }
            if (!document.body.classList.contains('menu-expanded')) {
                document.body.classList.add('menu-expanded');
                resizeMaps();
            }
            dispatch(
                actionConfigActiveSettingAdd({ value: 'settings'})
            )
          }}
        ><SettingOutlined />
        </li>
        <li
           onClick={() => {
            if (document.body.classList.contains('hide-left-region')) {
              document.body.classList.remove('hide-left-region');
              resizeMaps();
           }
            if (!document.body.classList.contains('menu-expanded')) {
                document.body.classList.add('menu-expanded');
                resizeMaps();
            }
            dispatch(
                actionConfigActiveSettingAdd({ value: 'errors'})
            )
          }}
        >
            <BugOutlined />
        </li>
        <li>
        <a 
         href="https://docs.akuko.io"
         target="blank"
         >
          <QuestionCircleOutlined />
        </a>
        </li>
      </ul>
    </div>
  )
}

export { LeftMenu };