import React from 'react';
import './Loader.css';

function Loader(props) {
  const getLoaderBackground = (props) => {
    return '#ffffff';
  };

  const getLoaderColor = (props) => {
    return '#111111';
  };

  return (
    <div className="loader" style={{ background: getLoaderBackground(props) }}>
      <div className="mark">
        <svg width="5em" height="5em" viewBox="0 0 1319 1005">
          <g stroke="none">
            <rect
              id="Rectangle"
              fill={getLoaderColor(props)}
              transform="translate(1001.376689, 643.650485) rotate(-47.000000) translate(-1001.376689, -643.650485) "
              x="793.376689"
              y="435.650485"
              width="416"
              height="416"
              rx="101.203614"
            >
              <animate
                attributeName="opacity"
                values="0.1;0.1;1"
                dur="1s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              id="Rectangle"
              fill={getLoaderColor(props)}
              transform="translate(655.376689, 324.650485) rotate(-47.000000) translate(-655.376689, -324.650485) "
              x="447.376689"
              y="116.650485"
              width="416"
              height="416"
              rx="101.203614"
            >
              <animate
                attributeName="opacity"
                values="0.1;1;0.1"
                dur="1s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              id="Rectangle"
              fill={getLoaderColor(props)}
              transform="translate(333.376689, 667.650485) rotate(-47.000000) translate(-333.376689, -667.650485) "
              x="125.376689"
              y="459.650485"
              width="416"
              height="416"
              rx="101.203614"
            >
              <animate
                attributeName="opacity"
                values="1;0.1;0.1"
                dur="1s"
                repeatCount="indefinite"
              />
            </rect>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default Loader;
