import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../Error/Error';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../Account/PrivateRoute/PrivateRoute';
import axios from 'axios';
import Loading from '../Post/Loader/Loader';
import './App.css';
import Dashboard from '../Account/Dashboard';
import DashboardMenu from '../Account/Dashboard/components/DashboardMenu';
import Spaces from '../Account/Dashboard/components/Spaces';
import SpacePosts from '../Account/Dashboard/components/Space';
import UserSources from '../Account/Dashboard/components/UserSources';
import UserForm from '../Account/Dashboard/components/UserForm';
import AccountForm from '../Account/Dashboard/components/AccountForm';
import Design from '../Account/Design';
import SpaceSources from '../Account/Dashboard/components/SpaceSources';
import SpaceUsers from '../Account/Dashboard/components/SpaceUsers';
import SpaceSettings from '../Account/Dashboard/components/SpaceSettings';
import CheckToken from '../Account/Auth/CheckToken/CheckToken'; 
import { Source } from '../Account/Dashboard/components/Source';
import { Profile } from '../Profile';
import { Feed } from '../Feed';
import { RootComponent } from '../Account/Root';
import { SetHandle } from '../Account/SetHandle';
import Footer from '../Post/Footer';
import { message } from 'antd';
import NotFound from '../404';
import { UnauthorizedPage } from '../403';
import { MaintenancePage } from '../Maintenance';
import { Editor } from '../Post/Editor';
import mixpanel from 'mixpanel-browser';

const Space = React.lazy(() => import('../Space'));
const Post = React.lazy(() => import('../Post'));
const AccountsMenu = React.lazy(() => import('../Account/Dashboard/components/AccountsMenu'));

mixpanel.init('d8556146bc56e23aa92f229ff6ecc116', {
  debug: false,
  track_pageview: false,
  persistence: 'localStorage',
});

axios.defaults.withCredentials = true;
window.akukoQueryIndex = [];
window.akukoLayerQueryIndex = [];
message.config({
  top: 20,
  duration: 5,
  maxCount: 1,
});

const mapStateToProps = (state, ownProps) => {
  if (!state) {
    return null;
  }
  return {
    state,
  };
};

export const App = (props) => {
  return (
    <div className="page-wrapper">
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" render={(props) => <RootComponent />} />
            <Route
              exact
              path="/post/:id"
              render={(props) => (
                <>
                  <Post {...props} />
                </>
              )}
            />
            <Route
              exact
              path="/embed/:id"
              render={(props) => (
                <>
                  <Post {...props} />
                </>
              )}
            />
            <Route
              exact
              path="/space/:id"
              render={(props) => (
                <>
                  <Space {...props} />
                </>
              )}
            />
            <Route
              exact
              path="/space/:id/tag/:tag"
              render={(props) => (
                <>
                  <Space {...props} />
                </>
              )}
            />
            <PrivateRoute
              exact
              path="/user/set-handle"
              render={() => (
                <>
                  <SetHandle />
                </>
              )}
            />
            <PrivateRoute exact path="/post/:id/edit">
              ` <Editor />
                <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact path="/editor/data/:id">
              ` <Editor />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact path="/editor/data">
              ` <Editor />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/dashboard">
              <Dashboard />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/dashboard/posts">
              <Dashboard />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/dashboard/settings">
              <UserForm />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/dashboard/sources">
              <UserSources />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/account/:id">
              <Spaces />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/account/:id/settings">
              <AccountForm />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/source/:sourceId">
              <Source />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/space/:id/posts">
              <SpacePosts />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/space/:id/sources">
              <SpaceSources />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/space/:id/users">
              <SpaceUsers />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/space/:id/sources/:sourceId">
              <Source />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/space/:id/settings">
              <SpaceSettings />
              <AccountsMenu />
              <DashboardMenu />
              <Footer align="right" />
              <CheckToken />
            </PrivateRoute>
            <PrivateRoute exact={true} path="/space/:id/design">
              <Design />
              <CheckToken />
            </PrivateRoute>
            <Route exact path="/@:user">
              <Profile />
            </Route>
            <Route exact path="/feed">
              <Feed />
            </Route>
            <Route path="/unauthorized">
              <UnauthorizedPage />
            </Route>
            <Route path="/maintenance">
              <MaintenancePage />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const ConnectedApp = connect(mapStateToProps)(App);

export default ConnectedApp;
