export const actionPostGet = function (data) {
  return {
    type: 'POST_GET',
    data,
  };
};

export const actionPostConfigEdit = function (data) {
  return {
    type: 'POST_CONFIG_EDIT',
    data,
  };
};

export const actionPostPropertyEdit = function (data) {
  return {
    type: 'POST_PROPERTY_EDIT',
    data,
  };
};

export const actionPostDelete = function (data) {
  return {
    type: 'POST_DELETE',
    data,
  };
};

export const _actionPostComponentEdit = (data) => {
  return {
    type: '_POST_COMPONENT_EDIT',
    data,
  };
};

export const actionPostComponentInsert = (data) => {
  return {
    type: 'POST_COMPONENT_INSERT',
    data,
  };
};

export const actionPostComponentSettingEdit = (data) => {
  return {
    type: 'POST_COMPONENT_SETTING_EDIT',
    data,
  };
};

export const actionPostArrayItemAdd = (data) => {
  return {
    type: 'POST_ARRAY_ITEM_ADD',
    data,
  };
};

export const actionPostArrayItemDelete = (data) => {
  return {
    type: 'POST_ARRAY_ITEM_DELETE',
    data,
  };
};

export const actionPostArrayItemEdit = (data) => {
  return {
    type: 'POST_ARRAY_ITEM_EDIT',
    data,
  };
};

export const actionPostArrayItemMove = (data) => {
  return {
    type: 'POST_ARRAY_ITEM_MOVE',
    data,
  };
};

export const actionPostArrayItemChildMove = (data) => {
  return {
    type: 'POST_ARRAY_ITEM_CHILD_MOVE',
    data,
  };
};

export const actionPostListItemAdd = (data) => {
  return {
    type: 'POST_LIST_ITEM_ADD',
    data,
  };
};

export const actionPostListItemMove = (data) => {
  return {
    type: 'POST_LIST_ITEM_MOVE',
    data,
  };
};

export const actionPostListItemDelete = (data) => {
  return {
    type: 'POST_LIST_ITEM_DELETE',
    data,
  };
};

export const actionPostListItemEdit = (data) => ({
  type: 'POST_LIST_ITEM_EDIT',
  data,
});

export const actionPostFilterEdit = (data) => ({
  type: 'POST_FILTER_EDIT',
  data,
});

export const actionComponentSourceQuery = async (data, componentId, query, type) => {
  return {
    type: 'POST_COMPONENT_QUERY',
    data: {
      data,
      componentId,
      query,
      type,
    },
  };
};

export const actionPostKeyEdit = (data) => {
  return {
    type: 'POST_COMPONENT_KEY_EDIT',
    data,
  };
};

export const actionPostEdit = (data) => {
  return {
    type: 'POST_EDIT',
    data,
  };
};

export const actionPostComponentDelete = (data) => {
  return {
    type: 'POST_COMPONENT_DELETE',
    data,
  };
};

export const actionPostComponentMove = (data) => {
  return {
    type: 'POST_COMPONENT_MOVE',
    data,
  };
};

export const actionPostComponentEdit = (data) => {
  return {
    type: 'POST_COMPONENT_EDIT',
    data,
  };
};

export const actionComponentAdd = (data) => {
  return {
    type: 'POST_COMPONENT_ADD',
    data,
  };
};

export const actionPostComponentWidthEdit = (data) => {
  return {
    type: 'POST_COMPONENT_WIDTH_EDIT',
    data,
  };
};

export const actionComponentFormatEdit = (data) => {
  return {
    type: 'POST_COMPONENT_FORMAT_EDIT',
    data,
  };
};

export const actionComponentClustersEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CLUSTERS_EDIT',
    data,
  };
};

export const actionComponentColorKeyEdit = (data) => {
  return {
    type: 'POST_COMPONENT_COLOR_KEY_EDIT',
    data,
  };
};

export const actionComponentColorTypeEdit = (data) => {
  return {
    type: 'POST_COMPONENT_COLOR_TYPE_EDIT',
    data,
  };
};

export const actionComponentColorsEdit = (data) => {
  return {
    type: 'POST_COMPONENT_COLORS_EDIT',
    data,
  };
};

export const actionComponentBreaksEdit = (data) => {
  return {
    type: 'POST_COMPONENT_BREAKS_EDIT',
    data,
  };
};

export const actionComponentCategoriesEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CATEGORIES_EDIT',
    data,
  };
};

export const actionComponentLongitudeEdit = (data) => {
  return {
    type: 'POST_COMPONENT_LONGITUDE_EDIT',
    data,
  };
};

export const actionComponentLatitudeEdit = (data) => {
  return {
    type: 'POST_COMPONENT_LATITUDE_EDIT',
    data,
  };
};

export const actionComponentZoomEdit = (data) => {
  return {
    type: 'POST_COMPONENT_ZOOM_EDIT',
    data,
  };
};

export const actionComponentBearingEdit = (data) => {
  return {
    type: 'POST_COMPONENT_BEARING_EDIT',
    data,
  };
};

export const actionComponentPitchEdit = (data) => {
  return {
    type: 'POST_COMPONENT_PITCH_EDIT',
    data,
  };
};

export const actionComponentTitleEdit = (data) => {
  return {
    type: 'POST_COMPONENT_TITLE_EDIT',
    data,
  };
};

export const actionActiveColumnConnectedEdit = (data) => {
  return {
    type: 'POST_ACTIVE_COLUMN_CONNECTED_EDIT',
    data,
  };
};

export const actionPostNameEdit = (data) => {
  return {
    type: 'POST_NAME_EDIT',
    data,
  };
};

export const actionPostDescriptionEdit = (data) => {
  return {
    type: 'POST_DESCRIPTION_EDIT',
    data,
  };
};

export const actionPostComponentOrderEdit = (data) => {
  return {
    type: 'POST_COMPONENT_ORDER_EDIT',
    data,
  };
}

export const actionConfigActiveLayerEdit = (data) => {
  return {
    type: 'CONFIG_COMPONENT_LAYER_ACTIVE',
    data,
  };
}

export const actionConfigActiveItemEdit = (data) => {
  return {
    type: 'CONFIG_COMPONENT_ITEM_ACTIVE',
    data,
  };
}

export const actionPostComponentCopy = (data) => {
  return {
    type: 'POST_COMPONENT_COPY',
    data,
  };
}

export const actionConfigActiveColumnEdit = (data) => {
  return {
    type: 'CONFIG_COMPONENT_COLUMN_ACTIVE',
    data,
  };
}

export const actionPostComponentSwitchRegionEdit = (data) => {
  return {
    type: 'POST_COMPONENT_SWITCH_REGION_EDIT',
    data,
  };
}

export const actionComponentSourcesGet = (sources) => {
  return {
    type: 'POST_COMPONENT_SOURCES_GET',
    data: sources,
  };
};

export const actionComponentSourceGet = (data) => {
  return {
    type: 'POST_COMPONENT_SOURCE_GET',
    data,
  };
};

export const actionPostPublicEdit = (data) => ({
  type: 'POST_PUBLIC_EDIT',
  data,
});

export const actionPostTitleToggle = (data) => ({
  type: 'POST_TITLE_TOGGLE',
  data,
});

export const actionConfigEdit = (data) => ({
  type: 'CONFIG_EDIT',
  data,
});

export const actionConfigDrawerOpenEdit = (data) => ({
  type: 'CONFIG_DRAWER_OPEN_EDIT',
  data,
});

export const actionConfigComponentIndexActive = (data) => ({
  type: 'CONFIG_COMPONENT_INDEX_ACTIVE',
  data,
});

export const actionComponentFilterAdd = (data) => ({
  type: 'POST_COMPONENT_FILTER_ADD',
  data,
});

export const actionComponentGlobalFilterAdd = (data) => ({
  type: 'POST_COMPONENT_GLOBAL_FILTER_ADD',
  data,
});

export const actionComponentRemoveGlobalFilter = (data) => ({
  type: 'POST_COMPONENT_REMOVE_GLOBAL_FILTER',
  data,
});

export const actionComponentWithGlobalFilters = (data) => ({
  type: 'POST_COMPONENT_LIST_WITH_GLOBAL_FILTER',
  data,
});

export const actionComponentFilterEdit = (data) => ({
  type: 'POST_COMPONENT_FILTER_EDIT',
  data,
});

export const actionComponentHighlightEdit = (data) => ({
  type: 'POST_COMPONENT_HIGHLIGHT_EDIT',
  data,
});

export const actionComponentFilterOptionEdit = (data) => ({
  type: 'POST_COMPONENT_FILTER_OPTION_EDIT',
  data,
});

export const actionComponentFilterDelete = (data) => ({
  type: 'POST_COMPONENT_FILTER_DELETE',
  data,
});

export const actionPostAccountEdit = (data) => {
  return {
    type: 'POST_ACCOUNT_EDIT',
    data,
  };
};

export const actionComponentFilterSet = (data) => {
  return {
    type: 'POST_COMPONENT_FILTER_SET',
    data,
  };
};

export const actionComponentMoveUp = (data) => {
  return {
    type: 'POST_COMPONENT_MOVE_UP',
    data,
  };
};

export const actionComponentMoveDown = (data) => {
  return {
    type: 'POST_COMPONENT_MOVE_DOWN',
    data,
  };
};

export const actionPostTagsEdit = (data) => {
  return {
    type: 'POST_TAGS_EDIT',
    data,
  };
};

export const actionComponentDataFieldAdd = (data) => {
  return {
    type: 'POST_COMPONENT_DATA_FIELD_ADD',
    data,
  };
};

export const actionComponentDataFieldEdit = (data) => {
  return {
    type: 'POST_COMPONENT_DATA_FIELD_EDIT',
    data,
  };
};

export const actionComponentDataFieldDelete = (data) => {
  return {
    type: 'POST_COMPONENT_DATA_FIELD_DELETE',
    data,
  };
};

export const actionConfigPostMenuSectionEdit = (data) => {
  return {
    type: 'CONFIG_POST_MENU_SECTION_EDIT',
    data,
  };
};

export const actionComponentCaptionEdit = (data) => {
  return {
    type: 'POST_COMPONENT_CAPTION_EDIT',
    data,
  };
};

export const actionComponentEventAdd = (data) => {
  return {
    type: 'POST_COMPONENT_EVENT_ADD',
    data,
  };
};

export const actionSpaceClearConfig = (data) => {
  return {
    type: 'SPACE_CLEAR_CONFIG',
    data,
  };
};

export const actionPostClearConfig = (data) => {
  return {
    type: 'POST_CLEAR_CONFIG',
    data,
  };
};

export const actionPostComponentErrorAdd = (data) => {
  return {
    type: 'POST_COMPONENT_ERRORS_ADD',
    data,
  };
};

export const actionPostComponentErrorDelete = (data) => {
  return {
    type: 'POST_COMPONENT_ERRORS_DELETE',
    data,
  };
};

export const actionConfigActivePostAdd = (data) => {
  return {
    type: 'CONFIG_ACTIVE_POST_ADD',
    data,
  };
};

export const actionConfigActiveSettingAdd = (data) => {
  return {
    type: 'CONFIG_ACTIVE_SETTING_ADD',
    data,
  };
};

