import React, { useState, useEffect } from 'react';
import { Collapse, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import { io } from 'socket.io-client';

import { AkukoAPIService } from '../../../services/serviceClass';
import { SOURCES_API, SOURCES_WEB_SOCKET_URL } from '../../../configs/env';
import { SourcePostgresInput } from '../settings/SourcePostgresInput';
import { MySQLSourceType } from '../settings/MySQLSourceType';
import { actionSourceSave } from '../actions';
import { LinkOutlined, LoadingOutlined } from '@ant-design/icons';
import { Source, sourceAsyncOperationsJobStatusTypes } from '../../../configs/component-types';
import { ProgressModal } from '../settings/OnadataSourceType/ProgressModal';
import {
  onadataSourceJobStatusTypes,
  onadataSourceJobStatusTypesDescriptions,
} from '../settings/constants';
import { Link } from 'react-router-dom';
import { SourceS3ParquetInput } from '../settings/SourceS3ParquetInput';
import { ClickHouseSourceInput } from '../settings/ClickHouseSourceInput';
const { Panel } = Collapse;

export interface ConnectionFormProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const ConnectionForm: React.FC<ConnectionFormProps> = (props: ConnectionFormProps) => {
  const { sourceActionSaveCreator } = props;
  const dispatch = useDispatch();
  const source = useSelector((store: Dictionary) => store.source);
  const googleSheetUrl = source.config?.['sheet-url'];
  const googleSheet = source.config?.['sheet'];
  const url = source.url;
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const handleModalCancel = () => {
    setIsModalOpen(false);
  };
  const [progress, setProgress] = useState<Dictionary>({});

  useEffect(() => {
    if (loading === false && progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }
      message.success('Source refreshed successfully!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, progress]);

  useEffect(() => {
    if (
      loading === true &&
      progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE] &&
      source.type === 'onadata' &&
      source?.dimensions !== null &&
      source?.dimensions.length !== 0
    ) {
      handleModalCancel();
      message.success('Source refreshed successfully!', 2.5);
      setLoading(false);
      setProgress({});
      setCurrent(0);
    }
  }, [progress, loading, source, isModalOpen]);

  useEffect(() => {
    if (progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE]) {
      const sourceService = new AkukoAPIService(SOURCES_API, 'source');
      sourceService.read(source.uuid).then((res) => {
        const source = res as Source;
        if (sourceActionSaveCreator) {
          /* @ts-ignore */
          dispatch(sourceActionSaveCreator({ ...source }));
        }
      });
    } else if (progress?.stage === 'FAILED') {
      setIsModalOpen(false);
      message.error('Error refreshing source', 2.5);
      setLoading(false);
      setProgress({});
      setCurrent(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const triggerWebsocket = async (jobId: string) => {
    const socket = io(SOURCES_WEB_SOCKET_URL, {
      path: '/v1/socket.io',
      withCredentials: true,
    });
    // eslint-disable-next-line no-console
    socket.on('connect', () =>
      // eslint-disable-next-line no-console
      console.log(
        `client connected to websock server ${SOURCES_WEB_SOCKET_URL}, socket id: ${socket.id}`
      )
    );
    socket.on('connect_error', () => {
      setTimeout(() => socket.connect(), 5000);
    });
    socket.on(jobId, (data: { progress: { stage: string } }) => {
      const currentStep = Object.keys(onadataSourceJobStatusTypesDescriptions).indexOf(
        data.progress.stage
      );
      setCurrent(currentStep);
      setProgress(data?.progress);
      if (
        data?.progress?.stage === onadataSourceJobStatusTypes[onadataSourceJobStatusTypes.DONE] ||
        data?.progress?.stage === 'FAILED'
      ) {
        socket.disconnect();
      }
    });
    // eslint-disable-next-line no-console
    socket.on('disconnect', () =>
      // eslint-disable-next-line no-console
      console.log(`client disconnected from websock server ${SOURCES_WEB_SOCKET_URL}`)
    );
  };

  const handleOnadataSourceRefresh = async () => {
    setCurrent(0);
    setIsModalOpen(true);
    setLoading(true);
    setProgress({});
    const service = new AkukoAPIService(SOURCES_API, `source/onadata/${source.uuid}`);
    try {
      const res = await service.update({
        source_name: source.name,
        source_id: source.uuid,
      });
      const data = res as { job_uuid: string };
      await triggerWebsocket(data.job_uuid);
    } catch (error) {
      setCurrent(0);
      setLoading(false);
      setProgress({});
      message.error('Error refreshing source');
    }
  };

  if (source?.dimensions?.length > 0) {
    return (
      <div className="source-connection">
        <Collapse>
          <Panel key={1} header="Connection">
            <div className="input-field">
              <label>Type</label>
              {source?.type}
            </div>
            {source.type === 'geoparquet' && source?.config?.url && (
              <div className="input-field">
                <label>Url</label>
                <a target="blank" href={source?.config?.url}>
                  {source?.config?.url}
                </a>
              </div>
            )}
            {source.type === 'onadata' && (
              <>
                {source?.parent_source_uuid === null && (
                  <>
                    <div className="input-field">
                      <label>Number of submissions</label>
                      {source?.config?.number_of_submissions}
                    </div>
                    <div className="input-field">
                      <label>Last submission time</label>
                      {source?.config?.last_submission_time}
                    </div>
                    <div className="input-field">
                      <label>Last refreshed time</label>
                      {source?.config?.last_refreshed_time}
                    </div>
                    <div className="input-field">
                      <label>Ona form url</label>
                      <a target="blank" href={source?.config?.ona_form_url}>
                        {source?.config?.ona_form_url}
                      </a>
                    </div>
                    {source?.config?.repeat_group_sources && (
                      <div className="input-field">
                        <label>Repeat group sources</label>
                        {source?.config?.repeat_group_sources.map(
                          (repeatGroupSource: Dictionary) => (
                            <div className="input-field" key={repeatGroupSource.source_id}>
                              <Link
                                target="blank"
                                to={
                                  source.space_id === null
                                    ? `/source/${repeatGroupSource.source_id}`
                                    : `/space/${source.space_id}/sources/${repeatGroupSource.source_id}`
                                }
                              >
                                {repeatGroupSource.source_id} <LinkOutlined />
                              </Link>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </>
                )}
                {source?.parent_source_uuid !== null && (
                  <>
                    <div className="input-field">
                      <label>Parent Source</label>
                      <div className="input-field">
                        <Link
                          target="blank"
                          to={
                            source.space_id === null
                              ? `/source/${source?.parent_source_uuid}`
                              : `/space/${source.space_id}/sources/${source?.parent_source_uuid}`
                          }
                        >
                          {source?.parent_source_uuid} <LinkOutlined />
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {source.type === 'postgres' && (
              <>
                <SourcePostgresInput />
              </>
            )}
            {source.type === 'clickhouse' && (
              <>
                <ClickHouseSourceInput asyncTaskName={'Source Refresh'} refresh={true} />
              </>
            )}
            {source.type === 'parquet' && (
              <>
                <SourceS3ParquetInput refresh={true} />
              </>
            )}
            {source.type === 'mysql' && (
              <>
                <MySQLSourceType refresh={true} asyncTaskName="Source Refresh" />
              </>
            )}
            {(source.type === 'csv' || source.type === 'geojson') && source.url !== null && (
              <div className="input-field">
                <label>Url</label>
                <a target="blank" href={url}>
                  {url}
                </a>
              </div>
            )}
            {source.type === 'google-sheet' && (
              <>
                <div className="input-field">
                  <label>Url</label>
                  <a target="blank" href={googleSheetUrl}>
                    {googleSheetUrl}
                  </a>
                </div>
                <div className="input-field">
                  <label>Sheet</label>
                  {googleSheet}
                </div>
              </>
            )}
            {source.type === 'onadata' && source?.parent_source_uuid === null && (
              <>
                <ProgressModal
                  title="Source refresh progress"
                  progress={progress}
                  current={current}
                  isModalOpen={isModalOpen}
                  handleModalCancel={handleModalCancel}
                />
                <div className="input-field">
                  <Button
                    type="primary"
                    disabled={loading}
                    onClick={async () => {
                      await handleOnadataSourceRefresh();
                    }}
                  >
                    {loading && <LoadingOutlined />} Refresh
                  </Button>
                </div>
              </>
            )}
          </Panel>
        </Collapse>
      </div>
    );
  } else {
    return null;
  }
};

ConnectionForm.defaultProps = defaultProps;

export { ConnectionForm };
