import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, message, Form, Row, Col } from 'antd';
import { actionSourceEdit, actionSourceSave } from '../actions';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../configs/env';
import {
  Source,
  UrlSourceCreationJobStatusTypes,
  sourceAsyncOperationsJobStatusTypes,
} from '../../../configs/component-types';
import { Dictionary } from '@onaio/utils';
import { startAsycPostgresSQLSourceCreation } from '../helpers/helpers';
import { triggerAsycSourceOperation } from '../components/SourceWebsocketsUpdatesHelpers';
import { UrlSourceCreationProgressSteps } from '../components/UrlSourceCreationProgressSteps';

export interface SourcePostgresInputProps {
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const SourcePostgresInput: React.FC<SourcePostgresInputProps> = (
  props: SourcePostgresInputProps
) => {
  const { sourceActionSaveCreator } = props;
  const dispatch = useDispatch();
  const source = useSelector((store: Dictionary) => store.source);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<
    | { stage: sourceAsyncOperationsJobStatusTypes; data: Dictionary[]; errors: Dictionary[] }
    | Dictionary
  >({});

  useEffect(() => {
    if (progress?.stage === sourceAsyncOperationsJobStatusTypes.DONE) {
      // send dimensions to the store
      if (sourceActionSaveCreator) {
        dispatch(
          /* @ts-ignore */
          sourceActionSaveCreator({
            ...progress.data[0]?.source,
          })
        );
      }
      setLoading(false);
      message.success('Source saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const createPostgresSQLSource = ({ payload }: { payload: Dictionary }) => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });

    triggerAsycSourceOperation({
      asynFunc: startAsycPostgresSQLSourceCreation,
      asynFuncArgs: {
        sourceId: payload?.id,
        type: payload?.type,
        name: payload?.name,
        cube: payload?.cube,
        schema: payload?.schema,
        table: payload?.table,
        user: payload?.user,
        host: payload?.host,
        database: payload?.database,
        password: payload?.password,
        port: payload?.port,
      },
      asyncTaskName: 'Source Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  if (source?.type === 'postgres') {
    return (
      <>
        <Form
          className="source-postgres-form"
          onFinish={(values) => {
            dispatch(
              actionSourceEdit({
                base_table: values['table'],
              })
            );
            const payload = {
              id: source.uuid,
              type: 'postgres',
              name: source?.name,
              cube: source?.cube,
              schema: values['schema'],
              table: values['table'],
              user: values['username'],
              host: values['host'],
              database: values['database'],
              password: values['password'],
              port: values['port'],
            };
            createPostgresSQLSource({
              payload,
            });
          }}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={16}>
              <Form.Item
                name="host"
                label="Host"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-host" placeholder="host" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="port"
                label="Port"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-port" placeholder="port" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="database"
                label="Database"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-database" placeholder="database" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="schema"
                label="Schema"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-schema" placeholder="schema" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                name="table"
                label="Table"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-table" placeholder="table" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input id="input-username" placeholder="username" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password id="input-password" placeholder="password" disabled={loading} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Button id="btn-connect" htmlType="submit" type="primary" disabled={loading}>
                Connect
              </Button>
            </Col>
          </Row>
        </Form>
        {loading === true && (
          <div className="input-field">
            <label>Progress</label>
            <UrlSourceCreationProgressSteps progress={progress} />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};

SourcePostgresInput.defaultProps = defaultProps;

export { SourcePostgresInput };
