// buttons
export const SUBMIT_BUTTON_TEXTS = {
  saving: 'Saving...',
  default: 'Save',
};

// validation messages
export const NAME_FIELD_REQUIRED_MESSAGE = 'Name Field is required';
export const TYPE_FIELD_REQUIRED_MESSAGE = 'Type Field is Required';
export const SQL_FIELD_REQUIRED_MESSAGE = 'SQL Field is Required';

// info messages
export const STATUS_MESSAGES = {
  error: 'error',
  done: 'done',
  default: '',
};
export const OPERATORS = [
  {
    label: 'equal to',
    value: 'equals',
  },
  {
    label: 'not equal to',
    value: 'notEquals',
  },
  {
    label: 'greater than',
    value: 'gt',
  },
  {
    label: 'greater than or equal to',
    value: 'gte',
  },
  {
    label: 'contains',
    value: 'contains',
  },
  {
    label: 'less than',
    value: '<',
  },
  {
    label: 'less than or equal to',
    value: 'lte',
  },
  {
    label: 'not null',
    value: 'notNull',
  },
  {
    label: 'is null',
    value: 'isNull',
  },
];

export const TIME_OPERATORS = [
  {
    label: 'before date',
    value: 'beforeDate',
  },
  {
    label: 'after date',
    value: 'afterDate',
  },
  {
    label: 'in date range',
    value: 'inDateRange',
  },
  {
    label: 'not in date range',
    value: 'notInDateRange',
  },
];
