import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Checkbox, Input, Row, Col, Slider } from 'antd';
const { TextArea } = Input;
import './style.css';

const EmbedSettings = (props) => {
  const post = useSelector((state) => state.post);
  const [components, setComponents] = useState([]);
  const [height, setHeight] = useState(600);


  useEffect(() => {
    setComponents([props.componentId]);
  }, [props.showModal]);

  const getValue = (componentId) => {
    if (components.includes(componentId)) {
      return true
    }
    return false
  }

  return (
    <Modal
      className="modal--embed"
      title="Embed"
      width={'70%'}
      destroyOnClose={true}
      visible={props.showModal}
      onCancel={() => {
        props.onCancel();
      }}
      onOk={() => {
        props.onCancel();
      }}
    >
      <Row gutter={40}>
        <Col sm={6}>
          <label>Height</label>
          <Slider
            value={height}
            min={100}
            max={1200}
            onChange={(value) => {
              setHeight(value);
            }}
          />
          {post?.config?.type !== 'map' &&
            <label>Components</label>
          }
          {post?.config?.type !== 'map' && post?.components?.map(
            (item, index) =>
              item.type !== 'title' &&
              !item.region && (
                <div className="embed--component-item" key={item.id}>
                  <Checkbox
                    checked={getValue(item.id)}
                    onChange={(e) => {
                      const array = components.slice();
                      if (e.target.checked === true) {
                        if (!array.includes(item.id)) {
                          array.push(item.id);
                        }
                      } else {
                        const indexOfItem = array.indexOf(item.id);
                        array.splice(indexOfItem, 1);
                      }
                      setComponents(array);
                    }}
                  >
                    {' '}
                    {item.type}
                  </Checkbox>
                </div>
              )
          )}
        </Col>
        <Col sm={18}>
          <label>Embed code</label>
          <TextArea
            className="input--embed-code"
            rows={4}
            value={`<iframe seamless src='https://${window.location.hostname}/embed/${
              post.uuid
            }?components=${JSON.stringify(
              components
            )}' style='border:none;' width='100%' height='${height}'></iframe>`}
          />
          <label>Preview</label>
          <iframe
            className="embed-preview"
            src={`https://${window.location.hostname}/embed/${
              post.uuid
            }/?components=${JSON.stringify(components)}`}
            width="100%"
            height={height}
          ></iframe>
          <label>Link</label>
          <a className="embed-link" target="blank" href={`https://${window.location.hostname}/embed/${
              post.uuid
            }/?components=${JSON.stringify(components)}`}>
              {`https://${window.location.hostname}/embed/${
              post.uuid
            }/?components=${JSON.stringify(components)}`}
            </a>
        </Col>
      </Row>
    </Modal>
  );
};

export { EmbedSettings };
