import React, { useEffect, useState } from 'react';
import { Select, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../actions';
const { Option } = Select;
export interface GeometryInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const GeometryInput: React.FC<GeometryInputProps> = (props: GeometryInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, placeHolder } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const [value, setValue] = useState('');
  const [sourceId, setSourceId] = useState('');
  const source: Dictionary = post.sources?.[sourceId];

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      const currentSource =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]
          ?.source;
      setValue(currentValue);
      setSourceId(currentSource);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      const currentSource = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.source;
      setSourceId(currentSource);
      setValue(currentValue);
    }
    if (!parents) {
      setSourceId(post.components[componentIndex].source);
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <Row gutter={10}>
      <Col sm={6}>
      <label>{label}</label>
      </Col>
      <Col sm={18}>
      <Select
        placeholder={placeHolder ? placeHolder : undefined}
        allowClear
        style={{ width: '100%' }}
        value={value}
        onChange={(value) => {
          setValue(value);

          dispatch(
            actionPostComponentSettingEdit({
              parents: parents,
              property: property,
              componentIndex: componentIndex,
              itemIndex: itemIndex,
              childIndex: childIndex,
              refreshKey: true,
              value: value,
            })
          );
          dispatch(
            actionPostComponentSettingEdit({
              parents: parents,
              property: 'geometries',
              componentIndex: componentIndex,
              itemIndex: itemIndex,
              childIndex: childIndex,
              refreshKey: true,
              value: source?.geometries,
            })
          );
        }}
      >
        {source?.geometries &&
          source?.geometries.map((item: Dictionary, index: number) => (
            <Option key={index} value={index}>
              {item.name}
            </Option>
          ))}
      </Select>
      </Col>
    </Row>
  );
};

export { GeometryInput };
