import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dictionary } from '@onaio/utils';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import './style.css';
import {
  buildCategories,
  buildStopsIntervals,
  buildSymbolCategories,
  classesChangeChecker,
  colorPropGetter,
  generateBreaks,
  generateCategories,
  hasChanged,
  shouldGenerateColors,
} from '../SelectStepsInput/helpers/helpers';
import { ClassesInput } from './components/ClassesInput';
import { ColorRange } from './components/ColorRange';
import { colorSchemes, colorSchemesProps } from './constants';
import { actionPostComponentSettingEdit } from '../../../actions';

export interface ColorRangeInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

export interface prevValueRefProps {
  // colorRangeInputs: string;
  colorScheme: string;
  colorScale: string;
  componentId: string;
  layerId: string;
  classes: string;
  categoricalClasses: string;
}

const ColorRangeInput: React.FC<ColorRangeInputProps> = (
  props: ColorRangeInputProps
) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents, objectKey, hideClassInput } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const [value, setValue] = useState();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [componentItem, setComponentItem] = useState<Dictionary>({});
  const componentItemValue = component?.[parents[0]]?.[itemIndex as number];
  const prevValueRef = useRef<prevValueRefProps>();
  const [classes, setClasses] = useState(6);
  const isMounted = useRef(false);

  const componentConfigs = {
    parents,
    property,
    objectKey,
    componentIndex,
    childIndex,
    itemIndex,
    value,
    setClasses,
    setValue,
  };

  useEffect(() => {
    if (
      parents?.length === 2 &&
      itemIndex !== undefined &&
      childIndex !== undefined
    ) {
      let currentValue;
      if (objectKey !== undefined) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
            property
          ]?.[objectKey];
      } else {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
            property
          ];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      let currentValue;
      if (objectKey !== undefined) {
        currentValue =
          component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      } else {
        currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      }
      setValue(currentValue);
      setClasses(componentItemValue.classes || 6);
      setComponentItem(componentItemValue);
    }
    if (!parents) {
      if (objectKey !== undefined) {
        setValue(component[property]?.[objectKey]);
      } else {
        setValue(component[property]);
      }
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  useEffect(() => {
    if (componentItem?.regenerateBreaks === false) {
      dispatch(
        actionPostComponentSettingEdit({
          componentIndex: componentIndex,
          itemIndex: itemIndex,
          parents: parents,
          property: 'regenerateBreaks',
          value: true,
        })
      );
      prevValueRef.current = {
        colorScheme: componentItem.colorRange,
        colorScale: componentItem.colorScale,
        componentId: component.id,
        layerId: componentItem.id,
        classes: componentItem.classes,
        categoricalClasses: componentItem.categoricalClasses,
      };
      return;
    }
    const layerData = post.data?.[`layer-${componentItem.id}`];
    const componentData = post.data?.[component.id];
    // const data = component.type === "map" ? layerData : componentData;
    const source = post.sources?.[componentItem.source];
    const { current } = prevValueRef;
    const configs = {
      componentIndex,
      itemIndex,
      parents,
      layerId: componentItem.id,
      componentId: component.id,
      colorField: componentItem.colorField || componentItem.value,
      symbolField: componentItem.symbolField,
      cube: source?.cube,
      classes: componentItem.classes,
      colorRange: componentItem.colorRange,
      colorScale: componentItem.colorScale,
      componentCube: component.cube,
    };

    const { colorField, classes, categoricalClasses, colorScale } =
      componentItem;

    // Check traversing through layers
    let sameLayer = true;

    if (componentItem.id) {
      sameLayer = current?.layerId === componentItem.id;
    } else if (component.id) {
      sameLayer = current?.componentId === component.id;
    }

    const classesChanged = classesChangeChecker(classes, current?.classes);
    const categoricalClassesChanged = classesChangeChecker(
      categoricalClasses,
      current?.categoricalClasses
    );
    const colorScaleChanged = hasChanged(colorScale, current?.colorScale);

    // Generate the component string from the current component item
    const componentString = `${colorField || ''}${classes || ''}${
      categoricalClasses || ''
    }${colorScale || ''}`;

    // Final condition to determine if steps should proceed
    const shouldProceed =
      classesChanged || categoricalClassesChanged || colorScaleChanged;

    if (isMounted.current === true && shouldProceed && sameLayer) {
      if (
        layerData &&
        layerData.length > 0 &&
        component.type === 'map' //&&
        //generateBreaksChecker(previousLayer, layer, 'colorBreaks')
      ) {
        const categoryConfigs = {
          ...configs,
          classes: componentItem?.categoricalClasses || 10,
        };
        if (
          componentItem.colorMethod === 'categorical' &&
          componentItem.layerType !== 'symbol'
        ) {
          generateCategories(
            post,
            categoryConfigs,
            dispatch,
            false,
            'colorCategories',
            shouldGenerateColors(
              current?.colorScale,
              componentItem,
              'colorCategories'
            )
          );
        } else {
          generateBreaks(
            post,
            configs,
            dispatch,
            undefined,
            undefined,
            shouldGenerateColors(
              current?.colorScale,
              componentItem,
              'colorBreaks'
            )
          );
        }
      } else if (
        component.type === 'table' &&
        componentData &&
        componentData.length > 0 //&&
        //generateBreaksChecker(previousLayer, layer, 'generatedSteps')
      ) {
        if (componentItem.colorMode === 'categories') {
          const categoryConfigs = {
            ...configs,
            classes: componentItem?.categoricalClasses || 10,
          };
          generateCategories(
            post,
            categoryConfigs,
            dispatch,
            true,
            'colorCategories',
            shouldGenerateColors(
              current?.colorScale,
              componentItem,
              'colorCategories'
            )
          );
        } else {
          generateBreaks(
            post,
            configs,
            dispatch,
            true,
            'generatedSteps',
            shouldGenerateColors(
              current?.colorScale,
              componentItem,
              'generatedSteps'
            )
          );
        }
      } else if (
        component.type === 'chart' &&
        componentData &&
        componentData.length > 0 //&&
        //generateBreaksChecker(previousLayer, layer, 'ungroupedData')
      ) {
        generateBreaks(
          post,
          configs,
          dispatch,
          true,
          'ungroupedData',
          shouldGenerateColors(
            current?.colorScale,
            componentItem,
            'ungroupedData'
          )
        );
      }
      // else if (component.type === "map" && !data) {
      //   // get layer data to post.data
      //   fetchLayerData(
      //     component,
      //     componentItem,
      //     source,
      //     actionComponentMapLayerBuildGeojson,
      //     50000,
      //     dispatch
      //   );
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted.current = true;
    prevValueRef.current = {
      colorScheme: componentItem.colorRange,
      colorScale: componentItem.colorScale,
      componentId: component.id,
      classes: componentItem.classes,
      layerId: componentItem.id,
      categoricalClasses: componentItem.categoricalClasses,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(
      `${componentItem.classes}${componentItem.categoricalClasses}${componentItem.colorScale}`
    ),
  ]);

  useMemo(() => {
    const shouldRebuildStops = (
      breaksProperty: string,
      componentItem: Dictionary
    ) => {
      if (
        componentItem?.[breaksProperty]?.length &&
        componentItem?.['colorRange'] === undefined
      ) {
        return componentItem?.[breaksProperty];
      }
      return [];
    };

    // const layerData = post.data?.[`layer-${componentItem.id}`];
    const source = post.sources?.[componentItem.source];
    // const { current } = prevValueRef;
    const configs = {
      componentIndex,
      itemIndex,
      parents,
      layerId: componentItem.id,
      colorField: componentItem.colorField,
      symbolField: componentItem.symbolField,
      cube: source?.cube,
      classes: componentItem.groupByColors?.length
        ? componentItem.groupByColors?.length
        : componentItem.classes,
      colorRange: componentItem.colorRange,
      colorScale: componentItem.colorScale,
      reverseColors: componentItem.reverseColors,
    };

    // reset colorBreaks on classes / color range update

    /** maps stops generation */

    if (isMounted.current) {
      if (
        componentItem.colorBreaks?.length &&
        componentItem.colorMethod === 'breaks' &&
        componentItem.layerType !== 'symbol'
      ) {
        const breakIntervals = componentItem.colorBreaks.map(
          (record: Dictionary) => record.value
        );

        buildStopsIntervals(
          breakIntervals,
          configs,
          dispatch,
          'colorBreaks',
          true,
          shouldRebuildStops('colorBreaks', componentItem)
        );
      } else if (
        componentItem.colorMethod === 'categorical' &&
        componentItem.colorCategories?.length &&
        componentItem.layerType !== 'symbol'
      ) {
        const breakIntervals = componentItem.colorCategories.map(
          (record: Dictionary) => record.value
        );
        const categoryConfigs = {
          ...configs,
          classes: componentItem?.categoricalClasses || 10,
        };
        buildCategories(
          breakIntervals,
          categoryConfigs,
          dispatch,
          'colorCategories',
          shouldRebuildStops('colorCategories', componentItem)
        );
      } else if (
        /** Group by chart stops generation  */
        componentItem.colorMode === 'defaultColor' &&
        componentItem.groupByColors?.length &&
        componentItem.layerType !== 'symbol'
      ) {
        const breakIntervals = componentItem.groupByColors.map(
          (record: Dictionary) => record.value
        );
        buildStopsIntervals(
          breakIntervals,
          configs,
          dispatch,
          'groupByColors',
          true,
          shouldRebuildStops('groupByColors', componentItem)
        );

        /** Ungrouped  chart stops generation  */
      } else if (
        componentItem.colorMode === 'generatedStepsBrakes' &&
        componentItem.ungroupedData?.length &&
        componentItem.layerType !== 'symbol'
      ) {
        const breakIntervals = componentItem.ungroupedData.map(
          (record: Dictionary) => record.value
        );
        buildStopsIntervals(
          breakIntervals,
          configs,
          dispatch,
          'ungroupedData',
          true,
          shouldRebuildStops('ungroupedData', componentItem)
        );
        /** Generated stops for table component */
      } else if (
        componentItem.colorMode === 'generatedStepsBrakes' &&
        componentItem.generatedSteps?.length &&
        componentItem.layerType !== 'symbol'
      ) {
        const breakIntervals = componentItem.generatedSteps.map(
          (record: Dictionary) => record.value
        );

        buildStopsIntervals(
          breakIntervals,
          configs,
          dispatch,
          'generatedSteps',
          true,
          shouldRebuildStops('generatedSteps', componentItem)
        );
      } else if (
        componentItem.colorMode === 'categories' &&
        componentItem.colorCategories?.length &&
        componentItem.layerType !== 'symbol'
      ) {
        const breakIntervals = componentItem.colorCategories.map(
          (record: Dictionary) => record.value
        );
        const categoryConfigs = {
          ...configs,
          classes: componentItem?.categoricalClasses || 10,
        };
        buildCategories(
          breakIntervals,
          categoryConfigs,
          dispatch,
          'colorCategories',
          shouldRebuildStops('colorCategories', componentItem)
        );
      } else if (
        componentItem.symbolMethod === 'categorical' &&
        componentItem.layerType === 'symbol' &&
        componentItem.symbolCategories?.length
      ) {
        const breakIntervals = componentItem.symbolCategories.map(
          (record: Dictionary) => record.value
        );
        const categoryConfigs = {
          ...configs,
          classes: componentItem?.categoricalClasses || 10,
        };
        buildSymbolCategories(
          breakIntervals,
          categoryConfigs,
          dispatch,
          'symbolCategories',
          shouldRebuildStops('symbolCategories', componentItem)
        );
      }
      // else if (component.type === "map" && !layerData) {
      //   // get layer data to post.data
      //   fetchLayerData(
      //     component,
      //     componentItem,
      //     source,
      //     actionComponentMapLayerBuildGeojson,
      //     50000,
      //     dispatch
      //   );
      // }
    }
    isMounted.current = true;
    prevValueRef.current = {
      colorScheme: componentItem.colorRange,
      colorScale: componentItem.colorScale,
      layerId: componentItem.id,
      componentId: component.id,
      classes: componentItem.classes,
      categoricalClasses: componentItem.categoricalClasses,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentItem.colorRange, componentItem.reverseColors]);

  return (
    <div className='color-input'>
      {!(
        componentItem.colorMethod === 'categorical' ||
        componentItem.colorMode === 'categories'
      ) && (
        <ClassesInput
          scheme={value}
          classes={classes}
          configs={componentConfigs}
          hideClassInput={hideClassInput}
        />
      )}
      <label>{label}</label>
      <div
        className='color-range--selected'
        onClick={() => setShowColorPicker(true)}
      >
        <ColorRange
          reverseColors={componentItem.reverseColors}
          classes={classes}
          scheme={value}
          configs={componentConfigs}
          colorList={colorPropGetter(componentItem, component)}
        />
      </div>
      {showColorPicker && (
        <div className='color-range-input-panel'>
          <CloseOutlined
            className='color-range-input-panel-close'
            onClick={() => {
              setShowColorPicker(false);
            }}
          />
          {colorSchemes.map((colorScheme: colorSchemesProps, key: number) => {
            const colorRanges = colorScheme.colors.map(
              (color: string, index: number) => {
                return (
                  <ColorRange
                    key={index}
                    classes={classes}
                    scheme={color}
                    configs={componentConfigs}
                    reverseColors={componentItem.reverseColors}
                  />
                );
              }
            );
            return (
              <>
                <label key={key}>{colorScheme.type}</label>
                {colorRanges}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export { ColorRangeInput };
