import React, { useEffect, useState } from 'react';
import { InputNumber, Row, Tooltip, Typography } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { ChartGroupSettingsTypes } from '../../../../../configs/types';
import { QuestionCircleFilled } from '@ant-design/icons';
import { actionPostComponentSettingEdit } from '../../../actions';
import { handlePayloadDispatch } from './helpers';
import { actionPostPropertyEdit } from '../../../actions';

export interface FontSizeInputProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: any;
}

const FontSizeInput: React.FC<FontSizeInputProps> = (props: FontSizeInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, tooltip, entity, min, max, propertyKey, placeHolder, cols, objectKey, parents } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const { type } = component;
  const parentProperty = type === 'map' ? 'layers' : 'properties';
  const [value, setValue] = useState<number>(0);
  const [defaultPageInputSizeError, setDefaultPageSizeInputError] = useState('');

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      let currentValue =
        component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue =
          component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      let currentValue = component?.[parents[0]]?.[itemIndex]?.[property];
      if (objectKey) {
        currentValue = component?.[parents[0]]?.[itemIndex]?.[property]?.[objectKey];
      }
      setValue(currentValue);
    }
    if (!parents && componentIndex >= 0) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      if (post[property] && objectKey) {
        setValue(post[property][objectKey]);
      } else {
        setValue(post[property]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <>
      <label>
        {label}
        &nbsp;
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionCircleFilled />
          </Tooltip>
        )}
      </label>
      <InputNumber
        min={min}
        max={max}
        style={cols === 24 ? { width: 250 } : undefined}
        placeholder={placeHolder ? placeHolder : undefined}
        value={value}
        onChange={(value) => {
          if (value) {
            setValue(value);
          }
          if (item.entity === 'post') {
            dispatch(
              actionPostPropertyEdit({
                property: property,
                objectKey: objectKey,
                value: value,
              })
            );
            post.components.forEach((item: Dictionary, index: number) => {
              if (item.type === 'text' ||
              item.type === 'title') {
                dispatch(
                actionPostComponentSettingEdit({
                  property: 'fontSize',
                  componentIndex: index,
                  value: value,
                })
                )
              }
            })
          }
          /* @ts-ignore */
          handlePayloadDispatch(dispatch, {
            parentProperty: parentProperty,
            property: property,
            componentIndex: componentIndex,
            itemIndex: itemIndex,
            childIndex: childIndex,
            value: value,
            setDefaultPageSizeInputError: setDefaultPageSizeInputError,
            propertyKey: propertyKey,
            objectKey,
            parents,
          });
          
        }}
      />
      {defaultPageInputSizeError && (
        <Row>
          <Typography.Text type="danger">{defaultPageInputSizeError}</Typography.Text>
        </Row>
      )}
    </>
  );
};

export { FontSizeInput };
