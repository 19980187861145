import React, { useEffect, useState } from 'react';
import { Input, Button, Form, Select, Row, Col, message } from 'antd';
import { useDispatch, useStore } from 'react-redux';
import { Dictionary } from '@onaio/utils';

import { actionSourceSave } from '../../actions';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../../configs/env';
import { defaultProgressStatusTypesForAsyncTasks, Source } from '../../../../configs/component-types';
import { transformSourceUIDimensionsAndMeasuresToCubeFormat, updateSourceDimensions } from '../../helpers/helpers';
import { SUBMIT_BUTTON_TEXTS } from '../constants';
import { DIMENSION_CREATED } from './constants';
import { triggerAsycSourceOperation } from '../SourceWebsocketsUpdatesHelpers';

const { Option } = Select;
const { TextArea } = Input;

interface SourceEditOptions {
  itemIndex: number;
  parentProperty: string;
  property: string;
  value: string;
}

export interface DimensionFormProps {
  item: Dictionary;
  sourceActionSaveCreator?: (obj: Source) => void;
  sourceNestedItemEditCreator?: (obj: SourceEditOptions) => void;
}

interface InitialValues {
  title: string;
  type: string;
  prefix: string;
  suffix: string;
  format: string;
  value: string;
  sql: string;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const DimensionForm: React.FC<DimensionFormProps> = (props: DimensionFormProps) => {
  const { item, sourceActionSaveCreator } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const currentState = store.getState();
  /* @ts-ignore */
  const sourceObj = Object.assign({}, currentState.source);
  const [isSubmitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState<
      | { stage: defaultProgressStatusTypesForAsyncTasks; data: Dictionary[]; errors: Dictionary[] }
      | Dictionary
    >({});
  const isDisabled = sourceObj.public ? true : false;
  const asyncTaskName = 'Dimension creation';

  useEffect(() => {
    if (progress?.stage === defaultProgressStatusTypesForAsyncTasks.DONE) {
      if (progress?.data[0]?.source) {
        if (sourceActionSaveCreator) {
          dispatch(
            /* @ts-ignore */
            sourceActionSaveCreator({
              ...progress?.data[0]?.source,
            })
          );
        }
      }
      setSubmitting(false);
      setProgress({});
      message.success(DIMENSION_CREATED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const formInitialValues: InitialValues = {
    title: item.title,
    type: item.type,
    prefix: item.prefix,
    suffix: item.suffix,
    format: item.format,
    value: item.value,
    sql: item.sql || item?.value,
  };

  return (
    <Form
      layout="vertical"
      initialValues={formInitialValues}
      onFinish={(values) => {
        setSubmitting(true);
        const { dimensions: cubeDimensions } = transformSourceUIDimensionsAndMeasuresToCubeFormat({
          dimensions: [values],
          measures: [],
          cubeDimensions: [],
          cubeMeasures: [],
        });
        triggerAsycSourceOperation({
          asynFunc: updateSourceDimensions,
          asynFuncArgs: {
            sourceId: sourceObj?.uuid,
            dimensions: cubeDimensions,
            cubeName: sourceObj?.cube,
          },
          asyncTaskName: asyncTaskName,
          setAsyncTaskProgressData: setProgress,
          setLoading: setSubmitting,
          webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
        });
      }}
    >
      <Row gutter={10}>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Name"
            name="value"
            rules={[
              {
                required: true,
                validator: (field, value) => {
                  if (Number(value.substring(0, 1))) {
                    return Promise.reject('Dimension names must begin with a letter.');
                  }
                  if (value.includes(' ')) {
                    return Promise.reject(
                      'No spaces are allowed in Dimension names. Use underscores (_) instead.'
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input disabled={isDisabled || isSubmitting} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item label="Label" name="title">
            <Input disabled={isDisabled || isSubmitting} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select disabled={isDisabled || isSubmitting}>
              <Option value="string">String</Option>
              <Option value="number">Number</Option>
              <Option value="time">Time</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24}>
          <Form.Item
            label="SQL"
            name="sql"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea className="cube-input" disabled={isDisabled || isSubmitting} rows={4} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Prefix" name="prefix">
            <Input disabled={isDisabled || isSubmitting} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Format" name="format">
            <Input disabled={isDisabled || isSubmitting} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Suffix" name="suffix">
            <Input disabled={isDisabled || isSubmitting} />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" disabled={isSubmitting || isDisabled}>
        {isSubmitting ? SUBMIT_BUTTON_TEXTS.saving : SUBMIT_BUTTON_TEXTS.default}
      </Button>
    </Form>
  );
};

DimensionForm.defaultProps = defaultProps;

export { DimensionForm };
