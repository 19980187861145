import React, { useEffect, useState } from 'react';
import { Select, Row, Col } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { actionPostComponentSettingEdit } from '../../../actions';
import { excludePreSelectedOptions } from './helpers/utils/utils';
import { createSourceBasedOnGroupBy } from '../DimensionGroupBy/helpers/dataLoaders/dataLoaders';
import { getFilters } from '../../../helpers';
const { Option } = Select;

export interface GroupByColorProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
}

const GroupByColor: React.FC<GroupByColorProps> = (props: GroupByColorProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];
  const { id, cube } = component;
  const [selectValues, setSelectValues] = useState<Dictionary[]>([]);
  const [value, setValue] = useState('');

  let source = component?.source;
  let targetProperty = '';
  let groupByValue = '';
  let groupByColors: Dictionary[] = [];

  if (parents && parents.length === 1 && itemIndex !== undefined) {
    source = component?.source;
    targetProperty = component?.[parents[0]]?.[itemIndex]?.[property];
    groupByValue = component?.[parents[0]]?.[itemIndex]?.['groupBy'];
    groupByColors = component?.[parents[0]]?.[itemIndex]?.['groupByColors'];
  }
  if (parents && parents.length === 2 && childIndex !== undefined && itemIndex !== undefined) {
    targetProperty = component?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.['value'];
    groupByValue = component?.[parents[0]]?.[childIndex]?.['groupBy'];
    groupByColors = component?.[parents[0]]?.[childIndex]?.['groupByColors'];
  }
  const sourceObj = post.sources[source];

  useEffect(() => {
    // clear groupByColors array
    const indexes = {
      componentIndex: componentIndex,
      id: id,
      sourceId: source,
      groupById: `${id}-group-by-${itemIndex}`,
      filters: component.filters?.length ? getFilters(component.filters || [], component.cube) : [],
    };
    createSourceBasedOnGroupBy(
      `${cube}.${groupByValue}`,
      indexes,
      dispatch,
      sourceObj?.cube,
      sourceObj?.refresh_key
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!parents) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      setValue(post[property]);
    }
    if (parents.length > 0) {
      setValue(targetProperty);
    }

    const options = excludePreSelectedOptions(
      post.data?.[`${id}-group-by-${itemIndex}`],
      groupByColors,
      `${cube}.${groupByValue}`
    );
    setSelectValues(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <Row gutter={10}>
      <Col sm={6}>
      <label>{label}</label>
      </Col>
      <Col sm={18}>
      <Select
        showSearch
        allowClear
        // mode={'multiple'}
        value={value}
        getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
        onChange={(value) => {
          if (value) {
            setValue(value);
            dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: property,
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                value: value,
              })
            );
          } else {
            setValue('');
            dispatch(
              actionPostComponentSettingEdit({
                parents: parents,
                property: property,
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                childIndex: childIndex,
                value: '',
              })
            );
          }
        }}
      >
        {selectValues?.map((item: Dictionary, index: number) => (
          <Option key={index} value={item[`${sourceObj.cube}.${groupByValue}`]}>
            {item[`${sourceObj.cube}.${groupByValue}`]}
          </Option>
        ))}
      </Select>
      </Col>
    </Row>
  );
};

export { GroupByColor };
