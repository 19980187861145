import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  categoriesGetter,
  colorFieldGetter,
  excludePreSelectedOptions,
} from './utils/utils';
import {
  actionPostArrayItemAdd,
  actionPostComponentSettingEdit,
} from '../../../actions';
import { makeId } from '../ArrayInput/utils/helpers';
const { Option } = Select;

export interface CategorySelectProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
  componentLevelColors: Dictionary;
}

const CategorySelect: React.FC<CategorySelectProps> = (
  props: CategorySelectProps
) => {
  const { componentIndex, childIndex, itemIndex, item, componentLevelColors } =
    props;
  const {
    colorCategories,
    value,
    showOtherCategories,
    colorField,
    symbolField,
    radiusCategories,
    symbolCategories,
  } = componentLevelColors;
  const { label, parents } = item;
  const post = useSelector((state: Dictionary) => state.post);
  const dispatch = useDispatch();
  const component = post.components[componentIndex];
  const { id, cube } = component;
  const [selectValues, setSelectValues] = useState<Dictionary[]>([]);

  let source = component?.source;
  let componentId = id;
  let componentCube = cube;

  if (parents && parents.length === 1 && itemIndex !== undefined) {
    source =
      component.type === 'map'
        ? component?.[parents[0]]?.[itemIndex]?.source
        : source;
    componentId =
      component.type === 'map'
        ? component?.[parents[0]]?.[itemIndex]?.id
        : componentId;
    componentCube =
      component.type === 'map'
        ? component?.[parents[0]]?.[itemIndex]?.cube
        : componentCube;
  }
  const sourceObj = post.sources[source];
  useEffect(() => {
    const componentData =
      component.type === 'map'
        ? post.data?.[`layer-${componentLevelColors.id}`]
        : post.data?.[`${componentId}`];
    const options = excludePreSelectedOptions(
      componentData,
      categoriesGetter(componentLevelColors),
      `${componentCube}.${colorFieldGetter(
        componentLevelColors,
        symbolField,
        value,
        colorField
      )}`
    );

    setSelectValues(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    post,
    parents,
    itemIndex,
    childIndex,
    componentIndex,
    colorCategories?.length,
    symbolCategories?.length,
    showOtherCategories,
  ]);

  return (
    <>
      <label>{label}</label>
      <Select
        showSearch
        allowClear
        value='+ Add Category'
        onChange={(value) => {
          if (
            componentLevelColors?.categoryCircleRadiusField &&
            componentLevelColors?.radiusScale === 'categorical'
          ) {
            dispatch(
              actionPostArrayItemAdd({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: 'radiusCategories',
                value: {
                  id: makeId(),
                  value: value,
                  radius: 3,
                },
              })
            );

            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: 'regenerateBreaks',
                value: false,
              })
            );
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: 'categoricalRadiusClasses',
                value: radiusCategories?.length + 1,
              })
            );
          } else {
            const symbol =
              componentLevelColors?.layerType === 'symbol'
                ? {
                    symbol: {
                      id: 'Add',
                      src: 'https://s3.amazonaws.com/assets.akuko.io/ocha-icons-black-svg/Add.svg',
                      title: 'Add',
                    },
                  }
                : {};

            dispatch(
              actionPostArrayItemAdd({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property:
                  componentLevelColors?.layerType === 'symbol'
                    ? 'symbolCategories'
                    : 'colorCategories',
                value: {
                  id: makeId(),
                  ...{
                    value: value,
                    color: '#D9D9D9',
                    ...symbol,
                  },
                },
              })
            );
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: 'regenerateBreaks',
                value: false,
              })
            );
            dispatch(
              actionPostComponentSettingEdit({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: 'categoricalClasses',
                value: symbolCategories?.length + 1,
              })
            );
          }
        }}
      >
        {selectValues?.map((item: Dictionary, index: number) => {
          return (
            <Option
              key={index}
              value={
                item[
                  `${sourceObj.cube}.${colorFieldGetter(
                    componentLevelColors,
                    symbolField,
                    value,
                    colorField
                  )}`
                ]
              }
            >
              {
                item[
                  `${sourceObj.cube}.${colorFieldGetter(
                    componentLevelColors,
                    symbolField,
                    value,
                    colorField
                  )}`
                ]
              }
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export { CategorySelect };
