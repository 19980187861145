import React from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import './style.css';
import { getComponentById, getComponentSource } from '../../../reducers/selectors/post';
import { ChartComponent } from '../../../configs/component-types';
import {
  ColumnChart,
  LineChart,
  BarChart,
  AreaChart,
  StackedBarChart,
  StackedColumnChart,
  StackedAreaChart,
  PieChart,
  PolarChart,
  RadarChart,
  ScatterPlot,
  BubbleChartHoc,
  TreeMapChart,
} from './components/ChartComponents';
import { useMemo } from 'react';
import { GenericComponent } from '../Component';
import { useFetchComponentData } from '../hooks';
import { CHART_DEFAULT_QUERY_LIMIT } from '../../../configs/constants';
import { BarChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { getContextRow } from '../helpers';

/** selector factories */
const makeGetComponentById = () => getComponentById;
const makeGetComponentSource = () => getComponentSource;

/** interface for component props */
export interface ChartProps {
  componentIndex: number; // index of the chart component
  componentId: string; // id of the chart component
  filterByValue?: string; // value to filter by against the data
  dataRow?: Dictionary;
  cardIndex?: number; // card index
  isEmbed?: boolean;
}
const Chart: React.FC<ChartProps> = React.memo((props: ChartProps) => {
  // Memoize selectors
  const selectComponentById = useMemo(makeGetComponentById, []);
  const selectComponentSource = useMemo(makeGetComponentSource, []);

  const { componentIndex, dataRow, cardIndex, isEmbed, componentId } = props;

  const component = useSelector((state) => {
    /* @ts-ignore */
    const chart = selectComponentById(state, { componentId });

    if (!chart) {
      return undefined;
    }
    return chart as ChartComponent;
  });

  /* @ts-ignore */
  const source = useSelector((state) => selectComponentSource(state, { componentId }));

  const [isLoading, data] = useFetchComponentData(
    componentId,
    component?.limit ? Number(component.limit) : CHART_DEFAULT_QUERY_LIMIT,
    cardIndex
  );

  if (!component) {
    return null;
  }
  const { filteredData } = getContextRow(dataRow, data, component, true);
  const chartProps = {
    component,
    data: filteredData,
    source,
  };
  const genericComponentProps = {
    componentIndex,
    componentId,
    dataRow,
    isChart: true,
    drawerTitle: 'Chart',
    isEmbed: isEmbed,
  };

  return (
    <GenericComponent {...genericComponentProps}>
      {isLoading && (
        <div className="component-loader">
          <LoadingOutlined />
        </div>
      )}
      <div className="chart" style={{ background: component.backgroundColor || 'transparent' }}>
        {component.chartType === 'scatterPlot' && <ScatterPlot {...chartProps} />}
        {component.chartType === 'column' && <ColumnChart {...chartProps} />}
        {component.chartType === 'bar' && <BarChart {...chartProps} />}
        {component.chartType === 'line' && <LineChart {...chartProps} />}
        {component.chartType === 'area' && <AreaChart {...chartProps} />}
        {component.chartType === 'pie' && <PieChart {...chartProps} />}
        {component.chartType === 'radar' && <RadarChart {...chartProps} />}
        {component.chartType === 'polar' && <PolarChart {...chartProps} />}
        {component.chartType === 'columnStack' && <StackedColumnChart {...chartProps} />}
        {component.chartType === 'barStack' && <StackedBarChart {...chartProps} />}
        {component.chartType === 'areaStack' && <StackedAreaChart {...chartProps} />}
        {component.chartType === 'bubble' && <BubbleChartHoc {...chartProps} />}
        {component.chartType === 'treeMap' && <TreeMapChart {...chartProps} />}
        {!component.chartType && !isLoading && (
          <div className="component-empty">
            <BarChartOutlined />
          </div>
        )}
      </div>
    </GenericComponent>
  );
});

Chart.displayName = 'chart';
export { Chart };
