import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../../configs/components";
import { Row, Tabs, Col } from "antd";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Dictionary } from "@onaio/utils/dist/types/types";
import "./style.css";
import {
  getComponentBasedOnType,
  shouldShowField,

} from "./helpers/helpers";

export interface SettingsProps {
  componentIndex: number;
  type?: string;
}

/** default component props */
const defaultProps: Partial<SettingsProps> = {
  type: "text",
};

/**
 * Component that displays the settings for a component
 * @param {SettingsProps}  props
 * @returns {React.FC} Settings component
 */

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const { componentIndex } = props;
  const store = useStore();
  const dispatch = useDispatch();
  const [section, setSection] = useState("post-components");
  const status = useSelector((state: Dictionary) => state.config);
  const post = useSelector((state: Dictionary) => state.post);
  const space = useSelector((state: Dictionary) => state.space);
  const [sourceId, setSourceId] = useState("");
  const [template, setTemplate] = useState("groups");
  const history = useHistory();

  useEffect(() => {
    if (
      componentIndex >= 0 &&
      post?.config?.type === "map" &&
      post?.components?.[componentIndex]?.type === "layout"
    ) {
      setTemplate("map");
    } else {
      setTemplate("groups");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post?.config?.type, componentIndex]);

  if (status.activeComponentIndex === 0 || status.activeComponentIndex > 0) {
    return (
      <div id="post--component-drawer" className="post--component-drawer">
        <div className="content" id="drawer-content">
          {/* Layer */}
          {status.activeLayerIndex >= 0 && (
            <div>
              <h3>Layer</h3>
              <Tabs 
                items={[
                  {
                    key: 'config',
                    label: 'Config',
                    children: (
                      <>
                      {config["layer"][template]?.map(
                        (group: string, index: number) => (
                          group !== 'Filters' &&
                          group !== 'Events' &&
                          <div key={index}>
                            <h3>{group}</h3>
                            
                            <div key={index}>
                              {config["layer"].sections?.[group] &&
                                config["layer"].sections[group].map(
                                  (section: string, sectionIndex: number) => (
                                    <Row
                                      style={{ margin: "10px 0" }}
                                      gutter={10}
                                      key={sectionIndex}
                                    >
                                      <Col xs={24}>
                                        <h4>{section}</h4>
                                      </Col>
                                      {post.components[componentIndex] &&
                                        config["layer"].fields.map(
                                          (item: Dictionary, index: number) =>
                                            item.group === group &&
                                            item.section === section && (
                                              <Col key={index} xs={item.cols || 24}>
                                                <div key={index}>
                                                  {shouldShowField(
                                                    post.components[componentIndex]
                                                      .layers,
                                                    status.activeLayerIndex,
                                                    item,
                                                    post.components[componentIndex],
                                                  ) &&
                                                    getComponentBasedOnType(
                                                      item,
                                                      componentIndex,
                                                      index,
                                                      0,
                                                      status.activeLayerIndex,
                                                    )}
                                                </div>
                                              </Col>
                                            ),
                                        )}
                                    </Row>
                                  ),
                                )}
        
                              <Row gutter={10}>
                                {!config["layer"].sections?.[group] &&
                                  post.components[componentIndex] &&
                                  config["layer"].fields.map(
                                    (item: Dictionary, index: number) =>
                                      item.group === group && (
                                        <Col key={index} xs={item.cols || 24}>
                                          <div key={index}>
                                            {shouldShowField(
                                              post.components[componentIndex].layers,
                                              status.activeLayerIndex,
                                              item,
                                              post.components[componentIndex],
                                            ) &&
                                              getComponentBasedOnType(
                                                item,
                                                componentIndex,
                                                index,
                                                0,
                                                status.activeLayerIndex,
                                              )}
                                          </div>
                                        </Col>
                                      ),
                                  )}
                              </Row>
                            </div>
                          </div>
                        ),
                      )}
                      </>
                    )
                  },
                  {
                    key: 'filters',
                    label: 'Filters',
                    children: (
                      <>
                      {config["layer"][template]?.map(
                        (group: string, index: number) => (
                          group === 'Filters' &&
                          <div key={index}>
                            <h3>{group}</h3>
                            
                            <div key={index}>
                              {config["layer"].sections?.[group] &&
                                config["layer"].sections[group].map(
                                  (section: string, sectionIndex: number) => (
                                    <Row
                                      style={{ margin: "10px 0" }}
                                      gutter={10}
                                      key={sectionIndex}
                                    >
                                      <Col xs={24}>
                                        <h4>{section}</h4>
                                      </Col>
                                      {post.components[componentIndex] &&
                                        config["layer"].fields.map(
                                          (item: Dictionary, index: number) =>
                                            item.group === group &&
                                            item.section === section && (
                                              <Col key={index} xs={item.cols || 24}>
                                                <div key={index}>
                                                  {shouldShowField(
                                                    post.components[componentIndex]
                                                      .layers,
                                                    status.activeLayerIndex,
                                                    item,
                                                    post.components[componentIndex],
                                                  ) &&
                                                    getComponentBasedOnType(
                                                      item,
                                                      componentIndex,
                                                      index,
                                                      0,
                                                      status.activeLayerIndex,
                                                    )}
                                                </div>
                                              </Col>
                                            ),
                                        )}
                                    </Row>
                                  ),
                                )}
        
                              <Row gutter={10}>
                                {!config["layer"].sections?.[group] &&
                                  post.components[componentIndex] &&
                                  config["layer"].fields.map(
                                    (item: Dictionary, index: number) =>
                                      item.group === group && (
                                        <Col key={index} xs={item.cols || 24}>
                                          <div key={index}>
                                            {shouldShowField(
                                              post.components[componentIndex].layers,
                                              status.activeLayerIndex,
                                              item,
                                              post.components[componentIndex],
                                            ) &&
                                              getComponentBasedOnType(
                                                item,
                                                componentIndex,
                                                index,
                                                0,
                                                status.activeLayerIndex,
                                              )}
                                          </div>
                                        </Col>
                                      ),
                                  )}
                              </Row>
                            </div>
                          </div>
                        ),
                      )}
                      </>
                    )
                  },
                  {
                    key: 'events',
                    label: 'Events',
                    children: (
<>
                      {config["layer"][template]?.map(
                        (group: string, index: number) => (
                          group === 'Events' &&
                          <div key={index}>
                            <h3>{group}</h3>
                            
                            <div key={index}>
                              {config["layer"].sections?.[group] &&
                                config["layer"].sections[group].map(
                                  (section: string, sectionIndex: number) => (
                                    <Row
                                      style={{ margin: "10px 0" }}
                                      gutter={10}
                                      key={sectionIndex}
                                    >
                                      <Col xs={24}>
                                        <h4>{section}</h4>
                                      </Col>
                                      {post.components[componentIndex] &&
                                        config["layer"].fields.map(
                                          (item: Dictionary, index: number) =>
                                            item.group === group &&
                                            item.section === section && (
                                              <Col key={index} xs={item.cols || 24}>
                                                <div key={index}>
                                                  {shouldShowField(
                                                    post.components[componentIndex]
                                                      .layers,
                                                    status.activeLayerIndex,
                                                    item,
                                                    post.components[componentIndex],
                                                  ) &&
                                                    getComponentBasedOnType(
                                                      item,
                                                      componentIndex,
                                                      index,
                                                      0,
                                                      status.activeLayerIndex,
                                                    )}
                                                </div>
                                              </Col>
                                            ),
                                        )}
                                    </Row>
                                  ),
                                )}
        
                              <Row gutter={10}>
                                {!config["layer"].sections?.[group] &&
                                  post.components[componentIndex] &&
                                  config["layer"].fields.map(
                                    (item: Dictionary, index: number) =>
                                      item.group === group && (
                                        <Col key={index} xs={item.cols || 24}>
                                          <div key={index}>
                                            {shouldShowField(
                                              post.components[componentIndex].layers,
                                              status.activeLayerIndex,
                                              item,
                                              post.components[componentIndex],
                                            ) &&
                                              getComponentBasedOnType(
                                                item,
                                                componentIndex,
                                                index,
                                                0,
                                                status.activeLayerIndex,
                                              )}
                                          </div>
                                        </Col>
                                      ),
                                  )}
                              </Row>
                            </div>
                          </div>
                        ),
                      )}
                      </>
                    )
                  },
                ]}
              />
              
            </div>
          )}

          {/* Item */}
          {status.activeItemIndex >= 0 && 
          (
            <div>
              <h3>Menu item</h3>
                {config["item"][template]?.map(
                (group: string, index: number) => (
                  <div key={index}>
                    <h3>{group}</h3>
                    <div key={index}>
                      {config["item"].sections?.[group] &&
                        config["item"].sections[group].map(
                          (section: string, sectionIndex: number) => (
                            <Row
                              style={{ margin: "10px 0" }}
                              gutter={10}
                              key={sectionIndex}
                            >
                              <Col xs={24}>
                                <h4>{section}</h4>
                              </Col>
                              {post.components[componentIndex] &&
                                config["item"].fields.map(
                                  (item: Dictionary, index: number) =>
                                    item.group === group &&
                                    item.section === section && (
                                      <Col key={index} xs={item.cols || 24}>
                                        <div key={index}>
                                          {shouldShowField(
                                            post.components[componentIndex]
                                              .properties,
                                            status.activeItemIndex,
                                            item,
                                            post.components[componentIndex],
                                          ) &&
                                            getComponentBasedOnType(
                                              item,
                                              componentIndex,
                                              index,
                                              0,
                                              status.activeItemIndex,
                                            )}
                                        </div>
                                      </Col>
                                    ),
                                )}
                            </Row>
                          ),
                        )}

                      <Row gutter={10}>
                        {!config["item"].sections?.[group] &&
                          post.components[componentIndex] &&
                          config["item"].fields.map(
                            (item: Dictionary, index: number) =>
                              item.group === group && (
                                <Col key={index} xs={item.cols || 24}>
                                  <div key={index}>
                                    {shouldShowField(
                                      post.components[componentIndex]
                                        .properties,
                                      status.activeItemIndex,
                                      item,
                                      post.components[componentIndex],
                                    ) &&
                                      getComponentBasedOnType(
                                        item,
                                        componentIndex,
                                        index,
                                        0,
                                        status.activeItemIndex,
                                      )}
                                  </div>
                                </Col>
                              ),
                          )}
                      </Row>
                    </div>
                  </div>
                ),
              )}
            </div>
          )}

          {/* Column */}
          {status.activeColumnIndex >= 0 && (
            <div>
              {config["column"][template]?.map(
                (group: string, index: number) => (
                  <div key={index}>
                    <h3>{group}</h3>
                    <div key={index}>
                      {config["column"].sections?.[group] &&
                        config["column"].sections[group].map(
                          (section: string, sectionIndex: number) => (
                            <Row
                              style={{ margin: "10px 0" }}
                              gutter={10}
                              key={sectionIndex}
                            >
                              <Col xs={24}>
                                <h4>{section}</h4>
                              </Col>
                              {post.components[componentIndex] &&
                                config["column"].fields.map(
                                  (item: Dictionary, index: number) =>
                                    item.group === group &&
                                    item.section === section && (
                                      <Col key={index} xs={item.cols || 24}>
                                        <div key={index}>
                                          {shouldShowField(
                                            post.components[componentIndex]
                                              .properties,
                                            status.activeColumnIndex,
                                            item,
                                            post.components[componentIndex],
                                          ) &&
                                            getComponentBasedOnType(
                                              item,
                                              componentIndex,
                                              index,
                                              0,
                                              status.activeColumnIndex,
                                            )}
                                        </div>
                                      </Col>
                                    ),
                                )}
                            </Row>
                          ),
                        )}

                      <Row gutter={10}>
                        {!config["column"].sections?.[group] &&
                          post.components[componentIndex] &&
                          config["column"].fields.map(
                            (item: Dictionary, index: number) =>
                              item.group === group && (
                                <Col key={index} xs={item.cols || 24}>
                                  <div key={index}>
                                    {shouldShowField(
                                      post.components[componentIndex]
                                        .properties,
                                      status.activeColumnIndex,
                                      item,
                                      post.components[componentIndex],
                                    ) &&
                                      getComponentBasedOnType(
                                        item,
                                        componentIndex,
                                        index,
                                        0,
                                        status.activeColumnIndex,
                                      )}
                                  </div>
                                </Col>
                              ),
                          )}
                      </Row>
                    </div>
                  </div>
                ),
              )}
            </div>
          )}

          {/* Component */}
          {status.activeColumnIndex < 0 &&
            status.activeLayerIndex < 0 &&
            status.activeItemIndex < 0 &&
            status.activeComponentIndex >= 0 &&
            post.components[status.activeComponentIndex] && (
              <div>
                <h3>{post.components[componentIndex].type}</h3>
                <Tabs
                  items={[
                    {
                      key: "config",
                      label: "Config",
                      children: (
                        <>
                          {status.activeColumnIndex < 0 &&
                            status.activeLayerIndex < 0 &&
                            status.activeComponentIndex >= 0 &&
                            post.components[status.activeComponentIndex] && (
                              <div>
                                {config[post.components[componentIndex].type][
                                  template
                                ]?.map(
                                  (group: string, index: number) =>
                                    group !== "Style" && 
                                    group !== "Filters" &&
                                    group !== "Events" &&
                                  (
                                      <div key={index}>
                                        <h3>{group}</h3>
                                        <div key={index}>
                                          {config[
                                            post.components[componentIndex].type
                                          ].sections?.[group] &&
                                            config[
                                              post.components[componentIndex]
                                                .type
                                            ].sections[group].map(
                                              (
                                                section: string,
                                                sectionIndex: number,
                                              ) => (
                                                <Row
                                                  style={{ margin: "10px 0" }}
                                                  gutter={10}
                                                  key={sectionIndex}
                                                >
                                                  <Col xs={24}>
                                                    <h4>{section}</h4>
                                                  </Col>
                                                  {post.components[
                                                    componentIndex
                                                  ] &&
                                                    config[
                                                      post.components[
                                                        componentIndex
                                                      ].type
                                                    ].fields.map(
                                                      (
                                                        item: Dictionary,
                                                        index: number,
                                                      ) =>
                                                        item.group === group &&
                                                        item.section ===
                                                          section && (
                                                          <Col
                                                            key={index}
                                                            xs={item.cols || 24}
                                                          >
                                                            <div key={index}>
                                                              {shouldShowField(
                                                                post.components,
                                                                componentIndex,
                                                                item,
                                                                status,
                                                              ) &&
                                                                getComponentBasedOnType(
                                                                  item,
                                                                  componentIndex,
                                                                  index,
                                                                )}
                                                            </div>
                                                          </Col>
                                                        ),
                                                    )}
                                                </Row>
                                              ),
                                            )}

                                          <Row gutter={10}>
                                            {!config[
                                              post.components[componentIndex]
                                                .type
                                            ].sections?.[group] &&
                                              post.components[componentIndex] &&
                                              config[
                                                post.components[componentIndex]
                                                  .type
                                              ].fields.map(
                                                (
                                                  item: Dictionary,
                                                  index: number,
                                                ) =>
                                                  item.group === group && (
                                                    <Col
                                                      key={index}
                                                      xs={item.cols || 24}
                                                    >
                                                      <div key={index}>
                                                        {shouldShowField(
                                                          post.components,
                                                          componentIndex,
                                                          item,
                                                          status,
                                                        ) &&
                                                          getComponentBasedOnType(
                                                            item,
                                                            componentIndex,
                                                            index,
                                                          )}
                                                      </div>
                                                    </Col>
                                                  ),
                                              )}
                                          </Row>
                                        </div>
                                      </div>
                                    ),
                                )}
                              </div>
                            )}
                        </>
                      ),
                    },
                    {
                      key: "style",
                      label: "Style",
                      children: (
                        <>
                          {status.activeColumnIndex < 0 &&
                            status.activeLayerIndex < 0 &&
                            status.activeComponentIndex >= 0 &&
                            post.components[status.activeComponentIndex] && (
                              <div>
                                {config[post.components[componentIndex].type][
                                  "styles"
                                ]?.map((group: string, index: number) => (
                                  <div key={index}>
                                    <h3>{group}</h3>
                                    <div key={index}>
                                      <Row gutter={10}>
                                        {!config[
                                          post.components[componentIndex].type
                                        ].sections?.[group] &&
                                          post.components[componentIndex] &&
                                          config[
                                            post.components[componentIndex].type
                                          ].fields.map(
                                            (item: Dictionary, index: number) =>
                                              item.group === group && (
                                                <Col
                                                  key={index}
                                                  xs={item.cols || 24}
                                                >
                                                  <div key={index}>
                                                    {shouldShowField(
                                                      post.components,
                                                      componentIndex,
                                                      item,
                                                      status,
                                                    ) &&
                                                      getComponentBasedOnType(
                                                        item,
                                                        componentIndex,
                                                        index,
                                                      )}
                                                  </div>
                                                </Col>
                                              ),
                                          )}
                                      </Row>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </>
                      ),
                    },
                    ...( config[post.components[componentIndex].type].filters ?
                      [
                    {
                      key: "filters",
                      label: "Filters",
                      children: (
                        <>
                          {status.activeColumnIndex < 0 &&
                            status.activeLayerIndex < 0 &&
                            status.activeComponentIndex >= 0 &&
                            post.components[status.activeComponentIndex] && (
                              <div>
                                {config[post.components[componentIndex].type][
                                  template
                                ]?.map((group: string, index: number) => (
                                  group === 'Filters' &&
                                  <div key={index}>
                                    <h3>{group}</h3>
                                    <div key={index}>
                                      <Row gutter={10}>
                                        {!config[
                                          post.components[componentIndex].type
                                        ].sections?.[group] &&
                                          post.components[componentIndex] &&
                                          config[
                                            post.components[componentIndex].type
                                          ].fields.map(
                                            (item: Dictionary, index: number) =>
                                              item.group === group && (
                                                <Col
                                                  key={index}
                                                  xs={item.cols || 24}
                                                >
                                                  <div key={index}>
                                                    {shouldShowField(
                                                      post.components,
                                                      componentIndex,
                                                      item,
                                                      status,
                                                    ) &&
                                                      getComponentBasedOnType(
                                                        item,
                                                        componentIndex,
                                                        index,
                                                      )}
                                                  </div>
                                                </Col>
                                              ),
                                          )}
                                      </Row>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </>
                      ),
                    },
                  ]  : []),
                    ...( config[post.components[componentIndex].type].events
                      ? [
                          {
                            key: "events",
                            label: "Events",
                            children: (
                              <>
                                {status.activeColumnIndex < 0 &&
                                  status.activeLayerIndex < 0 &&
                                  status.activeComponentIndex >= 0 &&
                                  post.components[status.activeComponentIndex] && (
                                    <div>
                                      {config[
                                        post.components[componentIndex].type
                                      ][template]?.map((group: string, index: number) => (
                                        group === "Events" && (
                                          <div key={index}>
                                            <h3>{group}</h3>
                                            <div key={index}>
                                              <Row gutter={10}>
                                                {!config[
                                                  post.components[
                                                    componentIndex
                                                  ].type
                                                ].sections?.[group] &&
                                                  post.components[componentIndex] &&
                                                  config[
                                                    post.components[
                                                      componentIndex
                                                    ].type
                                                  ].fields.map(
                                                    (item: Dictionary, index: number) =>
                                                      item.group === group && (
                                                        <Col
                                                          key={index}
                                                          xs={item.cols || 24}
                                                        >
                                                          <div key={index}>
                                                            {shouldShowField(
                                                              post.components,
                                                              componentIndex,
                                                              item,
                                                              status,
                                                            ) &&
                                                              getComponentBasedOnType(
                                                                item,
                                                                componentIndex,
                                                                index,
                                                              )}
                                                          </div>
                                                        </Col>
                                                      ),
                                                  )}
                                              </Row>
                                            </div>
                                          </div>
                                        )
                                      ))}
                                    </div>
                                  )}
                              </>
                            ),
                          },
                        ]
                      : []),
                  ]}
                />
              </div>
            )}
        </div>
      </div>
    );
  }

  return null;
};

Settings.defaultProps = defaultProps;

export { Settings };
