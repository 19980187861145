import React, { useState, useEffect, useRef } from 'react';
import { Input, InputRef, Tag, Tooltip, theme } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

interface TippecanoeOptionsTagProps {
  setTippecanoeOptionsList: (options: string[]) => void;
  tippecanoeOptionsList: string[];
  disable?: boolean;
}

/** default component props */
const defaultProps: Partial<TippecanoeOptionsTagProps> = {
  disable: false,
};

const TippecanoeOptionsTag: React.FC<TippecanoeOptionsTagProps> = (
  props: TippecanoeOptionsTagProps
) => {
  const { tippecanoeOptionsList, setTippecanoeOptionsList, disable } = props;
  const [tippecanoeOptionInputVisible, setTippecanoeOptionInputVisible] = useState(false);
  const [tippecanoeOptionInputValue, setTippecanoeOptionInputValue] = useState('');
  const [editTippecanoeOptionInputValue, setEditTippecanoeOptionInputValue] = useState('');
  const [editTippecanoeOptionInputIndex, setEditTippecanoeOptionInputIndex] = useState(-1);
  const tippecanoeOptionInputRef = useRef<InputRef>(null);
  const editTippecanoeOptionInputRef = useRef<InputRef>(null);
  const [tippecanoeOptionInputStyle, setTippecanoeOptionInputStyle] = useState<React.CSSProperties>(
    {
      width: '15em',
      height: 30,
      marginInlineEnd: 8,
      verticalAlign: 'top',
    }
  );
  const { token } = theme.useToken();
  const [tippecanoeOptionPlusStyle, setTippecanoeOptionPlusStyle] = useState<React.CSSProperties>({
    height: 22,
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  });

  useEffect(() => {
    if (disable === true) {
      setTippecanoeOptionInputStyle({
        ...tippecanoeOptionInputStyle,
        color: '#aaa',
        cursor: 'not-allowed',
      });
      setTippecanoeOptionPlusStyle({
        ...tippecanoeOptionPlusStyle,
        color: '#aaa',
        cursor: 'not-allowed',
      });
    }
    if (disable === false) {
      const inputStyle = {
        ...tippecanoeOptionInputStyle,
      };
      delete inputStyle.color;
      delete inputStyle.cursor;
      setTippecanoeOptionInputStyle({
        ...inputStyle,
      });
      const plusStyle = {
        ...tippecanoeOptionPlusStyle,
      };
      delete plusStyle.color;
      delete plusStyle.cursor;
      setTippecanoeOptionPlusStyle({
        ...plusStyle,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable]);
  const handleTippecanoeOptionsClose = (removedTippecanoeOption: string) => {
    const newTippecanoeOptions = tippecanoeOptionsList.filter(
      (option: string) => option !== removedTippecanoeOption
    );
    setTippecanoeOptionsList(newTippecanoeOptions);
  };

  useEffect(() => {
    if (tippecanoeOptionInputVisible) {
      tippecanoeOptionInputRef.current?.focus();
    }
  }, [tippecanoeOptionInputVisible]);

  useEffect(() => {
    editTippecanoeOptionInputRef.current?.focus();
  }, [editTippecanoeOptionInputValue]);

  const showTippecanoeOptionInput = () => {
    setTippecanoeOptionInputVisible(true);
  };

  const handleTippecanoeOptionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTippecanoeOptionInputValue(e.target.value);
  };

  const handleTippecanoeOptionInputConfirm = () => {
    if (tippecanoeOptionInputValue && !tippecanoeOptionsList.includes(tippecanoeOptionInputValue)) {
      setTippecanoeOptionsList([...tippecanoeOptionsList, tippecanoeOptionInputValue]);
    }
    setTippecanoeOptionInputVisible(false);
    setTippecanoeOptionInputValue('');
  };

  const handleTippecanoeOptionEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditTippecanoeOptionInputValue(e.target.value);
  };

  const handleTippecanoeOptionEditInputConfirm = () => {
    const newTippecanoeOptionsList = [...tippecanoeOptionsList];
    newTippecanoeOptionsList[editTippecanoeOptionInputIndex] = editTippecanoeOptionInputValue;
    setTippecanoeOptionsList(newTippecanoeOptionsList);
    setEditTippecanoeOptionInputIndex(-1);
    setEditTippecanoeOptionInputValue('');
  };

  return (
    <>
      {tippecanoeOptionsList.map((tippecanoeOption: string, index: number) => {
        if (editTippecanoeOptionInputIndex === index) {
          return (
            <Input
              ref={editTippecanoeOptionInputRef}
              key={tippecanoeOption}
              size="large"
              placeholder="Tippecanoe option"
              style={tippecanoeOptionInputStyle}
              value={editTippecanoeOptionInputValue}
              onChange={handleTippecanoeOptionEditInputChange}
              onBlur={handleTippecanoeOptionEditInputConfirm}
              onPressEnter={handleTippecanoeOptionEditInputConfirm}
              suffix={
                <Tooltip title="Tippecanoe options e.g. --maximum-zoom=20">
                  <InfoCircleOutlined style={{ fontSize: '12px' }} />
                </Tooltip>
              }
            />
          );
        }
        const isLongOption = tippecanoeOption.length > 20;
        const tippecanoeOptionElem = (
          <Tag
            key={tippecanoeOption}
            closable={disable ? false : true}
            style={{ userSelect: 'none' }}
            onClose={() => handleTippecanoeOptionsClose(tippecanoeOption)}
          >
            <span
              style={disable ? { color: '#aaa', cursor: 'not-allowed' } : {}}
              onDoubleClick={(e) => {
                setEditTippecanoeOptionInputIndex(index);
                setEditTippecanoeOptionInputValue(tippecanoeOption);
                e.preventDefault();
              }}
            >
              {isLongOption ? `${tippecanoeOption.slice(0, 20)}...` : tippecanoeOption}
            </span>
          </Tag>
        );
        return isLongOption ? (
          <Tooltip title={tippecanoeOption} key={tippecanoeOption}>
            {tippecanoeOptionElem}
          </Tooltip>
        ) : (
          tippecanoeOptionElem
        );
      })}
      {tippecanoeOptionInputVisible ? (
        <Input
          ref={tippecanoeOptionInputRef}
          type="text"
          size="large"
          placeholder="Tippecanoe option"
          style={tippecanoeOptionInputStyle}
          value={tippecanoeOptionInputValue}
          onChange={handleTippecanoeOptionInputChange}
          onBlur={handleTippecanoeOptionInputConfirm}
          onPressEnter={handleTippecanoeOptionInputConfirm}
          suffix={
            <Tooltip title="Tippecanoe options e.g. --maximum-zoom=20">
              <InfoCircleOutlined style={{ fontSize: '12px' }} />
            </Tooltip>
          }
        />
      ) : (
        <Tag
          style={tippecanoeOptionPlusStyle}
          icon={<PlusOutlined />}
          onClick={showTippecanoeOptionInput}
        >
          New Option
        </Tag>
      )}
    </>
  );
};

TippecanoeOptionsTag.defaultProps = defaultProps;

export { TippecanoeOptionsTag };
