export const actionComponentColumnEdit = (data) => {
  return {
    type: 'POST_COMPONENT_COLUMN_EDIT',
    data,
  };
};

export const actionComponentGroupByEdit = (data) => {
  return {
    type: 'POST_COMPONENT_GROUP_BY_EDIT',
    data,
  };
};

export const actionComponentTableSortEdit = (data) => {
  return {
    type: 'POST_COMPONENT_TABLE_SORT_EDIT',
    data,
  };
};
