import { GroupFieldProps } from '../types';

export const map: GroupFieldProps = {
  groups: ['Map', 'Position', 'Zoom Control', 'Location Control'],
  styles: ['Width','Base Map'],
  filters: false,
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Map',
      placeHolder: 'Map Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Map',
      placeHolder: 'Map Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Map',
      placeHolder: 'Map Identifier',
    },
    {
      type: 'lat-lng',
      label: 'Default position',
      property: 'defaultPosition',
      group: 'Position',
    },

    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'slider',
      label: 'Height',
      property: 'height',
      min: 100,
      max: 1000,
      group: 'Map',
    },
    {
      type: 'select',
      label: 'Map style',
      property: 'styleOption',
      group: 'Base Map',
      options: [
        { value: 'default-style', label: 'Light' },
        { value: 'dark', label: 'Dark' },
        { value: 'outdoors', label: 'Outdoors' },
        { value: 'terrain', label: 'Terrain' },
        { value: 'satstreets', label: 'Satelitte Streets' },
        { value: 'sat', label: 'Satelitte' },
        { value: 'url-style', label: 'Custom style URL' },
        { value: 'no-style', label: 'No style' },
      ],
    },
    {
      type: 'text',
      label: 'Style url',
      property: 'mapStyle',
      group: 'Base Map',
      dependsOn: [
        {
          property: 'styleOption',
          value: 'url-style',
        },
      ],
    },
    {
      type: 'color',
      label: 'Background',
      property: 'styleColor',
      group: 'Base Map',
      dependsOn: [
        {
          property: 'styleOption',
          value: 'no-style',
        },
      ],
    },
    {
      type: 'select',
      label: 'Projection',
      property: 'projection',
      placeHolder: 'Mercator',
      group: 'Base Map',
      options: [
        { value: 'globe', label: 'Globe' },
        { value: 'albers', label: 'Albers' },
        { value: 'equalEarth', label: 'EqualEarth' },
        { value: 'equirectangular', label: 'Equirectangular' },
        { value: 'lambertConformalConic', label: 'Lambert Conformal Conic' },
        { value: 'equalEarth', label: 'EqualEarth' },
        { value: 'naturalEarth', label: 'Natural Earth' },
        { value: 'winkelTripel', label: 'Winkel Tripel' },
        { value: 'mercator', label: 'Mercator' },
      ],
    },
    {
      type: 'switch',
      label: 'Enable Fog',
      property: 'terrain',
      group: 'Base Map',
    },
    {
      type: 'color',
      disableOpacity: true,
      label: 'Fog low',
      property: 'fogColor',
      group: 'Base Map',
      dependsOn: [
        {
          property: 'terrain',
          value: true,
        },
      ],
    },
    {
      type: 'color',
      disableOpacity: true,
      label: 'Fog high',
      property: 'highColor',
      group: 'Base Map',
      dependsOn: [
        {
          property: 'terrain',
          value: true,
        },
      ],
    },
    {
      type: 'color',
      disableOpacity: true,
      label: 'Sky',
      property: 'spaceColor',
      group: 'Base Map',
      dependsOn: [
        {
          property: 'terrain',
          value: true,
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable',
      property: 'locateControl',
      group: 'Location Control',
    },
    {
      type: 'select',
      label: 'Vertical',
      property: 'locateControlVerticalPosition',
      group: 'Location Control',
      options: [
        { value: 'top', label: 'top' },
        { value: 'bottom', label: 'bottom' },
      ],
      placeHolder: 'top',
    },
    {
      type: 'select',
      label: 'Horizontal',
      property: 'locateControlHorizontalPosition',
      group: 'Location Control',
      options: [
        { value: 'right', label: 'right' },
        { value: 'left', label: 'left' },
      ],
      placeHolder: 'right',
    },
    {
      type: 'switch',
      label: 'Enable',
      property: 'zoomControl',
      group: 'Zoom Control',
    },
    {
      type: 'select',
      label: 'Vertical',
      property: 'zoomControlVerticalPosition',
      group: 'Zoom Control',
      options: [
        { value: 'top', label: 'top' },
        { value: 'bottom', label: 'bottom' },
      ],
      placeHolder: 'top',
    },
    {
      type: 'select',
      label: 'Horizontal',
      property: 'zoomControlHorizontalPosition',
      group: 'Zoom Control',
      options: [
        { value: 'right', label: 'right' },
        { value: 'left', label: 'left' },
      ],
      placeHolder: 'right',
    },
    
  ],
};
