import React from 'react';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { SymbolValueProps } from '../../../../../../configs/types';

export interface SymbolLegendProps {
  categories: SymbolValueProps[];
}

const SymbolLegend = (props: SymbolLegendProps): JSX.Element => {
  const { categories } = props;
  return (
    <div className="symbol-legend">
      {categories.map((category, i) => {
        const { symbol, value } = category;
        return (
          <div key={i} className="symbol-legend--item">
            <img
              src={symbol.src}
              alt={symbol.title}
              style={{
                filter: hexToCSSFilter(symbol.color || '#000000').filter.replace(';', ''),
              }}
            />
            <span>{value}</span>
          </div>
        );
      })}
    </div>
  );
};

export { SymbolLegend };
