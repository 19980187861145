import React, { ReactChild } from 'react';
import { useSelector } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';


export interface MainRegionProps {
    id: string
    children?: ReactChild
}

const MainRegion: React.FC<MainRegionProps> = (props: MainRegionProps) => {
  const { children } = props;
  return (
    <div className="editor-main-region">
        { children }
    </div>
  )
}

export { MainRegion }