import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, TreeSelect, message } from 'antd';
import { Dictionary } from '@onaio/utils';
import {
  buildBulkSelectFragment,
  checkFilters,
  getComponentsBasedOnSource,
  toggleFilters,
} from './helpers/helpers';

/** interface for component props */
export type PropertiesProps = {
  componentIndex: number; // index of the icon component
  item: Dictionary; // id of the icon component
};

/** treeData interface */
export interface TreeDataProps {
  title: JSX.Element;
  value: string;
}

export interface FilterStructure {
  0: string;
  1: string;
  2: string;
  expose: boolean;
  multiple: boolean;
  inherited: boolean;
  disableClear?: boolean;
}

/** stateProps */
export interface StateProps {
  componentsWithMatchingSources: TreeDataProps[];
  filters: FilterStructure[];
  components: Dictionary[];
  componentsWithGlobalFilters: string[];
  hasFilterSet: boolean;
  globalFilterSource: string | undefined;
}
/**
 * Component that renders the properties/(post components) with congruent filter sources
 */
const Properties: React.FC<PropertiesProps> = (props: PropertiesProps) => {
  const dispatch = useDispatch();

  const { componentIndex } = props;

  /**
   * Hook that enables us expose components with matching filter sources, global filters,
   * and components with global filters
   */
  const stateProps: StateProps = useSelector((state) => {
    const { components, sources } = (state as Dictionary).post;
    const component = components[componentIndex];
    const { source, filters, componentsWithGlobalFilters } = component;
    const globalFilterSource = source;
    const hasFilterSet = filters?.length ? checkFilters(filters) : false;
    if (globalFilterSource && hasFilterSet) {
      // get the components that match the global filter source
      const componentsWithMatchingSources = getComponentsBasedOnSource(
        globalFilterSource,
        components,
        sources,
        filters
      ) as TreeDataProps[];
      // get the components that have global filters
      return {
        componentsWithMatchingSources,
        filters,
        components,
        componentsWithGlobalFilters: componentsWithGlobalFilters || [],
        hasFilterSet,
        globalFilterSource,
      };
    }
    return {
      componentsWithMatchingSources: [],
      filters: [],
      components,
      componentsWithGlobalFilters: componentsWithGlobalFilters || [],
      hasFilterSet: false,
      globalFilterSource,
    };
  });

  //   const { property, label } = item;
  const {
    componentsWithMatchingSources,
    filters,
    componentsWithGlobalFilters,
    hasFilterSet,
    globalFilterSource,
  } = stateProps;

  const [selectedValues, setSelectedValues] = useState<string[]>(componentsWithGlobalFilters);

  useMemo(() => {
    setSelectedValues(componentsWithGlobalFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentIndex]);

  const allIds: string[] = componentsWithMatchingSources.map(({ value }) => value);

  if (componentsWithMatchingSources && componentsWithMatchingSources.length !== 0 && hasFilterSet) {
    return (
      <>
        <TreeSelect
          allowClear={true}
          placeholder="Select Components"
          treeCheckable={true}
          showCheckedStrategy={TreeSelect.SHOW_CHILD}
          getPopupContainer={() => document.getElementById('drawer-content') as HTMLElement}
          // dropdownStyle={{ position: 'fixed' }}
          onChange={(ids) => setSelectedValues(ids)}
          value={selectedValues}
          showSearch={true}
          treeNodeFilterProp="title"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          filterTreeNode={(search: string, item: any) => {
            return item?.title?.props?.children?.props?.children?.[2].includes(search);
          }}
          treeData={[
            {
              title: buildBulkSelectFragment(
                selectedValues,
                componentsWithMatchingSources,
                allIds,
                setSelectedValues
              ),
              value: '',
              disableCheckbox: true,
              checkable: false,
              disabled: true,
            },
            ...componentsWithMatchingSources,
          ]}
        />
        <div className="input-field">
          <Button
            htmlType="submit"
            onClick={() => {
              toggleFilters(
                filters,
                componentIndex,
                componentsWithGlobalFilters,
                selectedValues,
                dispatch
              );
              message.success('Filters updated');
            }}
          >
            Apply filters
          </Button>
        </div>
      </>
    );
  } else if (globalFilterSource && !hasFilterSet) {
    return (
      <Alert
        message="Missing filters!"
        description="Please set all filter properties and operators"
        type="info"
      />
    );
  } else {
    return (
      <Alert
        description="Source not set or has no matching dimensions."
        type="warning"
      />
    );
  }
};

export { Properties };
