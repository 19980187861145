import React from "react";
import { Select, Popover, Row, Col } from "antd";
import { Dictionary } from "@onaio/utils/dist/types/types";
import { ColorInput } from "../../../ColorInput";
import { TextInput } from "../../../TextInput";
import { SelectInput } from "../../../SelectInput";
import "../colorStyles.css";

export interface StepsPopoverProps {
  componentIndex: number;
  childIndex?: number;
  childProperty?: string;
  itemIndex?: number;
  item: Dictionary;
  selectValue: string | string[] | undefined;
  componentLevelColors: Dictionary;
}

const StepsPopOver: React.FC<StepsPopoverProps> = (
  props: StepsPopoverProps
) => {
  const {
    componentIndex,
    childIndex,
    itemIndex,
    item,
    selectValue,
    componentLevelColors,
  } = props;
  const stepsOptions = [
    { value: "ckmeans", label: "Jenks Natural Breaks" },
    { value: "linear", label: "Equal Intervals" },
    { value: "quantile", label: "Quantiles" },
  ];
  const selectLabel = stepsOptions.find(
    (option) => option.value === selectValue
  )?.["label"];

  const parentGetter = (
    componentLevelColors: Dictionary,
    firstParentItem: string
  ) => {
    if (componentLevelColors?.colorMethod === "breaks") {
      return [firstParentItem, "colorBreaks"];
    } else if (
      componentLevelColors?.colorMode === "generatedStepsBrakes" &&
      componentLevelColors?.ungroupedData?.length
    ) {
      return [firstParentItem, "ungroupedData"];
    } else {
      return [firstParentItem, "generatedSteps"];
    }
  };

  const popoverListItems = (componentLevelColors: Dictionary): Dictionary[] => {
    if (
      componentLevelColors?.colorMode === "generatedStepsBrakes" &&
      componentLevelColors?.generatedSteps?.length
    ) {
      return componentLevelColors?.generatedSteps;
    } else if (
      componentLevelColors?.colorMethod === "breaks" &&
      componentLevelColors?.colorBreaks?.length
    ) {
      return componentLevelColors?.colorBreaks;
    } else if (
      componentLevelColors?.colorMode === "generatedStepsBrakes" &&
      componentLevelColors?.ungroupedData?.length
    ) {
      return componentLevelColors?.ungroupedData;
    }
    return [];
  };

  const data: Dictionary[] = popoverListItems(componentLevelColors);
  const dataColumns = (data: Dictionary[]) => {
    if (data.length) {
      return (
        <>
          {data.map((dataItem: Dictionary, index: number) => {
            if (data?.[index + 1]?.value) {
              return (
                <>
                  <Row key={index}>
                    {
                      <ColorInput
                        componentIndex={componentIndex}
                        item={{
                          property: "color",
                          parents: parentGetter(
                            componentLevelColors,
                            `${item.parents[0]}`
                          ),
                          size: "small",
                          width: "25%",
                        }}
                        childIndex={itemIndex}
                        itemIndex={index}
                        renderAsColumn={true}
                      />
                    }
                    &nbsp;
                    {
                      <TextInput
                        renderAsColumn={true}
                        componentIndex={componentIndex}
                        item={{
                          property: "value",
                          parents: parentGetter(
                            componentLevelColors,
                            `${item.parents[0]}`
                          ),
                          size: "small",
                          width: "25%",
                        }}
                        childIndex={itemIndex}
                        itemIndex={index}
                      />
                    }
                    &nbsp;
                    {
                      <TextInput
                        componentIndex={componentIndex}
                        item={{
                          property: "value",
                          parents: parentGetter(
                            componentLevelColors,
                            `${item.parents[0]}`
                          ),
                          size: "small",
                          width: "25%",
                        }}
                        childIndex={itemIndex}
                        itemIndex={index + 1}
                        renderAsColumn={true}
                      />
                    }
                    &nbsp;
                  </Row>
                  <br />
                </>
              );
            }
          })}
        </>
      );
    }
    return <></>;
  };

  const content = (
    <>
      <Row>
        <Col span={12}>Method</Col>
        <Col span={12}>
          <SelectInput
            componentIndex={componentIndex}
            item={{ ...item, label: undefined, popOver: false }}
            childIndex={childIndex}
            itemIndex={itemIndex}
            renderAsColumn
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={12}>Class</Col>
        <Col span={12}>
          {
            <SelectInput
              componentIndex={componentIndex}
              item={{
                ...item,
                label: undefined,
                popOver: false,
                property: "classes",
                placeHolder: "Enter classes",
                options: [
                  {
                    value: 3,
                    label: "3",
                  },
                  {
                    value: 4,
                    label: "4",
                  },
                  {
                    value: 5,
                    label: "5",
                  },
                  {
                    value: 6,
                    label: "6",
                  },
                  {
                    value: 7,
                    label: "7",
                  },
                  {
                    value: 8,
                    label: "8",
                  },
                  {
                    value: 9,
                    label: "9",
                  },
                  {
                    value: 10,
                    label: "10",
                  },
                  {
                    value: 11,
                    label: "11",
                  },
                  {
                    value: 12,
                    label: "12",
                  },
                ],
              }}
              childIndex={childIndex}
              itemIndex={itemIndex}
              renderAsColumn={true}
            />
          }
        </Col>
      </Row>
      <br />
      <div className="breaks-breakdown">{dataColumns(data)}</div>
    </>
  );

  return (
    <Popover
      style={{ width: "100%" }}
      placement="left"
      content={content}
      trigger="click"
      title="Set Steps"
      arrow={{ pointAtCenter: false }}
      autoAdjustOverflow={false}
    >
      <Select
        value={selectLabel}
        placeholder={item?.placeHolder}
        notFoundContent={null}
      />
    </Popover>
  );
};

export { StepsPopOver };
