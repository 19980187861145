import { GroupFieldProps } from '../types';
import React from 'react';
import { Row, Col } from 'antd';

export const card: GroupFieldProps = {
  groups: ['Card', 'Grid', 'Pagination', 'Sort', 'Events', 'Filters'],
  styles: ['Width'],
  events: true,
  filters: true,
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Card',
      placeHolder: 'Card Title',
    },
    {
      type: 'text',
      label: 'Caption',
      property: 'caption',
      group: 'Card',
      placeHolder: 'Card Description',
    },
    {
      type: 'component',
      label: 'Parent layout',
      property: 'layout',
      group: 'Parent',
      placeHolder: 'Layout',
      componentType: 'layout',
    },
    {
      type: 'text',
      label: 'Parent region',
      property: 'region',
      group: 'Parent',
      placeHolder: 'Layout',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Card',
      placeHolder: 'Card Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Card',
    },
    {
      type: 'color',
      label: 'Background color',
      property: 'backgroundColor',
      group: 'Style',
    },
    {
      type: 'slider',
      label: 'Padding',
      property: 'padding',
      group: 'Style',
      min: 0,
      max: 40,
      step: 5,
      placeHolder: '0',
    },
    {
      type: 'slider',
      property: 'rowMargin',
      label: 'Margin',
      group: 'Style',
      min: 0,
      placeHolder: '0',
    },
    {
      type: 'checkbox',
      property: 'cardBorder',
      label: 'Show border',
      group: 'Style',
    },
    {
      type: 'checkbox',
      property: 'cardShadow',
      label: 'Show shadow',
      group: 'Style',
    },
    {
      type: 'switch',
      label: 'Enable Pagination',
      property: 'pagination',
      group: 'Pagination',
    },
    {
      type: 'switch',
      label: 'Simple pager',
      property: 'simplePager',
      group: 'Pagination',
    },
    {
      type: 'number',
      label: 'Records',
      placeHolder: '10',
      property: 'defaultPageSize',
      group: 'Pagination',
    },

    /*
    {
      type: 'select',
      label: 'Format',
      property: 'format',
      group: 'Grid',
      placeHolder: 'Grid',
      options: [
        { value: 'grid', label: 'Grid' },
        { value: 'scroll', label: 'Scroll' },
      ],
    },
    */
    {
      type: 'number',
      label: 'Rows',
      property: 'rows',
      min: 1,
      max: 10,
      group: 'Grid',
      dependsOn: [
        {
          property: 'cardLayout',
          value: ['!', 'tabs'],
        },
      ],
      placeHolder: '0',
    },
    {
      type: 'select',
      label: 'Per row',
      property: 'perRow',
      group: 'Grid',
      options: [
        { value: '1', label: '1 card' },
        { value: '2', label: '2 cards' },
        { value: '3', label: '3 cards' },
        { value: '4', label: '4 cards' },
        { value: '6', label: '6 cards' },
        { value: '8', label: '8 cards' },
      ],
      placeHolder: 'Enter Cards Per Row',
    },
    {
      type: 'layout',
      label: '',
      property: 'cardLayout',
      group: 'Grid',
      options: [
        {
          value: '24',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={24}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '12-12',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={12}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={12}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '8-8-8',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '6-6-6-6',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '8-16',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={16}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '16-8',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={16}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={8}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '18-6',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={18}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '6-18',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={6}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={18}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '4-20',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={4}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={20}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
        {
          value: '20-4',
          label: (
            <div className="layout--thumb">
              <Row gutter={5}>
                <Col xs={20}>
                  <div className="thumb-region"></div>
                </Col>
                <Col xs={4}>
                  <div className="thumb-region"></div>
                </Col>
              </Row>
            </div>
          ),
        },
      ],
    },
    {
      type: 'slider',
      property: 'cardGutter',
      label: 'Gutter',
      group: 'Width',
      placeHolder: '0',
    },
    {
      type: 'slider',
      label: 'Min Height',
      property: 'cardHeight',
      min: 0,
      max: 1000,
      step: 5,
      group: 'Width',
      placeHolder: '0',
    },
    {
      type: 'array',
      label: 'Events',
      property: 'events',
      itemLabel: 'Event',
      group: 'Events',
      addButtonText: 'Add Event',
      groups: ['Events'],
      settings: [
        {
          label: 'Events',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      defaultValueType: 'array',
      fields: [
        {
          type: 'select',
          label: 'Trigger',
          property: 'type',
          parents: ['events'],
          placeHolder: 'Select trigger',
          options: [
            { value: 'onCardClick', label: 'Card Click' },
            { value: 'onCardHover', label: 'Card Hover' },
          ],
          group: 'Events',
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['events'],
          placeHolder: 'Select event',
          options: [
            { value: 'updateFilterValue', label: 'Update filter value' },
            { value: 'showLayout', label: 'Show layout' },
            { value: 'hideLayout', label: 'Hide layout' },
            { value: 'flyTo', label: 'Fly to' },
            { value: 'navigateToUrl', label: 'Navigate to URL' },
            { value: 'clearFilterValue', label: 'Clear filter value' },
          ],
          group: 'Events',
        },
        {
          type: 'component',
          label: 'Layout to update',
          property: 'target',
          parents: ['events'],
          placeHolder: 'Select component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'hideLayout' }],
        },
        {
          type: 'text',
          label: 'URL',
          property: 'url',
          parents: ['events'],
          placeHolder: 'https://',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
        },
        {
          type: 'checkbox',
          label: 'Open in new window',
          property: 'blank',
          parents: ['events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
        },
        {
          type: 'dimension',
          label: 'Filter to update',
          property: 'filter',
          parents: ['events'],
          placeHolder: 'Select filter',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'updateFilterValue' }],
        },
        {
          type: 'dimension',
          label: 'Filter to clear',
          property: 'filter',
          parents: ['events'],
          placeHolder: 'Select filter',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'clearFilterValue' }],
        },
        {
          type: 'component',
          label: 'Component to update',
          property: 'target',
          parents: ['events'],
          placeHolder: 'Select component',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'setActiveTab' }],
        },
        {
          type: 'component',
          label: 'Component to update',
          property: 'target',
          parents: ['events'],
          placeHolder: 'Select component',
          componentType: 'layout',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'showLayout' }],
        },
        {
          type: 'component',
          label: 'Component to update',
          property: 'target',
          parents: ['events'],
          placeHolder: 'Select component',
          componentType: 'map',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Zoom to',
          property: 'zoom',
          parents: ['events'],
          min: 1,
          max: 20,
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Latitude',
          property: 'lat',
          parents: ['events'],
          placeHolder: '1',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Longitude',
          property: 'lng',
          parents: ['events'],
          placeHolder: '1',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'select',
          label: 'Select tab',
          property: 'tab',
          parents: ['events'],
          placeHolder: 'Select tab',
          group: 'Events',
          options: [
            { value: '1', label: 'Tab 1' },
            { value: '2', label: 'Tab 2' },
            { value: '3', label: 'Tab 3' },
          ],
          dependsOn: [{ property: 'target', value: 'layout' }],
        },
      ],
    },
    {
      type: 'array',
      label: 'Properties',
      property: 'properties',
      tooltip: 'This is some help',
      group: 'Card',
      groups: ['Card item'],
      dependsOn: [{ property: 'source', value: '*' }],
      settings: [
        {
          label: 'Card item',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      itemLabel: 'Property',
      collapseProperty: 'value',
      collapseParent: 'properties',
      collapse: false,
      defaultValue: {
        value: '',
        type: '',
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Card item',
          property: 'value',
          showMeasures: true,
          showDimensions: true,
          parents: ['properties'],
          setType: true,
          typeKey: 'type',
          placeHolder: 'Fields to be inherited',
          setDataType: true,
          dataTypeKey: 'dataType',
          allowGranularity: true,
        },
        {
          type: 'select',
          label: 'Granularity',
          property: 'granularity',
          parents: ['properties'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'second', label: 'Second' },
            { value: 'minute', label: 'Minute' },
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' },
          ],
          group: 'Card item',
          placeHolder: 'Select time granularity option',
        },
      ],
    },
    {
      type: 'array',
      label: 'Highlight',
      property: 'highlights',
      group: 'Highlights',
      groups: ['Highlight'],
      settings: [
        {
          label: 'Highlight',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      collapse: true,
      itemLabel: 'Highlight',
      collapseParent: 'highlights',
      defaultValueType: 'array',
      defaultValue: {
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Highlight',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['highlights'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Highlight',
          property: '0',
          parents: ['highlights'],
          options: [{ value: '==', label: 'equal to' }],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Highlight',
          property: '2',
          parents: ['highlights'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
          ],
          placeHolder: 'Set value to highlight',
        },
      ],
    },
    {
      type: 'array',
      label: '',
      property: 'filters',
      group: 'Filters',
      groups: ['Filter'],
      addButtonText: 'Add Filter',
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      collapse: false,
      itemLabel: 'Filter',
      collapseProperty: '1',
      collapseParent: 'filters',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        inherit: false,
      },
      fields: [
        {
          type: 'dimension',
          label: 'Property',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate', '<=', '>='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Default value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          group: 'Options',
          label: 'Inherit filter value',
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          property: 'expose',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Options',
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Options',
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<'],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'text',
          label: 'Name',
          group: 'Options',
          property: 'name',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Filter Name',
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Options',
          property: 'cols',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable cascading filters',
      property: 'cascade',
      group: 'Filters',
    },
    {
      type: 'dimension',
      label: 'Sort Field',
      property: 'sortField',
      showMeasures: true,
      showDimensions: true,
      group: 'Sort',
      placeHolder: 'Set value to Sort by',
    },
    {
      type: 'select',
      label: 'Sort order',
      property: 'sortOrder',
      options: [
        { value: 'desc', label: 'Descending' },
        { value: 'asc', label: 'Ascending' },
      ],
      group: 'Sort',
      placeHolder: 'Set Order to Sort by',
    },
  ],
};
