import { message } from 'antd';
import { Dispatch } from 'redux';
import { ASSETS_BUCKET_NAME } from '../../../../../../../configs/env';
import { actionComponentImageUrlEdit } from '../../../../../Image/actions';
import { actionPostImageUrlEdit } from '../../../../../Title/actions';
import { actionPostComponentSettingEdit } from '../../../../../actions';

export interface StateValues {
  setCurrentFile: (arg: string | Blob) => void;
  setIsUploading: (arg: boolean) => void;
}

export const readFileAsDataURL = async (file: Blob): Promise<string | ArrayBuffer> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (reader.result) {
        resolve(reader.result);
      }
    });
    reader.readAsDataURL(file);
  });
};

export const uploadDone = (
  imageId: string | undefined,
  dispatch: Dispatch,
  property: string,
  componentIndex: number,
  itemIndex: number | undefined,
  childIndex: number | undefined,
  parents: string[],
  entity: string | undefined,
  stateValues: StateValues
): void => {
  const { setCurrentFile, setIsUploading } = stateValues;
  if (entity === 'post') {
    dispatch(
      actionPostImageUrlEdit({
        value: `https://${ASSETS_BUCKET_NAME}/${imageId}`,
      })
    );
  } else {
    dispatch(
      actionPostComponentSettingEdit({
        parents: parents,
        property: property,
        componentIndex: componentIndex,
        itemIndex: childIndex,
        value: `https://${ASSETS_BUCKET_NAME}/${imageId}`,
      })
    );
  }
  setCurrentFile('');
  setIsUploading(false);
  message.success('Image uploaded successfully');
};

export const doFileRead = async (
  file: Blob,
  setImgUrl: (arg: string | undefined) => void
): Promise<void> => {
  const result = (await readFileAsDataURL(file)) as string | undefined;
  setImgUrl(result);
};
