import React, { useEffect, useState } from 'react';
import { Button, Dropdown, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils';
import type { MenuProps } from 'antd';
import { useHistory } from 'react-router-dom';
import { actionUserLogout, actionUserGet } from '../actions';
import { AkukoAPIService } from '../../../services/serviceClass';
import { IDENTITY_API } from '../../../configs/env';
import { EmbedSettings } from '../../Post/Embed/EmbedSettings';
import { ERROR_GENERIC } from '../../../configs/constants';
import { useAuth } from "react-oidc-context";
import './style.css';
import { LoadingOutlined } from '@ant-design/icons';

const UserDropdown = (): JSX.Element => {
  const user = useSelector((store: Dictionary) => store.user);
  const history = useHistory<Dictionary>();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { signoutRedirect, signinRedirect, isAuthenticated, user: authUser, isLoading } = useAuth();
  const handleLogout = () => {
    void signoutRedirect();
    dispatch(actionUserLogout());
  };

  useEffect(() => {
    if (isAuthenticated && authUser && (user === undefined || user?.uuid === undefined)) {
      const service = new AkukoAPIService(IDENTITY_API, `user/profile`);
      service.read(authUser?.profile?.sub).then((res) => {
        dispatch(actionUserGet(res));
      })
      .catch((error) => {
        message.error(error?.message || ERROR_GENERIC);
      });
    }
  }, [isAuthenticated, user, authUser, isLoading]);

  const defaultItems: MenuProps['items'] = [
    {
      label: (
        <a
          href="#dashboard"
          onClick={(e) => {
            e.preventDefault();
              history.push(`/dashboard/settings`);
            
          }}
        >
          My Account
        </a>
      ),
      key: 'dashboard',
    },
    { type: 'divider' },
    {
      label: (
        <a
          href="#sign-out"
          onClick={handleLogout}
        >
          Sign Out
        </a>
      ),
      key: 'sign-out',
    },
  ];
  if (authUser !== null && authUser?.profile?.email) {
    return (
      <>
        <Dropdown menu={{ items: defaultItems }} overlayClassName={'user-dropdown'}>
          <span className="user-icon">
            <span className="user-icon-name">{authUser?.profile?.email.charAt(0).toUpperCase()}</span>
          </span>
        </Dropdown>
        <EmbedSettings
          onCancel={() => {
            setModalVisible(false);
          }}
          showModal={modalVisible}
        />
      </>
    );
  } else if (isAuthenticated === false && isLoading == false && authUser === null) {
    return (
      <>
        <Button
          className="btn-user-sign-in"
          onClick={() => void signinRedirect()}
        >
          Sign In
        </Button>
        <Button
          type="primary"
          className="btn-user-sign-up"
          onClick={() => void signinRedirect()}
        >
          Sign Up
        </Button>
      </>
    );
  } else {
    return (
      <span className="user-icon-loading">
        <LoadingOutlined style={{ color: '#ccc', fontSize: 30 }} />
      </span>
    )
  }
};

export { UserDropdown };
