import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapComponentContainer from '../../Map';
import { actionPostComponentSettingEdit } from '../../actions';
import { ColsOne } from '../../Layout/components/cols-1';
import { ColsTwo } from '../../Layout/components/cols-2';
import { ColsThree } from '../../Layout/components/cols-3';
import { ColsFour } from '../../Layout/components/cols-4';
import { ColsFive } from '../../Layout/components/cols-5';
import { ColsSix } from '../../Layout/components/cols-6';
import { ColsSeven } from '../../Layout/components/cols-7';
import { ColsEight } from '../../Layout/components/cols-8';
import { ColsNine } from '../../Layout/components/cols-9';
import { ColsTen } from '../../Layout/components/cols-10';
import { TabsLayout } from '../../Layout/components/tabs';
import './style.css';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import {
  actionConfigComponentIndexActive,
  actionConfigActiveLayerEdit,
  actionConfigActiveColumnEdit,
} from '../../actions';
import { CollapseLayout } from '../../Layout/components/collapse';
import { ModalLayout } from '../../Layout/components/modal';
import { DrawerLayout } from '../../Layout/components/drawer';

const MapTemplate = (props) => {
  const dispatch = useDispatch();
  const post = useSelector((store) => store.post);
  const user = useSelector((store) => store.user);
  const [loading, setLoading] = useState(false);
  const [mapIndex, setMapIndex] = useState();
  const { embedComponents, embed } = props;

  const addActiveClass = (id) => {
    const el = document.getElementById(`component-tree-${id}`);
    if (el) {
      el?.classList.add('active');
    }
  };

  const removeActiveClass = (id) => {
    const el = document.getElementById(`component-tree-${id}`);
    if (el) {
      el?.classList.remove('active');
    }
  };

  const setComponentActive = (e, index) => {
    e.stopPropagation();
    dispatch(
      actionConfigComponentIndexActive({
        value: index,
      })
    );
    dispatch(
      actionConfigActiveLayerEdit({
        value: -1,
      })
    );
    dispatch(
      actionConfigActiveColumnEdit({
        value: -1,
      })
    );
  };

  useEffect(() => {
    post.components.find((item, index) => {
      if (item.type === 'map') {
        setMapIndex(index);
        return true;
      }
    });
  }, [post.components]);

  const getMapLayoutClasses = (layout) => {
    let classes = 'map-template-layout';
    if (layout.showDisplayToggle) {
      classes += ' collapse-enabled';
    }
    if (layout.hideOnMobile) {
      classes += ' hide-on-mobile';
    }
    return classes;
  };

  const handleGutter = (item) => {
    let gutter = 15;
    if (item?.gutter >= 0) {
      gutter = item.gutter;
    }
    return gutter;
  };

  const getHeight = (item) => {
    if (item.layoutId === 'collapse') {
      return 'auto';
    }
    if (item.heightMetric === 'px') {
      return `${item.pixelHeight}px`;
    }
    if (item.heightMetric === '%') {
      return `${item.percentHeight}%`;
    }
    return 'auto';
  };

  const getWidth = (item) => {
    if (item.widthMetric === 'px') {
      return `${item.pixelWidth}px`;
    }
    if (item.widthMetric === '%') {
      return `${item.percentWidth}%`;
    }
    return 'auto';
  };

  if (mapIndex >= 0) {
    return (
      <div className="map-template">
        {post.components[mapIndex].hidden !== true && <MapComponentContainer index={mapIndex} />}
        <div className="map-template-layouts">
          {post.components?.map(
            (item, index) =>
              item.type === 'layout' &&
              item.hidden !== true &&
              !item.region &&
              (embed ? embedComponents.includes(item.id) : true) &&
              item.name !== 'Map wrapper layout' && (
                <div
                  key={index}
                  onMouseOver={() => addActiveClass(item.id)}
                  onMouseOut={() => removeActiveClass(item.id)}
                  onClick={(e) => setComponentActive(e, index)}
                  id={`component-${item.id}`}
                  className={getMapLayoutClasses(item)}
                  style={{
                    position: 'absolute',
                    background: item.layoutBackgroundColor || 'transparent',
                    left: item.positionHorizontal === 'left' ? `${item.horizontalValue}px` : 'auto',
                    right:
                      item.positionHorizontal === 'right' ? `${item.horizontalValue}px` : 'auto',
                    top: item.positionVertical === 'top' ? `${item.verticalValue}px` : 'auto',
                    bottom: item.positionVertical === 'bottom' ? `${item.verticalValue}px` : 'auto',
                    width: getWidth(item),
                    height: item.collapsed ? '40px' : getHeight(item),
                    boxShadow: item?.layoutShadow ? `0 1px 7px rgb(0 0 0 / 20%)` : 'none',
                    overflowY: item.yScroll ? 'auto' : 'hidden',
                    overflowX: item.xScroll ? 'auto' : 'hidden',
                    borderRadius: `${item.borderRadius}px`,
                  }}
                >
                  {item.title && (
                    <div
                      className="map-template-layouts-header"
                      style={{
                        paddingTop: item.paddingTop || 0,
                        paddingRight: item.paddingRight || 0,
                        paddingLeft: item.paddingLeft || 0,
                        paddingBottom: item.paddingBottom || 0,
                        background: item.headerBackgroundColor || 'transparent',
                      }}
                    >
                      {item.collapsed !== true && (
                        <h2 style={{ color: item.titleColor ? item.titleColor : '#111' }}>
                          {item.title}
                        </h2>
                      )}
                    </div>
                  )}
                  {item.allowClose && (
                    <div className="map-template-layout-close">
                      <CloseOutlined
                        style={{ color: item.titleColor ? item.titleColor : '#111' }}
                        onClick={() => {
                          dispatch(
                            actionPostComponentSettingEdit({
                              componentIndex: index,
                              property: 'hidden',
                              value: item.hidden ? false : true,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                  {item.showDisplayToggle && item.collapsed === false && (
                    <div className="map-template-layout-close">
                      <MinusOutlined
                        style={{ color: item.titleColor ? item.titleColor : '#111' }}
                        onClick={() => {
                          dispatch(
                            actionPostComponentSettingEdit({
                              componentIndex: index,
                              property: 'collapsed',
                              value: true,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                  {item.showDisplayToggle && item.collapsed === true && (
                    <div className="map-template-layout-close">
                      <PlusOutlined
                        style={{ color: item.titleColor ? item.titleColor : '#111' }}
                        onClick={() => {
                          dispatch(
                            actionPostComponentSettingEdit({
                              componentIndex: index,
                              property: 'collapsed',
                              value: false,
                            })
                          );
                        }}
                      />
                    </div>
                  )}
                  {item.collapsed !== true && (
                    <div
                      style={{
                        paddingTop: item.paddingTop || 0,
                        paddingRight: item.paddingRight || 0,
                        paddingLeft: item.paddingLeft || 0,
                        paddingBottom: item.paddingBottom || 0,
                      }}
                    >
                      {item.layoutId === '24' && (
                        <ColsOne
                          post={post}
                          user={user}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '12-12' && (
                        <ColsTwo
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '8-8-8' && (
                        <ColsThree
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '6-6-6-6' && (
                        <ColsFour
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '16-8' && (
                        <ColsFive
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '8-16' && (
                        <ColsSix
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '6-18' && (
                        <ColsSeven
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '18-6' && (
                        <ColsEight
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '4-20' && (
                        <ColsNine
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === '20-4' && (
                        <ColsTen
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === 'tabs' && (
                        <TabsLayout
                          post={post}
                          user={user}
                          grid={item.mobileGrid || 'sm'}
                          componentIndex={index}
                          gutter={handleGutter(item)}
                          {...item}
                        />
                      )}
                      {item.layoutId === 'collapse' && (
                        <CollapseLayout post={post} componentIndex={index} user={user} {...item} />
                      )}
                      {item.layoutId === 'modal' && (
                        <ModalLayout post={post} componentIndex={index} user={user} {...item} />
                      )}
                      {item.layoutId === 'drawer' && (
                        <DrawerLayout post={post} componentIndex={index} user={user} {...item} />
                      )}
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export { MapTemplate };
