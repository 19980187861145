export const getIcons = (baseURL = 'https://assets.akuko.io/gggi/') => {
  return [
    {
      id: 'adaptation',
      src: `${baseURL}adaptation.svg`,
      title: 'Adaptation',
    },
    {
      id: 'beneficiaries',
      src: `${baseURL}beneficiaries.svg`,
      title: 'Beneficiaries',
    },
    {
      id: 'boldness',
      src: `${baseURL}boldness.svg`,
      title: 'Boldness',
    },
    {
      id: 'capacity-building',
      src: `${baseURL}capacity-building.svg`,
      title: 'Capacity building',
    },
    {
      id: 'carbon-pricing',
      src: `${baseURL}carbon-pricing.svg`,
      title: 'Carbon pricing',
    },
    {
      id: 'carbon-pricing-unit',
      src: `${baseURL}carbon-pricing-unit.svg`,
      title: 'Carbon pricing unit',
    },
    {
      id: 'circular-economy',
      src: `${baseURL}circular-economy.svg`,
      title: 'Circular economy',
    },
    {
      id: 'climate-action-and-inclusive-development',
      src: `${baseURL}climate-action-and-inclusive-development.svg`,
      title: 'Climate action and inclusive development',
    },
    {
      id: 'climate-action',
      src: `${baseURL}climate-action.svg`,
      title: 'Climate action',
    },
    {
      id: 'climate-change-adaptation-service',
      src: `${baseURL}climate-change-adaptation-service.svg`,
      title: 'Climate change adaptation service',
    },
    {
      id: 'climate-change',
      src: `${baseURL}climate-change.svg`,
      title: 'Climate change',
    },
    {
      id: 'climate-resilient-agriculture',
      src: `${baseURL}climate-resilient-agriculture.svg`,
      title: 'Climate resilient agriculture',
    },
    {
      id: 'coastal-resilience-mangroves-aquaculture',
      src: `${baseURL}coastal-resilience-mangroves-aquaculture.svg`,
      title: 'Coastal resilience mangroves aquaculture',
    },
    {
      id: 'donors',
      src: `${baseURL}donors.svg`,
      title: 'Donors',
    },
    {
      id: 'ecosystem',
      src: `${baseURL}ecosystem.svg`,
      title: 'Ecosystem',
    },
    {
      id: 'efficient-and-sustainable-resource-use',
      src: `${baseURL}efficient-and-sustainable-resource-use.svg`,
      title: 'Efficient and sustainable resource use',
    },
    {
      id: 'e-mobility',
      src: `${baseURL}e-mobility.svg`,
      title: 'E mobility',
    },
    {
      id: 'energy-efficiency',
      src: `${baseURL}energy-efficiency.svg`,
      title: 'Energy efficiency',
    },
    {
      id: 'energy',
      src: `${baseURL}energy.svg`,
      title: 'Energy',
    },
    {
      id: 'excellence',
      src: `${baseURL}excellence.svg`,
      title: 'Excellence',
    },
    {
      id: 'fecal-sludge-management',
      src: `${baseURL}fecal-sludge-management.svg`,
      title: 'Fecal sludge management',
    },
    {
      id: 'gender-equality',
      src: `${baseURL}gender-equality.svg`,
      title: 'Gender equality',
    },
    {
      id: 'gggi-composite-core-values',
      src: `${baseURL}gggi-composite-core-values.svg`,
      title: 'GGGI composite core values',
    },
    {
      id: 'gggi-core-values',
      src: `${baseURL}gggi-core-values.svg`,
      title: 'GGGI core values',
    },
    {
      id: 'gggi_mission',
      src: `${baseURL}gggi_mission.svg`,
      title: 'GGGI-mission',
    },
    {
      id: 'gggi_vision',
      src: `${baseURL}gggi_vision.svg`,
      title: 'GGGI-vision',
    },
    {
      id: 'ghg_emission_reduction',
      src: `${baseURL}ghg_emission_reduction.svg`,
      title: 'GHG-emission-reduction',
    },
    {
      id: 'government',
      src: `${baseURL}government.svg`,
      title: 'Government',
    },
    {
      id: 'green-bonds',
      src: `${baseURL}green-bonds.svg`,
      title: 'Green bonds',
    },
    {
      id: 'green-buildings',
      src: `${baseURL}green-buildings.svg`,
      title: 'Green buildings',
    },
    {
      id: 'green-economy-circular-economy',
      src: `${baseURL}green-economy-circular-economy.svg`,
      title: 'Green economy circular economy',
    },
    {
      id: 'green-entrepreneurship',
      src: `${baseURL}green-entrepreneurship.svg`,
      title: 'Green entrepreneurship',
    },
    {
      id: 'green-growth-index',
      src: `${baseURL}green-growth-index.svg`,
      title: 'Green growth index',
    },
    {
      id: 'green-growth-solution',
      src: `${baseURL}green-growth-solution.svg`,
      title: 'Green growth solution',
    },
    {
      id: 'green-growth',
      src: `${baseURL}green-growth.svg`,
      title: 'Green growth',
    },
    {
      id: 'green-investment-services',
      src: `${baseURL}green-investment-services.svg`,
      title: 'Green investment services',
    },
    {
      id: 'green-investment',
      src: `${baseURL}green-investment.svg`,
      title: 'Green investment',
    },
    {
      id: 'green-jobs',
      src: `${baseURL}green-jobs.svg`,
      title: 'Green jobs',
    },
    {
      id: 'greenpreneurs',
      src: `${baseURL}greenpreneurs.svg`,
      title: 'Greenpreneurs',
    },
    {
      id: 'improved-air-quality',
      src: `${baseURL}improved-air-quality.svg`,
      title: 'Improved air quality',
    },
    {
      id: 'inclusive',
      src: `${baseURL}inclusive.svg`,
      title: 'Inclusive',
    },
    {
      id: 'integrity',
      src: `${baseURL}integrity.svg`,
      title: 'Integrity',
    },
    {
      id: 'knowledge-products',
      src: `${baseURL}knowledge-products.svg`,
      title: 'Knowledge products',
    },
    {
      id: 'knowledge-sharing',
      src: `${baseURL}knowledge-sharing.svg`,
      title: 'Knowledge sharing',
    },
    {
      id: 'long-term-low-emission-development-strategy',
      src: `${baseURL}long-term-low-emission-development-strategy.svg`,
      title: 'Long term low emission development strategy',
    },
    {
      id: 'loss-and-damage',
      src: `${baseURL}loss-and-damage.svg`,
      title: 'Loss and damage',
    },
    {
      id: 'member-states',
      src: `${baseURL}member-states.svg`,
      title: 'Member states',
    },
    {
      id: 'mrv-measurement-reporting-and-verification',
      src: `${baseURL}mrv-measurement-reporting-and-verification.svg`,
      title: '(MRV) measurement reporting and verification',
    },
    {
      id: 'nationally-determined-contributions(ndc)',
      src: `${baseURL}nationally-determined-contributions(ndc).svg`,
      title: '(NDC) nationally determined contributions',
    },
    {
      id: 'national',
      src: `${baseURL}national.svg`,
      title: 'National',
    },
    {
      id: 'natural-capital',
      src: `${baseURL}natural-capital.svg`,
      title: 'Natural capital',
    },
    {
      id: 'nature-based-solutions',
      src: `${baseURL}nature-based-solutions.svg`,
      title: 'Nature based solutions',
    },
    {
      id: 'operation-partners',
      src: `${baseURL}operation-partners.svg`,
      title: 'Operation partners',
    },
    {
      id: 'outcome',
      src: `${baseURL}outcome.svg`,
      title: 'Outcome',
    },
    {
      id: 'participants-audience',
      src: `${baseURL}participants-audience.svg`,
      title: 'Participants audience',
    },
    {
      id: 'partners',
      src: `${baseURL}partners.svg`,
      title: 'Partners',
    },
    {
      id: 'policy',
      src: `${baseURL}policy.svg`,
      title: 'Policy',
    },
    {
      id: 'poverty-reduction',
      src: `${baseURL}poverty-reduction.svg`,
      title: 'Poverty reduction',
    },
    {
      id: 'private-and-public-partnership',
      src: `${baseURL}private-and-public-partnership.svg`,
      title: 'Private and public partnership',
    },
    {
      id: 'private-sector',
      src: `${baseURL}private-sector.svg`,
      title: 'Private sector',
    },
    {
      id: 'public',
      src: `${baseURL}public.svg`,
      title: 'Public',
    },
    {
      id: 'renewable-energy',
      src: `${baseURL}renewable-energy.svg`,
      title: 'Renewable energy',
    },
    {
      id: 'research',
      src: `${baseURL}research.svg`,
      title: 'Research',
    },
    {
      id: 'social-inclusion',
      src: `${baseURL}social-inclusion.svg`,
      title: 'Social inclusion',
    },
    {
      id: 'solar-irrigation',
      src: `${baseURL}solar-irrigation.svg`,
      title: 'Solar irrigation',
    },
    {
      id: 'solar',
      src: `${baseURL}solar.svg`,
      title: 'Solar',
    },
    {
      id: 'study-feasibility',
      src: `${baseURL}study-feasibility.svg`,
      title: 'Study feasibility',
    },
    {
      id: 'sub-national',
      src: `${baseURL}sub-national.svg`,
      title: 'Sub national',
    },
    {
      id: 'sun',
      src: `${baseURL}sun.svg`,
      title: 'Sun',
    },
    {
      id: 'sustainable-energy',
      src: `${baseURL}sustainable-energy.svg`,
      title: 'Sustainable energy',
    },
    {
      id: 'sustainable-forest',
      src: `${baseURL}sustainable-forest.svg`,
      title: 'Sustainable forest',
    },
    {
      id: 'sustainable-landscapes',
      src: `${baseURL}sustainable-landscapes.svg`,
      title: 'Sustainable landscapes',
    },
    {
      id: 'sustainable-services',
      src: `${baseURL}sustainable-services.svg`,
      title: 'Sustainable services',
    },
    {
      id: 'sustainable-transport',
      src: `${baseURL}sustainable-transport.svg`,
      title: 'Sustainable transport',
    },
    {
      id: 'sustainable-waste-management',
      src: `${baseURL}sustainable-waste-management.svg`,
      title: 'Sustainable waste management',
    },
    {
      id: 'target',
      src: `${baseURL}target.svg`,
      title: 'Target',
    },
    {
      id: 'transformational',
      src: `${baseURL}transformational.svg`,
      title: 'Transformational',
    },
    {
      id: 'transition',
      src: `${baseURL}transition.svg`,
      title: 'Transition',
    },
    {
      id: 'tree',
      src: `${baseURL}tree.svg`,
      title: 'Tree',
    },
    {
      id: 'waste-management',
      src: `${baseURL}waste-management.svg`,
      title: 'Waste management',
    },
    {
      id: 'waste',
      src: `${baseURL}waste.svg`,
      title: 'Waste',
    },
    {
      id: 'webinars',
      src: `${baseURL}webinars.svg`,
      title: 'Webinars',
    },
    {
      id: 'wind',
      src: `${baseURL}wind.svg`,
      title: 'Wind',
    },
    {
      id: 'workshop',
      src: `${baseURL}workshop.svg`,
      title: 'Workshop',
    },
  ];
};
