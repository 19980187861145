import React from 'react';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import { useEffect } from 'react';
import { useState } from 'react';

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

export interface TextEditorProps {
  value?: string; // value to initialize
  height?: number; // height of the text editor
  onChange: (text: string) => void; // handler to handle text change
}

const defaultProps: Partial<TextEditorProps> = {
  height: 100,
};

const TextEditor: React.FC<TextEditorProps> = (props: TextEditorProps) => {
  const { value, height, onChange } = props;
  const [text, setText] = useState<string | undefined>(value);


  return (
    <MdEditor
      style={{ height: `${height}px` }}
      value={value}
      config={{
        view: {
          menu: false,
          md: true,
          html: false,
          fullScreen: false,
        },
      }}
      onChange={({ text }) => {
        onChange(text);
      }}
      renderHTML={(text) => mdParser.render(text)}
    />
  );
};

TextEditor.defaultProps = defaultProps;

export { TextEditor };
