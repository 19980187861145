import { Dictionary } from '@onaio/utils';
import { Dispatch } from 'redux';
import { actionPostComponentSettingEdit } from '../../../../../actions';

export const handleUpdateStore = (
  color: string | undefined,
  classes: string | number | null,
  dispatch: Dispatch,
  configs: Dictionary
): void => {
  const {
    parents,
    property,
    objectKey,
    componentIndex,
    childIndex,
    itemIndex,
  } = configs;
  dispatch(
    actionPostComponentSettingEdit({
      parents: parents,
      property: property,
      objectKey: objectKey,
      componentIndex: componentIndex,
      childIndex: childIndex,
      itemIndex: itemIndex,
      value: color,
    })
  );
  dispatch(
    actionPostComponentSettingEdit({
      parents: parents,
      property: 'classes',
      objectKey: objectKey,
      componentIndex: componentIndex,
      childIndex: childIndex,
      itemIndex: itemIndex,
      value: classes,
    })
  );
};
