import { combineReducers } from 'redux';
import post from './reducerPost.js';
import posts from './reducerPosts.js';
import source from './reducerSource.js';
import sources from './reducerSources.js';
import account from './reducerAccount.js';
import space from './reducerSpace.js';
import config from './reducerConfig.js';
import user from './reducerUser.js';
import users from './reducerUsers.js';
import profile from './reducerProfile.js';

export default combineReducers({
  post,
  posts,
  source,
  sources,
  account,
  config,
  space,
  profile,
  users,
  user
});
