import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import { actionSourceEdit } from '../actions';
import { Dictionary } from '@onaio/utils';
const { Option } = Select;

const ParquetSourceInputType: React.FC = () => {
  const source = useSelector((store: Dictionary) => store.source);
  const dispatch = useDispatch();

  const isVisible = () => {
    if (source.dimensions && source.dimensions.length > 0) {
      return false;
    }
    if (source.type === 'parquet') {
      return true;
    }
    return false;
  };

  if (isVisible()) {
    return (
      <div className="input-field">
        <label>Input type</label>
        <Select
          placeholder="Create parquet source from AWS S3 or file"
          onChange={(value) => {
            dispatch(
              actionSourceEdit({
                inputType: value,
              })
            );
          }}
        >
          <Option value="aws_s3">AWS S3</Option>
          <Option value="file">Upload file</Option>
        </Select>
      </div>
    );
  } else {
    return null;
  }
};

export { ParquetSourceInputType };
