import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GenericComponent } from '../Component';
import { PictureOutlined, LoadingOutlined } from '@ant-design/icons';
import { useFetchComponentData } from '../hooks';
import { actionComponentEventAdd } from '../actions';
import { getContextRow } from '../helpers';
import { renderImage } from './helpers';
import './style.css';

const Image = (props) => {
  const { componentIndex, dataRow } = props;

  const post = useSelector((state) => state.post);
  const component = post.components[componentIndex];
  const componentId = component?.id;
  const region = component?.region;
  const dispatch = useDispatch();

  const genericComponentProps = {
    componentIndex,
    componentId,
    drawerTitle: 'Image',
  };

  const [isLoading, data] = useFetchComponentData(componentId);

  const { filteredData, layoutIncomponent } = getContextRow(
    dataRow,
    data,
    component,
    true
  );

  const handleEvents = (e, btn) => {
    dispatch(
      actionComponentEventAdd({
        type: 'onButtonClick',
        event: {
          target: {
            checked: e?.target?.checked,
            key: e.key, // horizontal menu key
            target: e?.target?.outerHTML,
          },
        },
        data: filteredData[0],
        componentId: btn?.id,
        history: history,
      })
    );
  };

  return (
    <GenericComponent {...genericComponentProps}>
      <div>
        {isLoading && (
          <div className='component-loader'>
            <LoadingOutlined />
          </div>
        )}
        {renderImage(component, props, region, handleEvents, data)}
        {!component?.imageUrl && !component.dimensionImageUrl && (
          <div className='component-empty'>
            <PictureOutlined />
          </div>
        )}
      </div>
    </GenericComponent>
  );
};

export { Image };
