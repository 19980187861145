/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Col, Button, Alert, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Dictionary } from '@onaio/utils';

import { actionSourceSave } from '../../actions';
import { TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL } from '../../../../configs/env';
import { SUBMIT_BUTTON_TEXTS } from '../constants';
import { updateSourceCubeSchema } from '../../helpers/helpers';
import { defaultProgressStatusTypesForAsyncTasks } from '../../../../configs/component-types';
import { triggerAsycSourceOperation } from '../SourceWebsocketsUpdatesHelpers';

const alertMessageStyle = {
  marginTop: '1em',
};

export interface CubeSchemaFormProps {
  sourceSaveActionCreator?: (obj: Dictionary) => void;
}

interface InitialValues {
  schema: string;
}

/** default component props */
const defaultProps = {
  sourceSaveActionCreator: actionSourceSave,
};

const CubeSchemaForm: React.FC<CubeSchemaFormProps> = (props: CubeSchemaFormProps) => {
  const { sourceSaveActionCreator } = props;
  const dispatch = useDispatch();
  const source = useSelector((store: Dictionary) => store.source);
  const [isSubmitting, setSubmitting] = useState(false);
  const validationSchema = Yup.object().shape({
    schema: Yup.string().required('Source cube schema can not be empty'),
  });
  const schema: Dictionary = source?.schema ? { ...source?.schema } : {};
  const [progress, setProgress] = useState<
      | { stage: defaultProgressStatusTypesForAsyncTasks; data: Dictionary[]; errors: Dictionary[] }
      | Dictionary
    >({});
  const asyncTaskName = 'Source cube schema update';

  useEffect(() => {
    if (progress?.stage === defaultProgressStatusTypesForAsyncTasks.DONE) {
      if (progress?.data[0]?.source) {
        if (sourceSaveActionCreator) {
          dispatch(
            /* @ts-ignore */
            sourceSaveActionCreator({
              ...progress?.data[0]?.source,
            })
          );
        }
      }
      setSubmitting(false);
      setProgress({});
      message.success('Schema updated successfully');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  if (Object.keys(schema).length !== 0) {
    if (schema?.sql) {
      delete schema.sql;
    }
    if (schema?.sql_table) {
      delete schema.sql_table;
    }
    if (schema?.refreshKey) {
      delete schema.refreshKey;
    }
  }

  const formInitialValues: InitialValues = {
    schema:
      Object.keys(schema).length !== 0
        ? JSON.stringify(schema, undefined, 2)
        : JSON.stringify(schema),
  };

  return (
    <Formik
      key={JSON.stringify(formInitialValues.schema)}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        try {
          setSubmitting(true);
          let schema: Dictionary = {
            ...JSON.parse(values?.schema),
          };
          // Replace measures with an empty array when it's just an empty object inside
          if (
            schema?.measures &&
            schema?.measures.length === 1 &&
            Object.keys(schema?.measures[0]).length === 0
          ) {
            schema = { ...schema, measures: [] };
          }

          if (source?.schema?.sql) {
            schema.sql = source?.schema?.sql;
          }
          if (source?.schema?.sql_table) {
            schema.sql_table = source?.schema?.sql_table;
          }
          if (source?.schema?.refreshKey) {
            schema.refreshKey = source?.schema?.refreshKey;
          }

          triggerAsycSourceOperation({
            asynFunc: updateSourceCubeSchema,
            asynFuncArgs: {
              sourceId: source?.uuid,
              schema: schema,
              cubeName: source?.cube,
            },
            asyncTaskName: asyncTaskName,
            setAsyncTaskProgressData: setProgress,
            setLoading: setSubmitting,
            webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
          });
        } catch (error) {
          message.error('Incorrect JSON format');
          setSubmitting(false);
          setProgress({});
        }
      }}
    >
      {({ handleSubmit, handleChange }) => (
        <Form onSubmit={handleSubmit}>
          <Row gutter={10}>
            <Col xs={24}>
              <div className="input-field cube-input">
                <Field name="schema">
                  {({ field, meta }: Dictionary) => (
                    <div>
                      <textarea
                        {...field}
                        className="ant-input"
                        rows={10}
                        onChange={handleChange}
                        disabled={source.public === true || isSubmitting}
                      />
                      {meta.error && (
                        <Alert
                          style={alertMessageStyle}
                          message={meta.error}
                          type="error"
                          showIcon
                        />
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </Col>
          </Row>
          <br />
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              source.public === true || isSubmitting
            }
          >
            {isSubmitting ? SUBMIT_BUTTON_TEXTS.saving : SUBMIT_BUTTON_TEXTS.default}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

CubeSchemaForm.defaultProps = defaultProps;

export { CubeSchemaForm };
