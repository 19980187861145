import produce from 'immer';

const account = (state = {}, action) => {
  let nextState;
  switch (action.type) {
    case 'ACCOUNT_GET':
      if (action.payload.data && action.payload.data.users) {
        nextState = produce(state, (draftState) => action.payload.data);
      } else {
        if (action.payload.data && action.payload.data.emails) {
          const users = [];
          action.payload.data.emails.forEach((item) => {
            users.push({ id: item, key: item, role: 'viewer' });
          });
          action.payload.data.users = users;
        }
        nextState = produce(state, (draftState) => action.payload.data);
      }
      return nextState;
    case 'ACCOUNT_INFO_EDIT':
      nextState = produce(state, (draftState) => {
        if (!draftState.id) {
          draftState.id = action.data.shortName.toLowerCase();
        }
        draftState.name = action.data.name;
        draftState.description = action.data.description;
        draftState.heroImage = action.data.heroImage;
        draftState.shortName = action.data.shortName;
        draftState.logoUrl = action.data.logoUrl;
      });
      return nextState;
    case 'ACCOUNT_USER_ADD':
      return state;
    case 'ACCOUNT_USER_DELETE':
      return state;
    case 'SPACES_GET':
      if (action.data) {
        nextState = produce(state, (draftState) => {
          draftState.spaces = action.data.sort((a, b) => a.updated < b.updated);
        });
        return nextState;
      } else {
        return state;
      }
    case 'SPACE_ADD':
      nextState = produce(state, (draftState) => {
        draftState.spaces.unshift({
          id: action.data.id,
          name: action.data.name,
          config: action.data.config,
        });
      });
      return nextState;
    case 'USER_ROLE_EDIT':
      nextState = produce(state, (draftState) => {
        draftState.userToAdd = {};
        draftState.userToAdd.id = action.data.id;
        draftState.userToAdd.role = action.data.value;
        draftState.users.forEach((item) => {
          if (item.id == action.data.id) {
            item.role = action.data.value;
          }
        });
      });
      return nextState;
    default:
      return state;
  }
};

export default account;
