import React, { useEffect, useState } from 'react';
import { Input, Button, Col, Row, message } from 'antd';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { actionPostComponentSettingEdit, actionPostPropertyEdit } from '../../../actions';
import { SymbolInput } from '../SymbolInput';
import { Icon as IconType } from '../SymbolPicker';
import { getComponentIndex } from '../../../../../reducers/selectors/post';
import { AimOutlined } from '@ant-design/icons';
import { SavePost } from '../../helpers/helpers';

export interface LatLngInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const LatLngInput: React.FC<LatLngInputProps> = (props: LatLngInputProps) => {
  const dispatch = useDispatch();
  const { componentIndex, childIndex, itemIndex, item } = props;
  const { property, label, parents } = item;
  const post = useSelector((state: any) => state.post);
  const store = useStore();
  const [value, setValue] = useState();
  const component = post.components[componentIndex];
  const { defaultLatitude, defaultLongitude, defaultZoom } = post.components[componentIndex];
  const map = window.akukoMaps?.[component.id];
  const [zoom, setZoom] = useState(component.zoom);
  const [latitude, setLatitude] = useState(component.latitude);
  const [longitude, setLongitude] = useState(component.longitude);
  const [pitch, setPitch] = useState(component.pitch || 0);
  const [bearing, setBearing] = useState(component.bearing || 0);
  const [currentLatLng, setCurrentLatLng] = useState({
    longitude: component.longitude,
    latitude: component.latitude,
    zoom: component.zoom,
    pitch: component.pitch || 0,
    bearing: component.bearing || 0
  });

  map?.on('move', (e: Dictionary) => {
    setCurrentLatLng(e.viewState);
    setLatitude(e.viewState.latitude);
    setLongitude(e.viewState.longitude);
    setPitch(e.viewState.pitch);
    setBearing(e.viewState.bearing);
    setZoom(e.viewState.zoom);
  });

  useEffect(() => {
    if (parents?.length === 2 && itemIndex !== undefined && childIndex !== undefined) {
      const currentValue =
        post.components[componentIndex]?.[parents[0]]?.[childIndex]?.[parents[1]]?.[itemIndex]?.[
          property
        ];
      setValue(currentValue);
    }
    if (parents?.length === 1 && itemIndex !== undefined) {
      const currentValue = post.components[componentIndex]?.[parents[0]]?.[itemIndex]?.[property];
      setValue(currentValue);
    }
    if (componentIndex && !parents) {
      setValue(post.components[componentIndex][property]);
    }
    if (item.entity === 'post') {
      if (post && post.color) {
        setValue(post[property]);
      }
    }
  }, [post, parents, itemIndex, childIndex, componentIndex]);

  return (
    <>
      <Row style={{ marginBottom: '10px' }} gutter={10}>
        <Col sm={6}>
          <label>Longitude</label>
        </Col>
        <Col sm={18}>
          <Input
            value={`${longitude || 0}`}
            onChange={(e) => {
              setLongitude(e.target.value);
            }}
          />
        </Col>
        <Col sm={24}>
          <div style={{ height: '10px' }}></div>
        </Col>
        <Col sm={6}>
          <label>Latitude</label>
        </Col>
        <Col sm={18}>
          <Input
            value={`${latitude || 0}`}
            onChange={(e) => {
              setLatitude(e.target.value);
            }}
          />
        </Col>

        <Col sm={8}>
          <label>Zoom</label>
          <Input
            value={`${zoom || 0}`}
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </Col>
        <Col sm={8}>
          <label>Pitch</label>
          <Input
            value={`${pitch}`}
            onChange={(e) => {
              setPitch(e.target.value);
            }}
          />
        </Col>
        <Col sm={8}>
          <label>Bearing</label>
          <Input
            value={`${bearing}`}
            onChange={(e) => {
              setBearing(e.target.value);
            }}
          />
        </Col>
      </Row>

      <Button
        onClick={() => {
          map.jumpTo({
            zoom: zoom,
            center: [longitude, latitude],
            pitch: pitch,
            bearing: bearing,
          });
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: componentIndex,
              componentId: post.components[componentIndex].id,
              property: 'zoom',
              value: zoom,
            })
          );
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: componentIndex,
              componentId: post.components[componentIndex].id,
              property: 'latitude',
              value: latitude,
            })
          );
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: componentIndex,
              componentId: post.components[componentIndex].id,
              property: 'longitude',
              value: longitude,
            })
          );
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: componentIndex,
              componentId: post.components[componentIndex].id,
              property: 'pitch',
              value: pitch,
            })
          );
          dispatch(
            actionPostComponentSettingEdit({
              componentIndex: componentIndex,
              componentId: post.components[componentIndex].id,
              property: 'bearing',
              value: bearing,
            })
          );
          // save post
          setTimeout(() => {
            /* @ts-ignore */
            const updatedPost = store.getState().post;
            SavePost(updatedPost).then((res: any) => {
              message.success('Position saved');
              dispatch(
                actionPostPropertyEdit({
                  property: 'updated',
                  value: res?.updated,
                })
              );
            });
          }, 100);
        }}
      >
        <AimOutlined /> Save as default position
      </Button>
    </>
  );
};

export { LatLngInput };
