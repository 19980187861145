import { GroupFieldProps } from '../types';

export const image: GroupFieldProps = {
  groups: ['Image', 'Events'],
  styles: ['Width'],
  sections: {},
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Image',
      placeHolder: 'Image Title',
    },
    {
      type: 'textarea',
      label: 'Caption',
      property: 'caption',
      group: 'Image',
      placeHolder: 'Image Description',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Image',
      placeHolder: 'Image Identifier',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'select',
      label: 'Origin',
      placeHolder: 'Select image source',
      property: 'imageSource',
      group: 'Image',
      options: [
        { value: 'upload', label: 'Upload' },
        { value: 'dimension', label: 'Dimension' },
      ],
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Image',
      dependsOn: [{ property: 'imageSource', value: 'dimension' }],
    },
    {
      type: 'dimension',
      label: 'Inherit context from card',
      property: 'context',
      group: 'Image',
      showMeasures: true,
      showDimensions: true,
      placeHolder: 'Set Inherit Field From Card Data',
      hasContext: true,
    },
    {
      type: 'dimension',
      label: 'Image field',
      property: 'dimensionImageUrl',
      group: 'Image',
      dependsOn: [{ property: 'imageSource', value: 'dimension' }],
    },
    {
      type: 'image',
      label: 'Image',
      property: 'imageUrl',
      group: 'Image',
      dependsOn: [{ property: 'imageSource', value: ['!', 'dimension'] }],
    },
    {
      type: 'slider',
      label: 'Height',
      property: 'height',
      min: 0,
      max: 1000,
      group: 'Width',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'width',
          value: 'large',
        },
      ],
    },
    {
      type: 'slider',
      label: 'Width',
      property: 'imageWidth',
      min: 40,
      max: 1000,
      group: 'Width',
      placeHolder: '0',
      dependsOn: [
        {
          property: 'width',
          value: ['!', 'large'],
        },
      ],
    },
    {
      type: 'select',
      label: 'Align',
      property: 'align',
      group: 'Align',
      options: [
        { value: 'left', label: 'Left' },
        { value: 'center', label: 'Center' },
        { value: 'right', label: 'Right' },
      ],
    },
    {
      type: 'array',
      label: 'Events',
      property: 'events',
      itemLabel: 'Event',
      collapseProperty: 'effect',
      collapseParent: 'events',
      group: 'Events',
      groups: ['Events'],
      settings: [
        {
          label: 'Events',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      defaultValueType: 'array',
      fields: [
        {
          type: 'select',
          label: 'Trigger',
          property: 'type',
          parents: ['events'],
          placeHolder: 'Select trigger',
          options: [{ value: 'onButtonClick', label: 'Click' }],
          group: 'Events',
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['events'],
          placeHolder: 'Select event',
          options: [{ value: 'navigateToUrl', label: 'Navigate to URL' }],
          group: 'Events',
          dependsOn: [{ property: 'type', value: 'onButtonClick' }],
        },
        {
          type: 'text',
          label: 'URL',
          property: 'url',
          parents: ['events'],
          placeHolder: 'https://',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
        },
        {
          type: 'checkbox',
          label: 'Open in new window',
          property: 'blank',
          parents: ['events'],
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'navigateToUrl' }],
        },
      ],
    },
  ],
};
