import { GroupFieldProps } from '../types';

export const menu: GroupFieldProps = {
  groups: ['Button'],
  styles: ['Width', 'Font', 'Style'],
  sections: {},
  filters: false,
  fields: [
    {
      type: 'color',
      property: 'color',
      label: 'Color',
      disableOpacity: true,
      inherit: 'primaryColor',
      group: 'Style',
    },
    {
      type: 'color',
      defaultValue: "#eee",
      label: 'Background',
      property: 'backgroundColor',
      placeHolder: 'Background color',
      inherit: 'itemBg',
      group: 'Style',
    },
    {
      type: 'color',
      defaultValue: "#ddd",
      property: 'itemSelectedBg',
      inherit: 'itemSelectedBg',
      label: 'Selected bg',
      group: 'Style',
    },
    {
      type: 'color',
      defaultValue: "#000",
      property: 'itemSelectedColor',
      inherit: 'itemSelectedColor',
      label: 'Selected',
      group: 'Style',
    },
    {
      type: 'color',
      defaultValue: "#eee",
      property: 'itemHoverBg',
      inherit: 'itemHoverBg',
      label: 'Hover bg',
      group: 'Style',
    },
    {
      type: 'color',
      defaultValue: "#000",
      property: 'itemHoverColor',
      inherit: 'itemHoverColor',
      label: 'Hover color',
      group: 'Style',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Button',
      placeHolder: 'Button Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'fontFamily',
      property: 'fontFamily',
      inherit: 'fontFamily',
      placeHolder: 'PT Serif',
      label: 'Font family',
      group: 'Font',
    },
    {
      type: 'number',
      property: 'fontSize',
      inherit: 'fontSize',
      placeHolder: '12',
      min: 9,
      label: 'Font Size',
      group: 'Font',
    },
    {
      type: 'select',
      property: 'theme',
      label: 'Theme',
      group: 'Font',
      defaultValue: 'light',
      placeHolder: 'Light',
      options: [
        { label: 'Dark', value: 'dark' },
        { label: 'Light', value: 'light' },
      ],
      dependsOn: [
        {
          property: 'format',
          value: 'verticalMenu',
        },
      ],
    },
    {
      type: 'select',
      label: 'Format',
      property: 'format',
      group: 'Button',
      placeHolder: 'Button',
      options: [
        { value: 'horizontalMenu', label: 'Horizontal Menu' },
        { value: 'verticalMenu', label: 'Vertical Menu' },
        { value: 'inlineMenu', label: 'Inline Menu' },
      ],
    },
  ],
};
