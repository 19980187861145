import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { Button } from 'antd';
import { ArrayItem } from './components/ArrayItem';
import { LoadingOutlined } from '@ant-design/icons';
import { actionPostArrayItemAdd, actionPostArrayItemMove, } from '../../../actions';
import { makeId } from './utils/helpers';

export interface ArrayInputProps {
  componentIndex: number;
  childIndex?: number;
  itemIndex?: number;
  item: Dictionary;
}

const ArrayInput: React.FC<ArrayInputProps> = (props: ArrayInputProps) => {
  const { item, componentIndex, itemIndex } = props;
  const { property, parents, defaultValue, addButtonText } = item;
  const [loading, showLoading] = useState(false);
  const [itemIndexDragged, setItemIndexDragged] = useState(0);
  const [dragTarget, setDragTarget] = useState(0);
  const dispatch = useDispatch();
  const post = useSelector((state: Dictionary) => state.post);
  const component = post.components[componentIndex];

  let array: Dictionary = [];
  if (!parents) {
    array = component[property];
  }
  if (parents && parents.length === 1 && itemIndex !== undefined) {
    array = component?.[parents[0]]?.[itemIndex]?.[property];
  }

  return (
    <div className="array-input">
      <label>{item?.label}</label>
      {array?.map((step: Dictionary, index: number) => (
        <div
          key={index}
          draggable={step.draggable}
          onDragStart={(e) => {
            setItemIndexDragged(index);
          }}
          onDragEnd={(e) => {
            dispatch(
              actionPostArrayItemMove({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: property,
                fromIndex: itemIndexDragged,
                toIndex: dragTarget,
              })
            );
          }}
          onDragOver={(e) => {
            setDragTarget(index);
          }}
          className="array-item"
        >
          <ArrayItem
            componentIndex={componentIndex}
            itemIndex={index}
            childIndex={itemIndex}
            item={item}
            step={step}
          />
        </div>
      ))}
      <Button
        className="btn-item-add"
        onClick={() => {
          showLoading(true);
          setTimeout(() => {
            dispatch(
              actionPostArrayItemAdd({
                componentIndex: componentIndex,
                itemIndex: itemIndex,
                parents: parents,
                property: property,
                value: {
                  id: makeId(),
                  ...defaultValue,
                },
              })
            );
            showLoading(false);
          }, 500);
        }}
      >
        {addButtonText || 'Add'}
        {loading && <LoadingOutlined />}
      </Button>
    </div>
  );
};

export { ArrayInput };
