import { Dictionary } from '@onaio/utils';
import { categoricalClassesValue } from '../../SelectInput/helpers/helpers';

const getUniqueRecords = (
  data: Dictionary[],
  dataField: string
): Dictionary[] => {
  const uniqueRecords: Dictionary[] = [];
  const seenRecords = new Set();

  data.forEach((record) => {
    if (!seenRecords.has(record[`${dataField}`])) {
      seenRecords.add(record[`${dataField}`]);
      uniqueRecords.push(record);
    }
  });
  return uniqueRecords;
};

export const excludePreSelectedOptions = (
  data: Dictionary[],
  groupByColors: Dictionary[],
  groupByValue: string
): Dictionary[] => {
  const options: Dictionary[] = [];

  const uniqueRecords = getUniqueRecords(data, groupByValue);
  if (uniqueRecords?.length > 0) {
    uniqueRecords.forEach((item: Dictionary) => {
      const color = groupByColors?.find(
        (color: Dictionary) => color.value === item[groupByValue]
      );
      if (!color) {
        options.push(item);
      }
    });
  }
  return options;
};

export const colorFieldGetter = (
  componentLevelColors: Dictionary,
  symbolField: string,
  value: string,
  colorField: string
): string => {
  if (componentLevelColors?.layerType === 'symbol') {
    return symbolField;
  } else if (
    componentLevelColors?.radiusScale === 'categorical' &&
    componentLevelColors?.categoryCircleRadiusField
  ) {
    return componentLevelColors?.categoryCircleRadiusField;
  }
  return value || colorField;
};

export const categoriesGetter = (
  componentLevelColors: Dictionary
): Dictionary[] => {
  if (componentLevelColors?.radiusScale === 'categorical') {
    return componentLevelColors?.radiusCategories;
  } else if (componentLevelColors?.layerType === 'symbol') {
    return componentLevelColors?.symbolCategories;
  }
  return componentLevelColors?.colorCategories;
};
