import React from 'react';
import { abbreviateNumber } from 'js-abbreviation-number';
import { LegendOptions } from '../QuantityHorizontalLegend';

/** interface for component props */
interface HeatmapLegendProps {
  low: number | undefined; // low value
  high: number | undefined; // high value
  startColor: string | undefined; // start color for the gradient
  endColor: string | undefined; // end color for the gradient
  legendOptions: LegendOptions;
}

const HeatmapLegend: React.FC<HeatmapLegendProps> = (props: HeatmapLegendProps) => {
  const { low, high, startColor, endColor, legendOptions } = props;

  return (
    <div className="heatmap-legend">
      <div className="heatmap-legend--colors">
        <div
          className="heatmap-legend--color"
          style={{
            background: `linear-gradient(to right, ${startColor}, ${endColor})`,
          }}
        ></div>
      </div>
      <div className="heatmap-legend--values">
        <span className="heatmap-legend--values-low">
          {low &&
            abbreviateNumber(
              low,
              legendOptions?.decimalPoint !== undefined ? legendOptions?.decimalPoint : 1
            )}
        </span>
        <span className="heatmap-legend--values-high">
          {high &&
            abbreviateNumber(
              high,
              legendOptions?.decimalPoint !== undefined ? legendOptions?.decimalPoint : 1
            )}
        </span>
      </div>
    </div>
  );
};

export { HeatmapLegend };
