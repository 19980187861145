import { GroupFieldProps } from '../types';

export const filter: GroupFieldProps = {
  groups: ['Filter', 'Components', 'Style'],
  styles: ['Width'],
  sections: {},
  filters: false,
  fields: [
    {
      type: 'text',
      label: 'Title',
      property: 'title',
      group: 'Filter',
      placeHolder: 'Filter Title',
    },
    {
      type: 'text',
      label: 'Name',
      property: 'name',
      group: 'Filter',
      placeHolder: 'Filter Identifier Name',
    },
    {
      type: 'pills',
      label: 'Width',
      property: 'width',
      placeHolder: 'Small',
      group: 'Width',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
    },
    {
      type: 'source',
      label: 'Source',
      property: 'source',
      group: 'Filter',
    },
    {
      type: 'array',
      label: 'Filters',
      property: 'filters',
      group: 'Filter',
      groups: ['Filter'],
      addButtonText: 'Add Filter',
      settings: [
        {
          label: 'Filter',
          dependsOn: [],
          showCollapse: false,
        },
        {
          label: 'Options',
          dependsOn: [],
          collapse: false,
        },
      ],
      collapse: false,
      itemLabel: 'Filter',
      collapseProperty: '1',
      collapseParent: 'filters',
      defaultValueType: 'array',
      defaultValue: {
        0: '==',
        1: '',
        2: [],
        3: [],
        4: [],
        multiple: true,
        expose: true,
        inherit: false,
      },
      fields: [
        {
          type: 'text',
          label: 'Name',
          group: 'Filter',
          property: 'name',
          parents: ['filters'],
          placeHolder: 'Filter Name',
        },
        {
          type: 'dimension',
          label: 'Property',
          group: 'Filter',
          property: '1',
          showMeasures: true,
          showDimensions: true,
          parents: ['filters'],
          placeHolder: 'Set Field to Filter by',
          setDataType: true,
          dataTypeKey: 'dataType',
          setDataSource: true,
          dataSourceKey: 'dataSource',
        },
        {
          type: 'select',
          label: 'Granularity',
          property: 'granularity',
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
          parents: ['filters'],
          options: [
            // day, month, year, week, hour, minute, second, null
            { value: 'second', label: 'Second' },
            { value: 'minute', label: 'Minute' },
            { value: 'hour', label: 'Hour' },
            { value: 'day', label: 'Day' },
            { value: 'week', label: 'Week' },
            { value: 'month', label: 'Month' },
            { value: 'quarter', label: 'Quarter' },
            { value: 'year', label: 'Year' },
          ],
          group: 'Filter',
          placeHolder: 'Select time granularity option',
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          options: [
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
            { value: '==', label: 'equal to' },
            { value: '!=', label: 'not equal to' },
            { value: '>', label: 'greater than' },
            { value: '>=', label: 'greater than or equal to' },
            { value: 'contains', label: 'contains' },
            { value: '<=', label: 'less than or equal to' },
            { value: '<', label: 'less than' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Operator',
          group: 'Filter',
          property: '0',
          parents: ['filters'],
          defaultValue: 'afterDate',
          options: [
            { value: 'beforeDate', label: 'is before' },
            { value: 'afterDate', label: 'is after' },
            { value: 'inDateRange', label: 'in date range' },
            { value: 'isNull', label: 'is null' },
            { value: 'notNull', label: 'not null' },
          ],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
            {
              property: 'dataSource',
              value: ['!', 'postgres', 'mysql'],
            },
          ],
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['==', '!=', 'contains'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['beforeDate', 'afterDate', '<=', '>='],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Greater than or equal to',
          group: 'Filter',
          property: '3',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'dimension-value',
          label: 'Less than or equal to',
          group: 'Filter',
          property: '4',
          parents: ['filters'],
          showMeasures: true,
          showDimensions: true,
          dependsOn: [
            {
              property: '0',
              value: ['notInDateRange', 'inDateRange'],
            },
            {
              property: 'showTextField',
              value: '!',
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['>', '<'],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'text',
          label: 'Value',
          group: 'Filter',
          property: '2',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', '>', '<', 'isNull', 'notNull'],
            },
            {
              property: 'showTextField',
              value: [true],
            },
          ],
          placeHolder: 'Set value to Filter by',
        },
        {
          type: 'checkbox',
          group: 'Options',
          label: 'Inherit filter value',
          cols: 24,
          property: 'inherit',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Expose this filter',
          group: 'Options',
          cols: 24,
          property: 'expose',
          parents: ['filters'],
        },

        {
          type: 'checkbox',
          label: 'Allow multiple values',
          group: 'Options',
          cols: 24,
          property: 'multiple',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['!', 'time'],
            },
          ],
        },

        {
          type: 'checkbox',
          label: 'Separate text by commas',
          group: 'Options',
          cols: 24,
          property: 'separateTextByCommas',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['contains'],
            },
          ],
        },

        {
          type: 'checkbox',
          label: 'Use slider input',
          group: 'Options',
          cols: 24,
          property: 'slider',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Use Date picker',
          group: 'Options',
          cols: 24,
          property: 'datePicker',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'showTextField',
              value: '!',
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Disable input clear',
          group: 'Options',
          cols: 24,
          dependsOn: [
            {
              property: 'expose',
              value: [true],
            },
          ],
          property: 'disableClear',
          parents: ['filters'],
        },
        {
          type: 'checkbox',
          label: 'Use text input',
          group: 'Options',
          cols: 24,
          property: 'showTextField',
          parents: ['filters'],
          dependsOn: [
            {
              property: '0',
              value: ['!', 'isNull', 'notNull', '>', '<'],
            },
            {
              property: 'slider',
              value: '!',
            },
          ],
        },
        {
          type: 'checkbox',
          label: 'Show Actual Date Format',
          group: 'Options',
          cols: 24,
          property: 'actualDateFormat',
          parents: ['filters'],
          dependsOn: [
            {
              property: 'dataType',
              value: ['time'],
            },
          ],
        },
        {
          type: 'select',
          label: 'Input width',
          group: 'Options',
          property: 'cols',
          parents: ['filters'],
          dependsOn: [{ property: 'expose', value: true }],
          placeHolder: 'Small',
          options: [
            { label: 'Small', value: '8' },
            { label: 'Medium', value: '12' },
            { label: 'Large', value: '24' },
          ],
        },
      ],
    },
    {
      type: 'switch',
      label: 'Enable cascading filters',
      property: 'cascade',
      group: 'Filter',
    },
    {
      type: 'slider',
      label: 'padding',
      property: 'padding',
      min: 0,
      max: 200,
      step: 5,
      group: 'Style',
      placeHolder: '0',
    },
    {
      type: 'filter',
      label: 'Components',
      property: 'components',
      group: 'Components',
    },
    {
      type: 'array',
      label: 'Events',
      property: 'events',
      itemLabel: 'Event',
      group: 'Events',
      groups: ['Events'],
      settings: [
        {
          label: 'Events',
          dependsOn: [],
          showCollapse: false,
        },
      ],
      defaultValueType: 'array',
      fields: [
        {
          type: 'select',
          label: 'Trigger',
          property: 'type',
          parents: ['events'],
          placeHolder: 'Select trigger',
          options: [{ value: 'onChange', label: 'Change' }],
          group: 'Events',
        },
        {
          type: 'select',
          label: 'Event',
          property: 'effect',
          parents: ['events'],
          placeHolder: 'Select event',
          options: [{ value: 'zoomToBounds', label: 'Zoom to bounds' }],
          group: 'Events',
        },
        {
          type: 'component',
          label: 'Map',
          property: 'target',
          parents: ['events'],
          placeHolder: 'Select component',
          componentType: 'map',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'zoomToBounds' }],
        },
        {
          type: 'dimension',
          label: 'Filter',
          property: 'filter',
          parents: ['events'],
          placeHolder: 'Select filter',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'zoomToBounds' }],
        },
        {
          type: 'component',
          label: 'Component to update',
          property: 'target',
          parents: ['events'],
          placeHolder: 'Select component',
          componentType: 'map',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'slider',
          label: 'Zoom to',
          property: 'zoom',
          parents: ['events'],
          min: 1,
          max: 20,
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Latitude',
          property: 'lat',
          parents: ['events'],
          placeHolder: '1',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'dimension',
          label: 'Longitude',
          property: 'lng',
          parents: ['events'],
          placeHolder: '1',
          group: 'Events',
          dependsOn: [{ property: 'effect', value: 'flyTo' }],
        },
        {
          type: 'select',
          label: 'Select tab',
          property: 'tab',
          parents: ['events'],
          placeHolder: 'Select tab',
          group: 'Events',
          options: [
            { value: '1', label: 'Tab 1' },
            { value: '2', label: 'Tab 2' },
            { value: '3', label: 'Tab 3' },
          ],
          dependsOn: [{ property: 'target', value: 'layout' }],
        },
      ],
    },
  ],
};
