import React from 'react';
import { Col, Row } from 'antd';
import Region from './Region';

const LayoutTwentyThree = (props) => {
  return (
    <div>
      <Row gutter={15}>
        <Col xs={24} sm={16}>
          <Region regionIndex={1} {...props} />
          <Row gutter={15}>
            <Col xs={24} sm={12}>
              <Region regionIndex={2} {...props} />
            </Col>
            <Col xs={24} sm={12}>
              <Region regionIndex={3} {...props} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={8}>
          <Region regionIndex={4} {...props} />
        </Col>
      </Row>
    </div>
  );
};

export { LayoutTwentyThree };
