import React, { useState} from 'react';
import { Input, Form, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils/dist/types/types';
import { useAuth } from 'react-oidc-context';

import { IDENTITY_API } from '../../../configs/env';
import { AkukoAPIService } from '../../../services/serviceClass';
import Footer from '../../Post/Footer';
import { actionUserGet } from '../actions';
import { ERROR_GENERIC } from '../../../configs/constants';
import './style.css';

const SetHandle: React.FC = () => {
 const history = useHistory();
 const [loading, setLoading] = useState(false);
 const [hasErrors, setHasErrors] = useState(false);
 const [checkingAvailability, setCheckingAvailability] = useState(false);
 const auth = useAuth();
 const dispatch = useDispatch();
 const [form] = Form.useForm();

 const handleIsValid = (value: string) => {
  const re = /^\w+$/;
    if (!re.test(value)) {
      return false;
    }
  return true;
 }

 return(
  <div className="form-set-password">
   <img alt="akuko-logo" className="form-set-password--brand" src="https://assets.akuko.io/akuko-color-logo.png" />
   <h1>Create your handle</h1>
   <p>Please create a handle for your Akuko account.</p>
   <Form
    form={form}
    layout="vertical"
    onFinish={ async (values) => {
      try {
        setLoading(true);
        const service = new AkukoAPIService(IDENTITY_API, `user/signup/auth/callback/${auth?.user?.profile?.sub}`);
        const userProfile = await service.update({
          handle: values?.handle,
          email: auth?.user?.profile?.email,
        });
        dispatch(actionUserGet(userProfile));
        message.success('Handle updated');
        history.push('/dashboard');
      } catch (err) {
        const error = err as Error;
        if (error.message) {
          message.error(error.message);
        } else {
          message.error(ERROR_GENERIC);
        }
      } finally {
        setLoading(false);
      }
    }}
   >
     <Form.Item
       name="handle"
       hasFeedback
       validateTrigger="onChange"
       rules={[
          {
            required: true,
            message: 'Handle is not available',
            validator: async (_, value) => {
              if (value === '') {
                setHasErrors(true);
                return Promise.reject();
              }
              if (value.length < 3 || value.length > 16 || handleIsValid(value) === false) {
                setHasErrors(true);
                return Promise.reject();
              }
              setCheckingAvailability(true);
              const service = new AkukoAPIService(IDENTITY_API, 'user/handle/available');
              try {
                const res = await service.read(value) as Dictionary;
                if (!res?.available) {
                  setHasErrors(true);
                  return Promise.reject();
                }
                setHasErrors(false);
                return Promise.resolve();
              } catch (e) {
                setHasErrors(true);
                return Promise.reject();
              } finally {
                setCheckingAvailability(false);
              }
            },
          },
        ]}
      >
       <Input size="large" addonBefore={(<UserOutlined />)} prefix="@" disabled={loading} placeholder="myGreatHandle" />
     </Form.Item>
     <p>Handles may contain letters, numbers and underscores.</p>
     <Button
        htmlType="submit"
        size="large"
        type="primary"
        disabled={loading || checkingAvailability || hasErrors}
      >
        {loading && <LoadingOutlined />} {loading ? 'Setting handle...' : 'Set handle'}
      </Button>
   </Form>
   <Footer />
  </div>
 )
};

export { SetHandle };
