import React, { ReactChild, useState } from 'react';
import { ComponentMenu } from '../ComponentMenu';
import { FullscreenOutlined, CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { Dictionary } from '@onaio/utils/dist/types/types';
export interface LeftRegionProps {
    id: string,
    children?: ReactChild
}

const LeftRegion: React.FC<LeftRegionProps> = (props: LeftRegionProps) => {
  const { children } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <>
    <div className="editor-left-region">
      { children }
    </div>
    </>
  )
}

export { LeftRegion }